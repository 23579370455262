import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { ILookup } from '../../interfaces/lookup';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;

  private lookupUrl = this.baseUrl + '/common/lookup'; 
  private brandTemplatesUrl = this.baseUrl + '/common/lookup/brand-templates';


  // private headers = new HttpHeaders()
  // .set('Authorization', this.basicAuth)

  constructor(private http: HttpClient,
    private errorProcessorService: ErrorProcessorService) { }

  getAllLookups(): Observable<ILookup[]> {
    return this.http.get<ILookup[]>(this.lookupUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );
  }
 
  getLookupsByCategory(category: string): Observable<ILookup[]> {
    var url = this.lookupUrl + "/category/" + category;
    return this.http.get<ILookup[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );
  }

  // Brand Templates

  getBrandTemplates(): Observable<string> {
    return this.http.get<string>(this.brandTemplatesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );
  }  

  // Brand migration
  getSourceRegions() {
    return environment.brandMigration.sourceRegions;
  }

  getTargetRegions() {
    return environment.brandMigration.targetRegions;
  }

}
