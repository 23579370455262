<!-- <h2>c<small style="color:DarkGreen; margin: 15%;">{{brandName}}</small></h2>  -->

 <app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

 <div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded"  [disabled]="isDetailsShown" *ngIf="isBrandSelectionButtonShown">
         </button>
    </div>  
</div> 

<!--  S E L E C T I O N   C R I T E R I A  -->

<div class="col-6">

    <div class="div-criteria-two">

        <h2 style="width: 15em; color: darkblue; margin-left: 2em;">Enrollment Statistics</h2>

        <div class="grid">

            <div class="col-4">
                <div class="field grid">
                    <div class="col-1"></div>
                    <label for="dateFrom" class="col-fixed label" style="width: 5em">From: </label>
                    <div class="col-6">
                        <p-calendar id="dateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" placeholder="Date From:" 
                        [defaultDate]="defaultDate" [showIcon]="true" class="margin-left"></p-calendar>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="field grid">
                    <div class="col-1"></div>
                    <label for="dateTo" class="col-fixed label" style="width: 5em">To: </label>
                    <div class="col-6">
                        <p-calendar id="dateTo" [(ngModel)]="selectedDateTo" dateFormat="yy-mm-dd" placeholder="Date To:" 
                        [showIcon]="true" class="margin-left"></p-calendar>
                    </div>
                </div>         
            </div>

            <div class="col-1"></div>

            <div class="col-2">
                <p-button type="button" label="Search" 
                    styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)">
                </p-button>
                <p-button type="button" label="Clear" 
                    styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)">
                </p-button>
            </div> 
                
        </div>

    </div>

    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
    </div>

</div>

    <!--  E N R O L M E N T S   G R I D  -->

<div class="grid pt-5">

    <p-tabView>

        <p-tabPanel header="Bar Chart View" leftIcon="pi pi-chart-bar">

            <p-chart type="bar" [data]="enrollmentStatsChart" [options]="optionsEnrollments" width="1000" height="500" ></p-chart>
           
        </p-tabPanel>

        <p-tabPanel header="Grid View" leftIcon="pi pi-table">

            <div class="col-12">
  
                <p-table #dtt [columns]="enrollmentStatsCols" [value]="enrollmentStats" selectionMode="single" 
                      tableStyleClass="prime-table" [rowsPerPageOptions]="[10,15,25,50]" [showCurrentPageReport]="true"
                      sortMode="multiple" [paginator]="true" [rows]="15">
                    <ng-template pTemplate="caption">
                      Enrollment Counts by Source and Platform
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                          {{col.header}}
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                      </tr>
                  </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr [pSelectableRow]="rowData">
                          <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                              {{rowData[col.field]}}
                          </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div style="text-align:left">
                        <p-button type="button" label="CSV Export" 
                            styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="dtt.exportCSV()">
                        </p-button>
<!--     
                        <div class="ui-helper-clearfix">
                            <button type="button" pButton icon="fa-file-o" iconPos="left"
                            label="CSV Export" (click)="dtt.exportCSV()" style="float:right"></button>
                        </div>
                         -->
                    </div>
                </ng-template>    
            </p-table> 
          
            </div>
        
        </p-tabPanel>

    </p-tabView>

    <p-confirmDialog  class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>

</div>

