import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot} from '@angular/router';
import { map } from 'rxjs';
import {AuthService }  from "../services/safeguard/auth.service";

export const authToken = (next: ActivatedRouteSnapshot) => {
  const service = inject(AuthService);
  return service
    .isTokenValid()
    .pipe(
      map((isAuthorized) =>
      isAuthorized ? true : createUrlTreeFromSnapshot(next, ['/', 'login'])
      )
    );
};
