<h2>Walletron Users</h2>

 <div class="grid">
    <div class="col-7">
        <div *ngIf="envRegion.envCode" class="alert alert-danger">
            <p-message severity="success" text="Current Environmental Region: '{{envRegion.envCode}}'"></p-message>
        </div>
    </div>
</div> 

 <!-- Selection criteria  -->

<div class="grid col-8">
    <div class="col-1">
        <label for="selectedUserName" class="margin-left">User Name: </label>
        <input pInputText id="userName" [(ngModel)]="selectedUserName" placeholder="User Name:"/> 
    </div>  
    <div class="col-1"></div>
    <div class="col-1">
        <label for="selectedBrandCode" class="margin-left">Brand Code: </label>
        <p-dropdown id ="brandCode" [options]="brandCodes" [(ngModel)]="selectedBrandCode" [filter]="true" 
            placeholder="Brand Code:" [editable]="false" ></p-dropdown>
    </div>  
    <div class="col-1"></div>
    <div class="col-1">
        <label for="selectedRoleName" class="margin-left">Role Name: </label>
        <p-dropdown id ="roleName" [options]="roleNames" [(ngModel)]="selectedRoleName" [filter]="false" 
            placeholder="Role Name:" [editable]="false"></p-dropdown>
    </div>  
    <div class="col-1"></div>
    <div class="col-1">
        <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
    </div>  
    <div class="col-1">
        <p-button label="Next" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onNextClick($event)"></p-button>
    </div>  
    <div class="col-1">
        <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)"></p-button>
    </div>  
    <div class="col-1">
    </div>  
    <div class="col-2">
        <button pButton type="button" label="Env. Region" icon="pi pi-sitemap" iconPos="left" (click)="onEnvRegionClick($event)"  class="p-button-raised p-button-rounded"></button>
    </div>  

</div>

 <!-- Grid with all users based on the specified selection criteria  -->

<div class="grid">
    <div class="col-8">

        <p-table #dt [columns]="cols" [value]="users" selectionMode="single" [(selection)]="selectedUser" 
            sortMode="multiple" (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[10,15,25,50]" 
            [showCurrentPageReport]="true">
          <ng-template pTemplate="caption">
              List of Users
          </ng-template>
          <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    <input *ngSwitchCase="'userId'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                    <input *ngSwitchCase="'userName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                    <input *ngSwitchCase="'brandCode'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                </th>
            </tr>  
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary" let-rowData>
              <div style="text-align:left" *ngIf="isSubmitShown">
                  <p-button label="Add" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="showDialogToAdd()"></p-button>
              </div>
          </ng-template>    
          </p-table> 
      
    </div>
</div>

<!-- User Details dialog -->

<p-confirmDialog class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>

<p-dialog appendTo="body" header="User Details" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '40%'}">
    <div *ngIf="user">

        <p-tabView>
            <p-tabPanel header="Properties" leftIcon="pi pi-user-edit">

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                        <label for="userId" class="col-fixed label" style="width: 12em">User Id:</label>
                        <div class="col">
                            <input pInputText id="userId" [(ngModel)]="user.userId" autofocus [disabled]="true" style="width: 8em"/>
                        </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="field grid">
                        <label for="userName" class="col-fixed label" style="width:12em">User Name:</label>
                        <div class="col">
                            <input pInputText id="userName" [(ngModel)]="user.userName" style="width: 10em">
                        </div>
                    </div>
                </div> 

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                        <label for="brandCode" class="col-fixed label" style="width: 12em">Brand Code:</label>
                        <div class="col">
                            <p-dropdown id ="brandCode" [options]="brandCodes" [(ngModel)]="user.brandCode" [filter]="true" 
                                placeholder="Select brand" [editable]="false"  style="width: 8em"></p-dropdown>
                        </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="field grid">
                        <label for="password" class="col-fixed label" style="width:12em">Password:</label>
                        <div class="col">
                            <input pPassword type="password" id="password" [(ngModel)]="user.password"/>
                        </div>
                    </div>
                </div> 

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                        <label for="locked" class="col-fixed label" style="width: 12em">Locked:</label>
                        <div class="col">
                            <p-checkbox [(ngModel)]="user.locked" id="locked" [binary]="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="col-2"></div>
                    <div class="field grid">
                        <label for="disabled" class="col-fixed label" style="width:12em">Disabled:</label>
                        <div class="col">
                            <p-checkbox [(ngModel)]="user.disabled" id="disabled" [binary]="true"></p-checkbox>
                        </div>
                    </div>
                </div> 

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                        <label for="lastLoginDate" class="col-fixed label" style="width:12em">Last Login:</label>
                        <div class="col">
                            <input pInputText id="lastLoginDate" [(ngModel)]="user.lastLoginDate" [disabled]="true"/>
                        </div>
                    </div>
                </div> 

            </p-tabPanel>

            <p-tabPanel header="Roles" leftIcon="pi pi-check-circle">

                <p-pickList [source]="availableRoles" [target]="grantedRoles" sourceHeader="Available" targetHeader="Granted" 
                [responsive]="true" [dragdrop]="true" [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-car pTemplate="item">
                <div class="ui-helper-clearfix">
                    <div>{{car.label}}</div>
                    <!-- <div style="font-size:14px;float:left;margin:15px 5px 0 0">{{car.label}}</div> -->
                </div>
                </ng-template>
                </p-pickList>
                
            </p-tabPanel>
        </p-tabView>

    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
<!--             
            <button type="button" pButton icon="fa fa-close p-button-raised" (click)="delete()" label="Delete" *ngIf="isSubmitShown"></button>
            <button type="button" pButton icon="fa fa-check p-button-raised" (click)="save()" label="Save" *ngIf="isSubmitShown"></button>
            <button type="button" pButton icon="fa fa-check p-button-raised" (click)="cancel()" label="Cancel"></button>
 -->
            <p-button label="Delete" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="delete()" *ngIf="isSubmitShown"></p-button>
            <p-button label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="save()" *ngIf="isSubmitShown"></p-button>
            <p-button label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancel()"></p-button>
  
        </div>
    </p-footer>

</p-dialog>

<!-- The following is a popup dialog to select an enviromental region -->

<p-dialog appendTo="body" header="Select Enviromental Region" [(visible)]="displayEnvRegionDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '35%'}" [showHeader]="true">

        <div class="grid">
            <div class="col-12">
        
                <p-table #dt [columns]="envRegionCols" [value]="envRegions" selectionMode="single" 
                    [(selection)]="envRegion"  
                    sortMode="multiple" (onRowSelect)="onRowEnvRegionSelect($event)" [paginator]="false" [rows]="10" >
                  <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                          {{col.header}}
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                      </th>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr [pSelectableRow]="rowData">
                          <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                              {{rowData[col.field]}}
                          </td>
                      </tr>
                  </ng-template>
                </p-table> 
              
            </div>
        </div>

    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
<!--             
            <button type="button" pButton icon="fa fa-check" (click)="onEnvRegionSelect()" label="Select"></button>
            <button type="button" pButton icon="fa fa-check" (click)="onCancelEnvRegionSelect()" label="Cancel"></button>
 -->
            <p-button label="Select" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onEnvRegionSelect()"></p-button>
            <p-button label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelEnvRegionSelect()"></p-button>

        </div>
    </p-footer>

</p-dialog>


