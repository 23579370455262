<div class="grid">

    <div class="col-4">

        <p-card header="Brand Migration" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">
    
            <div>
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="brandCode" class="col-fixed label" style="width: 15em">Brand Code:</label>
                        <input pInputText id="brandCode" type="text" formControlName="brandCode" [disabled]="isDisabled" 
                            [size]="3" [minLength]="3" placeholder="Required" pTooltip="Three character unique brand code" tooltipPosition="top"/> 
                        <p-message severity="error" text="Brand code is required" *ngIf="!userform.controls['brandCode'].valid&&userform.controls['brandCode'].dirty"></p-message>                
                </div>
                
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="sourceRegion" class="col-fixed label" style="width: 15em">Source Region:</label>
                        <p-dropdown id ="sourceRegion" [options]="sourceRegions" formControlName="sourceRegion" [disabled]="isDisabled" 
                            [filter]="false" [editable]="false" pTooltip="Select the source region" tooltipPosition="top"> 
                            <p-message severity="error" text="Source region is required" *ngIf="!userform.controls['sourceRegion'].valid&&userform.controls['sourceRegion'].dirty"></p-message>                 
                        </p-dropdown>
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="targetRegion" class="col-fixed label" style="width: 15em">Target Region:</label>
                        <p-dropdown id ="targetRegion" [options]="targetRegions" formControlName="targetRegion" [disabled]="isDisabled" 
                            [filter]="false" [editable]="false" pTooltip="Select the target region" tooltipPosition="top"> 
                            <p-message severity="error" text="Target region is required" *ngIf="!userform.controls['targetRegion'].valid&&userform.controls['targetRegion'].dirty"></p-message>                 
                        </p-dropdown>
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="gwIssuerId" class="col-fixed label" style="width: 15em">Google Pay Issuer Id:</label>
                        <input pInputText id="gwIssuerId" type="text" formControlName="gwIssuerId" [style]="{'width': '50%'}" [disabled]="isDisabled" 
                            [size]="45" placeholder="Required" pTooltip="Google pay issuer Id" tooltipPosition="top"/> 
                        <p-message severity="error" text="Google pay issuer Id is required" *ngIf="!userform.controls['gwIssuerId'].valid&&userform.controls['gwIssuerId'].dirty"></p-message>                 
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="passTypeId" class="col-fixed label" style="width: 15em">Pass Type Id:</label>
                        <input pInputText id="passTypeId" type="text" formControlName="passTypeId" [style]="{'width': '50%'}" [disabled]="isDisabled" 
                            [size]="100" placeholder="Required" pTooltip="Pass type Id" tooltipPosition="top"/> 
                        <p-message severity="error" text="Pass type Id is required" *ngIf="!userform.controls['passTypeId'].valid&&userform.controls['passTypeId'].dirty"></p-message>                 
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="keyName" class="col-fixed label" style="width: 15em">Key Name:</label>
                        <input pInputText id="keyName" type="text" formControlName="keyName" [style]="{'width': '50%'}" [disabled]="isDisabled" 
                            [size]="40" placeholder="Required" pTooltip="Key name" tooltipPosition="top"/> 
                        <p-message severity="error" text="Key name is required" *ngIf="!userform.controls['keyName'].valid&&userform.controls['keyName'].dirty"></p-message>                 
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="keyFile" class="col-fixed label" style="width: 15em">Key File:</label>
                        <input pInputText id="keyFile" type="text" formControlName="keyFile" [style]="{'width': '50%'}" [disabled]="isDisabled" 
                            [size]="4" placeholder="Required" pTooltip="Key file name" tooltipPosition="top"/> 
                        <p-message severity="error" text="Key file name is required" *ngIf="!userform.controls['keyFile'].valid&&userform.controls['keyFile'].dirty"></p-message>                 
                </div>
    
                <div class="col-12">
                    <div  class="col-2" *ngIf="isSubmitShown" class="row container-fluid"  id="divshow">

                        <!-- <button pButton type="submit" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" ></button> -->

                        <p-button type="submit" label="Submit" 
                            styleClass="p-button-success p-button-raised p-button-rounded margin-left" 
                            [disabled]="!userform.valid">
                        </p-button>

                    </div>          
                    <div class="col-10" *ngIf="!isSubmitShown">
                        <mat-label>{{resultMsg}}</mat-label>
                    </div>
                </div>
    
            </div>
        </form>
    
        <p-footer>
                <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
            </p-footer>
        </p-card>

    </div>

    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-migration"></p-confirmDialog>




