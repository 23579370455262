import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldBackField extends OfferFieldBase {

    fieldAlignmentOptions: any[];
    fieldFormatOptions: any[];
    linkCategories: any[];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        fieldAlignmentOptions: any[],
        fieldFormatOptions: any[],
        linkCategories: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.fieldAlignmentOptions = fieldAlignmentOptions;
            this.fieldFormatOptions = fieldFormatOptions;
            this.linkCategories = linkCategories;
            this.setOfferFieldAttributes();
    } 

    public setOfferFieldAttributes() {

        // Field Label (N), Field Value, Alt Field Value (N), Link (N), Field Alignment (N), Field Data Type and Style (N)
        // Change Message ?, Show/hide Field Expression, Var Transformation Expression ?

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldLabel',
            label: 'Field Label',
            value: this.data.fieldLabel,
            styleWidth: '25em',
            required: false,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'altFieldValue',
            label: 'Alt Field Value',
            value: this.data.altFieldValue,
            styleWidth: '20em',
            required: false,
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'offerlink',
            label: 'Link',
            value: this.data.offerlink,
            required: false,
            styleWidth: '40em',
            validator: 'url',
            tooltip: 'Invalid URL link',
            order: 7
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'linkCategoryCode',
            label: 'Link Category',
            value: this.data.linkCategoryCode,
            styleWidth: '20em',
            required: false,
            tooltip: 'Select the proper link category',
            options: this.linkCategories,
            order: 8
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldAlignment',
            label: 'Field Alignment',
            value: this.data.fieldAlignment,
            styleWidth: '15em',
            required: false,
            tooltip: 'Select the proper field allignment on the pass',
            options: this.fieldAlignmentOptions,
            order: 9
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldFormat',
            label: 'Field Data Type and Style',
            value: this.data.fieldFormat,
            styleWidth: '15em',
            required: false,
            tooltip: 'Select the proper field data type and style on the pass',
            options: this.fieldFormatOptions,
            order: 10
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'changeMessage',
            label: 'Change Message',
            value: this.data.changeMessage,
            styleWidth: '20em',
            required: false,
            order: 11
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 12
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'varTransformationExpression',
            label: 'Var Transformation Expression',
            value: this.data.varTransformationExpression,
            styleWidth: '30em',
            required: false,
            order: 13
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }

}
