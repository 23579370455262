export class OfferFieldAttribute<T> {
    value?: T;
    key: string;
    label: string;
    required: boolean;
    readonly: boolean;
    order: number;
    controlType: string;
    type: string;
    tooltip: string;
    checked: boolean;
    cols: number;
    rows: number;
    styleWidth: string;
    colorInit: string;
    validator: string;
    options: {label: string, value: string}[];
  
    constructor(options: {
        value?: T;
        key?: string;
        label?: string;
        required?: boolean;
        readonly?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        tooltip?: string;
        checked?: boolean;
        cols?: number;
        rows?: number;
        styleWidth?: string;
        colorInit?: string;
        validator?: string;
        options?: {label: string, value: string}[];
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label === undefined ? '' : options.label + ':';
      this.required = !!options.required;
      this.readonly = options.readonly === undefined ? false: options.readonly;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.tooltip = options.tooltip === undefined ? options.label || '' : options.tooltip;
      this.cols = options.cols === undefined ? 30 : options.cols;
      this.rows = options.rows === undefined ? 1 : options.rows;
      this.checked = options.checked === undefined ? false : options.checked; 
      this.styleWidth = options.styleWidth === undefined ?  '40%': options.styleWidth;
      this.options = options.options || [];
      this.colorInit = options.colorInit === undefined ?  'FFFFFF': options.colorInit;
      this.validator = options.validator || '';
    }
}
