import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './portal/home/home.component';
import { UsersComponent } from './portal/users/users.component';
import { PortalUsersComponent } from './portal/portal-users/portal-users.component';
import { MonitoringComponent } from './portal/monitoring/monitoring.component';
import { BrandListingComponent } from './portal/brand-listing/brand-listing.component';
import { ConfigPropertyComponent } from './portal/config-property/config-property.component';
import { BrandConfigPropertyComponent } from './portal/brand-config-property/brand-config-property.component';
import { LoginComponent } from './portal/login/login.component';
import { EncryptionUtilityComponent } from './portal/encryption-utility/encryption-utility.component';
import { EnrollmentStatsComponent } from './portal/enrollment-stats/enrollment-stats.component';
import { ClickthroughStatsComponent } from './portal/clickthrough-stats/clickthrough-stats.component';
import { NotificationStatsComponent } from './portal/notification-stats/notification-stats.component';
import { SummaryReportsComponent } from './portal/summary-reports/summary-reports.component';
import { EnrollmentReportComponent } from './portal/enrollment-report/enrollment-report.component';
import { AccountInfoComponent } from './portal/account-info/account-info.component';
import { CommunicationSubscribersComponent } from './portal/communication-subscribers/communication-subscribers.component';
import { TestSuiteComponent }  from './portal/test-suite/test-suite.component';
import { BrandBootstrapComponent } from './portal/brand-bootstrap/brand-bootstrap.component';
import { BrandVariablesComponent } from './portal/brand-variables/brand-variables.component'; 
import { BrandPassTemplatesComponent } from './portal/brand-pass-templates/brand-pass-templates.component'; 
import { MessageTemplatesComponent } from './portal/message-templates/message-templates.component'; 
import { BrandOffersComponent } from './portal/brand-offers/brand-offers.component'; 
import { OfferFieldsComponent } from './portal/offer-fields/offer-fields.component'; 
import { BrandImagesComponent } from './portal/brand-images/brand-images.component';
import { EnrollmentLinksComponent } from './portal/enrollment-links/enrollment-links.component';
import { EmailSmsTemplatesComponent } from './portal/email-sms-templates/email-sms-templates.component';
import { EnrollmentTrendComponent } from './portal/enrollment-trend/enrollment-trend.component';
import { MessageBroadcastingComponent } from './portal/message-broadcasting/message-broadcasting.component';
import { AccountTasksComponent } from './portal/account-tasks/account-tasks.component';
import { EnrollmentInvitationComponent } from './portal/enrollment-invitation/enrollment-invitation.component';
import { CommunicationHistoryComponent } from './portal/communication-history/communication-history.component';
import { ScheduledEventsComponent } from './portal/scheduled-events/scheduled-events.component';
import { HomeBillerComponent } from './portal/home-biller/home-biller.component';
import { BrandMigrationComponent } from './portal/brand-migration/brand-migration.component';
import { CsrAccountComponent } from './portal/csr-account/csr-account.component';
import { GroupOnboardComponent } from './portal/group-onboard/group-onboard.component';
import { MessageProfileComponent } from './portal/message-profile/message-profile.component';
import { UserProfileComponent } from './portal/user-profile/user-profile.component';
import { BrandProfileComponent } from './portal/brand-profile/brand-profile.component';
import { QrcodeGenerationComponent } from './portal/qrcode-generation/qrcode-generation.component';
import { ConfigMappingsComponent } from './portal/config-mappings/config-mappings.component';
import { PortalRolesComponent } from './portal/portal-roles/portal-roles.component';
import { RtpnTransactionsComponent } from './portal/rtpn-transactions/rtpn-transactions.component';
import { ClientDashboardComponent } from './portal/client-dashboard/client-dashboard.component';
import { ScheduledEventsListingComponent } from './portal/scheduled-events-listing/scheduled-events-listing.component';
import { RtpnSimulatorComponent } from './portal/rtpn-simulator/rtpn-simulator.component';
import { AdhocNotificationComponent } from './portal/adhoc-notification/adhoc-notification.component';
import { AdhocMessageConfigComponent } from './portal/adhoc-message-config/adhoc-message-config.component';
import { MessageExecViewerComponent } from './portal/message-exec-viewer/message-exec-viewer.component';
import { authToken } from './helpers/auth-token.guard';
import { requiredPrivilege } from './helpers/required-privilege.guard';

//import { CanDeactivateGuard } from './services/safeguard/can-deactivate-guard.service';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' }, 
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_PORTAL_HOME_PAGE'}},  
  { path: 'users', component: UsersComponent, canActivate: [authToken, requiredPrivilege] , data: { privileges: ['PRIV_WAL_USERS_VIEW','PRIV_WAL_USERS_MODIFY']}}, 
  { path: 'portalRoles', component: PortalRolesComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WA_ROLE_VIEW','PRIV_WA_ROLE_MODIFY']}},
  { path: 'portalUsers', component: PortalUsersComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WA_USER_MODIFY','PRIV_WA_USER_VIEW']}},
  { path: 'groupOnboard', component: GroupOnboardComponent, canActivate: [authToken, requiredPrivilege] , data: { privilege: 'PRIV_WA_USER_MODIFY'}}, 
  { path: 'monitoring', component: MonitoringComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_VP_JOB_MONITOR_VIEW','PRIV_VP_EVENT_OCCURANCE_VIEW']}},
  { path: 'brandListing', component: BrandListingComponent, canActivate: [authToken, requiredPrivilege], data: {privileges: ['PRIV_WP_BRAND_VIEW','PRIV_WR_ADHOC_RPT']}}, 
  { path: 'brandMigration', component: BrandMigrationComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_VP_BRAND_MIGRATE'} },  
  { path: 'configProperty', component: ConfigPropertyComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_MASTER_CONFIG_PROP_MODIFY','PRIV_WAL_BRAND_MASTER_CONFIG_PROP_VIEW']} },  
  { path: 'messageProfile', component: MessageProfileComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_CM_SUBSCRIPTION_MODIFY'} }, 
  { path: 'encryptionUtility', component: EncryptionUtilityComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WP_ENCRYPTION'} }, 
  { path: 'enrollmentStats', component: EnrollmentStatsComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WR_STATS'} }, 
  { path: 'clickthroughStats', component: ClickthroughStatsComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WR_STATS'} },
  { path: 'notificationStats', component: NotificationStatsComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WR_STATS'} },
  { path: 'summaryReports', component: SummaryReportsComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WR_RPT'} },  
  { path: 'enrollmentReport', component: EnrollmentReportComponent, canActivate: [authToken, requiredPrivilege], data: {privilege: 'PRIV_VP_STATS'}},
  { path: 'accountInfo', component: AccountInfoComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WAL_ACCOUNT_INFO'}}, 
  { path: 'communicationSubscribers', component: CommunicationSubscribersComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_CM_SUBSCRIPTION_VIEW','PRIV_CM_SUBSCRIPTION_MODIFY']} }, 
  { path: 'testSuite', component: TestSuiteComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_VP_TEST_SUITE_VIEW','PRIV_VP_TEST_SUITE_ADD']} },
  { path: 'brandBootstrap', component: BrandBootstrapComponent, canActivate: [authToken, requiredPrivilege], data: {privilege:'PRIV_VP_BRAND_BOOTSTRAP'}},  
  { path: 'brandVariables', component: BrandVariablesComponent, canActivate: [authToken, requiredPrivilege], data: { privileges:['PRIV_WAL_BRAND_CONFIG_VARIABLE_MODIFY','PRIV_WAL_BRAND_CONFIG_VARIABLE_VIEW']}}, 
  { path: 'brandPassTemplates', component: BrandPassTemplatesComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_PASS_VIEW','PRIV_WAL_BRAND_CONFIG_PASS_MODIFY']}},
  { path: 'messageTemplates', component: MessageTemplatesComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_MSG_TEMPLATE_VIEW','PRIV_WAL_BRAND_CONFIG_MSG_TEMPLATE_MODIFY']}},
  { path: 'brandConfigProperty', component: BrandConfigPropertyComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_PROP_MODIFY','PRIV_WAL_BRAND_CONFIG_PROP_VIEW']}},  
  { path: 'brandOffers', component: BrandOffersComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_OFFER_MODIFY','PRIV_WAL_BRAND_CONFIG_OFFER_VIEW']}},  
//  { path: 'offerFields', component: OfferFieldsComponent, canActivate: [authToken, requiredPrivilege], canDeactivate: [CanDeactivateGuard], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_OFFER_FIELD_VIEW','PRIV_WAL_BRAND_CONFIG_OFFER_FIELD_MODIFY']}},
  { path: 'offerFields', component: OfferFieldsComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_OFFER_FIELD_VIEW','PRIV_WAL_BRAND_CONFIG_OFFER_FIELD_MODIFY']}},
  { path: 'brandImages', component: BrandImagesComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_IMAGE_VIEW','PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY']}},  
  { path: 'enrollmentLinks', component: EnrollmentLinksComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WAL_BRAND_CONFIG_ENROLL_LINKS'}},  
  { path: 'scheduledEvents', component: ScheduledEventsComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_MS_EVENTS_VIEW','PRIV_MS_EVENTS_MODIFY']}},  
  { path: 'emailSmsTemplates', component: EmailSmsTemplatesComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_BRAND_CONFIG_TEMPLATE_VIEW','PRIV_WAL_BRAND_CONFIG_TEMPLATE_MODIFY']}}, 
  { path: 'enrollmentTrend', component: EnrollmentTrendComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WR_STATS'} },
  { path: 'messageBroadcasting', component: MessageBroadcastingComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_MESSAGE_TO_ACCOUNT','PRIV_WAL_ACCOUNT_MESSAGE']} },
  { path: 'accountTasks', component: AccountTasksComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_ACCOUNT_INFO','PRIV_WAL_MESSAGE_TO_ACCOUNT','PRIV_WAL_ACCOUNT_UPDATE','PRIV_WAL_ACCOUNT_MESSAGE','PRIV_WAL_DEMO_SCENARIOS']} }, 
  { path: 'enrollmentInvitation', component: EnrollmentInvitationComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WAL_DEMO_DISTRIBUTION'} }, 
  { path: 'communicationHistory', component: CommunicationHistoryComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_CM_HISTORY_VIEW'} },  
  { path: 'csrAccount', component: CsrAccountComponent, canActivate: [authToken, requiredPrivilege], data: {privilege: 'PRIV_VP_ACCT_CSR'}}, 
  { path: 'userProfile', component: UserProfileComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_PORTAL_USER_PROFILE'}},
  { path: 'brandProfile', component: BrandProfileComponent, canActivate: [authToken, requiredPrivilege], data: { privileges:['PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY','PRIV_WAL_BRAND_INFO']}},
  { path: 'qrcodeGeneration', component: QrcodeGenerationComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_PORTAL_QR_CODE'}},
  { path: 'configMappings', component: ConfigMappingsComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_CONFIG_MAPPING_VIEW','PRIV_WAL_CONFIG_MAPPING_MODIFY']}},
  { path: 'clientDashboard', component: ClientDashboardComponent, canActivate: [authToken, requiredPrivilege], data: { privilege: 'PRIV_WR_STATS'} },
  { path: 'rtpnTransactions', component: RtpnTransactionsComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_VP_RTPN_VIEWER']}}, 
  { path: 'rtpnSimulator', component: RtpnSimulatorComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_RTPN_TEST']}}, 
  { path: 'adhoc-notification', component: AdhocNotificationComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_FILTER_CONFIG_VIEW']}}, 
  { path: 'adhoc-msg-config', component: AdhocMessageConfigComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_FILTER_CONFIG_MODIFY','PRIV_WAL_ADHOC_MSG_TEMPLATE_MODIFY','PRIV_WAL_ADHOC_MSG_CONFIG','PRIV_WAL_MESSAGE_BATCH_EXEC_VIEW','PRIV_WAL_ADHOC_MSG_EXECUTE']}}, 
  { path: 'msg-exec-viewer', component: MessageExecViewerComponent, canActivate: [authToken, requiredPrivilege], data: { privileges: ['PRIV_WAL_MESSAGE_BATCH_EXEC_VIEW','PRIV_WAL_ADHOC_MSG_EXECUTE']}}, 
  { path: 'se-listing', component: ScheduledEventsListingComponent},
    
  { path: '**', pathMatch: 'full', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ 
    // CanDeactivateGuard
  ]  
})
export class AppRoutingModule { }
