import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OfferFieldAttribute } from '../../classes/offer-field-attribute';

@Component({
  // selector: 'app-dynamic-offer-field-property',
  selector: 'app-attribute',
  templateUrl: './dynamic-offer-field-attribute.component.html',
  styleUrls: ['./dynamic-offer-field-attribute.component.css']
})

export class DynamicOfferFieldAttributeComponent implements OnInit {

  @Input() offerFieldAttribute: OfferFieldAttribute<string>;
  @Input() form: FormGroup;
  @Output() changeFieldTypeEvent = new EventEmitter<string>();
  @Output() captureColorEvent = new EventEmitter<string>();
  @Output() showImageEvent = new EventEmitter<string>();
  @Output() changeOfferLinkEvent = new EventEmitter<string>();
  
  get isValid() { 
    if (this.form && this.offerFieldAttribute) {
      if (this.form.controls[this.offerFieldAttribute?.key]) {
        return this.form.controls[this.offerFieldAttribute?.key].valid; 
      }
    }
    return false;
  }

  constructor(
    private fb: FormBuilder
  ) {
    this.offerFieldAttribute = new OfferFieldAttribute();
    this.form = this.fb.group({});
   }

  ngOnInit(): void {
  }

  onChangeEvent(objId: string, data: any) {
    switch(objId) {
      case 'fieldType':
        var fieldType = data;
        this.changeFieldTypeEvent.emit(fieldType);
        break;
      case 'imageUID':
        var objImageUid = data;
        this.showImageEvent.emit(objImageUid);
        break;
      }
  }

  onTextChangeEvent(objId: string, data: any) {
    // console.log(objId,  data);
    switch(objId) {
      case 'offerlink':
        var offerlink = data;
        this.changeOfferLinkEvent.emit(offerlink);
        break;
    }
  }

  onCaptureColor(objId: string, data: any) {
    // console.log(objId,  data);
    var objColor = objId + '|' + data;
    this.captureColorEvent.emit(objColor);
  }

}
