<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded"  [disabled]="isDetailsShown">
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 

<!--   -->

 <!-- Grid with all brand config properties based on the specified selection criteria  -->

<div class="grid">

    <div @flyInOut class="col-9  side-list-content-data" *ngIf="isListShown">
        <p-card header="List of Brand Configuration Properties" subheader="" styleClass="ui-card-light" >

            <p-table #dt [columns]="cols" [value]="brandConfigProperties" selectionMode="single" 
                    sortMode="multiple"  [paginator]="true" [rows]="15" >
                <ng-template pTemplate="caption">
                    List of Brand Config Properties
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            <input *ngSwitchCase="'propertyName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                            <input *ngSwitchCase="'propertyValue'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                        </th>
                        <th></th>
                    </tr>  
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                        <td style="text-align:center">
                            <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Modify brand configuration property" 
                                class="ui-button-info" (click)="onRowEdit(rowData)">
                            </button>
                            <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Delete brand configuration property" 
                                class="ui-button-danger" (click)="onRowDelete(rowData)" *ngIf="isSubmitShown" >
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left" *ngIf="isSubmitShown" >
<!-- 
                        <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button>
 -->
                        <p-button type="submit" label="Add" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="showDialogToAdd()"></p-button>
                        
                    </div>
                </ng-template>    
          </p-table> 
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">list_all</mat-icon>
            </p-footer>
        </p-card>
    </div>

<!-- Brand Config Details dialog -->

    <div @flyInOut class="col-5 side-list-content-data" *ngIf="isDetailsShown">
        <p-card header="Brand Configuration Property Details" subheader="" styleClass="ui-card-light" >
        
            <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">
    
                <div>


                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="brandConfigPropertyId" class="col-fixed label" style="width: 15em">Brand Config Property Id:</label>
                            <div class="col">
                            <input pInputText id="brandConfigPropertyId" type="text" formControlName="brandConfigPropertyId" readonly="true" 
                                autofocus style="width: 10em"/> 
                            </div>
                    </div>   

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="enabled" class="col-fixed label" style="width: 15em">Enabled:</label>
                            <div class="col">
                                <p-checkbox formControlName="enabled" id="enabled" [binary]="true"></p-checkbox>
                            </div>
                    </div>   


                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="propertyName" class="col-fixed label" style="width: 15em">Property Name:</label>
                        <div class="col">
                            <p-dropdown id ="propertyName" [options]="configProperties" formControlName="propertyName"
                                [filter]="true" placeholder="Select property" [editable]="false" [style]="{'width': '80%'}"
                                (onChange)="onConfigPropertyChange()"></p-dropdown>
                            </div>

<!--
                            <p-dropdown id ="propertyName" [options]="configProperties" formControlName="propertyName"
                                [filter]="true" placeholder="Select property" [editable]="false" [style]="{'width': '80%'}"
                                [virtualScroll]="true" [virtualScrollItemSize]="100" [virtualScrollOptions]="vsOptions"
                                (onChange)="onConfigPropertyChange()"></p-dropdown>
                            </div>
-->

                        </div>

                     <div class="field grid">
                        <div class="col-1"></div>
                            <label for="propertyDescription" class="col-fixed label" style="width: 15em">Property Description:</label>
                        <div class="col">
                            <textarea [rows]="1" [cols]="30" pInputTextarea [autoResize]="autoResize" id="propertyDescription" 
                            formControlName="propertyDescription" style="width: 100%;" readonly="true"></textarea>
                        </div>
                     </div>

                     <div class="field grid">
                        <div class="col-1"></div>
                            <label for="propertyValue" class="col-fixed label" style="width: 15em">Property Value:</label>
                        <div class="col">
                            <textarea [rows]="5" [cols]="30" pInputTextarea [autoResize]="autoResize" id="propertyValue" 
                            formControlName="propertyValue" style="width: 100%;"></textarea>
                        </div>
                     </div>

                     <div class="field grid">
                        <div class="col-1"></div>
                            <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                        <div class="col">
                            <input pInputText id="createDate" formControlName="createDate" readonly="true" />
                        </div>
                    </div>

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                        <div class="col">
                            <input pInputText id="lastUpdateDate" formControlName="lastUpdateDate" readonly="true"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                            <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></button>
                            <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button>
 -->
                            <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></p-button>
                            <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>
                                
                        </div> 
                        <div class="col-10" *ngIf="!isSubmitShown">
                            <mat-label>{{resultMsg}}</mat-label>
                        </div>
                    </div>
                               
                </div>
            </form>
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
            </p-footer>
        </p-card>
    
    </div>


</div>

<!-- Brand Config Details dialog -->

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-config-properties"></p-confirmDialog>

