import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { AppConfigService} from '../../services/app-config.service';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class BrandMigrationService {


  // protected portalUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected portalAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected portalUrl = environment.apiServer.portalUrl;
  protected portalAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;

  private brandMigrationUrl = this.portalUrl + '/brand-admin/brand-migration';
  private authTokenUrl = this.portalUrl + '/brand-admin/brand-removal/{brandCode}/token';
  private removeBrandUrl = this.portalUrl + '/brand-admin/brand-removal/{brandCode}';

  private portalHeaders = new HttpHeaders()
    .set('Authorization', this.portalAuth);

  private currUser: string;

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService ) 
    { 
      this.currUser = this.tokenStorageService.getCurrentUser() ? this.tokenStorageService.getCurrentUser().userName : '';
      this.portalHeaders = this.portalHeaders.append('mobills-user', this.currUser);     
    }

  migrateBrand(brandMigration: any) {
      var portalHeaders = new HttpHeaders()
      .set('Authorization', this.portalAuth)
      .set('mobills-user', this.currUser);
      // .set('Content-Type', 'text/plain; charset=utf-8');
      return this.http.post<any>(this.brandMigrationUrl, brandMigration, {headers: portalHeaders, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  getAuthToken(brandCode: string): Observable<any> {
    var url = this.authTokenUrl.replace('{brandCode}', brandCode);
    var portalHeaders = new HttpHeaders()
    // .set('Authorization', this.portalAuth)
    // .set('mobills-user', this.currUser)
    .set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, {headers: portalHeaders, responseType: 'text'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // removeBrand(brandCode, authToken): Observable<any> {
  //   var url = this.removeBrandUrl.replace('{brandCode}', brandCode);
  //   var portalHeaders = new HttpHeaders()
  //   .set('mobills-user', authToken);
  //   // .set('mobills-user', this.currUser);
  //   return this.http.delete(url, {headers: portalHeaders, responseType: 'text'})
  //   .pipe(
  //     catchError((err) => {
  //       return throwError(err);
  //     })
  //   )
  // }

  removeBrand(brandCode: string): Observable<any> {
    var url = this.removeBrandUrl.replace('{brandCode}', brandCode);
    return this.http.delete(url, {responseType: 'text'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

}

