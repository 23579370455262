export class AccountField <T> {
    value?: T;
    key: string;
    label: string;
    options: {label: string, value: string}[];
  
    constructor(options: {
        value?: T;
        key?: string;
        label?: string;
        options?: {label: string, value: string}[];
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label === undefined ? '' : options.label + ':';
      this.options = options.options || [];
    }
}