import { Component, OnInit } from '@angular/core';
import { Validators,FormControl,FormGroup,FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { BrandMigrationService } from "../../services/construct/brand-migration.service";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { LookupService} from '../../services/config/lookup.service';
import { IBrandMigration } from "../../interfaces/brand-migration";

@Component({
  selector: 'app-brand-migration',
  templateUrl: './brand-migration.component.html',
  styleUrls: ['./brand-migration.component.css']
})
export class BrandMigrationComponent implements OnInit {

  userform!: FormGroup;
  isDisabled = false;
  isSubmitShown = false;
  resultMsg = "";
  sourceRegions: any[] = [];  
  targetRegions: any[] = [];  
  isSpinnerHidden: boolean = true;

  constructor(
    private fb: FormBuilder, 
    private brandMigrationService: BrandMigrationService,
    private lookupService: LookupService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService  ) { }

  ngOnInit(): void {

      // Hide submit button for users with read-only access type
      if (this.userContextService.userHasPrivilege('PRIV_VP_BRAND_MIGRATE')) {
        this.isSubmitShown = true;
      }

      let sourceRegionsList = this.lookupService.getSourceRegions();
      let targetRegionsList = this.lookupService.getTargetRegions();
      sourceRegionsList.forEach(region => {
        this.sourceRegions.push({label: region, value: region})
      });
      targetRegionsList.forEach(region => {
        this.targetRegions.push({label: region, value: region})
      });

    //  this.envRegions = [
    //   {label: "qa", value: "qa"}, {label: "uat", value: "uat"}, {label: "demo", value: "demo"},
    //   {label: "client2prod", value: "client2prod"}, {label: "mbprod", value: "mbprod"}, {label: "mobills2prod", value: "mobills2prod"},
    //   {label: "prod", value: "prod"}, {label: "wuprod", value: "wuprod"}];

      this.userform = this.fb.group({
        'brandCode': new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]),
        'sourceRegion': new FormControl(this.sourceRegions[0].value, [Validators.required]),
        'targetRegion': new FormControl(this.targetRegions[0].value, [Validators.required]),
        'gwIssuerId': new FormControl('', [Validators.required, Validators.maxLength(45)]),
        'passTypeId': new FormControl('', [Validators.required, Validators.maxLength(100)]),
        'keyName': new FormControl('', [Validators.required, Validators.maxLength(40)]),
        'keyFile': new FormControl('', [Validators.required, Validators.maxLength(4)]),
      });
  }

  onSubmit(value: string) {
    // populate brandProfile variable
    let brandMigration: IBrandMigration = {
      brandCode: this.userform.value.brandCode,
      sourceRegion: this.userform.value.sourceRegion,
      targetRegion: this.userform.value.targetRegion, 
      gwIssuerId: this.userform.value.gwIssuerId,
      keyName: this.userform.value.keyName,
      keyFile: this.userform.value.keyFile,
      passTypeId: this.userform.value.passTypeId,
    };
    if (brandMigration.sourceRegion == brandMigration.targetRegion) {
      this.alertTheUser('Source and target regions must be different.');
      return;
    }

    // Call the service to invoke a Web API call
    this.isSpinnerHidden = false;
    this.brandMigrationService.migrateBrand(brandMigration)
    .subscribe({
      next: (response) => {
        this.isSpinnerHidden = true;
        this.isDisabled = true;
        this.isSubmitShown = false;
        this.alertTheUser(response.message);
      },
      error: (error) => {
        this.isSpinnerHidden = true;
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }


  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-migration',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }


}
