<h2>Monitoring Jobs and Events</h2>

<p-tabView  orientation="top">

    <!--  J O B S  S U M M A R Y   P A N E L  -->

  <p-tabPanel header="Jobs Summary" leftIcon="pi pi-list" *ngIf="isSummaryShown">

    <div class="grid">
      <div class="col-8">
        <p-chart type="bar" [data]="jobSummary" [options]="optionsSummary"></p-chart>
        <p-button label="Refresh" icon="pi refresh" iconPos="left" (click)="update($event)" 
          styleClass="p-button-success p-button-raised p-button-rounded" ></p-button>
      </div>
      <div class="col-1"></div>
      <div class="col-2">
        <p-chart type="doughnut" [data]="jobStatus" [options]="optionsStatus"></p-chart>
        <p-chart type="doughnut" [data]="environmentCount" [options]="optionsEnvironmentCount"></p-chart>
      </div>
    </div>
    
  </p-tabPanel>

  <!--  J O B   E X E C U T I O N   P A N E L  -->

  <p-tabPanel header="Job Executions" leftIcon="pi pi-tags">

    <!--  J O B   E X E C U T I O N   C R I T E R I A  -->
    <div class="grid">
      <div class="col-10">

        <p-dropdown id ="envRegion" [options]="envRegions" [(ngModel)]="selectedRegion" styleClass="prime-dropdown" 
          (onChange)="onChangeEnvRegion($event)"
          [filter]="false" placeholder="Region:" [editable]="false" [style]="{'width':'7%'}">
      </p-dropdown>
        <p-dropdown id ="brandCode" [options]="brandCodes" [(ngModel)]="selectedBrandCode" [filter]="true" 
            styleClass="prime-dropdown" placeholder="Brand Code:" [editable]="false"  class="margin-left" [style]="{'width':'7%'}">
          </p-dropdown>
          <p-dropdown id ="jobCode" [options]="jobCodes" [(ngModel)]="selectedJobCode" [filter]="true" 
            styleClass="prime-dropdown" placeholder="Job Code:" [editable]="false"  class="margin-left" [style]="{'width':'10%'}">
          </p-dropdown>

          <!-- <p-dropdown id ="jobCategory" [options]="jobCategories" [(ngModel)]="selectedJobCategory" [filter]="true" 
          styleClass="prime-dropdown" placeholder="Job Category:" [editable]="false"  class="margin-left"></p-dropdown> -->

          <label for="jobDateFrom" class="margin-left">From: </label>
          <p-calendar id="jobDateFrom" [(ngModel)]="selectedJobDateFrom" dateFormat="yy-mm-dd" placeholder="Date From:"
           [showIcon]="true" placeholder="From">
          </p-calendar>

          <label for="jobDateTo" class="margin-left">To: </label>
          <p-calendar id="jobDateTo" [(ngModel)]="selectedJobDateTo" dateFormat="yy-mm-dd" placeholder="Date To:"
           [showIcon]="true" placeholder="To">
          </p-calendar>

          <!-- <div class="col-2">
          </div>
          <div class="col-4">
        </div> -->
        <label for="isIncludedMaintenenceJobs" class="margin-left">Maintenance: </label>
        <p-checkbox [(ngModel)]="isIncludedMaintenenceJobs" id="enabled" [binary]="true"></p-checkbox>

          <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onJobSearchClick($event)"></p-button>
          <p-button label="Next" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onJobNextClick($event)"></p-button>
          <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onJobClearClick($event)"></p-button>

      </div>

      <div class="col-2" id="idSpinner" [hidden]="isJobsSpinnerHidden">
        <div class="loader"></div>  
      </div>

    </div>  

    <!--  J O B   E X E C U T I O N   G R I D  -->

    <div class="grid">
      <div class="col-8">

        <p-table #dtJobs [columns]="jobCols" [value]="jobs" selectionMode="single" [(selection)]="selectedJob" 
            tableStyleClass="prime-table" [rowsPerPageOptions]="[10,15,25,50]" [showCurrentPageReport]="true"
            sortMode="multiple" (onRowSelect)="onJobRowSelect($event)" [paginator]="true" [rows]="15">
          <ng-template pTemplate="caption">
            List of Jobs Execution
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>

            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  <input *ngSwitchCase="'brandCode'" pInputText type="text" (input)="dtJobs.filter($any($event.target).value, 'brandCode', 'contains')"  [style]="{'width':'50%'}">
                    <!-- [value]="dtJobs.filters['brandCode'] ? dtJobs.filters['brandCode'].value : ''"> -->
                  <input *ngSwitchCase="'jobName'" pInputText type="text" (input)="dtJobs.filter($any($event.target).value,'jobName', 'contains')"  [style]="{'width':'50%'}">
                    <!-- [value]="dtJobs.filters['jobName'] ? dtJobs.filters['jobName']?.value : ''"> -->
                  <input *ngSwitchCase="'status'" pInputText type="text" (input)="dtJobs.filter($any($event.target).value, 'status', 'contains')" [style]="{'width':'50%'}">
                    <!-- [value]="dtJobs.filters['status'] ? dtJobs.filters['status']?.value : ''"> -->
                  <input *ngSwitchCase="'environment'" pInputText type="text" (input)="dtJobs.filter($any($event.target).value, 'environment', 'contains')"  [style]="{'width':'50%'}">
                    <!-- [value]="dtJobs.filters['environment'] ? dtJobs.filters['environment']?.value : ''"> -->
                  <input *ngSwitchCase="'host'" pInputText type="text" (input)="dtJobs.filter($any($event.target).value, 'host', 'contains')" [style]="{'width':'50%'}">
                    <!-- [value]="dtJobs.filters['host'] ? dtJobs.filters['host']?.value : ''"> -->
                  <input *ngSwitchCase="'exitCode'" pInputText type="text" (input)="dtJobs.filter($any($event.target).value, 'exitCode', 'contains')" [style]="{'width':'50%'}">
                    <!-- [value]="dtJobs.filters['exitCode'] ? dtJobs.filters['exitCode']?.value : ''"> -->
              </th>
            </tr>
 
        </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary" let-rowData>
            <div style="text-align:left">
            </div>
          </ng-template>    
        </p-table> 

      </div>
    </div>

  </p-tabPanel>

  <!-- E V E N T   O C C U R R E N C E   P A N E L  -->

  <p-tabPanel header="Event Occurences" leftIcon="pi pi-bell">

    <!-- E V E N T   O C C U R R E N C E   C R I T E R I A  -->
    <div class="grid">
      <div class="col-7">

          <p-dropdown id ="eventName" [options]="eventNames" [(ngModel)]="selectedEventName" [filter]="true" 
          styleClass="prime-dropdown" placeholder="Event Name:" [editable]="false" class="margin-left"></p-dropdown>

          <label for="eventDateFrom" class="margin-left"> From:</label>
          <p-calendar id="eventDateFrom" [(ngModel)]="selectedEventDateFrom" dateFormat="yy-mm-dd" placeholder="Date From:" [showIcon]="true" class="margin-left"></p-calendar>
          <label for="eventDateTo" class="margin-left"> To:</label>
          <p-calendar id="eventDateTo" [(ngModel)]="selectedEventDateTo" dateFormat="yy-mm-dd" placeholder="Date To:" [showIcon]="true" class="margin-left"></p-calendar>

          <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onEventSearchClick($event)"></p-button>
          <p-button label="Next" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onEventNextClick($event)"></p-button>
          <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onEventClearClick($event)"></p-button>

        </div> 

        <div class="col-2" id="idSpinner" [hidden]="isEventsSpinnerHidden">
          <div class="loader"></div>  
        </div>

    </div>  

    <!-- E V E N T   O C C U R R E N C E   G R I D  -->

      <div class="grid">
        <div class="col-8">

          <p-table #dtEvents [columns]="eventCols" [value]="events" selectionMode="single" [(selection)]="selectedEvent" 
              tableStyleClass="prime-table" [rowsPerPageOptions]="[10,15,25,50]" [showCurrentPageReport]="true"
              sortMode="multiple" (onRowSelect)="onEventRowSelect($event)" [paginator]="true" [rows]="15">
            <ng-template pTemplate="caption">
              List of Event Occurrences
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  <input *ngSwitchCase="'eventName'" pInputText type="text" (input)="dtEvents.filter($any($event.target).value, 'eventName', 'contains')">
                    <!-- [value]="dtEvents.filters['eventName'] ? dtEvents.filters['eventName'].value : ''"> -->
                  <input *ngSwitchCase="'sourceEnvironment'" pInputText type="text" (input)="dtEvents.filter($any($event.target).value, 'sourceEnvironment', 'contains')">
                    <!-- [value]="dtEvents.filters['sourceEnvironment'] ? dtEvents.filters['sourceEnvironment'].value : ''"> -->
                </th>
              </tr>  
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary" let-rowData>
              <div style="text-align:left">
              </div>
            </ng-template>    
          </p-table> 

        </div>
      </div>
    
  </p-tabPanel>

  <p-tabPanel header="Task Hierarchy" leftIcon="pi pi-sitemap">

    <div style="margin-bottom: 1rem">
      <button pButton type="button" label="Expand all" (click)="expandAll()" style="margin-right: .5rem" class="ui-button-success"></button>
      <button pButton type="button" label="Collapse all" (click)="collapseAll()" class="ui-button-info"></button>
    </div>
    <p-tree [value]="taskTree" [styleClass]="'task-tree'"></p-tree>

  </p-tabPanel>

</p-tabView>


<!-- E V E N T   O C C U R R E N C E   D E T A I L S  -->

<p-dialog appendTo="body" header="Event Occurance Details" [(visible)]="eventDisplayDialog" [focusOnShow]="false" 
    [responsive]="true" showEffect="fade" [modal]="true"  [style]="{width: '600px'}">
  <div class="grid ui-fluid" *ngIf="event">

    <div class="field grid">
      <label for="scheduledEventOccurrenceId" class="col-fixed label" style="width: 15em">Event Occurrence Id:</label>
      <div class="col">
          <input pInputText id="scheduledEventOccurrenceId" [(ngModel)]="event.scheduledEventOccurrenceId" autofocus readonly>
      </div>
    </div>      

    <div class="field grid">
      <label for="eventName" class="col-fixed label" style="width: 15em">Event Name:</label>
      <div class="col">
          <input pInputText id="eventName" [(ngModel)]="event.eventName" readonly style="width: 25em"/>
      </div>
    </div>      

    <div class="field grid">
      <label for="eventDescription" class="col-fixed label" style="width: 15em">Event Description:</label>
      <div class="col">
          <input pInputText id="eventDescription" [(ngModel)]="event.eventDescription" readonly style="width: 25em">
      </div>
    </div>      

    <div class="field grid">
      <label for="sourceEnvironment" class="col-fixed label" style="width: 15em">Env. Region:</label>
      <div class="col">
          <input pInputText id="sourceEnvironment" [(ngModel)]="event.sourceEnvironment" readonly>
      </div>
    </div>      

    <div class="field grid">
      <label for="sourceHost" class="col-fixed label" style="width: 15em">Source Host:</label>
      <div class="col">
          <input pInputText id="sourceHost" [(ngModel)]="event.sourceHost" readonly>
      </div>
    </div>      

    <div class="field grid">
      <label for="eventTimestamp" class="col-fixed label" style="width: 15em">Event Timestamp:</label>
      <div class="col">
          <input pInputText id="eventTimestamp" [(ngModel)]="event.eventTimestamp"  readonly>
      </div>
    </div>      

    <div class="field grid">
      <label for="eventAttributes" class="col-fixed label" style="width: 15em">Event Attributes:</label>
      <div class="col">
          <textarea [rows]="3" [cols]="30" pInputTextarea autosize id="eventAttributes" [(ngModel)]="event.eventAttributes" 
            readonly style="width: 25em"></textarea>
       </div>
    </div>      

</div>
  <p-footer>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <p-button label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="eventCancel()"></p-button>
      </div>
  </p-footer>
</p-dialog>

 <!--  J O B   E X E C U T I O N   D E T A I L S  -->

 <p-dialog appendTo="body" header="Job Details" [(visible)]="jobDisplayDialog" [focusOnShow]="false" 
    [responsive]="true" showEffect="fade" [modal]="true"  [style]="{width: '50%'}">
  <div class="grid ui-fluid" *ngIf="job">

    <p-tabView  orientation="top">

      <p-tabPanel header="Details" leftIcon="pi pi-volume-up">
 
        <div class="field grid">
          <label for="jobUuid" class="col-fixed label" style="width: 15em">Job Uuid:</label>
          <div class="col">
              <input pInputText id="jobUuid" [(ngModel)]="job.jobUuid" autofocus readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="jobUuid" class="col-fixed label" style="width: 15em">Job Code:</label>
          <div class="col">
              <input pInputText id="jobUuid" [(ngModel)]="job.jobUuid" readonly style="width: 40em">
          </div>
        </div>      

        <div class="field grid">
          <label for="jobName" class="col-fixed label" style="width: 15em">Job Name:</label>
          <div class="col">
              <input pInputText id="jobName" [(ngModel)]="job.jobName"  readonly style="width: 40em">
          </div>
        </div>      

        <div class="field grid">
          <label for="jobDescription" class="col-fixed label" style="width: 15em">Job Description:</label>
          <div class="col">
              <input pInputText id="jobDescription" [(ngModel)]="job.jobDescription" style="width: 40em" readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="brandCode" class="col-fixed label" style="width: 15em">Brand Code:</label>
          <div class="col">
              <input pInputText id="brandCode" [(ngModel)]="job.brandCode"  readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="status" class="col-fixed label" style="width: 15em">Job Status:</label>
          <div class="col">
              <input pInputText id="status" [(ngModel)]="job.status" readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="environment" class="col-fixed label" style="width: 15em">Env. Region:</label>
          <div class="col">
              <input pInputText id="environment" [(ngModel)]="job.environment" readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="host" class="col-fixed label" style="width: 15em">Host:</label>
          <div class="col">
              <input pInputText id="host" [(ngModel)]="job.host"  readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="startTimestamp" class="col-fixed label" style="width: 15em">Started:</label>
          <div class="col">
              <input pInputText id="startTimestamp" [(ngModel)]="job.startTimestamp"  readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="endTimestamp" class="col-fixed label" style="width: 15em">Finished:</label>
          <div class="col">
              <input pInputText id="endTimestamp" [(ngModel)]="job.endTimestamp"  readonly>
          </div>
        </div>      

        <div class="field grid">
          <label for="exitMessage" class="col-fixed label" style="width: 15em">Exit Message:</label>
          <div class="col">
              <input pInputText id="exitMessage" [(ngModel)]="job.exitMessage"  readonly>
          </div>
        </div>      

      </p-tabPanel>

      <p-tabPanel header="Steps" leftIcon="pi pi-sort-amount-down">

        <p-table #dt [columns]="stepCols" [value]="steps" selectionMode="single" 
            tableStyleClass="prime-table" dataKey="seqNo"
            sortMode="multiple" (onRowSelect)="onStepRowSelect($event)" [paginator]="true" [rows]="5">
          <ng-template pTemplate="caption">
            List of Job Steps
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 2.25em"></th>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr [pSelectableRow]="rowData">
              <td [ngStyle]="{'width': '5%'}">
                  <button type="button" pButton pRipple [pRowToggler]="rowData" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{rowData[col.field]}}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
            <tr>
                <td [attr.colspan]="columns.length + 1">
                    <div class="ui-g ui-fluid" style="font-size:11px;padding:7px">
                        <div class="ui-g-12 ui-md-9">
                            <div class="grid">
                              <div class="col-12">
                                <b>Parameters:</b> {{rowData.parameters}}
                            </div>
                            <div class="col-12">
                                    <b>Additional Info:</b> {{rowData.additionalInfo}}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
          </ng-template>
        </p-table> 

        <p-table #dt [columns]="chunkCols" [value]="chunks" selectionMode="single"  dataKey="seqNo"
            tableStyleClass="prime-table" sortMode="multiple"  [paginator]="true" [rows]="5">
          <ng-template pTemplate="caption">
            List of Step Chunks
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 2.25em"></th>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr [pSelectableRow]="rowData">
                <td [ngStyle]="{'width': '5%'}">
                  <button type="button" pButton pRipple [pRowToggler]="rowData" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{rowData[col.field]}}
                </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
            <tr>
                <td [attr.colspan]="columns.length + 1">
                    <div class="ui-g ui-fluid" style="font-size:11px;padding:7px">
                        <div class="ui-g-12 ui-md-9">
                            <div class="grid">
                              <div class="col-12">
                                <b>Parameters:</b> {{rowData.parameters}}
                            </div>
                            <div class="col-12">
                                    <b>Additional Info:</b> {{rowData.additionalInfo}}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
          </ng-template>
        </p-table> 

                <!-- <div class="row">

          <div class="col-6">
            <p-table #dt [columns]="stepParametersCols" [value]="stepParameters" selectionMode="single" [responsive]="true"
              tableStyleClass="prime-table" sortMode="multiple"  [paginator]="true" [rows]="5" sortOrder="1">
              <ng-template pTemplate="caption">
                Step's Parameters
              </ng-template> 
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{rowData[col.field]}}
                    </td>
                </tr>
              </ng-template>
            </p-table> 
          </div>
  
          <div class="col-6">
            <p-table #dt [columns]="stepAdditionalInfoCols" [value]="stepAdditionalInfo" selectionMode="single" [responsive]="true"
              tableStyleClass="prime-table" sortMode="multiple"  [paginator]="true" [rows]="5" sortOrder="1">
              <ng-template pTemplate="caption">
                Step's Additional Info
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{rowData[col.field]}}
                    </td>
                </tr>
              </ng-template>
            </p-table> 
          </div> 

        </div> -->

      </p-tabPanel>

      <p-tabPanel header="Parameters" leftIcon="pi pi-list">

        <div class="col-7">
          <p-table #dt [columns]="paramCols" [value]="params" selectionMode="single" 
              tableStyleClass="prime-table" sortMode="multiple" [paginator]="true" [rows]="10">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
            </ng-template>
          </p-table> 
        </div>
      </p-tabPanel>

      <p-tabPanel header="Additional Info" leftIcon="pi pi-info">

        <div class="col-8">
          <p-table #dt [columns]="paramCols" [value]="additionalInfo" selectionMode="single" 
              tableStyleClass="prime-table" sortMode="multiple" [paginator]="true" [rows]="10">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
            </ng-template>
          </p-table> 
      </div>

      </p-tabPanel>

    </p-tabView>
  
  </div>


  <p-footer>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <p-button label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="jobCancel()"></p-button>
      </div>
  </p-footer>
</p-dialog>

<p-confirmDialog  class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>


<!-- <p-accordion>
    <p-accordionTab header="Scheduled Events" [selected]="true">

    
    </p-accordionTab>

    <p-accordionTab header="Scheduled Jobs">
        Content 2
    </p-accordionTab>
</p-accordion>
 -->