import { Injectable } from '@angular/core';
import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase } from '../../classes/offer-field-base';
import { OfferFieldBackField } from '../../classes//offer-field-types/offer-field-back-field';
import { OfferFieldBarCode } from '../../classes/offer-field-types/offer-field-bar-code';
import { OfferFieldCustomJson } from '../../classes/offer-field-types/offer-field-custom-json';
import { OfferFieldOnlyFieldValue } from '../../classes/offer-field-types/offer-field-only-field-value';
import { OfferFieldBillPay } from '../../classes/offer-field-types/offer-field-bill-pay';
import { OfferFieldDelayMyPayment } from '../../classes/offer-field-types/offer-field-delay-my-payment';
import { OfferFieldDisplayableField } from '../../classes/offer-field-types/offer-field-displayable-field';
import { OfferFieldImage } from '../../classes/offer-field-types/offer-field-image';
import { OfferFieldGwAccountLabel } from '../../classes/offer-field-types/offer-field-gw-account-label';
import { OfferFieldGwAccountName } from '../../classes/offer-field-types/offer-field-gw-account-name';
import { OfferFieldEnrollAuthParam } from '../../classes/offer-field-types/offer-field-enroll-auth-param';
import { OfferFieldLabelColor } from '../../classes/offer-field-types/offer-field-label-color';
import { OfferFieldMsgContainer } from '../../classes/offer-field-types/offer-field-msg-container';
import { OfferFieldNotifPrefEditor } from '../../classes/offer-field-types/offer-field-notif-pref-editor';
import { OfferFieldNotificationScheduleDescriptor } from '../../classes/offer-field-types/offer-field-notification-schedule-descriptor';
import { OfferFieldOnlyImage } from '../../classes/offer-field-types/offer-field-only-image';
import { OfferFieldOnlyTemplate } from '../../classes/offer-field-types/offer-field-only-template';
import { OfferFieldPersonalizedImage } from '../../classes/offer-field-types/offer-field-personalized-image';
import { OfferFieldRtpnBarcode } from '../../classes/offer-field-types/offer-field-rtpn-barcode';
import { OfferFieldSingleSignOn } from '../../classes/offer-field-types/offer-field-single-sign-on';
import { OfferFieldSmsPhoneEditor } from '../../classes/offer-field-types/offer-field-sms-phone-editor';
import { OfferFieldSmsPrepend } from '../../classes/offer-field-types/offer-field-sms-prepend';
import { OfferFieldTextColor } from '../../classes/offer-field-types/offer-field-text-color';
import { OfferFieldTriggeredAction } from '../../classes/offer-field-types/offer-field-triggered-action';
import { OfferFieldVariableField } from '../../classes/offer-field-types/offer-field-variable-field';
import { OfferFieldWalPage } from '../../classes/offer-field-types/offer-field-wal-page';

@Injectable({
  providedIn: 'root'
})
export class OfferFieldFactoryService {

  fieldformatOptions!: any[];

  constructor() { }

  public getOfferFieldAttributesObject(offerFieldData: IOfferField, offerFieldTypes: any[], 
      brandVariables?: any[], notifSchedDescrTypes?: any[], brandImages?: any[], emailSmsTemplates?: any[], linkCategories?: any []) {
    var offerFieldObj: OfferFieldBase;
    switch(offerFieldData.fieldType) { 
      // Back Field
      case 'backFields': { 
        offerFieldObj = new OfferFieldBackField(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions(), 
          this.getFieldFormatOptions (), linkCategories || []);
        break; 
      } 
      // Bar Code
      case 'barCode': { 
        offerFieldObj = new OfferFieldBarCode(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Apple Wallet Sharing, Bar Code Text, Document Viewer, WOB Refresher, Template Selector, Notification Schedule Item
      case 'awShare':
      case 'barCodeAlt': 
      case 'docViewer':
      case 'notifSchedItem':
      case 'refreshWobFld':
      case 'templateSelector':
      { 
        offerFieldObj = new OfferFieldOnlyFieldValue(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Bill Payment
      case 'billPay': { 
        offerFieldObj = new OfferFieldBillPay(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions(), 
          this.getFieldFormatOptions (), linkCategories || []);
        break; 
      } 
      // Customer Rendered
      case 'customJsonField': { 
        offerFieldObj = new OfferFieldCustomJson(offerFieldData, offerFieldTypes, brandVariables || [], 
          this.getFieldAlignmentOptions(), this.getFieldFormatOptions());
        break; 
      } 
      // Delay My Payment
      case 'dmpField': { 
        offerFieldObj = new OfferFieldDelayMyPayment(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions());
        break; 
      } 
      // Enrollment Authorization Parameter
      case 'enrollAuthParam': { 
        offerFieldObj = new OfferFieldEnrollAuthParam(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Foreground text color, Background text color
      case 'fgColorField':
      case 'bgColorField': { 
        offerFieldObj = new OfferFieldTextColor(offerFieldData, offerFieldTypes, brandVariables || []);
        break; 
      }
      // Google Pay Account ID
      case 'gwAccountIdLabel': { 
        offerFieldObj = new OfferFieldGwAccountLabel(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions());
        break; 
      } 
      // Google Pay Account Name
      case 'gwAccountName': { 
        offerFieldObj = new OfferFieldGwAccountName(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Google Pay Landing Page Logo, Non-mobile Page Logo
      case 'gwLandPageLogoOverride': 
      case 'pbNonmobilePageLogoOverride': { 
        offerFieldObj = new OfferFieldOnlyImage(offerFieldData, offerFieldTypes, brandImages || []);
        break; 
      } 
      // Header, Primary Field, Secondary Field, Auxiliary Field 
      case 'headerFields':
      case 'primaryFields':
      case 'secondaryFields':
      case 'auxiliaryFields': { 
        offerFieldObj = new OfferFieldDisplayableField(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions(), 
          this.getFieldFormatOptions (), linkCategories || []);
        break; 
      }
      // Background Image, Strip Image, Thumbnail Image
      case 'BackgroundImage':
      case 'StripImage': 
      case 'ThumbnailImage': { 
        offerFieldObj = new OfferFieldImage(offerFieldData, offerFieldTypes, brandImages || []);
        break; 
      }
      // Foreground Label Color
      case 'fgLblColorField': { 
        offerFieldObj = new OfferFieldLabelColor(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Message Container
      case 'msgField': { 
        offerFieldObj = new OfferFieldMsgContainer(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Non-mobile Email Template Override, Non-mobile SMS Template Override
      case 'pbNonmobileTemplateOverride':
      case 'pbNonmobileSMSTemplateOverride': {
        const templateType = offerFieldData.fieldType == 'pbNonmobileTemplateOverride' ?  'E' : 'S';
        offerFieldObj = new OfferFieldOnlyTemplate(offerFieldData, offerFieldTypes, emailSmsTemplates || [], templateType);
        break; 
      } 
      // Notification Preferences Editor
      case 'notifPrefEditor': { 
        offerFieldObj = new OfferFieldNotifPrefEditor(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions(), 
          this.getFieldFormatOptions (), linkCategories || []);
        break; 
      } 
      // Notification Scheduler Descriptor
      case 'notifSchedDescr': { 
        offerFieldObj = new OfferFieldNotificationScheduleDescriptor(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Personalized Image
      case 'PersonalizedImage': { 
        offerFieldObj = new OfferFieldPersonalizedImage(offerFieldData, offerFieldTypes);
        break; 
      }
      // RTPN Barcode Field 
      case 'rtpnBarcodeField': { 
        offerFieldObj = new OfferFieldRtpnBarcode(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions(), linkCategories || []);
        break; 
      }
      // Single Sign On, Disbursement link field
      case 'dsbLinkField':
      case 'ssoField': { 
        offerFieldObj = new OfferFieldSingleSignOn(offerFieldData, offerFieldTypes, this.getFieldAlignmentOptions(), 
          this.getFieldFormatOptions (), linkCategories || []);
        break; 
      } 
      // SMS Phone Editor
      case 'smsPhoneEditor': { 
        offerFieldObj = new OfferFieldSmsPhoneEditor(offerFieldData, offerFieldTypes);
        break; 
      } 
      // SMS Prepend
      case 'smsPrepend': { 
        offerFieldObj = new OfferFieldSmsPrepend(offerFieldData, offerFieldTypes);
        break; 
      } 
      // Triggered Action
      case 'trigActionField': { 
        offerFieldObj = new OfferFieldTriggeredAction(offerFieldData, offerFieldTypes, notifSchedDescrTypes || []);
        break; 
      } 
      // Variable Field
      case 'filterData': { 
        offerFieldObj = new OfferFieldVariableField(offerFieldData, offerFieldTypes, brandVariables || [], linkCategories || []);
        break; 
      } 
      // Walletron Pages
      case 'walPagesField': { 
        offerFieldObj = new OfferFieldWalPage(offerFieldData, offerFieldTypes, linkCategories || []);
        break; 
      } 
      default: { 
        offerFieldObj = new OfferFieldBase(offerFieldData, offerFieldTypes);
         break; 
      } 
    } 
    return offerFieldObj;
  }

  private getFieldAlignmentOptions() {
    return [
        {label: '[none]', value: ''},
        {label: 'Left',  value: 'PKTextAlignmentLeft'},
        {label: 'Center',  value: 'PKTextAlignmentCenter'},
        {label: 'Right',   value: 'PKTextAlignmentRight'},
        {label: 'Natural', value: '[none]'}
    ]
  }

  private getFieldFormatOptions () {
    return [
      {label: '[none]', value: ''},
      {label: 'String',  value: 'String'},
      {label: 'Date - None',  value: 'PKDateStyleNone'},
      {label: '"Date - Short',  value: 'PKDateStyleShort'},
      {label: 'Date - Medium',  value: 'PKDateStyleMedium'},
      {label: 'Date- Long',  value: 'PKDateStyleLong'},
      {label: 'Date - Full',  value: 'PKDateStyleFull'},
      {label: 'Decimal',  value: 'PKNumberStyleDecimal'},
      {label: 'Percent',  value: 'PKNumberStylePercent'},
      {label: 'Scientific',  value: 'PKNumberStyleScientific'},
      {label: 'Natural',  value: 'PKNumberStyleSpellOut'},
      {label: 'Currency - USD',  value: 'USD'},
      {label: 'Currency - GBP',  value: 'GBP'},
      {label: 'Currency - Euro',  value: 'Euro'},
      {label: 'Phone',  value: 'Phone'},
      {label: '[none]', value: '[none]'}
    ]
  }

}

