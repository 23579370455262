<div class="col-2 float-right">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="ui-card-powder">
        <button mat-menu-item routerLink="/brandProfile" *ngIf="hasAllPrivileges(['PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY','PRIV_WAL_BRAND_INFO']) && parentForm != 'BrandProfile'">
            <mat-icon>domain</mat-icon>
            <span>Brand Profile</span>
        </button>
        <button mat-menu-item routerLink="/brandPassTemplates" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_PASS_VIEW','PRIV_WAL_BRAND_CONFIG_PASS_MODIFY']) && parentForm != 'BrandPassTemplates'">
            <mat-icon>table_chart</mat-icon>
            <span>Pass Templates</span>
        </button>
        <button mat-menu-item routerLink="/messageTemplates" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_MSG_TEMPLATE_VIEW','PRIV_WAL_BRAND_CONFIG_MSG_TEMPLATE_MODIFY']) && parentForm != 'MessageTemplates'">
            <mat-icon>tap_and_play</mat-icon>
            <span>Message Templates</span>
        </button>
        <button mat-menu-item routerLink="/brandVariables" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_VARIABLE_MODIFY','PRIV_WAL_BRAND_CONFIG_VARIABLE_VIEW']) && parentForm != 'BrandVariables'">
            <mat-icon>developer_board</mat-icon>
            <span>Brand Variables</span>
        </button>
        <button mat-menu-item routerLink="/brandOffers" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_OFFER_MODIFY','PRIV_WAL_BRAND_CONFIG_OFFER_VIEW']) && parentForm != 'BrandFieldSets'">
            <mat-icon>format_list_numbered_rtl</mat-icon>
            <span>Brand Field Sets</span>
        </button>
        <button mat-menu-item routerLink="/brandConfigProperty" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_PROP_MODIFY','PRIV_WAL_BRAND_CONFIG_PROP_VIEW']) && parentForm != 'BrandConfigProperty'">
            <mat-icon>category</mat-icon>
            <span>Brand Config Property</span>
        </button>
        <button mat-menu-item routerLink="/brandImages" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_IMAGE_VIEW','PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY']) && parentForm != 'BrandImages'">
            <mat-icon>camera_alt</mat-icon>
            <span>Brand Images</span>
        </button>
        <button mat-menu-item routerLink="/emailSmsTemplates" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_TEMPLATE_VIEW','PRIV_WAL_BRAND_CONFIG_TEMPLATE_MODIFY']) && parentForm != 'EmailSmsTemplates'">
            <mat-icon>email</mat-icon>
            <span>Email/SMS Templates</span>
        </button>
        <button mat-menu-item routerLink="/configMappings" *ngIf="hasAnyPrivilege(['PRIV_WAL_CONFIG_MAPPING_VIEW','PRIV_WAL_CONFIG_MAPPING_MODIFY']) && parentForm != 'ConfigMappings'">
            <mat-icon>view_comfy</mat-icon>
            <span>Config Mappings</span>
        </button>
        <button mat-menu-item routerLink="/enrollmentLinks" *ngIf="hasPrivilege('PRIV_WAL_BRAND_CONFIG_ENROLL_LINKS') && parentForm != 'EnrollmentLinks'">
            <mat-icon>link</mat-icon>
            <span>Enrollment Links</span>
        </button>
        <button mat-menu-item routerLink="/qrcodeGeneration" *ngIf="hasPrivilege('PRIV_PORTAL_QR_CODE') && parentForm != 'QRCodes'">
            <mat-icon>grain</mat-icon>
            <span>QR Codes</span>
        </button>
        <button mat-menu-item routerLink="/scheduledEvents" *ngIf="hasAnyPrivilege(['PRIV_MS_EVENTS_VIEW','PRIV_MS_EVENTS_MODIFY']) && parentForm != 'ScheduledEvents'">
            <mat-icon>access_alarms</mat-icon>
            <span>Scheduled Events</span>
        </button>
    </mat-menu>
</div>

<!-- <mat-icon>menu</mat-icon> -->

<!-- <mat-icon>more_vert</mat-icon> -->

