<h2>Communication Manager Subscribers</h2>

<div class="grid">
    <div class="col-5">
        <div class="col-1">
            <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
        </div>  
    </div>

    <div class="col-3" *ngIf="!hideSubscriber">
        <p-message severity="info" text="Subscriber Details: {{selectedSubscriberName}}"></p-message>
    </div>

</div>

<!-- L I S T   O F   S U B S C R I B E R S -->    

<div class="grid">
    <div class="col-4  div-lightgray">

        <p-table #dt [columns]="subscriberCols" [value]="subscribers" selectionMode="single" 
            [(selection)]="selectedSubscriberName" styleClass="p-datatable-striped p-datatable-sm"
            sortMode="multiple" (onRowSelect)="onSubscriberRowSelect($event)" [paginator]="true" [rows]="15" >
          <ng-template pTemplate="caption">
              List of Subscribers
          </ng-template>
          <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" class="p-column-title" [ngStyle]="{'width': col.width, 'display': col.display}">
                    <input *ngSwitchCase="'subscriberName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                </th>
            </tr>  
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary" let-rowData>
              <div style="text-align:left" *ngIf="isSubmitShown">
                  <p-button label="Add" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="addSubscriber()"></p-button>
                </div>
          </ng-template>    
        </p-table> 
      
    </div>

    <div class="col-1">
    </div>

<!-- F U L L   V I E W   OF   O N E   S U B S C R I B E R -->    

    <div class="col-5 group" [hidden]="hideSubscriber">

        <p-tabView>

            <p-tabPanel header="Properties" leftIcon="pi pi-user-edit">

                <p-card header="Subscriber Properties" subheader="" styleClass="ui-card-light">
        
                    <div>

                        <div class="field grid">
                            <label for="subscriberId" class="col-fixed label" style="width:15em">Subscriber Id:</label>
                            <div class="col">
                                <input pInputText id="subscriberId" type="text" [(ngModel)]="currentSubscriber.subscriberId" style="width: 25%;"  
                                placeholder="Required" [readonly]="true"/> 
                            </div>
                          </div>                             

                          <div class="field grid">
                            <label for="subscriberName" class="col-fixed label" style="width:15em">Subscriber Name:</label>
                            <div class="col">
                                <input pInputText id="subscriberName" type="text" [(ngModel)]="currentSubscriber.subscriberName" style="width: 75%;"  
                                placeholder="Required" [readonly]="true"/> 
                            </div>
                          </div>                             

                          <div class="field grid">
                            <label for="enabled" class="col-fixed label" style="width:15em">Enabled?:</label>
                            <div class="col">
                                <p-checkbox [(ngModel)]="currentSubscriber.enabled" id="enabled" [binary]="true" [readonly]="false"></p-checkbox>
                            </div>
                          </div>                             
                            
                          <div class="field grid">
                            <label for="modifiedDate" class="col-fixed label" style="width:15em">Last Update Date:</label>
                            <div class="col">
                                <input pInputText id="modifiedDate" type="text" [(ngModel)]="currentSubscriber.modifiedDate" style="width: 50%;"  
                                placeholder="Required" [readonly]="true"/> 
                            </div>
                          </div>                             
        
                    </div>

                    <div style="text-align:left" *ngIf="isSubmitShown">
                        <p-button label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="saveSubscriberProperties()"></p-button>
                        <p-button label="Delete" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="deleteSubscriber()"></p-button>
                    </div>

            
                    <p-footer>
                        <mat-icon class="nav-caption wal-center">add_a_photo</mat-icon>
                    </p-footer>
                </p-card>

            </p-tabPanel>

            <p-tabPanel header="Contact Points" leftIcon="pi pi-list">

                <p-table #dt [columns]="contactPointsCols" [value]="contactPoints" selectionMode="single" [(selection)]="selectedContactPoint" 
                    sortMode="multiple" (onRowSelect)="onContactPointRowSelect($event)" [paginator]="false" >
                    <ng-template pTemplate="caption">
                    Subscriber's Contact Points
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary" let-rowData>
                        <div style="text-align:left" *ngIf="isSubmitShown">
                            <!-- <button type="button" pButton icon="fa fa-plus p-button-raised p-button-rounded" (click)="addContactPoint()" label="Add"></button>
                            <button type="button" pButton icon="fa fa-minus p-button-raised p-button-rounded" (click)="dropContactPoint()" 
                                label="Drop" *ngIf="displayDialogDropContactPoint"></button> -->
                            <p-button label="Add" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="addContactPoint()"></p-button>
                            <p-button label="Drop" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="dropContactPoint()" 
                                *ngIf="displayDialogDropContactPoint"></p-button>
                        </div>
                    </ng-template>    
                </p-table> 

            </p-tabPanel>

            <p-tabPanel header="Attributes" leftIcon="pi pi-check-circle">

                <p-table #dt [columns]="attributesCols" [value]="attributes" selectionMode="single" [(selection)]="selectedAttribute" 
                    sortMode="multiple" (onRowSelect)="onAttributesRowSelect($event)" [paginator]="false" >
                    <ng-template pTemplate="caption">
                    Subscriber's Attributes
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary" let-rowData>
                        <div style="text-align:left" *ngIf="isSubmitShown">
                            <p-button label="Add" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="addAttribute()"></p-button>
                            <p-button label="Drop" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="dropAttribute()" 
                                *ngIf="displayDialogDropAttribute"></p-button>
                        </div>
                    </ng-template>    
                </p-table> 
                
            </p-tabPanel>

            <p-tabPanel header="Channels" leftIcon="pi pi-sitemap">

                <p-table #dt [columns]="channelsCols" [value]="channels" selectionMode="single" [(selection)]="selectedChannel" 
                    sortMode="multiple" (onRowSelect)="onChannelRowSelect($event)" [paginator]="false" >
                    <ng-template pTemplate="caption">
                    Subscription Channels
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary" let-rowData>
                        <div style="text-align:left" *ngIf="isSubmitShown">
                            <p-button label="Add" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="addChannel()"></p-button>
                            <p-button label="Drop" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="dropChannel()" 
                                *ngIf="displayDialogDropChannel"></p-button>
                                <p-button label="{{changeSubscriptionLabel}}" styleClass="p-button-info p-button-raised p-button-rounded margin-left"
                                     (click)="changeSubscriptionStatus()" *ngIf="displayDialogDropChannel"></p-button>
                        </div>
                    </ng-template>    
                </p-table> 

            </p-tabPanel>

        </p-tabView>

    </div>

</div>

 <p-confirmDialog class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>

<!-- D I A L O G   T O   A D D   S U B S C R I B E R -->    

<p-dialog appendTo="body" header="Add Subscriber" [(visible)]="displayDialogAddSubscriber" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '30%'}" [contentStyle]="{minHeight: '300px'}">
    <div>

        <form [formGroup]="newSubscriberForm" (ngSubmit)="saveNewSubscriber(newSubscriberForm.value)">

            <div class="field grid">
                <label for="newSubscriberName" class="col-fixed label" style="width:15em">Subscriber Name:</label>
                <div class="col">
                    <input pInputText id="newSubscriberName" type="text" formControlName="newSubscriberName" style="width: 90%;"  
                    placeholder="Required" /> 
                </div>
            </div>                             

            <div class="field grid">
                <label for="contactPointType" class="col-fixed label" style="width:15em">Contact Point Code:</label>
                <div class="col">
                    <p-dropdown id ="contactPointType" [options]="contactPointCodes" formControlName="newContactPointType" [filter]="false" 
                        (onChange)="onChangeNewContactPointType($event)"
                        placeholder="Select contact point type" [editable]="false"></p-dropdown>
                </div>
            </div>                             

            <div class="field grid">
                <label for="newContactPoint" class="col-fixed label" style="width:15em">Contact Point:</label>
                <div class="col">
                    <input pInputText type="tel" id="customerPhoneNo" formControlName="newContactPoint" *ngIf="!isNewContactPointEmail"
                        validatorMessage="Value does not match pattern."  pTooltip="Format: 000-000-0000" tooltipPosition="top" 
                            [style]="{'width': '50%'}"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>                    
    
                    <input pInputText type="email" id="customerEmailAddress" formControlName="newContactPoint" *ngIf="isNewContactPointEmail"
                        validatorMessage="Value does not match pattern." pTooltip="Examle: jane.doe@gmail.com" tooltipPosition="top" 
                            [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
                </div>
            </div>                             

            <div class="field grid">
                <label for="attributeCode" class="col-fixed label" style="width:15em">Attribute Code:</label>
                <div class="col">
                    <p-dropdown id ="attributeCode" [options]="attributeCodes" formControlName="newAttributeCode" [filter]="false" 
                        placeholder="Select attribute" [editable]="false"></p-dropdown>
                </div>
            </div>                             

            <div class="field grid">
                <label for="newAttribute" class="col-fixed label" style="width:15em">Attribute Value:</label>
                <div class="col">
                    <input pInputText id="newAttribute" formControlName="newAttribute" autofocus/>
                </div>
            </div>                             

            <div class="col-12">
                <p-button type="submit" label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!newSubscriberForm.valid"></p-button>
                <p-button label="Cancel" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="cancelSubscriber()"> </p-button>
<!-- 
                <div  class="col-2">
                    <button pButton type="submit" label="Save" icon="fa fa-check p-button-raised" [disabled]="!newSubscriberForm.valid"></button>
                </div>          
                <div class="col-2">
                    <button pButton type="button" label="Cancel" icon="fa fa-minus p-button-raised" (click)="cancelSubscriber()" ></button>
                </div>                     -->
            </div>

        </form>

    </div>
</p-dialog> 


<!-- D I A L O G   T O   A D D   C O N T A C T   P O I N T   T O   S U B S C R I B E R -->    

<p-dialog appendTo="body" header="Add Contact Point to Subscriber" [(visible)]="displayDialogAddContactPoint" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '25%'}">
    <div>

        <form [formGroup]="newContactPointForm" (ngSubmit)="saveContactPoint(newContactPointForm.value)">

            <div class="field grid">
                <label for="contactPointCode" class="col-fixed label" style="width:15em">Contact Point Code:</label>
                <div class="col">
                    <p-dropdown id ="contactPointCode" [options]="contactPointCodes" formControlName="newContactPointType" [filter]="false" 
                    (onChange)="onChangeNewContactPointType($event)"
                    placeholder="Select contact point type" [editable]="false"></p-dropdown>
                </div>
            </div>    

            <div class="field grid">
                <label for="newContactPoint" class="col-fixed label" style="width:15em">Contact Point:</label>
                <div class="col">
                    <input pInputText type="tel" id="customerPhoneNo" formControlName="newContactPoint" *ngIf="!isNewContactPointEmail"
                        validatorMessage="Value does not match pattern."  pTooltip="Format: 000-000-0000" tooltipPosition="top" 
                            [style]="{'width': '50%'}"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>                    
    
                    <input pInputText type="email" id="customerEmailAddress" formControlName="newContactPoint" *ngIf="isNewContactPointEmail"
                        validatorMessage="Value does not match pattern." pTooltip="Examle: jane.doe@gmail.com" tooltipPosition="top" 
                            [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
                </div>
            </div>    

            <div class="ui-g-12 ui-dialog-buttonpane ui-helper-clearfix">
                <p-button type="submit" label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!newContactPointForm.valid"></p-button>
                <p-button label="Cancel" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="cancelContactPoint()"> </p-button>
<!-- 
                <div  class="col-2">
                    <button pButton type="submit" label="Save" icon="fa fa-check p-button-raised" [disabled]="!newContactPointForm.valid"></button>
                </div>          
                <div class="col-2">
                    <button pButton type="button" label="Cancel" icon="fa fa-minus p-button-raised" (click)="cancelContactPoint()" ></button>
                </div>                     -->
            </div>

        </form>

    </div>
</p-dialog> 

<!-- D I A L O G   T O   A D D   A T T R I B U T E   T O   S U B S C R I B E R -->    

<p-dialog appendTo="body" header="Add Attribute to Subscriber" [(visible)]="displayDialogAddAttribute" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '25%'}" [contentStyle]="{minHeight: '150px'}">
    <div>

        <div class="field grid">
            <label for="attributeCode" class="col-fixed label" style="width:15em">Attribute Code:</label>
            <div class="col">
                <p-dropdown id ="attributeCode" [options]="attributeCodes" [(ngModel)]="newAttributeCode" [filter]="false" 
                    placeholder="Select attribute" [editable]="false" [style]="{'width':'90%'}"></p-dropdown>
            </div>
        </div>    

        <div class="field grid">
            <label for="newAttribute" class="col-fixed label" style="width:15em">Attribute Value:</label>
            <div class="col">
                <input pInputText id="newAttribute" [(ngModel)]="newAttribute" autofocus [style]="{'width':'90%'}" />
            </div>
        </div>    

    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <p-button label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="saveAttribute()"></p-button>
            <p-button label="Cancel" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="cancelAttribute()"> </p-button>
        </div>
    </p-footer>
</p-dialog> 

<!-- D I A L O G   T O   A D D   C H A N N E L   T O   S U B S C R I B E R -->    

<p-dialog appendTo="body" header="Subscribe to Channel" [(visible)]="displayDialogAddChannel" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true" [style]="{width: '25%'}" [contentStyle]="{minHeight: '300px'}" >
    <div>
        <div class="field grid">
            <label for="newChannel" class="col-fixed label" style="width:15em">Channel Code:</label>
            <div class="col">
                <p-dropdown id ="newChannel" [options]="channelCodes" [(ngModel)]="newChannelId" [filter]="false" 
                    placeholder="Select channel" [editable]="false" [style]="{'width':'80%'}"></p-dropdown>
            </div>
        </div>    

        <div class="field grid">
            <label for="subContactPoint" class="col-fixed label" style="width:15em">Contact Point:</label>
            <div class="col">
                <p-dropdown id ="subContactPoint" [options]="subContactPoints" [(ngModel)]="newSubContactPointId" [filter]="false" 
                    placeholder="Select contact point" [editable]="false" [style]="{'width':'80%'}"></p-dropdown>
            </div>
        </div>    

    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <p-button label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="saveChannel()"></p-button>
            <p-button label="Cancel" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="cancelChannel()"> </p-button>
        </div>
    </p-footer>
</p-dialog> 


