import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { User } from '../../models/user';
import { UserDescResponse } from '../../interfaces/user-desc-response';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { ErrorProcessorService }  from "../error-processor.service";


@Injectable({
  providedIn: 'root'
})
export class WalletronUserService {

  // protected basicAuth = AppConfigService.settings.appSetting.baseAuth;
  // protected envoronment = AppConfigService.settings.env.name;

  protected basicAuth = environment.appSetting.baseAuth;
  protected envoronment = environment.env.name;

  private usersPath = '/admin/users';
  private roleNamesPath = '/admin/users/distinctRoleNames'; 
    
  private headers = new HttpHeaders()
    .set('Authorization', this.basicAuth)

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private errorProcessorService: ErrorProcessorService) { }

  getUsers(envRegion: any, selectedUserName: string, selectedBrandCode: string, selectedRoleName: string,
     offset: number): Observable<UserDescResponse> {
    var params = new HttpParams()
      .set('limit', '50')
      .set('offset', offset);
    if (selectedUserName) {
      params = params.set('userName', selectedUserName);
    }
    if (selectedBrandCode) {
      params = params.set('brandCode', selectedBrandCode);
    }
    if (selectedRoleName) {
      params = params.set('roleName', selectedRoleName);
    }
    var usersUrl = this.getUrl(envRegion) + this.usersPath;
    return this.http.get<UserDescResponse>(usersUrl, {params: params, headers: this.headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  getUserRoles(envRegion: any, userId: number): Observable<string[]> {
    var usersUrl = this.getUrl(envRegion) + this.usersPath;
    return  this.http.get<string[]>(usersUrl, {headers: this.headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }
     
  getRoleNames(envRegion: any): Observable<string[]> {
     var roleNamesUrl = this.getUrl(envRegion) + this.roleNamesPath;
    return this.http.get<string[]>(roleNamesUrl, {headers: this.headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  addUser(envRegion: any, user: any, roles: any) {
    let newUser: User = {
      userName: user.userName,
      brandCode: user.brandCode,
      disabled: user.disabled,
      locked: user.locked,
      roles: roles,
      lastLoginDate: '',
      userId: 0,
      password: user.password
    };
    let newUsers = [newUser];
    var usersUrl = this.getUrl(envRegion) + this.usersPath;
    return this.http.post(usersUrl, newUsers, {headers: this.headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

   modifyUser(envRegion: any, user: any, roles: any) {
    let modifiedUser: User = {
      userName: user.userName,
      brandCode: user.brandCode,
      disabled: user.disabled,
      locked: user.locked,
      roles: roles,
      lastLoginDate: user.lastLoginDate,
      userId: user.userId,
      password: user.password
    };
    let modifiedUsers = [modifiedUser];
    var usersUrl = this.getUrl(envRegion) + this.usersPath;
    return this.http.put(usersUrl, modifiedUsers, {headers: this.headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  deleteUser(envRegion: any, userName: string) {
    var usersUrl = this.getUrl(envRegion) + this.usersPath;
    return this.http.delete(usersUrl + '/' + userName, {headers: this.headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }


  // Determine the URL of the Web API service based on the given environmental region
  getUrl(envRegion: any) {
    // Determine the URL based on the given environment; prevent going against production region while in QA and UAT
      // switch (envRegion.baseUrl) {
      //   case 'QA':
      //   case 'UAT':
      //     return this.baseUrl;
      //   default:  // Production
      //     return envRegion.baseUrl;
      // }
      return envRegion.baseUrl;
  } 


}
