<app-choose-brand 
    [displayBrandDialog] = "openBrandSelectionDialog" 
    (chooseBrandEvent)="chooseBrand($event)">
</app-choose-brand>

<app-filter-builder 
    [displayFilterBuilderDialog] = "openFilterBuilderDialog" 
    [currentBrandSubject]="currentBrandSubject"
    (selectFilterConfigEvent)="selectFilterConfig($event)">
</app-filter-builder>

<app-adhoc-message-template
    [displayMessageTemplateDialog] = "openMessageTemplateDialog" 
    [currentBrandSubject]="currentBrandSubject"
    (selectMessageTemplateEvent)="selectMessageTemplate($event)">
</app-adhoc-message-template>

<app-cron-editor
    [displayCronEditorDialog] = "openCronEditorDialog" 
    [currentBrandSubject]="currentBrandSubject"
    [adhocMsgConfigIdSubject]="adhocMsgConfigIdSubject"
    [cronEditorConfig]="cronEditorConfig"
    (setCronSchedulerEvent)="setCronScheduler($event)">
</app-cron-editor>


<div class="grid">
    <div class="col-7 flex-container">
        <div class="flex-child">
            <p-message severity="info" text="{{titleMessage}}"></p-message>
        </div>
        <div class="flex-child">
            <div *ngIf="isImagePreviewShown"><img class="center-block" [src]="brandImage" /></div>
        </div>
    </div>

    <div class="col-1">
       <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded" >
        </button>
    </div> 
</div> 


@if(!isAdhocMessageConfigDialogVisible) {

    <div class="col-9">

        <p-card header="Target Communication Configs" subheader="" styleClass="ui-card-light">

            <div class="grid">
                <div class="col-12">
        
                    <p-table #dt [columns]="adhocMessageConfigCols" [value]="adhocMessageConfigs" selectionMode="single" 
                        [(selection)]="selectedAdhocMessageConfig"  
                        sortMode="multiple" (onRowSelect)="onRowAdhocMessageConfigSelect($event)" [paginator]="true" [rows]="15" >
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                              {{col.header}}
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                          </th>
                          <!-- <th pSortableColumn="isScheduled" style="width: 12%">Scheduled? <p-sortIcon field="isScheduled"></p-sortIcon></th> -->
                          <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}"></th>
                        </tr>
                          <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field"  [ngStyle]="{'width': col.width}">
                                <input *ngSwitchCase="'messageConfigName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                                <input *ngSwitchCase="'filterName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                                <input *ngSwitchCase="'messageTemplateName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                                <input *ngSwitchCase="'modifiedByUser'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                                <input *ngSwitchCase="'schedulerStatus'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                            </th>
                        </tr>  
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-columns="columns">
                          <tr [pSelectableRow]="rowData">
                              <td *ngFor="let col of columns">
                                  {{rowData[col.field]}}
                              </td>
                              <!-- <td>
                                <p-tag [value]="rowData.isScheduled ? 'Y' : 'N'" [severity]="getStatus(rowData.isScheduled)"></p-tag>
                              </td> -->
    
                              <td style="text-align:center">
                                  <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}" 
                                      data-toggle="tooltip" data-placement="top" title="Modify Targeted Communication Config" 
                                      class="btn ui-button-info p-mr-2" (click)="onModifyAdhocMessageConfig(rowData)">
                                  </button> 
                                  <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                      data-toggle="tooltip" data-placement="top" title="Delete Targeted Communication Config" 
                                      class="btn ui-button-danger" (click)="onDeleteAdhocMessageConfig(rowData)" style="margin-right:10px">
                                  </button>                                
                              </td>
    
                          </tr>
                      </ng-template>
                    </p-table> 
              
                </div>
            </div>
    
    
            <p-footer>
    
                <div class="ui-g btn-group" role="group">
    
                    <p-button type="button" label="New Config" (click)="onNewAdhocMessageConfig()"
                        styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                    </p-button>                                    
    
                    @if (isExecuteNowButtonShown) {
                        <p-button type="button" label="Execute Now" (click)="onRunAdhocMessageConfig()"
                            styleClass="p-button-info p-button-raised p-button-rounded margin-left">
                        </p-button>                       
                    }             
    
                    @if (isPutOnScheduleButtonShown) {
                        <p-button type="button" label="Put on Schedule" (click)="onPutOnScheduleAdhocMessageConfig()"
                            styleClass="p-button-primary p-button-raised p-button-rounded margin-left">
                        </p-button>                                    
                    }
    
                    @if (isRemoveFromScheduleButtonShown) {
                        <p-button type="button" label="Remove from Schedule" (click)="onRemoveFromScheduleAdhocMessageConfig()"
                            styleClass="p-button-danger p-button-raised p-button-rounded margin-left">
                        </p-button>                                    
                    }

                </div>
    
            </p-footer>
    
        </p-card>
    
    
    </div>

}

@if(isAdhocMessageConfigDialogVisible) {

    <div class="grid">

        <div class="col-8">
    
            <p-card header="Target Communication Config" subheader="" styleClass="ui-card-light" >
    
                <form [formGroup]="adhocMsgConfigForm" (ngSubmit)="onSubmit(adhocMsgConfigForm.value)">
        
                    <div class="field grid">
                        <label for="messageConfigName" class="col-fixed label" style="width: 20em">Target Communication Name:</label>
                        <div class="col">
                            <input pInputText id="messageConfigName" formControlName="messageConfigName"  [style]="{'width': '80%'}" 
                                pTooltip="Target communication config name" tooltipPosition="top" />                    
                            <p-message severity="error" text="Message configuration name is required" *ngIf="!adhocMsgConfigForm.controls['messageConfigName'].valid && adhocMsgConfigForm.controls['messageConfigName'].dirty"></p-message>                 
                        </div>
                        <div class="col-1"></div>
                        <div style="margin-right: 10px;">
                            @if(isAdhocMessageConfigScheduled) {
                                <div class="text-center green-banner">SCHEDULED</div>
                            }
                        </div>
                </div>                  
    
                    <div class="field grid">
                            <label for="description" class="col-fixed label" style="width: 20em">Target Communication Description:</label>
                        <div class="col">
                            <textarea pInputTextarea id="description" style="width: 100%;" [rows]="1" [cols]="36" 
                                pTooltip="Target communication configuration description" tooltipPosition="top" 
                                formControlName="description" > 
                            </textarea>
                        </div>
                    </div>
    
    
                    <div class="ui-card-border">
    
                        <div class="field grid">
                            <label for="filterName" class="col-fixed label" style="width: 20em">Filter Configuration:</label>
                            <div class="col">
                                <input pInputText id="filterName" formControlName="filterName"  [style]="{'width': '80%'}"  readonly="true"
                                    pTooltip="Select the filter configuration" tooltipPosition="top" />                    
                                <p-message severity="error" text="Filter configuration is required" *ngIf="!adhocMsgConfigForm.controls['filterName'].valid && adhocMsgConfigForm.controls['filterName'].dirty"></p-message>                 
                            </div>
                            <div class="col-1"></div>
                            <div style="margin-right: 10px;">
                                @if(!isAdhocMessageConfigScheduled) {
                                    <p-button type="button" label="Set Filter" styleClass="p-button-success p-button-raised p-button-rounded " (click)="onSelectFilterClick()"></p-button>
                                }
                            </div>
                        </div>                  
    
                        <div class="field grid">
                            <label for="filterLogic" class="col-fixed label" style="width: 20em">Filter Logic:</label>
                            <div class="col">
                                <textarea pInputTextarea id="filterLogic" style="width: 100%;" [rows]="2" [cols]="36" readonly="true"
                                    pTooltip="The business logic of the selected filter configuration" tooltipPosition="top" 
                                    formControlName="filterLogic" > 
                                </textarea>
                            </div>
                        </div>
    
                    </div>
    
                    <hr>
    
                    <div class="ui-card-border">
    
                        <div class="field grid">
                            <label for="messageTemplateName" class="col-fixed label" style="width: 20em">Message Template Name:</label>
                            <div class="col">
                                <input pInputText id="messageTemplateName" formControlName="messageTemplateName"  [style]="{'width': '75%'}"  readonly="true"
                                    pTooltip="Message template name" tooltipPosition="top" readonly="true"/>                    
                                <p-message severity="error" text="Message template name is required" *ngIf="!adhocMsgConfigForm.controls['messageTemplateName'].valid && adhocMsgConfigForm.controls['messageTemplateName'].dirty"></p-message>                 
                            </div>
                            <div class="col-1"></div>
                            <div style="margin-right: 10px;">
                                @if(!isAdhocMessageConfigScheduled) {
                                    <p-button type="button" label="Set Message Template" styleClass="p-button-success p-button-raised p-button-rounded " (click)="onSelectMessageTemplateClick()"></p-button>
                                }
                            </div>
                        </div>
    
                        <div class="field grid">
                            <label for="messageTemplateHeader" class="col-fixed label" style="width: 20em">Message Template Header:</label>
                            <div class="col">
                                <textarea pInputTextarea id="messageTemplateHeader" style="width: 100%;" [rows]="2" [cols]="36" 
                                    pTooltip="The text of the message template header" tooltipPosition="top" readonly="true"
                                    formControlName="messageTemplateHeader" > 
                                </textarea>
                                <p-message severity="error" text="Message template header is required" *ngIf="!adhocMsgConfigForm.controls['messageTemplateHeader'].valid && adhocMsgConfigForm.controls['messageTemplateHeader'].dirty"></p-message>                 
                            </div>
                        </div>
    
                        <div class="field grid">
                            <label for="messageTemplateText" class="col-fixed label" style="width: 20em">Message Template Text:</label>
                            <div class="col">
                                <textarea pInputTextarea id="messageTemplateText" style="width: 100%;" [rows]="2" [cols]="36" 
                                    pTooltip="The text of the message template text" tooltipPosition="top" readonly="true"
                                    formControlName="messageTemplateText" > 
                                </textarea>
                                <p-message severity="error" text="Message template text is required" *ngIf="!adhocMsgConfigForm.controls['messageTemplateText'].valid && adhocMsgConfigForm.controls['messageTemplateText'].dirty"></p-message>                 
                            </div>
                        </div>
    
                    </div>

                    <hr>
    
                    <div class="ui-card-border">
                        <div class="field grid">
                            <label for="schedulerConfig" class="col-fixed label" style="width: 20em">Scheduler Configuration:</label>

                            <div class="col">

                                <textarea pInputTextarea id="humanCron" style="width: 100%;" [rows]="2" [cols]="36" 
                                    pTooltip="Scheduler configuration" tooltipPosition="top" readonly="true"
                                    formControlName="humanCron" > 
                                </textarea>

                            </div>
                            <div class="col-1"></div>
                            <div style="margin-right: 10px;">
                                @if(!isAdhocMessageConfigScheduled) {
                                    <p-button type="button" label="Set Schedule" styleClass="p-button-success p-button-raised p-button-rounded " (click)="onSetScheduleClick()"></p-button>
                                }
                            </div>

                        </div>

                    </div>                    
    
                    <div class="col-12">
                        <div  class="col-2" class="row container-fluid" id="divshow">
                                <p-button type="submit" label="Submit" 
                                    styleClass="p-button-success p-button-raised p-button-rounded margin-left" 
                                    pTooltip="{{isAdhocMessageConfigScheduled ? hintMsg : ''}}" tooltipPosition="top"
                                    [disabled]="!adhocMsgConfigForm.valid || isAdhocMessageConfigScheduled">
                                </p-button>
                            <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelAdhocMessageConfig()"></p-button>
                        </div> 
                    </div>                

<!--                     
                    <div class="field grid" style="margin-top: 0.5em;">
                        <div class="col row container-fluid">
                            @if(!isAdhocMessageConfigScheduled) {
                                <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!adhocMsgConfigForm.valid" ></p-button>
                            }
                            <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelAdhocMessageConfig()"></p-button>
                        </div>
                        <div class="col">
                            @if(isAdhocMessageConfigScheduled) {
                                <mat-label>{{hintMsg}}</mat-label>
                            }
                        </div>
                    </div> 
                    -->
    
                </form>
    
                <p-footer>
                    <mat-icon class="nav-caption wal-center">system_update</mat-icon>
                </p-footer>
    
            </p-card>
    
        </div>
    
    </div>
    
}

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="adhoc-message-config"></p-confirmDialog>