<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-7">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded" [disabled]="isDetailsShown">
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 

<!--   -->

<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-9 side-list-content-data" *ngIf="isListShown">
        <p-card header="List of Pass Templates" subheader="" styleClass="ui-card-powder" >

            <p-table #dt [columns]="cols" [value]="passTemplates" selectionMode="single" 
                sortMode="multiple" [paginator]="true" [rows]="15" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            <input *ngSwitchCase="'passName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                            <input *ngSwitchCase="'passtypeName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"  [style]="{'width': '75%'}">
                        </th>
                        <th></th>
                    </tr>  
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pEditableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                        <td style="text-align:center">
<!-- 
                            <p-button type="button" icon="pi pi-pencil" styleClass="p-button-primary" 
                                style="width:18px; height:18px; display: table-cell" (click)="onRowEdit(rowData)">
                            </p-button>
                            <p-button type="button" icon="pi pi-clone" styleClass="p-button-primary" *ngIf="isSubmitShown"
                                style="width:18px; height:18px; display: table-cell"  (click)="onRowClone(rowData)">
                            </p-button>
                            <p-button type="button" icon="pi pi-times" styleClass="p-button-primary"  *ngIf="isSubmitShown"
                                style="width:18px; height:18px; display: table-cell" (click)="onRowDelete(rowData)">
                            </p-button>
                            <p-button type="button" icon="pi pi-sitemap" styleClass="p-button-primary" *ngIf="isSubmitShown"
                                style="width:18px; height:18px; display: table-cell" (click)="onRowCloneToOtherBrand(rowData)">
                            </p-button>
                             -->

                            <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Modify pass template" 
                                class="ui-button-info" (click)="onRowEdit(rowData)">
                            </button>
                            <button pButton type="button" icon="pi pi-clone" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Clone pass template"  *ngIf="isSubmitShown"
                                class="btn ui-button-info" (click)="onRowClone(rowData)">
                            </button>
                            <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Delete pass template"  *ngIf="isSubmitShown"
                                class="ui-button-danger" (click)="onRowDelete(rowData)"  style="margin-right:10px">
                            </button>
                            <button pButton type="button" icon="pi pi-sitemap" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Clone pass template to a different brand"  *ngIf="isSubmitShown"
                                class="ui-button-primary" (click)="onRowCloneToOtherBrand(rowData)">
                            </button>

                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left" *ngIf="isSubmitShown">
                        <button pButton type="button" label="Add" icon="pi fa-plus" iconPos="left" (click)="showDialogToAdd()"  
                        class="p-button-info p-button-rounded" ></button>
                    </div>
                </ng-template>    
          </p-table> 
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">list_all</mat-icon>
            </p-footer>
        </p-card>

    </div>

    <!--- Second card with the details -->

    <div @flyInOut class="col-10 side-list-content-data" *ngIf="isDetailsShown">

        <div class="col-10">

            <p-card header="Pass Template Details" subheader="" styleClass="ui-card-light" >
            
                <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">

                    <div class="grid">

                        <!--- Left part of the form -->

                        <div class="col-6">

                            <div class="field grid">
                              <div class="col-1"></div>
                              <label for="passId" class="col-fixed label" style="width: 15em">Pass Template Id:</label>
                              <div class="col">
                                <input pInputText id="passId" type="text" formControlName="passId" readonly="true" 
                                    pTooltip="Unique pass template identifier" tooltipPosition="top" autofocus style="width: 10em"/> 
                              </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                              <label for="passName" class="col-fixed label" style="width: 15em">Pass Template Name:</label>
                              <div class="col">
                                <input pInputText id="passName" type="text" formControlName="passName" style="width: 25em" 
                                    [size]="45" placeholder="Required" pTooltip="Pass template name" tooltipPosition="top"/> 
                                <p-message severity="error" text="Pass template name is required" *ngIf="!userform.controls['passName'].valid&&userform.controls['passName'].dirty"></p-message>                 
                              </div>
                            </div>                             

                            <div class="field grid">
                                <div class="col-1"></div>
                                <label for="passName" class="col-fixed label" style="width: 15em">Pass Template Description:</label>
                                <div class="col">
                                  <input pInputText id="passDesc" type="text" formControlName="passDesc" style="width: 25em"
                                      [size]="200" placeholder="Required" pTooltip="Pass template description" tooltipPosition="top"/> 
                                  <p-message severity="error" text="Pass template description is required" *ngIf="!userform.controls['passDesc'].valid&&userform.controls['passDesc'].dirty"></p-message>                 
                                </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                              <label for="passtypeName" class="col-fixed label" style="width: 15em">Pass Type:</label>
                              <div class="col">
                                <p-dropdown id ="passtypeName" [options]="passTypes" formControlName="passtypeName" 
                                    [filter]="false" [editable]="false" pTooltip="Select the pass type" tooltipPosition="top"> 
                                    <p-message severity="error" text="Pass type is required" *ngIf="!userform.controls['passtypeName'].valid&&userform.controls['passtypeName'].dirty"></p-message>                 
                                </p-dropdown>
                              </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                              <label for="foregroundColor" class="col-fixed label" style="width: 15em">Foreground Color:</label>
                              <div class="col">
                                <input pInputText formControlName="foregroundColor"
                                [style.background]="foregroundColorInit" 
                                [(colorPicker)]="foregroundColorInit"
                                [cpOKButton] = "true"
                                [cpOKButtonText] = "'Select'"
                                [cpCancelButton] = "true"
                                [cpSaveClickOutside] = "false"
                                [cpDisableInput]="false"
                                [cpAlphaChannel]="'disabled'" 
                                [cpOutputFormat]="'hex'"
                                [cpAddColorButton]="true"
                                [cpColorMode] ="'color'"   
                                [cpPresetLabel]="''"    
                                [cpPresetEmptyMessage]="''"                              
                                />
                              </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                              <label for="backgroundColor" class="col-fixed label" style="width: 15em">Background Color:</label>
                              <div class="col">
                                <input pInputText formControlName="backgroundColor"
                                    [style.background]="backgroundColorInit" 
                                    [(colorPicker)]="backgroundColorInit"
                                    [cpOKButton] = "true"
                                    [cpOKButtonText] = "'Select'"
                                    [cpCancelButton] = "true"
                                    [cpSaveClickOutside] = "false"
                                    [cpDisableInput]="false"
                                    [cpAlphaChannel]="'disabled'" 
                                    [cpOutputFormat]="'hex'"
                                    [cpAddColorButton]="true"
                                    [cpColorMode] ="'color'"   
                                    [cpPresetLabel]="''"    
                                    [cpPresetEmptyMessage]="''"                              
                                />
                              </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                              <label for="labelColor" class="col-fixed label" style="width: 15em">Label Color:</label>
                              <div class="col">
                                <input pInputText formControlName="labelColor"
                                    [style.background]="labelColorInit" 
                                    [(colorPicker)]="labelColorInit"
                                    [cpOKButton] = "true"
                                    [cpOKButtonText] = "'Select'"
                                    [cpCancelButton] = "true"
                                    [cpSaveClickOutside] = "false"
                                    [cpDisableInput]="false"
                                    [cpAlphaChannel]="'disabled'" 
                                    [cpOutputFormat]="'hex'"
                                    [cpAddColorButton]="true"
                                    [cpColorMode] ="'color'"   
                                    [cpPresetLabel]="''"    
                                    [cpPresetEmptyMessage]="''"                              
                                />
                              </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                              <label for="imageLogoUID" class="col-fixed label" style="width: 15em">Logo Image:</label>
                              <div class="col">
                                <p-dropdown id ="imageLogoUID" [options]="imageList" formControlName="imageLogoUID" 
                                    (onChange)="onChangeLogoImage($event)" (onBlur)="onBlurLogoImage($event)"
                                    [filter]="false" [editable]="false" pTooltip="Select the logo image" tooltipPosition="top"> 
                                    <p-message severity="error" text="Logo image is required" *ngIf="!userform.controls['imageLogoUID'].valid&&userform.controls['imageLogoUID'].dirty">
                                    </p-message>                 
                                </p-dropdown>
                              </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="imageIconUID" class="col-fixed label" style="width: 15em">Apple Icon Image:</label>
                                  <div class="col">
                                    <p-dropdown id ="imageIconUID" [options]="imageList" formControlName="imageIconUID" 
                                        (onChange)="onChangeIconImage($event)" (onBlur)="onBlurIconImage($event)"
                                        [filter]="false" [editable]="false" pTooltip="Select the Apple icon image" tooltipPosition="top"> 
                                        <p-message severity="error" text="Apple icon image is required" *ngIf="!userform.controls['imageIconUID'].valid&&userform.controls['imageIconUID'].dirty"></p-message>                 
                                    </p-dropdown>
                                  </div>
                            </div>                             

                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="associatedApp" class="col-fixed label" style="width: 15em">Associated App Id:</label>
                                  <div class="col">
                                    <input pInputText id="associatedApp" type="text" formControlName="associatedApp" style="width: 15em" 
                                        [size]="255" pTooltip="The App ID for an iOS app related to this Wallet Object" tooltipPosition="top"/> 
                                  </div>
                            </div>                             
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="logoText" class="col-fixed label" style="width: 15em">Logo Text:</label>
                                  <div class="col">
                                    <input pInputText id="logoText" type="text" formControlName="logoText" style="width: 25em"
                                        [size]="45"/> 
                                  </div>
                            </div>                             

                        </div>

                        <!--- Right part of the form -->

                        <div class="col-6">

                          <div class="field grid">
                            <div class="col-1"></div>
                                  <label for="initMsgTemplateCode" class="col-fixed label" style="width: 15em">Initial Message Template:</label>
                                  <div class="col">
                                    <p-dropdown id ="initMsgTemplateCode" [options]="messageTemplates" formControlName="initMsgTemplateCode" 
                                        [filter]="false" [editable]="false" pTooltip="Select the initial message template" tooltipPosition="top"> 
                                    </p-dropdown>
                                  </div>
                                  <div class="col">
                                    <button pButton type="button" icon="fa fa-plus" label="Msg Templates" class="ui-button-primary" (click)="msgTemplatesRedirect()" [disabled]="!userform.valid" ></button>
                                  </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwSupported" class="col-fixed label" style="width: 15em">Google Pay Support:</label>
                                  <div class="col">
                                    <p-checkbox formControlName="gwSupported" id="gwSupported" [binary]="true" (onChange)="onGwSupported($event)"></p-checkbox>
                                  </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwAllowMultipleUsers" class="col-fixed label" style="width: 15em">Allow Multiple GW Users:</label>
                                  <div class="col">
                                    <p-checkbox formControlName="gwAllowMultipleUsers" id="gwAllowMultipleUsers" [binary]="true" [disabled]="googlePayDisabled"></p-checkbox>
                                  </div>
                            </div>                             
                                
                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwIconImageUID" class="col-fixed label" style="width: 15em">Google Pay Icon Image:</label>
                                  <div class="col">
                                    <p-dropdown id ="gwIconImageUID" [options]="imageList" formControlName="gwIconImageUID" [disabled]="googlePayDisabled"
                                        (onChange)="onChangeGwIconImage($event)" (onBlur)="onBlurGwIconImage($event)"
                                        [filter]="false" [editable]="false" pTooltip="Select the Google Pay icon image" tooltipPosition="top"> 
                                    </p-dropdown>
                                  </div>
                            </div>                             
                                
                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwHeroImageUID" class="col-fixed label" style="width: 15em">Google Pay Hero Image:</label>
                                  <div class="col">
                                    <p-dropdown id ="gwHeroImageUID" [options]="imageList" formControlName="gwHeroImageUID" [disabled]="googlePayDisabled"
                                        (onChange)="onChangeGwHeroImage($event)" (onBlur)="onBlurGwHeroImage($event)"
                                        [filter]="false" [editable]="false" pTooltip="Select the Google pay hero image" tooltipPosition="top"> 
                                    </p-dropdown>
                                  </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwIssuerName" class="col-fixed label" style="width: 15em">Google Pay Issuer Name:</label>
                                  <div class="col">
                                    <input pInputText id="gwIssuerName" type="text" formControlName="gwIssuerName" style="width: 25em"  
                                       [size]="100"/> 
                                  </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwProgramName" class="col-fixed label" style="width: 15em">Google Pay Program Name:</label>
                                  <div class="col">
                                    <input pInputText id="gwProgramName" type="text" formControlName="gwProgramName" style="width: 25em"  
                                       [size]="100"/> 
                                  </div>
                            </div>                             

                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwAccountIdLabel" class="col-fixed label" style="width: 15em">Google Pay Account Id Label:</label>
                                  <div class="col">
                                    <input pInputText id="gwAccountIdLabel" type="text" formControlName="gwAccountIdLabel" style="width: 25em"  
                                       [size]="100"/> 
                                  </div>
                            </div>                             
                                
                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="gwAccountNameLabel" class="col-fixed label" style="width: 15em">Google Pay Account Name Label:</label>
                                  <div class="col">
                                    <input pInputText id="gwAccountNameLabel" type="text" formControlName="gwAccountNameLabel" style="width: 25em"  
                                       [size]="100"/> 
                                  </div>
                            </div>                             
                                
                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                                  <div class="col">
                                    <input pInputText id="createDate" type="text" formControlName="createDate" style="width: 15em" readonly="true"
                                       [size]="100"/> 
                                  </div>
                            </div>                             
                            
                            <div class="field grid">
                              <div class="col-1"></div>
                                  <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                                  <div class="col">
                                    <input pInputText id="lastUpdateDate" type="text" formControlName="lastUpdateDate" style="width: 15em" readonly="true"
                                       [size]="100"/> 
                                  </div>
                            </div>                             

                        </div>
                        
                        <div class="col-12 margin-bottom-narrower">
                            <div  class="col-2" class="row container-fluid"  id="divshow">
                                <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></p-button>
                                <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>
<!-- 
                                <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></button>
                                <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button> -->
                            </div> 
                            <div class="col-10" *ngIf="!isSubmitShown">
                                <mat-label>{{resultMsg}}</mat-label>
                            </div>
                        </div>

                    </div>

                </form>
        
                <p-footer>
                    <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
                </p-footer>
            </p-card>

        </div>

        <div class="col-2" styleClass="ui-card-light"  *ngIf="isImagePreviewShown">
            <img [src]="image" />
        </div>

    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-pass-templates"></p-confirmDialog>





<p-dialog appendTo="body" header="Clone Pass Template" [(visible)]="openCloneToOtherBrandDialog"
         [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '60%'}" position="top" [showHeader]="true" [closable]="false">

        <p-card header="Clone Pass Template to Different Brand" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="cloneform" (ngSubmit)="onCloneToOtherBrand(cloneform.value)">

                <div class="grid">

                    <div class="col-6"> 

                      <div class="field grid">
                        <div class="col-1"></div>
                              <label for="targetBrandCode" class="col-fixed label" style="width: 15em">Target Brand:</label>
                              <div class="col">
                                <p-dropdown id ="targetBrandCode" [options]="targetBrands" formControlName="targetBrandCode" [style]="{'width': '100%'}"
                                    [filter]="true" [editable]="false" pTooltip="Select the target brand to clone to" tooltipPosition="top"> 
                                    <p-message severity="error" text="Target brand is required" *ngIf="!cloneform.controls['targetBrandCode'].valid&&cloneform.controls['targetBrandCode'].dirty"></p-message>                 
                                </p-dropdown>
                              </div>
                        </div>                             

                        <div class="field grid">
                          <div class="col-1"></div>
                              <label for="passTemplateName" class="col-fixed label" style="width: 15em">Pass Template Name:</label>
                              <div class="col">
                                <input pInputText id="passTemplateName" type="text" formControlName="passTemplateName" style="width: 20em"
                                    [size]="45" placeholder="Required" pTooltip="Target Pass Template Name" tooltipPosition="top"/> 
                                <p-message severity="error" text="Pass Template Name is required" *ngIf="!cloneform.controls['passTemplateName'].valid&&cloneform.controls['passTemplateName'].dirty"></p-message>                 
                              </div>
                        </div>                             

                        <div class="field grid">
                          <div class="col-1"></div>
                              <label for="passTemplateDescription" class="col-fixed label" style="width: 15em">Pass Template Description:</label>
                              <div class="col">
                                <input pInputText id="passTemplateDescription" type="text" formControlName="passTemplateDescription" style="width: 20em" 
                                    [size]="45" placeholder="Required" pTooltip="Target Pass Template Description" tooltipPosition="top"/> 
                                <p-message severity="error" text="Pass Template Description is required" *ngIf="!cloneform.controls['passTemplateDescription'].valid&&cloneform.controls['passTemplateDescription'].dirty"></p-message>                 
                              </div>
                        </div>        
                        
                        <div  class="grid col-2" class="row container-fluid"  id="divshow">
                            <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!cloneform.valid"></p-button>
                            <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelCloneToOtherBrand()"></p-button>
                        </div>                         
<!-- 
                        <div class="grid"  *ngIf="isSubmitShown"  id="divshow">
                            <div class="col-1">
                                <button pButton type="submit" label="Submit" class="ui-button-primary" [disabled]="!cloneform.valid" ></button>
                            </div>
                            <div class="col-1">
                                <button type="button" pButton icon="fa fa-check" (click)="onCancelCloneToOtherBrand()" label="Cancel"></button>
                            </div>
                        </div>                             
             -->
                    </div>

                    <div class="col-6"> 

                      <div class="field grid">
                        <div class="col-1"></div>
                              <label for="appleLogoImage" class="col-fixed label" style="width: 15em">Apple Logo Image:</label>
                              <div class="col">
                                <p-fileUpload name="appleLogoImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                                    [customUpload]="true" (onSelect)="onAppleLogoFileChange($event)" 
                                    [showCancelButton]="true" > 
                                </p-fileUpload>
                              </div>
                        </div>                             
                        
                        <div class="field grid">
                          <div class="col-1"></div>
                          <label for="appleIconImage" class="col-fixed label" style="width: 15em">Apple Icon Image:</label>
                              <div class="col">
                                <p-fileUpload name="appleIconImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                                    [customUpload]="true" (onSelect)="onApleIconFileChange($event)"
                                    [showCancelButton]="true"> 
                                </p-fileUpload>
                              </div>
                        </div>                             

                        <div class="field grid">
                          <div class="col-1"></div>
                              <label for="googleLogoImage" class="col-fixed label" style="width: 15em">Google Logo Image:</label>
                              <div class="col">
                                <p-fileUpload name="googleLogoImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                                    [customUpload]="true" (onSelect)="onGoogleLogoFileChange($event)"
                                    [showCancelButton]="true" > 
                                </p-fileUpload>
                              </div>
                        </div>                             
            
                        <div class="field grid">
                          <div class="col-1"></div>
                              <label for="googleIconImage" class="col-fixed label" style="width: 15em">Google Icon Image:</label>
                              <div class="col">
                                <p-fileUpload name="googleIconImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                                    [customUpload]="true" (onSelect)="onGoogleIconFileChange($event)"
                                    [showCancelButton]="true" > 
                                </p-fileUpload>
                              </div>
                        </div>                             
        
                    </div>
        
                </div>

            </form>
    
        <p-footer>
                <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
            </p-footer>
        </p-card>

</p-dialog>





