import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IMessageTemplate } from '../../interfaces/message-template';
import { ErrorProcessorService }  from "../error-processor.service";
import { SharedService } from "../../services/shared.service";
import { IMessageBatchExecViewerResponse } from '../../interfaces/message-batch-exec-viewer-response';

@Injectable({
  providedIn: 'root'
})
export class AdhocNotificationService {

  protected baseAuth = environment.appSetting.baseAuth;

  private baseHeaders = new HttpHeaders()
    .set("request-origin", "csr-portal")
    .set('Authorization', this.baseAuth);

    protected portalBaseUrl = environment.apiServer.portalUrl;

    private adhocNotifUrl = '';
    private messageBatchExecutionsViewerUrl = this.portalBaseUrl + '/msg-batch-exec/viewer'; 

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private errorProcessorService: ErrorProcessorService
  ) { }

  submitOnDemandRequest(brandCode: string, adhocNotifRequest: any, baseUrl: string): Observable<any> {
    var url = baseUrl + this.adhocNotifUrl
      .replace('{brandCode}', brandCode);
    return this.http.post<any>(url, adhocNotifRequest, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }  

  getMessageBatchExecutions(brandCode: string, messageBatchType: string, executionMethod: string, statusCode: string, 
    dateFrom: string, dateTo: string, nextPageTokens: any, direction: string, pageSize: number): Observable<IMessageBatchExecViewerResponse> {
      if (dateFrom != null) {
        dateFrom = this.sharedService.getFormattedDate(dateFrom);
      }
      if (dateTo != null) {
        dateTo = this.sharedService.getFormattedDate(this.sharedService.getNextDate(dateTo));
      }
      let request = {
        nextPageTokens: nextPageTokens ?? null,
        direction: direction ?? null,
        brandCodes: brandCode == null ? null : [brandCode], 
        messageBatchType: messageBatchType ?? null, 
        status: statusCode ?? null, 
        executionMethod: executionMethod ?? null,
        dateFrom:  this.sharedService.getFormattedDate(dateFrom) ?? null, 
        dateTo:  this.sharedService.getFormattedDate(dateTo) ?? null, 
        limit: pageSize
      }
      console.log('getMessageBatchExecutions', request);
      console.log('getMessageBatchExecutions', this.messageBatchExecutionsViewerUrl);

      return this.http.post<IMessageBatchExecViewerResponse>(this.messageBatchExecutionsViewerUrl, request, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    }

}
