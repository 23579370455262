import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { QueryBuilderConfig, QueryBuilderClassNames, RuleSet, Field, FieldMap } from "ngx-angular-query-builder";
import { SharedService } from "../../services/shared.service";
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { FilterBuilderService } from '../../services/client/filter-builder.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IVariableMetadata } from '../../interfaces/variable-metadata';
import { IFilterConfig } from '../../interfaces/filter-config';

@Component({
  selector: 'app-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrl: './filter-builder.component.css'
})

export class FilterBuilderComponent {

  @Input() displayFilterBuilderDialog?: boolean;
  @Input('currentBrandSubject') currentBrandSubject!: Subject<any>;
  @Output() selectFilterConfigEvent = new EventEmitter<IFilterConfig>();

  currentBrand!: IBrand;

  filterForm!: FormGroup;

  filterConfigs!: IFilterConfig[];
  filterConfigsCols: any[] = [];
  selectedFilterConfig: IFilterConfig = <IFilterConfig> {};
  selectedFilterConfigId!: number | null;

  isFilterConfigDialogVisible: boolean = false;
  isFilterNew: boolean = false;
  isSelectFilterButtonShown: boolean = false;
  isFilterSyntaxVisible: boolean = false;

  currentUser!: string;
  name = 'Angular';
  resultMsg = "";

  brandCode: string = '';
  baseUrl: string = '';

  defaultErrorMessage: string = "Error processing the request.  Please report to the Walletron Support team.";

  public filterCtrl: FormControl;

  public filter: RuleSet = {
    condition: 'and',
    rules: [
    ]
  };

  public config: QueryBuilderConfig = {fields: {}};

  public fieldTypeMap = new Map<string, string>();

  constructor(
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService,
    private brandProfileService: BrandProfileService,
    private filterBuilderService: FilterBuilderService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService
  ) {  
    this.filterCtrl = this.formBuilder.control(this.filter);
  }

  ngOnInit() {
    this.currentUser = this.tokenStorageService.getCurrentUser().userName;
    this.filterForm = this.formBuilder.group({
      'filterConfigId': new FormControl(null),
      // 'brandCode': new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]),
      'filterName': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'filterDescription': new FormControl('', [Validators.required, Validators.maxLength(200)]),
      'lastUpdateDate': new FormControl(''),
      'modifiedByUser': new FormControl(''),
    });
    // Define the columns for Brands grid
    this.filterConfigsCols = [
      { field: 'filterName', header: 'Filter Name', display: 'table-cell', width:'30%' },
      { field: 'filterDescription', header: 'Filter Description', display: 'table-cell', width:'55%' },
    ];     
    // Recieve the current brand from the parent component
    this.currentBrand = this.sharedService.getCurrentBrand()
    if (this.currentBrand) {
      this.setCurrentBrand(this.currentBrand);
    }
    this.currentBrandSubject.subscribe((item: IBrand) => {
      this.setCurrentBrand(item);
    });
  }
  
  private setCurrentBrand(brand: IBrand) {
    if (brand) {
      this.currentBrand = brand;
      this.brandCode = brand.brandCode;
      this.baseUrl = brand.baseUrl;
      // Call the service to invoke a Web API call
      this.getBrandVariables(this.brandCode, this.baseUrl);
      this.getFilterConfigs(this.brandCode, this.baseUrl);
    } else {
      this.alertTheUser('No current brand determined.  Please select the current brand.');
    }
  }

  private getBrandVariables(brandCode: string, baseUrl: string) {
    this.fieldTypeMap.clear();
    this.brandProfileService.getBrandVariables(brandCode, baseUrl || '')
    .subscribe({
      next: (response) => {
        if (response) {
          // console.log('response', response);
          let fieldMap: FieldMap = {};
          var field: Field;
          var varType: any;
          response.forEach(element => {
            if (element.variableMetadata) {
              let varMetadata: IVariableMetadata = JSON.parse(element.variableMetadata);
              if (varMetadata && varMetadata.isQueried) {
                switch (element.datatype.toLowerCase()) {
                  case 'numeric':
                    varType = 'number';
                    break;
                  case 'datetime':
                    varType = 'date';
                    break;
                  default:
                    varType = element.datatype.toLowerCase();
                    break;
                }
                field = {
                  name: varMetadata.name,
                  type: varMetadata.type == "category" ? 'category' : varType,
                  options: varMetadata.type == "category" && varMetadata.options ? varMetadata.options : undefined,
                  operators: varMetadata.operators ? varMetadata.operators : undefined,
                  defaultOperator: varMetadata.defaultOperator ? varMetadata.defaultOperator : undefined,
                  validator: varMetadata.validator ? varMetadata.validator : undefined,
                  defaultValue: varMetadata.defaultValue ? varMetadata.defaultValue : undefined
                };
                // console.log('field', field);
                fieldMap[element.variableName]= field;
                this.fieldTypeMap.set(element.variableName, element.datatype);
              }
            }
          })
          this.config = {fields: fieldMap};
        }
        // console.log('config', JSON.stringify(this.config));
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });

  }

  // Select all existing filter config for the current brand
  private getFilterConfigs(brandCode: string, baseUrl: string) {
    this.isSelectFilterButtonShown = false;
    this.filterBuilderService.getFilterConfigsForBrand(brandCode, baseUrl || '')
    .subscribe({
      next: (response) => {
        if (response) {
          this.filterConfigs = response;
          this.selectedFilterConfigId = null;
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // When the user selects a row to identify a chosen filter config
  onRowFilterConfigSelect(event: any) {
    this.selectedFilterConfig = <IFilterConfig>  {
      filterConfigId: event.data.filterConfigId,
      brandCode: event.data.brandCode,
      filterName: event.data.filterName,
      filterDescription: event.data.filterDescription,
      filterExpression: event.data.filterExpression,
      filterLogic: event.data.filterLogic
    };
    this.selectedFilterConfigId = event.data.filterConfigId;
    if (this.selectedFilterConfigId) {
      this.isSelectFilterButtonShown = true;
    }
  }

  // To create a new filter config
  onNewFilter() {
    this.filterForm.setValue({
      filterConfigId: null,
      filterName: null, 
      filterDescription: null,
      modifiedByUser: this.currentUser,
      lastUpdateDate: ''
    });
    this.filter = {
      condition: 'and',
      rules: []
    }
    this.filterCtrl = this.formBuilder.control(this.filter);
    this.isFilterNew = true;
    this.isFilterConfigDialogVisible = true;
  }

  // To modify an existing filter config
  onModifyFilter(event: any) {
    let filterConfigId: number = event.filterConfigId;
    this.filterBuilderService.getFilterConfigById(filterConfigId, this.baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.filterForm.setValue({
            filterConfigId: response.filterConfigId,
            filterName: response.filterName, 
            filterDescription: response.filterDescription,
            modifiedByUser: response.modifiedByUser,
            lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate || '')
          });
          this.filter = JSON.parse(response.filterExpression);
          this.filterCtrl = this.formBuilder.control(this.filter);
          console.log('filter', this.filter);
          this.isFilterNew = false;
          this.isFilterConfigDialogVisible = true;
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // To clone an existing filter config
  onCloneFilter(event: any) {
    this.confirmationService.confirm({
      message: 'The filter configuration ' + event.filterName + ' will be cloned into a new filter?  Do you want to proceed?',
      header: 'Confirmation',
      key: 'filter-builder',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        let filterConfig: IFilterConfig = {
          filterConfigId: event.filterConfigId,
          brandCode: this.brandCode,
          filterName: 'Clone ' + event.filterName,
          filterDescription: 'Clone ' + event.filterDescription,
          filterExpression: JSON.stringify(event.filter),
          createdByUser: this.currentUser,
          modifiedByUser: this.currentUser
        }
        // Call the service to invoke a Web API call
        this.filterBuilderService.cloneFilterConfig(filterConfig, this.baseUrl)
          .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully cloned filter configuration ' + response.filterName + ' for brand '
                + this.brandCode);
            this.getFilterConfigs(this.brandCode, this.baseUrl);
            this.isFilterConfigDialogVisible = false;
          },
          error: (error) => {
            this.alertTheUser(this.defaultErrorMessage);
          },
          complete: () => {
          }
        });               
      },
      reject: () => {
      }
    });

  }

  // To delete an existing filter config
  onDeleteFilter(event: any) {
    let filterConfigId: number = event.filterConfigId;
    let filterName: string = event.filterName;
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the filter configuration ' + filterName + ' ?',
      header: 'Confirmation',
      key: 'filter-builder',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.filterBuilderService.deleteFilterConfig(filterConfigId, this.baseUrl)
          .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted filter configuration ' + filterName + ' from brand '
                + this.brandCode);
            this.getFilterConfigs(this.brandCode, this.baseUrl);
            this.isFilterConfigDialogVisible = false;
          },
          error: (error) => {
            this.alertTheUser(this.defaultErrorMessage);
          },
          complete: () => {
          }
        });               
      },
      reject: () => {
      }
    });

  }

  // When the user submits a request to save off a new or modified filter config
  onSubmit(event: any) {
    // Append each Rule within the filter with the data type
    traverseFilter(this.filter, this.fieldTypeMap);
    // Prepare FilterConfig object
    let filterName = this.filterForm.value.filterName;
    let filterConfig: IFilterConfig = {
      brandCode: this.brandCode,
      filterName: filterName,
      filterDescription: this.filterForm.value.filterDescription,
      filterExpression: JSON.stringify(this.filter),
      modifiedByUser: this.currentUser
    }
    if (this.isFilterNew) {
      filterConfig.createdByUser = this.currentUser;
      this.filterBuilderService.addFilterConfig(filterConfig, this.baseUrl)
      .subscribe({
        next: (response) => {
          if (response) {
            this.alertTheUser('Successfully created filter configuration ' + filterName + ' from brand '
                + this.brandCode);
            this.getFilterConfigs(this.brandCode, this.baseUrl);
            this.isFilterConfigDialogVisible = false;
          }
        },
        error: (error) => {
          this.alertTheUser(this.defaultErrorMessage);
        },
        complete: () => {
        }
      });

    } else {
      filterConfig.filterConfigId = this.filterForm.value.filterConfigId;
      this.filterBuilderService.modifyFilterConfig(filterConfig, this.baseUrl)
      .subscribe({
        next: (response) => {
          if (response && response.filterConfigId) {
            this.alertTheUser('Successfully modified filter configuration ' + filterName + ' from brand '
                + this.brandCode);
            this.getFilterConfigs(this.brandCode, this.baseUrl);
            this.isFilterConfigDialogVisible = false;
          }
        },
        error: (error) => {
          this.alertTheUser(this.defaultErrorMessage);
        },
        complete: () => {
        }
      });
  
    }
  }

  // onChangeCustomQueries(event: any) {
  // }


  // When the user clicks on Select Filter to exist out from the form and send a message to the parent form
  onSelectFilter() {
    if (this.selectedFilterConfigId) {
      this.selectFilterConfigEvent.emit(this.selectedFilterConfig);
    } else {
      this.alertTheUser ("No filter has been selected.  Please chose a filter from the list, then click on Select button again.");
    }
  }  

  // When the user cancels the dialog for filter configs
  onCancelFilterBuilder() {
    this.selectFilterConfigEvent.emit();
  } 

  // When the New Filter Config dialog is canceled
  onCancelNewFilterBuilder() {
    this.isFilterConfigDialogVisible = false;
  }   

  alertTheUser(message: any) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'filter-builder',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}

  // Append each Rule with the data type of the variable
  function traverseFilter(filter: RuleSet, fieldTypeMap: Map<string, string>) {
    for (const rule of filter.rules) {
      if (rule.condition) {
        // Recursive case: if it is a ruleset, traverse it
        traverseFilter(rule, fieldTypeMap);
      } else {
        // Base case: if it is a simple rule
        if (fieldTypeMap.has(rule.field)) {

          let dType = fieldTypeMap.get(rule.field)?.toLowerCase();
          switch (dType) {
            case 'numeric':
              rule.datatype = 'number';
              break;
            case 'datetime':
              rule.datatype = 'date';
              break;
            default:
              rule.datatype = dType;
              break;
          }
          // rule.datatype = fieldTypeMap.get(rule.field)?.toLowerCase();
        }
        // console.log(rule.field + ' - ' + rule.datatype);
      }
    }

  }


