import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldTriggeredAction extends OfferFieldBase {

    notifSchedDescrTypes: any[];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        notifSchedDescrTypes: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.notifSchedDescrTypes = notifSchedDescrTypes;
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Value, Action Name, Trigger Name, Show/hide Field Expression, Var Transformation Expression

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'actionName',
            label: 'Action Name',
            styleWidth: '10em',
            value: this.data.actionName,
            required: true,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'triggerName',
            label: 'Trigger Name',
            value: this.data.triggerName,
            required: true,
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 7
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'varTransformationExpression',
            label: 'Var Transformation Expression',
            value: this.data.varTransformationExpression,
            styleWidth: '30em',            
            required: true,
            options: this.notifSchedDescrTypes,
            order: 8
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }

}
