<div class="grid">

    <div class="col-7">
        <p-card header="Group of Users Onboard" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm.value)">

                <div class="grid">

                    <div class="col-6">

                        <div class="field grid">
                            <label for="userType" class="col-fixed label" style="width:15em">User Type:</label>
                            <div class="col">
                                <p-dropdown id ="userType" [options]="userTypes" formControlName="userType" 
                                    [filter]="false" [editable]="false" [disabled]="isDisabled" pTooltip="Select the user type" tooltipPosition="top"> 
                                    <p-message severity="error" text="User type is required" *ngIf="!userForm.controls['userType'].valid&&userForm.controls['userType'].dirty"></p-message>                 
                                </p-dropdown>
                            </div>
                        </div>  
        
                        <div class="field grid">
                            <label for="brandCode" class="col-fixed label" style="width:15em">Brand Code:</label>
                            <div class="col">
                                <input pInputText id="brandCode" type="text" formControlName="brandCode" [disabled]="isDisabled" 
                                    [size]="3" [minLength]="3" placeholder="Required" pTooltip="Brand code" tooltipPosition="top"/> 
                                <p-message severity="error" text="Brand code is required" *ngIf="!userForm.controls['brandCode'].valid&&userForm.controls['brandCode'].dirty"></p-message>                 
                            </div>
                        </div>  
        
                        <div class="field grid">
                            <label for="initialPassword" class="col-fixed label" style="width:15em">Initial Password:</label>
                            <div class="col">
                                <input pInputText id="initialPassword" type="text" formControlName="initialPassword" [style]="{'width': '80%'}" [disabled]="isDisabled" 
                                    [size]="45" placeholder="Required" pTooltip="Initial password asssigned to all users within the group" tooltipPosition="top"/> 
                                <p-message severity="error" text="Initial password is required" *ngIf="!userForm.controls['initialPassword'].valid&&userForm.controls['initialPassword'].dirty"></p-message>                 
                            </div>
                        </div>  
            
                        <div class="field grid">
                            <label for="passwordChangeRequired" class="col-fixed label" style="width:15em">Password Change Required:</label>
                            <div class="col">
                                <p-checkbox formControlName="passwordChangeRequired" id="passwordChangeRequired" [binary]="true"></p-checkbox>
                            </div>
                        </div>  
                                            
                        <div class="field grid">
                            <label for="csvFile" class="col-fixed label" style="width:15em">CSV File:</label>
                            <div class="col">
                                <p-fileUpload id="csvFile" [multiple]="false" accept=".csv" [maxFileSize]="1000000" [showUploadButton]="false"
                                    [customUpload]="true" (onSelect)="onCsvFileChange($event)" formControlName="csvFile"
                                    [showCancelButton]="true" [disabled]="isDisabled" > 
                                </p-fileUpload>
                            </div>
                        </div>  

                        <div class="col-12">
                            <div  class="col-2" *ngIf="isSubmitShown" class="row container-fluid"  id="divshow">
                                <p-button label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left"  [disabled]="!userForm.valid"></p-button>
                            </div>          
                        </div>
                        
                    </div>

                    <div class="col-1"></div>
            
                    <div class="col-5">
        
                        <div class="field grid">
                            <label for="csvSeparator" class="col-fixed label" style="width:15em">CSV Separator:</label>
                            <div class="col">
                                <input pInputText id="csvSeparator" type="text" formControlName="csvSeparator" [disabled]="isDisabled" 
                                    [size]="1" [minLength]="1" placeholder="Required" pTooltip="A one character that is used to separate out fields in the CSV file" tooltipPosition="top"/> 
                                <p-message severity="error" text="CSV separator is required" *ngIf="!userForm.controls['csvSeparator'].valid&&userForm.controls['csvSeparator'].dirty"></p-message>                
                            </div>
                        </div>  
                       
                        <div class="field grid">
                            <label for="roleListSeparator" class="col-fixed label" style="width:15em">Role List Separator:</label>
                            <div class="col">
                                <input pInputText id="roleListSeparator" type="text" formControlName="roleListSeparator" [disabled]="isDisabled" 
                                    [size]="1" [minLength]="1" placeholder="Required" pTooltip="A one character that is used to separate out user roles that are stored in one field within the CSV file" tooltipPosition="top"/> 
                                <p-message severity="error" text="CSV separator is required" *ngIf="!userForm.controls['roleListSeparator'].valid&&userForm.controls['roleListSeparator'].dirty"></p-message>                
                            </div>
                        </div>  
                       
                        <div class="field grid">
                            <label for="userNameColumnPosition" class="col-fixed label" style="width:15em">User Name Column Position:</label>
                            <div class="col">
                                <input pInputText id="userNameColumnPosition" type="number" formControlName="userNameColumnPosition" [style]="{'width': '40%'}" [disabled]="isDisabled" 
                                    [size]="1" placeholder="Required" pTooltip="Position of User Name column in the CSV file" tooltipPosition="top"/> 
                                <p-message severity="error" text="User name column position is required" *ngIf="!userForm.controls['userNameColumnPosition'].valid&&userForm.controls['userNameColumnPosition'].dirty"></p-message>                 
                            </div>
                        </div>  
                        
                        <div class="field grid">
                            <label for="userRolesColumnPosition" class="col-fixed label" style="width:15em">User Role(s) Column Position:</label>
                            <div class="col">
                                <input pInputText id="userRolesColumnPosition" type="number" formControlName="userRolesColumnPosition" [style]="{'width': '40%'}" [disabled]="isDisabled" 
                                    [size]="1" placeholder="Required" pTooltip="Position of User Roles column in the CSV file" tooltipPosition="top"/> 
                                <p-message severity="error" text="User role(s) column position is required" *ngIf="!userForm.controls['userRolesColumnPosition'].valid&&userForm.controls['userRolesColumnPosition'].dirty"></p-message>                 
                            </div>
                        </div>  
        
                        <div class="field grid">
                            <label for="emailAddressColumnPosition" class="col-fixed label" style="width:15em">Email Address Column Position:</label>
                            <div class="col">
                                <input pInputText id="emailAddressColumnPosition" type="number" formControlName="emailAddressColumnPosition" [style]="{'width': '40%'}" [disabled]="isDisabled" 
                                    [size]="1" placeholder="Required" pTooltip="Position of Email Address column in the CSV file" tooltipPosition="top"/> 
                                <p-message severity="error" text="Email address column position is required" *ngIf="!userForm.controls['emailAddressColumnPosition'].valid&&userForm.controls['emailAddressColumnPosition'].dirty"></p-message>                 
                            </div>
                        </div>  
        
                        <div class="field grid">
                            <label for="fullNameColumnPosition" class="col-fixed label" style="width:15em">Full Name Column Position:</label>
                            <div class="col">
                                <input pInputText id="fullNameColumnPosition" type="number" formControlName="fullNameColumnPosition" [style]="{'width': '40%'}" [disabled]="isDisabled" 
                                    [size]="1" placeholder="Required" pTooltip="Position of User's Full Name column in the CSV file" tooltipPosition="top"/> 
                                <p-message severity="error" text="Full name column position is required" *ngIf="!userForm.controls['fullNameColumnPosition'].valid&&userForm.controls['fullNameColumnPosition'].dirty"></p-message>                 
                            </div>
                        </div>  
       
            
                    </div>
        
        
                </div>
    
        </form>

        <div class="col-12" *ngIf="isResultMsgShown">
            <mat-label>{{resultMsg}}</mat-label>
        </div>

        <p-footer>
                <mat-icon class="nav-caption wal-center">people</mat-icon>
            </p-footer>
        </p-card>
    </div>
    
    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
    </div>
    

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="group-onboard"></p-confirmDialog>




