import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { PortalRoleService } from '../../services/admin/portal-role.service';
import { SharedService } from '../../services/shared.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { IPortalRole } from '../../interfaces/portal-role';
import { IPortalPrivilege } from '../../interfaces/portal-privilege';

// export interface Privilege {
//   privilegeId?: number;
//   privilegeName?: string;
// }

@Component({
  selector: 'app-portal-roles',
  templateUrl: './portal-roles.component.html',
  styleUrls: ['./portal-roles.component.css']
})
export class PortalRolesComponent implements OnInit {

  isSubmitShown: boolean = false;
  displayDialog: boolean = false;

  role!: IPortalRole;
  privileges: IPortalPrivilege[] = [];
  roles: IPortalRole[] = [];
  roleCols: any[] = [];
  selectedRoleId!: number;
  selectedRole!: any;
  newRoleName!: string;  

  availablePrivileges: IPortalPrivilege[] = [];
  grantedPrivileges: IPortalPrivilege[] = [];

  // availablePrivileges: Privilege[] = [];
  // grantedPrivileges: Privilege[] = [];

  roleUsers: any[] = [];
  userCols: any[] = [];

  constructor(
    private roleService: PortalRoleService,
    private sharedService: SharedService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService ) { }

  ngOnInit(): void {
    this.roleCols = [
      { field: 'roleId', header: 'Role Id', width:'10%', display: 'table-cell' },
      { field: 'roleName', header: 'Role Name', width:'20%', display: 'table-cell' },
      { field: 'roleDescription', header: 'Role Description', width:'60%', display: 'table-cell' },
      { field: 'enabled', header: 'Enabled', width:'10%', display: 'table-cell' }
    ];
    this.userCols = [
      { field: 'userId', header: 'User Id', display: 'table-cell', width:'12%' },
      { field: 'userName', header: 'User Name', display: 'table-cell', width:'25%' },
      { field: 'locked', header: 'Locked', display: 'table-cell', width:'12%' },
      { field: 'enabled', header: 'Enabled', display: 'table-cell', width:'12%'},
      { field: 'emailAddress', header: 'Email Address', display: 'table-cell', width:'39%'}
    ];
    this.role = {
      roleId: 0, roleName: '', roleDescription: '', enabled: true 
    };
    this.getPrivileges(); 
    this.getRoles();
    if (this.userContextService.userHasPrivilege('PRIV_WA_ROLE_MODIFY')) {
      this.isSubmitShown = true;
    }
  }


  onSearchClick(event: void) {
    this.displayDialog = false;
    this.getRoles();
  }

  getPrivileges(): void {
    this.roleService.getPrivileges()
        .subscribe (list => {
          this.privileges = list;
        });
  } 
  
  getRoles(): void {
    this.roleService.getRoles()
        .subscribe (list => {
          this.roles = list;
        });
  } 
  
  addRole() {
    var privilege;
    this.availablePrivileges.length = 0;
    this.grantedPrivileges.length = 0;
    this.roleUsers.length = 0;
    this.role = {
      roleId: 0, roleName: '', roleDescription: '', enabled: true 
    };
    for (privilege of this.privileges) {
      this.availablePrivileges.push(privilege);
    }
    this.displayDialog = true;  
  }

  onRoleRowSelect(event: any) {
    var privilege;
    var selectedRoleName = event.data.roleName;
    this.selectedRoleId = event.data.roleId;
    this.roleUsers.length = 0
    this.availablePrivileges = [];
    this.grantedPrivileges = [];
    // Get role details
    this.roleService.getPortalRole(this.selectedRoleId)
    .subscribe (role => {
      if (role) {
        this.role = role;
        this.role.createDate = this.sharedService.formatFromISO(this.role.createDate || '');
        this.role.lastUpdateDate = this.sharedService.formatFromISO(this.role.lastUpdateDate || '');
        // this.displayDialog = true;

        // Get role users
        this.findRoleUsers (role.roleName);


        // Get role privileges
        // debugger;
        // this.roleService.getRolePrivileges(this.selectedRoleId)
        // .subscribe (list => {
        //   if (list) {
        //     debugger;
        //     for (privilege of this.privileges) {
        //       if (list.includes(privilege.privilegeName)) {
        //         // this.grantedPrivileges.push(privilege);
        //         this.grantedPrivileges.push(
        //           {
        //             privilegeId: privilege.privilegeId,
        //             privilegeName: privilege.privilegeName
        //           }
        //         );
        //       }
        //       else{
        //         // this.availablePrivileges.push(privilege);
        //         this.grantedPrivileges.push(
        //           {
        //             privilegeId: privilege.privilegeId,
        //             privilegeName: privilege.privilegeName
        //           }
        //         );
        //       }
        //     }
        //   } else {
        //     for (privilege of this.privileges) {
        //       this.availablePrivileges.push(privilege);
        //     }
        //   }
        //   debugger;
        // });
        // debugger;
        // // Get role users
        // this.roleService.getRoleUsers(selectedRoleName)
        // .subscribe (users => {
        //   this.roleUsers = users;
        // });
        // this.displayDialog = true;
      }
    });
  }

  findRolePrivileges() {
    var privilege;
    this.roleService.getRolePrivileges(this.selectedRoleId)
    .subscribe({
      next: (response) => {
        if (response) {
          for (privilege of this.privileges) {
            if (response.includes(privilege.privilegeName)) {
              this.grantedPrivileges.push(privilege);
            }
            else{
              this.availablePrivileges.push(privilege);
            }
          }
        } else {
          for (privilege of this.privileges) {
            this.availablePrivileges.push(privilege);
          }
        }
      },
      error: (error) => {
      },
      complete: () => {
        this.availablePrivileges = [...this.availablePrivileges];
        this.grantedPrivileges = [...this.grantedPrivileges];
        this.displayDialog = true;
      }
    });
  }

  findRoleUsers (roleName: string) {
    this.roleService.getRoleUsers(roleName)
    .subscribe({
      next: (response) => {
        this.roleUsers = response;
      },
      error: (error) => {
      },
      complete: () => {
        this.findRolePrivileges();
      }
    });
  } 

  deleteRole() {
    if (this.selectedRole) {    
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete selected role "' + this.role.roleName
         + '" and all its granted privileges?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.roleService.deleteRole(this.selectedRoleId) 
          .subscribe({
            next: (response) => {
              this.displayDialog = false;
              this.getRoles();
              this.processResult('Role "' + this.role.roleName + '" has been successfully deleted .');
            },
            error: (error) => {
              this.processResult(error);
            },
            complete: () => {
            }
          });
        },
        reject: () => {
        }
      });
    }
  }

  cancel() {
    this.role = {
      roleId: 0, roleName: '', roleDescription: '', enabled: true 
    };
    this.displayDialog = false;
  }

  onSaveRoleClick() {
    // Some basic validation
    if (!this.role.roleName) {
      this.confirmationService.confirm({
        message: 'The user record must have a non-empty Role Name.',
        header: 'Warning',
        key: "portal-role",
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });
    }
    // Get a list of granted privileges to assign then to the role
    var rolePrivileges : string[] = [];
    this.grantedPrivileges.forEach( privilege => {
      // rolePrivileges.push(privilege.privilegeName);
      rolePrivileges.push(privilege.privilegeName || '');
    })
      // Submit the request      
      if (this.role.roleId == null || this.role.roleId == 0) {
        this.roleService.addRole(this.role, rolePrivileges)
        .subscribe({
          next: (response) => {
            this.displayDialog = false;
            this.getRoles();
            this.processResult('The role record ' + this.role.roleName + ' has been successfully added.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      }
    else {
      this.roleService.modifyRole(this.role, rolePrivileges)
      .subscribe({
        next: (response) => {
          this.displayDialog = false;
          this.getRoles();
          this.processResult('The role record ' + this.role.roleName + ' has been successfully modified.');
        },
        error: (error) => {
          console.log("Error", error);
        },
        complete: () => {
        }
      });
    }

  }

  processResult(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Notification',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
  }  
}
