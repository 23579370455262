import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, query, stagger, group, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IPassTemplate } from '../../interfaces/pass-template';
import { IMessageTemplate } from '../../interfaces/message-template';
import { IOffer } from '../../interfaces/offer';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { SharedService } from "../../services/shared.service";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-message-templates',
  templateUrl: './message-templates.component.html',
  styleUrls: ['./message-templates.component.css'],
  animations: [,
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class MessageTemplatesComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "MessageTemplates";
  titleMessage = "Message Templates";
  userform!: FormGroup;
  isListShown = true;
  isDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;
  isCurrentPassTemplateShown = true;
  // isSelectPassTemplatesShown = true;
  isListOfMessageTemplatesShown = false;
  isPassTemplatesRedirect = false;

  messageTemplates: any[]  = [];
  cols: any[] = [];
  resultMsg = "";
  currentBrand: IBrand;
  currentPassTemplate: IPassTemplate;

  // passTemplateList = [];
  passTemplateId = 0;
  openBrandSelectionDialog!: boolean;
  openPassTemplateSelectionDialog!: boolean;

  hintMsg = 'Hint! For the Header above, 99% of the time you may use this default value below: '
    +'#{wal.formatDateInTimezone(__MsgDateTime__,"M/d/yyyy h:mm a z", "America/New_York")}';

    dummyOffer: IOffer = {
      offerId: 0,
      brandCode: '',
      offerName: '', itemDesc: ''
  }    

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private userContextService: UserContextService,    
    private router: Router,
    private confirmationService: ConfirmationService
  ) {
    this.currentBrand = this.sharedService.getDummyBrand();
    this.currentPassTemplate = this.sharedService.getDummyPassTemplate();
   }

  ngOnInit(): void {
    // Define the columns on the grid
    this.cols = [
      { field: 'messageTemplateId', header: 'Id', display: 'table-cell', width:'8%'  },
      { field: 'messageTemplateName', header: 'Message Name', display: 'table-cell', width:'24%'  },
      { field: 'messageTemplateCode', header: 'Message Code', display: 'table-cell', width:'24%'  },
      { field: 'messageTemplateDescription', header: 'Message Description', display: 'table-cell', width:'20%'  },
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'table-cell', width:'14%'  },
    ]; 

    this.userform = this.fb.group({
      'messageTemplateId': new FormControl(''),
      'messageTemplateCode': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'messageTemplateName': new FormControl('', [Validators.required, Validators.maxLength(100)]),
      'messageTemplateDescription': new FormControl('', [Validators.maxLength(1000)]),
      'messageTemplateHeader': new FormControl('', [Validators.maxLength(100)]),
      'messageTemplateText': new FormControl('', [Validators.maxLength(2000)]),
      'linkUrl': new FormControl('', [Validators.maxLength(255)]),
      'linkHeader': new FormControl('', [Validators.maxLength(100)]),
      'linkText': new FormControl('', [Validators.maxLength(255)]),
      'enabled': new FormControl(true),
      'alwaysReplacePreviousMsg': new FormControl(false),
      'alwaysReplaceAllMessages': new FormControl(false),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
    });

    debugger;
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.openBrandSelectionDialog = false;
      var passTemplate = this.sharedService.getCurrentPassTemplate();
      debugger;
      if (passTemplate) {
        if (passTemplate.brandCode == this.currentBrand.brandCode) {
          this.currentPassTemplate = passTemplate;
          // this.titleMessage = this.makeTitleMessage (this.currentBrand, this.currentPassTemplate.passName);
          this.child.makeTitleMessage(this.currentBrand, this.titleMessage, this.dummyOffer, this.currentPassTemplate);
          this.getMessageTemplates(this.currentBrand.brandCode, this.currentPassTemplate.passId, this.currentBrand.baseUrl);
          this.isPassTemplatesRedirect = true;
          return;
        }
      }
      this.getPassTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      // this.currentBrand = {brandCode: '', brandName: ''};
      this.currentPassTemplate = {passId: 0, passtypeName: '', brandCode: '', gwAllowMultipleUsers: false, passName: ''};
      this.openBrandSelectionDialog = true;
    }
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_MSG_TEMPLATE_MODIFY')) {
      this.isSubmitShown = true;
    }
    
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      // this.titleMessage = this.currentBrand.brandName + " ( " + this.currentBrand.brandCode + " ) > " + "Message Templates";
      this.isCurrentBrandShown = false;
      this.sharedService.setCurrentBrand(item);
      this.getPassTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      // this.titleMessage = this.makeTitleMessage (this.currentBrand, null);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
    }
  }

  // When the user opens up the dialog to select a pass template 
  onChoosePassTemplateClick(event: any) {
    this.openPassTemplateSelectionDialog = true;
  }

  // When a pass template is chosen
  choosePassTemplate(item: IPassTemplate) {
    this.openPassTemplateSelectionDialog = false;
    if (item != null) {
      this.isCurrentPassTemplateShown = false;
      this.currentPassTemplate = item;
      this.getMessageTemplates(this.currentBrand.brandCode, item.passId, this.currentBrand.baseUrl);
      // this.titleMessage = this.makeTitleMessage (this.currentBrand, this.currentPassTemplate.passName);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage, this.dummyOffer, this.currentPassTemplate);
    }
  }

  // Select all existing brand pass templates, then make the first of them as the current
  getPassTemplates(brandCode: string, baseUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getPassTemplates(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          if (response.length > 0) {
            this.currentPassTemplate = {
              passId: response[0].passId, passtypeName: response[0].passtypeName, brandCode: response[0].brandCode, 
              gwAllowMultipleUsers: response[0].gwAllowMultipleUsers, passName: response[0].passName
            };
            // this.titleMessage = this.makeTitleMessage (this.currentBrand, this.currentPassTemplate.passName);
            this.child.makeTitleMessage(this.currentBrand, this.titleMessage, this.dummyOffer, this.currentPassTemplate);
            this.getMessageTemplates(brandCode, this.currentPassTemplate.passId, baseUrl);
          }
        }            
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }


  // onChangePassTemplate(event) {
  //   this.getMessageTemplates(this.currentBrand.brandCode, this.passTemplateId, this.currentBrand.baseUrl);
  //   this.isListOfMessageTemplatesShown = true;
  // }

  // Select all existing message templates
  getMessageTemplates(brandCode: string | null, passTemplateId: number | null, baseUrl: string | null) {
    if (!brandCode || !passTemplateId || !baseUrl) {
      return;
    }
    // Call the service to invoke a Web API call
    this.brandProfileService.getMessageTemplates(brandCode, passTemplateId, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.messageTemplates = response;
          // Format the dates
          for(let i = 0; i < response.length; i++){
            if (response[i].createDate) {
              response[i].createDate = this.sharedService.formatFromISO(response[i].createDate);
            }
            if (response[i].lastUpdateDate) {
              response[i].lastUpdateDate = this.sharedService.formatFromISO(response[i].lastUpdateDate);
            }
          }
          this.isListOfMessageTemplatesShown = true;
        }            
      },
      error: (error) => {
        console.log("Error", error.message);
        // this.resultMsg = error.message;
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onSubmit(value: string) {
    let messageTemplate: IMessageTemplate = {
      brandCode: this.currentBrand.brandCode,
      passId: this.currentPassTemplate.passId,
      messageTemplateId: this.userform.value.messageTemplateId,
      messageTemplateCode: this.userform.value.messageTemplateCode,
      messageTemplateName: this.userform.value.messageTemplateName, 
      messageTemplateDescription: this.userform.value.messageTemplateDescription,
      messageTemplateHeader: this.userform.value.messageTemplateHeader, 
      messageTemplateText: this.userform.value.messageTemplateText, 
      linkUrl: this.userform.value.linkUrl, 
      linkHeader: this.userform.value.linkHeader,
      linkText: this.userform.value.linkText, 
      enabled: this.userform.value.enabled,
      alwaysReplacePreviousMsg: this.userform.value.alwaysReplacePreviousMsg, 
      alwaysReplaceAllMessages: this.userform.value.alwaysReplaceAllMessages,
      createDate: this.userform.value.createDate,
      lastUpdateDate: this.userform.value.lastUpdateDate
    };
    // Call the service to invoke a Web API call
    this.brandProfileService.submitMessageTemplate(this.currentBrand.brandCode, this.currentPassTemplate.passId,
           messageTemplate, this.currentBrand.baseUrl)
           .subscribe({
            next: (response) => {
              if (messageTemplate.messageTemplateId) {
                this.alertTheUser('Successfully modified message template ' + messageTemplate.messageTemplateCode
                 + ' from pass template ' + this.currentPassTemplate.passName);
              } else {
                this.alertTheUser('Successfully added message template ' + messageTemplate.messageTemplateCode
                 + ' to pass template ' + this.currentPassTemplate.passName);
              }
              this.getMessageTemplates(this.currentBrand.brandCode, this.currentPassTemplate.passId, this.currentBrand.baseUrl);
              this.isListShown = true;
              this.isDetailsShown = false;
            },
            error: (error) => {
              this.alertTheUser(error.message);
            },
            complete: () => {
            }
          });
  }

  cancelDetails() {
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  showDialogToAdd() {
    this.userform.setValue({messageTemplateId: null, messageTemplateCode: '', messageTemplateName: '', messageTemplateDescription: null,
      messageTemplateHeader: null, messageTemplateText: null, linkUrl: null, linkHeader: null, linkText: null, enabled: true,
      alwaysReplacePreviousMsg: false, alwaysReplaceAllMessages: false,
      createDate: null,
      lastUpdateDate: null
    });
    this.isListShown = false;
    this.isDetailsShown = true;
  }

  // Modify existing message template
  onRowEdit(event: any) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getMessageTemplate(this.currentBrand.brandCode, this.currentPassTemplate.passId, 
      event.messageTemplateId, this.currentBrand.baseUrl)
      .subscribe({
        next: (response) => {
          this.userform.setValue({messageTemplateId: response.messageTemplateId, messageTemplateCode: response.messageTemplateCode, 
            messageTemplateName: response.messageTemplateName, 
            messageTemplateDescription: response.hasOwnProperty('messageTemplateDescription') ? response.messageTemplateDescription : null,
            messageTemplateHeader: response.hasOwnProperty('messageTemplateHeader') ? response.messageTemplateHeader : null, 
            messageTemplateText: response.hasOwnProperty('messageTemplateText') ? response.messageTemplateText : null,
            linkUrl: response.hasOwnProperty('linkUrl') ? response.linkUrl : null, 
            linkHeader: response.hasOwnProperty('linkHeader') ? response.linkHeader : null, 
            linkText: response.hasOwnProperty('defaullinkTexttValue') ? response.linkText : null, 
            enabled: response.enabled,
            alwaysReplacePreviousMsg: response.alwaysReplacePreviousMsg, alwaysReplaceAllMessages: response.alwaysReplaceAllMessages,
            createDate: this.sharedService.formatFromISO(response.createDate),
            lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate)})
          this.isListShown = false;
          this.isDetailsShown = true;
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the variable
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the message template ' + event.messageTemplateCode + ' ?',
      header: 'Confirmation',
      key: 'message-templates',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteMessageTemplate(this.currentBrand.brandCode, this.currentPassTemplate.passId,
           event.messageTemplateId, this.currentBrand.baseUrl)
           .subscribe({
            next: (response) => {
              this.alertTheUser('Successfully deleted variable ' + event.messageTemplateCode
              + ' from pass template ' + this.currentPassTemplate.passName);
              this.getMessageTemplates(this.currentBrand.brandCode, this.currentPassTemplate.passId,
               this.currentBrand.baseUrl);
            },
            error: (error) => {
              this.alertTheUser(error.message);
            },
            complete: () => {
            }
          });
      },
      reject: () => {
      }
    });
  }

  onRowClone(event: any) {
  }  

  passTemplatesRedirect(event: any)  {
    this.router.navigate(['/brandPassTemplates']);    
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'message-templates',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  // makeTitleMessage (currentBrand, passName) {
  //   var title = '';
  //   if (currentBrand.brandCode) {
  //     title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) &emsp; >> &emsp;';
  //   }
  //   if (passName) {
  //     title += 'Pass Template: ' + this.currentPassTemplate.passName + ' &emsp; >> &emsp;'
  //   }
  //   title += 'Message Templates';
  //   // Display the brand logo image, if possible    
  //   this.brandLogoImage = null;
  //   this.isbrandLogoImageShown = false;
  //   if (currentBrand.logoImageContent) {
  //     this.brandLogoImage = 'data:' + currentBrand.logoImageType + ';base64,' + currentBrand.logoImageContent;
  //     this.isbrandLogoImageShown = true;
  //   }
  //   return title;
  // }

}
