import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators'
import jwt_decode from "jwt-decode";
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { IAuthTokenResponse } from '../../interfaces/auth-token-response';
import { IAuthToken } from '../../interfaces/auth-token';
import { ErrorProcessorService }  from "../error-processor.service";
import { TokenStorageService } from './token-storage.service';

const SCOPE = 'mobills-portal'; // ei-blond to make the token expire in one minute
const GRANT_TYPE = 'password';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService,
    private tokenStorageService: TokenStorageService
  ) { }

  // Prrepare, then make a request to obtain an auth token
  public getAuthToken(userName: string, password: string): Observable<IAuthTokenResponse> {
    let tokenRquestUrl = this.getAuthServerUrl() + '/token';  
    let body = `grant_type=${GRANT_TYPE}&username=${userName}&password=${password}&scope=${SCOPE}`;
    let headers = this.getAuthHeaders();
    // console.log('getAuthToken', tokenRquestUrl);
    return this.http.post<IAuthTokenResponse>(tokenRquestUrl, body, {headers: headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Prrepare, then make a request to obtain an auth token
  public getRefreshedAuthToken(userName: string, refreshToken: string): Observable<IAuthTokenResponse> {
    let refreshTokenRquestUrl = this.getAuthServerUrl() + '/refresh-token';  
    let body = `grant_type=${GRANT_TYPE}&username=${userName}&refresh_token=${refreshToken}&scope=${SCOPE}`;
    let headers = this.getAuthHeaders();
    return this.http.post<IAuthTokenResponse>(refreshTokenRquestUrl, body, {headers: headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  private getAuthServerUrl() {
    // return AppConfigService.settings.apiServer.authServerUrl;
    return environment.apiServer.authServerUrl;
  }

  private getAuthHeaders() {
    // let basicAuth = AppConfigService.settings.appSetting.portalAuth;
    let basicAuth = environment.appSetting.portalAuth;
    let headers = new HttpHeaders()
      .set('Authorization', basicAuth)
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return headers;
  }


  // Verify whether the auth token has not expired yet
  public isTokenValid(): Observable<boolean> {
    let isTokenExpired: boolean = false;
    let tokenString: string = this.tokenStorageService.getAuthToken() || '';
    if (tokenString) {
      let authToken : IAuthToken = jwt_decode(tokenString);
      const now = new Date();
      var utcEpoch = now.getTime();
      console.log('utcEpoch', utcEpoch);
      console.log('authToken.exp', authToken.exp * 1000);
      isTokenExpired = authToken.exp * 1000 > utcEpoch;
    }
    return of(isTokenExpired);
  }

  public isUserAuthorized(role?: string, roles?: string[], privilege?: string, privileges?: string[]): Observable<boolean> {
    let hasPrivilege: boolean = false;
    const currentUser = this.tokenStorageService.getCurrentUser();

    // Verify that the user exists and has at least one required application role or privilege
    if (currentUser) {
        if (currentUser.userName && (Array.isArray(currentUser.userRoleList) && currentUser.userRoleList.length)
            && (Array.isArray(currentUser.userPrivilegeList) && currentUser.userPrivilegeList.length)) {

            // If the page requires a specific role - make sure it was granted to the user
            if (role) {
                if (currentUser.userRoleList.indexOf(role) >= 0) {
                    hasPrivilege = true;
                }
            }

            // If the page requires at least one the specified roles - make sure it was granted to the user
            else if (roles) {
                roles.forEach(role => {
                    if (currentUser.userRoleList) {
                      if (currentUser.userRoleList.indexOf(role) >= 0) {
                        hasPrivilege = true;
                      }
                    }                  
                  })
            }

            // If the page requires a specific privilege - make sure it was granted to the user
            else if (privilege) {
                if (currentUser.userPrivilegeList.indexOf(privilege) >= 0) {
                    hasPrivilege = true;
                }
            }

            // If the page requires at least one the specified privilege - make sure it was granted to the user
            else if (privileges) {
                privileges.forEach(privilege => {
                    if (currentUser.userPrivilegeList) {
                      if (currentUser.userPrivilegeList.indexOf(privilege) >= 0) {
                        hasPrivilege = true;
                      }
                    }
                  })
            }

            // The page does not require a specific role - the user is authorised to use it since he/she has at least one application role
            else {
                hasPrivilege = true;
            }
        }
    }
    if (hasPrivilege) {
        return of(true);
    } 


    // // the user is already logged in, but does not have the role to the page
    // if (currentUser) {
    //     if (currentUser.userName) {
    //         // alert('You do not have the role to access selected web page');
    //         this.confirmationService.confirm({
    //             message: 'You do not have the role to access selected web page',
    //             header: 'Warning',
    //             icon: 'pi pi-exclamation-triangle',
    //             acceptLabel: "Ok",
    //             rejectVisible: false,
    //             accept: () => {
    //             }
    //         }); 
    //     }
    // }
    // // not logged in, so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return of(false);

  }

}
