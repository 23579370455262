import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IAccountStateInfo } from '../../interfaces/account-state-info';
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class AccountInfoService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;

  private accountInfoUrl = '{baseUrl}/admin/acccount-info/{brandCode}/{extAccountId}';  // PRIV_WAL_ACCOUNT_INFO

  private headers = new HttpHeaders()
  .set('Authorization', this.basicAuth);

  private baseHeaders = new HttpHeaders()
  .set('Authorization', this.baseAuth);
    
  constructor(
    private http: HttpClient, 
    private sharedService: SharedService,
    private errorProcessorService: ErrorProcessorService) 
    { }

    getAccountInfo(baseUrl: string, brandCode: string, extAccountId: string): Observable<IAccountStateInfo> {
      // var url = baseUrl + "/admin/acccount-info/" + brandCode + "/" + extAccountId;
      var url = this.accountInfoUrl
          .replace('{baseUrl}',baseUrl)
          .replace('{brandCode}',brandCode)
          .replace('{extAccountId}',extAccountId);
        return this.http.get<IAccountStateInfo>(url, {responseType: 'json'})
        .pipe(
          catchError(this.errorProcessorService.handleError)
        )
      }

}
