import { Injectable } from '@angular/core';
import { IUserAuth } from '../../interfaces/user-auth';
import { NavigationStart, Router } from '@angular/router'; 
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Subscription } from "rxjs";

const AUTH_TOKEN = 'auth-token';
const REFRESH_TOKEN = 'auth-refresh-token';
const CURRENT_USER = 'current-user';

export let browserRefresh = false;

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private loginSbject = new Subject<any>();
  private browserRefreshSubject = new Subject<any>();
  subscription: Subscription;

  constructor(
    private router: Router,
  ) { 
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if (browserRefresh) {
          this.browserRefreshSubject.next(true);
        }
      }   
    }) 
  }

  // Save the auth token in the local storage
  public setAuthToken (authToken: string): void {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.setItem(AUTH_TOKEN, authToken);
  }

  // Retrieve the auth token from the local storage
  public getAuthToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN);
  }

  // Save the refresh token in the local storage
  public setRefreshToken (refreshToken: string): void {
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  // Retrieve the refresh token from the local storage
  public getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  // Set the current user in the local storage
  public setCurrentUser (user: IUserAuth): void {
    localStorage.removeItem(CURRENT_USER);
    localStorage.setItem(CURRENT_USER, JSON.stringify(user));
  }

  // Retrieve the current user from the local storage
  public getCurrentUser (): IUserAuth {
    let user = localStorage.getItem(CURRENT_USER);
    if (user) {
      return JSON.parse(user);
    }
    return {
      userName: '', 
      passwordChangeRequired: true
    };
  }

  // Retrieve a list of the brand codes that are specifically granted to the current user
  public getCurrentUserGrantedBrands(): string[] {
    let grantedBrands: string[] = [];
    var currentUser = this.getCurrentUser();
    if (currentUser.userBrandList) {
      currentUser.userBrandList.forEach(brandCode => {
        grantedBrands.push(brandCode);
      })
    }
    return grantedBrands;
  }

  // Find out the only brand code assigned to the current user, otherwise (no brands at all or multiple brands) return null
  public getCurrentUserAssignedBrand(): string {
    let grantedBrandCodes = this.getCurrentUserGrantedBrands();
    if (grantedBrandCodes) {
      if (grantedBrandCodes.length == 1) {
        return grantedBrandCodes[0];
      }      
    }
    return '';
  }

  // Broadcast the fact that login event took place
  sendLoginEvent() {
    this.loginSbject.next(true);
  }
  
  getLoginEvent(): Observable<any>{ 
    return this.loginSbject.asObservable();
  }
    
  signOut(): void {
    localStorage.clear();
    this.loginSbject.next(false);
  }

  public getBrowserRefreshEvent(): Observable<any>{ 
    return this.browserRefreshSubject.asObservable();
  }

}
