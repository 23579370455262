<!-- <h2>Account Info</h2>  -->

    <!--  S E L E C T I O N   C R I T E R I A  -->

<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<app-query-builder [displayQueryBuilderDialog] = "openQueryBuilderDialog" (queryBuilderEvent)="queryBuilderEvent($event)"></app-query-builder>

<div class="grid">
    <div class="col-7">
        <p-message severity="info" text="{{titleMessage}}"></p-message>
    </div>
   <div class="col-1">
       <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded">
        </button>
   </div>  
   <div class="col-1">
    <button pButton type="button" label="Query" icon="pi pi-sitemap" iconPos="left" (click)="onQueryBuilderClick($event)"  
         class="p-button-raised p-button-rounded">
     </button>
</div>  

</div> 

<!-- <div class="grid"> -->
    <div class="col-5">

        <!-- <p-card header="Account Info" subheader="" styleClass="ui-card-light"> -->

            <div class="div-criteria-two">

                <div class="grid">

                    <div class="col-5">
                        <div class="field grid">
                            <div class="col-1"></div>
                            <label for="extAccountId" class="col-fixed label" style="width: 10em">External Account Id: </label>
                            <div class="col-3">
                                <input pInputText id="extAccountId" [(ngModel)]="extAccountId" style="width: 10em"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-5" *ngIf="isAccountStatusVisibale">
                        <div class="field grid">
                            <div class="col-1"></div>
                            <label class="col-fixed label" style="width: 10em">Account Status: </label>
                            <div class="col-4">
                                <!-- <label style="color:black">{{accountStatus}}</label> -->
                                <input pInputText id="extAccountId" [(ngModel)]="accountStatus" style="width: 10em" readonly/>
                            </div>
                        </div>         
                    </div>
                    <div class="col-2">
<!-- 
                        <button pButton type="button" label="Find Account" class="ui-button-success margin-left" (click)="onSearchClick($event)"></button>
                         -->
                        <p-button type="button" label="Find Account" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
                    </div> 
                        
                </div>
        
            </div>
  
    </div>
<!-- </div>   -->

<div class="grid pt-5" [hidden]="customerpassHidden">

    <!--  C U S T O M E R P A S S  -->

    <div class="grid">
    
        <div class="col-10">

            <p-table #dtt [columns]="customerpassCols" [value]="customerpass" selectionMode="single" 
                tableStyleClass="prime-table" [showCurrentPageReport]="false" [paginator]="false" >
                <ng-template pTemplate="caption">
                  Customer Pass
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> 

        </div>

        <div class="col-1">
            <button pButton type="button"  icon="pi pi-angle-double-left"  (click)="onPriorCustomerpass($event)"  
                class="p-button-raised p-button-rounded"  [disabled]="priorDisabled"></button>
            <button pButton type="button"  icon="pi pi-angle-double-right"  (click)="onNextCustomerpass($event)"  
                class="p-button-raised p-button-rounded" [disabled]="nextDisabled"></button>
        </div>

    </div>  

</div>  

<div class="grid pt-5" [hidden]="customerpassHidden">

    <p-tabView>

<!--  P A S S R E G I S T E R S -->
    
        <p-tabPanel header="Pass Registers">

            <div class="col-10">

                <p-table #dtt [columns]="passregistersCols" [value]="passregisters" selectionMode="single" 
                    tableStyleClass="prime-table" [showCurrentPageReport]="true"
                    sortMode="multiple" [paginator]="true" [rows]="15">
                    <ng-template pTemplate="caption">
                        Pass Registers
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table> 

            </div>
    
        </p-tabPanel>
    
<!--  V A R V A L U E S  -->

        <p-tabPanel header="Variable Values">

            <div class="ui-g-5">

                <p-table #dtt [columns]="varValuesCols" [value]="varValues" selectionMode="single" 
                    tableStyleClass="prime-table" [showCurrentPageReport]="true"
                    sortMode="multiple" [paginator]="true" [rows]="15">
                    <ng-template pTemplate="caption">
                    Variable Values
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table> 

            </div>
    
        </p-tabPanel>

<!--  M E S S A G E S  -->        

        <p-tabPanel header="Notification Messages">

            <div class="col-10">

                <p-table #dtt [columns]="messagesCols" [value]="messages" selectionMode="single" 
                    tableStyleClass="prime-table" [showCurrentPageReport]="true"
                    sortMode="multiple" [paginator]="true" [rows]="15">
                    <ng-template pTemplate="caption">
                        Notification Messages
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table> 

            </div>
    
    
        </p-tabPanel>
    
        <p-tabPanel header="Account Events Log">

            <div class="col-10">

                <p-table #dtt [columns]="accountEventLogCols" [value]="accountEventLog" selectionMode="single" 
                        tableStyleClass="prime-table" [showCurrentPageReport]="false" [paginator]="true" [rows]="2" >
                    <ng-template pTemplate="caption">
                        Account Events Log
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table> 

            </div>
    
        </p-tabPanel>

        <p-tabPanel header="Clickthroughs Log" [hidden]="isClickthroughHidden">

            <div class="col-12">

                <p-table #dtt [columns]="clickthrouthLogCols" [value]="clickthrouthLog" selectionMode="single" 
                        tableStyleClass="prime-table" [showCurrentPageReport]="false" [paginator]="true" [rows]="15" >
                    <ng-template pTemplate="caption">
                        Clickthroughs Log
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table> 

            </div>
    
        </p-tabPanel>
    
    </p-tabView>
    
</div>
