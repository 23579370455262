import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BrandService } from '../../services/config/brand.service';
import { BrandStatsService } from "../../services/stats/brand-stats.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { SharedService } from "../../services/shared.service";
import { SummaryReportsService } from '../../services/reports/summary-reports.service';
import { IBrand } from '../../interfaces/brand';
import { IBrandPassEnrollmentStats } from '../../interfaces/brand-pass-enrollment-stats';
import { ICsrCount } from '../../interfaces/csr-count';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit {

  @ViewChild(Table) dt!: Table;

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;    

  titleMessage = "Client Dashboard";

  openBrandSelectionDialog: boolean = false;
  isBrandSelectionButtonShown: boolean = false;
  isSpinnerHidden: boolean = true;
  isDashboardHidden: boolean = false;
  isDetailsShown = false;

  currentBrand!: IBrand;
  selectedBrand!: string;
  clientBrandCode!: string;
  brandName!: string;
  baseUrl!: string;
  selectedBrandName!: string;

  selectedDateFrom!: string;
  selectedDateTo!: string;
  numOfMonths: number = 6;

  enrollmentCounts: any;
  enrollmentOptions: any;
  defaultDate: any;
  
  isNoDataFound: boolean = true;
  columnHeaders!: string[];

  enrollments = new Array<IBrandPassEnrollmentStats>();
  activeAccountsCols!: any[];

  csrStatsCols: any[] = [];
  csrStats: ICsrCount[] = [];


  constructor(
    private brandService: BrandService,
    private tokenStorageService: TokenStorageService,
    private brandStatsService: BrandStatsService,
    private summaryReportsService: SummaryReportsService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    // Prepopulate the dates
    this.selectedDateFrom = this.sharedService.getOneMonthAgoDate();
    this.selectedDateTo = this.sharedService.getCurrentDate();

    // Define the columns for active accounts
    this.activeAccountsCols = [
      { field: 'brandCode', header: 'Brand Code', display: 'table-cell', width:'8%'  },
      { field: 'description', header: 'Brand Description', display: 'table-cell', width:'20%'  },
      { field: 'passName', header: 'Pass Name', display: 'table-cell', width:'24%'  },
      { field: 'uniqueAccountsCnt', header: 'Active Accounts', display: 'table-cell', width:'12%'  },
      { field: 'last30AddsCnt', header: 'Last 30 Day Adds', display: 'table-cell', width:'12%'  },
      { field: 'last30RemovesCnt', header: 'Last 30 Day Removes', display: 'table-cell', width:'12%'  },
      { field: 'last30NetsCnt', header: 'Last 30 Day Nets', display: 'table-cell', width:'12%'  },
    ]; 

    // Define the columns for CSR grid
    this.csrStatsCols = [
      { field: 'emailSendCnt', header: 'Emails Sent', width:'33%', display: 'table-cell' },
      { field: 'smsSendCnt', header: 'SMS Sent', width:'33%', display: 'table-cell' },
      { field: 'totalSendCnt', header: 'Total', width:'33%', display: 'table-cell' }
    ]; 

        // Figure out whether the user has just one granted brand - in this case we can eliminate brand selection altogether
        let assignedBrand = this.tokenStorageService.getCurrentUserAssignedBrand();
        if (assignedBrand) {
          this.setSingleBrand(assignedBrand);
        } else {      
          this.isBrandSelectionButtonShown = true;
          this.currentBrand = this.sharedService.getCurrentBrand();
          if (this.currentBrand != null) {
            this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
            this.selectedBrand = this.currentBrand.brandCode || '';
            this.baseUrl = this.currentBrand.baseUrl || '';
            this.clientBrandCode = this.selectedBrand;
            this.getDashboardData();
            this.openBrandSelectionDialog = false;
          } else {
            this.openBrandSelectionDialog = true;
          } 
        }
  }

  // Set single brand as the default when the user has just one brand granted
  // Set single brand as the default when the user has just one brand granted
  setSingleBrand(brandCode: string) {
    this.isBrandSelectionButtonShown = false;
    this.isSpinnerHidden = false;
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe (brand => {
      this.currentBrand = brand;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.baseUrl = brand.baseUrl || '';
      this.selectedBrand = brand.brandCode || '';
      this.getDashboardData();
      this.isSpinnerHidden = true;
    });
  }

  // Find out brand properies from the IBrand object
  getBrandProperties(brand: IBrand) {
    // console.log('homeBiller: ', 'inside getBrandProperties');
    this.brandName = brand.brandName || '';
    this.clientBrandCode = brand.brandCode || '';
    this.baseUrl = brand.baseUrl || '';
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.getBrandProperties(item);
      this.sharedService.setCurrentBrand(item);
      this.selectedBrand = item.brandCode || '';
      this.selectedBrandName = " ( " + item.brandName + " )";
      this.baseUrl = item.baseUrl || '';
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.getDashboardData();
    }
  }

  // Retrieve all dashboard real-time data
  getDashboardData() {
    this.getEnrollmentStatsForBrand(this.clientBrandCode, this.baseUrl);
    this.getCsrStats(this.baseUrl, this.clientBrandCode, this.selectedDateFrom, this.selectedDateTo);
}

  // Get the enrollment stats for the current brand: active accounts, last 30 days activity
  getEnrollmentStatsForBrand(brandCode: string, baseUrl: string) {
    // console.log('homeBiller: ', 'inside getEnrollmentStatsForBrand');
    this.isSpinnerHidden = false;
    this.summaryReportsService.getEnrollmentStatsForBrand(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        this.enrollments = response;
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Process the CSR request
  getCsrStats(baseUrl: string, clientBrandCode: string, selectedDateFrom: string, selectedDateTo: string) {
    // console.log('homeBiller: ', 'inside getCsrStats');
    this.isSpinnerHidden = false;
    this.csrStats = [];
    this.brandStatsService.getCsrCounts(baseUrl, clientBrandCode, selectedDateFrom, selectedDateTo)
    .subscribe({
      next: (response) => {
        this.csrStats = response;
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

}
