import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { IEncryptionRequest } from '../../interfaces/encryption-request';
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class EncryptionUtilityService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;

  private encryptionUrl = this.baseUrl + '/common/encryption'; 

  private headers = new HttpHeaders()
  .set('Authorization', this.basicAuth)

  constructor(private http: HttpClient,
    private errorProcessorService: ErrorProcessorService) { }

  encryptText(environment: string, text: string) {
    let encryptionRequest: IEncryptionRequest = {
      environment: environment,
      text: text
    };
    return this.http.post(this.encryptionUrl, encryptionRequest, {headers: this.headers, responseType: 'text'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );
  }

}
