import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators,FormControl,FormGroup,FormBuilder,ValidatorFn } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { CommunicationManagerService } from '../../services/admin/communication-manager.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { ISubscriber } from '../../interfaces/subscriber';

@Component({
  selector: 'app-communication-subscribers',
  templateUrl: './communication-subscribers.component.html',
  styleUrls: ['./communication-subscribers.component.css']
})
export class CommunicationSubscribersComponent implements OnInit {
  
  newSubscriberForm!: FormGroup;
  newContactPointForm!: FormGroup;

  subscribers: ISubscriber[] = [];
  subscriberCols: any[] = [];
  selectedSubscriberId!: number;
  currentSubscriber!: ISubscriber;
  selectedSubscriberName!: string;
  // newSubscriberName: string;

  contactPoints: any[] = [];
  contactPointsCols: any[] = [];
  selectedContactPoint!: any;
  newContactPoint!: string;
  // newContactPointCode: string;
  // newContactPointType: string;
  contactPointCodes: any[] = [];
  selectedContactPointId!: number;

  attributes: any[] = [];
  attributesCols: any[] = [];
  selectedAttribute: any;
  attributeCodes: any;
  newAttributeCode!: string;
  newAttribute!: string;
  selectedAttributeId!: number;

  channels: any[] = [];
  channelsCols: any[] = [];
  selectedChannel: any;
  newSubContactPointId!: number;
  newChannelId!: number;
  channelCodes: any[] = [];
  subContactPoints: any[] = [];
  selectedChannelId!: number;
  selectedSubscriptionStatus!: boolean;
  changeSubscriptionLabel!: string;
  displayDialogDropContactPoint!: boolean;
  displayDialogDropAttribute!: boolean;

  hideSubscriber: boolean = true;
  displayDialogAddSubscriber: boolean = false;
  displayDialogAddContactPoint: boolean = false;
  displayDialogAddAttribute: boolean = false;
  displayDialogAddChannel: boolean = false;
  displayDialogDropChannel: boolean = false;
  isSubmitShown: boolean = false;
  isNewContactPointEmail: boolean = true;

  constructor(
    private fb: FormBuilder,
    private communicationManagerService: CommunicationManagerService,
    private userContextService: UserContextService,    
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.currentSubscriber = {
      subscriberId: 0,
      subscriberName: '',
      modifiedUser: '',
      modifiedDate: '',
      enabled: true 
    };
    this.subscriberCols = [
      { field: 'subscriberId', header: 'Subscriber Id', width:'30%', display: 'table-cell' },
      { field: 'subscriberName', header: 'Subscriber Name', width:'70%', display: 'table-cell' }
    ]; 
    this.contactPointsCols = [
      { field: 'contactPointId', header: 'Id', display: 'none' },
      { field: 'contactPointType', header: 'Type', width:'35%', display: 'table-cell' },
      { field: 'addressOrNumber', header: 'Address or Number', width:'65%', display: 'table-cell' },
    ]; 
    this.attributesCols = [
      { field: 'subscriberAttributeId', header: 'Id', display: 'none' },
      { field: 'attributeCode', header: 'Attribute Code', width:'45%', display: 'table-cell' },
      { field: 'attributeValue', header: 'Attribute Value', width:'55%', display: 'table-cell' }
    ]; 
    this.channelsCols = [
      { field: 'channelCode', header: 'Channel Code', width:'43%', display: 'table-cell' },
      { field: 'contactPoint', header: 'Contact Point', width:'42%', display: 'table-cell' },
      { field: 'active', header: 'Active', width:'15%', display: 'table-cell' },
      { field: 'channelId', header: 'channelId', width:'1%', display: 'none' },
      { field: 'contactPointId', header: 'contactPointId', width:'1%', display: 'none' },
    ]
    this.newSubscriberForm = this.fb.group({
      'newSubscriberName': new FormControl('', [Validators.required]),
      'newContactPointType': new FormControl(''),
      'newContactPoint': new FormControl(''),
      'newAttributeCode': new FormControl(''),
      'newAttribute': new FormControl(''),
    });
    this.newContactPointForm = this.fb.group({
      'newContactPointType': new FormControl('', [Validators.required]),
      'newContactPoint': new FormControl('', [Validators.required]),
    });

    this.channels = [];
    this.channelCodes = [];
    this.subContactPoints = [];
    this.contactPointCodes = [
      {label:'EMAIL_ADDRESS', value:'EMAIL_ADDRESS'}, 
      {label:'PHONE_NUMBER', value:'PHONE_NUMBER'}
    ];
    this.attributeCodes = [
      {label:'brandCode', value:'brandCode'}
    ];
    this.getSubscribers(false);
    this.getChannels();
    // Hide submit, add, drop buttons for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_CM_SUBSCRIPTION_MODIFY')) {
      this.isSubmitShown = true;
    }
    
  }

  onSearchClick(event: any) {
    this.getSubscribers(false);
    this.hideSubscriber = true;
  }

  getSubscribers(showSubscriber: boolean): void {
    this.communicationManagerService.getSubscribers()
    .subscribe({
      next: (response) => {
        this.subscribers = response;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
    if (showSubscriber && this.selectedSubscriberId) {
      this.getSubscriber(this.selectedSubscriberId);
    }
  }
  
  getChannels(): void {
    this.communicationManagerService.getChannels()
    .subscribe({
      next: (response) => {
        var channel;
        for (channel of response) {
          this.channelCodes.push({label: channel.channelCode, value: channel.channelId});
        }
    },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }
  
  onSubscriberRowSelect(event: any) {
    this.selectedSubscriberId = event.data.subscriberId;
    this.getSubscriber(this.selectedSubscriberId);
  }

  getSubscriber(subscriberId: number) {
    this.communicationManagerService.getSubscriber(subscriberId)
    .subscribe({
      next: (subscriber) => {
        this.currentSubscriber = subscriber;
        this.selectedSubscriberName = subscriber.subscriberName;
        this.contactPoints = subscriber.contactPoints || [];
        this.attributes = subscriber.attributes || [];
        this.selectedContactPointId = 0;
        this.selectedAttributeId = 0;
        this.selectedChannelId = 0;
        this.channels = subscriber.subscribedChannels || [];
        this.subContactPoints.length = 0;
        var contactPoint;
        if (subscriber.contactPoints) {
          for (contactPoint of subscriber.contactPoints) {
            this.subContactPoints.push({label: contactPoint.addressOrNumber, value: contactPoint.contactPointId});
          }
        }
        this.hideSubscriber = false;
        this.displayDialogDropChannel = false;
        this.displayDialogDropAttribute = false;
        this.displayDialogDropContactPoint = false;
        },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  addSubscriber() {
    this.newSubscriberForm.patchValue(
      {
        newSubscriberName: '', 
        newContactPointType: 'EMAIL_ADDRESS', 
        newContactPoint: '',  
        newAttributeCode: '',  
        newAttribute: ''
      });
    this.displayDialogAddSubscriber = true;
  }

  saveNewSubscriber(form: any) {
      let newSubscriberName = this.newSubscriberForm.value.newSubscriberName;
      let newContactPointType = this.newSubscriberForm.value.newContactPointType;
      let newContactPoint = this.newSubscriberForm.value.newContactPoint;
      let newAttributeCode = this.newSubscriberForm.value.newAttributeCode;
      let newAttribute = this.newSubscriberForm.value.newAttribute;
    this.communicationManagerService.addSubscriber(
      newSubscriberName, newContactPointType, newContactPoint, newAttributeCode, newAttribute)
      .subscribe({
        next: (response) => {
          this.hideSubscriber = true;
          this.displayDialogAddSubscriber = false;
          this.getSubscribers(true);
          this.processResult('The new subscriber has been successfully added.');
          },
        error: (error) => {
          this.processResult(error);
        },
        complete: () => {
        }
      });
  }

  saveSubscriberProperties() {
    this.communicationManagerService.modifySubscriber(
      this.currentSubscriber.subscriberId, this.currentSubscriber.subscriberName, this.currentSubscriber.enabled || false)
      .subscribe({
        next: (response) => {
          this.hideSubscriber = true;
          this.displayDialogAddAttribute = false;
          this.getSubscribers(true);
          this.processResult("Subscriber's record has been successfully modified.");
        },
        error: (error) => {
          this.processResult(error);
        },
        complete: () => {
        }
      });
  }

  cancelSubscriber() {
    this.displayDialogAddSubscriber = false;
  }

  deleteSubscriber() {
    if (this.selectedSubscriberName) {    
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete selected subscriber "' + this.selectedSubscriberName
         + '" and all its contact points and attributes?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.communicationManagerService.deleteSubscriber(this.selectedSubscriberName) 
          .subscribe({
            next: (response) => {
              this.hideSubscriber = true;
              this.getSubscribers(false);
              this.processResult('Subscriber "' + this.selectedSubscriberName + '" has been successfully deleted .');
            },
            error: (error) => {
              this.processResult(error);
            },
            complete: () => {
            }
          });
        }
      });
    }
  }

  onContactPointRowSelect(event: any) {
    this.selectedContactPointId = event.data.contactPointId;
    this.selectedContactPoint = event.data.addressOrNumber;
    this.displayDialogDropContactPoint = true;
  }

  addContactPoint() {
    // this.newContactPointCode = '';
    // this.newContactPoint = '';
    this.newContactPointForm.patchValue({newContactPointType: 'EMAIL_ADDRESS', newContactPoint: ''})
    this.displayDialogAddContactPoint = true;
  }

  saveContactPoint(form: any) {
    let newContactPointCode = this.newContactPointForm.value.newContactPointType;
    let newContactPoint = this.newContactPointForm.value.newContactPoint;
    this.communicationManagerService.addContactPointToSubscriber(this.selectedSubscriberId, newContactPointCode, newContactPoint)
    .subscribe({
      next: (response) => {
        this.hideSubscriber = true;
        this.displayDialogAddContactPoint = false;
        this.getSubscribers(true);
        this.processResult('The contact point has been successfully added to subscriber "' + this.selectedSubscriberName + '".');
      },
      error: (error) => {
        this.processResult(error);
      },
      complete: () => {
      }
    });
  }  

  cancelContactPoint() {
    this.newContactPointForm.patchValue({newContactPointType: 'EMAIL_ADDRESS', newContactPoint: ''})
    this.displayDialogAddContactPoint = false;
  }  

  dropContactPoint() {
    if (this.selectedContactPoint) {    
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete "' + this.selectedContactPoint 
            + '" contact point from "' + this.selectedSubscriberName + '" subscriber record?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.communicationManagerService.dropContactPointFromSubscriber(this.selectedSubscriberId, this.selectedContactPointId)
          .subscribe({
            next: (response) => {
              this.hideSubscriber = true;
              this.displayDialogAddContactPoint = false;
              this.getSubscribers(true);
              this.processResult('The contact point has been successfully deleted from subscriber "' + this.selectedSubscriberName + '".');
            },
            error: (error) => {
              this.processResult(error);
            },
            complete: () => {
            }
          });
        },
        reject: () => {
        }
      });
    }
  }

  onAttributesRowSelect(event: any)   {
    this.selectedAttribute = event.data.attributeValue;
    this.selectedAttributeId = event.data.subscriberAttributeId;
    this.displayDialogDropAttribute = true;
  }

  addAttribute() {
    this.displayDialogAddAttribute = true;
    this.newAttribute = '';
    this.newAttributeCode = '';
  }

  saveAttribute() {
    this.communicationManagerService.addAttributeToSubscriber(this.selectedSubscriberId, this.newAttributeCode, this.newAttribute)
    .subscribe({
      next: (response) => {
        this.hideSubscriber = true;
        this.displayDialogAddAttribute = false;
        this.getSubscribers(true);
        this.processResult('The attribute has been successfully added to subscriber "' + this.selectedSubscriberName + '".');
      },
      error: (error) => {
        this.processResult(error);
      },
      complete: () => {
      }
    });
  }  

  cancelAttribute() {
    this.displayDialogAddAttribute = false;
  }  

  dropAttribute() {
    if (this.selectedAttribute) {    
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete "' + this.selectedAttribute 
            + '" attribute from "' + this.selectedSubscriberName + '" subscriber record?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.communicationManagerService.dropAttributeFromSubscriber(this.selectedSubscriberId, this.selectedAttributeId)
          .subscribe({
            next: (response) => {
              this.hideSubscriber = true;
              this.displayDialogAddAttribute = false;
              this.getSubscribers(true);
              this.processResult('The attribute has been successfully deleted from subscriber "' + this.selectedSubscriberName + '".');
            },
            error: (error) => {
              this.processResult(error);
            },
            complete: () => {
            }
          });
        },
        reject: () => {
        }
      });
    }
  }

  onChannelRowSelect (event: any) {
    this.selectedChannelId = event.data.channelId;
    this.selectedChannel = event.data.channelCode;
    this.selectedContactPointId = event.data.contactPointId;
    this.selectedContactPoint = event.data.contactPoint;
    this.selectedSubscriptionStatus = event.data.active;
    if (this.selectedSubscriptionStatus) {
      this.changeSubscriptionLabel = "Deactivate";
    } else {
      this.changeSubscriptionLabel = "Reactivate";
    }
    this.displayDialogDropChannel = true;
  }

  addChannel() {
    this.displayDialogAddChannel = true;
    this.newSubContactPointId = 0;
    this.newChannelId = 0;
  }

  saveChannel() {
    this.communicationManagerService.subscribeContactPointToChannel(this.newChannelId, this.newSubContactPointId)
    .subscribe({
      next: (response) => {
        this.hideSubscriber = true;
        this.displayDialogAddChannel = false;
        this.getSubscribers(true);
        this.processResult('The contact point has been successfully subscribed to the channel.');
      },
      error: (error) => {
        this.processResult(error);
      },
      complete: () => {
      }
    });
  }  

  cancelChannel() {
    this.displayDialogAddChannel = false;
  }  

  dropChannel() {
    if (this.selectedContactPointId == null || this.selectedChannelId == null) {    
      this.confirmationDialog("Please select a contact point and a channel to indicate your unsubscribe requirement.");
      return;
    }
    this.confirmationService.confirm({
        message: 'Are you sure that you want to unsubscribe "' + this.selectedContactPoint 
            + '" contact point from "' + this.selectedChannel + '" channel?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.communicationManagerService.unsubscribeContactPointFromChannel(this.selectedChannelId, this.selectedContactPointId)
          .subscribe({
            next: (response) => {
              this.hideSubscriber = true;
              this.displayDialogAddAttribute = false;
              this.getSubscribers(true);
              this.processResult('The contact point has been successfully unsubscribed from the channel.');
            },
            error: (error) => {
              this.processResult(error);
            },
            complete: () => {
            }
          });
        },
        reject: () => {
        }
      });
  }

  changeSubscriptionStatus() {
    if (this.selectedContactPointId == null || this.selectedChannelId == null) {    
      this.confirmationDialog("Please select a contact point and a channel to indicate your (de-)activate requirement.");
      return;
    }
    var msg: string;
    if (this.selectedSubscriptionStatus) {
      msg = 'Are you sure that you want to deactivate subscription to "' +  this.selectedChannel
       + '" channel for contact point "'+ this.selectedContactPoint  + '"?' 
    } else {
      msg = 'Are you sure that you want to reactivate subscription to "' +  this.selectedChannel
       + '" channel for contact point "'+ this.selectedContactPoint  + '"?' 
    }
    this.confirmationService.confirm({
        message: msg,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.communicationManagerService.changeSubscriptionStatus(this.selectedChannelId, this.selectedContactPointId,
            !this.selectedSubscriptionStatus)
            .subscribe({
              next: (response) => {
                this.hideSubscriber = true;
                this.displayDialogAddAttribute = false;
                this.getSubscribers(true);
                this.processResult('The contact point has been successfully unsubscribed from the channel.');
              },
              error: (error) => {
                this.processResult(error);
              },
              complete: () => {
              }
            });
        },
        reject: () => {
        }
      });
  }

  confirmationDialog(msg: string): boolean {
     this.confirmationService.confirm({
       message: msg,
       header: 'Warning',
       icon: 'pi pi-exclamation-triangle',
       acceptLabel: "Ok",
       rejectVisible: false,
       acceptButtonStyleClass: "p-button-info  p-button-rounded",
       accept: () => {
             return true;
           }
     });
     return false;
  }

  processResult(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Notification',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
  }

  onChangeNewContactPointType (event: any) {
    let newContactPointType = event.value;
    if (newContactPointType == 'EMAIL_ADDRESS') {
      this.isNewContactPointEmail = true;
    } else {
      this.isNewContactPointEmail = false;
    }
  }   

}
