<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-7">
        <p-message severity="info" text="{{titleMessage}}"></p-message>
    </div>
   <div class="col-1">
       <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded">
        </button>
   </div>  
</div> 

<div class="grid">

    <div class="col-6">

        <p-card header="Broadcasting Criteria" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">

                <div>

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="passId" class="col-fixed label" style="width: 15em">Pass Template:</label>
                        <div class="col">
                            <p-dropdown id ="passId" [options]="passTemplates" formControlName="passId" [style]="{'width': '60%'}" 
                                (onChange)="onChangePassTemplate($event)"
                                [filter]="false" [editable]="false" pTooltip="Select the pass template" tooltipPosition="top"> 
                            </p-dropdown>
                            <p-message severity="error" text="Pass template is required" *ngIf="!userform.controls['passId'].valid&&userform.controls['passId'].dirty"></p-message>                 
                        </div>
                    </div>

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="messageTemplateCode" class="col-fixed label" style="width: 15em">Message Template:</label>
                        <div class="col">
                            <p-dropdown id ="messageTemplateCode" [options]="messageTemplates" formControlName="messageTemplateCode" [style]="{'width': '60%'}" 
                                (onChange)="onChangeMessageTemplate($event)"
                                [filter]="false" [editable]="false" pTooltip="Select the message template" tooltipPosition="top"> 
                            </p-dropdown>
                            <p-message severity="error" text="Message template is required" *ngIf="!userform.controls['messageTemplateCode'].valid&&userform.controls['messageTemplateCode'].dirty"></p-message>                 
                        </div>
                    </div>

                    <div class="field grid" *ngIf="isTemplateShown">
                        <div class="col-1"></div>
                        <label for="templateHeader" class="col-fixed label" style="width: 15em">Template Header:</label>
                        <div class="col">
                            <textarea pInputTextarea id="templateHeader" style="width: 100%;" [rows]="2" [cols]="36" 
                                pTooltip="The header of the message template" tooltipPosition="top" 
                                formControlName="templateHeader" > 
                            </textarea>
                            <p-message severity="error" text="Template header is required" *ngIf="!userform.controls['templateHeader'].valid&&userform.controls['templateHeader'].dirty"></p-message>                 
                        </div>
                    </div>

                    <div class="field grid" *ngIf="isTemplateShown">
                        <div class="col-1"></div>
                            <label for="templateText" class="col-fixed label" style="width: 15em">Template Text:</label>
                        <div class="col">
                            <textarea pInputTextarea id="templateText" style="width: 100%;" [rows]="2" [cols]="36" 
                                pTooltip="The text of the message template" tooltipPosition="top" 
                                formControlName="templateText" > 
                            </textarea>
                            <p-message severity="error" text="Template text is required" *ngIf="!userform.controls['templateText'].valid&&userform.controls['templateText'].dirty"></p-message>                 
                        </div>
                    </div>

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="allAccountsInd" class="col-fixed label" style="width: 15em">Send to All Accounts?:</label>
                        <div class="col-1">
                            <p-checkbox formControlName="allAccountsInd" id="allAccountsInd" [binary]="true" (click)="onAllAccountsIndClick($any($event))"></p-checkbox>
                        </div>
                    </div>

                    <div class="field grid" *ngIf="isAccountListVisible">
                        <div class="col-1"></div>
                            <label for="accountList" class="col-fixed label" style="width: 15em">List of Accounts:</label>
                        <div class="col">
                            <textarea [rows]="3" [cols]="36" pInputTextarea [autoResize]="autoResize" id="accountList" 
                                formControlName="accountList" style="width: 95%;">
                            </textarea>
                        </div>
                    </div>

                    <div class="col-12">
                        <div  class="col-2" *ngIf="isSubmitShown" class="row container-fluid"  id="divshow">
    <!-- 
                            <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" ></button>
    -->
                            <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!userform.valid"></p-button>
                            
                        </div> 
                        <div class="col-10" *ngIf="!isSubmitShown">
                            <mat-label>{{resultMsg}}</mat-label>
                        </div>
                    </div>

                </div>

            </form>

            <p-footer>
                <mat-icon class="nav-caption wal-center">list_all</mat-icon>
            </p-footer>

        </p-card>

    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="message-broadcasting"></p-confirmDialog>
