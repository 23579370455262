import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
//import * as fileSaver from 'angular-file';
import * as fileSaver from 'file-saver';
import { PortalUserService } from '../../services/admin/portal-user.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { RtpnTransactionsService } from '../../services/rtpn/rtpn-transactions.service';
import { SharedService } from "../../services/shared.service";
import { IFileCreationSession }  from '../../interfaces/file-creation-session';

export const StrongPasswordRegx: RegExp =
  /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  isSpinnerHidden: boolean = true;

  userId!: number;
  userName!: string;
  emailAddress!: string;
  grantedBrands!: string;
  existingPassword!: string;
  newPassword!: string;
  newEmailAddress!: string;
  confirmPassword!: string;

  isProfileFrameShown: boolean = true;
  isChangePasswordFrameShown: boolean = false;
  isChangeEmailAddressFrameShown: boolean = false;

  fileRequestCols: any[] = [];
  fileRequests: IFileCreationSession[] = [];
  selectedFileRequest: IFileCreationSession = <IFileCreationSession> {};

  fileUrl!: any;

  public passwordForm!: FormGroup;
  public emailForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private portalUserService: PortalUserService, 
    private tokenStorageService: TokenStorageService,
    private userContextService: UserContextService,
    private rtpnTransactionsService: RtpnTransactionsService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService    
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      existingPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern(StrongPasswordRegx)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(StrongPasswordRegx)]]
    })
    this.emailForm = this.fb.group({
      existingPassword: ['', [Validators.required]],
      newEmailAddress: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    })
    // Define the columns for Brands grid
    this.fileRequestCols = [
      { field: 'fileName', header: 'File Name', display: 'table-cell', width:'42%' },
      { field: 'requestType', header: 'Request Type', display: 'table-cell', width:'16%' },
      { field: 'brandCode', header: 'Brand', display: 'table-cell', width:'6%' },
      { field: 'status', header: 'Status', display: 'table-cell', width:'9%' },
      { field: 'fileCreatedTimestamp', header: 'File Created', display: 'table-cell', width:'14%' },
      { field: 'fileSize', header: 'File Size', display: 'table-cell', width:'9%' },
      { field: 'sessionGuid', header: '', display: 'none', width:'0%' }
    ];    
    this.getUserProfile();
    this.getFileCreationRequests();
  }

  getUserProfile() {
    let currentUser = this.tokenStorageService.getCurrentUser();
    if (currentUser) {
      this.portalUserService.getUser(currentUser.userName)
      .subscribe (user => {
        if (user) {
          this.userId = user.userId;
          this.userName = user.userName;
          this.emailAddress = user.emailAddress || '';
          this.grantedBrands = this.userContextService.hasAllBrands() ? 'all brands' : user.userBrands?.join() || '';
        }
      });
    }
  }

  getFileCreationRequests() {
    this.isSpinnerHidden = false;
    this.rtpnTransactionsService.getFileCreationRequests()
    .subscribe({
      next: (response) => {
        if (response) {
          for(let i = 0; i < response.length; i++){
            // Format the dates
            if (response[i].fileCreatedTimestamp) {
              response[i].fileCreatedTimestamp = this.sharedService.formatFromEpoch(response[i].fileCreatedTimestamp);
            }
            let re = /_/gi;
            if (response[i].requestType) {
              response[i].requestType = response[i].requestType.replace(re,' ').toLowerCase();
            }
          }
          this.fileRequests = response;
          this.isSpinnerHidden = true;
          }
        },
      error: (error) => {
        this.displayMessage("Unable to download the file.");
        this.isSpinnerHidden = true;
      },
      complete: () => {
      }
    });
}

  changePassword () {
    this.existingPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.isChangePasswordFrameShown = true;
  }

  changeEmailAddress() {
    this.newEmailAddress = '';
    this.isChangeEmailAddressFrameShown = true;
  }

  onSubmitPasssword() {
    this.existingPassword = this.passwordForm.get('existingPassword')?.value;
    this.confirmPassword = this.passwordForm.get('confirmPassword')?.value;
    this.newPassword = this.passwordForm.get('newPassword')?.value;
    if (this.newPassword != this.confirmPassword) {
      this.displayMessage("New and confirm passwords do not match.");
      return;
    }
    else if (this.newPassword === this.existingPassword) {
      this.displayMessage("New and old passwords must be different.");
      return;
    }
    else {
      this.userContextService.changeUserPassword(this.userName, this.existingPassword, this.newPassword)
      .subscribe({
        next: (response) => {
          this.displayMessage("The user's password has been successfully changed.");
          this.isChangePasswordFrameShown = false;
          this.existingPassword = '';
          this.newPassword ='';
          this.confirmPassword = '';
          return;                      
        },
        error: (error) => {
          this.existingPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.displayMessage('Password must contain at least one digit, lowercase, uppercase and special characters with the length of 8 to 20 characters.');
        },
        complete: () => {
        }
      });
    }
  }

  onCancelPassword() {
    this.isChangePasswordFrameShown = false;
  } 
  
  onSubmitEmail() {
    this.newEmailAddress = this.emailForm.get('newEmailAddress')?.value;
    this.existingPassword = this.emailForm.get('existingPassword')?.value;
    if (!this.newEmailAddress) {
      this.displayMessage("New email address must be entered in.");
      return;
    }
    else if (!this.existingPassword) {
      this.displayMessage("Existing user's password must be entered in.");
      return;
    }
    else {
      this.userContextService.changeEmailAddress(this.userName, this.existingPassword, this.newEmailAddress)
      .subscribe({
        next: (response) => {
          this.getUserProfile();
          this.displayMessage("The user's email address has been successfully changed.");
          this.isChangeEmailAddressFrameShown = false;
          this.existingPassword = '';
          this.newEmailAddress = '';
          return;                      
        },
        error: (error) => {
          this.existingPassword = '';
          this.newEmailAddress = '';
          this.displayMessage('An incorrect input specified - unable to process the request for changing email address.');
        },
        complete: () => {
        }
      });
    }
  }

  onCancelEmail() {
    this.isChangeEmailAddressFrameShown = false;
  }

  displayMessage(message: string) {
    this.confirmationService.confirm({
      message: message,
      key: 'user-profile',      
      header: 'Notification',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
  }

  onFileDownload(event: any) {
      this.isSpinnerHidden = false;    
      let fileGuid = event.sessionGuid; 
      this.rtpnTransactionsService.downloadFile(fileGuid)
      .subscribe({
        next: (response) => {
          let blob:any = new Blob([response], { type: 'text/csv; charset=utf-8' }); 
          fileSaver.saveAs(blob, event.fileName);
          this.isSpinnerHidden = true;          
        },
        error: (error) => {
          this.displayMessage(error.error.message);
          this.isSpinnerHidden = true;        
        },
        complete: () => {
        }
      });
  }

//    window.open('https://portalserver-qa.acimobills.com/portal/api/file-creation-request/downloads/42b4b07c67544744a6b2de5964fd7707', '_blank');
    
          // const url= window.URL.createObjectURL(blob);
          // window.open(url, "_parent");       
    

          // console.log('start download:', response.length);
          // const a = document.createElement("a");
          // a.href = "data:text/csv," + response;
          // a.setAttribute("download", event.fileName);
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);

}
