export const environment = {
    production: true,
    env: {
      name:"PROD",
      banner: "Production Environment"
    },
    apiServer:  {
        webSocketlUrl: "wss://portalserver.acimobills.com/portal",
        portalUrl: "https://portalserver.acimobills.com/portal/api",
        authServerUrl: "https://portalserver.acimobills.com/auth/api",
        rtpnUrl: ""                  
    },
    appSetting: {
      baseAuth: "Basic YWRtaW46d2FsbGV0cm9uZ2Y=",
      portalAuth: "Basic dXNyX21vbml0b3I6ZEVHT0I5TUc4cVlQMHhGUw==",
      communicationAuth: "Basic dXNyX2NvbW1fbW5ncjpKUjNVZHhjdVJuREgzZEtU"
    },
    brandMigration: {
      sourceRegions: ["uat"],
      targetRegions: ["client2prod", "mbprod", "mobills2prod", "wuprod"]
    }
  
  };