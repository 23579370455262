<mat-toolbar color="primary">

  <mat-toolbar-row>
    <button mat-icon-button (click)="onToggleSidenav()"  *ngIf="isUserAuthenticated">
        <mat-icon>menu</mat-icon>
    </button>
    <span>Walletron Portal</span>
    <span class="example-spacer"></span>
    <div class="version-info">
      {{appVerion}} 
    </div>
    <span class="example-spacer"></span>
    <img src="../assets/images/ACI-Horizontal-White.png" style="height:30%">
    <span class="example-spacer"></span>

    <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <button pButton type="button" label="Brand" icon="pi pi-th-large" iconPos="left" (click)="navigateMenu('chooseBrand')"  class="p-button-raised p-button-rounded"></button>
    </div> -->

    <span class="example-spacer">
    </span>

    <span class="example-spacer">
      @if (!isProdEnvironment) {
        <h4 class="text-center env-banner">{{environmentBanner}}</h4>        
      }
    </span>

    <span class="example-spacer">
    </span>

    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <button pButton type="button" label="Home" icon="pi pi-home" iconPos="left" (click)="navigateMenu('home')"
        class="p-button-raised p-button-rounded" *ngIf="isUserAuthenticated && hasPrivilege('PRIV_PORTAL_HOME_PAGE')">
        <!-- hasPortalRole -->
      </button>
      <button pButton type="button" label="Monitor" icon="pi pi-camera" iconPos="left" (click)="navigateMenu('monitoring')"
        class="p-button-raised p-button-rounded" *ngIf="isUserAuthenticated && hasAllPrivileges(['PRIV_VP_JOB_MONITOR_VIEW','PRIV_VP_EVENT_OCCURANCE_VIEW'])">
        <!-- hasMonitorRole -->
      </button>
      <button pButton type="button" label="Acct Info" icon="pi pi-info-circle" iconPos="left" (click)="navigateMenu('accountInfo')"
        class="p-button-raised p-button-rounded" *ngIf="isUserAuthenticated && hasPrivilege('PRIV_WAL_ACCOUNT_INFO')">  
        <!-- hasAcctAdminRole -->
      </button>
      <button pButton type="button" label="Enrollments" icon="pi pi-user-plus" iconPos="left" (click)="navigateMenu('enrollments')"
        class="p-button-raised p-button-rounded" *ngIf="isUserAuthenticated && hasPrivilege('PRIV_VP_STATS')">
        <!-- hasMonitorRole -->
      </button>
      <button pButton type="button" label="CSR" icon="pi pi-check-circle" iconPos="left" (click)="navigateMenu('csrAccount')"
        class="p-button-raised p-button-rounded" *ngIf="isUserAuthenticated && hasPrivilege('PRIV_VP_ACCT_CSR')">
        <!-- hasCsrUserRole -->
      </button>
      <button pButton type="button" label="Users" icon="pi pi-users" iconPos="left" (click)="navigateMenu('portalUsers')"
        class="p-button-raised p-button-rounded" *ngIf="isUserAuthenticated && hasAnyPrivilege(['PRIV_WA_USER_MODIFY','PRIV_WA_USER_VIEW'])">
        <!-- hasAdminRole || hasUserAdminRole -->
      </button>
      <button pButton type="button" label="Sign Out" icon="pi pi-sign-out" iconPos="left" (click)="navigateMenu('logout')"
       class="p-button-raised p-button-rounded">
      </button>
    </div>

  </mat-toolbar-row>

</mat-toolbar>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="idle-session"></p-confirmDialog>



