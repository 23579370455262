import { Component, OnInit } from '@angular/core';
import { IBrand } from '../../interfaces/brand';
import { IBillerBrand } from '../../interfaces/biller-brand';
import { ConfirmationService } from "primeng/api";
import { BrandService } from '../../services/config/brand.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { BrandStatsService } from "../../services/stats/brand-stats.service";
import { CsvExportService } from "../../services/stats/csv-export.service";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'app-enrollment-trend',
  templateUrl: './enrollment-trend.component.html',
  styleUrls: ['./enrollment-trend.component.css']
})
export class EnrollmentTrendComponent implements OnInit {

  cols!: any[];
  numOfMonths!: number;
  enrollmentCounts: any;
  enrollmentOptions: any;
  brands: IBillerBrand[] = [];
  selectedBrands: IBrand[] = [];

  isNoDataFound: boolean = true;
  columnHeaders!: string[];
  treandData: any[] = [];

  isSpinnerHidden: boolean = true;
 
  constructor(
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService,
    private brandStatsService: BrandStatsService,
    private brandService: BrandService,
    private csvExportService: CsvExportService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.cols = [
      { field: '', header: '', display: 'table-cell', width:'10%'  },
      { field: 'brandCode', header: 'Brand Name', display: 'table-cell', width:'20%' },
      { field: 'brandName', header: 'Brand Name', display: 'table-cell', width:'70%'},
    ];     
    // Get a list of granted brands   
    this.getBrandCodes();
    this.numOfMonths = 12;
    this.enrollmentOptions = 
    {
      title: {
        display: true,
        text: "Account Enrollments Trend",
        fontSize: 16
      },
      legend: {
        position: 'top'
      }
    };
  }

  // // Retrieve distinct brand codes
  getBrandCodes(): void {
    this.brandService.getBrands()
    .subscribe(items => {
//      this.brands = items;
      this.brands = this.sharedService.getCurrentUserGrantedBrands(items); 
      this.brands.sort((a, b) => ((a.brandCode || '') > (b.brandCode || '')) ? 1 : -1);
    });
  } 

  onGetTrend() {
    this.columnHeaders = [];
    this.treandData = [];
    // Make sure we have valid input from the user
    if ((!this.userContextService.isPortalAdmin() && this.selectedBrands.length == 0) || !this.numOfMonths) {
      this.confirmationService.confirm({
        message: 'Please select one or up to five brands and specify the number of monthly intervals.',
        header: 'Warning',
        key: 'enrollment-trend',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });
      return;
    }
    // Get the list of selected brands
    let i = 0;
    let brandCodesList: any[] = [];
    this.selectedBrands.forEach(element => {
      if (++i <= 5) {
        brandCodesList.push(element.brandCode);
      }
    })
    // Call the service to get the data
    this.isSpinnerHidden = false;
    this.brandStatsService.getEnrollmentTrent(brandCodesList.join(), this.numOfMonths)
    .subscribe({
      next: (trendRecords) => {
        this.isSpinnerHidden = true;
        if (trendRecords.length == 0) {
          this.enrollmentCounts = {
            labels: [],
            datasets: []
          }
          this.isNoDataFound = true;
          this.confirmationService.confirm({
            message: 'No data found for the specified selection criteria.',
            header: 'Warning',
            icon: 'pi pi-exclamation-triangle',
            key: 'enrollment-trend',
            acceptLabel: "Ok",
            rejectVisible: false,
            acceptButtonStyleClass: "p-button-info  p-button-rounded",
            accept: () => {
                  return;
                }
          });          
        } else {
  
          let labels: any[] = [];
          let brandCode = "";
          let data: any[] = [];
          let datasets = [];
          this.columnHeaders.push('brandCode');
          this.columnHeaders.push('brandName');
  
          trendRecords.forEach(element => {
            if (labels.indexOf(element.timePeriod) < 0) {
              labels.push(element.timePeriod);
              this.columnHeaders.push(element.timePeriod);
            }
            //
            if (element.brandCode != brandCode) {
              if (brandCode != '') {
                datasets.push({label: brandCode, backgroundColor: "#" + randomColor(), data: data});
              }
              brandCode = element.brandCode;
              data = [];
            }
            data.push(element.accountCnt);
          })
          datasets.push({label: brandCode, backgroundColor: "#" + randomColor(), data: data});
  
          this.enrollmentCounts = {
            labels: labels,
            datasets: datasets
          };        
  
          this.convertTrendRecords(trendRecords);
          this.isNoDataFound = false;
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });

    function randomColor() {
      return Math.floor(Math.random()*16777215).toString(16);
    }

  }

  // The following function converts an array like 
  // [{"brandCode": "lfs","timePeriod": "2021-03","accountCnt": 20},{"brandCode": "lfs","timePeriod": "2021-04","accountCnt": 25}]
  // into another array like
  // [{"brandCode": "lfs","2021-03": 20,"2021-04": 25}]
  convertTrendRecords(trendRecords: any[]) {
    this.treandData = [];
    trendRecords.forEach(element => {
      let index = this.treandData.findIndex(item => item['brandCode'] === element.brandCode); 
      if (index >= 0) {
        this.treandData[index][element.timePeriod] = element.accountCnt;
      } else {
        let brand = this.brands.find(elem => elem.brandCode == element.brandCode);
        var obj = {
          brandCode: (brand ? brand.brandCode : 'Walletron'),
          brandName: (brand ? brand.brandName : 'Walletron'),
          [element.timePeriod]: element.accountCnt
        };
        // var obj = {'brandCode': (brand ? brand.brandCode : 'mobills')};
        // obj['brandName'] = (brand ? brand.brandName : 'mobills');
        // obj[element.timePeriod] = element.accountCnt;
        this.treandData.push(obj);
      }
    });
  }

  onCsvExport() {
    let csvData = this.csvExportService.jsonToCSV(this.treandData, this.columnHeaders);
    this.csvExportService.downloadFile(csvData, 'EnrollmentTrend');
   }

}
