import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from "../../services/shared.service";
import { ConfirmationService } from "primeng/api";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { RtpnTransactionsService } from "../../services/rtpn/rtpn-transactions.service";
import { IRtpnBiller } from '../../interfaces/rtpn-biller';

@Component({
  selector: 'app-rtpn-transactions',
  templateUrl: './rtpn-transactions.component.html',
  styleUrls: ['./rtpn-transactions.component.css']
})
export class RtpnTransactionsComponent implements OnInit {

  isSpinnerHidden: boolean = true;

  @ViewChild('dtRtpnTxn') dtRtpnTxn:any;

  billerCodes: any[] = [];
  requestTypes: any[] = [];
  statusCodes: any[] = [];
  rtpnTransactionsCols: any[] = [];
  rtpnTransactions: any[] = [];
  selectedBrandCode!: string;
  selectedRequestType: string = 'P';
  selectedStatusCode!: string;
  selectedAccountNumber!: string;
  selectedDateFrom!: string;
  selectedDateTo!: string;
  recOffset!: number;  
  isBrandSelectionShown: boolean = true;

  isNextButtonShown: boolean = false;
  isPriorButtonShown: boolean = false;

  selectedRtpnTransaction: any;
  nextPageToken: string = '';
  pageSize: number = 25;

  constructor(
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private userContextService: UserContextService,
    private rtpnTransactionsService: RtpnTransactionsService
  ) { }

  ngOnInit(): void {
    this.rtpnTransactionsCols = [
      { field: 'rtpnRequestId', header: 'Rec Id', width:'5%', display: 'table-cell' },
      { field: 'billerId', header: 'Biller Id', width:'5%', display: 'table-cell' },
      { field: 'brandCode', header: 'Brand Code', width:'5%', display: 'table-cell' },
      { field: 'billerName', header: 'Biller Name', width:'10%', display: 'table-cell' },
      { field: 'accountNumber', header: 'Account No', width:'7%', display: 'table-cell' },
      { field: 'transactionId', header: 'Transaction Id', width:'12%', display: 'table-cell' },
      { field: 'transactionDate', header: 'Transaction Date', width:'8%', display: 'table-cell' },
      { field: 'status', header: 'Status', width:'4%', display: 'table-cell' },
      { field: 'requestType', header: 'Type', width:'5%', display: 'table-cell' },
      { field: 'amount', header: 'Amount', width:'4%', display: 'table-cell' },
      { field: 'cardNumber', header: 'Card No', width:'6%', display: 'table-cell' },
      { field: 'merchantId', header: 'Merchant Id', width:'6%', display: 'table-cell' },
      { field: 'storeId', header: 'Store Id', width:'14%', display: 'table-cell' },
      { field: 'terminalId', header: 'Terminal Id', width:'6%', display: 'table-cell' },
      { field: 'exitCode', header: 'Exec. Code', width:'4%', display: 'table-cell' },
      { field: 'errorMsg', header: 'Exec. Message', width:'8%', display: 'table-cell' }
    ] 
    // Prepopulate the Status Code dropdown
    this.statusCodes = [
      {label: '', value: null},
      {label: 'Created', value: 'CRE'},
      {label: 'Submitted', value: 'SUB'},
      {label: 'Success', value: 'SUC'},
      {label: 'Error', value: 'ERR'},
      {label: 'Fatal', value: 'FTL'},
      {label: 'Rejected', value: 'REJ'}
    ];
    // Prepopulate the Request Type dropdown
    this.requestTypes = [
      {label: '', value: null},
      {label: 'Cancelation', value: 'C'},
      {label: 'Payment', value: 'P'},
      {label: 'Verification', value: 'V'},
    ];
    // Figure out the list of granted brands to the current user
    let hasAllBrands = this.userContextService.hasAllBrands();
    // Prepopulate the Biller dropdown
    this.getRtpnBillers(hasAllBrands);
    this.selectedDateFrom = this.sharedService.getCurrentDate();
    this.selectedDateTo = this.sharedService.getCurrentDate();
  }

  getRtpnBillers(hasAllBrands: boolean) {
    this.billerCodes = [];
    this.billerCodes.push({label: '', value: null});
    this.isSpinnerHidden = false;
    this.rtpnTransactionsService.getRtpnBillers()
    .subscribe({
      next: (response) => {
        if (response) {
          response.forEach(biller => {
            if (biller.enabled) {
              if (hasAllBrands || this.userContextService.hasBrandGranted(biller.brandCode)) {
                this.billerCodes.push({label: biller.brandCode + ' - ' + biller.billerName, value: biller.brandCode});
              }
            }
          })
        }
        // If only one brand is granted to the user, then hide selection of brands from the criteria
        if (this.billerCodes.length == 1) {
          this.isBrandSelectionShown = false;
          this.selectedBrandCode = this.billerCodes[0].value;
          this.alertTheUser("Unable to retrieve RTPN billers.");
        } else if (this.billerCodes.length == 2) {
          this.isBrandSelectionShown = false;
          this.selectedBrandCode = this.billerCodes[1].value;
        } else {
          this.isBrandSelectionShown = true;
          this.selectedBrandCode = '';
        }
        this.isSpinnerHidden = true;
      },
      error: (error) => {
        this.alertTheUser("Unable to retrieve RTPN billers.");
        this.isSpinnerHidden = true;      },
      complete: () => {
      }
    });
  }

  onRtpnTransactionRowSelect(event: any) {

  }

  onSearchClick(event: any) {
    this.nextPageToken = ":";
    this.getRtpnTransactions(this.selectedBrandCode, this.selectedRequestType, this.selectedStatusCode, 
      this.selectedAccountNumber, this.selectedDateFrom, this.selectedDateTo, "");
  }

  onNextClick(event: any) {
    this.getRtpnTransactions(this.selectedBrandCode, this.selectedRequestType, this.selectedStatusCode, 
      this.selectedAccountNumber, this.selectedDateFrom, this.selectedDateTo, "next");
  }

  onPriorClick(event: any) {
    this.getRtpnTransactions(this.selectedBrandCode, this.selectedRequestType, this.selectedStatusCode, 
      this.selectedAccountNumber, this.selectedDateFrom, this.selectedDateTo, "prior");
  }

  onClearClick(event: any) {
    this.isNextButtonShown = false;
//    this.selectedBrandCode = null;
    this.selectedRequestType = '';
    this.selectedStatusCode = '';
    this.selectedAccountNumber = '';
    this.selectedDateFrom = this.sharedService.getCurrentDate();
    this.selectedDateTo = this.sharedService.getCurrentDate();
    this.rtpnTransactions = [];
    this.dtRtpnTxn.reset();
  }

   // Select rtpn transactions based on the user defined selection criteria
   getRtpnTransactions(billerCode: string, requestType: string, statusCode: string, accountNumber: string,
      dateFrom: string, dateTo: string, direction: string): void {
    this.isSpinnerHidden = false;
    this.recOffset = 0;
    this.dtRtpnTxn.reset();
    this.rtpnTransactions = [];
    this.rtpnTransactionsService.getRtpnRequests(billerCode, requestType, statusCode, accountNumber,
      dateFrom, dateTo, this.nextPageToken, direction, this.pageSize)
      .subscribe({
        next: (response) => {
          this.nextPageToken = response.paging.nextPageToken;
          if (response.rtpnRequests && response.rtpnRequests.length > 0) {
            this.rtpnTransactions = response.rtpnRequests;
            // Format the amounts
            for(let i = 0; i < this.rtpnTransactions.length; i++){
              if (this.rtpnTransactions[i].amount) {
                this.rtpnTransactions[i].amount = this.rtpnTransactions[i].amount
                  .toLocaleString("en-US", {style: "currency",currency: "USD"});
              }
            }
          }
          this.isSpinnerHidden = true;
          // Display the Next and/or Prior button(s)
          this.isNextButtonShown = false;
          this.isPriorButtonShown = false;  
          if (!direction && !response.paging.hasMore) {
            return;
          }
          if (direction == "next" || direction == "") {
              if (response.paging.hasMore) {
                this.isNextButtonShown = true;
              } else {
                this.isNextButtonShown = false;
              }
              if (response.paging.resultsPerPage >= 0 && direction != "") {
                this.isPriorButtonShown = true;
              }
            }
            if (direction == "prior") {
              if (response.paging.hasMore) {
                this.isPriorButtonShown = true;
              } else {
                this.isPriorButtonShown = false;
              }
              if (response.paging.resultsPerPage >= 0) {
                this.isNextButtonShown = true;
              }
            }
        },
        error: (error) => {
          this.alertTheUser("Unable to retrieve RTPN billers.");
          this.isSpinnerHidden = true;
      },
        complete: () => {
        }
      });
  }

  onChangeBiller(event: any) {
    // Display the Next button only when retrieving transactions for one biller
    let billerCode = event.value;
    if (!billerCode) {
      this.isNextButtonShown = false;
    }
  }

  onFileCreationRequest(event: any) {
    // Call the service to invoke a Web API call
    this.rtpnTransactionsService.sendFileCreationRequest(this.selectedBrandCode, this.selectedRequestType, 
      this.selectedStatusCode, this.selectedAccountNumber, this.selectedDateFrom, this.selectedDateTo) 
      .subscribe({
        next: (response) => {
          this.alertTheUser('An email will be sent to the address on your profile when the CSV is ready to download.');
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  }  

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'rtpn-transactions',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }  

}
