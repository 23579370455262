<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!-- A popup dialog to select a different pass template -->
<app-choose-pass-template [displayPassTemplateDialog] = "openPassTemplateSelectionDialog" [currentBrand] = "currentBrand"
     (choosePassTemplateEvent)="choosePassTemplate($event)">
</app-choose-pass-template>

<!--- Generic header, inclusing current brand and a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded" [disabled]="isDetailsShown">
        </button>
    </div>  
    <div class="col-1">
        <button pButton type="button" label="Pass Template" icon="pi pi-pencil" iconPos="left" (click)="onChoosePassTemplateClick($event)"  
            class="p-button-raised p-button-rounded" [disabled]="isDetailsShown">
        </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 


<!--   -->

<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-8 side-list-content-data" *ngIf="isListShown">

        <!-- The list of Message Templates for the chosen Pass Template -->

        <div class="row" *ngIf="isListOfMessageTemplatesShown">

            <div class="col-12">

                <p-card header="List of Message Templates" subheader="" styleClass="ui-card-powder" >
    
                    <p-table #dt [columns]="cols" [value]="messageTemplates" selectionMode="single" 
                       sortMode="multiple" [paginator]="true" [rows]="15" >
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th [ngStyle]="{'width': 'table-cell', 'display': '10%'}">
                                </th>
                            </tr>
                            <!-- <tr>
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    <input *ngSwitchCase="'messageTemplateName'" pInputText type="text" (input)="dt.filter($event.target?.value, col.field, 'contains')">
                                    <input *ngSwitchCase="'messageTemplateCode'" pInputText type="text" (input)="dt.filter($event.target?.value, col.field, 'contains')">
                                    <input *ngSwitchCase="'messageTemplateDescription'" pInputText type="text" (input)="dt.filter($event.target?.value, col.field, 'contains')">
                                </th>
                                <th></th>
                            </tr>   -->
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pEditableRow]="rowData">
                                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{rowData[col.field]}}
                                </td>
                                <td style="text-align:center">
                                    <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Modify message template" 
                                        class="ui-button-info" (click)="onRowEdit(rowData)">
                                    </button>
                                    <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Delete message template" style="margin-right:10px"
                                        class="ui-button-danger" (click)="onRowDelete(rowData)" *ngIf="isSubmitShown" >
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary" let-rowData>
                            <div style="text-align:left" *ngIf="isSubmitShown" >
                                <button pButton type="button" label="Add" icon="pi fa-plus" iconPos="left" (click)="showDialogToAdd()"  
                                    class="p-button-info p-button-rounded" >
                                </button>
                            </div>
                        </ng-template>    
                  </p-table> 
            
                    <p-footer>
                        <mat-icon class="nav-caption wal-center">list_all</mat-icon>
                    </p-footer>
                </p-card>
                    
            </div>
    
        </div>
 
        <div class="row" *ngIf="isPassTemplatesRedirect">
            <div class="col-10">
            </div>
            <div class="col-2">
                <button pButton type="button" label="Back to Pass Templates" icon="pi pi-backward" iconPos="left" (click)="passTemplatesRedirect($event)"  
                     class="p-button-info p-button-rounded" [disabled]="isDetailsShown">
                 </button>
            </div>  
         </div> 

    </div>

    

    <!--- Second card with the details -->

    <div @flyInOut class="ui-g-10 side-list-content-data" *ngIf="isDetailsShown">

        <div class="col-8">

            <p-card header="Message Template Details" subheader="" styleClass="ui-card-light" >
            
                <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">

                    <div class="grid">

                        <div class="col-6">

                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="passId" class="col-fixed label" style="width: 15em">Msg Template Id:</label>
                                  <div class="col">
                                    <input pInputText id="messageTemplateId" type="text" formControlName="messageTemplateId" readonly="true" 
                                        pTooltip="Unique message template identifier" tooltipPosition="top" autofocus style="width: 10em"/> 
                                  </div>
                            </div>                             

                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="messageTemplateCode" class="col-fixed label" style="width: 15em">Msg Template Code:</label>
                                  <div class="col">
                                    <input pInputText id="messageTemplateCode" type="text" formControlName="messageTemplateCode" style="width: 25em" 
                                        [size]="255" placeholder="Required" pTooltip="The code of the Message Template" tooltipPosition="top" autofocus /> 
                                    <p-message severity="error" text="Message template code is required" 
                                        *ngIf="!userform.controls['messageTemplateCode'].valid&&userform.controls['messageTemplateCode'].dirty">
                                    </p-message>                 
                                  </div>
                            </div>                             

                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="messageTemplateName" class="col-fixed label" style="width: 15em">Msg Template Name:</label>
                                  <div class="col">
                                    <input pInputText id="messageTemplateName" type="text" formControlName="messageTemplateName" style="width: 25em" 
                                    [size]="100" placeholder="Required" pTooltip="The name of the Message Template" tooltipPosition="top"/> 
                                    <p-message severity="error" text="Message template name is required" 
                                        *ngIf="!userform.controls['messageTemplateName'].valid&&userform.controls['messageTemplateName'].dirty">
                                    </p-message>                   
                                  </div>
                            </div> 
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="messageTemplateDescription" class="col-fixed label" style="width: 15em">Msg Template Descr:</label>
                                  <div class="col">
                                    <input pInputText id="messageTemplateDescription" type="text" formControlName="messageTemplateDescription"
                                    style="width: 25em"
                                    [size]="1000" pTooltip="The description of the Message Template" tooltipPosition="top"/> 
                                  </div>
                            </div>        

                        </div>

                        <div class="col-6">

                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="enabled" class="col-fixed label" style="width: 25em">Enabled:</label>
                                  <div class="col">
                                    <p-checkbox formControlName="enabled" id="enabled" [binary]="true"
                                        pTooltip="Is the Message Template enabled?" tooltipPosition="top">
                                    </p-checkbox>
                                  </div>
                            </div>                             
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="alwaysReplacePreviousMsg" class="col-fixed label" style="width: 25em">Always Replace Previous Notification:</label>
                                  <div class="col">
                                    <p-checkbox formControlName="alwaysReplacePreviousMsg" id="alwaysReplacePreviousMsg" [binary]="true"
                                        pTooltip="Always replace the previous message when sending this Message Template?" tooltipPosition="top">
                                    </p-checkbox>
                                  </div>
                            </div>                             
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="alwaysReplaceAllMessages" class="col-fixed label" style="width: 25em">Always Replace ALL Notifications:</label>
                                  <div class="col">
                                    <p-checkbox formControlName="alwaysReplaceAllMessages" id="alwaysReplaceAllMessages" [binary]="true">
                                        pTooltip="Always replace all messages when sending this Message Template?" tooltipPosition="top"
                                    </p-checkbox>
                                  </div>
                            </div>                             
                            
                        </div>

                    </div>

                    <div class="grid">

                        <div class="col-12">

                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="messageTemplateHeader" class="col-fixed label" style="width: 15em">Msg Template Header:</label>
                                  <div class="col">
                                    <input pInputText id="messageTemplateHeader" type="text" formControlName="messageTemplateHeader" 
                                    style="width: 50em"
                                    [size]="100" pTooltip="The header of the Message Template" tooltipPosition="top"/> 
                                  </div>
                            </div>    
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="messageTemplateText" class="col-fixed label" style="width: 15em">Msg Template Text:</label>
                                  <div class="col">
                                    <textarea pInputTextarea id="messageTemplateText" type="text" formControlName="messageTemplateText" 
                                        style="width: 50em"
                                        [rows]="2" pTooltip="The content of the Message Template" tooltipPosition="top"> 
                                    </textarea>
                                  </div>
                            </div>                             
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="linkUrl" class="col-fixed label" style="width: 15em">Link URL:</label>
                                  <div class="col">
                                    <textarea pInputTextarea id="linkUrl" type="text" formControlName="linkUrl" style="width: 50em"
                                        [rows]="1" pTooltip="The link URL of the Message Template" tooltipPosition="top"> 
                                    </textarea>
                                  </div>
                            </div>                             
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="linkHeader" class="col-fixed label" style="width: 15em">Link Header:</label>
                                  <div class="col">
                                    <input pInputText id="linkHeader" type="text" formControlName="linkHeader" style="width: 50em"
                                    [size]="100" pTooltip="The link Header of the Message Template" tooltipPosition="top"/> 
                                  </div>
                            </div>                             
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="linkText" class="col-fixed label" style="width: 15em">Link Text:</label>
                                  <div class="col">
                                    <textarea pInputTextarea id="linkText" type="text" formControlName="linkText" style="width: 50em" 
                                        [rows]="1" pTooltip="The link text of the Message Template" tooltipPosition="top"> 
                                    </textarea>
                                  </div>
                            </div>                             

                        </div>

                    </div>

                    <div class="grid">
                    
                        <div class="col-6">

                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                                  <div class="col">
                                    <input pInputText type="createDate" id="createDate" type="text" formControlName="createDate" readonly="true">
                                </div>
                            </div>                             
                            
                        </div>

                        <div class="col-6">
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                  <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                                  <div class="col">
                                    <input pInputText type="lastUpdateDate" id="lastUpdateDate" type="text" formControlName="lastUpdateDate" readonly="true">
                                </div>
                            </div>                             
                            
                        </div>


    
                         <div class="ui-g-12 margin-bottom-narrower">
                            <div  class="col-3" id="divshow">
                                <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></p-button>
                                <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>
<!-- 
                                <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></button>
                                <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button>
                                 -->
                            </div> 
                            <div class="col-9">
                                    <p>
                                        {{hintMsg}}
                                    </p>
                            </div>
                        </div>

                    </div>

                </form>
        
                <p-footer>
                    <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
                </p-footer>
            </p-card>

        </div>

    </div>


</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="message-templates"></p-confirmDialog>




