import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { isValidCron } from 'cron-validator';
import { IBrand } from '../../interfaces/brand';
import { IScheduledEvent } from '../../interfaces/scheduled-event';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { JobService } from '../../services/admin/job-service.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";
import { EnvRegionService } from '../../services/config/env-region.service';

@Component({
  selector: 'app-scheduled-events-listing',
  templateUrl: './scheduled-events-listing.component.html',
  styleUrls: ['./scheduled-events-listing.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class ScheduledEventsListingComponent implements OnInit{

  eventForm!: FormGroup;
  attributeForm!: FormGroup;

  scheduledEvents: any[] = [];
  eventCols: any[] = [];
  attributes: any[] = [];
  attributeCols: any[] = [];
  msgDestinationTypes: any[] = [];
  msgDestinationNames: any[] = [];
  msgDestinations: any[] = [];  

  envRegions: any[] = [];
  envRegionBaseUrl!: string;  

  isListShown: boolean = true;
  isDetailsShown: boolean = false;
  isAttributeDetailsShown: boolean = false;
  isSubmitShown: boolean = false;
  isSpinnerHidden: boolean = true;  

  isNextButtonShown: boolean = false;
  isPriorButtonShown: boolean = false;
  nextPageToken: number = 0;
  pageSize: number = 10;  

  region!: string;
  eventName!: string;
  msgDestinationType!: string;
  enabled!: boolean;
  sendToAll!: boolean;

  constructor(
    private fb: FormBuilder, 
    private sharedService: SharedService,
    private userContextService: UserContextService, 
    private envRegionService: EnvRegionService,   
    private brandProfileService: BrandProfileService,
    private confirmationService: ConfirmationService  ) { 
    }  

  ngOnInit(): void {
    this.eventCols = [
      { field: 'eventName', header: 'Event Name', display: 'table-cell', width:'20%'  },
      { field: 'eventDescription', header: 'Event Description', display: 'table-cell', width:'30%'  },
      { field: 'enabled', header: 'Enabled', display: 'table-cell', width:'10%'  },
      { field: 'lastFiredDate', header: 'Last Fired', display: 'table-cell', width:'15%'  },
      { field: 'nextTimeoutDate', header: 'Next Timeout', display: 'table-cell', width:'15%'  }
    ]; 
    this.attributeCols = [
      { field: 'attributeName', header: 'Attribute Name', display: 'table-cell', width:'30%'  },
      { field: 'attributeValue', header: 'Attribute Value', display: 'table-cell', width:'40%'  },
      { field: 'enabled', header: 'Enabled', display: 'table-cell', width:'15%'  }
    ]; 
    this.msgDestinationTypes.push(
      {value: 'Q', label: 'GenericSchedulerQueue'},
      {value: 'R', label: 'RabbitMQ'},
      {value: 'W', label: 'Web API'} 
      );

    this.eventForm = this.fb.group({
      'eventName': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'eventDescription': new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      'enabled': new FormControl(true, [Validators.required]),
      'sendToAll': new FormControl(false, [Validators.required]),
      'msgDestinationType': new FormControl('R', [Validators.required]),
      'msgDestinationName': new FormControl('walletron', [Validators.required]),
      'hourExpr': new FormControl(''),
      'minuteExpr': new FormControl(''),
      'secondExpr': new FormControl(''),
      'lastFiredDate':  new FormControl(''),
      'nextTimeoutDate': new FormControl(''),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
    });  

    this.attributeForm = this.fb.group({
      'attributeName': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'attributeValue': new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      'attributeEnabled': new FormControl(true, [Validators.required])
    });  
   
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_MS_EVENTS_MODIFY')) {
      this.isSubmitShown = true;
    }   
  }  

  getEnviromentalRegions(): void {
    // find out all enviromental regions
    this.envRegionService.getEnvRegions()
    .subscribe({
      next: (response) => {
        for(let i = 0; i < response.length; i++){
          var prop = response[i];
          this.envRegions.push({label: prop.envCode, value: prop.baseUrl});
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  } 

  onSearchClick(event: any) {
    this.nextPageToken = 0;
    this.getScheduledEvents(this.region, this.eventName, this.msgDestinationType, this.enabled, this.sendToAll, "");
}

  onNextClick(event: any) {
    this.getScheduledEvents(this.region, this.eventName, this.msgDestinationType, this.enabled, this.sendToAll, "next");
  }

  onPriorClick(event: any) {
    this.getScheduledEvents(this.region, this.eventName, this.msgDestinationType, this.enabled, this.sendToAll, "prior");
  }

  getScheduledEvents (region: string, eventName: string, msgDestinationType: string, enabled: boolean, sendToAll: boolean, direction: string) {
    let eventCriteria = {
      region: region,
      enabled: enabled,
      eventName: eventName,
      msgDestinationType: msgDestinationType,
      sendToAll: sendToAll,
      nextPageToken: '',
      direction: direction,
      pageSize: '',
    }
    this.isSpinnerHidden = false;
    // this.dtRtpnTxn.reset();
    this.scheduledEvents = [];    
    this.brandProfileService.getScheduledEventsByCriteria(this.envRegionBaseUrl, eventCriteria)
      .subscribe({
        next: (response) => {
          this.nextPageToken = response.paging.nextPageToken;
          if (response.scheduledEventRegisterList && response.scheduledEventRegisterList.length > 0) {
            this.scheduledEvents = response.scheduledEventRegisterList;
          }
          this.isSpinnerHidden = true;
          // Display the Next and/or Prior button(s)
          this.isNextButtonShown = false;
          this.isPriorButtonShown = false;  
          if (!direction && !response.paging.hasMore) {
            return;
          }
          if (direction == "next" || direction == "") {
              if (response.paging.hasMore) {
                this.isNextButtonShown = true;
              } else {
                this.isNextButtonShown = false;
              }
              if (response.paging.resultsPerPage >= 0 && direction != "") {
                this.isPriorButtonShown = true;
              }
            }
            if (direction == "prior") {
              if (response.paging.hasMore) {
                this.isPriorButtonShown = true;
              } else {
                this.isPriorButtonShown = false;
              }
              if (response.paging.resultsPerPage >= 0) {
                this.isNextButtonShown = true;
              }
            }
        },
        error: (error) => {
          this.alertTheUser("Unable to retrieve scheduled events.");
          this.isSpinnerHidden = true;
      },
        complete: () => {
        }
      });

  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'scheduled-events-listing',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }  

}
