import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { EnrollmentLinkAttribute } from '../../classes/enrollment-link-attribute';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { SharedService } from "../../services/shared.service";
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-enrollment-links',
  templateUrl: './enrollment-links.component.html',
  styleUrls: ['./enrollment-links.component.css']
})

export class EnrollmentLinksComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "EnrollmentLinks";
  titleMessage = "Enrollment Links";
  isCurrentBrandShown = true;
  openBrandSelectionDialog!: boolean;  
  currentBrand: IBrand;

  passTemplates: any[] = [];
  offers: any[] = [];
  sourceChannels: any[] = [];
  selectedPassTemplate!: number;
  selectedOffer!: number;
  selectedChannel!: string;

  isSpinnerHidden: boolean = true;

  @Input() enrollmentLinks: EnrollmentLinkAttribute<string>[] = [];

  constructor(
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService
  ) { 
    this.currentBrand = this.sharedService.getDummyBrand();    
  }

  ngOnInit(): void {
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.getChannels(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.populateDropDowns(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, 
        this.selectedChannel, this.currentBrand.baseUrl);
  } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }  
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.sharedService.setCurrentBrand(item);
      this.getChannels(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.populateDropDowns(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, 
        this.selectedChannel, this.currentBrand.baseUrl);
  }
  }  

  // Select enrollment links for the giwn combination of brand, pass template, offer, and channel
  getEnrollmentLinks(brandCode: string, passTemplateId: number, offerId: number, channel: string, brandUrl: string) {
    this.enrollmentLinks.length = 0;
    if (!this.selectedPassTemplate || !this.selectedOffer || !this.selectedChannel) {
      return;
    }
    // Call the service to invoke a Web API call
    this.isSpinnerHidden = false;
    this.brandProfileService.getEnrollmentLinksWithChannel(brandCode, passTemplateId, offerId, channel, brandUrl)
    .subscribe({
      next: (response) => {
        response.forEach(item => {
          this.enrollmentLinks.push({label: item.key, value: item.value, key: item.key, options: []});
        })
        this.isSpinnerHidden = true;
      },
      error: (error) => {
        this.isSpinnerHidden = true;
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  } 

  // Populate pass templates and brand offers dropdowns
  populateDropDowns(brandCode: string, baseUrl: string) {
    this.selectedPassTemplate = 0;
    this.passTemplates.length = 0;
    this.selectedOffer = 0;
    this.offers.length = 0;
    this.enrollmentLinks.length = 0;
    // Select all existing brand pass templates
    this.brandProfileService.getPassTemplates(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
          response.forEach(item => {
            this.passTemplates.push({label: item.passName, value: item.passId});
          })
          // If there is just one pass template, then default to it
          if (response.length == 1) {
            this.selectedPassTemplate = response[0].passId || 0;
          }
          // Select all existing brand offers
          this.brandProfileService.getBrandOffers(brandCode, baseUrl)
          .subscribe({
            next: (response) => {
              response.forEach(item => {
                this.offers.push({label: item.offerName, value: item.offerId});
                // If there is just one offer, then default to it
                if (response.length == 1) {
                  this.selectedOffer = response[0].offerId || 0;
                }
                // If we have just one pass template and on offer, then immediately retrieve enrollment links
                this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, 
                  this.selectedChannel, this.currentBrand.baseUrl);
              })
            },
            error: (error) => {
              this.alertTheUser(error.message);
            },
            complete: () => {
            }
          });
        },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  getChannels(brandCode: string, baseUrl: string) {
    this.selectedChannel = '';
    this.sourceChannels.length = 0;
    this.sourceChannels.push({label: '', value: ''});
    this.brandProfileService.getSourceChannels(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          response.forEach (channel => {this.sourceChannels.push({label: channel, value: channel})})
        }
        this.selectedChannel = '';
      },
      error: (error) => {
        if (error.status != 404) {
          this.alertTheUser(error.message);
        }
        this.selectedChannel = '';
      },
      complete: () => {
      }
    });
  }

  onChangePassTemplate(event: any) {
    this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, 
      this.selectedChannel, this.currentBrand.baseUrl);
  } 

  onChangeOffer(event: any) {
    this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, 
      this.selectedChannel, this.currentBrand.baseUrl);
  }

  onChangeChannel(event: any) {
    this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, 
      this.selectedChannel, this.currentBrand.baseUrl);
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'enrollment-links',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
