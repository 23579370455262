<h2>Communication Message Profile Setup</h2>

<form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">

    <div class="grid">

        <div class="col-5">

            <p-card header="Message Profile" subheader="" styleClass="ui-card-light" >
            
                <div>

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="messageCode" class="col-fixed label" style="width: 15em">Message Code:</label>
                        <div class="col-6">
                            <input pInputText id="messageCode" type="text" formControlName="messageCode"  [style]="{'width': '80%'}" 
                                pTooltip="A unique communication message code that typically starts with 'MP_'"tooltipPosition="top"
                                [disabled]="isDisabled" [size]="60" [minLength]="4" placeholder="Required"/> 
                            <p-message severity="error" text="Message code is required to have the 'MP_' prefix" *ngIf="!userform.controls['messageCode'].valid&&userform.controls['messageCode'].dirty"></p-message>
                        </div>
                    </div> 
            
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="messageDescription" class="col-fixed label" style="width: 15em">Message Description:</label>
                        <div class="col-7">
                            <input pInputText id="messageDescription" type="text" formControlName="messageDescription" [style]="{'width': '100%'}" 
                                pTooltip="A free-form communication description"tooltipPosition="top"
                                [disabled]="isDisabled" [size]="400" [minLength]="4" placeholder="Required"/> 
                            <p-message severity="error" text="Message description is required" *ngIf="!userform.controls['messageDescription'].valid&&userform.controls['messageDescription'].dirty"></p-message>
                        </div>
                    </div>
            
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="category" class="col-fixed label" style="width: 15em">Category:</label>
                        <div class="col-7">
                            <p-dropdown id ="category" [options]="categories" formControlName="category"  [style]="{'width': '60%'}"
                                pTooltip="Choose the category of the new communication message configuration"tooltipPosition="top"
                                [filter]="false" [editable]="false" [disabled]="isDisabled"> 
                            </p-dropdown>
                            <p-message severity="error" text="Category is required" *ngIf="!userform.controls['category'].valid&&userform.controls['category'].dirty"></p-message> 
                        </div>
                    </div>
            
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="clientCode" class="col-fixed label" style="width: 15em">Client Code:</label>
                        <div class="col-7">
                            <p-dropdown id ="clientCode" [options]="clientCodes" formControlName="clientCode" 
                                pTooltip="Choose the client code of the new communication message configuration"tooltipPosition="top"
                                [filter]="false" [editable]="false" [disabled]="isDisabled"> 
                            </p-dropdown>
                            <p-message severity="error" text="Category is required" *ngIf="!userform.controls['clientCode'].valid&&userform.controls['clientCode'].dirty"></p-message>
                        </div>
                    </div>

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="emailChannel" class="col-fixed label" style="width: 15em">Email Channel:</label>
                        <div class="col-7">
                            <p-checkbox formControlName="emailChannel" id="emailChannel" [binary]="true" 
                                pTooltip="Specify whether Email channel needs to be enabled" tooltipPosition="top">
                            </p-checkbox>
                        </div>
                    </div>                    

                    <!-- <div class="ui-g-12 margin-bottom-narrower">
                        <div class="col-4"> -->
                    <div class="field grid">
                        <div class="col-1"></div>                            
                            <label for="smsChannel" class="col-fixed label" style="width: 15em">SMS Channel:</label>
                        <div class="col-7">
                            <p-checkbox formControlName="smsChannel" id="smsChannel" [binary]="true"
                                pTooltip="Specify whether SMS channel needs to be enabled" tooltipPosition="top">
                            </p-checkbox>
                        </div>
                    </div>                    

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="template" class="col-fixed label" style="width: 15em">Template:</label>
                        <div class="col-7">
                            <p-dropdown id ="template" [options]="templates" formControlName="template"  [style]="{'width': '100%'}"
                                pTooltip="Choose the velocity template, if needed"tooltipPosition="top"
                                [filter]="false" [editable]="false" [disabled]="isDisabled"> 
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="defaultVarValues" class="col-fixed label" style="width: 15em">Default Var Values:</label>
                        <div class="col-7">
                            <textarea pInputTextarea id="defaultVarValues" style="width: 100%;" [rows]="3" [cols]="36" 
                                pTooltip="A json that includes addiditional email attributes, such as messageSubject, senderEmail, senderName, if needed" 
                                tooltipPosition="top" formControlName="defaultVarValues" > 
                            </textarea>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="isSubmitShown" >
                        <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                            <button pButton type="submit" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" ></button>
 -->
                            <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left"  [disabled]="!userform.valid" ></p-button>

                        </div>          
                        <div class="col-10">
                            <mat-label>{{resultMsg}}</mat-label>
                        </div>
                    </div>
            
                </div>
                <p-footer>
                        <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
                </p-footer>
            </p-card>

        </div>

    </div> 

</form>
        


<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="message-profile"></p-confirmDialog>
