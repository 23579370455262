import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";


@Injectable({
  providedIn: 'root'
})
export class JobService {

  private refreshDaqDataUrl = "/admin/jobs/refresh-daq-data/{brandCode}";
  private importInboundFeedsUrl = "/admin/jobs/import-inbound-feeds/{brandCode}";
  private generateOutboundFeedsUrl = "/admin/jobs/generate-outbound-feeds/{brandCode}";
  private runAndroidScannerUrl = "/admin/jobs/run-android-scanner/{brandCode}";
  private triggeredActionUrl = "/admin/jobs/triggered-action/{brandCode}/{eventName}";
  private rtpnBarcodeRefresherUrl = "/admin/jobs/rtpn-barcode-refresher/{brandCode}";

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService    
  ) { }


  triggerEvent(brandCode: string, taskName: string, baseUrl: string) {
    var url = baseUrl;
    if (taskName.indexOf("DaqDataBatchRefresher") >= 0) {
      url += this.refreshDaqDataUrl.replace('{brandCode}', brandCode);
    } else if (taskName.indexOf("GenericDaqDatafeedImporter") >= 0) {
      url += this.importInboundFeedsUrl.replace('{brandCode}', brandCode);
    } else if (taskName.indexOf("GenericOutgoingDatafeedGenerator") >= 0) {
      url += this.generateOutboundFeedsUrl.replace('{brandCode}', brandCode);
    } else if (taskName.indexOf("AndroidPayScanner") >= 0) {
      url += this.runAndroidScannerUrl.replace('{brandCode}', brandCode);
    } else if (taskName.indexOf("RtpnBatchBarcodeRefresher") >= 0) {
      url += this.rtpnBarcodeRefresherUrl.replace('{brandCode}', brandCode);
    } else {
      url += this.triggeredActionUrl.replace('{brandCode}', brandCode).replace('{eventName}', taskName);
    }
    return this.http.post<string>(url, null, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 


}
