import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IMessageBroadcasting } from '../../interfaces/message-broadcasting';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { AccountAdminService } from '../../services/admin/account-admin.service';
import { SharedService } from "../../services/shared.service";
import { UserContextService } from '../../services/safeguard/user-context.service';

@Component({
  selector: 'app-message-broadcasting',
  templateUrl: './message-broadcasting.component.html',
  styleUrls: ['./message-broadcasting.component.css']
})
export class MessageBroadcastingComponent implements OnInit {

  titleMessage = "Message Broadcasting";
  isSubmitShown = false;
  isCurrentBrandShown = true;

  passTemplates: any[] = [];
  messageTemplates: any[] = [];
  msgTemplates: any[] = [];
  resultMsg = "";
  currentBrand: IBrand;

  userform!: FormGroup;
  isTemplateShown!: boolean;
  openBrandSelectionDialog!: boolean;

  allAccountsInd: boolean = false;
  isAccountListVisible: boolean = true;

  autoResize: boolean = true;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private accountAdminService: AccountAdminService,
    private sharedService: SharedService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService,
  ) {
    this.currentBrand = this.sharedService.getDummyBrand();    
   }

  ngOnInit(): void {
    this.userform = this.fb.group({
      'passId': new FormControl('', [Validators.required, Validators.min(1)]),
      'messageTemplateCode': new FormControl('', [Validators.required]),
      'templateHeader': new FormControl('', [Validators.required]),
      'templateText': new FormControl('', [Validators.required]),
      'accountList': new FormControl(''),
      'allAccountsInd': new FormControl(false)
    });

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.openBrandSelectionDialog = false;
      this.getPassTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      // this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }  
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasAnyPrivilege(['PRIV_WAL_MESSAGE_TO_ACCOUNT','PRIV_WAL_ACCOUNT_MESSAGE'])) {
      this.isSubmitShown = true;
    }
    
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.sharedService.setCurrentBrand(item);
      this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
      // this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    }
  }

  // Select all existing brand pass templates
  getPassTemplates(brandCode: string, baseUrl: string) {
    this.passTemplates.length = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getPassTemplates(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          response.forEach(element => {
            this.passTemplates.push({label: element.passName, value: element.passId});
          });
          if (response.length == 1) {
            let passTemplateId = response[0].passId || 0;
            this.userform.setValue({
              passId: passTemplateId, messageTemplateCode: '', accountList: '', templateHeader: '', templateText: '', allAccountsInd: false
            });
            this.getMessageTemplates(brandCode, passTemplateId, baseUrl)
          } else {
            this.isTemplateShown = false;
            this.messageTemplates.length = 0;
            this.msgTemplates.length = 0;
            this.passTemplates.push({label: '[none]', value: 0});
            this.messageTemplates.push({label: '[none]', value: ''});
            this.userform.patchValue({
              passId: 0, messageTemplateCode: '', accountList: '', templateHeader: '', templateText: '', allAccountsInd: false
            });
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Retrieve message templates based on the chosen pass template
  getMessageTemplates(brandCode: string, passTemplateId: number, baseUrl: string) {
    this.isTemplateShown = false;
    this.messageTemplates.length = 0;
    this.msgTemplates.length = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getMessageTemplates(brandCode, passTemplateId, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.msgTemplates = response;
          response.forEach(element => {
              this.messageTemplates.push({label: element.messageTemplateName, value: element.messageTemplateCode});
            });
          if (response.length == 1) {
            this.userform.patchValue({
              messageTemplateCode: response[0].messageTemplateCode, 
              templateHeader: response[0].messageTemplateHeader, 
              templateText: response[0].messageTemplateText
            });
          } else {
            this.messageTemplates.push({label: '[none]', value: ''});
            this.userform.patchValue({messageTemplateCode: ''});
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // // Select all existing brand offers
  // getBrandOffers(brandCode, brandUrl) {
  //   this.brandOffers.length = 0;
  //   // Call the service to invoke a Web API call
  //   this.brandProfileService.getBrandOffers(brandCode, brandUrl)
  //       .subscribe( 
  //         (response) => {
  //           if (response) {
  //             response.forEach(element => {
  //               this.brandOffers.push({label: element.offerName, value: element.offerId});
  //             });
  //             if (response.length == 1) {
  //               this.userform.patchValue({offerId: response[0].offerId});
  //             } else {
  //               this.brandOffers.push({label: '[none]', value: 0});
  //               this.userform.patchValue({offerId: 0});
  //             }
  //           }
  //           },
  //         (error)  => {
  //           this.alertTheUser(error.message);
  //         }
  //       )
  // }  

  onChangePassTemplate (event: any) {
    let passTemplateId = event.value;
    this.getMessageTemplates(this.currentBrand.brandCode || '', passTemplateId, this.currentBrand.baseUrl || '');
  } 

  onChangeMessageTemplate(event: any) {
    let messageTemplateCode = event.value;
    let messageTemplate = this.msgTemplates.filter(item => item.messageTemplateCode == messageTemplateCode);
    if (messageTemplate.length == 1) {
      this.userform.patchValue({
        templateHeader: messageTemplate[0].messageTemplateHeader, 
        templateText: messageTemplate[0].messageTemplateText
      });
      this.isTemplateShown = true;
    }
  } 

  onSubmit(value: string) {
    let messageBroadcasting: IMessageBroadcasting = {
      passId: this.userform.value.passId,
      messageTemplateCode: this.userform.value.messageTemplateCode,
      templateHeader: this.userform.value.templateHeader,
      templateText: this.userform.value.templateText,
      accountIds: [''] 
    }
    if (this.userform.value.allAccountsInd) {
      this.submitMessageToAllAccounts(messageBroadcasting);
    } else {
      this.submitMessageToAccountList(messageBroadcasting, this.userform.value.accountList);
    }
  }

  submitMessageToAccountList(messageBroadcasting: any, accountList: any) {
    if (!accountList) {
      this.alertTheUser('At least one account id must be specified');
      return;
    } 
    messageBroadcasting.accountIds = accountList.split(',');
    // Call the service to invoke a Web API call
    this.accountAdminService.submitMessageBroadcasting(
      this.currentBrand.brandCode || '', messageBroadcasting, this.currentBrand.baseUrl || '')
      .subscribe({
        next: (response) => {
          this.alertTheUser('Successfully submitted the message for brand ' + this.currentBrand.brandCode);
        },
        error: (error) => {
          this.alertTheUser(error.statusText);
        },
        complete: () => {
        }
      });
  }

  submitMessageToAllAccounts(messageBroadcasting: any) {
    // Call the service to invoke a Web API call
    this.accountAdminService.submitMessageToAllAccounts(
      this.currentBrand.brandCode || '', messageBroadcasting, this.currentBrand.baseUrl || '')
      .subscribe({
        next: (response) => {
          this.alertTheUser('Successfully submitted the message for brand ' + this.currentBrand.brandCode);
        },
        error: (error) => {
          this.alertTheUser(error.statusText);
        },
        complete: () => {
        }
      });
  }

  onAllAccountsIndClick(value:boolean) {
    this.allAccountsInd = !this.allAccountsInd;
    if (this.allAccountsInd) {
      this.isAccountListVisible = false;
    } else {
      this.isAccountListVisible = true;
    }

  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'message-broadcasting',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  makeTitleMessage (currentBrand: IBrand) {
    var title = '';
    if (currentBrand.brandCode) {
      title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) >> ';
    }
    title += 'Message Broadcasting';
    return title;
  }


}
