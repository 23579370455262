import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { ITestSuiteResponse } from '../../interfaces/test-suite-response';
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class TestSuiteService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;

  private testSuiteUrl = this.baseUrl + '/testsuite/log'; 
  private suiteNamesUrl = this.baseUrl + '/testsuite/distinct-suite-names'; 
  private testNamesUrl = this.baseUrl + '/testsuite/distinct-test-names'; 
  private testNamesForSuiteUrl = this.baseUrl + '/testsuite/distinct-test-names-for-test-suite'; 
    
  private headers = new HttpHeaders()
    .set('Authorization', this.basicAuth)

  constructor(
    private http: HttpClient, 
    private sharedService: SharedService,
    private messageService: MessageService,
    private errorProcessorService: ErrorProcessorService
  ) { }

  getTestSuiteLog(selectedSuiteName: string, selectedTestName: string, selectedJobStatus: string,
     selectedDateFrom: string, selectedDateTo: string, offset: number):
        Observable<ITestSuiteResponse> {
      var params = new HttpParams()
      .set('limit', '50')
      .set('offset', offset);
    if (selectedSuiteName != null) {
        params = params.set('suiteName', selectedSuiteName);
    }
    if (selectedTestName != null) {
      params = params.set('testName', selectedTestName);
    }
    if (selectedDateFrom != null) {
      params = params.set('dateFrom', this.sharedService.getFormattedDate(selectedDateFrom));
    }
    if (selectedDateTo != null) {
      var dateTo = this.sharedService.getNextDate(selectedDateTo);
      params = params.set('dateTo', this.sharedService.getFormattedDate(dateTo));
    }
    return this.http.get<ITestSuiteResponse>(this.testSuiteUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  getSuiteNames(): Observable<string[]> {
    return this.http.get<string[]>(this.suiteNamesUrl, {headers: this.headers, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  getTestNames(): Observable<string[]> {
    return this.http.get<string[]>(this.testNamesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  getTestNamesForSuite(suiteName: string): Observable<string[]> {
    var params = new HttpParams()
    if (suiteName != null) {
      params = params.set('suiteName', suiteName);
    }
    return this.http.get<string[]>(this.testNamesForSuiteUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

}
