import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from "../../services/shared.service";
import { ConfirmationService } from "primeng/api";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { BrandService } from '../../services/config/brand.service';
import { EnvRegionService } from '../../services/config/env-region.service';
import { AdhocMessageConfigService } from '../../services/client/adhoc-message-config.service';
import { AdhocNotificationService } from '../../services/client/adhoc-notification.service';

@Component({
  selector: 'app-message-exec-viewer',
  templateUrl: './message-exec-viewer.component.html',
  styleUrl: './message-exec-viewer.component.css'
})
export class MessageExecViewerComponent {

  @ViewChild('dtMessageBatchExecs') dtMessageBatchExecs:any;

  isSpinnerHidden: boolean = true;

  brandCodes: any[] = [];
  batchTypes: any[] = [];
  executionMethods: any[] = [];
  statusCodes: any[] = [];
  messageBatchExecsCols: any[] = [];
  messageBatchExecs: any[] = [];

  selectedBrandCode!: string;
  selectedBatchType!: string;
  selectedExecutionMethod!: string;
  selectedStatusCode!: string;
  selectedDateFrom!: string;
  selectedDateTo!: string;

  recOffset!: number;  
  isBrandSelectionShown: boolean = true;
  isBatchTypeSelectionShown: boolean = true;

  isNextButtonShown: boolean = false;
  isPriorButtonShown: boolean = false;

  selectedMeaagesgBatchExec: any;
  nextPageTokens?: any;
  pageSize: number = 15;  

  constructor(
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private userContextService: UserContextService,
    private brandService: BrandService,
    private envRegionService: EnvRegionService, 
    private adhocMessageConfigService: AdhocMessageConfigService,
    private adhocNotificationService: AdhocNotificationService
  ) { }  

  ngOnInit(): void {
    this.messageBatchExecsCols = [
      { field: 'messageBatchExecId', header: 'Exec Id', width:'5%', display: 'table-cell' },
      { field: 'brandCode', header: 'Brand Code', width:'5%', display: 'table-cell' },
      // { field: 'billerName', header: 'Biller Name', width:'10%', display: 'table-cell' },
      { field: 'executionMethod', header: 'Exec Method', width:'8%', display: 'table-cell' },
      { field: 'messageBatchType', header: 'Batch Type', width:'10%', display: 'table-cell' },
      { field: 'status', header: 'Status', width:'5%', display: 'table-cell' },
      { field: 'messageTemplateName', header: 'Template', width:'12%', display: 'table-cell' },
      { field: 'filterLogicExpr', header: 'Filter', width:'15%', display: 'table-cell' },
      { field: 'startDate', header: 'Start Date', width:'10%', display: 'table-cell' },
      { field: 'finishDate', header: 'Finish Date', width:'10%', display: 'table-cell' },
      { field: 'totalAccountCnt', header: 'Total Accounts', width:'5%', display: 'table-cell' },
      { field: 'totalProcessed', header: 'Total Processed', width:'5%', display: 'table-cell' },
      { field: 'totalSuccessful', header: 'Total Targeted', width:'5%', display: 'table-cell' },
      { field: 'totalFailed', header: 'Total Failed', width:'5%', display: 'table-cell' },
      ] 
    // Prepopulate the Status Code dropdown
    this.statusCodes = [
      {label: '', value: null},
      {label: 'Started', value: 'STARTED'},
      {label: 'Pending', value: 'PENDING'},
      {label: 'Finished', value: 'FINISHED'}
    ];
    // Prepopulate the Batch Type dropdown
    this.batchTypes = [
      {label: '', value: null},
      {label: 'Targeted Communication', value: 'ADHOC_MESSAGE'},
      {label: 'Standard Notification', value: 'WALLETRON_NOTIFICATION'},
    ];
    // Prepopulate the Execution Methods dropdown
    this.executionMethods = [
      {label: '', value: null},
      {label: 'On Demand', value: 'ON_DEMAND'},
      {label: 'By Schedule', value: 'BY_SCHEDULE'},
      {label: 'External Call', value: 'EXTERNAL_CALL'},
    ];
    // Figure out the list of granted brands to the current user
    let hasAllBrands = this.userContextService.hasAllBrands();
    // Prepopulate the Biller dropdown
    this.findGrantedBrands(hasAllBrands);
    // if the user is an external (non-ACI), then hide the batch type
    if (this.userContextService.isBillerRep()) {
      this.isBatchTypeSelectionShown = false;
      this.selectedBatchType = 'ADHOC_MESSAGE';
    }
    this.selectedDateFrom = this.sharedService.getCurrentDate();
    this.selectedDateTo = this.sharedService.getCurrentDate();
  }

  findGrantedBrands(hasAllBrands: boolean) {
    this.brandCodes = [];
    this.brandCodes.push({label: '', value: null});
    this.isSpinnerHidden = false;
    this.brandService.getBrands()
    .subscribe({
      next: (response) => {
        if (response) {
          response.forEach(brand => {
            if (hasAllBrands || this.userContextService.hasBrandGranted(brand.brandCode)) {
              this.brandCodes.push({label: brand.brandCode + ' - ' + brand.brandName, value: brand.brandCode});
            }
          })
        }
        // If only one brand is granted to the user, then hide selection of brands from the criteria
        if (this.brandCodes.length == 2) {
          this.isBrandSelectionShown = false;
          this.selectedBrandCode = this.brandCodes[1].value;
        } else {
          this.isBrandSelectionShown = true;
          this.selectedBrandCode = '';
        }
        this.isSpinnerHidden = true;
      },
      error: (error) => {
        this.alertTheUser("Unable to retrieve the list of billers.");
        this.isSpinnerHidden = true;      
      },
      complete: () => {
      }
    });
  }

  setDefaultPageTokens(): void {
  this.nextPageTokens = new Map<string, string>();
    // find out all enviromental regions
    this.envRegionService.getEnvRegions()
    .subscribe({
      next: (response) => {
        for(let i = 0; i < response.length; i++){
          var prop = response[i];
          this.nextPageTokens.set(prop.envCode, ":");
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  } 

  onMessageBatchExecRowSelect(event: any) {
  }

  onSearchClick(event: any) {
    this.setDefaultPageTokens();
    this.getMessageBatchExecs("next", true);
  }

  onNextClick(event: any) {
    this.getMessageBatchExecs("next", false);
  }

  onPriorClick(event: any) {
    this.getMessageBatchExecs("prior", false);
  }

  onClearClick(event: any) {
    this.isNextButtonShown = false;
    if (this.isBrandSelectionShown) {
     this.selectedBrandCode = '';
    }
    this.selectedBatchType = '';
    this.selectedStatusCode = '';
    this.selectedExecutionMethod = '';
    this.selectedDateFrom = this.sharedService.getCurrentDate();
    this.selectedDateTo = this.sharedService.getCurrentDate();
    this.messageBatchExecs = [];
    this.dtMessageBatchExecs.reset();
  } 
  
   // Select Message Batch Execution Logs based on the user defined selection criteria
  getMessageBatchExecs(direction: string, isSearchClicked: boolean): void {
    this.isSpinnerHidden = false;
    this.recOffset = 0;
    this.dtMessageBatchExecs.reset();
    this.messageBatchExecs = [];
    this.adhocNotificationService.getMessageBatchExecutions(this.selectedBrandCode, this.selectedBatchType, 
      this.selectedExecutionMethod, this.selectedStatusCode, this.selectedDateFrom, this.selectedDateTo, 
      this.nextPageTokens, direction, this.pageSize)
      .subscribe({
        next: (response) => {
          console.log('getMessageBatchExecs', response);
          this.nextPageTokens = response.paging.nextPageTokens;
          console.log('nextPageTokens', this.nextPageTokens);
          if (response.executionResults && response.executionResults.length > 0) {
            this.messageBatchExecs = response.executionResults;
            this.messageBatchExecs.forEach(item => {
              item.startDate = this.sharedService.formatFromISO(item.startDate || '');
              item.finishDate = this.sharedService.formatFromISO(item.finishDate || '');
              item.messageBatchType = item.messageBatchType == 'ADHOC_MESSAGE' ? 'TARGETED_COMM' : 'STANDARD_NOTIF';
            })
            this.messageBatchExecs.sort(function (a, b) {
              return b.startDate.localeCompare(a.startDate) || b.messageBatchExecId - a.messageBatchExecId;
            });
          }
          this.isSpinnerHidden = true;
          // Display the Next and/or Prior button(s)
          this.isNextButtonShown = false;
          this.isPriorButtonShown = false;  
          if (!direction && !response.paging.hasMore) {
            return;
          }
          if (direction == "next" || direction == "") {
              if (response.paging.hasMore) {
                this.isNextButtonShown = true;
              } else {
                this.isNextButtonShown = false;
              }
              if (response.paging.resultsPerPage >= 0 && direction != "" && !isSearchClicked) {
                this.isPriorButtonShown = true;
              }
            }
            if (direction == "prior") {
              if (response.paging.hasMore) {
                this.isPriorButtonShown = true;
              } else {
                this.isPriorButtonShown = false;
              }
              if (response.paging.resultsPerPage >= 0) {
                this.isNextButtonShown = true;
              }
            }
        },
        error: (error) => {
          this.alertTheUser("Unable to retrieve Message Batch Execution Logs.");
          this.isSpinnerHidden = true;
      },
        complete: () => {
        }
      });
  }

  onChangeBrand(event: any) {
    // Display the Next button only when retrieving transactions for one biller
    let brandCode = event.value;
    if (!brandCode) {
      this.isNextButtonShown = false;
    }
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'message-exec-viewer',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }    

}
