import { Component, OnInit, ViewChild } from '@angular/core';
import { EventOccurrence } from '../../models/event.occurrence';
import { EventOccurrenceService } from '../../services/monitor/event-occurrence.service';
import { JobRegister } from '../../models/job.register';
import { StepRegister } from '../../models/step.register';
import { IJobRunner }  from '../../interfaces/job-runner';
import { IJobRegister }  from '../../interfaces/job-register';
import { IStepRegister }  from '../../interfaces/step-register';
import { IChunkRegister }  from '../../interfaces/chunk-register';
import { IEventOccurrence }  from '../../interfaces/event-occurrence';
import { JobMonitorService } from '../../services/monitor/job-monitor.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { Table } from 'primeng/table';
import { TreeNode } from 'primeng/api';
import { BrandService } from '../../services/config/brand.service';
import { LookupService } from '../../services/config/lookup.service';
import { SharedService } from "../../services/shared.service";
import { EnvRegionService } from "../../services/config/env-region.service";
import { throwToolbarMixedModesError } from '@angular/material/toolbar';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']
})

export class MonitoringComponent implements OnInit {

  //  @ViewChild(Table) dt: Table;
   @ViewChild('dtEvents') dtEvents:any;
   @ViewChild('dtJobs') dtJobs:any;

    isJobsSpinnerHidden: boolean = true;
    isEventsSpinnerHidden: boolean = true;
    isIncludedMaintenenceJobs: boolean = false;
    autoResize: boolean = true;
  
    // Event occurrrence variables
    events: EventOccurrence[] = [];
    selectedEvent!: EventOccurrence;
    event!: IEventOccurrence;// = new EventOccurrence(); 
    eventDisplayDialog!: boolean;
    eventCols: any[] = [];

    // and selection criteria
    eventNames: any[] = [];
    selectedEventName!: string;
    selectedEventDateFrom!: string;
    selectedEventDateTo!: string;
    eventOffset: number = 0;

    // Job Execution/Register variables
    jobs: JobRegister[] = [];
    selectedJob!: JobRegister;
    // job: JobRegister = new JobRegister();
    job!: IJobRegister;
    jobDisplayDialog!: boolean;
    jobCols: any[] = [];

    // Step execution variables
    stepCols: any[] = [];
    steps: StepRegister[] = [];
  
    // Chunk execution variables
    chunkCols: any[] = [];
    chunks: StepRegister[] = [];

    //  Parameters and Additional Info
    paramCols: any[] = [];
    params: any[] = [];
    additionalInfo: any[] = [];

    // Step parameters and additional info
    stepParametersCols: any[] = [];
    stepParameters: any[] = [];
    stepAdditionalInfoCols: any[] = [];
    stepAdditionalInfo: any[] = [];

    // Selection criteria
    jobCodes: any[] = [];
    jobStatuses: any[] = [];
    jobCategories: any[] = [];
    brandCodes: any[] = [];
    envRegions: any[] = [];
    selectedRegion!: string;
    selectedBrandCode!: string;
    selectedJobCode!: string;
    selectedJobStatus!: string;
    selectedJobDateFrom!: string;
    selectedJobDateTo!: string;
    jobOffset: number = 0;

    taskTree: TreeNode[] = [];

    isSummaryShown: boolean = false;
    jobSummary: any;
    jobStatus: any;
    environmentCount: any;
    optionsSummary = options('The number of job runs by job code and execution status');
    optionsStatus = options('Job runs by execution status');
    optionsEnvironmentCount = options('Job runs by enviromental region');
  
    constructor(
      private eventOccurrenceService: EventOccurrenceService, 
      private envRegionService: EnvRegionService,
      private brandService: BrandService, 
      private lookupService: LookupService, 
      private jobMonitorService: JobMonitorService, 
      private userContextService: UserContextService,
      private sharedService: SharedService)  {}
  
    ngOnInit() {
      // Define the columns for Event Occurrences grid
      this.eventCols = [
        { field: 'scheduledEventOccurrenceId', header: 'Occurrence Id', width:'15%', display: 'none' },
        { field: 'eventOccurrenceUuid', header: 'Occurrence Id', width:'15%', display: 'none' },
        { field: 'eventName', header: 'Event Name', width:'20%', display: 'table-cell' },
        { field: 'eventDescription', header: 'Event Description', width:'40%', display: 'table-cell' },
        { field: 'sourceEnvironment', header: 'Env. Region', width:'15%', display: 'table-cell' },
        { field: 'sourceHost', header: 'Host', width:'10%', display: 'table-cell' },
        { field: 'eventTimestamp', header: 'Event Timestamp', width:'15%', display: 'table-cell' },
        { field: 'eventAttributes', header: 'Event Attributes', display: 'none'}
      ]; 
      // Define the columns for Job Executions grid
      this.jobCols = [
        { field: 'jobUuid', header: 'Job Uuid', width:'10%', display: 'none' },
        { field: 'brandCode', header: 'Brand', width:'7%', display: 'table-cell' },
        { field: 'taskCode', header: 'Job Code', width:'23%', display: 'none' },
        { field: 'jobName', header: 'Job Name', width:'23%', display: 'table-cell' },
        { field: 'jobCategory', header: 'Category', width:'13%', display: 'none' },
        { field: 'status', header: 'Status', width:'10%', display: 'table-cell' },
        { field: 'environment', header: 'Env. Region', width:'10%', display: 'table-cell' },
        { field: 'host', header: 'Host', width:'10%', display: 'table-cell' },
        { field: 'exitCode', header: 'Exit Code', width:'10%', display: 'table-cell' },
        { field: 'startTimestamp', header: 'Start Timestamp', width:'15%', display: 'table-cell' },
        { field: 'endTimestamp', header: 'End Timestamp', width:'15%', display: 'table-cell' }
      ]; 
      // Define the columns for Step Executions grid
      this.stepCols = [
        { field: 'seqNo', header: 'Seq No', width:'6%', display: 'table-cell' },
        { field: 'stepName', header: 'Step Name', width:'12%', display: 'table-cell' },
        { field: 'status', header: 'Status', width:'7%', display: 'table-cell' },
        { field: 'startTimestamp', header: 'Start Timestamp', width:'13%', display: 'table-cell' },
        { field: 'endTimestamp', header: 'End Timestamp', width:'13%', display: 'table-cell' },
        { field: 'exitCode', header: 'Exit Code', width:'8%', display: 'table-cell' },
        { field: 'exitMessage', header: 'Exit Message', width:'17%', display: 'table-cell' }
      ]; 
      // Define the columns for Chunk Executions grid
      this.chunkCols = [
        { field: 'seqNo', header: 'Seq No', width:'5%', display: 'table-cell' },
        { field: 'chunkName', header: 'Chunk Name', width:'15%', display: 'table-cell' },
        { field: 'status', header: 'Status', width:'7%', display: 'table-cell' },
        { field: 'startTimestamp', header: 'Start Timestamp', width:'15%', display: 'table-cell' },
        { field: 'endTimestamp', header: 'End Timestamp', width:'15%', display: 'table-cell' },
        { field: 'exitCode', header: 'Exit Code', width:'8%', display: 'table-cell' },
        { field: 'exitMessage', header: 'Exit Message', width:'12%', display: 'table-cell' }
      ]; 
      // Define the columns for Parameters and Additional Info grids
      this.paramCols = [
        { field: 0, header: 'Key', width:'25%', display: 'table-cell' },
        { field: 1, header: 'Value', width:'75%', display: 'table-cell' }
      ]; 
      // Define the columns for Parameters and Additional Info grids for the Step
      this.stepParametersCols = [
        { field: 0, header: 'Key', width:'30%', display: 'table-cell' },
        { field: 1, header: 'Value', width:'70%', display: 'table-cell' }
      ]; 
      this.stepAdditionalInfoCols = [
        { field: 0, header: 'Key', width:'30%', display: 'table-cell' },
        { field: 1, header: 'Value', width:'70%', display: 'table-cell' }
      ]; 
      // Prepopulate the dates
      this.selectedJobDateFrom = this.sharedService.getCurrentDate();
      this.selectedJobDateTo = this.sharedService.getCurrentDate();
      this.selectedEventDateFrom = this.sharedService.getCurrentDate();
      this.selectedEventDateTo = this.sharedService.getCurrentDate();
      // Populate the charts
      if (this.userContextService.hasAllBrands()) {
        this.getJobSummary();
        this.getEnvironmentCount();
        this.isSummaryShown = true;
      }
      // Populate the dropdowns
      this.getEnviromentalRegions();
      this.getBrandCodes();
      this.getLookups();
//      this.getEventNames();
      this.getJobCodes();
//      this.getJobStatuses();
      this.getTreeNodes();
    }
  
    // P O P U L A T E   L O O K U P S
    getLookups(): void {
      this.lookupService.getAllLookups()
      .subscribe({
        next: (lookups) => {
          // Populate event names
          var eventNames = lookups.filter(function(lookup) {
            return lookup.category == 'event-occurrence-name';
          });
          for(let i = 0; i < eventNames.length; i++){
            this.eventNames.push({label: eventNames[i].lookupCode, value: eventNames[i].lookupCode});
          }
          // Populate job types
          var jobCodes = lookups.filter(function(lookup) {
            return lookup.category == 'job-code';
          });
          for(let i = 0; i < jobCodes.length; i++){
            this.jobCodes.push({label: jobCodes[i].lookupCode, value: jobCodes[i].lookupCode});
          }
          // Populate job statuses
          var jobStatuses = lookups.filter(function(lookup) {
            return lookup.category == 'job-status';
          });
          for(let i = 0; i < jobStatuses.length; i++){
            this.jobStatuses.push({label: eventNames[i].lookupCode, value: eventNames[i].lookupCode});
          }
          // Populate job categories
          var jobCategories = lookups.filter(function(lookup) {
            return lookup.category == 'job-category';
          });
          for(let i = 0; i < jobCategories.length; i++){
            this.jobCategories.push({label: jobCategories[i].lookupCode, value: jobCategories[i].lookupCode});
          }
        },
        error: (error) => {
        },
        complete: () => {
        }
      });
    }

    getEnviromentalRegions(): void {
      // find out all enviromental regions
      this.envRegionService.getEnvRegions()
      .subscribe({
        next: (response) => {
          for(let i = 0; i < response.length; i++){
            var prop = response[i];
            this.envRegions.push({label: prop.envCode, value: prop.envCode});
          }
        },
        error: (error) => {
        },
        complete: () => {
        }
      });
    } 

    onChangeEnvRegion(event: any) {
      if (this.selectedRegion) {
        this.envRegionService.getBrands(this.selectedRegion)
        .subscribe({
          next: (result) => {
            this.brandCodes = [];
            result.sort((a,b) => ((a.brandCode || '') > (b.brandCode || '')) ? 1 : (((b.brandCode || '') > (a.brandCode || '')) ? -1 : 0)); 
            for(let i = 0; i < result.length; i++){
              var prop = result[i];
                this.brandCodes.push({label: prop.brandCode, value: prop.brandCode});
            }
          },
          error: (error) => {
          },
          complete: () => {
          }
        });
      } else {
        this.getBrandCodes();
      }
    } 
  

    // E V E N T   O C C U R R E N C E S   S E C T I O N

  //   // Retrieve distinct event names
  //   getEventNames(): void {
  //     this.eventOccurrenceService.getEventNames()
  //        .subscribe(names => {
  //          for(let i = 0; i < names.length; i++){
  //            this.eventNames.push({label: names[i], value: names[i]});
  //         }
  //        });
  //  }

   // Select event occurrences based on the user defined selection criteria
    getEventOccurrences(selectedEventName: string, selectedDateFrom: string, selectedDateTo: string, offset: number): void {
      this.isEventsSpinnerHidden = false;
      this.dtEvents.reset();
      this.eventOccurrenceService.getEventOccurrences(selectedEventName, selectedDateFrom, selectedDateTo, offset)
          .subscribe(eventOccurrencesResponse => {
            this.eventOffset = eventOccurrencesResponse.paging.nextPageToken;
            this.events = eventOccurrencesResponse.eventOccurrenceList
          // Format the dates
          this.events.forEach(function (arrayItem) {
            arrayItem.eventTimestamp = arrayItem.eventTimestamp.replace('T', ' ').replace('Z[UTC]','');
          });
          this.isEventsSpinnerHidden = true;
        });
    }
  
    // Process events when clicking on Search, Next, and Clear buttons for Events Occurrences
    onEventSearchClick(event: any) {
      var offset = 0;
      this.getEventOccurrences(this.selectedEventName, this.selectedEventDateFrom, this.selectedEventDateTo, offset);
    }
  
    onEventNextClick(event: any) {
      var offset = this.eventOffset;
      this.getEventOccurrences(this.selectedEventName, this.selectedEventDateFrom, this.selectedEventDateTo, offset);
    }
  
    onEventClearClick(event: any) {
      this.selectedBrandCode = '';
      this.selectedJobCode = '';
      this.selectedEventName = '';
      this.selectedEventDateFrom = '';
      this.selectedEventDateTo = '';
      this.events = [];
      this.dtEvents.reset();
    }
  
    // Process events when clicking on a row within Events Occurrences grid
    onEventRowSelect(event: any) {
      debugger;
      this.event = this.cloneEvent(event.data);
      this.eventDisplayDialog = true;
    }
  
    cloneEvent(c: IEventOccurrence): EventOccurrence {
      // let event = new EventOccurrence();
      // for (let prop in c) {
      //     event[prop] = c[prop];
      // }
      let event: IEventOccurrence = {
        scheduledEventOccurrenceId: c.scheduledEventOccurrenceId,
        eventName: c.eventName,
        eventDescription: c.eventDescription,
        sourceEnvironment: c.sourceEnvironment,
        sourceHost: c.sourceHost,
        eventTimestamp: c.eventTimestamp,
        eventAttributes: c.eventAttributes
      };
      return event;
    }

    eventCancel() {
      this.event = {
        scheduledEventOccurrenceId: 0,
        eventName: '',
        eventDescription: '',
        sourceEnvironment: '',
        sourceHost: '',
        eventTimestamp: '',
        eventAttributes: ''
      };
      this.eventDisplayDialog = false;
    }
  

  // J O B   E X E C U T I O N   S E C T I O N

    // Retrieve tree nodes with job, staep, and chunk definitions
    getTreeNodes(): void {
      this.jobMonitorService.getTaskHierarchy()
      .subscribe(tasks => {
        this.taskTree = tasks;
      });
    }

    // Retrieve distinct brand codes
    getBrandCodes(): void {
      let userBrands = this.userContextService.getUserBrands();
      if (userBrands) {
        userBrands.forEach(brand => {
          this.brandCodes.push({label: brand, value: brand});
        })
      }
      // this.brandService.getBrands()
      // .subscribe(brands => {
      //   for(let i = 0; i < brands.length; i++){
      //     this.brandCodes.push({label: brands[i].brandCode, value: brands[i].brandCode});
      //  }
      //  this.brandCodes.sort((a, b) => (a.value >= b.value) ? 1 : -1)
      // });
   }

    // Retrieve distinct job codes
    getJobCodes(): void {
      this.jobMonitorService.getJobCodes()
      .subscribe(jobCodes => {
        for(let i = 0; i < jobCodes.length; i++){
          this.jobCodes.push({label: jobCodes[i], value: jobCodes[i]});
       }
      });
   }

    // // Retrieve distinct job types
    // getJobTypes(): void {
    //   this.daqJobExecutionService.getJobTypes()
    //      .subscribe(names => {
    //        for(let i = 0; i < names.length; i++){
    //          this.jobTypes.push({label: names[i], value: names[i]});
    //       }
    //      });
    // }

    // // Retrieve distinct job statuses
    // getJobStatuses(): void {
    //     this.daqJobExecutionService.getJobStatuses()
    //        .subscribe(names => {
    //          for(let i = 0; i < names.length; i++){
    //            this.jobStatuses.push({label: names[i], value: names[i]});
    //         }
    //        });
    // }
 
   // Select job executions based on the user defined selection criteria
   getJobExecutions(selectedRegion: string, selectedBrandCode: string, selectedJobCode: string, selectedJobStatus: string,  
       selectedJobDateFrom: string, selectedJobDateTo: string, offset: number, includeMaintenenceJobs: boolean): void {
        this.isJobsSpinnerHidden = false;
      this.dtJobs.reset();
      this.jobMonitorService.getJobExecutions(selectedRegion, selectedBrandCode, selectedJobCode, selectedJobStatus, 
        selectedJobDateFrom, selectedJobDateTo, offset, includeMaintenenceJobs)
        .subscribe(jobExecutionsResponse => {
          this.jobOffset = jobExecutionsResponse.paging.nextPageToken;
          this.jobs = this.formatDate(jobExecutionsResponse.jobRunnerList);
          this.isJobsSpinnerHidden = true;
      });
  }

  // Process events when clicking on Search, Next, and Clear buttons for Job Execution
  onJobSearchClick(event: any) {
    var offset = 0;
    this.getJobExecutions(this.selectedRegion, this.selectedBrandCode, this.selectedJobCode, this.selectedJobStatus,
      this.selectedJobDateFrom, this.selectedJobDateTo, offset, this.isIncludedMaintenenceJobs);
  }

  onJobNextClick(event: any) {
    var offset = this.jobOffset;
    this.getJobExecutions(this.selectedRegion, this.selectedBrandCode, this.selectedJobCode, this.selectedJobStatus, 
      this.selectedJobDateFrom, this.selectedJobDateTo, offset, this.isIncludedMaintenenceJobs);
  }

  onJobClearClick(event: any) {
    this.selectedRegion = '';
    this.selectedBrandCode = '';
    this.selectedJobCode = '';
    this.selectedJobStatus = '';
    this.selectedJobDateFrom = '';
    this.selectedJobDateTo = '';
    this.jobs = [];
    this.dtJobs.reset();
  }

  // Process events when clicking on a row within Job Execution grid
  onJobRowSelect(selectedJob: any) {
    debugger;
    //this.job = this.cloneJob(job.data);
    this.isJobsSpinnerHidden = false;
    let jobUuid = selectedJob.data.jobUuid;
    this.jobMonitorService.getJobExecution(jobUuid)
      .subscribe(job => {
        debugger;
        if (job) {
          // this.job = this.cloneJob2(job);
          this.job = job;
          if (this.job.startTimestamp) {
            this.job.startTimestamp = this.job.startTimestamp.replace('T', ' ').replace('Z[UTC]','');
          }
          if (this.job.endTimestamp) {
            this.job.endTimestamp = this.job.endTimestamp.replace('T', ' ').replace('Z[UTC]','');
          }
          this.steps = this.formatDate(job.stepRegisterList);
          this.steps.forEach(element => {
            element.additionalInfo = JSON.stringify(element.additionalInfo);
            element.parameters = JSON.stringify(element.parameters);
          });
      
          this.params = job.parameters ? Object.entries(job.parameters) : [];
          this.additionalInfo = job.additionalInfo ? Object.entries(job.additionalInfo) : [];
          this.chunks = [];
          this.stepParameters = [];
          this.stepAdditionalInfo = [];
          debugger;
          this.jobDisplayDialog = true;
        }
        this.isJobsSpinnerHidden = true;
    });

  }

  // Process events when clicking on a row within Step Execution grid
  onStepRowSelect(step: any) {
    if (!this.isEmpty(step.data.chunkRegisterList)) {
      this.chunks = this.formatDate(step.data.chunkRegisterList);
      this.chunks.forEach(element => {
          element.additionalInfo = JSON.stringify(element.additionalInfo);
          element.parameters = JSON.stringify(element.parameters);
      });
    } else {
      this.chunks = [];
    }
    if (!this.isEmpty(step.data.parameters)) {
      this.stepParameters = Object.entries(step.data.parameters);
    } else {
      this.stepParameters = [];
    }
    if (!this.isEmpty(step.data.additionalInfo)) {
      this.stepAdditionalInfo = Object.entries(step.data.additionalInfo);
    } else {
      this.stepAdditionalInfo = [];
    }
  }

  // cloneJob(c: IJobRegister): JobRegister {
  //   // let job = new JobRegister();
  //   // for (let prop in c) {
  //   //   job[prop] = c[prop];
  //   // }

  //   let steps: IStepRegister[] = [];
  //   if (c.stepRegisterList) {
  //     let steps: IStepRegister[] = [];
  //     c.stepRegisterList.forEach(item => {
  //       let step: IStepRegister = {
  //         stepUuid: item.stepUuid,
  //         taskCode: item.taskCode,
  //         brandCode: item.brandCode,
  //         status: item.status,
  //         parameters:  item.parameters,
  //         additionalInfo:  item.additionalInfo,
  //         startTimestamp: item.startTimestamp,
  //         endTimestamp: item.endTimestamp,
  //         exitCode: item.exitCode,
  //         exitMessage: item.exitMessage,
  //         seqNo: item.seqNo,
  //       };
  //       steps.push(step);
  //     })
  //   }

  //   let job: JobRegister = {
  //     jobUuid: c.jobUuid,
  //     taskCode: c.taskCode,
  //     brandCode: c.brandCode || '',
  //     status: c.status,
  //     parameters: c.parameters || '',
  //     additionalInfo:  c.additionalInfo || '',
  //     jobName: c.jobName,
  //     jobDescription: c.jobDescription,
  //     environment: c.environment,
  //     host: c.host,
  //     executionMethod: c.executionMethod || '',
  //     jobCategory: c.jobCategory || '',
  //     exitCode: c.exitCode || '',
  //     exitMessage: c.exitMessage || '',
  //     startTimestamp: c.startTimestamp,
  //     endTimestamp: c.endTimestamp || '',
  //     stepRegisterList: steps
  //   };

  //   return job;
  // }

  
  // cloneJob2(c: IJobRunner): JobRegister {
  //   let job = new JobRegister();
    // for (let prop in c) {
    //   job[prop] = c[prop];
    // }

    // var job : JobRegister = Object.assign({}, c);

    // let steps: IStepRegister[] = [];
    // if (c.stepRegisterList) {
    //   let steps: IStepRegister[] = [];
    //   c.stepRegisterList.forEach(item => {
    //     let step: IStepRegister = {
    //       taskUuid: item.taskUuid,
    //       taskCode: item.taskCode,
    //       brandCode: item.brandCode,
    //       status: item.status,
    //       parameters:  item.parameters,
    //       additionalInfo:  item.additionalInfo,
    //       startTimestamp: item.startTimestamp,
    //       endTimestamp: item.endTimestamp,
    //       exitCode: item.exitCode,
    //       exitMessage: item.exitMessage,
    //       seqNo: item.seqNo,
    //     };
    //     steps.push(step);
    //   })
    // }


    // let job: JobRegister = {
    //   jobUuid: c.jobUuid,
    //   taskCode: c.taskCode,
    //   brandCode: c.brandCode || '',
    //   status: c.status,
    //   parameters: c.parameters || '',
    //   additionalInfo:  c.additionalInfo || '',
    //   jobName: c.jobName,
    //   jobDescription: c.jobDescription,
    //   environment: c.environment,
    //   host: c.host,
    //   executionMethod: c.executionMethod || '',
    //   jobCategory: c.jobCategory || '',
    //   exitCode: c.exitCode || '',
    //   exitMessage: c.exitMessage || '',
    //   startTimestamp: c.startTimestamp,
    //   endTimestamp: c.endTimestamp || '',
    //   stepRegisterList: steps
    // }
  //   return job;
  // }

  jobCancel() {
    // this.job = new JobRegister();
    this.job = {
      jobUuid: '',
      taskCode: '',
      status: '',
      jobName: '',
      jobDescription: '',
      environment: '',
      host: '',
      startTimestamp: '',
      };
    this.jobDisplayDialog = false;
  }

  formatDate(array: any) {
    array.forEach(function (arrayItem: any) {
      if(arrayItem.startTimestamp) {
        arrayItem.startTimestamp = arrayItem.startTimestamp.replace('T', ' ').replace('Z[UTC]','');
      }
      if(arrayItem.endTimestamp) {
        arrayItem.endTimestamp = arrayItem.endTimestamp.replace('T', ' ').replace('Z[UTC]','');
      }
    });
    return array;
  }

  expandAll(){
    this.taskTree.forEach( node => {
        this.expandRecursive(node, true);
    } );
  }

  collapseAll(){
    this.taskTree.forEach( node => {
        this.expandRecursive(node, false);
    } );
  }

  private expandRecursive(node:TreeNode, isExpand:boolean){
    node.expanded = isExpand;
    if (node.children){
        node.children.forEach( childNode => {
            this.expandRecursive(childNode, isExpand);
        } );
    }
  }

  isEmpty(obj: any) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
  }  
  
  update(event: Event) {
    this.getJobSummary();
    this.getEnvironmentCount();
  }

  // Select job executions sammary
  getJobSummary(): void {
    this.jobMonitorService.getJobSummary()
        .subscribe(result => {
          var labels = [];
          var started = [];
          var aborted = [];
          var finished = [];
          var totalStarted = 0;
          var totalAborted = 0;
          var totalFinished = 0;
          for(let i = 0; i < result.length; i++){
            labels.push(result[i].jobCode);
            started.push(result[i].started);
            totalStarted += result[i].started;
            aborted.push(result[i].aborted);
            totalAborted += result[i].aborted;
            finished.push(result[i].finished);
            totalFinished += result[i].finished;
          }

          this.jobSummary = {
            labels: labels,
            datasets: [
                {
                    label: 'Started',
                    backgroundColor: 'blue',
                    borderColor: 'darkblue',
                    data: started
                },
                {
                    label: 'Aborted',
                    backgroundColor: 'red',
                    borderColor: 'darkred',
                    data: aborted
                },
                {
                  label: 'Finished',
                  backgroundColor: 'green',
                  borderColor: 'darkgreen',
                  data: finished
              }
          ]
        }
        
        this.jobStatus = {
          labels: ['Started','Aborted','Finished'],
          datasets: [
              {
                  data: [totalStarted, totalAborted, totalFinished],
                  backgroundColor: [
                      "blue",
                      "red",
                      "green"
                  ],
                  hoverBackgroundColor: [
                    "lightblue",
                    "lightred",
                    "lightgreen"
                ]
              }]    
          };
      });
  }

  getEnvironmentCount(): void {
    this.jobMonitorService.getEnvironmentCount()
        .subscribe(result => {
          this.environmentCount = buildChartData('environment', result); 
      });
  }


}  



  function buildChartData(brandOrEnv: any, list: any) {
    var labels = [];
    var counts = [];
    var backgroundColors = [];
    var hoverBackgroundColors = [];
    for(let i = 0; i < list.length; i++){
      if (brandOrEnv == 'brand') {
        labels.push(list[i].BrandCode);
      } else if (brandOrEnv == 'environment') {
        labels.push(list[i].environment);
      }
      counts.push(list[i].cnt);
      backgroundColors.push("#" + randomColor());
      hoverBackgroundColors.push("#" + randomColor());
    }
    return {
      labels: labels,
      datasets: [
        {
          label: [],
          data: counts,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: hoverBackgroundColors
        }]    
      };    
  }

  function randomColor() {
    return Math.floor(Math.random()*16777215).toString(16);
  }

  function options(titleText: any) {
  return {
    title: {
      display: true,
      text: titleText,
      fontSize: 16
    },
    legend: {
      position: 'top'
    }
  }

}