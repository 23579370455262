import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { SharedService } from "../../services/shared.service";
import { AdhocMessageTemplateService } from '../../services/client/adhoc-message-template.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IMessageTemplate } from '../../interfaces/message-template';

@Component({
  selector: 'app-adhoc-message-template',
  // standalone: true,
  // imports: [],
  templateUrl: './adhoc-message-template.component.html',
  styleUrl: './adhoc-message-template.component.css'
})

export class AdhocMessageTemplateComponent {

  @Input() displayMessageTemplateDialog?: boolean;
  @Input('currentBrandSubject') currentBrandSubject!: Subject<any>;
  @Output() selectMessageTemplateEvent = new EventEmitter<IMessageTemplate>();

  currentBrand!: IBrand;

  messageTemplateForm!: FormGroup;

  messageTemplates!: IMessageTemplate[];
  messageTemplatesCols: any[] = [];
  selectedMessageTemplate: IMessageTemplate = <IMessageTemplate> {};
  selectedMessageTemplateCode!: string | null;
  modifiedMessageTemplateCode!: string; 

  isMessageTemplateDialogVisible: boolean = false;
  isMessageTemplateNew: boolean = false;
  isSelectMessageTemplateButtonShown: boolean = false;
  isMessageTemplateCodeShown: boolean = false;

  currentUser!: string;
  // name = 'Angular';
  resultMsg = "";

  brandCode: string = '';
  baseUrl: string = '';

  hintMsg = 'Hint! For the message template header, in most cases you may use the default value, '
  + 'which generates the timestamp when the notification is sent out.';

  defaultErrorMessage: string = "Error processing the request.  Please report to the Walletron Support team.";

  constructor(
    private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService,
    private adhocMessageTemplateService: AdhocMessageTemplateService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.currentUser = this.tokenStorageService.getCurrentUser().userName;
    this.messageTemplateForm = this.formBuilder.group({
      'messageTemplateId': new FormControl(null),
      'messageTemplateCode': new FormControl('', [Validators.maxLength(255)]),
      'messageTemplateName': new FormControl('', [Validators.required, Validators.maxLength(100)]),
      'messageTemplateDescription': new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      'messageTemplateHeader': new FormControl('', [Validators.maxLength(100)]),
      'messageTemplateText': new FormControl('', [Validators.maxLength(2000)]),
      'lastUpdateDate': new FormControl(''),
    });
    // Define the columns for Brands grid
    this.messageTemplatesCols = [
      // { field: 'messageTemplateCode', header: 'Message Template Code', display: 'table-cell', width:'25%' },
      { field: 'messageTemplateName', header: 'Message Template Name', display: 'table-cell', width:'35%' },
      { field: 'messageTemplateDescription', header: 'Message Template Description', display: 'table-cell', width:'55%' },
    ];     
    // Recieve the current brand from the parent component
    this.currentBrand = this.sharedService.getCurrentBrand()
    if (this.currentBrand) {
      this.setCurrentBrand(this.currentBrand);
    }
    this.currentBrandSubject.subscribe((item: IBrand) => {
      this.setCurrentBrand(item);
    });
  }
  
  private setCurrentBrand(brand: IBrand) {
    if (brand) {
      this.currentBrand = brand;
      this.brandCode = brand.brandCode;
      this.baseUrl = brand.baseUrl;
      // Call the service to invoke a Web API call
      this.getMessageTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.alertTheUser('No current brand determined.  Please select the current brand.');
    }
  }
  

  // Select all existing adhoc message templates for the current brand
  private getMessageTemplates(brandCode: string, baseUrl: string) {
    this.isSelectMessageTemplateButtonShown = false;
    this.adhocMessageTemplateService.getMessageTemplatesByBrand(brandCode, baseUrl || '')
    .subscribe({
      next: (response) => {
        if (response) {
          this.messageTemplates = response;
          this.selectedMessageTemplateCode = null;
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // When the user selects a row to identify a chosen message template
  onRowMessageTemplateSelect(event: any) {
    this.selectedMessageTemplate = <IMessageTemplate>  {
      messageTemplateId: event.data.messageTemplateId,
      messageTemplateCode: event.data.messageTemplateCode,
      messageTemplateName: event.data.messageTemplateName,
      messageTemplateDescription: event.data.messageTemplateDescription,
      messageTemplateHeader: event.data.messageTemplateHeader,
      messageTemplateText: event.data.messageTemplateText,

      passId: 0,
      brandCode: this.brandCode,
      enabled: true,
      alwaysReplacePreviousMsg: false,
      alwaysReplaceAllMessages: false,
      createDate: '',
      lastUpdateDate: '',
      adhocIndicator: true
    };
    this.selectedMessageTemplateCode = event.data.messageTemplateCode;
    if (event.data.messageTemplateCode) {
      this.isSelectMessageTemplateButtonShown = true;
    }
  }

  // To create a new message template
  onNewMessageTemplate() {
    this.messageTemplateForm.setValue({
      messageTemplateId: null,
      messageTemplateCode: null,
      messageTemplateName: '', 
      messageTemplateDescription: '',
      messageTemplateHeader: '#{wal.formatDateInTimezone(__MsgDateTime__,"M/d/yyyy h:mm a z", "America/New_York")}',
      messageTemplateText: null,
      lastUpdateDate: ''
    });
    this.isMessageTemplateNew = true;
    this.isMessageTemplateDialogVisible = true;
  }

  // To modify an existing message template
  onModifyMessageTemplate(event: any) {
    this.modifiedMessageTemplateCode = event.messageTemplateCode;
    this.adhocMessageTemplateService.getMessageTemplateById(this.brandCode, this.modifiedMessageTemplateCode, this.baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          console.log('onModifyMessageTemplate', response);
          this.messageTemplateForm.setValue({
            messageTemplateId: response.messageTemplateId,
            messageTemplateCode: response.messageTemplateCode,
            messageTemplateName: response.messageTemplateName, 
            messageTemplateDescription: response.messageTemplateDescription,
            messageTemplateHeader: response.messageTemplateHeader,
            messageTemplateText: response.messageTemplateText,
            lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate || '')
          });
          this.isMessageTemplateNew = false;
          this.isMessageTemplateDialogVisible = true;
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // To delete an existing filter config
  onDeleteMessageTemplate(event: any) {
    let messageTemplateCode: string = event.messageTemplateCode;
    let messageTemplateName: string = event.messageTemplateName;
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the message template ' + messageTemplateName + ' ?',
      header: 'Confirmation',
      key: 'message-template',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.adhocMessageTemplateService.deleteMessageTemplate(this.brandCode, messageTemplateCode, this.baseUrl)
          .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted message template ' + messageTemplateName + ' from brand '
                + this.brandCode);
            this.getMessageTemplates(this.brandCode, this.baseUrl);
            this.isMessageTemplateDialogVisible = false;
          },
          error: (error) => {
            this.alertTheUser(this.defaultErrorMessage);
          },
          complete: () => {
          }
        });               
      },
      reject: () => {
      }
    });

  }

  // When the user submits a request to save off a new or modified message template
  onSubmit(event: any) {
    let messageTemplateName = this.messageTemplateForm.value.messageTemplateName;
    let messageTemplate: IMessageTemplate = {
      passId: 0,
      messageTemplateId: 0,
      brandCode: this.brandCode,
      messageTemplateCode: this.messageTemplateForm.value.messageTemplateCode,
      messageTemplateName: messageTemplateName,
      messageTemplateDescription: this.messageTemplateForm.value.messageTemplateDescription,
      messageTemplateHeader: this.messageTemplateForm.value.messageTemplateHeader,
      messageTemplateText: this.messageTemplateForm.value.messageTemplateText,
      enabled: true,
      alwaysReplacePreviousMsg: false,
      alwaysReplaceAllMessages: false,
      createDate: '',
      lastUpdateDate: '',
      adhocIndicator: true
    }
    if (this.isMessageTemplateNew) {
      this.adhocMessageTemplateService.addMessageTemplate(this.brandCode, messageTemplate, this.baseUrl)
      .subscribe({
        next: (response) => {
          if (response) {
            this.alertTheUser('Successfully created message template ' + messageTemplateName + ' from brand '
                + this.brandCode);
            this.getMessageTemplates(this.brandCode, this.baseUrl);
            this.isMessageTemplateDialogVisible = false;
          }
        },
        error: (error) => {
          this.alertTheUser(this.defaultErrorMessage);
        },
        complete: () => {
        }
      });

    } else {
      // messageTemplate.messageTemplateId = this.messageTemplateForm.value.messageTemplateId;
      this.adhocMessageTemplateService.modifyMessageTemplate(
        this.brandCode, this.modifiedMessageTemplateCode, messageTemplate, this.baseUrl)
      .subscribe({
        next: (response) => {
          if (response && response.messageTemplateId) {
            this.alertTheUser('Successfully modified message template ' + messageTemplateName + ' from brand '
                + this.brandCode);
            this.getMessageTemplates(this.brandCode, this.baseUrl);
            this.isMessageTemplateDialogVisible = false;
          }
        },
        error: (error) => {
          this.alertTheUser(this.defaultErrorMessage);
        },
        complete: () => {
        }
      });
  
    }
  }

  // When the user clicks on Select messageTemplate to exist out from the form and send a message to the parent form
  onSelectMessageTemplate() {
    if (this.selectedMessageTemplateCode) {
      this.selectMessageTemplateEvent.emit(this.selectedMessageTemplate);
    } else {
      this.alertTheUser ("No message template has been selected.  Please chose a message template from the list, then click on Select button again.");
    }
  }  


  // When the user cancels the dialog for message templates
  onCancelMessageTemplate() {
    this.selectMessageTemplateEvent.emit();
  } 

  // When the Message Template dialog is canceled
  onCancelNewMessageTemplate() {
    this.isMessageTemplateDialogVisible = false;
  }   

//  private calcNewMessageTemplateCode() {
//   let root = this.brandCode + '-adhoc-notif-';
//   if (this.messageTemplates.length > 0) {
//     let maxSuffix: number = 0;
//     this.messageTemplates.forEach(item => {
//       let pos = item.messageTemplateCode.lastIndexOf('-');
//       let itemSuffix = Number(item.messageTemplateCode.substring(pos + 1));
//       maxSuffix = maxSuffix < itemSuffix ? itemSuffix : maxSuffix;
//     })
//     maxSuffix++;
//     return root + maxSuffix.toString().padStart(3, '0');
//   } else {
//     return  root + '001';
//   }
//  }

  private alertTheUser(message: any) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'message-template',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}

