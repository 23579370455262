import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { IPortalUser } from '../../interfaces/portal-user';
import { IUserTypeRole } from '../../interfaces/user-type-role';
import { IBillerBrand } from '../../interfaces/biller-brand';
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";


@Injectable({
  providedIn: 'root'
})
export class PortalUserService {


  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected communicationAuth = AppConfigService.settings.appSetting.communicationAuth;
  // protected authServerUrl = AppConfigService.settings.apiServer.authServerUrl;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected communicationAuth = environment.appSetting.communicationAuth;
  protected authServerUrl = environment.apiServer.authServerUrl;

  private usersUrl = this.baseUrl + '/common/users';
  // private roleNamesUrl = this.baseUrl + '/common/users/distinctRoleNames'; 

  private authUsersUrl = this.authServerUrl + '/auth/users'; // PRIV_WA_USER_VIEW, PRIV_WA_USER_MODIFY
  private authUsersListingUrl = this.authServerUrl + '/auth/users/listing/criteria';  // PRIV_WA_USER_VIEW
  private authUserUrl = this.authServerUrl + '/auth/users/{userName}';  // none -> PRIV_PORTAL_HOME_PAGE
  private authModifyUserUrl = this.authServerUrl + '/auth/users/{userId}';  // PRIV_WA_USER_MODIFY
  private authDeleteUserUrl = this.authServerUrl + '/auth/users/{userName}';  // PRIV_WA_USER_MODIFY
  private authUserRoleNamesUrl = this.authServerUrl + '/auth/users/{userName}/roles'; // none -> PRIV_PORTAL_HOME_PAGE
  private authUserEmailAddressUrl = this.authServerUrl + '/auth/users/{userName}/email-address'; // none ->  PRIV_PORTAL_HOME_PAGE 
  private authRoleNamesUrl = this.authServerUrl + '/auth/roles/active-role-names'; // PRIV_WA_USER_VIEW   
  private authBillerBrandsUrl = this.authServerUrl + '/auth/brands'; // PRIV_WP_BRAND_VIEW
  private authUnlockUserUrl = this.authServerUrl + '/auth/users/{userName}/unlock'; // PRIV_WA_USER_MODIFY
  private groupOnboardUrl = this.authServerUrl + '/auth/users/group-onboard'; // PRIV_WA_USER_MODIFY
  private userTypeRolesUrl = this.authServerUrl + '/user-types/roles'; // PRIV_WA_USER_TYPE_VIEW

  private currentUserName!: string;
    
  private headers = new HttpHeaders()
    .append('Authorization', this.basicAuth);

  private tokenHeaders =  new HttpHeaders();
    // .set('Authorization', 'Bearer ' + this.accessToken);
    

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService) { 
      var currentUserValue = this.tokenStorageService.getCurrentUser();
      if (currentUserValue != null) {
        this.currentUserName = currentUserValue.userName;
        this.headers = this.headers.append('portal-user', this.currentUserName);
        // var accessToken = this.tokenStorageService.getAuthToken();  //currentUserValue.authToken;
        // this.tokenHeaders = this.tokenHeaders.append('Authorization', 'Bearer ' + accessToken);
      }
    }

  // Portal Users Related Services

  public getUsers(userName: string, roleName: string, brandCode: string, userType: string): Observable<IPortalUser[]> {
    var params = new HttpParams()
    if (userName) {
      params = params.set('userName', userName);
    }
    if (roleName) {
      params = params.set('roleNames', roleName);
    }
    if (brandCode) {
      params = params.set('brandCodes', brandCode);
    }
    if (userType) {
      params = params.set('userType', userType);
    }
    return this.http.get<IPortalUser[]>(this.authUsersUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public getUsersListing(userName: string, roleName: string, brandCode: string, userType: string): Observable<IPortalUser[]> {
    var params = new HttpParams()
    if (userName) {
      params = params.set('userName', userName);
    }
    if (roleName) {
      params = params.set('roleNames', roleName);
    }
    if (brandCode) {
      params = params.set('brandCodes', brandCode);
    }
    if (userType) {
      params = params.set('userType', userType);
    }
    return this.http.get<IPortalUser[]>(this.authUsersListingUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Find out the user's profile, including roles, brands, and attributes
  public getUser(userName: string): Observable<IPortalUser> {
    let url = this.authUserUrl.replace('{userName}', userName);
      return this.http.get<IPortalUser>(url, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  public getUserRoles(userName: string): Observable<string[]> {
    return this.http.get<string[]>(this.usersUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }
  
  // public getUserEmailAddress(userName: string): Observable<any> {
  //   var url = this.authUserEmailAddressUrl.replace('{userName}', userName);
  //   return  this.http.get<any>(url, {headers: this.headers, responseType: 'text'}).pipe(
  //     catchError((err) => {
  //       debugger;
  //       return throwError(err);
  //     })
  //   );
  // }

  public getUserEmailAddress(userName: string): Observable<string> {
    var url = this.authUserEmailAddressUrl.replace('{userName}', userName);
    return  this.http.get(url, {headers: this.headers, responseType: 'text'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public getRoleNames(): Observable<string[]> {
    return this.http.get<string[]>(this.authRoleNamesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public getBillerBrands(): Observable<IBillerBrand[]> {
    return this.http.get<IBillerBrand[]>(this.authBillerBrandsUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public addUser(user: IPortalUser): Observable<any> {
    let newUser: IPortalUser = {
      userName: user.userName,
      enabled: user.enabled,
      locked: user.locked,
      userRoles: user.userRoles,
      userBrands: user.userBrands,
      lastLogin: '',
      loginFailCount: 0,
      userId: 0,
      password: user.password,
      userAttributes: user.userAttributes,
      passwordChangeRequired: user.passwordChangeRequired,
      createdByUser: this.currentUserName,
      modifiedByUser: this.currentUserName,
      emailAddress: user.emailAddress
    };
    return this.http.post(this.authUsersUrl, newUser, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public modifyUser(user: IPortalUser): Observable<any> {
    let modifiedUser: IPortalUser = {
      userName: user.userName,
      enabled: user.enabled,
      locked: user.locked,
      userRoles: user.userRoles,
      userBrands: user.userBrands,
      lastLogin: '',
      loginFailCount: 0, //user.loginFailCount,
      userId: user.userId,
      password: user.password ? user.password : '',
      userAttributes: user.userAttributes,
      passwordChangeRequired: user.passwordChangeRequired,
      modifiedByUser: this.currentUserName,
      emailAddress: user.emailAddress
    };
    let url = this.authModifyUserUrl.replace('{userId}', user.userId.toString());
    // let authHeaders = this.getAuthHeaders();
    return this.http.put(url, modifiedUser, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public deleteUser(userName: string): Observable<any> {
    let url = this.authDeleteUserUrl.replace('{userName}', userName);
    // let authHeaders = this.getAuthHeaders();
    return this.http.delete(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public unlockUser(userName: string): Observable<any> {
    let url = this.authUnlockUserUrl.replace('{userName}', userName);
//    let authHeaders = this.getAuthHeaders();
    return this.http.put(url, '', {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public groupOnboard(onboardProfile: any, csvFile: File): Observable<any> {
    // Prepare the body for a POST request
    let formData = new FormData();
    formData.append("csvInputStream", csvFile);
    formData.append("brandCode", onboardProfile.brandCode);
    formData.append("initialPassword", onboardProfile.initialPassword);
    formData.append("userType", onboardProfile.userType);
    formData.append("passwordChangeRequired", onboardProfile.passwordChangeRequired);
    formData.append("csvSeparator", onboardProfile.csvSeparator);
    formData.append("roleListSeparator", onboardProfile.roleListSeparator);
    formData.append("userNameColumnPosition", onboardProfile.userNameColumnPosition);
    formData.append("userRolesColumnPosition", onboardProfile.userRolesColumnPosition);
    formData.append("emailAddressColumnPosition", onboardProfile.emailAddressColumnPosition);
    formData.append("fullNameColumnPosition", onboardProfile.fullNameColumnPosition);
    // Make a RESTful call
    return this.http.post<any>(this.groupOnboardUrl, formData, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public getUserTypeRoles(): Observable<IUserTypeRole[]> {
    return this.http.get<IUserTypeRole[]>(this.userTypeRolesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }



}
