<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
  <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>    
<mat-sidenav-content>
<app-header (sidenavToggle)="sidenav.toggle()"></app-header>
<main>
  <router-outlet></router-outlet>
  <app-messages></app-messages>  
</main>
</mat-sidenav-content>
</mat-sidenav-container>