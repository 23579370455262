import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { PortalUserService } from "../../services/admin/portal-user.service";
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { AccountAdminService } from '../../services/admin/account-admin.service';
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'app-enrollment-invitation',
  templateUrl: './enrollment-invitation.component.html',
  styleUrls: ['./enrollment-invitation.component.css']
})
export class EnrollmentInvitationComponent implements OnInit {

  titleMessage = "Enrollment Invitation";
  isCurrentBrandShown: boolean  = true;
  isOfferFieldsShown: boolean  = false;
  isSmsAreaShown: boolean = false;
  isEmailSmsAreaShown: boolean = false;
  openBrandSelectionDialog!: boolean;  
  currentBrand: IBrand;

  passTemplates: any[] = [];
  offers: any[] = [];
  emailSmsTemplates: any[] = [];
  origEmailSmsTemplates: any[] = [];
  offerFields: any[] = [];
  offerFieldsCols: any[] = [];
  selectedPassTemplate!: number;
  selectedOffer!: number;
  selectedEmailSmsTemplate!: number;
  emailAddress!: string;
  controlEmailRecipient!: string;
  phoneNum!: string;
  otherRecipients!: string;

  autoResize: boolean = true;

  constructor(
    private brandProfileService: BrandProfileService,
    private tokenStorageService: TokenStorageService,
    private portalUserService: PortalUserService,
    private accountAdminService: AccountAdminService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService    
  ) { 
    this.currentBrand = this.sharedService.getDummyBrand();
  }

  ngOnInit(): void {
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.openBrandSelectionDialog = false;
      this.isEmailSmsAreaShown = false;
      this.populateDropDowns(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      if (this.selectedPassTemplate && this.selectedOffer) {
        this.getOfferFields(this.currentBrand.brandCode, this.selectedOffer, this.currentBrand.baseUrl);
      }
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    } 
    // Define the columns for offerFields grid
    this.offerFieldsCols = [
      { field: 'offerFieldId', header: 'Offer Field Id', width:'0%', display: 'none', editable: false },
      { field: 'fieldKey', header: 'Field Key', width:'35%', display: 'table-cell', editable: false },
      { field: 'fieldType', header: 'Field Type', width:'0%', display: 'none', editable: false },
      { field: 'fieldValue', header: 'Field Value', width:'65%', display: 'table-cell', editable: true },
    ];  
    // Prepopulate the email address input box
    var currentUserValue = this.tokenStorageService.getCurrentUser();
    if (currentUserValue != null) {
      var userName = currentUserValue.userName;
      this.portalUserService.getUserEmailAddress(userName)
      .subscribe(result => {
        if (result){ 
          this.emailAddress = result; 
          this.controlEmailRecipient = result;
        }
      });
    }
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.isEmailSmsAreaShown = false;
      this.currentBrand = item;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.sharedService.setCurrentBrand(item);
      this.populateDropDowns(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      if (this.selectedPassTemplate && this.selectedOffer) {
        this.getOfferFields(this.currentBrand.brandCode, this.selectedOffer, this.currentBrand.baseUrl);
      }
    }
  }  

  // Populate pass templates and brand offers dropdowns
  populateDropDowns(brandCode: string, baseUrl: string) {
    this.selectedPassTemplate = 0;
    this.passTemplates.length = 0;
    this.selectedOffer = 0;
    this.offers.length = 0;
    this.isOfferFieldsShown = false;
    this.isEmailSmsAreaShown = false;
    // this.enrollmentLinks.length = 0;
    // Select all existing brand pass templates
    this.brandProfileService.getPassTemplates(brandCode, baseUrl)
    .subscribe( 
      (response) => {
        response.forEach(item => {
          this.passTemplates.push({label: item.passName, value: item.passId});
        })
        // If there is just one pass template, then default to it
        if (response.length == 1) {
          this.selectedPassTemplate = response[0].passId || 0;
        }
        // Select all existing brand offers
        this.getOffers(brandCode, baseUrl);
        // Select the templates
        this.getEmailSmsTemplates(brandCode, baseUrl);
      },
      (error)  => {
        this.alertTheUser(error.message);
      }
    )
  }

  // Retrieve brand offers based on the chosen brand
  getOffers(brandCode: string, baseUrl: string) {
    this.offers.length = 0;
    this.selectedOffer = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandOffers(brandCode, baseUrl)
        .subscribe( 
          (response) => {
            response.forEach(item => {
              this.offers.push({label: item.offerName, value: item.offerId});
            })
            // If there is just one offer, then default to it
            if (response.length == 1) {
              this.selectedOffer = response[0].offerId || 0;
            }
            // If we have just one pass template and on offer, then immediately retrieve enrollment links
            if (this.selectedPassTemplate && this.selectedOffer) {
              this.getOfferFields(this.currentBrand.brandCode, this.selectedOffer, this.currentBrand.baseUrl);
            }
          },
          (error)  => {
            this.alertTheUser(error.message);
          }
        )
  }

  // Retrieve Email/SMS templates based on the chosen brand
  getEmailSmsTemplates(brandCode: string, baseUrl: string) {
    this.emailSmsTemplates.length = 0;
    this.selectedEmailSmsTemplate = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getEmailSmsTemplates(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.origEmailSmsTemplates = response;
          response.forEach(element => {
              this.emailSmsTemplates.push({label: element.templateName, value: element.templateID});
            });
          if (response.length == 1) {
            this.selectedEmailSmsTemplate = response[0].templateID;
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onChangePassTemplate(event: any) {
    this.isOfferFieldsShown = false;
    if (this.selectedPassTemplate && this.selectedOffer) {
      this.getOfferFields(this.currentBrand.brandCode, this.selectedOffer, this.currentBrand.baseUrl);
    }
  } 

  onChangeOffer(event: any) {
    this.isOfferFieldsShown = false;
    if (this.selectedPassTemplate && this.selectedOffer) {
      this.getOfferFields(this.currentBrand.brandCode, this.selectedOffer, this.currentBrand.baseUrl);
    }
  }

  onChangeEmailSmsTemplate(event: any) {
    this.isEmailSmsAreaShown = false;
    let currTemplate = this.origEmailSmsTemplates.filter(template => template.templateID == this.selectedEmailSmsTemplate);
    if (currTemplate) {
      if (currTemplate[0].templateType == "E") {
        this.isSmsAreaShown = false;
        this.isEmailSmsAreaShown = true;
      } else if (currTemplate[0].templateType == "S") {
        this.isSmsAreaShown = true;
        this.isEmailSmsAreaShown = true;
      }
    }
  }   

  // Select all existing offer fields
  getOfferFields(brandCode: string, offerId: number, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getOfferFields(brandCode, offerId, brandUrl)
    .subscribe({
      next: (response) => {
        this.offerFields = response.filter(offerField => offerField.overrideStatus == 'B');            
        this.isOfferFieldsShown = true;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }   

  onSendEmail(event: any) {
    if (!(this.selectedPassTemplate && this.selectedOffer && this.selectedEmailSmsTemplate)) {
      this.alertTheUser("Please make sure to select Pass Template, Field Set and Email/SMS Template from the dropdowns.");
      return;
    }
    let overrideFieldsMap = new Map<string, string>();  
    this.offerFields.forEach(item => {
      overrideFieldsMap.set(item.fieldKey, item.fieldValue);
    });
    let otherEmailAddresses: string[] = [];
    if (this.otherRecipients) {
      otherEmailAddresses = this.otherRecipients.split(',');
    }
    // Call the service to invoke a Web API call
    this.accountAdminService.assembleEmailBroadcasting(this.currentBrand.brandCode, this.selectedPassTemplate, 
      this.selectedOffer, this.selectedEmailSmsTemplate, overrideFieldsMap, this.controlEmailRecipient, 
      this.emailAddress, otherEmailAddresses, this.currentBrand.baseUrl)
      .subscribe({
        next: (response) => {
          this.alertTheUser("Accepted request to assemble a test enrollment for brand " + this.currentBrand.brandCode
           + ".  Check your email inbox out.");
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  } 

  onSendSms(event: any) {
    if (!(this.selectedPassTemplate && this.selectedOffer && this.selectedEmailSmsTemplate)) {
      this.alertTheUser("Please make sure to select Pass Template, Field Set and Email/SMS Template from the dropdowns.");
      return;
    }
    // Call the service to invoke a Web API call
    this.accountAdminService.assembleSmsBroadcasting(this.currentBrand.brandCode, this.selectedPassTemplate, 
      this.selectedOffer, this.selectedEmailSmsTemplate, this.controlEmailRecipient, this.phoneNum,
      this.currentBrand.baseUrl)
      .subscribe({
        next: (response) => {
          this.alertTheUser("Accepted request to assemble a test enrollment for brand " + this.currentBrand.brandCode + ".  Check your phone for a new SMS.");
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  } 

  makeTitleMessage (currentBrand: IBrand) {
    var title = '';
    if (currentBrand.brandCode) {
      title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) >> ';
    }
    title += 'Enrollment Invitation';
    return title;
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'enrollment-invitation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
