<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand and a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
                class="p-button-raised p-button-rounded" [disabled]="isDetailsShown">
            </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 

<!--   -->

<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-8 side-list-content-data" *ngIf="isListShown">

        <!-- The list of Field Sets -->

        <div class="row" *ngIf="isListOfOffersShown">

            <div class="col-12">

                <p-card header="List of Brand Field Sets" subheader="" styleClass="ui-card-powder" >
    
                    <p-table #dt [columns]="cols" [value]="brandOffers" selectionMode="single" 
                         sortMode="multiple" [paginator]="true" [rows]="15" >
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pEditableRow]="rowData">
                                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{rowData[col.field]}}
                                </td>
                                <td style="text-align:center">
                                    <button pButton type="button" icon="pi pi-table" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Go to individual fields" 
                                        class="btn ui-button-primary" (click)="offerFieldsRedirect(rowData)" style="margin-right:10px">
                                    </button>                                
                                    <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}" 
                                        data-toggle="tooltip" data-placement="top" title="Modify field set" 
                                        class="btn ui-button-info p-mr-2" (click)="onRowEdit(rowData)">
                                    </button> 
                                    <button pButton type="button" icon="pi pi-clone" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Clone field set" 
                                        class="btn ui-button-info" (click)="onRowClone(rowData)" *ngIf="isSubmitShown" >
                                    </button>
                                    <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Delete field set" *ngIf="isSubmitShown" 
                                        class="btn ui-button-danger" (click)="onRowDelete(rowData)" style="margin-right:10px">
                                    </button>                                
                                    <button pButton type="button" icon="pi pi-sitemap" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Clone offer to a different brand" *ngIf="isSubmitShown" 
                                        class="ui-button-primary" (click)="onRowCloneToOtherBrand(rowData)">
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary" let-rowData>
                            <div style="text-align:left">
                                <button pButton type="button" label="Add" icon="pi fa-plus" iconPos="left" (click)="showDialogToAdd()"  
                                class="p-button-info p-button-rounded" *ngIf="isSubmitShown"></button>  
                            </div>
                        </ng-template>    
                  </p-table> 
            
                    <p-footer>
                        <mat-icon class="nav-caption wal-center">list_all</mat-icon>
                    </p-footer>
                </p-card>
                    
            </div>
    
        </div>
 
    </div>

    <!--- Second card with the details -->

    <div @flyInOut class="col-6 side-list-content-data" *ngIf="isDetailsShown">

        <div class="col-12">

            <p-card header="Brand Field Set Details" subheader="" styleClass="ui-card-light" >
            
                <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">

                    <div class="field grid">
                        <div class="col-1"></div>
                                <label for="offerId" class="col-fixed label" style="width: 15em">Field Set Id:</label>
                                <div class="col">
                                    <input pInputText id="offerId" type="text" formControlName="offerId" readonly="true" 
                                        pTooltip="Unique field set identifier" tooltipPosition="top" autofocus style="width: 10em"/> 
                                </div>
                        </div>                         
                        
                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="offerName" class="col-fixed label" style="width: 15em">Field Set Name:</label>
                                <input pInputText id="offerName" type="text" formControlName="offerName" style="width: 15em" 
                                    [size]="45" placeholder="Required" pTooltip="Discriptive field set name" tooltipPosition="top"/> 
                                <p-message severity="error" text="Field set name is required" *ngIf="!userform.controls['offerName'].valid&&userform.controls['offerName'].dirty"></p-message>                 
                        </div>
            
                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="offerDesc" class="col-fixed label" style="width: 15em">Field Set Description:</label>
                                <input pInputText id="offerDesc" type="text" formControlName="offerDesc" style="width: 40em" 
                                    [size]="400" placeholder="Required" pTooltip="Discriptive field set description" tooltipPosition="top"/> 
                                <p-message severity="error" text="Field set description is required" *ngIf="!userform.controls['offerDesc'].valid&&userform.controls['offerDesc'].dirty"></p-message>                 
                        </div>
            
                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="itemDesc" class="col-fixed label" style="width: 15em">Type:</label>
                                <p-dropdown id ="itemDesc" [options]="itemDescs" formControlName="itemDesc" 
                                    [filter]="false" [editable]="false" pTooltip="Select the type" tooltipPosition="top"> 
                                </p-dropdown>
                        </div>
    
                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="discount" class="col-fixed label" style="width: 15em">Discount:</label>
                                <input pInputText id="discount" type="number" formControlName="discount" 
                                    pTooltip="Numeric field" tooltipPosition="top"/> 
                        </div>
            
                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                                <input pInputText type="createDate" id="createDate" type="text" formControlName="createDate" readonly="true">
                        </div>
            
                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                                <input pInputText type="lastUpdateDate" id="lastUpdateDate" type="text" formControlName="lastUpdateDate" readonly="true">
                        </div>
            
                         <div class="col-12">
                            <div  class="col-3" id="divshow">
<!-- 
                                <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" *ngIf="isSubmitShown"></button>
                                <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button>
 -->
                                <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></p-button>
                                <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>
    
                            </div> 
                        </div>

                </form>
        
                <p-footer>
                    <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
                </p-footer>
            </p-card>

        </div>

    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-offers"></p-confirmDialog>





<p-dialog appendTo="body" header="Clone Offer" [(visible)]="openCloneToOtherBrandDialog"
         [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '35%'}" position="top" [showHeader]="true" [closable]="false">

        <p-card header="Clone Offer (Field Set) to Different Brand" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="cloneform" (ngSubmit)="onCloneToOtherBrand(cloneform.value)">
    
            <div>
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="targetBrandCode" class="col-fixed label" style="width: 10em">Target Brand:</label>
                        <p-dropdown id ="targetBrandCode" [options]="targetBrands" formControlName="targetBrandCode" 
                            [filter]="true" [editable]="false" pTooltip="Select the target brand to clone to" tooltipPosition="top"> 
                            <p-message severity="error" text="Target brand is required" *ngIf="!cloneform.controls['targetBrandCode'].valid&&cloneform.controls['targetBrandCode'].dirty"></p-message>                 
                        </p-dropdown>
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="offerName" class="col-fixed label" style="width: 10em">Offer Name:</label>
                        <input pInputText id="offerName" type="text" formControlName="offerName" style="width: 15em"
                            [size]="45" placeholder="Required" pTooltip="Target Offer Name" tooltipPosition="top"/> 
                        <p-message severity="error" text="Offer Name is required" *ngIf="!cloneform.controls['offerName'].valid&&cloneform.controls['offerName'].dirty"></p-message>                 
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="offerDescription" class="col-fixed label" style="width: 10em">Offer Description:</label>
                        <input pInputText id="offerDescription" type="text" formControlName="offerDescription" style="width: 20em" 
                            [size]="45" placeholder="Required" pTooltip="Target Offer Description" tooltipPosition="top"/> 
                        <p-message severity="error" text="Offer Description is required" *ngIf="!cloneform.controls['offerDescription'].valid&&cloneform.controls['offerDescription'].dirty"></p-message>                 
                </div>
    
                <div class="col-12" >
<!-- 
                    <div  class="col-2" *ngIf="isSubmitShown"  id="divshow">
                        <button pButton type="submit" label="Submit" class="ui-button-primary" [disabled]="!cloneform.valid" ></button>
                    </div>          
                    <div  class="col-2" *ngIf="isSubmitShown" id="divshow">
                        <button type="button" pButton icon="fa fa-check" (click)="onCancelCloneToOtherBrand()" label="Cancel"></button>
                    </div>          
 -->
                    <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!cloneform.valid" *ngIf="isSubmitShown" ></p-button>
                    <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelCloneToOtherBrand()"></p-button>


                </div>
    
            </div>
        </form>
    
        <p-footer>
                <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
            </p-footer>
        </p-card>

</p-dialog>
