import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldLabelColor extends OfferFieldBase {

    brandVariables: any[] = [];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Key, Field Value

        if (this.data.fieldValue && this.data.fieldValue.search('#{') >= 0) {

            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'textarea',
                key: 'fieldValue',
                label: 'Field Value (Color Expression)',
                value: this.data.fieldValue,
                // styleWidth: '40em',
                rows: 1,
                cols: 80,
                required: false,
                order: 4
            }));
    
            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'colorpicker',
                key: 'fieldValueBeta',
                label: '(or Color Picker)',
                value: '',
                styleWidth: '15em',
                colorInit: '',
                required: false,
                order: 5
            }));
    
        } else {

            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'textarea',
                key: 'fieldValue',
                label: 'Field Value (Color Expression)',
                value: '',
                // styleWidth: '40em',
                rows: 1,
                cols: 80,
                required: false,
                order: 4
            }));
            
            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'colorpicker',
                key: 'fieldValueBeta',
                label: '(or Color Picker)',
                value: this.data.fieldValue,
                styleWidth: '15em',
                colorInit: this.data.fieldValue ? this.data.fieldValue : '',
                required: false,
                order: 5
            }));

        }

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 6
        }));
        
        this.offerFieldAttributes.sort((a, b) => a.order - b.order);

    }

}

