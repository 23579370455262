import { Component, OnInit, Input } from '@angular/core';
import { Validators,FormControl,FormGroup,FormBuilder,ValidatorFn } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { UserContextService } from "../../services/safeguard/user-context.service";
import { SharedService } from "../../services/shared.service";
import { BrandService } from '../../services/config/brand.service';
import { CsrService } from '../../services/client/csr-service';
import { IBrand } from '../../interfaces/brand';
import { IEnrollmentInvitationRequest } from '../../interfaces/enrollment-invitation-request';
import { AccountField } from '../../classes/account-field';
import { ICsrInputField } from 'src/app/interfaces/csr-input-field';

@Component({
  selector: 'app-csr-account',
  templateUrl: './csr-account.component.html',
  styleUrls: ['./csr-account.component.css']
})
export class CsrAccountComponent implements OnInit {

  accountForm!: FormGroup;
  emailForm!: FormGroup;
  smsForm!: FormGroup;

  openBrandSelectionDialog: boolean = false;
  isBrandSelectionButtonShown: boolean = false;
  isSpinnerHidden: boolean = true;
  isSecondaryAccountIdShown: boolean = false;
  isTretiaryAccountIdShown: boolean = false;
  isImagePreviewShown: boolean = false;
  isGuestImagePreviewShown: boolean = false;
  isAccountDetailsShown: boolean = false;
  isCsrInstructShown: boolean = false;
  isSendEnrollmentLinksShown: boolean = false;
  isCoachingShown: boolean = false;
  isDeregisterAccountShown: boolean = false;

  primaryTooltip: string = "Please enter a valid account number";
  primaryErrorText: string = "Account Number is required";

  useSecondaryAccountId: boolean = false;
  secondaryAccountIdName: string = '';
  secondaryAccountIdLabel: string = 'Secondary Account Id:';
  secondaryTooltip: string = "";
  secondaryErrorText: string = "";
  
  useTertiaryAccountId: boolean = false;
  tertiaryAccountIdName: string = '';
  tertiaryAccountIdLabel: string = 'Tertiary Account Id:';
  tertiaryTooltip: string = "";
  tertiaryErrorText: string = "";

  coachingBodyEmail: string = '';
  coachingBodySMS: string = '';
  coachingHeaderEmail: string = '';
  coachingHeaderSMS: string = '';
  coachingBody: string = '';
  coachingHeader: string = '';
  deregCmdSupported: boolean = false;

  currentBrand: IBrand;
  clientBrandCode!: string;
  brandName!: string;
  baseUrl!: string;

  brandImage: any;
  guestBrandImage: any;

  resultMsg!: string;
  titleMessage: string = "CSR";

  csrInstructHeader: string = 'Walletron Background';
  csrInstructBody: string = '';

  accountFound: boolean = false;
  accountRegistered: boolean = false;
  message: string = '';

  @Input() accountFields: AccountField<string>[] = []; 

  // Customer identity verification fields
  evFields: AccountField<string>[] = []; 
  
  constructor(
    private fb: FormBuilder, 
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService,    
    private brandService: BrandService,
    private csrService: CsrService
    ) { 
      this.currentBrand = this.sharedService.getDummyBrand();      
    }

  ngOnInit(): void {

    this.accountForm = this.fb.group({
      'accountNumber': new FormControl('', [Validators.required, Validators.maxLength(45)]),
      'secondaryAccountId': new FormControl(''),
      'tertiaryAccountId': new FormControl('')
    });

    this.emailForm = this.fb.group({
      'customerEmailAddress': new FormControl('', [Validators.required])
    });

    this.smsForm = this.fb.group({
      'customerPhoneNo': new FormControl('', [Validators.required])
    });

    this.newBrandCleanup();
    this.newAccountCleanup();

    // Get the client's brand code 

    // If the current brand is already known, then use it as the default for the dashboard
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.getBrandProperties(this.currentBrand);
      this.openBrandSelectionDialog = false;
      this.getCsrConfiguration();
    } else {

      // There is no current brand yet - find out a set of brands (user attributes) that have been granted to them
      let grantedBrands = this.userContextService.getUserBrands();
      if (grantedBrands) {
        // Case I: the user was granted exactly one brand - make it as the current brand and skip the brand selection dialog
        if (grantedBrands.length == 1) {
          this.setSingleBrand(grantedBrands[0]);
        }
        // Case II: the user was granted multiple brands - popup the brand selection dialog
        //if (grantedBrands.length > 1) {
        else {
          this.isBrandSelectionButtonShown = true;
          this.openBrandSelectionDialog = true;
        }
      } else {
        this.isBrandSelectionButtonShown = true;
        this.openBrandSelectionDialog = true;
      }

    }
    
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.getBrandProperties(item);
      this.currentBrand = item;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.sharedService.setCurrentBrand(item);
      this.getCsrConfiguration();
    }
  }

  // Find out brand properies from the IBrand object
  getBrandProperties(brand: IBrand) {
    this.brandName = brand.brandName || '';
    this.clientBrandCode = brand.brandCode || '';
    this.baseUrl = brand.baseUrl || '';
  }

  // Set single brand as the default when the user has just one brand granted
  setSingleBrand(brandCode: string) {
    this.isSpinnerHidden = false;
    this.brandService.getBrandWithRegion(brandCode)


    .subscribe (brand => {
      this.currentBrand = brand;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.getBrandProperties(brand);
      this.isSpinnerHidden = true;
      this.getCsrConfiguration();
    });
  }

  // Get CSR configuration for the current brand
  getCsrConfiguration() {
    this.isSpinnerHidden = false;
    this.newBrandCleanup();
    this.newAccountCleanup();
    this.evFields = [];
    Object.keys(this.accountForm.controls).forEach(key => {
      if (key != 'accountNumber' && key != 'secondaryAccountId' && key != 'tertiaryAccountId') {
        this.accountForm.removeControl(key); 
      }
    });
    // Make a web api call
    this.csrService.getCsrConfiguration(this.currentBrand.brandCode, this.currentBrand.baseUrl)
    .subscribe({
      next: (result) => {
        console.log('csr config', result);
        if (result) {
          if (result.showCsrInstruct) {
            this.csrInstructHeader = result.csrInstructHeader || '';
            this.csrInstructBody = result.csrInstructBody || '';
            this.isCsrInstructShown = true;
          }
          // The "new" way of defining secondary and tertiary fields
          // If CSR fields are defined, then use them to define account lookup fields, including patterns and length restrictions
          if (result.csrInputFields) {
            for (let csrInputField of result.csrInputFields) {
              // Define the field validators
              let validators: ValidatorFn[] = [];
              validators.push(Validators.required);
              if (csrInputField.maxLength) {
                validators.push(Validators.maxLength(csrInputField.maxLength));
              }
              if (csrInputField.minLength) {
                validators.push(Validators.minLength(csrInputField.minLength));
              }
              if (csrInputField.fieldPattern) {
                validators.push(Validators.pattern(csrInputField.fieldPattern));
              }
              // Primary
              if (csrInputField.fieldRank == "1") {
                this.accountForm.controls['accountNumber'].clearValidators();
                this.accountForm.controls['accountNumber'].setValidators(validators);
                this.primaryTooltip = csrInputField.toolTip || '';
                this.primaryErrorText = csrInputField.validationMessage || '';
              }
              // Secondary
              if (csrInputField.fieldRank == "2") {
                this.accountForm.controls['secondaryAccountId'].clearValidators();
                this.accountForm.controls['secondaryAccountId'].setValidators(validators);
                this.secondaryAccountIdName = csrInputField.fieldName;
                this.secondaryAccountIdLabel = csrInputField.fieldLabel;
                this.secondaryTooltip = csrInputField.toolTip || '';
                this.secondaryErrorText = csrInputField.validationMessage || '';
                this.isSecondaryAccountIdShown = true;
                this.useSecondaryAccountId = result.useSecondaryAccountId;
              }
              // Tertiary
              if (csrInputField.fieldRank == "3") {
                this.accountForm.controls['tertiaryAccountId'].clearValidators();
                this.accountForm.controls['tertiaryAccountId'].setValidators(validators);
                this.tertiaryAccountIdName = csrInputField.fieldName;
                this.tertiaryAccountIdLabel = csrInputField.fieldLabel;
                this.tertiaryTooltip = csrInputField.toolTip || '';
                this.tertiaryErrorText = csrInputField.validationMessage || '';
                this.isTretiaryAccountIdShown = true;
                this.useTertiaryAccountId = result.useTertiaryAccountId;
              }
            }
          }
          // The "old" way of defining secondary and tertiary fields
          else {
            // Primary
            this.accountForm.controls['accountNumber'].clearValidators();
            this.accountForm.controls['accountNumber'].setValidators([Validators.required, Validators.maxLength(45), Validators.minLength(2)]);
            this.primaryTooltip = "Please enter a valid account number";
            this.primaryErrorText = "Account Number is required";
            // Secondary
            this.useSecondaryAccountId = result.useSecondaryAccountId;
            if (result.useSecondaryAccountId) {
              this.isSecondaryAccountIdShown = true;
              this.secondaryAccountIdName = result.secondaryAccountIdName || '';
              this.secondaryAccountIdLabel = result.secondaryAccountIdLabel || '';
            }
            this.accountForm.controls['secondaryAccountId'].clearValidators();
            this.secondaryTooltip =  '';
            // Tertiary
            this.useTertiaryAccountId = result.useTertiaryAccountId;
            if (result.useTertiaryAccountId) {
              this.isTretiaryAccountIdShown = true;
              this.tertiaryAccountIdName = result.tertiaryAccountIdName || '';
              this.tertiaryAccountIdLabel = result.tertiaryAccountIdLabel || '';
            }
            this.accountForm.controls['tertiaryAccountId'].clearValidators();
            this.tertiaryTooltip = '';
          }
          // if (result.showCsrInstruct) {
          //   this.csrInstructBody = result.csrInstructBody;
          // }
          // if (result.brandImageDescr) {
          //   this.brandImage = 'data:' + result.brandImageDescr.imageType + ';base64,' + result.brandImageDescr.imageContent;
          //   this.isImagePreviewShown = true;
          // }
          // if (result.guestBrandImageDescr) {
          //   this.guestBrandImage = 'data:' + result.guestBrandImageDescr.imageType + ';base64,' + result.guestBrandImageDescr.imageContent;
          //   this.isGuestImagePreviewShown = true;
          // }
          this.coachingBodyEmail = result.coachingBodyEmail || '';
          this.coachingBodySMS = result.coachingBodySMS || '';
          this.coachingHeaderEmail = result.coachingHeaderEmail || '';
          this.coachingHeaderSMS = result.coachingHeaderSMS || '';
          this.isCoachingShown = false;
          this.deregCmdSupported = result.deregCmdSupported || false;
          // Display customer identification validation fields if they are configured
          if (result.evFields) {
            result.evFields.forEach(item => {
              if (item.variableName && !this.accountForm.contains(item.variableName)) {
                this.evFields.push({
                  label: item.placeholder.literal || '', 
                  value: '', 
                  key: item.variableName || '', 
                  options: []
                });
                this.accountForm.addControl(item.variableName, new FormControl('', [Validators.required])); 
              }
            })          
          }
          this.isSpinnerHidden = true;
        }
        this.isSpinnerHidden = true;
 
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Make an account lookup
  onSubmit(value: string) {
    this.isSpinnerHidden = false;
    this.newAccountCleanup();
    // Use secondary and tertiary account lookup fields, if they are needed based on CSR configuration
    let accountNumber = this.accountForm.value.accountNumber;
    let secondaryAccountId = this.accountForm.value.secondaryAccountId; 
    let tertiaryAccountId = this.accountForm.value.tertiaryAccountId;
    // If secondary and tertiary fields are not used for account lookup - perhaps they are needed for EV of the customer
    if (this.evFields) {
      this.evFields.forEach(item => {
        if (!secondaryAccountId) {
          secondaryAccountId = this.accountForm.get(item.key)?.value
        } else if (!tertiaryAccountId) {
          tertiaryAccountId = this.accountForm.get(item.key)?.value
        }
      })
    }
    // Call the account lookup service
    this.csrService.getCsrAccountLookup(this.currentBrand.brandCode, this.currentBrand.baseUrl, 
      accountNumber, secondaryAccountId, tertiaryAccountId)
      .subscribe({
        next: (result) => {
          if (result) {
            this.accountFound = result.accountFound;
            this.accountRegistered = result.accountRegistered;
            this.message = result.message || '';
            if (result.accountFields) {
              result.accountFields.forEach(item => {
                this.accountFields.push({
                  label: item.key, 
                  value: item.value, 
                  key: item.key, 
                  options: []
                });
              })
            }
            if (this.accountFound) {
              this.isSendEnrollmentLinksShown = true;
              if (result.emailAddress) {
                this.emailForm.setValue({customerEmailAddress: result.emailAddress});
              }
              if (result.phoneNumber) {
                this.smsForm.setValue({customerPhoneNo: this.sharedService.formatPhoneNumber(result.phoneNumber)});
              }
            }
            this.isAccountDetailsShown = true;
            this.isDeregisterAccountShown = this.accountRegistered && this.deregCmdSupported
              && !this.userContextService.hasReadOnlyAccess();
            this.alertTheUser(result.message || '');
          }
          this.isSpinnerHidden = true;
        },
        error: (error) => {
        },
        complete: () => {
        }
      });
  }

  // Send out an email enrollment invitation
  onSendEmail(value: string) {
    this.isSpinnerHidden = false;
    let customerEmailAddress = this.emailForm.value.customerEmailAddress;
    let enrollmentInvitationRequest = this.makeEnrollmentInvitationRequest('EMAIL', customerEmailAddress, '');
    this.csrService.sendEnrollmentInvitation(this.currentBrand.brandCode, this.currentBrand.baseUrl, 
      enrollmentInvitationRequest)
      .subscribe({
        next: (response) => {
          this.coachingHeader = this.coachingHeaderEmail;
          this.coachingBody = this.coachingBodyEmail;
          this.isCoachingShown = true;
          this.isSpinnerHidden = true;
          this.alertTheUser("An email invitation has been successfuly sent to " + customerEmailAddress);
        },
        error: (error) => {
          this.isSpinnerHidden = true;
          this.alertTheUser(error.message);
            },
        complete: () => {
        }
      });
  }

  // Send out SMS enrollment invitation
  onSendSMS(value: string) {
    this.isSpinnerHidden = false;
    let customerPhoneNo = this.smsForm.value.customerPhoneNo;
    let enrollmentInvitationRequest = this.makeEnrollmentInvitationRequest('SMS', '', customerPhoneNo);
    this.csrService.sendEnrollmentInvitation(this.currentBrand.brandCode, this.currentBrand.baseUrl, 
      enrollmentInvitationRequest)
      .subscribe({
        next: (response) => {
          this.coachingHeader = this.coachingHeaderSMS;
          this.coachingBody = this.coachingBodySMS;
          this.isCoachingShown = true;
          this.isSpinnerHidden = true;
          this.alertTheUser("A SMS invitation has been successfuly sent to " + customerPhoneNo);
        },
        error: (error) => {
          this.isSpinnerHidden = true;
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  }

  // Deregister customer's account
  onDeregisterAccount(value: string) {
    let accountNumber = this.accountForm.value.accountNumber;
    this.confirmationService.confirm({
      message: "Do you really want to deactivate account " + accountNumber + " from Walletron?",
      header: 'Warning',
      key: 'csr-account',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",
      accept: () => {
        this.isSpinnerHidden = false;
        this.csrService.deregisterAccount(this.currentBrand.brandCode, this.currentBrand.baseUrl, accountNumber)
        .subscribe({
          next: (response) => {
            this.newAccountCleanup();
            this.isSpinnerHidden = true;
            this.alertTheUser("Account " +  accountNumber + " has been successfuly deregistered from Walletron.");
          },
          error: (error) => {
            this.isSpinnerHidden = true;
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      }
    })
  }

  newBrandCleanup() {
    this.isSecondaryAccountIdShown = false;
    this.isTretiaryAccountIdShown = false;
    // this.isImagePreviewShown = false;
    // this.isGuestImagePreviewShown = false;
    this.isCsrInstructShown = false;
  
    this.useSecondaryAccountId = false;
    this.secondaryAccountIdName = '';
    this.secondaryAccountIdLabel = 'Secondary Account Id:';
    this.useTertiaryAccountId = false;
    this.tertiaryAccountIdName = '';
    this.tertiaryAccountIdLabel = 'Tertiary Account Id:';
  
    // this.brandImage = null;
    // this.guestBrandImage = null;
  
    this.csrInstructHeader = 'Walletron Background';
    this.csrInstructBody = '';
    this.deregCmdSupported = false;

    this.accountForm.reset();
  }

  newAccountCleanup() {
    this.isAccountDetailsShown = false;
    this.isSendEnrollmentLinksShown = false;
    this.isCoachingShown = false;
    this.isDeregisterAccountShown = false;

    // this.coachingBodyEmail = '';
    // this.coachingBodySMS = '';
    // this.coachingHeaderEmail = '';
    // this.coachingHeaderSMS = '';
    // this.coachingBody = '';
    // this.coachingHeader = '';
  
    this.accountFound= false;
    this.accountRegistered= false;
    this.message = '';
    this.accountFields = [];
  
    this.emailForm.reset();
    this.smsForm.reset();

  }

  makeEnrollmentInvitationRequest(channel: string, emailAddress: string, phoneNo: string) {
    // let params: [{ name: string, value: string }] = new Array(0);//[,];
    let params = new Array(0);//[,];
    if(this.useSecondaryAccountId) {
      params.push({key: this.secondaryAccountIdName, value: this.accountForm.value.secondaryAccountId});
    }
    if(this.useTertiaryAccountId) {
      params.push({key: this.tertiaryAccountIdName, value: this.accountForm.value.tertiaryAccountId});
    }
    let enrollmentInvitationRequest  = {  // IEnrollmentInvitationRequest
      accountId: this.accountForm.value.accountNumber,
      emailAddr: emailAddress,
      phoneNum: phoneNo,
      channel: channel,
      params: params
    }
    return enrollmentInvitationRequest;
  }

  makeTitleMessage (currentBrand: IBrand) {
    var title = '';
    if (currentBrand.brandCode) {
      title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) >> ';
    }
    title += 'CSR';
    if (currentBrand.logoImageContent) {
      this.brandImage = 'data:' + currentBrand.logoImageType + ';base64,' + currentBrand.logoImageContent;
      this.isImagePreviewShown = true;
    }
    return title;
  }    

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'CSR Account',
      key: 'csr-account',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }  
}
