import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, query, stagger, group, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IConfigMapping }  from '../../interfaces/config-mapping';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'app-config-mappings',
  templateUrl: './config-mappings.component.html',
  styleUrls: ['./config-mappings.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class ConfigMappingsComponent implements OnInit {

  currentForm: string = "ConfigMappings";
  titleMessage = "Config Mappings";
  userform!: FormGroup;
  createform!: FormGroup;
  isListShown: boolean = true;
  isListConfigMappingsShown: boolean = true;
  isDetailsShown: boolean = false;
  isCreateShown: boolean = false;
  isSubmitShown: boolean = false;
  isCurrentBrandShown: boolean = true;

  mappingTypes: any[] = [];
  configMappings: any[] = [];
  mappingElements: any[] = [];
  templateNames: any[] = [];
  availableTemplateNames: any[] = [];
  cols!: any[];
  elemCols!: any[];
  resultMsg = "";
  currentBrand: IBrand;

  currentConfigMapping!: IConfigMapping;

  openBrandSelectionDialog!: boolean;
  isListOfConfigMappingsShown: boolean = true;
  isAdvanceElementShown: boolean = true;
  // openCloneToOtherBrandDialog: boolean = false;
  // targetBrands = [];  

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService    
      ) { 
        this.currentBrand = this.sharedService.getDummyBrand();        
      }

  ngOnInit(): void {
    // Define the columns on the grid
    this.cols = [
      { field: 'mappingId', header: 'Mapping Id', display: 'table-cell', width:'10%'  },
      { field: 'mappingName', header: 'Mapping Name', display: 'table-cell', width:'20%'  },
      { field: 'mappingType', header: 'Mapping Type', display: 'table-cell', width:'14%'  },
      { field: 'mappingDescription', header: 'Mapping Description', display: 'table-cell', width:'28%'  },
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'table-cell', width:'14%'  }
    ]; 

    this.elemCols = [
      { field: 'targetName', header: 'Target Name', display: 'table-cell', width:'35%'  },
      { field: 'dataType', header: 'Data Type', display: 'table-cell', width:'25%'  },
      // { field: 'ruleType', header: 'Rule Type', display: 'table-cell', width:'25%'  },
      { field: 'enabled', header: 'Enabled?', display: 'table-cell', width:'20%'  }
    ]; 


    this.mappingTypes = [
      {label: "[none]", value: "[none]"}, 
      {label: "INDEXED_FIELD", value: "INDEXED_FIELD"}, 
      {label: "FIXED_LENGTH_FIELD", value: "FIXED_LENGTH_FIELD"}, 
      {label: "NAMED_FIELD", value: "NAMED_FIELD"}, 
      {label: "CHOICES", value: "CHOICES"}, 
      {label: "MULTIFACTOR_CHOICES", value: "MULTIFACTOR_CHOICES"
    }];

    this.userform = this.fb.group({
      'mappingId': new FormControl(''),
      'mappingName': new FormControl('', [Validators.required, Validators.maxLength(200)]),
      'mappingType': new FormControl('', [Validators.required, Validators.maxLength(40)]),
      'mappingDescription': new FormControl('', [Validators.maxLength(1000)]),
      'mappingElements': new FormControl('', [Validators.required]),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
    });   
    
    this.createform= this.fb.group({
      'templateName': new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });
    
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.openBrandSelectionDialog = false;
      // this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.getBrandConfigMappings(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getTemplateNames(this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }
    // Hide the submit buttons for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_CONFIG_MAPPING_MODIFY')) {
      this.isSubmitShown = true;
    }
       
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.titleMessage = this.currentBrand.brandName + " ( " + this.currentBrand.brandCode + " ) > " + "Config Mappings";
      this.isCurrentBrandShown = false;
      this.sharedService.setCurrentBrand(item);
      // this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.getBrandConfigMappings(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getTemplateNames(this.currentBrand.baseUrl);
    }
  }

  // Select all existing config mappings for the brand
  getBrandConfigMappings(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getConfigMappings(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        this.configMappings = response;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  } 
  
  // Select all existing config mappings for the brand
  getTemplateNames(brandUrl: string) {
    this.templateNames.splice(0);
    // Call the service to invoke a Web API call
    this.brandProfileService.getConfigMappingsTemplateNames(brandUrl)
    .subscribe({
      next: (response) => {
        response.forEach(elem => {
          this.templateNames.push(elem);
        })
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  } 

  showDialogToAdd() {
    this.currentConfigMapping = {
      mappingId: 0,
      brandCode: '',
      mappingName: '',
      createDate: '',
      lastUpdateDate: '',
      mappingType: '',
      mappingElements: [
        {
          targetName: '',
          dataType: '',
          enabled: false,
          rule: {
              ruleType: '',
          }
      }]      
    };
    this.availableTemplateNames.splice(0);
    this.availableTemplateNames.push({label: '[none]', value: null});
    this.templateNames.forEach(item => {
      if (this.configMappings.findIndex(mapping => mapping.mappingName == item) < 0) {
        this.availableTemplateNames.push({label: item, value: item});
      }
    })
    this.createform.setValue({templateName: null});
    this.isListShown = false;
    this.isCreateShown = true;
  }

  onRowEdit(event: any) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getConfigMapping(event.mappingId, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
              this.currentConfigMapping = response;
              let json = JSON.stringify(response.mappingElements, null, 2);
              this.userform.setValue({
                mappingId: response.mappingId, 
                mappingName: response.mappingName,  
                mappingType: response.hasOwnProperty('mappingType') ? response.mappingType : '[none]', 
                mappingDescription: response.hasOwnProperty('mappingDescription') ? response.mappingDescription : null, 
                mappingElements: json,
                createDate: response.createDate,
                lastUpdateDate: response.lastUpdateDate
              });
              this.mappingElements = response.mappingElements;
              this.isListShown = false;
              this.isDetailsShown = true;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the brand offer
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the config mapping ' + event.mappingName + ' ?',
      header: 'Confirmation',
      key: 'config-mappings',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteConfigMapping(event.mappingId, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted config mappin ' + event.mappingName + ' from brand ' + this.currentBrand.brandCode);
            this.getBrandConfigMappings(this.currentBrand.brandCode, this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  onSubmit(value: string) {
    try {
      JSON.parse(this.userform.value.mappingElements);
    } catch (error) {
      if (error instanceof Error) {
        this.alertTheUser(error.message);
      }
      return;
    }
    let configMapping: IConfigMapping = {
      brandCode: this.currentBrand.brandCode,
      mappingId: this.userform.value.mappingId,
      mappingName: this.userform.value.mappingName,
      mappingDescription: this.userform.value.mappingDescription, 
      mappingType: this.userform.value.mappingType,
      mappingElements:  JSON.parse(this.userform.value.mappingElements),
      createDate: this.userform.value.createDate,
      lastUpdateDate: this.userform.value.lastUpdateDate
    };
    // Call the service to invoke a Web API call
    this.brandProfileService.submitConfigMapping(configMapping, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        if (configMapping.mappingId) {
          this.alertTheUser('Successfully modified config mapping ' + configMapping.mappingName + ' from brand ' + this.currentBrand.brandCode);
        } else {
          this.alertTheUser('Successfully added config mapping ' + configMapping.mappingName + ' to brand ' + this.currentBrand.brandCode);
        }
        this.getBrandConfigMappings(this.currentBrand.brandCode, this.currentBrand.baseUrl);
        this.isListShown = true;
        this.isDetailsShown = false;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onCreate(value: string) {
    let templateName = this.createform.value.templateName;
    // Call the service to invoke a Web API call
    this.brandProfileService.createConfigMapping(this.currentBrand.brandCode, templateName, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.alertTheUser('Successfully created config mapping ' + templateName + ' to brand ' + this.currentBrand.brandCode);
        this.getBrandConfigMappings(this.currentBrand.brandCode, this.currentBrand.baseUrl);
        this.isListShown = true;
        this.isCreateShown = false;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  cancelDetails() {
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  cancelCreate() {
    this.isListShown = true;
    this.isCreateShown = false;
  }

  onElementEdit(rowData: any) {

  } 
  
  onElementDelete(rowData: any) {

  }

  showAddElement() {

  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'config-mappings',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }  

}
