<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand and a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
                class="p-button-raised p-button-rounded"  [disabled]="isDetailsShown">
            </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 


<!--   -->


<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-7 side-list-content-data" *ngIf="isListShown">
        <p-card header="List of Brand Variables" subheader="" styleClass="ui-card-light" >

            <p-table #dt [columns]="cols" [value]="brandVariables" selectionMode="single" 
                sortMode="multiple" [paginator]="true" [rows]="15" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            <input *ngSwitchCase="'variableName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                            <input *ngSwitchCase="'datatype'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '75%'}">
                        </th>
                        <th></th>
                    </tr>  
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pEditableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                        <td style="text-align:center">
                            <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Modify brand variable" 
                                class="ui-button-info" (click)="onRowEdit(rowData)">
                            </button>
                            <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Delete brand variable" 
                                class="ui-button-danger" (click)="onRowDelete(rowData)" *ngIf="isSubmitShown">
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left" *ngIf="isSubmitShown">
                        <button pButton type="button" label="Add" icon="pi fa-plus" iconPos="left" (click)="showDialogToAdd()"  
                        class="p-button-info p-button-rounded" *ngIf="isSubmitShown"></button>  
                    </div>
                </ng-template>    
          </p-table> 
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">list_all</mat-icon>
            </p-footer>
        </p-card>
    </div>

    <!--- Second card with the details -->

    <div @flyInOut class="col-5 side-list-content-data" *ngIf="isDetailsShown">

        <div class="col-9">

            <p-card header="Variable Details" subheader="" styleClass="ui-card-light" >
        
                <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">
        
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="variableId" class="col-fixed label" style="width: 15em">Variable Id:</label>
                            <div class="col">
                                <input pInputText id="variableId" type="text" formControlName="variableId" readonly="true" 
                                    pTooltip="Unique variable identifier" tooltipPosition="top" autofocus style="width: 10em"/> 
                            </div>
                    </div>                             

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="variableName" class="col-fixed label" style="width: 15em">Variable Name:</label>
                            <div class="col">
                                <input pInputText id="variableName" type="text" formControlName="variableName" style="width: 15em" 
                                    [size]="35" placeholder="Required" pTooltip="Discriptive variable name" tooltipPosition="top"/> 
                                <p-message severity="error" text="Variable name is required" *ngIf="!userform.controls['variableName'].valid&&userform.controls['variableName'].dirty">
                                </p-message>                                           
                            </div>
                    </div>                             

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="datatype" class="col-fixed label" style="width: 15em">DataType:</label>
                            <div class="col">
                                <p-dropdown id ="datatype" [options]="dataTypes" formControlName="datatype" 
                                    [filter]="false" [editable]="false" pTooltip="Select the datatype" tooltipPosition="top"> 
                                    <p-message severity="error" text="Datattype is required" *ngIf="!userform.controls['datatype'].valid&&userform.controls['datatype'].dirty"></p-message>                 
                                </p-dropdown>
                            </div>
                    </div>                             
                    
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="evalScope" class="col-fixed label" style="width: 15em">Scope:</label>
                            <div class="col">
                                <p-dropdown id ="evalScope" [options]="evalScopes" formControlName="evalScope" 
                                    [filter]="false" [editable]="false" pTooltip="Select the variable scope" tooltipPosition="top"> 
                                    <p-message severity="error" text="Variable scope is required" *ngIf="!userform.controls['evalScope'].valid&&userform.controls['evalScope'].dirty"></p-message>                 
                                </p-dropdown>
                            </div>
                    </div>                             
        
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="defaultValue" class="col-fixed label" style="width: 15em">Default Value:</label>
                            <div class="col">
                                <input pInputText id="defaultValue" type="text" formControlName="defaultValue" style="width: 15em"  /> 
                            </div>
                    </div>                             
        
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="defaultValue" class="col-fixed label" style="width: 15em">Metadata:</label>
                            <div class="col">
                                <textarea pInputTextarea id="variableMetadata" style="width: 100%;" [rows]="2" [cols]="36" 
                                    pTooltip="Variable metadata for adhoc filter specification" tooltipPosition="top" 
                                    formControlName="variableMetadata" > 
                                </textarea>
                            </div>
                    </div>                             
        
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="evalExpression" class="col-fixed label" style="width: 15em">Change Indicator Variable:</label>
                            <div class="col">
                                <p-dropdown id ="evalExpression" [options]="evalExpressions" formControlName="evalExpression" 
                                    [filter]="false" [editable]="false" pTooltip="Select the change indicator variable, if needed.  When the value of the variable changes - the change indicator variable gets set to TRUE." tooltipPosition="top"> 
                                </p-dropdown>
                            </div>
                    </div>                             
        
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                            <div class="col">
                                <input pInputText type="createDate" id="createDate" type="text" formControlName="createDate" readonly="true">
                            </div>
                    </div>                             
        
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                            <div class="col">
                                <input pInputText type="lastUpdateDate" id="lastUpdateDate" type="text" formControlName="lastUpdateDate" readonly="true">
                            </div>
                    </div>                             
        
        
                    <div class="col-12">
                        <div  class="col-2" class="row container-fluid"  id="divshow">

                            <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></p-button>
                            <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>
<!-- 
                            <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" *ngIf="isSubmitShown"></button>
                            <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button>
                                -->
                        </div> 
                        <div class="col-10" *ngIf="!isSubmitShown">
                            <mat-label>{{resultMsg}}</mat-label>
                        </div>
                    </div>
        
                </form>
        
                <p-footer>
                    <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
                </p-footer>

            </p-card>
    
        </div>

    
    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-variables"></p-confirmDialog>

