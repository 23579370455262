import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IMessageBroadcasting } from '../../interfaces/message-broadcasting';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { AccountAdminService } from '../../services/admin/account-admin.service';
import { AccountInfoService } from "../../services/stats/account-info.service";
import { JobService } from '../../services/admin/job-service.service';
import { SharedService } from "../../services/shared.service";
import { UserContextService } from '../../services/safeguard/user-context.service';

@Component({
  selector: 'app-account-tasks',
  templateUrl: './account-tasks.component.html',
  styleUrls: ['./account-tasks.component.css']
})
export class AccountTasksComponent implements OnInit {

  titleMessage = "Account Tasks";
  isSubmitShown = false;
  isCurrentBrandShown = true;

  extAccountId: string = '';
  passTemplates: any[] = [];
  messageTemplates: any[] = [];
  msgTemplates: any[] = [];
  varValuesCols!: any[];
  varValues: any[] = [];
  origVarValues: any[] = [];

  standardAmounts!: any[];
  standardAmountsCols!: any[];
  amountDueChange: number = 54.11;
  
  resultMsg = "";
  currentBrand: IBrand;

  userform!: FormGroup;

  openBrandSelectionDialog: boolean = false;
  isTemplateShown!: boolean;
  isAccountFound!: boolean;

  taskCols!: any[];
  tasks!: any[]; // IPassTemplate[];
  selectedTask: any;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private accountInfoService: AccountInfoService, 
    private accountAdminService: AccountAdminService,
    private jobService: JobService,
    private sharedService: SharedService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService,
  ) { 
    this.currentBrand = this.sharedService.getDummyBrand();    
  }

  ngOnInit(): void {
    if (this.userContextService.userHasAnyPrivilege
        (['PRIV_WAL_MESSAGE_TO_ACCOUNT','PRIV_WAL_ACCOUNT_UPDATE','PRIV_WAL_ACCOUNT_MESSAGE','PRIV_WAL_DEMO_SCENARIOS'])) {
      this.isSubmitShown = true;
    }
    this.isAccountFound = false;
    this.userform = this.fb.group({
      'passId': new FormControl('', [Validators.required, Validators.min(1)]),
      'messageTemplateCode': new FormControl('', [Validators.required]),
      'templateHeader': new FormControl('', [Validators.required]),
      'templateText': new FormControl('', [Validators.required]),
    });
    this.userform.setValue({
      passId: 0, messageTemplateCode: '', templateHeader: '', templateText: ''
    });

    // Define the columns for varValues grid
    this.varValuesCols = [
      { field: 'variableId', header: 'Var Id', width:'10%', display: 'table-cell', editable: false },
      { field: 'variableName', header: 'Var Name', width:'28%', display: 'table-cell', editable: false },
      { field: 'dataType', header: 'Data Type', width:'12%', display: 'table-cell', editable: false },
      { field: 'variableValue', header: 'Var Value', width:'40%', display: 'table-cell', editable: true },
    ]; 

    // Define the columns for standardAmounts grid
    this.standardAmountsCols = [
      { field: 'industry', header: 'Industry', width:'60%', display: 'table-cell', editable: false },
      { field: 'standardAmount', header: 'Standard Amount', width:'40%', display: 'table-cell', editable: true },
    ]; 

    this.taskCols = [
      { field: 'eventName', header: 'Task Name', display: 'table-cell', width:'40%'  },
      { field: 'eventDescription', header: 'Task Description', display: 'table-cell', width:'60%'  },
    ];

    // Prepopulate the standardAmounts grid
    this.standardAmounts = [
      { industry: 'Auto', standardAmount: 358.22},
      { industry: 'CreditCard', standardAmount: 787.25},
      { industry: 'Gift', standardAmount: 215.39},
      { industry: 'Insurance', standardAmount: 358.22},
      { industry: 'Mortgage', standardAmount: 2231.56},
      { industry: 'Other', standardAmount: 215.39},
      { industry: 'Prepaid', standardAmount: 215.39},
      { industry: 'Utility', standardAmount: 215.39}
    ]; 

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.openBrandSelectionDialog = false;
      this.getPassTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getScheduledEvents(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }  
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.sharedService.setCurrentBrand(item);
      this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
      // this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getScheduledEvents(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
    }
  }

  // Select all existing brand pass templates
  getPassTemplates(brandCode: string, baseUrl: string) {
    this.passTemplates.length = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getPassTemplates(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          response.forEach(element => {
            this.passTemplates.push({label: element.passName, value: element.passId});
          });
          if (response.length == 1) {
            let passTemplateId = response[0].passId || 0;
            this.userform.patchValue({passId: passTemplateId});                
            this.getMessageTemplates(brandCode, passTemplateId, baseUrl);
          } else {
            this.isTemplateShown = false;
            this.messageTemplates.length = 0;
            this.msgTemplates.length = 0;
            this.passTemplates.push({label: '[none]', value: 0});
            this.messageTemplates.push({label: '[none]', value: ''});
            this.userform.patchValue({
              passId: 0, messageTemplateCode: '', templateHeader: '', templateText: ''
            });
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);        
        },
      complete: () => {
      }
    });
  }

  // Retrieve message templates based on the chosen pass template
  getMessageTemplates(brandCode: string, passTemplateId: number, baseUrl: string) {
    this.isTemplateShown = false;
    this.messageTemplates.length = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getMessageTemplates(brandCode, passTemplateId, baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.msgTemplates = response;
          response.forEach(element => {
              this.messageTemplates.push({label: element.messageTemplateName, value: element.messageTemplateCode});
            });
          if (response.length == 1) {
            this.userform.patchValue({
              messageTemplateCode: response[0].messageTemplateCode, 
              templateHeader: response[0].messageTemplateHeader, 
              templateText: response[0].messageTemplateText
            });
          } else {
            this.messageTemplates.push({label: '[none]', value: ''});
            this.userform.patchValue({messageTemplateCode: ''});
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Select all existing Scheduled Events for the brand
  getScheduledEvents(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    // this.brandProfileService.getScheduledEvents(brandCode, brandUrl)
    //     .subscribe( 
    //       (response) => {
    //           this.tasks = response;
    //         },
    //       (error)  => {
    //         this.alertTheUser(error.message);
    //       }
    //     )
    this.tasks = [
      { eventName: "DaqDataBatchRefresher", eventDescription: "Refresher all client accounts" },
      { eventName: "GenericDaqDatafeedImporter", eventDescription: "Import a data file for the client" },
      { eventName: "GenericOutgoingDatafeedGenerator", eventDescription: "Generate outbound files for the client" },
      { eventName: "AndroidPayScanner", eventDescription: "Wun the Android Pay Scanner" }
    ];

  }


  onChangePassTemplate (event: any) {
    let passTemplateId = event.value;
    this.getMessageTemplates(this.currentBrand.brandCode || '', passTemplateId, this.currentBrand.baseUrl || '');
  } 

  onChangeMessageTemplate(event: any) {
    let messageTemplateCode = event.value;
    let messageTemplate = this.msgTemplates.filter(item => item.messageTemplateCode == messageTemplateCode);
    if (messageTemplate.length == 1) {
      this.userform.patchValue({
        templateHeader: messageTemplate[0].messageTemplateHeader, 
        templateText: messageTemplate[0].messageTemplateText
      });
      this.isTemplateShown = true;
    }
  } 

  onFindAccount(event: any) {
    this.isAccountFound = false;
    this.origVarValues = [];
    this.varValues = [];
    this.accountInfoService.getAccountInfo(this.currentBrand.baseUrl || '', this.currentBrand.brandCode || '', this.extAccountId)
    .subscribe({
      next: (response) => {
        if (response == null || response.customerPassInfoList == null || response.customerPassInfoList.length < 1) {
          this.varValues = [];
          this.origVarValues = [];
          this.alertTheUser('No account records found for the specified selection criteria.');
        } else {
          let customerpasses = response.customerPassInfoList;
          let customerpass = customerpasses[0];
          let vpipFullList = customerpass.vpivInfoList || [];
          if (vpipFullList) {
            // Get a list of distinct variable names
            var vpipDistinctNames = new Set(vpipFullList.map(x => x.variableName));
            // Loop by a specific variable to find out its latest version to populate two arrays: one to hold existing values, the other - new values
            vpipDistinctNames.forEach(variableName => {
              let oneVpip = vpipFullList.filter(item => item.variableName == variableName).sort((a, b) => (a.versionNum < b.versionNum) ? 1 : -1); 
              this.origVarValues.push({vpivId: oneVpip[0].vpivId, variableName: oneVpip[0].variableName, variableValue: oneVpip[0].variableValue});
              this.varValues.push(oneVpip[0]);
            })         
          }
          this.isAccountFound = true;
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
        },
      complete: () => {
      }
    });
  }

  onSubmitNotification(event: any) {
    let messageBroadcasting: IMessageBroadcasting = {
      passId: this.userform.value.passTemplateId,
      messageTemplateCode: this.userform.value.messageTemplateCode,
      templateHeader: this.userform.value.templateHeader,
      templateText: this.userform.value.templateText,
      accountIds: [this.extAccountId] 
    };
    // Call the service to invoke a Web API call
    this.accountAdminService.submitMessageBroadcasting(this.currentBrand.brandCode || '', messageBroadcasting, 
        this.currentBrand.baseUrl || '')
    .subscribe({
      next: (response) => {
        this.alertTheUser('Successfully submitted the message for brand ' + this.currentBrand.brandCode + ' and account ' + this.extAccountId);
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onSubmitDataUpdate(event: any) {
    let map = new Map<string, string>();  
    this.varValues.forEach(item => {
      let origVar = this.origVarValues.filter(oVar => oVar.vpivId == item.vpivId);
      if (origVar.length > 0) {
        if (item.variableValue != origVar[0].variableValue) {
          map.set(item.variableName, item.variableValue);
        }
      }
    });
    if (map.size > 0) {
    // Call the service to invoke a Web API call
    this.accountAdminService.updateAccount(this.currentBrand.brandCode || '', this.extAccountId, map, this.currentBrand.baseUrl || '')
    .subscribe({
      next: (response) => {
        this.onFindAccount(null);
        this.alertTheUser('Successfully submitted the message for brand ' + this.currentBrand.brandCode + ' and account ' + this.extAccountId);
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
    } else {
      this.alertTheUser('No variable value has been modified by the user.  Please modify at least one variable, then resubmit the request.');
    }
  }

  onPaid (event: any) {
    this.runScenario('paid');
  }

  onPastDue (event: any) {
    this.runScenario('pastdue');
  }

  onCycle (event: any) {
    this.runScenario('cycle');
  }

  onReset (event: any) {
    this.runScenario('reset');
  }

  runScenario(scenarioName: string) {
    let standardAmountsMap = new Map<string, string>();  
    this.standardAmounts.forEach(item => {
      standardAmountsMap.set(item.industry, item.standardAmount);
    });
    // Call the service to invoke a Web API call
    this.accountAdminService.runScenario(this.currentBrand.brandCode || '', this.extAccountId, scenarioName,
      this.amountDueChange, standardAmountsMap, this.currentBrand.baseUrl || '')
      .subscribe({
        next: (response) => {
          this.alertTheUser('Successfully run the ' + scenarioName + ' sccenario for brand '
          + this.currentBrand.brandCode + ' and account ' + this.extAccountId);
       },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  }

  // When the user chooses a Task
  onRowTaskSelect(event: any) {
    this.selectedTask = event.data.eventName;
  } 

  triggerEvent () {
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to execute the event right now on demand?',
      header: 'Confirmation',
      key: 'account-tasks',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.jobService.triggerEvent(this.currentBrand.brandCode || '', this.selectedTask, this.currentBrand.baseUrl || '')
        .subscribe({
          next: (response: any) => {
            this.alertTheUser('Successfully triggered event ' + this.selectedTask + ' from brand ' + this.currentBrand.brandCode);
          },
          error: (error: any) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      }
    })
  }


  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'account-tasks',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  makeTitleMessage (currentBrand: any) {
    var title = '';
    if (currentBrand.brandCode) {
      title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) >> ';
    }
    title += 'Account Tasks';
    return title;
  }  
}
