import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {

  constructor() { }
  downloadFile(csvData: any, filename='data') {
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  // Convert a given json object into an array
  jsonToCSV(objArray: any, columnHeaders: any, delimiter = ',') {
    let str = '';
    let row = '';
    if (!delimiter) {
      delimiter = ',';
    }
    // Convert json into array if it has not been provided as an array parameter
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    // Create the header row
    for (let indCol in columnHeaders) {
        row += columnHeaders[indCol] + delimiter;
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    // Next, loop within the array creating rows wwith data
    for (let indRow = 0;  indRow < array.length; indRow++) {
        let line = '';
        // Loop by the columns
        for (let indCol in columnHeaders) {
          let head = columnHeaders[indCol];
          line += ',' + (array[indRow][head] ? array[indRow][head] : '');
        }
        str += line.substr(1) + '\r\n';
    }
    return str;
 } 


}
