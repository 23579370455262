import { Component, Input, OnInit } from '@angular/core';
import { UserContextService } from '../../services/safeguard/user-context.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  @Input('parentForm') parentForm?: string;
  hasBrandConfigRole: boolean = false;

  constructor(
    private userContextService: UserContextService    
  ) { }

  ngOnInit(): void {
    // var currentUser = this.tokenStorageService.getCurrentUser();
    let userRoles = this.userContextService.getUserRoles();
    if (userRoles.indexOf('rl_brand_config') >= 0) {
      this.hasBrandConfigRole = true;
    } 
  }

  // Find out whether the current user has ALL the privileges from the list
  public hasAllPrivileges (priveleges: string[]): boolean {
    return this.userContextService.userHasAllPrivileges(priveleges);
  }

  // Find out whether the current user has at least one privilege from the list
  public hasAnyPrivilege (priveleges: string[]): boolean {
    return this.userContextService.userHasAnyPrivilege(priveleges);
  }

  // Find out whether the current user has a required privilege
  public hasPrivilege (privelege: string): boolean {
    return this.userContextService.userHasPrivilege(privelege);
  }  

}
