import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { IJobMonitorResponse } from '../../interfaces/job-monitor-response';
import { ICommunicationHistoryResponse } from '../../interfaces/communication-history-response';
import { IJobSummary } from '../../interfaces/job-summary';
import { IJobRunner }  from '../../interfaces/job-runner';
import { IJobRegister }  from '../../interfaces/job-register';
import { IEnvironmentCount } from '../../interfaces/environment-count';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class JobMonitorService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected communicationAuth = AppConfigService.settings.appSetting.communicationAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected communicationAuth = environment.appSetting.communicationAuth;

  private jobsExecutionUrl = this.baseUrl + '/monitor/jobs'; 
  private jobExecutionUrl = this.baseUrl + '/monitor/jobs/{jobUuid}'; 
  private latestFilteredJobsUrl = this.baseUrl + '/monitor/latest-filtered-jobs'; 
  private currentJobStatusUrl = this.baseUrl + '/monitor/current-job-status';
  private jobCodesUrl = this.baseUrl + '/monitor/distinct-job-codes'; 
  private envRegionsUrl = this.baseUrl + '/monitor/distinct-env-regions'; 
  // private jobStatusesUrl = this.baseUrl + '/monitor/distinctJobStatuses'; 
  private jobSummaryUrl = this.baseUrl + '/monitor/latest-summary'; 
  private environmentCountUrl = this.baseUrl + '/monitor/environment-count'; 
  private taskHierarchyUrl = this.baseUrl + '/monitor/task-hierarchy'; 
  private communicationHistoryUrl = this.baseUrl.replace("portal/api","communication/api") + '/admin/comm-history';

  private headers = new HttpHeaders()
     .set('Authorization', this.basicAuth)

    // private tokenHeaders = new HttpHeaders()
    // .set('Authorization', this.getAuthToken())

  private communicationHeaders = new HttpHeaders()
    .set('Authorization', this.communicationAuth);

  constructor(
    private http: HttpClient, 
    private sharedService: SharedService,
    private messageService: MessageService,
    private errorProcessorService: ErrorProcessorService)
     { }

  // getAuthToken() {
  //   debugger;
  //   let jsonString = localStorage.getItem('currentUser');
  //   let currentUser: any = JSON.parse(jsonString);
  //   let authToken = currentUser.authToken;
  //   return authToken;
  //   }

  getJobExecutions(selectedRegion: string, selectedBrandCode: string, selectedJobCode: string, selectedJobStatus: string,  
    selectedDateFrom: string, selectedDateTo: string, offset: number, includeMaintenenceJobs: any):
        Observable<IJobMonitorResponse> {
    let request = {
      envRegion: selectedRegion ?? null,
      jobCode: selectedJobCode ?? null,
      brandCodes: selectedBrandCode == null ? null : [selectedBrandCode], 
      jobStatus: selectedJobStatus ?? null, 
      includeMaintenenceJobs: includeMaintenenceJobs ?? false, 
      dateFrom:  this.sharedService.getFormattedDate(selectedDateFrom) ?? null, 
      txnDateTo:  selectedDateTo != null ? this.sharedService.getFormattedDate(this.sharedService.getNextDate(selectedDateTo)) : null, 
      limit: 50,
      offset: offset
    }
    return this.http.post<IJobMonitorResponse>(this.jobsExecutionUrl, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  getJobExecution(jobUuid: string): Observable<IJobRegister> {
    let url = this.jobExecutionUrl.replace("{jobUuid}", jobUuid);
    return this.http.get<IJobRegister>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }  

  getLatestJobs(interval: any):
      Observable<IJobRunner[]> {
      var params = new HttpParams();
    if (interval != null) {
        params = params.set('latestInterval', interval);
    }
    return this.http.get<IJobRunner[]>(this.latestFilteredJobsUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  getCurrentJobStatus(jobUuids: any):
      Observable<IJobRunner[]> {
      return this.http.post<IJobRunner[]>(this.currentJobStatusUrl, JSON.stringify(jobUuids), {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      );
  }

  getEnvRegions(): Observable<string[]> {
    return this.http.get<string[]>(this.envRegionsUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );

  }

  getJobCodes(): Observable<string[]> {
    return this.http.get<string[]>(this.jobCodesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );

  }

  // getJobStatuses(): Observable<string[]> {
  //   return this.http.get<string[]>(this.jobStatusesUrl, {headers: this.headers, responseType: 'json'});
  // }

  getJobSummary():
        Observable<[IJobSummary]> {
      var params = new HttpParams()
      .set('latestInterval', '240');
    return this.http.get<[IJobSummary]>(this.jobSummaryUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );

  }  

  getEnvironmentCount():
        Observable<[IEnvironmentCount]> {
      var params = new HttpParams()
      .set('latestInterval', '240');
    return this.http.get<[IEnvironmentCount]>(this.environmentCountUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );

  }  

  getTaskHierarchy():
    Observable<any[]> {
    return this.http.get<any[]>(this.taskHierarchyUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );

  }  
  
  private log(message: string) {
//    this.messageService.add(`UserService: ${message}`);
  }

  getCommunicationHistory(selectedBrandCode: string, selectedMessageCode: string, selectedChannelCode: string,
     selectedDateFrom: string, selectedDateTo: string, offset: number):
        Observable<ICommunicationHistoryResponse> {
      var params = new HttpParams()
      .set('limit', '50')
      .set('offset', offset);
    if (selectedBrandCode != null) {
        params = params.set('brandCode', selectedBrandCode);
    }
    if (selectedMessageCode != null) {
      params = params.set('messageCode', selectedMessageCode);
    }
    if (selectedChannelCode != null) {
      params = params.set('channelCode', selectedChannelCode);
    }
    if (selectedDateFrom != null) {
      params = params.set('dateFrom', this.sharedService.getFormattedDate(selectedDateFrom));
    }
    if (selectedDateTo != null) {
      var dateTo = this.sharedService.getNextDate(selectedDateTo);
      params = params.set('dateTo', this.sharedService.getFormattedDate(dateTo));
    }
    return this.http.get<ICommunicationHistoryResponse>(this.communicationHistoryUrl, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );

  }

}
