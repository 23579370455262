import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { Subscription } from 'rxjs';
// import { nextTick } from 'process';
 
@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  // @Input() isAuthenticated: boolean;
 
  // hasPortalRole: boolean;
  // hasBillerRole: boolean;
  // hasAdminRole: boolean;
  // hasMonitorRole: boolean;
  // hasBrandConfigRole: boolean;
  // hasBrandBootstrapRole: boolean;
  // hasAcctAdminRole: boolean;
  // hasDemoSupportRole: boolean;
  // hasCsrUserRole: boolean;
  // hasUserAdminRole: boolean;
  // hasReadOnlyRole: boolean;
  // hasRtpnRole: boolean;
  // hasStatsViewerRole: boolean;
  // hasBrandListingRole: boolean;

  isUserAuthenticated: boolean = false;

  clickEventSubscription: Subscription;
  browserRefreshEventSubscription: Subscription;

  constructor(
    private userContextService: UserContextService,
    private tokenStorageService: TokenStorageService
  ) { 
      this.clickEventSubscription = this.tokenStorageService.getLoginEvent().subscribe((userLoggedIn: boolean)=>{
        // this.menuItemsAuthorization(userLoggedIn);
        this.isUserAuthenticated = userLoggedIn;
      })
      this.browserRefreshEventSubscription = this.tokenStorageService.getBrowserRefreshEvent().subscribe((browserRefresh: boolean) => {
        if (browserRefresh && this.userContextService.isUserAuthenticated()) {
          this.isUserAuthenticated = true;
        }
      })
  }
 
  ngOnInit() {
  }
 
  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  // Find out whether the current user has ALL the privileges from the list
  public hasAllPrivileges (priveleges: string[]): boolean {
    return this.userContextService.userHasAllPrivileges(priveleges);
  }

  // Find out whether the current user has at least one privilege from the list
  public hasAnyPrivilege (priveleges: string[]): boolean {
    return this.userContextService.userHasAnyPrivilege(priveleges);
  }

  public hasPrivilege (privelegeName: string, roleName?: string): boolean {
    if (privelegeName) {
      return this.userContextService.userHasPrivilege(privelegeName);
    } 
    else if (roleName) {
      return this.userContextService.userHasRole(roleName);
    }
    return false;
  }

  // private menuItemsAuthorization(isUserLoggedIn) {
  //   this.hasPortalRole = false;
  //   this.hasBillerRole = false;
  //   this.hasAdminRole = false;
  //   this.hasMonitorRole = false;
  //   this.hasBrandConfigRole = false;
  //   this.hasBrandBootstrapRole = false;
  //   this.hasAcctAdminRole = false;
  //   this.hasDemoSupportRole = false;
  //   this.isUserAuthenticated = false;
  //   this.hasCsrUserRole = false;
  //   this.hasUserAdminRole = false;
  //   this.hasReadOnlyRole = false;
  //   this.hasRtpnRole = false;
  //   this.hasStatsViewerRole = false;
  //   this.hasBrandListingRole = false;

  //   if (!isUserLoggedIn) {
  //     return;
  //   }

  //   this.isUserAuthenticated = true;
  //   // Hide/show specific menu items based on the current user's role(s)
  //   var currentUser = this.tokenStorageService.getCurrentUser();

  //   if (currentUser) {
  //     if(currentUser.userName && (Array.isArray(currentUser.userRoleList) && currentUser.userRoleList.length)) {
  //       if (currentUser.userRoleList.indexOf('rl_aci_staff') >= 0 || this.userContextService.isAciStaff()
  //         || this.userContextService.hasReadOnlyAccess()) {
  //         this.hasPortalRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_biller_rep') >= 0) {
  //         this.hasBillerRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_admin') >= 0) {
  //         this.hasAdminRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_monitor') >= 0) {
  //         this.hasMonitorRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_brand_config') >= 0) {
  //         this.hasBrandConfigRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_brand_bootstrap') >= 0) {
  //         this.hasBrandBootstrapRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_acct_admin') >= 0) {
  //         this.hasAcctAdminRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_demo_support') >= 0) {
  //         this.hasDemoSupportRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_user_admin') >= 0) {
  //         this.hasUserAdminRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_rtpn') >= 0) {
  //         this.hasRtpnRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_csr_user') >= 0) {
  //         this.hasCsrUserRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_aci_read_only') >= 0 || this.userContextService.hasReadOnlyAccess()) {
  //         this.hasReadOnlyRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_stats_viewer') >= 0) {
  //         this.hasStatsViewerRole = true;
  //       }      
  //       if (currentUser.userRoleList.indexOf('rl_brand_listing') >= 0) {
  //         this.hasBrandListingRole = true;
  //       }      
  //     }
  //   }
  // }

}
