import { Component, OnInit } from '@angular/core';
// import { Output, EventEmitter } from '@angular/core';
import { Validators,FormControl,FormGroup,FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
// import { MessageService } from '../message.service';
import { IMessageProfile } from '../../interfaces/message-profile';
// import { ITemplateRepository } from '../../interfaces/template-repository';
// import { IChannel } from '../../interfaces/channel';
//import { AuthenticationService } from '../../services/authentication.service';
import { CommunicationManagerService } from '../../services/admin/communication-manager.service';
import { UserContextService } from '../../services/safeguard/user-context.service';

@Component({
  selector: 'app-message-profile',
  templateUrl: './message-profile.component.html',
  styleUrls: ['./message-profile.component.css']
})
export class MessageProfileComponent implements OnInit {

  userform!: FormGroup;

  // brandProfile: BrandProfile = new BrandProfile(); 
  isDisabled = false;
  isSubmitShown = false;
  resultMsg = "";
  categories: any[] = [];  
  clientCodes: any[] = [];
  templates: any[] = [];
  messageProfile!: IMessageProfile; 

  constructor(
    private fb: FormBuilder, 
    private communicationManagerService: CommunicationManagerService,
    private userContextService: UserContextService,
    // private messageService: MessageService,
  //  private brandProfileService: BrandProfileService,
  //  private authenticationService: AuthenticationService,
    private confirmationService: ConfirmationService    
  ) { }

  ngOnInit(): void {
    this.getTemplates();

    this.categories = [
      {label: "Walletron", value: "Walletron"}, 
      {label: "Walletron maintanence", value: "Walletron maintanence"}, 
      {label: "Walletron reporting", value: "Walletron reporting"}, 
      {label: "Direct Communication", value: "Direct Communication"}
    ];
    this.clientCodes = [
      {label: "Walletron Portal", value: "Mobills Portal"}, 
      {label: "Walletron Reports", value: "Mobills Reports"}, 
      {label: "Walletron", value: "Mobills"}
    ];

    this.userform = this.fb.group({
      'messageCode': new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(60), Validators.pattern("^MP_[a-zA-Z_]*")]),
      'messageDescription': new FormControl('', [Validators.required, Validators.maxLength(400), Validators.minLength(4)]),
      'category': new FormControl('Walletron', [Validators.required]),
      'clientCode': new FormControl('Mobills', [Validators.required]),
      'template': new FormControl(),
      'emailChannel': new FormControl(true),
      'smsChannel': new FormControl(true),
      'defaultVarValues': new FormControl()
    });
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_CM_SUBSCRIPTION_MODIFY')) {
      this.isSubmitShown = true;
    }
     
  }

  getTemplates(): void {
    this.communicationManagerService.getTemplateRepositories()
        .subscribe (list => {
          var template;
          for (template of list) {
            this.templates.push({label: template.templateDescription, value: template.templateRepositoryId});
          }
        });
  }  

  onSubmit(value: string) {
    // Message description
    let messageDescription = this.userform.value.messageDescription;
    // Message code
    let messageCode = this.userform.value.messageCode.toUpperCase();
    if (messageCode.indexOf('MP_') < 0) {
      messageCode = 'MP_' + messageCode;
    }

    let messageProfile = {
      category: this.userform.value.category,
      channels: [
        {
          channelCode: messageCode.replace('MP_','C_') + '_EMAIL',
          channelDescription: 'An email channel for ' + messageDescription,
          deliveryMethod: 'EMAIL',
          enabled: this.userform.value.emailChannel
        },
        {
          channelCode: messageCode.replace('MP_','C_') + '_SMS',
          channelDescription: 'A SMS channel for ' + messageDescription,
          deliveryMethod: 'SMS',
          enabled: this.userform.value.smsChannel
        }
      ],
      clientCode: this.userform.value.clientCode,
      defaultVarValues: this.userform.value.defaultVarValues ? JSON.parse(this.userform.value.defaultVarValues) : null,
      enabled: true,
      messageCode: messageCode,
      messageDescription: messageDescription
    };

    this.communicationManagerService.addMessageProfile(messageProfile)
    .subscribe({
      next: (response) => {
        this.processResult('Successfully added new message profile ' + messageCode);
      },
      error: (error) => {
        this.processResult(error);
      },
      complete: () => {
      }
    });
  }

  processResult(message: string) {
    this.confirmationService.confirm({
      message: message,
      key: 'message-profile',      
      header: 'Notification',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
  }

}
