import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldOnlyImage extends OfferFieldBase {

    imageOptions: any[] = [];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        brandImages: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            if (brandImages) {
                this.imageOptions.length = 0;
                this.imageOptions.push({label: '[none]', value: null});
                brandImages.forEach(element => {
                  this.imageOptions.push({label: element.imageName, value: element.imageUID});
                });
            }
            // console.log('OfferFieldOnlyImage - brandImages', brandImages);
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Value, Selected Image, Show/hide Field Expression(N)

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            styleWidth: '10em',
            required: false,
            order: 5
        }));

        // console.log('OfferFieldOnlyImage - data', this.data);


        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'imageUID',
            label: 'Selected Image',
            value: this.data.imageUID,
            styleWidth: '20em',
            required: false,
            options: this.imageOptions,
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 7
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);

    }
}
