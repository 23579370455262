import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { IReturnedMessage } from '../../interfaces/returned-message';
import { IBrandPassEnrollmentStats } from '../../interfaces/brand-pass-enrollment-stats';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class SummaryReportsService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;

  private adhocUrl = '/adhoc';
  
  private headers = new HttpHeaders()
  .set('Authorization', this.basicAuth);

  private baseHeaders = new HttpHeaders()
  .set('Authorization', this.baseAuth);

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private errorProcessorService: ErrorProcessorService)
     { }

    public runSummaryReport(reportType: string, baseUrl: string, brandCode: string, 
      dateStart: string, dateEnd: string, emailAddress: string, brandTimeZone: string): Observable<IReturnedMessage>{
    var url = baseUrl.replace('Walletron','reports') + "/admin/summaryReport";
      var reportRequest =
    {
      "brandCodes": brandCode,
      "messageProfileCode": "MP_SUMMARY_REPORT_DAILY",
      "summaryReportType": reportType,
      "timeZone": brandTimeZone,
      "emailAddresses": [emailAddress],
      "startDateTime": null,
      "endDateTime": null
    };
    if ( reportType == "REPORT_CUSTOM") {
      reportRequest.startDateTime = this.sharedService.getFormattedDate(dateStart);
      reportRequest.endDateTime = this.sharedService.getFormattedDate(dateEnd);
    }
      return this.http.post<IReturnedMessage>(url, reportRequest, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  public getEnrollmentStats(baseUrl: string): Observable<IBrandPassEnrollmentStats[]>{
    var url = baseUrl.replace('Walletron','reports') + "/admin/stats/enrollments";
    return this.http.get<IBrandPassEnrollmentStats[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // The report's data used for the Enrollment Report
  public getPortalEnrollmentStats(): Observable<IBrandPassEnrollmentStats[]>{
    var url = this.baseUrl + "/account-stats/enrollments";
    return this.http.get<IBrandPassEnrollmentStats[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }    

  // Get the Enrollment Report for one brand
  public getEnrollmentStatsForBrand(brandCode: string, baseUrl: string): Observable<IBrandPassEnrollmentStats[]>{
    var url = baseUrl.replace('Walletron','reports') + "/admin/stats/account-enrollments/" + brandCode;
    return this.http.get<IBrandPassEnrollmentStats[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }    

  // Get a list of enrolled accounts for a brand
  public getEnrolledAccounts(brandCode: string, recepient: string, baseUrl: string): Observable<string> {
    let request = {
      "adHocReportType": "ENROLLED_ACCOUNTS_LIST",
      "brandCode": brandCode,
      "recepientEmailAddresses": [recepient],
      "onDemandInd": true
    }
      var url = baseUrl.replace('Walletron','reports') + this.adhocUrl;
      return this.http.post<string>(url, request, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  getAccountDataExtract(brandCode: string, requestedVars: any, 
    emailAddress: string, baseUrl: string): Observable<string> {
      let request = {
        "adHocReportType": "ENROLLED_ACCOUNTS_WITH_DATA",
        "brandCode": brandCode,
        "requestedVars": requestedVars,
        "recepientEmailAddresses": [emailAddress],
        "onDemandInd": true
      }
      var url = baseUrl.replace('Walletron','reports') + this.adhocUrl;
      return this.http.post<string>(url, request, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

}
