import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { IPortalRole } from '../../interfaces/portal-role';
import { IPortalPrivilege } from '../../interfaces/portal-privilege';
import { IPortalUser } from '../../interfaces/portal-user';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";


@Injectable({
  providedIn: 'root'
})
export class PortalRoleService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected communicationAuth = AppConfigService.settings.appSetting.communicationAuth;
  // protected authServerUrl = AppConfigService.settings.apiServer.authServerUrl;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected communicationAuth = environment.appSetting.communicationAuth;
  protected authServerUrl = environment.apiServer.authServerUrl;

  private privilegesUrl = this.authServerUrl + '/auth/privileges?enabled=true';
  private rolesUrl = this.authServerUrl + '/auth/roles';
  private roleUrl = this.authServerUrl + '/auth/roles/{roleId}';
  private rolePrivilegesUrl = this.authServerUrl + '/auth/roles/{roleId}/privileges';
  private roleUsersUrl = this.authServerUrl + '/auth/users?roleNames={roleName}';

  private currentUserName!: string;

  private headers = new HttpHeaders()
  .append('Authorization', this.basicAuth);

private tokenHeaders =  new HttpHeaders();

  constructor(
    private http: HttpClient,   
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService 
  ) {
    var currentUserValue = this.tokenStorageService.getCurrentUser();
    if (currentUserValue != null) {
      this.currentUserName = currentUserValue.userName;
      this.headers = this.headers.append('portal-user', this.currentUserName);
    }

   }

  getPrivileges(): Observable<IPortalPrivilege[]> {
    return this.http.get<IPortalPrivilege[]>(this.privilegesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getRoles(): Observable<IPortalRole[]> {
    return this.http.get<IPortalRole[]>(this.rolesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }
  
  getPortalRole(roleId: number): Observable<IPortalRole> {
    var url = this.roleUrl.replace('{roleId}', roleId.toString());
    return this.http.get<IPortalRole>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getRolePrivileges(roleId: number): Observable<string[]> {
    var url = this.rolePrivilegesUrl.replace('{roleId}', roleId.toString());
    return this.http.get<string[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getRoleUsers(roleName: string): Observable<IPortalUser[]> {
    var url = this.roleUsersUrl.replace('{roleName}', roleName);
    return this.http.get<IPortalUser[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deleteRole(roleId: number) {
    return this.http.delete(this.rolesUrl + '/' + roleId, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addRole(role: IPortalRole, privileges: string[]): Observable<any> {
    let addedRole: IPortalRole = {    
      roleName: role.roleName,
      roleDescription: role.roleDescription,
      enabled: role.enabled,
      privileges: privileges
    }
    // Create a new role
    return this.http.post<IPortalRole>(this.rolesUrl, addedRole, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  public modifyRole(role: IPortalRole, privileges: string[]): Observable<any> {
    let roleId = role.roleId;
    let modifiedRole: IPortalRole = {    
      roleId: roleId,
      roleName: role.roleName,
      roleDescription: role.roleDescription,
      enabled: role.enabled,
      privileges: privileges
    }
    let url = this.roleUrl.replace('{roleId}', roleId? roleId.toString() : '');
    return this.http.put<IPortalRole>(url, modifiedRole, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

}
