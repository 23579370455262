<h2>Encryption Utility</h2>

<div class="grid">

<div class="col-4">
    <p-card header="" subheader="" styleClass="ui-card-light">
       
        <div>
            <div class="field grid">
                <div class="col-1"></div>
                    <label for="selectedEnvironment" class="col-fixed label" style="width: 15em">Environment:</label>
                <div class="col-6">
                    <p-dropdown id ="selectedEnvironment" [options]="environments" [(ngModel)]="selectedEnvironment" [filter]="false" 
                        [editable]="false" [showClear]="false" ></p-dropdown>
                    </div>
            </div>
            
            <div class="field grid">
                <div class="col-1"></div>
                    <label for="textToEncrypt" class="col-fixed label" style="width: 15em">Text to Encrypt:</label>
                <div class="col-6">
                    <input pInputText id="textToEncrypt" [(ngModel)]="textToEncrypt" />
                </div>
            </div>
            
            <div class="field grid">
                <div class="col-1"></div>
                    <label for="encryptedText" class="col-fixed label" style="width: 15em">Encrypted Text:</label>
                <div class="col-6">
                    <textarea [rows]="2" [cols]="60" pInputTextarea [autoResize]="autoResize" id="encryptedText" 
                        [(ngModel)]="encryptedText" style="width: 100%;"></textarea>
                </div>

            </div>
            
        </div>
<!-- 
            <button type="button" pButton (click)="onEncryptTextClick()" icon="pi pi-check" label="Encrypt" class="ui-button-success"></button>
 -->
            <p-button type="button" label="Encrypt" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onEncryptTextClick()" ></p-button>

        <p-footer>
            <mat-icon class="nav-caption wal-center">settings_applications</mat-icon>
        </p-footer>

    </p-card>
</div>

</div>