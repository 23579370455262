import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEnvRegion } from '../../interfaces/env-region';
import { IBrand } from '../../interfaces/brand';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class EnvRegionService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;

  private environmentUrl = this.baseUrl + '/common/environments'; 
  private brandUrl = this.baseUrl + '/common/brands'; 
  private currentUser!: string ;
  
  private headers = new HttpHeaders()
  .set('Authorization', this.basicAuth);

  private baseHeaders = new HttpHeaders()
  .set('Authorization', this.baseAuth);


  constructor(
    private http: HttpClient, 
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService
    ) {
    this.currentUser = this.tokenStorageService.getCurrentUser() ? this.tokenStorageService.getCurrentUser().userName : '';
  }

  getEnvRegions(): Observable<IEnvRegion[]> {
      return this.http.get<IEnvRegion[]>(this.environmentUrl, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
       );
  }

  getBrands(selectedRegionName: string): Observable<IBrand[]> {
    return this.http.get<IBrand[]>(this.brandUrl + "/" + selectedRegionName, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );
  }

  addBrand(brand: any) {
    let newBrand = 
    {
      brandUuid: null, 
      brandCode: brand.brandCode, 
      brandName: brand.brandName, 
      environments: [brand.envRegion],
      modifiedUser: this.currentUser
    };
    return this.http.post(this.brandUrl, newBrand, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );;
  }

  addS3Bucket(brandCode: string) {
      var s3bucketUrl = this.brandUrl + "/" + brandCode + "/s3bucket";
      return this.http.post(s3bucketUrl, null, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
       );;
  }

   modifyBrand(brand: any) {
    let modifiedBrand = 
    {
      brandUuid: brand.brandUuid, 
      brandCode: brand.brandCode, 
      brandName: brand.brandName, 
      environments:  [brand.envRegion],
      modifiedUser: this.currentUser
    };
    return this.http.put(this.brandUrl + "/" + brand.brandCode, modifiedBrand, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );;
  }

  deleteBrand(brandCode: string) {
    return this.http.delete(this.brandUrl + "/" + brandCode, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
     );;
  }

}
