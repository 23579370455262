import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { BrandService } from '../../services/config/brand.service';
import { SummaryReportsService} from '../../services/reports/summary-reports.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { PortalUserService } from "../../services/admin/portal-user.service";
import { BrandMigrationService } from "../../services/construct/brand-migration.service";
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { SharedService } from "../../services/shared.service";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { IBrand } from '../../interfaces/brand';

@Component({
  selector: 'app-brand-listing',
  templateUrl: './brand-listing.component.html',
  styleUrls: ['./brand-listing.component.css']
})
export class BrandListingComponent implements OnInit {

    displayDialog: boolean = false;
    brands: any[] = [];
    envRegions = [];
    cols!: any[];
    emailAddress!: string;
    selectedBrand: IBrand = <IBrand> {};

    availableVariables: any[] = [];
    selectedVariables: any[] = [];

    isSpinnerHidden: boolean = true;
    isRemoveBrandShown: boolean = false;
    isAccountDataShown: boolean = false;

    autoResize: boolean = true;

    constructor(
      private brandService: BrandService,
      private summaryReportsService: SummaryReportsService,
      private tokenStorageService: TokenStorageService,
      private portalUserService: PortalUserService,
      private confirmationService: ConfirmationService,
      private brandMigrationService: BrandMigrationService,
      private userContextService: UserContextService,      
      private brandProfileService: BrandProfileService,
      private sharedService: SharedService
      ) { }
  
    ngOnInit() {
      // Hide the delete brand button for users with read-only access type
      // if (this.userContextService.isBrandConfigAdmin()) {
      //   this.isRemoveBrandShown = true;        
      // }
      if (this.userContextService.userHasAllPrivileges(['PRIV_VP_BRAND_DELETE','PRIV_WAL_BRAND_REMOVAL'])) {
        this.isRemoveBrandShown = true;
      }
      if (this.userContextService.userHasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_VARIABLE_VIEW','PRIV_WAL_BRAND_CONFIG_VARIABLE_MODIFY'])) {
        this.isAccountDataShown = true;
      }
      this.cols = [
        { field: 'brandUuid', header: 'Brand Uuid', display: 'table-cell', width:'25%'  },
        { field: 'brandCode', header: 'Brand Code', display: 'table-cell', width:'10%'  },
        { field: 'brandName', header: 'Brand Name', display: 'table-cell', width:'20%'  },
        { field: 'envCode', header: 'Env. Region', display: 'table-cell', width:'10%'  },
        { field: 'baseUrl', header: 'Base URL', display: 'table-cell', width:'25%'  },
        // { field: 'isActive', header: 'Active?', display: 'table-cell', width:'10%'  },
      ]; 
      this.getBrands();

      // Prepopulate the email address input box
      var currentUserValue = this.tokenStorageService.getCurrentUser();
      if (currentUserValue != null) {
        var userName = currentUserValue.userName;
        this.portalUserService.getUserEmailAddress(userName)
        .subscribe({
          next: (response) => {
            this.emailAddress = response; 
          },
          error: (error) => {
            },
          complete: () => {
          }
        });
      }
    }
  
  getBrands(): void {
    this.isSpinnerHidden = false;
    this.brands = [];
    this.brandService.getBrands() // getBrandsWithRegion()
    .subscribe({
      next: (response) => {
        this.brands = this.sharedService.getCurrentUserGrantedBrands(response); 
        this.isSpinnerHidden = true;
      },
      error: (error) => {
        },
      complete: () => {
      }
    });
  }

  getStatus(isActive: boolean) {
    if (isActive)
      return 'success';
    else
      return 'danger';
  }

  onRowBrandSelect(event: any) {
    this.selectedBrand = <IBrand> {
      brandCode: event.data.brandCode,
      brandName: event.data.brandName,
      envCode: event.data.envCode,
      brandUuid: event.data.brandUuid,
      baseUrl: event.data.baseUrl
    }
  }  

  onEnrolledAccountsClick(event: any) {
    if (!this.selectedBrand.brandCode) {
      this.alertTheUser("Please select a brand to generate a list of enrolled accounts.");
      return;
    }
    this.summaryReportsService.getEnrolledAccounts(this.selectedBrand.brandCode, this.emailAddress, this.selectedBrand.baseUrl || '')
    .subscribe({
      next: (response) => {
        this.alertTheUser("Successfully submitted request to email a list of enrolled accounts for brand " + this.selectedBrand.brandCode);
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });    
  }

  onAccountDataExtract(event: any) {
    if (!this.selectedBrand.brandCode) {
      this.alertTheUser("Please select a brand to generate an accounts data extract.");
      return;
    }
    this.availableVariables.length = 0;
    this.selectedVariables.length = 0;
      // Call the service to invoke a Web API call
      this.brandProfileService.getBrandVariables(this.selectedBrand.brandCode, this.selectedBrand.baseUrl || '')
      .subscribe({
        next: (response) => {
          if (response) {
            response.forEach(element => {
              this.availableVariables.push({label: element.variableName, value: element.variableId});
            })
            this.displayDialog = true;
          }
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
          this.availableVariables = [...this.availableVariables];
          this.selectedVariables = [...this.selectedVariables];
        }
      });
  }

  onSubmitAccountExtract() {
    const requestedVars: any = {};
    this.selectedVariables.forEach(item => {
      requestedVars[item.label] = item.value;
    });
    // Make a Web APi call
    this.summaryReportsService.getAccountDataExtract(this.selectedBrand.brandCode || '', requestedVars, 
      this.emailAddress, this.selectedBrand.baseUrl || '')
      .subscribe({
        next: (response) => {
          this.alertTheUser("Successfully submitted request to email an account data extract for brand "
           + this.selectedBrand.brandCode);
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });      
  }

  cancel() {
    this.displayDialog = false;
  }

  onRemoveBrandClick(event: any) {
    if (!this.selectedBrand.brandCode) {
      this.alertTheUser("Please select a brand to be completely removed from Walletron.");
      return;
    } else {
      this.confirmationService.confirm({
        message: "Do you really want to completely remove selected brand from Walletron, Portal, and AWS S3?  This operation is not reversable, so the brand '"
        + this.selectedBrand.brandCode + "' (" + this.selectedBrand.brandName + ") will be totally deleted from the current region.",
        header: 'Warning',
        key: 'brand-listing',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.isSpinnerHidden = false;
            this.brandMigrationService.removeBrand(this.selectedBrand.brandCode || '')
            .subscribe({
              next: (response) => {
                this.getBrands();
                // this.brands = this.sharedService.getCurrentUserGrantedBrands(); 
                this.isSpinnerHidden = true;
                this.alertTheUser(response);
              },
              error: (error) => {
                this.isSpinnerHidden = true;
                this.alertTheUser(error.message);
              },
              complete: () => {
              }
            });
        }
      });
    }
  }

  // onRemoveBrandClick(event) {
  //   if (!this.selectedBrand.brandCode) {
  //     this.alertTheUser("Please select a brand to be completely removed from moBills.");
  //     return;
  //   } else {
  //     this.confirmationService.confirm({
  //       message: "Do you really want to completely remove selected brand from moBills (Walletron, Portal, and AWS S3)?  This operation is not reversable, so the brand '"
  //       + this.selectedBrand.brandCode + "' (" + this.selectedBrand.brandName + ") will be totally deleted from the current region.",
  //       header: 'Warning',
  //       key: 'brand-listing',
  //       icon: 'pi pi-exclamation-triangle',
  //       acceptLabel: "Yes",
  //       rejectVisible: true,
  //       accept: () => {
  //         this.isSpinnerHidden = false;
  //         this.brandMigrationService.getAuthToken(this.selectedBrand.brandCode)
  //         .subscribe( 
  //           (response) => {
  //               let authToken = response;
  //               this.brandMigrationService.removeBrand(this.selectedBrand.brandCode, authToken)
  //               .subscribe( 
  //                 (response) => {
  //                   this.getBrands();
  //                   this.isSpinnerHidden = true;
  //                   this.alertTheUser(response);
  //                   },
  //                 (error)  => {
  //                   this.isSpinnerHidden = true;
  //                   this.alertTheUser(error.message);
  //                 }
  //               )
  //             },
  //           (error)  => {
  //             this.isSpinnerHidden = true;
  //             this.alertTheUser(error.message);
  //           }
  //         )
  //          }
  //     });
  //   }
  // }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-listing',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }


}


