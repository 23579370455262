import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { IBrand } from '../../interfaces/brand';
import { IBillerBrand } from '../../interfaces/biller-brand';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;
  protected authServerUrl = environment.apiServer.authServerUrl;

  private brandCodesUrl = this.baseUrl + '/common/brands'; 
  private brandsWithRegionUrl = this.baseUrl + '/common/brands/with-regions'; 
  private brandWithRegionUrl = this.baseUrl + '/common/brands/with-regions/{brandCode}'; 
  private adhocUrl = '/adhoc';
  private authBrandCodesUrl = this.authServerUrl + '/auth/brands/billers';

  private headers = new HttpHeaders()
    .set('Authorization', this.basicAuth)
  private baseHeaders = new HttpHeaders()
    .set('Authorization', this.baseAuth);

  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService
    ) { }

  // Get all brands
  // getBrands(): Observable<IBrand[]> {
  //    console.log('baseUrl', this.baseUrl);
  //   return this.http.get<IBrand[]>(this.brandCodesUrl, {responseType: 'json'})
  //   .pipe(catchError(this.handleError))
  // }

  // Retrieve all brands
  getBrands(): Observable<IBillerBrand[]> {
    console.log('baseUrl', this.authBrandCodesUrl);
   return this.http.get<IBillerBrand[]>(this.authBrandCodesUrl, {responseType: 'json'})
   .pipe(
    catchError(this.errorProcessorService.handleError)
   )
  }

  // Get all brands
  getBrandsWithRegion(): Observable<IBrand[]> {
    // console.log('baseUrl', this.baseUrl);
    return this.http.get<IBrand[]>(this.brandsWithRegionUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Get one given brand brands
  getBrandWithRegion(brandCode: string): Observable<IBrand> {
    // console.log('brandService: ', 'inside getBrandWithRegion');
    let url = this.brandWithRegionUrl.replace('{brandCode}', brandCode);
    return this.http.get<IBrand>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Get brands for a specific enviromental region
  getEnvBrands(envRegion: string): Observable<IBrand[]> {
    return this.http.get<IBrand[]>(this.brandCodesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Get a list of enrolled accounts for a brand
  getEnrolledAccounts(brandCode: string, recepient: string, baseUrl: string) {
    let request = {
      "adHocReportType": "ENROLLED_ACCOUNTS_LIST",
      "brandCode": brandCode,
      "recepientEmailAddresses": [recepient]
    }
      var url = baseUrl.replace('Walletron','reports') + this.adhocUrl;
      return this.http.post<string>(url, request, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

}
