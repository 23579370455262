<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-7">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded" >
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 

<!-- Dropdowns for selecting Pass Tempalte and Offer -->

<div class="col-6">

    <p-card header="Settings Criteria" subheader="" styleClass="ui-card-light" >

        <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm.value)">

            <div class="grid">

                <div class="col-3">
                    <label for="passTemplate" class="margin-left">Pass Template: </label>
                    <p-dropdown id ="passTemplate" [options]="passTemplates" formControlName="passTemplate" 
                        [filter]="false" [editable]="false" [style]="{'width': '100%'}">
                        <p-message severity="error" text="Pass Template is required" *ngIf="!userForm.controls['passTemplate'].valid&&userForm.controls['passTemplate'].dirty"></p-message>                 
                    </p-dropdown>
                </div> 

                <div class="col-3">
                    <label for="offer" class="margin-left">Field Set: </label>
                    <p-dropdown id ="offer" [options]="offers" formControlName="offer" 
                        [filter]="false" [editable]="false" [style]="{'width': '100%'}">
                        <p-message severity="error" text="Field Set is required" *ngIf="!userForm.controls['offer'].valid&&userForm.controls['offer'].dirty"></p-message>                 
                    </p-dropdown>
                </div> 

                <div class="col-2">
                    <label for="sourceChannel" class="margin-left">Source Channel: </label>
                    <p-dropdown id ="sourceChannel" [options]="sourceChannels" formControlName="sourceChannel" 
                        [filter]="false" [editable]="false" [style]="{'width': '100%'}">
                        <p-message severity="error" text="Source Channel is required" *ngIf="!userForm.controls['sourceChannel'].valid&&userForm.controls['sourceChannel'].dirty"></p-message>                 
                    </p-dropdown>
                </div> 

                <div class="col-2">
                    <label for="imageSize" class="margin-left">Image Size: </label>
                    <p-dropdown id ="imageSize" [options]="imageSizes" formControlName="imageSize" 
                        [filter]="false" [editable]="false" [style]="{'width': '100%'}">
                        <p-message severity="error" text="Image Size is required" *ngIf="!userForm.controls['imageSize'].valid&&userForm.controls['imageSize'].dirty"></p-message>                 
                    </p-dropdown>
                </div> 

                <!-- <div class="col-2"></div> -->


                <div class="field grid">
<!-- 
                <button pButton type="submit" label="Enrollment Link" (click)="onSubmit($any($event))" class="ui-button-primary" 
                    [disabled]="!userForm.valid" >
                </button>
 -->
                <p-button type="button" label="Enrollment Link" styleClass="p-button-success p-button-raised p-button-rounded"
                (click)="onSubmit($any($event))" [disabled]="!userForm.valid" >
                </p-button>


            </div>  

        </div>

        </form>

        <p-footer>
                <mat-icon class="nav-caption wal-center">settings</mat-icon>
        </p-footer>

    </p-card>

</div> 


<div class="grid" *ngIf="isEnrollmentLinkShown">
    <div class="col-6">
        <p-card header="Enrollment Link" subheader="" styleClass="ui-card-light" >

            <form>
                <label>{{enrollmentLinkKey}}</label>
        
                <textarea pInputTextarea [id]="enrollmentLinkValue" style="width: 100%;" >{{enrollmentLinkValue}}</textarea>
    
                <div class="col-12">
<!-- 
                    <button pButton type="button" label="QR Code" (click)="onQrCodeClick($event)"  
                        class="ui-button-primary">
                    </button>
 -->
                    <p-button type="button" label="QR Code" styleClass="p-button-success p-button-raised p-button-rounded margin-left"
                    (click)="onQrCodeClick($event)" >
                    </p-button>
                        
                </div>  
    
            </form>

            <div class="ui-g-12 group" *ngIf="isShortenLinkShown">
                <label for="imageSize">Shorten Link: </label>
                <textarea pInputTextarea style="width: 100%;" readonly="true">{{shortenLinkValue}}</textarea>
            </div> 

            <p-footer>
                <mat-icon class="nav-caption wal-center">link</mat-icon>
            </p-footer>

        </p-card>
    </div>
</div>

<div class="col-2" styleClass="ui-card-light"  *ngIf="isShortenLinkShown">
    <img src="{{image}}" style="display: block"/>
</div>


<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="qrcode-generation"></p-confirmDialog>

