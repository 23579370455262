import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { ConfigPropertySrv } from '../../models/config.property.srv';
import { IConfigPropertyResponse } from '../../interfaces/config-property-response';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class ConfigPropertyService {

//  protected basicAuth = AppConfigService.settings.appSetting.baseAuth;
  protected basicAuth = environment.appSetting.baseAuth;

  private configPropertiesEndpoint = '/config-props-admin/master';

  private headers = new HttpHeaders()
    .set('Authorization', this.basicAuth)

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private errorProcessorService: ErrorProcessorService) { }

    getRequestUrl(envRegionBaseUrl: string) {
      var requestUrl = ""; //this.configPropertiesUrl
      if (envRegionBaseUrl) {
        requestUrl = envRegionBaseUrl + this.configPropertiesEndpoint;
      }
      return requestUrl;
    }

  getConfigProperties(envRegionBaseUrl: string, selectedPropertyName: string, selectedPropertyScope: string,
    selectedPropertyType: string, offset: number): Observable<IConfigPropertyResponse> {
    var params = new HttpParams()
      .set('limit', '100')
      .set('offset', offset);
    if (selectedPropertyName) {
      params = params.set('propertyName', selectedPropertyName);
    }
    if (selectedPropertyScope) {
      params = params.set('propertyScope', selectedPropertyScope);
    }
    if (selectedPropertyType) {
      params = params.set('propertyType', selectedPropertyType);
    }
    return this.http.get<IConfigPropertyResponse>(this.getRequestUrl(envRegionBaseUrl), 
      {params: params, responseType: 'json'});
  }

  addConfigProperty(envRegionBaseUrl: string, configProperty: any) {
    let newConfigProperty: ConfigPropertySrv = 
    {
      configPropertyId: 0, 
      propertyName: configProperty.propertyName, 
      propertyScope: configProperty.propertyScope,
      propertyType: configProperty.propertyType, 
      propertyDescription: configProperty.propertyDescription, 
      enabled: configProperty.enabled ? 1 : 0, 
      defaultValue: configProperty.defaultValue
    };
    let newConfigProperties = [newConfigProperty];
    return this.http.post(this.getRequestUrl(envRegionBaseUrl),
    newConfigProperties, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  modifyConfigProperty(envRegionBaseUrl: string, configProperty: any) {
    let modifiedConfigProperty: ConfigPropertySrv = 
    {
      configPropertyId: configProperty.configPropertyId, 
      propertyName: configProperty.propertyName, 
      propertyScope: configProperty.propertyScope,
      propertyType: configProperty.propertyType, 
      propertyDescription: configProperty.propertyDescription, 
      enabled: configProperty.enabled? 1 : 0, 
      defaultValue: configProperty.defaultValue
    };
    let modifiedConfigProperties = [modifiedConfigProperty];
    return this.http.put(this.getRequestUrl(envRegionBaseUrl),
    modifiedConfigProperties, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  deleteConfigProperty(envRegionBaseUrl: string, configPropertyId: number) {
    return this.http.delete(this.getRequestUrl(envRegionBaseUrl) + '/' + configPropertyId, 
    {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }


}
