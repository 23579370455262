import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBrandCount } from '../../interfaces/brand-count';
import { IBrandClickCount } from '../../interfaces/brand-click-count';
import { IEnrollmentStats } from '../../interfaces/enrollment-stats';
import { INotificationStats } from '../../interfaces/notification-stats';
import { IAccountEnrollmentTrend } from '../../interfaces/account-enrollment-trend';
import { ICsrCount } from '../../interfaces/csr-count';
import { IClickthroughCount } from '../../interfaces/clickthrough-count';
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class BrandStatsService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;
  
  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;
  
  private headers = new HttpHeaders()
  .set('Authorization', this.basicAuth);

  private baseHeaders = new HttpHeaders()
  .set('Authorization', this.baseAuth);

  private clickthroughStatsUrl = '/{brandCode}/clickthroughs';

  constructor(
    private http: HttpClient, 
    private sharedService: SharedService,
    private errorProcessorService: ErrorProcessorService) {
   }

   getClickthroughsCounts(baseUrl: string, brandCodes: any, dateStart: string, dateEnd: string): Observable<IBrandClickCount[]> {
    var url = this.getStatsUrl(baseUrl) + "/clickthroughs";
    var dateTo = this.sharedService.getNextDate(dateEnd);
    var params = new HttpParams();
      if (brandCodes != null) {
        params = params.set('brandCodes', brandCodes);
      }
      if (dateStart != null) {
        params = params.set('dateStart', this.sharedService.getFormattedDate(dateStart));
      }
      if (dateEnd != null) {
        params = params.set('dateEnd', this.sharedService.getFormattedDate(dateTo));
      }
      // return this.http.get<IBrandClickCount[]>(url, {headers: this.baseHeaders, params: params, responseType: 'json'})
      return this.http.get<IBrandClickCount[]>(url, {params: params, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
   }

  getRegisteredPasses(baseUrl: string, recentOnly: any): Observable<IBrandCount[]> {
    var url = this.getStatsUrl(baseUrl) + "/registeredPasses";
    var params = new HttpParams();
    params = params.set('recentOnly', recentOnly);
    // return this.http.get<IBrandCount[]>(url, {headers: this.baseHeaders, params: params, responseType: 'json'})
    return this.http.get<IBrandCount[]>(url, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getActiveAccounts(baseUrl: string): Observable<IBrandCount[]> {
    var url = this.getStatsUrl(baseUrl) + "/activeAccounts";
    // return this.http.get<IBrandCount[]>(url, {headers: this.baseHeaders, responseType: 'json'})
    return this.http.get<IBrandCount[]>(url, { responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getEnrollmentStats(baseUrl: string, brandCode: string, dateStart: string, dateEnd: string): Observable<IEnrollmentStats[]> {
    var url = this.getStatsUrl(baseUrl) + "/clickthroughs/bySource";
    var params = new HttpParams();
    var dateTo = this.sharedService.getNextDate(dateEnd);
    params = params.set('brandCode', brandCode);
    params = params.set('dateStart', this.sharedService.getFormattedDate(dateStart));
    params = params.set('dateEnd', this.sharedService.getFormattedDate(dateTo));
    // return this.http.get<IEnrollmentStats[]>(url, {headers: this.baseHeaders, params: params, responseType: 'json'})
    return this.http.get<IEnrollmentStats[]>(url, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getNotificationStats(baseUrl: string, brandCode: string, dateStart: string, dateEnd: string): Observable<INotificationStats[]> {
    var url = this.getStatsUrl(baseUrl) + "/notifications";
    var params = new HttpParams();
    var dateTo = this.sharedService.getNextDate(dateEnd);
    params = params.set('brandCode', brandCode);
    params = params.set('dateStart', this.sharedService.getFormattedDate(dateStart));
    params = params.set('dateEnd', this.sharedService.getFormattedDate(dateTo));
    // return this.http.get<INotificationStats[]>(url, {headers: this.baseHeaders, params: params, responseType: 'json'})
    return this.http.get<INotificationStats[]>(url, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getEnrollmentTrent(brandCodes: any, numOfMonths: number): Observable<IAccountEnrollmentTrend[]> {
    var url = this.baseUrl + "/account-stats/enrollment-trend";
    var params = new HttpParams();
    if (brandCodes != null) {
      params = params.set('brandCodes', brandCodes);
    }
    if (numOfMonths != null) {
      params = params.set('numOfMonths', numOfMonths);
    }
    return this.http.get<IAccountEnrollmentTrend[]>(url, {headers: this.headers, params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getCsrCounts(baseUrl: string, brandCode: string, dateStart: string, dateEnd: string): Observable<ICsrCount[]> {
    var url = this.getStatsUrl(baseUrl) + "/csrs";
    var dateTo = this.sharedService.getNextDate(dateEnd);
    var params = new HttpParams();
      if (brandCode != null) {
        params = params.set('brandCode', brandCode);
      }
      if (dateStart != null) {
        params = params.set('dateStart', this.sharedService.getFormattedDate(dateStart));
      }
      if (dateEnd != null) {
        params = params.set('dateEnd', this.sharedService.getFormattedDate(dateTo));
      }
      // return this.http.get<ICsrCount[]>(url, {headers: this.baseHeaders, params: params, responseType: 'json'})
      return this.http.get<ICsrCount[]>(url, {params: params, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  getClickthroughStats(baseUrl: string, brandCode: string, dateStart: string, dateEnd: string, aggregationType: string): Observable<IClickthroughCount[]> {
    var url = this.getStatsUrl(baseUrl) + this.clickthroughStatsUrl.replace('{brandCode}', brandCode);
    var dateTo = this.sharedService.getNextDate(dateEnd);
    var params = new HttpParams();
      if (aggregationType != null) {
        params = params.set('aggrType', aggregationType);
      }
      if (dateStart != null) {
        params = params.set('dateStart', this.sharedService.getFormattedDate(dateStart));
      }
      if (dateEnd != null) {
        params = params.set('dateEnd', this.sharedService.getFormattedDate(dateTo));
      }
      return this.http.get<IClickthroughCount[]>(url, {params: params, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  getStatsUrl(baseUrl: string) {
      return baseUrl.replaceAll('Walletron','reports') + "/admin/stats";
  }

}
