import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldEnrollAuthParam extends OfferFieldBase {
    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Key, Field Value, Show/hide Field Expression (N)

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 5,
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 6
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }

}
