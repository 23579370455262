import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { isValidCron } from 'cron-validator';
import { IBrand } from '../../interfaces/brand';
import { IScheduledEvent } from '../../interfaces/scheduled-event';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { JobService } from '../../services/admin/job-service.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-scheduled-events',
  templateUrl: './scheduled-events.component.html',
  styleUrls: ['./scheduled-events.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class ScheduledEventsComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "ScheduledEvents";
  titleMessage = "Scheduled Events";
  eventForm!: FormGroup;
  attributeForm!: FormGroup;
  isListShown = true;
  isDetailsShown = false;
  isAttributeDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;

  scheduledEvents: any[] = [];
  eventCols: any[] = [];
  attributes: any[] = [];
  attributeCols: any[] = [];
  msgDestinationTypes: any[] = [];
  msgDestinationNames: any[] = [];
  msgDestinations: any[] = [];
  resultMsg = "";
  currentBrand: IBrand;

  openBrandSelectionDialog!: boolean;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private jobService: JobService,
    private sharedService: SharedService,
    private userContextService: UserContextService,    
    private confirmationService: ConfirmationService  ) { 
      this.currentBrand = this.sharedService.getDummyBrand();
    }

  ngOnInit(): void {
    this.eventCols = [
      { field: 'eventName', header: 'Event Name', display: 'table-cell', width:'20%'  },
      { field: 'eventDescription', header: 'Event Description', display: 'table-cell', width:'30%'  },
      { field: 'enabled', header: 'Enabled', display: 'table-cell', width:'10%'  },
      { field: 'lastFiredDate', header: 'Last Fired', display: 'table-cell', width:'15%'  },
      { field: 'nextTimeoutDate', header: 'Next Timeout', display: 'table-cell', width:'15%'  }
    ]; 
    this.attributeCols = [
      { field: 'attributeName', header: 'Attribute Name', display: 'table-cell', width:'30%'  },
      { field: 'attributeValue', header: 'Attribute Value', display: 'table-cell', width:'40%'  },
      { field: 'enabled', header: 'Enabled', display: 'table-cell', width:'15%'  }
    ]; 
    this.msgDestinationTypes.push(
      {value: 'Q', label: 'GenericSchedulerQueue'},
      {value: 'R', label: 'RabbitMQ'},
      {value: 'W', label: 'Web API'} 
      );

    this.eventForm = this.fb.group({
      'eventName': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'eventDescription': new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      'enabled': new FormControl(true, [Validators.required]),
      'sendToAll': new FormControl(false, [Validators.required]),
      'msgDestinationType': new FormControl('R', [Validators.required]),
      'msgDestinationName': new FormControl('walletron', [Validators.required]),
      'hourExpr': new FormControl(''),
      'minuteExpr': new FormControl(''),
      'secondExpr': new FormControl(''),
      'lastFiredDate':  new FormControl(''),
      'nextTimeoutDate': new FormControl(''),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
    });  

    this.attributeForm = this.fb.group({
      'attributeName': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'attributeValue': new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      'attributeEnabled': new FormControl(true, [Validators.required])
    });  
    
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.getMessageDestinations(this.currentBrand.baseUrl);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.getScheduledEvents(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_MS_EVENTS_MODIFY')) {
      this.isSubmitShown = true;
    }   
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.getMessageDestinations(this.currentBrand.baseUrl);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.sharedService.setCurrentBrand(item);
      this.getScheduledEvents(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    }
  }

  // Select all existing Scheduled Events for the brand
  getScheduledEvents(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getScheduledEvents(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        this.scheduledEvents = response;
        // Format the dates
        for(let i = 0; i < response.length; i++){
          if (response[i].lastFiredDate) {
            response[i].lastFiredDate = this.sharedService.formatFromISO(response[i].lastFiredDate || '');
          }
          if (response[i].nextTimeoutDate) {
            response[i].nextTimeoutDate = this.sharedService.formatFromISO(response[i].nextTimeoutDate || '');
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Select all possible message destinations
  getMessageDestinations(brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getMessageDestinations(brandUrl)
    .subscribe({
      next: (response) => {
        this.msgDestinations = response;
        // this.populateDstinationName('R');      
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onSubmit(value: string) {
    // Validate cron expressions
    let cronExpr = (this.eventForm.value.secondExpr ? this.eventForm.value.secondExpr : '*') + ' '    
      + (this.eventForm.value.minuteExpr ? this.eventForm.value.minuteExpr : '*') + ' '
      + (this.eventForm.value.hourExpr ? this.eventForm.value.hourExpr : '*') + ' * * *';
    if (!isValidCron(cronExpr, { seconds: true })) {
      this.alertTheUser('The specified cron expression for Hour, Minute, and/or Second is incorrect.  '
       + 'Please varify, make an adjustment, then resubmit the scheduled event.');
      return;
    }
    // Prepare the ScheduledEvent object
    let scheduledEvent: IScheduledEvent = {
      enabled: this.eventForm.value.enabled,
      eventDescription: this.eventForm.value.eventDescription,
      eventName: this.eventForm.value.eventName,
      lastFiredDate: this.eventForm.value.lastFiredDate,
      lastUpdateDate: this.eventForm.value.lastUpdateDate,
      lcn: this.eventForm.value.lcn,
      msgDestinationType: this.eventForm.value.msgDestinationType,
      msgDestinationName: this.eventForm.value.msgDestinationName,
      nextTimeoutDate: this.eventForm.value.nextTimeoutDate,
      sendToAll: this.eventForm.value.sendToAll,
      createDate: this.eventForm.value.createDate,
      cronConfig: {
        hourExpr: this.eventForm.value.hourExpr,
        minuteExpr: this.eventForm.value.minuteExpr,
        secondExpr: this.eventForm.value.secondExpr
      },
      attributes: [{attributeName: 'dummy', attributeValue: 'dummy', enabled: false}]
    };
    this.attributes.forEach(attribute => {
      scheduledEvent.attributes?.push(attribute);
    })
    scheduledEvent.attributes?.shift();
    // Call the service to invoke a Web API call
    this.brandProfileService.submitScheduledEvent(scheduledEvent, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.alertTheUser('Successfully submitted scheduled event ' + scheduledEvent.eventName + ' for brand ' + this.currentBrand.brandCode);
        this.getScheduledEvents(this.currentBrand.brandCode, this.currentBrand.baseUrl);
        this.isListShown = true;
        this.isDetailsShown = false;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  showDialogToAdd() {
    this.populateDstinationName('R');      
    this.eventForm.setValue({eventName: '', eventDescription: '', enabled: true, hourExpr: '', minuteExpr: '', msgDestinationType: 'R',
      msgDestinationName: 'walletron',
      sendToAll:false, secondExpr: null, lastFiredDate: null, nextTimeoutDate: null, createDate: null, lastUpdateDate: null
    });
    this.attributes = [];
    this.isListShown = false;
    this.isAttributeDetailsShown = false;
    this.isDetailsShown = true;
  }

  // Modify existing brand variable
  onRowEdit(event: any) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getScheduledEvent(event.eventName, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.attributes = [];
        let defHour = '';
        let defMinute = '';
        let defSecond = '';
        if (response.hasOwnProperty('cronConfig')) {
          defHour = response.cronConfig.hasOwnProperty('hourExpr') ? response.cronConfig.hourExpr : '';
          defMinute = response.cronConfig.hasOwnProperty('minuteExpr') ? response.cronConfig.minuteExpr : '';
          defSecond = response.cronConfig.hasOwnProperty('secondExpr') ? response.cronConfig.secondExpr || '': '';
        }
        this.populateDstinationName(response.msgDestinationType || ''); 
        this.eventForm.setValue({eventName: response.eventName, eventDescription: response.eventDescription, 
          enabled: response.enabled, hourExpr: defHour, minuteExpr: defMinute, secondExpr: defSecond,
          msgDestinationType: response.msgDestinationType, msgDestinationName: response.msgDestinationName,
          sendToAll: response.sendToAll,
          createDate: this.sharedService.formatFromISO(response.createDate),
          lastFiredDate: this.sharedService.formatFromISO(response.lastFiredDate || ''),
          nextTimeoutDate: this.sharedService.formatFromISO(response.nextTimeoutDate || ''),
          lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate || '')});
        if (response.hasOwnProperty('attributes')) {
          response.attributes?.forEach(attribute => {
            this.attributes.push(attribute);
          })
        }
        this.isListShown = false;
        this.isAttributeDetailsShown = false;
        this.isDetailsShown = true;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Delete existing scheduled event
  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the scheduled event
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the scheduled event ' + event.eventName + ' ?',
      header: 'Confirmation',
      key: 'scheduled-events',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteScheduledEvent(event.eventName, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted variable ' + event.variableName + ' from brand ' + this.currentBrand.brandCode);
            this.getScheduledEvents(this.currentBrand.brandCode, this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  onRowSelect(event: any) {
  }  

  cancelDetails() {
      this.isListShown = true;
      this.isDetailsShown = false;
  }

  triggerEvent () {
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to execute the event right now on demand?',
      header: 'Confirmation',
      key: 'scheduled-events',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // All events with specific task name
        let eventName = this.eventForm.value.eventName;
        let taskName = null;
        this.attributes.forEach(attribute => {
          if (attribute.attributeName == "SchedulerTaskName") {
            debugger;
            taskName = attribute.attributeValue;
          }
        })
        // Generic TriggeredActionExecutor task name - use the event name instead
        if (taskName == 'TriggeredActionExecutor') {
           taskName = eventName;
         }
        // Call the service to invoke a Web API call
        this.jobService.triggerEvent(this.currentBrand.brandCode, taskName, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully triggered event ' + eventName + ' from brand ' + this.currentBrand.brandCode);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }
  
  onAddAttribute() {
    this.attributeForm.setValue({attributeName: '', attributeValue: '', attributeEnabled: true})
    this.isAttributeDetailsShown = true;
  }

  onAttributeEdit(event: any) {
    this.attributeForm.setValue({attributeName: event.attributeName, attributeValue: event.attributeValue, attributeEnabled: event.enabled})
    this.isAttributeDetailsShown = true;
  }

  onAttributeDelete(event: any) {
    let deletedAttribute = event.attributeName;
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete scheduled event attribute ' + deletedAttribute + ' ?',
      header: 'Confirmation',
      key: 'scheduled-events',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        for( var i = 0; i < this.attributes.length; i++){ 
          if ( this.attributes[i].attributeName === deletedAttribute) { 
            this.attributes.splice(i, 1); 
              i--; 
          }
        }
      },
      reject: () => {
      }
    });
  }

  onAdvanceAttribute(event: any) {
    let formValue = this.attributeForm.value;
    let isNewAttribute: boolean = true;    
    this.attributes.forEach(attribute => {
      if (attribute.attributeName == formValue.attributeName) {
        attribute.attributeValue = formValue.attributeValue;
        attribute.enabled = formValue.attributeEnabled;
        isNewAttribute = false;
      }
    })
    if (isNewAttribute) {
      this.attributes.push({
        attributeName: formValue.attributeName, attributeValue: formValue.attributeValue, enabled: formValue.attributeEnabled});
    }
    this.isAttributeDetailsShown = false;
  }

  onCancelAttributeDetails() {
    this.isAttributeDetailsShown = false;
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'scheduled-events',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  onChangMsgDestinationType(event: any) {
    let msgDestinationType = event.value;
    this.populateDstinationName(msgDestinationType);
  }

  populateDstinationName(msgDestinationType: string) {
    this.msgDestinationNames = [];
    if (this.msgDestinations) {
      this.msgDestinations.forEach(item => {
        if (item.destinationType == msgDestinationType) {
          let destinationNames: string[] = item.destinationNames;
          destinationNames.forEach(destinationName => {
            this.msgDestinationNames.push({value: destinationName, label: destinationName});
          })
        }
      })
    }
  }

}
