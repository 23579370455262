import { Component, OnInit } from '@angular/core';
import { Validators,FormControl,FormGroup,FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { PortalUserService } from '../../services/admin/portal-user.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { UserContextService } from '../../services/safeguard/user-context.service';

@Component({
  selector: 'app-group-onboard',
  templateUrl: './group-onboard.component.html',
  styleUrls: ['./group-onboard.component.css']
})
export class GroupOnboardComponent implements OnInit {

  userForm!: FormGroup;
  isDisabled: boolean = false;
  isSubmitShown: boolean = false;
  isResultMsgShown: boolean = false;
  resultMsg = "";
  userTypes: any[] = [];
  csvFile!: File

  isSpinnerHidden: boolean = true;  

  constructor(

    private fb: FormBuilder, 
    private portalUserService: PortalUserService, 
    private tokenStorageService: TokenStorageService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService    
  ) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      'csvFile': new FormControl('', [Validators.required]),
      'brandCode': new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]),
      'initialPassword': new FormControl('', [Validators.required, Validators.maxLength(45)]),
      'userType': new FormControl('biller-user', [Validators.required]),
      'passwordChangeRequired': new FormControl(true, [Validators.required]),
      'csvSeparator': new FormControl(',', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]),
      'roleListSeparator': new FormControl('|', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]),
      'userNameColumnPosition': new FormControl('0', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]),
      'userRolesColumnPosition': new FormControl('1', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]),
      'emailAddressColumnPosition': new FormControl('2', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]),
      'fullNameColumnPosition': new FormControl('3', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]),
    });
    this.userTypes = [
      {label: 'Biller User', value: 'biller-user'},
      {label: 'ACI User', value: 'aci-staff'},
      {label: 'ACI Read-Only', value: 'aci-read-only'},
      {label: 'Customer', value: 'customer'}
    ];
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WA_USER_MODIFY')) {
      this.isSubmitShown = true;
    }
    
  }

  onCsvFileChange(event: any) {
    this.csvFile = event.files[0];
    this.userForm.patchValue({'csvFile': this.csvFile.name});
  }

  onSubmit(value: string) {
    this.resultMsg = '';
    this.isResultMsgShown = false;
    let onboardProfile = {
      brandCode: this.userForm.value.brandCode,
      initialPassword: this.userForm.value.initialPassword,
      userType: this.userForm.value.userType, 
      passwordChangeRequired: this.userForm.value.passwordChangeRequired,
      csvSeparator: this.userForm.value.csvSeparator,
      roleListSeparator: this.userForm.value.roleListSeparator,
      userNameColumnPosition: this.userForm.value.userNameColumnPosition,
      userRolesColumnPosition: this.userForm.value.userRolesColumnPosition,
      emailAddressColumnPosition: this.userForm.value.emailAddressColumnPosition,
      fullNameColumnPosition: this.userForm.value.fullNameColumnPosition,
    };

    // Call the service to invoke a Web API call
    this.isSpinnerHidden = false;
    this.portalUserService.groupOnboard(onboardProfile, this.csvFile)
    .subscribe({
      next: (response) => {
        this.isSpinnerHidden = true;
        // console.log("Success", response);
        this.isDisabled = true;
        this.isSubmitShown = false;
        if (JSON.stringify(response).includes('Duplicate user')) {
          this.resultMsg = response;
          this.isResultMsgShown = true;
          this.alertTheUser("The CSV file has duplicate user(s) that already exist(s) in the database.", 'Warning');
        } else {
          this.alertTheUser('Successfully onboarded a group of users as specified in the CSV file.', 'Success');
        }
      },
      error: (error) => {
        this.isSpinnerHidden = true;
        this.alertTheUser("An error occurred while processing the request.", 'Error');
        this.resultMsg = error.error.message;
        this.isResultMsgShown = true;
      },
      complete: () => {
      }
    });
  }

  alertTheUser(message: string, header: string) {
    this.confirmationService.confirm({
      message: message,
      header: header,
      key: 'group-onboard',
      icon: header.includes('Success') ? 'pi pi-thumbs-up' : 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
