<h2>Enrollment Report</h2>
<div class="grid">
    <div class="col-10" id="idTable">

        <p-table #dt [columns]="cols" [value]="enrollments" selectionMode="single" [(selection)]="selectedBrand"
            tableStyleClass="prime-table" [showCurrentPageReport]="true" exportFilename="EnrollmentReport" csvSeparator="," [hidden]="hideTable"
            sortMode="multiple"  [paginator]="true" [rowsPerPageOptions]="[10,15,25,50]" [rows]="15">
          <ng-template pTemplate="caption">
              Enrollments by Unique Accounts
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    <input *ngSwitchCase="'brandCode'" pInputText type="text" (input)="dt.filter($any($event.target).value || '', col.field, 'contains')" [style]="{'width': '75%'}">
                    <input *ngSwitchCase="'description'" pInputText type="text" (input)="dt.filter($any($event.target).value || '', col.field, 'contains')" [style]="{'width': '75%'}">
                    <input *ngSwitchCase="'passName'" pInputText type="text" (input)="dt.filter($any($event.target).value || '', col.field, 'contains')"  [style]="{'width': '75%'}">
                </th>
            </tr>  
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
        </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td></td>
                    <td></td>
                    <td style="background-color:#7B95A3; color: white">TOTALS:</td>
                    <td style="background-color:#7B95A3; color: white">{{totalUniqueAccounts}}</td>
                    <td style="background-color:#7B95A3; color: white">{{totalLast30AddsCnt}}</td>
                    <td style="background-color:#7B95A3; color: white">{{totalLast30Removes}}</td>
                    <td style="background-color:#7B95A3; color: white">{{totalLast30Nets}}</td>
                </tr>
            </ng-template>    
            <ng-template pTemplate="summary">
                <div style="text-align:left">
                    <div class="ui-helper-clearfix">
<!-- 
                        <button type="button" pButton icon="fa-file-o" iconPos="left"
                        label="CSV Export" (click)="dt.exportCSV()" style="float:right"></button>
                         -->
                        <p-button type="button" label="CSV Export" 
                            styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="dt.exportCSV()">
                        </p-button>

                    </div>
                </div>
            </ng-template>    
        </p-table> 
      
    </div>

</div>

<div class="grid">
    <!-- <div class="col-1">
        <button pButton type="button" label="Get Stats" class="ui-button-success margin-left" (click)="onGetStatsClick($event)"></button>
    </div> -->

    <div class="col-2" id="idSpinner" [hidden]="hideSpinner">
        <div class="loader"></div>  
        Preparing report - please wait...
    </div>
</div>


