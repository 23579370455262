<div [formGroup]="form">
  
    <div class="col-12">

        <div class="grid">
            <div class="col-1"></div>
            <div class="field grid">

                <label *ngIf="offerFieldAttribute.required" [attr.for]="offerFieldAttribute.key" class="col-fixed label"
                    style="width: 17em">{{offerFieldAttribute.label}} (*)
                </label>
                <label *ngIf="!offerFieldAttribute.required" [attr.for]="offerFieldAttribute.key" class="col-fixed label"
                    style="width: 17em">{{offerFieldAttribute.label}}
                </label>

                <div class="col" [ngSwitch]="offerFieldAttribute.controlType">

                    <input *ngSwitchCase="'textbox'" pInputText [id]="offerFieldAttribute.key" 
                        [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                        [style]="{'width': offerFieldAttribute.styleWidth}" (ngModelChange)="onTextChangeEvent(offerFieldAttribute.key, $event)"
                        [type]="offerFieldAttribute.type" [formControlName]="offerFieldAttribute.key" /> 
        
                    <p-dropdown *ngSwitchCase="'dropdown'" [id]="offerFieldAttribute.key"
                        [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                        [options]="offerFieldAttribute.options" [formControlName]="offerFieldAttribute.key" [editable]="false" 
                        [style]="{'width': offerFieldAttribute.styleWidth}" (onChange)="onChangeEvent(offerFieldAttribute.key, $event)"> 
                    </p-dropdown>
        
                    <p-checkbox *ngSwitchCase="'checkbox'" [formControlName]="offerFieldAttribute.key"
                        [(ngModel)]="offerFieldAttribute.checked" 
                        [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                        [id]="offerFieldAttribute.key" [binary]="true">
                    </p-checkbox>
        
                    <textarea *ngSwitchCase="'textarea'" pInputTextarea [id]="offerFieldAttribute.key" 
                        [style]="{'width': '100%'}" [rows]="offerFieldAttribute.rows" [cols]="offerFieldAttribute.cols" 
                        [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                        [formControlName]="offerFieldAttribute.key" > 
                    </textarea>
        
                    <input pInputText *ngSwitchCase="'colorpicker'" pInputTextarea [id]="offerFieldAttribute.key"
                        style="width: 20%;" [formControlName]="offerFieldAttribute.key"
                        [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" 
                        [style.background]="offerFieldAttribute.colorInit" 
                        [(colorPicker)]="offerFieldAttribute.colorInit"
                        [cpOKButton] = "true"
                        [cpOKButtonText] = "'Select'"
                        [cpCancelButton] = "true"
                        [cpSaveClickOutside] = "false"
                        [cpDisableInput]="false"
                        [cpAlphaChannel]="'disabled'" 
                        [cpOutputFormat]="'hex'"
                        [cpAddColorButton]="true"
                        [cpColorMode] ="'color'"   
                        [cpPresetLabel]="''"    
                        [cpPresetEmptyMessage]="''"  
                        (colorPickerChange)="onCaptureColor(offerFieldAttribute.key, $event)" 
                        (colorPickerSelect)="onCaptureColor(offerFieldAttribute.key, $event)"                       
                    />
        
                    <p-message severity="error" text="{{offerFieldAttribute.label}} required or invalid value" *ngIf="!isValid"></p-message>                 
        
    
                </div>                

            </div>
        </div>  

<!--         
        <div class="ui-g-3">
            <label *ngIf="offerFieldAttribute.required" [attr.for]="offerFieldAttribute.key">{{offerFieldAttribute.label}} (*)</label>
            <label *ngIf="!offerFieldAttribute.required" [attr.for]="offerFieldAttribute.key">{{offerFieldAttribute.label}}</label>
        </div>

        <div class="ui-g-8 margin-bottom-narrow" [ngSwitch]="offerFieldAttribute.controlType">

            <input *ngSwitchCase="'textbox'" pInputText [id]="offerFieldAttribute.key" 
                [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                [style]="{'width': offerFieldAttribute.styleWidth}" (ngModelChange)="onTextChangeEvent(offerFieldAttribute.key, $event)"
                [type]="offerFieldAttribute.type" [formControlName]="offerFieldAttribute.key" /> 

            <p-dropdown *ngSwitchCase="'dropdown'" [id]="offerFieldAttribute.key"
                [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                [options]="offerFieldAttribute.options" [formControlName]="offerFieldAttribute.key" [editable]="false" 
                [style]="{'width': offerFieldAttribute.styleWidth}" (onChange)="onChangeEvent(offerFieldAttribute.key, $event)"> 
            </p-dropdown>

            <p-checkbox *ngSwitchCase="'checkbox'" [formControlName]="offerFieldAttribute.key"
                [(ngModel)]="offerFieldAttribute.checked" 
                [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                [id]="offerFieldAttribute.key" [binary]="true">
            </p-checkbox>

            <textarea *ngSwitchCase="'textarea'" pInputTextarea [id]="offerFieldAttribute.key" 
                style="width: 100%;" [rows]="offerFieldAttribute.rows" [cols]="offerFieldAttribute.cols" 
                [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" [readonly]="offerFieldAttribute.readonly"
                [formControlName]="offerFieldAttribute.key" > 
            </textarea>

            <input pInputText *ngSwitchCase="'colorpicker'" pInputTextarea [id]="offerFieldAttribute.key"
                style="width: 20%;" [formControlName]="offerFieldAttribute.key"
                [pTooltip]="offerFieldAttribute.tooltip" tooltipPosition="top" 
                [style.background]="offerFieldAttribute.colorInit" 
                [(colorPicker)]="offerFieldAttribute.colorInit"
                [cpOKButton] = "true"
                [cpOKButtonText] = "'Select'"
                [cpCancelButton] = "true"
                [cpSaveClickOutside] = "false"
                [cpDisableInput]="false"
                [cpAlphaChannel]="'disabled'" 
                [cpOutputFormat]="'hex'"
                [cpAddColorButton]="true"
                [cpColorMode] ="'color'"   
                [cpPresetLabel]="''"    
                [cpPresetEmptyMessage]="''"  
                (colorPickerChange)="onCaptureColor(offerFieldAttribute.key, $event)" 
                (colorPickerSelect)="onCaptureColor(offerFieldAttribute.key, $event)"                       
            />

            <p-message severity="error" text="{{offerFieldAttribute.label}} required or invalid value" *ngIf="!isValid"></p-message>                 

        </div>
 -->
    
    </div>

</div>
