<h2>Config Property</h2>

<div class="col-7">
    <div class="grid">
        <div class="col-2">
            <p-dropdown id ="regionName" [options]="envRegions" [(ngModel)]="envRegionBaseUrl" [filter]="true" 
                placeholder="Enviromental Region:" [editable]="false"></p-dropdown>
        </div>  
<!-- 
        <div class="col-2">
            <input pInputText id="propertyName" [(ngModel)]="selectedPropertyName" placeholder="Property Name:"/> 
        </div>  
        <div class="col-2">
            <p-dropdown id ="propertyScope" [options]="propertyScopes" [(ngModel)]="selectedPropertyScope" [filter]="true" 
                placeholder="Property Scope:" [editable]="false"></p-dropdown>
        </div>  
        <div class="col-2">
            <p-dropdown id ="propertyType" [options]="propertyTypes" [(ngModel)]="selectedPropertyType" [filter]="false" 
                placeholder="Property Type:" [editable]="false"></p-dropdown>
        </div>  
        <div class="col-1">
            <p-button type="submit" label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
        </div>  
        <div class="col-1">
            <p-button type="button" label="Clear" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)"></p-button>
        </div>  
 -->
    </div>
</div> 
