import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { HttpParams } from "@angular/common/http";
import { IEventOccurenceResponse } from '../../interfaces/event-occurrence-response';
import { IEventOccurrence } from '../../interfaces/event-occurrence';
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class EventOccurrenceService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;

  private eventsUrl = this.baseUrl + '/monitor/event-occurrence'; 
  private eventNamesUrl = this.baseUrl + '/monitor/event-occurrence/distinct-event-names'; 
  private latestEventsUrl = this.baseUrl + '/monitor/event-occurrence/latest'; 

  private headers = new HttpHeaders()
    .set('Authorization', this.basicAuth)

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private messageService: MessageService,
    private errorProcessorService: ErrorProcessorService)
     { }

  getEventOccurrences(selectedEventName: string, selectedDateFrom: string, selectedDateTo: string, offset: number)
        : Observable<IEventOccurenceResponse> {
      var params = new HttpParams()
      .set('limit', '50')
      .set('offset', offset);
    if (selectedEventName != null) {
      params = params.set('eventName', selectedEventName);
    }
    if (selectedDateFrom != null) {
      params = params.set('dateFrom', this.sharedService.getFormattedDate(selectedDateFrom));
    }
    if (selectedDateTo != null) {
      var dateTo = this.sharedService.getNextDate(selectedDateTo);
      params = params.set('dateTo', this.sharedService.getFormattedDate(dateTo));
    }
    return this.http.get<IEventOccurenceResponse>(this.eventsUrl, {params: params,  responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    );
  }

  getLatestEventOccurrences(interval: number):
      Observable<IEventOccurrence[]> {
      var params = new HttpParams();
    if (interval != null) {
        params = params.set('latestInterval', interval);
    }
    return this.http.get<IEventOccurrence[]>(this.latestEventsUrl,
      {params: params, headers: this.headers, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      );
  }

  getEventNames(): Observable<string[]> {
    return this.http.get<string[]>(this.eventNamesUrl, {responseType: 'json'});
  }

  private log(message: string) {
//    this.messageService.add(`UserService: ${message}`);
  }

}
