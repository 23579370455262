<p-tabView  orientation="top">

    <p-tabPanel header="Simulator" leftIcon="pi pi-tags">
        
        <div class="col-4">

            <p-card header="RTPN SIMULATOR" subheader="" styleClass="ui-card-light" >

                <form [formGroup]="rtpnForm" (ngSubmit)="onRtpnSubmit(rtpnForm.value)">

                    <div class="grid">

                        <div class="col-9">

                            <div class="field grid">
                                <div class="col-1"></div>
                                <label for="barcodeValue" class="col-fixed label" style="width: 15em">Barcode Value:</label>                            
                                <div class="col-4">
                                    <input pInputText id="barcodeValue" type="text" formControlName="barcodeValue" style="width: 15em"
                                        [size]="10" [minLength]="10" placeholder="Required" pTooltip="" tooltipPosition="top"/> 
                                    <p-message severity="error" text="Barcode value is required" *ngIf="!rtpnForm.controls['barcodeValue'].valid&&rtpnForm.controls['barcodeValue'].dirty"></p-message>                
                                </div>
                            </div>
    
                        </div>

                        @if(isBarcodeImageUrlShown) {

                            <div class="col-3"> 

                                <div class="field grid">
                                    <div class="col-2"></div>
                                    <div class="col-2">
                                        <a href= "{{barcodeImageUrl}}" target="_blank">Image URL</a> 
                                    </div>
                                </div>
                                    
                            </div>

                        }

                    </div>                        

                    <div class="grid">

                        <div class="field grid">
                            <div class="col-1"></div>
                            <label for="transactionAmount" class="col-fixed label" style="width: 15em">Transaction Amount:</label>                            
                            <div class="col-4">
                                <input pInputText id="transactionAmount" type="number" formControlName="transactionAmount" style="width: 15em"  
                                    [size]="10" placeholder="Required" pTooltip="" tooltipPosition="top"/> 
                                <p-message severity="error" text="Transaction amount is required" *ngIf="!rtpnForm.controls['transactionAmount'].valid&&rtpnForm.controls['transactionAmount'].dirty"></p-message>                 
                            </div>
                        </div>

                    </div>

                     <div class="grid">

                        <div class="col-12 float-center">
                            <div  class="col-8" id="divshow">

                                <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" 
                                    [disabled]="!rtpnForm.valid" *ngIf="isSubmitShown" ></p-button>
                                <p-button type="button" label="Barcode Lookup" styleClass="p-button-primary p-button-raised p-button-rounded margin-left"
                                    (click)="onBarcodeLookup()"></p-button>

                            </div>
                        </div>
            
                    </div>
           
                </form>

                <p-footer>
                    <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
                </p-footer>
            </p-card>

        </div>

        <div class="ui-g-2" id="idSpinner" [hidden]="isSpinnerHidden">
            <div class="loader"></div>  
        </div>

    </p-tabPanel>

    <p-tabPanel header="Recent Transactions" leftIcon="pi pi-list">

        <div class="grid">

            <div class="col-8">

                <p-card header="RECENT TRANSACTIONS" subheader="" styleClass="ui-card-light" >
    
                    <p-table #dtTxns [columns]="txnCols" [value]="txns" selectionMode="single" 
                            styleClass="p-datatable-striped p-datatable-sm"
                            sortMode="multiple" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,25,50]">
                        <ng-template pTemplate="caption">
                            Transaction List
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{rowData[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary" let-rowData>
                        </ng-template>    
                  </p-table> 
    
                </p-card>
    
            </div>
                
        </div>

    </p-tabPanel>


</p-tabView>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="rtpn-simulator"></p-confirmDialog>

<p-dialog appendTo="body" header="Barcode Lookup" [(visible)]="isDialogDisplayed" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '25%'}">

    <p-card header="BARCODE LOOKUP" subheader="" styleClass="ui-card-light" >

        <form [formGroup]="barcodeForm" (ngSubmit)="onBarcodeSubmit(barcodeForm.value)">

            <div class="grid">

                <div class="field grid">
                    <div class="col-1"></div>
                    <label for="brandCode" class="col-fixed label" style="width: 10em">Brand Code:</label>                    
                    <div class="col-3">
                        <p-dropdown id ="brandCode" [options]="brandCodes" formControlName="brandCode"  
                            [filter]="false" placeholder="Required" [editable]="false" >
                      </p-dropdown>                        
                        <p-message severity="error" text="Brand code is required" *ngIf="!barcodeForm.controls['brandCode'].valid&&barcodeForm.controls['brandCode'].dirty"></p-message>                
                    </div>
                </div>

            </div> 
            
            <div class="grid">
                    
                <div class="field grid">
                    <div class="col-1"></div>
                    <label for="accountId" class="col-fixed label" style="width: 10em">Account Id:</label>                      
                    <div class="col-3">
                        <input pInputText id="accountId" type="text" formControlName="accountId" style="width: 10em"
                            [size]="15" [minLength]="2" placeholder="Required" pTooltip="" tooltipPosition="top"/> 
                        <p-message severity="error" text="Account Id is required" *ngIf="!barcodeForm.controls['accountId'].valid&&barcodeForm.controls['accountId'].dirty"></p-message>                
                    </div>
                </div>

            </div>    

            <div class="grid">

                <div class="col-12 float-center">
                    <div  class="col-8" id="divshow">

                        <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" 
                            [disabled]="!barcodeForm.valid" ></p-button>
                        <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" 
                            (click)="cancel()"></p-button>
                                
                    </div>
                </div>

            </div>

        </form>

        <p-footer>

            <mat-icon class="nav-caption wal-center">list_all</mat-icon>
        </p-footer>
    </p-card>

</p-dialog>   



