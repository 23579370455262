import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldOnlyFieldValue extends OfferFieldBase {
    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.setOfferFieldAttributes();
    } 

    // Field Label, Field Value, Show/hide Field Expression,

    public setOfferFieldAttributes() {

    this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldLabel',
            label: 'Field Label',
            value: this.data.fieldLabel,
            styleWidth: '25em',
            required: false,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'changeMessage',
            label: 'Change Message',
            value: this.data.changeMessage,
            styleWidth: '20em',
            required: false,
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 7
        }));
        
        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }

}
