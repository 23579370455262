import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import { AppConfigService} from '../../services/app-config.service';
import { environment } from '../../../environments/environment';
import { ICsrConfiguration } from '../../interfaces/csr-configuration';
import { ICsrAccountDetails } from '../../interfaces/csr-account-details';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class CsrService {

  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected baseAuth = environment.appSetting.baseAuth;

  private baseHeaders = new HttpHeaders()
    .set("request-origin", "csr-portal")
    .set('Authorization', this.baseAuth);

  private csrConfigurationUrl = '/brands/{brandCode}/csr/config';
  private evpConfigurationUrl = '/admin/brandconfig/{brandCode}/evp';
  private csrAccountLookupUrl = '/brands/{brandCode}/csr/{accountNumber}';
  private csrEnrollmentInvitationUrl = '/account-csr/brands/{brandCode}/invitation';
  private deregisterAccountUrl = '/account-csr/brands/{brandCode}/accounts/{accountNumber}';

  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService   
  ) { }

  getCsrConfiguration(brandCode: string, baseUrl: string): Observable<ICsrConfiguration> {
    var url = baseUrl + this.csrConfigurationUrl.replace('{brandCode}', brandCode);
    return this.http.get<ICsrConfiguration>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getCsrAccountLookup(brandCode: string, baseUrl: string, accountNumber: string,
     secondaryAccountId: string, tertiaryAccountId: string): Observable<ICsrAccountDetails> {
    var url = baseUrl + this.csrAccountLookupUrl.replace('{brandCode}', brandCode).replace('{accountNumber}',accountNumber);
    if (secondaryAccountId) {
      url += '?secAcct=' + secondaryAccountId;
    }
    if (tertiaryAccountId) {
      url += '&terAcct=' + tertiaryAccountId;
    }
    return this.http.get<ICsrAccountDetails>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  sendEnrollmentInvitation(brandCode: string, baseUrl: string, enrollmentInvitationRequest: any) {
    var url = baseUrl + this.csrEnrollmentInvitationUrl.replace('{brandCode}', brandCode);
    // debugger;
    return this.http.post<string>(url, enrollmentInvitationRequest, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deregisterAccount(brandCode: string, baseUrl: string, accountNumber: string): Observable<any> {
    var url = baseUrl + this.deregisterAccountUrl.replace('{brandCode}', brandCode).replace('{accountNumber}',accountNumber);
    return this.http.delete(url, {headers: this.baseHeaders, responseType: 'text'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  
}

