import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from "primeng/api";
import { BrandService } from '../../services/config/brand.service';
import { BrandStatsService } from "../../services/stats/brand-stats.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { SharedService } from "../../services/shared.service";
import { SummaryReportsService } from '../../services/reports/summary-reports.service';
import { IBrand } from '../../interfaces/brand';
import { IBrandPassEnrollmentStats } from '../../interfaces/brand-pass-enrollment-stats';
import { INotificationStats } from '../../interfaces/notification-stats';
import { ICsrCount } from '../../interfaces/csr-count';

@Component({
  selector: 'app-home-biller',
  templateUrl: './home-biller.component.html',
  styleUrls: ['./home-biller.component.css']
})

export class HomeBillerComponent implements OnInit {

  openBrandSelectionDialog: boolean = false;
  isBrandSelectionButtonShown: boolean = false;
  isSpinnerHidden: boolean = true;
  isDashboardHidden: boolean = false;

  currentBrand: IBrand;
  clientBrandCode: string = '';
  brandName: string = '';
  baseUrl: string = '';

  selectedDateFrom!: string;
  selectedDateTo!: string;
  numOfMonths: number = 6;

  enrollmentCounts: any;
  enrollmentOptions: any;
  defaultDate: any;
  
  isNoDataFound: boolean = true;
  columnHeaders: string[] = [];
  treandData: any[] = [];

  enrollments = new Array<IBrandPassEnrollmentStats>();
  activeAccountsCols: any[] = [];

  clickthroughStatsCols: any[] = [];
  clickthroughStats: any[] = [];
  // clickthroughStatsChart: any;
  // optionsClickthroughs: any;

  notificationStatsCols: any[] = [];
  notificationStats: INotificationStats[] = [];
  // notificationStatsChart: any;
  // optionsNotifications: any;

  csrStatsCols: any[] = [];
  csrStats: ICsrCount[] = [];

  constructor(
      private router: Router,
      private brandService: BrandService,
      private tokenStorageService: TokenStorageService,
      private brandStatsService: BrandStatsService,
      private summaryReportsService: SummaryReportsService,
      private sharedService: SharedService,
      private confirmationService: ConfirmationService
  ) {
    this.currentBrand = this.sharedService.getDummyBrand();    
  }

  ngOnInit(): void {

    // Set up the options for the bar
    this.enrollmentOptions = {
      legend: {
        position: 'top',
        display: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }     
    };

    // Prepopulate the dates
    this.selectedDateFrom = this.sharedService.getOneMonthAgoDate();
    this.selectedDateTo = this.sharedService.getCurrentDate();

    // Define the columns for active accounts
    this.activeAccountsCols = [
      { field: 'brandCode', header: 'Brand Code', display: 'table-cell', width:'8%'  },
      { field: 'description', header: 'Brand Description', display: 'table-cell', width:'20%'  },
      { field: 'passName', header: 'Pass Name', display: 'table-cell', width:'24%'  },
      { field: 'uniqueAccountsCnt', header: 'Active Accounts', display: 'table-cell', width:'12%'  },
      { field: 'last30AddsCnt', header: 'Last 30 Day Adds', display: 'table-cell', width:'12%'  },
      { field: 'last30RemovesCnt', header: 'Last 30 Day Removes', display: 'table-cell', width:'12%'  },
      { field: 'last30NetsCnt', header: 'Last 30 Day Nets', display: 'table-cell', width:'12%'  },
    ]; 
    
    // Define the columns for Notifications grid
    this.notificationStatsCols = [
      { field: 'passName', header: 'Pass Name', width:'40%', display: 'table-cell' },
      { field: 'templateName', header: 'Template Name', width:'40%', display: 'table-cell' },
      { field: 'count', header: 'Count', width:'20%', display: 'table-cell' }
    ];

    // Define the columns for Clickthrough grid
    this.clickthroughStatsCols = [
      { field: 'fieldValue', header: 'Link Type', width:'50%', display: 'table-cell' },
      { field: 'clicknumAW', header: 'Apple Wallet Cnt', width:'25%', display: 'table-cell' },
      { field: 'clicknumAP', header: 'Google Pay Cnt', width:'25%', display: 'table-cell' }
    ]; 

    // Define the columns for CSR grid
    this.csrStatsCols = [
      { field: 'emailSendCnt', header: 'Emails Sent', width:'33%', display: 'table-cell' },
      { field: 'smsSendCnt', header: 'SMS Sent', width:'33%', display: 'table-cell' },
      { field: 'totalSendCnt', header: 'Total', width:'33%', display: 'table-cell' }
    ]; 

    // If the current brand is already known, then use it as the default for the dashboard
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.getBrandProperties(this.currentBrand);
      this.openBrandSelectionDialog = false;
      this.getDashboardData();
    } else {

      // There is no current brand yet - find out a set of brands (user attributes) that have been granted to them
      // let grantedBrands: String[] = [];
      // var currentUser = this.tokenStorageService.getCurrentUser();
      // if (currentUser.userBrandList) {
      //   currentUser.userBrandList.forEach(brandCode => {
      //     grantedBrands.push(brandCode);
      //   })
      // }
      let grantedBrands =this.tokenStorageService.getCurrentUserGrantedBrands();
  
      // Case I: the user was granted multiple brands - popup the brand selection dialog
      if (grantedBrands.length > 1) {
        this.isBrandSelectionButtonShown = true;
        this.openBrandSelectionDialog = true;
      }

      // Case II: the user was granted exactly one brand - make it as the current brand and skip the brand selection dialog
      else if (grantedBrands.length == 1) {
        this.setSingleBrand(grantedBrands[0]);
      }

      // Case III: no single brand was granted - notify the user and force them back to the login page
      else if (grantedBrands.length == 0) {
        this.isDashboardHidden = true;
        this.confirmationService.confirm({
          message: 'Unfortunately your Walletron Portal account has not been fully set up yet.  Please contact Systems Administrator.',
          header: 'Confirmation',
          key: 'home-biller',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: "Ok",
          rejectVisible: false,
          acceptButtonStyleClass: "p-button-info  p-button-rounded",
          accept: () => {
            this.router.navigate(['/login']);      
          },
          reject: () => {
          }
        });
      }
    }
  }

  // Set single brand as the default when the user has just one brand granted
  setSingleBrand(brandCode: string) {
    // console.log('homeBiller: ', 'inside setSingleBrand');
    this.isSpinnerHidden = false;
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe({
      next: (brand) => {
        this.currentBrand = brand;
        this.getBrandProperties(brand);
        this.isSpinnerHidden = true;
        this.getDashboardData();
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Find out brand properies from the IBrand object
  getBrandProperties(brand: IBrand) {
    // console.log('homeBiller: ', 'inside getBrandProperties');
    this.brandName = brand.brandName || '';
    this.clientBrandCode = brand.brandCode;
    this.baseUrl = brand.baseUrl;
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.getBrandProperties(item);
      this.currentBrand = item;
      this.sharedService.setCurrentBrand(item);
      this.getDashboardData();
    }
  }

  // When the user clicks on the Search button
  onSearchClick(event: any) {
    this.getDashboardData();
  }

  // Retrieve all dashboard real-time data
  getDashboardData() {
    // console.log('homeBiller: ', 'inside getDashboardData');
    this.getEnrollmentStatsForBrand(this.clientBrandCode, this.baseUrl);
    this.getEnrollmentTrend(this.clientBrandCode, this.numOfMonths);
    this.getNotificationStats(this.baseUrl, this.clientBrandCode, this.selectedDateFrom, this.selectedDateTo);
    this.getClickthroughStats(this.baseUrl, this.clientBrandCode, this.selectedDateFrom, this.selectedDateTo);
    this.getCsrStats(this.baseUrl, this.clientBrandCode, this.selectedDateFrom, this.selectedDateTo);
}

  // Get the enrollment stats for the current brand: active accounts, last 30 days activity
  getEnrollmentStatsForBrand(brandCode: string, baseUrl: string) {
    // console.log('homeBiller: ', 'inside getEnrollmentStatsForBrand');
    this.isSpinnerHidden = false;
    this.summaryReportsService.getEnrollmentStatsForBrand(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        this.enrollments = response;
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Get the enrollment trend for the last six months
  getEnrollmentTrend(clientBrandCode: string, numOfMonths: number) {
    // console.log('homeBiller: ', 'inside getEnrollmentTrend');
    this.isSpinnerHidden = false;
    this.columnHeaders = [];
    this.treandData = [];
    // Call the service to get the data
    this.brandStatsService.getEnrollmentTrent(clientBrandCode, numOfMonths)
    .subscribe({
      next: (trendRecords) => {
        if (trendRecords) {
          this.enrollmentCounts = {
            labels: [],
            datasets: []
          }
          let labels: any[] = [];
          let brandCode = "";
          let data: any[] = [];
          let datasets = [];
          this.columnHeaders.push('brandCode');
          this.columnHeaders.push('brandName');
  
          trendRecords.forEach(element => {
            if (labels.indexOf(element.timePeriod) < 0) {
              labels.push(element.timePeriod);
              this.columnHeaders.push(element.timePeriod);
            }
            //
            if (element.brandCode != brandCode) {
              if (brandCode != '') {
                datasets.push({label: '', backgroundColor: 'darkblue', data: data});
              }
              brandCode = element.brandCode;
              data = [];
            }
            data.push(element.accountCnt);
          })
          datasets.push({label: '', backgroundColor: 'darkblue', data: data});
  
          this.enrollmentCounts = {
            labels: labels,
            datasets: datasets
          };        
        }
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Process the Clickthrough request
  getClickthroughStats(baseUrl: string, clientBrandCode: string, selectedDateFrom: string, selectedDateTo: string) {
    this.isSpinnerHidden = false;
    this.clickthroughStats = [];
    this.brandStatsService.getClickthroughsCounts(baseUrl, clientBrandCode, selectedDateFrom, selectedDateTo)
    .subscribe({
      next: (response) => {
        if (response.length > 0) {
          var results = response[0].clickCountList;
          var cleanResults = [];
          for(let i = 0; i < results.length; i++){
            cleanResults[i] = {fieldValue: results[i].oflabel != null ? results[i].oflabel : results[i].fieldValue, clicknumAW: results[i].clicknumAW, clicknumAP: results[i].clicknumAP};
          }
          this.clickthroughStats = cleanResults;
        }
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }  

  // Process the Notification request
  getNotificationStats(baseUrl: string, clientBrandCode: string, selectedDateFrom: string, selectedDateTo: string) {
    // console.log('homeBiller: ', 'inside getDashboagetNotificationStatsrdData');
    this.isSpinnerHidden = false;
    this.brandStatsService.getNotificationStats(baseUrl, clientBrandCode, selectedDateFrom, selectedDateTo)
    .subscribe({
      next: (response) => {
        this.notificationStats = response;
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Process the CSR request
  getCsrStats(baseUrl: string, clientBrandCode: string, selectedDateFrom: string, selectedDateTo: string) {
    // console.log('homeBiller: ', 'inside getCsrStats');
    this.isSpinnerHidden = false;
    this.csrStats = [];
    this.brandStatsService.getCsrCounts(baseUrl, clientBrandCode, selectedDateFrom, selectedDateTo)
    .subscribe({
      next: (response) => {
        this.csrStats = response;
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

}
