import { Component, OnInit } from '@angular/core';
import { SummaryReportsService } from '../../services/reports/summary-reports.service';
import { IBrandPassEnrollmentStats } from '../../interfaces/brand-pass-enrollment-stats';

@Component({
  selector: 'app-enrollment-report',
  templateUrl: './enrollment-report.component.html',
  styleUrls: ['./enrollment-report.component.css']
})
export class EnrollmentReportComponent implements OnInit {

  envRegions = [];
  enrollmentStats: IBrandPassEnrollmentStats[] = [];
  enrollments = new Array<IBrandPassEnrollmentStats>();
  cols: any[] = [];
  selectedBrand!: string;
  hideSpinner: boolean = true;
  hideTable: boolean = true;

  totalUniqueAccounts: number = 0;
  totalLast30AddsCnt: number = 0;
  totalLast30Removes: number = 0;
  totalLast30Nets: number = 0;
  totalAllPassRegisters: number = 0;

  constructor(
    private summaryReportsService: SummaryReportsService
  ) { }

  ngOnInit() {
    // this.cols = [
    //   { field: 'brandCode', header: 'Brand Code', display: 'table-cell', width:'8%'  },
    //   { field: 'description', header: 'Brand Description', display: 'table-cell', width:'17%'  },
    //   { field: 'passName', header: 'Pass Name', display: 'table-cell', width:'20%'  },
    //   { field: 'uniqueAccountsCnt', header: 'Active Accounts', display: 'table-cell', width:'11%'  },
    //   { field: 'last30AddsCnt', header: 'Last 30 Day Adds', display: 'table-cell', width:'11%'  },
    //   { field: 'last30RemovesCnt', header: 'Last 30 Day Removes', display: 'table-cell', width:'11%'  },
    //   { field: 'last30NetsCnt', header: 'Last 30 Day Nets', display: 'table-cell', width:'11%'  },
    //   { field: 'allPassRegistersCnt', header: 'Total Registered Passes', display: 'table-cell', width:'11%'  }
    // ]; 
    this.cols = [
      { field: 'brandCode', header: 'Brand Code', display: 'table-cell', width:'8%'  },
      { field: 'description', header: 'Brand Description', display: 'table-cell', width:'20%'  },
      { field: 'passName', header: 'Pass Name', display: 'table-cell', width:'24%'  },
      { field: 'uniqueAccountsCnt', header: 'Active Accounts', display: 'table-cell', width:'12%'  },
      { field: 'last30AddsCnt', header: 'Last 30 Day Adds', display: 'table-cell', width:'12%'  },
      { field: 'last30RemovesCnt', header: 'Last 30 Day Removes', display: 'table-cell', width:'12%'  },
      { field: 'last30NetsCnt', header: 'Last 30 Day Nets', display: 'table-cell', width:'12%'  },
    ]; 
    this.getPortalEnrollmentStats();
  }

  getPortalEnrollmentStats() {
    this.hideSpinner = false;
    this.hideTable = true;
    this.summaryReportsService.getPortalEnrollmentStats()
    .subscribe({
      next: (result) => {
        result.forEach(element => {
          this.totalUniqueAccounts += element.uniqueAccountsCnt;
          this.totalLast30AddsCnt += element.last30AddsCnt;
          this.totalLast30Removes += element.last30RemovesCnt;
          this.totalLast30Nets += element.last30NetsCnt;
          // this.totalAllPassRegisters += element.allPassRegistersCnt;
        })
        this.enrollments = result;
        this.hideSpinner = true;
        this.hideTable = false;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

}
