import { Component, OnInit } from '@angular/core';
import { AccountInfoService } from "../../services/stats/account-info.service";
import { ConfirmationService } from "primeng/api";
import { SharedService } from "../../services/shared.service";
import { IBrand } from '../../interfaces/brand';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})
export class AccountInfoComponent implements OnInit {

  titleMessage = "Account Info";
  openBrandSelectionDialog: boolean = false;
  openQueryBuilderDialog: boolean = false;
  isCurrentBrandShown = true;
  currentBrand: IBrand;
  extAccountId!: string;
  accountStatus!: string;

  customerpassCols!: any[];
  customerpass!: any[];
  varValuesCols!: any[];
  varValues!: any[];
  messagesCols!: any[];
  messages!: any[];
  passregistersCols!: any[];
  passregisters!: any[];
  accountEventLogCols!: any[];
  accountEventLog!: any[];
  clickthrouthLogCols!: any[];
  clickthrouthLog!: any[];

  customerpasses!: any[];
  customerpassHidden: boolean = true;
  priorDisabled: boolean = true;
  nextDisabled: boolean = true;
  isClickthroughHidden: boolean = false;
  isAccountStatusVisibale: boolean = false;

  currentCustomerpassCnt: number = 0;
  totalCustomerpassCnt: number = 0;

  testVar!: any;

  constructor(    
    private accountInfoService: AccountInfoService, 
    private confirmationService: ConfirmationService,
    private sharedService: SharedService) {
      this.currentBrand = this.sharedService.getDummyBrand();      
     }

  ngOnInit(): void {
    // Define the columns for customerpass grid
    this.customerpassCols = [
      { field: 'customerPassId', header: 'CP Id', width:'10%', display: 'table-cell' },
      { field: 'serialNumber', header: 'Serial Number', width:'30%', display: 'table-cell' },
      { field: 'platform', header: 'Platform', width:'12%', display: 'table-cell' },
      { field: 'createDate', header: 'Create Date', width:'24%', display: 'table-cell' },
      { field: 'lastUpdateDate', header: 'Last Update Date', width:'24%', display: 'table-cell' }
    ]
    // Define the columns for varValues grid
    this.varValuesCols = [
      { field: 'variableName', header: 'Var Name', width:'18%', display: 'table-cell' },
      { field: 'dataType', header: 'Data Type', width:'12%', display: 'table-cell' },
      { field: 'versionNum', header: 'Version', width:'10%', display: 'table-cell' },
      { field: 'variableValue', header: 'Var Value', width:'30%', display: 'table-cell' },
      { field: 'createDate', header: 'Create Date', width:'25%', display: 'table-cell' }
    ]; 
    // Define the columns for messages grid
    this.messagesCols = [
      { field: 'messageSeqNum', header: 'Seq No', width:'6%', display: 'table-cell' },
      { field: 'status', header: 'Status', width:'6%', display: 'table-cell' },
      { field: 'messageHeader', header: 'Header', width:'25%', display: 'table-cell' },
      { field: 'messageText', header: 'Text', width:'20%', display: 'table-cell' },
      { field: 'linkUrl', header: 'Link Url', width:'14%', display: 'table-cell' },
      { field: 'createDate', header: 'Create Date', width:'10%', display: 'table-cell' },
      { field: 'deleteDate', header: 'Delete Date', width:'10%', display: 'table-cell' }
    ]; 
    // Define the columns for passregister grid
    this.passregistersCols = [
      { field: 'passRegisterId', header: 'Pass Register Id', width:'8%', display: 'table-cell' },
      { field: 'active', header: 'Active', width:'6%', display: 'table-cell' },
      { field: 'appleDeviceId', header: 'Apple Device Id', width:'15%', display: 'table-cell' },
      { field: 'pushToken', header: 'Push Token', width:'31%', display: 'table-cell' },
      { field: 'pingDate', header: 'Ping Date', width:'10%', display: 'table-cell' },
      { field: 'lastPingDate', header: 'Last Ping Date', width:'10%', display: 'table-cell' },
      { field: 'respDate', header: 'Response Date', width:'10%', display: 'table-cell' },
      { field: 'respIpAddr', header: 'Response IP Address', width:'10%', display: 'table-cell' }
    ]; 
    // Define the columns for accountEventLogCols grid
    this.accountEventLogCols = [
      { field: 'eventType', header: 'Event Type', width:'33%', display: 'table-cell' },
      { field: 'eventSource', header: 'Event Source', width:'34%', display: 'table-cell' },
      { field: 'eventDate', header: 'Event Date', width:'33%', display: 'table-cell' }
    ];
    // Define the columns for clickthrouthLog grid
    this.clickthrouthLogCols = [
      { field: 'fieldKey', header: 'Field Key', width:'15%', display: 'table-cell' },
      { field: 'clickthroughUrl', header: 'URL', width:'40%', display: 'table-cell' },
      { field: 'fieldValue', header: 'Field Value', width:'15%', display: 'table-cell' },
      { field: 'linkCategoryName', header: 'Link Category', width:'15%', display: 'table-cell' },
      { field: 'createDate', header: 'Event Date', width:'15%', display: 'table-cell' }
    ];
    // this.openBrandSelectionDialog = true;   
    
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.openBrandSelectionDialog = false;
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }  

  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  onQueryBuilderClick(event: any) {
    this.openQueryBuilderDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.sharedService.setCurrentBrand(item);
      // Clean up previous account info
      this.extAccountId = '';
      this.accountStatus = '';
      this.varValues = [];
      this.messages = [];
      this.passregisters = [];
      this.accountEventLog = [];
      this.clickthrouthLog = [];
      this.customerpasses = [];
      this.customerpass = [];
    }
  }


  onClearClick(event: any) {
    // this.baseUrl = null;
    // this.selectedBrand = null;
    this.extAccountId = '';
  }

    // Process the Search request
    onSearchClick(event: any) {
      if (!(this.currentBrand.baseUrl && this.currentBrand.brandCode && this.extAccountId)) {
        // if (!(this.baseUrl && this.selectedBrand && this.extAccountId)) {
        this.confirmationService.confirm({
          message: 'Make sure to select the Brand and the External Account Id in the selection criteria to retireve database records.',
          header: 'Warning',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: "Ok",
          rejectVisible: false,
          acceptButtonStyleClass: "p-button-info  p-button-rounded",
          accept: () => {
                return;
              }
        });          
        return;
      }
      // Start the search
      this.isAccountStatusVisibale = false;
      this.priorDisabled = true;
      this.nextDisabled = true;
      this.customerpass = [];
      this.varValues = [];
      this.messages = [];
      this.passregisters = [];
      this.accountEventLog = [];
      this.clickthrouthLog = [];
      this.accountStatus = '';

      this.accountInfoService.getAccountInfo(this.currentBrand.baseUrl, this.currentBrand.brandCode, this.extAccountId)
      .subscribe({
        next: (response) => {
          console.log('next', response);
            this.isAccountStatusVisibale = true;
            if (response == null) {
              this.accountStatus = 'NOT FOUND';
              this.customerpassHidden = true;
              this.varValues = [];
              this.confirmationService.confirm({
                message: 'No account records found for the specified selection criteria.',
                header: 'Warning',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: "Ok",
                rejectVisible: false,
                acceptButtonStyleClass: "p-button-info  p-button-rounded",
                accept: () => {
                      return;
                    }
              });          
            }
            if (response.customerPassInfoList == null) {
              this.customerpassHidden = true;
              this.varValues = [];
              // window.alert('No customerpasses found for the specified selection criteria.');
              this.accountStatus = 'ACTIVE, NO PASSES';
              this.isAccountStatusVisibale = true;
              return;
            }

            // Populate account level
            this.accountStatus = response.accountStatus ? response.accountStatus : 'ACTIVE';

            // Populate customer passs
            this.customerpasses = response.customerPassInfoList;
            this.currentCustomerpassCnt = 0;
            this.totalCustomerpassCnt = this.customerpasses.length;
            this.gotoCustomerPass();

            this.customerpassHidden = false;
            if (this.totalCustomerpassCnt > 1) {
              this.nextDisabled = false;
            }

            this.accountEventLog = response.accountEventLogList || [];
            if (response.accountClickthroughList) {
              this.clickthrouthLog = response.accountClickthroughList;
              this.isClickthroughHidden = false;
            } else {
              this.isClickthroughHidden = true;
            }
          },
          error: (error) => {
            console.log('error', error);
            this.accountStatus = 'NOT FOUND';
            this.isAccountStatusVisibale = true;
          },
          complete: () => {
          }
        });
 }

  gotoCustomerPass() {
          // Populate the grids
          var customerpass = this.customerpasses[this.currentCustomerpassCnt];
          this.customerpass = [customerpass];
          this.varValues = customerpass.vpivInfoList;
          this.messages = customerpass.messageInfoList;
          this.passregisters = customerpass.passregisterList;
  }


  onPriorCustomerpass(event: any) {
    if (this.currentCustomerpassCnt > 0) {
      this.currentCustomerpassCnt--;
      this.gotoCustomerPass();
      this.nextDisabled = false;
      if (this.currentCustomerpassCnt == 0) {
        this.priorDisabled = true;
      }
    }
  }

  onNextCustomerpass(event: any) {
    if (this.currentCustomerpassCnt < this.totalCustomerpassCnt - 1) {
      this.currentCustomerpassCnt++;
      this.gotoCustomerPass();
      this.priorDisabled = false;
      if (this.currentCustomerpassCnt == this.totalCustomerpassCnt - 1) {
        this.nextDisabled = true;
      }
    }
  }

  makeTitleMessage (currentBrand: any) {
    var title = '';
    if (currentBrand.brandCode) {
      title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) >> ';
    }
    title += 'Account Info';
    return title;
  }

  queryBuilderEvent(item: any) {
    this.openQueryBuilderDialog = false;
  }

}

