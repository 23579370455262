<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<div class="grid">
    <div class="col-7 flex-container">
        <div class="flex-child">
            <p-message severity="info" text="{{titleMessage}}"></p-message>
        </div>
        <div class="flex-child">
            <div *ngIf="isImagePreviewShown"><img class="center-block" [src]="brandImage" /></div>
        </div>
    </div>

    <div class="col-1">
       <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded" >
        </button>
    </div>  

    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
    </div>
</div> 

<div class="grid">

    <div class="col-4">

        <p-card header="CSR Account Lookup" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="accountForm" (ngSubmit)="onSubmit(accountForm.value)">
    
                <div>
                    
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="accountNumber" class="col-fixed label" style="width: 15em">Account Number:</label>
                        <div class="col">
                            <input pInputText id="accountNumber" type="text" formControlName="accountNumber" [style]="{'width': '50%'}"  
                                placeholder="Required" pTooltip="{{primaryTooltip}}" tooltipPosition="top"/> 
                            <p-message severity="error" text="{{primaryErrorText}}" *ngIf="!accountForm.controls['accountNumber'].valid&&accountForm.controls['accountNumber'].dirty"></p-message>                 
                        </div>
                    </div>
        
                    <div class="field grid" *ngIf="isSecondaryAccountIdShown">
                        <div class="col-1"></div>
                            <label for="secondaryAccountId" class="col-fixed label" style="width: 15em">{{secondaryAccountIdLabel}}</label>
                        <div class="col">
                            <input pInputText id="secondaryAccountId" type="text" formControlName="secondaryAccountId" pTooltip="{{secondaryTooltip}}" tooltipPosition="top"/>
                            <p-message severity="error" text="{{secondaryErrorText}}" *ngIf="!accountForm.controls['secondaryAccountId'].valid&&accountForm.controls['secondaryAccountId'].dirty"></p-message>                 
                        </div>
                    </div>
        
                    <div class="field grid" *ngIf="isTretiaryAccountIdShown">
                        <div class="col-1"></div>
                            <label for="tertiaryAccountId" class="col-fixed label" style="width: 15em">{{tertiaryAccountIdLabel}}</label>
                        <div class="col">
                            <input pInputText id="tertiaryAccountId" type="text" formControlName="tertiaryAccountId" pTooltip="{{tertiaryTooltip}}" tooltipPosition="top"/>
                            <p-message severity="error" text="{{tertiaryErrorText}}" *ngIf="!accountForm.controls['tertiaryAccountId'].valid&&accountForm.controls['tertiaryAccountId'].dirty"></p-message>                 
                        </div>
                    </div>

                    <!-- Customer Identity Verification fields -->
                    <div *ngFor="let evField of evFields" class="form-row">
                        <app-ev-field [evField]="evField"  [form]="accountForm"> </app-ev-field>
                    </div>
   
        
                    <div class="field grid">
                        <div class="col-1"></div>
                        <div class="row container-fluid"  id="divshow">
<!-- 
                            <button pButton type="submit" label="Submit" class="ui-button-primary" [disabled]="!accountForm.valid" ></button>
 -->
                            <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!accountForm.valid" ></p-button>

                        </div>          
                        <div class="col-9">
                            <mat-label>{{resultMsg}}</mat-label>
                        </div>
                    </div>
        
                </div>
            </form>
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
            </p-footer>

        </p-card>

        <hr/>
 
        <p-panel header="{{csrInstructHeader}}" [toggleable]="true" styleClass="ui-card" [collapsed]="false" *ngIf="isCsrInstructShown">

            <p [innerHTML]="csrInstructBody"></p>

            <div class="row ui-card-powder" escape="false">
               
            </div>

        </p-panel>

    </div>
    
    <div class="col-4">

        <p-card header="Account Details" subheader="" styleClass="ui-card-light" *ngIf="isAccountDetailsShown">
        
            <div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="accountFound" class="col-fixed label" style="width: 15em">Is Account Found?:</label>
                    <div class="col">
                        <p-checkbox [(ngModel)]="accountFound" id="accountFound" [binary]="true" [readonly]="true"></p-checkbox>
                    </div>
                </div>
    
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="accountRegistered" class="col-fixed label" style="width: 15em">Is Customer Registered?:</label>
                    <div class="col">
                        <p-checkbox [(ngModel)]="accountRegistered" id="accountRegistered" [binary]="true" [readonly]="true"></p-checkbox>
                    </div>
                </div>
    
                <div *ngFor="let accountField of accountFields" class="form-row">
                    <app-account-field [accountField]="accountField">
                    </app-account-field>
                </div>
    
                <div class="col-12" *ngIf="isDeregisterAccountShown">
<!-- 
                    <button pButton type="button" label="Deactivate moBills" icon="pi p-confirmDialog" iconPos="left"
                         (click)="onDeregisterAccount($any($event))"  class="ui-button-danger">
                     </button>
-->
                    <p-button type="button" label="Deactivate Walletron" 
                            styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onDeregisterAccount($any($event))" >
                    </p-button>

                </div> 

            </div>
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">add_a_photo</mat-icon>
            </p-footer>
        </p-card>

    </div>

    <div class="col-4" *ngIf="isSendEnrollmentLinksShown">

        <p-card header="Send Enrollment Links" subheader="" styleClass="ui-card-light" >
        
            <form [formGroup]="emailForm" (ngSubmit)="onSendEmail(emailForm.value)">

                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="customerEmailAddress" class="col-fixed label" style="width: 15em">Customer Email Address: </label>
                    <div class="col">
                        <input pInputText type="email" id="customerEmailAddress" formControlName="customerEmailAddress" 
                            validatorMessage="Value does not match pattern." pTooltip="Examle: jane.doe@gmail.com" tooltipPosition="top" 
                                [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
                        <p-message severity="error" text="Incorrect email address specified" *ngIf="!emailForm.controls['customerEmailAddress'].valid&&emailForm.controls['customerEmailAddress'].dirty"></p-message>                 
                    </div> 

                </div>
        
                <div class="field grid">
                    <div class="col-1"></div>
<!-- 
                        <button pButton type="submit" label="Send Email" class="ui-button-success" [disabled]="!emailForm.valid"></button>
 -->
                    <div class="col-3 group">
                        <p-button type="submit" label="Send Email" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!emailForm.valid" ></p-button>
                    </div>
                        
                </div>

            </form>
    
            <form [formGroup]="smsForm" (ngSubmit)="onSendSMS(smsForm.value)">

                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="customerPhoneNo" class="col-fixed label" style="width: 15em">Customer Phone No:</label>
                    <div class="col">
                        <input pInputText type="tel" id="customerPhoneNo" formControlName="customerPhoneNo" 
                            validatorMessage="Value does not match pattern."  pTooltip="Format: 000-000-0000" tooltipPosition="top" 
                                [style]="{'width': '50%'}"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>                    
                        <p-message severity="error" text="Incorrect phone number specified" *ngIf="!smsForm.controls['customerPhoneNo'].valid&&smsForm.controls['customerPhoneNo'].dirty"></p-message>                 
                    </div> 

                </div>
        
                <div class="field grid">
                    <div class="col-1"></div>
<!-- 
                        <button pButton type="submit" label="Send SMS" class="ui-button-success" [disabled]="!smsForm.valid"></button>
 -->
                    <div class="col-2 group">
                        <p-button type="submit" label="Send SMS" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!smsForm.valid" ></p-button>
                    </div>

                </div>
    
            </form>
        
            <p-footer>
                <mat-icon class="nav-caption wal-center">link</mat-icon>
            </p-footer>
        </p-card>

        <hr/>
 
        <p-panel header="{{coachingHeader}}" [toggleable]="true" styleClass="ui-card" [collapsed]="false" *ngIf="isCoachingShown">

            <p [innerHTML]="coachingBody"></p>

        </p-panel>


    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="csr-account"></p-confirmDialog>




