import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedService } from "../../services/shared.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { AdhocMessageSchedulerService} from '../../services/client/adhoc-message-scheduler.service';
import { BrandConfigPropertyService } from '../../services/config/brand-config-property.service';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { ISchedulerConfig } from '../../interfaces/scheduler-config';
import Utils from './Utils';
import * as defaultSchedule from './default-schedule.json';
import { CronEditorConfig } from '../../models/cron-editor-config';

@Component({
  selector: 'app-cron-editor',
  templateUrl: './cron-editor.component.html',
  styleUrl: './cron-editor.component.css'
})

export class CronEditorComponent {

  @Input() displayCronEditorDialog?: boolean;
  @Input('currentBrandSubject') currentBrandSubject!: Subject<any>;
  @Input ('cronEditorConfig') cronEditorConfig!: CronEditorConfig;
  @Input('adhocMsgConfigIdSubject') adhocMsgConfigIdSubject!: Subject<number>;
  @Output() setCronSchedulerEvent = new EventEmitter<string>();

  data: any = defaultSchedule;

  currentBrand!: IBrand;

  currentUser!: string;
  resultMsg = "";

  brandCode: string = '';
  baseUrl: string = '';  
  
  schedulerConfig!: ISchedulerConfig;
  adhocMsgConfigId!: number;

  specificSecondOptions: any[] = [];
  specificSecondRows: Array<any> = [];

  specificMinuteOptions: any[] = [];
  specificMinuteRows: Array<any> = [];

  specificHourOptions: any[] = [];
  specificHourRows: Array<any> = [];

  specificMonthOptions: any[] = [];
  specificMonthRows: Array<any> = [];

  specificYearOptions: any[] = [];
  specificYearRows: Array<any> = [];

  specificDayOfWeekOptions: any[] = [];
  specificDayOfWeekRows: Array<any> = [];
  specificDayOfMonthOptions: any[] = [];
  specificDayOfMonthRows: Array<any> = [];

  public selectOptions: any = {};  // this.getSelectOptions();

  // hintMsg: string = 'Hint! Specify schedule configuration in the UTC time zone.  For example: '
  // + 'if you want to schedule for 10:00 AM EDT, then configure for 14:00 during daylight saving time, otherwise for 15:00.';
  hintMsg!: string;
  defaultErrorMessage: string = "Error processing the request.  Please report to the Walletron Support team.";

  constructor(
    // private formBuilder: FormBuilder,
    private tokenStorageService: TokenStorageService,
    private sharedService: SharedService,
    private adhocMessageSchedulerService: AdhocMessageSchedulerService,
    private brandConfigPropertyService: BrandConfigPropertyService,
    private confirmationService: ConfirmationService
  ) {
      let rawJson: string = JSON.stringify(this.data.default);
      this.schedulerConfig = JSON.parse(rawJson, (key, val) => typeof val === 'number' && key.includes('specific') ? BigInt(val) : val); 
    // this.schedulerConfig = this.data.default;
  }  

  ngOnInit() {
    this.currentUser = this.tokenStorageService.getCurrentUser().userName;
    // Recieve the current brand from the parent component
    this.currentBrand = this.sharedService.getCurrentBrand()
    if (this.currentBrand) {
      this.setCurrentBrand(this.currentBrand);
    }
    this.currentBrandSubject.subscribe((item: IBrand) => {
      this.setCurrentBrand(item);
    });
    // Recieve the adhocMsgConfigId from the parent component
    this.adhocMsgConfigIdSubject.subscribe((item: number) => {
      // console.log('scheduler - onInit - brandCode', this.brandCode);
      console.log('scheduler - onInit - adhocMsgConfigId', item);
      this.adhocMsgConfigId = item;
      if (this.currentBrand && this.adhocMsgConfigId > 0) {
          this.getSchedulerConfig(this.brandCode, Number(this.adhocMsgConfigId), this.baseUrl);
      } else if (this.currentBrand && this.adhocMsgConfigId == 0) {
        let rawJson: string = JSON.stringify(this.data.default);
        this.schedulerConfig = JSON.parse(rawJson, (key, val) => typeof val === 'number' && key.includes('specific') ? BigInt(val) : val);
        this.selectOptions = this.getSelectOptions(
          this.schedulerConfig.yearsConfig.baseYear, this.schedulerConfig.yearsConfig.lastSupportedYear, this.cronEditorConfig.getMinuteStep());
        this.buildSpecificIntervals();
      }
    });
  }  

  private setCurrentBrand(brand: IBrand) {
    if (brand) {
      this.currentBrand = brand;
      this.brandCode = brand.brandCode;
      this.baseUrl = brand.baseUrl;
      this.getClientTimeZone(this.brandCode, this.baseUrl)
    } else {
      this.alertTheUser('No current brand determined.  Please select the current brand.');
    }
  }
   
  private getClientTimeZone(brandCode: string, baseUrl: string) {
    this.brandConfigPropertyService.getBrandConfigPropertyByName(brandCode, "CLIENT_TIMEZONE", baseUrl || '')
    .subscribe({
      next: (response) => {
        let clientTimeZone = response.propertyValue;
        this.hintMsg = 'Hint! Specify schedule configuration in the ' + clientTimeZone + ' time zone';
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // Retrieve existing, if found,  or use default schedule configuration
  private getSchedulerConfig(brandCode: string, adhocMsgConfigId: number, baseUrl: string) {
    this.adhocMessageSchedulerService.getScheduleConfig(brandCode, adhocMsgConfigId, baseUrl || '')
    .subscribe({
      next: (response) => {
        console.log('getSchedulerConfig',response);
        let rawJson: string;
        if (response) {
          rawJson= JSON.stringify(response);
        } else {
          rawJson = JSON.stringify(this.data.default);
        }
        this.schedulerConfig = JSON.parse(rawJson, (key, val) => typeof val === 'number' && key.includes('specific') ? BigInt(val) : val); 
        this.selectOptions = this.getSelectOptions(
          this.schedulerConfig.yearsConfig.baseYear, this.schedulerConfig.yearsConfig.lastSupportedYear, this.cronEditorConfig.getMinuteStep());
        this.buildSpecificIntervals();
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }
  
  // Prepare arrays for specific intervals
  private buildSpecificIntervals() {
    this.specificSecondOptions = [];
    this.specificMinuteOptions = [];
    this.specificHourOptions = [];
    this.specificMonthOptions = [];
    this.specificYearOptions = [];
    this.specificDayOfWeekOptions = [];
    this.specificDayOfMonthOptions = [];

    this.selectOptions.tagSeconds.forEach((item: any) => {
      this.specificSecondOptions.push(
        {name: item.tag, value: item.value, checked: Utils.isSpecificIntervalSet(this.schedulerConfig.secondsConfig.specificSeconds, item.value)}
      );
    })
    this.specificSecondRows = Array.from(Array(Math.ceil(this.selectOptions.tagSeconds.length / 10)).keys());

    this.selectOptions.tagMinutes.forEach((item: any) => {
      this.specificMinuteOptions.push(
        {name: item.tag, value: item.value, checked: Utils.isSpecificIntervalSet(this.schedulerConfig.minutesConfig.specificMinutes, item.value)}
      );
    })
    this.specificMinuteRows = Array.from(Array(Math.ceil(this.selectOptions.tagMinutes.length / 10)).keys());
    // console.log('specificMinuteOptions', this.specificMinuteOptions);

    this.selectOptions.tagHours.forEach((item: any) => {
      this.specificHourOptions.push(
        {name: item.tag, value: item.value, checked: Utils.isSpecificIntervalSet(this.schedulerConfig.hoursConfig.specificHours, item.value)}
      );
    })
    this.specificHourRows = Array.from(Array(Math.ceil(this.selectOptions.tagHours.length / 10)).keys());

    this.selectOptions.tagMonths.forEach((item: any) => {
      this.specificMonthOptions.push(
        {name: item.tag, value: item.value, checked: Utils.isSpecificIntervalSet(this.schedulerConfig.monthsConfig.specificMonths, item.value)}
      );
    })
    this.specificMonthRows = Array.from(Array(Math.ceil(this.selectOptions.tagMonths.length / 12)).keys());

    this.selectOptions.years.forEach((item: any) => {
      this.specificYearOptions.push(
        {name: item, value: item, checked: Utils.isSpecificIntervalSet(
          this.schedulerConfig.yearsConfig.specificYears, item - this.schedulerConfig.yearsConfig.baseYear)}
      );
    })
    this.specificYearRows = Array.from(Array(Math.ceil(this.selectOptions.years.length / 5)).keys());

    this.selectOptions.weekDays.forEach((item: any) => {
      this.specificDayOfWeekOptions.push(
        {name: item.dayName, value: item.dayNum, checked: Utils.isSpecificIntervalSet(this.schedulerConfig.daysConfig.specificDaysOfWeek, Number(item.dayNum))}
      );
    })
    this.specificDayOfWeekRows = Array.from(Array(Math.ceil(this.selectOptions.weekDays.length / 7)).keys());    

    this.selectOptions.atDays.forEach((item: any) => {
      this.specificDayOfMonthOptions.push(
        {name: item, value: item, checked: Utils.isSpecificIntervalSet(this.schedulerConfig.daysConfig.specificDaysOfMonth, Number(item))}
      );
    })
    this.specificDayOfMonthRows = Array.from(Array(Math.ceil(this.selectOptions.atDays.length / 10)).keys());    
  }

  // When the user cancels the dialog for building a cron schedule
  onCancelCronEditor() {
    this.setCronSchedulerEvent.emit();
  }

  // When the user finishes up building a schedule
  onSubmitCronEditor() {
    this.schedulerConfig.secondsConfig.specificSeconds = 
      Utils.setSpecificOptions(this.schedulerConfig.secondsConfig.specificSeconds, this.specificSecondOptions);
    this.schedulerConfig.minutesConfig.specificMinutes = 
      Utils.setSpecificOptions(this.schedulerConfig.minutesConfig.specificMinutes, this.specificMinuteOptions);
    this.schedulerConfig.hoursConfig.specificHours = 
      Utils.setSpecificOptions(this.schedulerConfig.hoursConfig.specificHours, this.specificHourOptions);
    this.schedulerConfig.daysConfig.specificDaysOfMonth = 
      Utils.setSpecificOptions(this.schedulerConfig.daysConfig.specificDaysOfMonth, this.specificDayOfMonthOptions);
    this.schedulerConfig.daysConfig.specificDaysOfWeek = 
      Utils.setSpecificOptions(this.schedulerConfig.daysConfig.specificDaysOfWeek, this.specificDayOfWeekOptions);      
    this.schedulerConfig.monthsConfig.specificMonths = 
      Utils.setSpecificOptions(this.schedulerConfig.monthsConfig.specificMonths, this.specificMonthOptions);
    this.schedulerConfig.yearsConfig.specificYears = 
      Utils.setBasedSpecificOptions(this.schedulerConfig.yearsConfig.specificYears, this.specificYearOptions, 
        this.schedulerConfig.yearsConfig.baseYear);

    let rawJson: string = JSON.stringify(this.schedulerConfig, (key, val) => typeof val === 'bigint' ? Number(val) : val);
    this.setCronSchedulerEvent.emit(rawJson);

    // this.adhocMessageSchedulerService.addScheduleConfig(this.brandCode, this.adhocMsgConfigId, this.schedulerConfig, this.baseUrl || '')
    // .subscribe({
    //   next: (response) => {
    //     this.selectCronEditorEvent.emit();
    //   },
    //   error: (error) => {
    //     // this.alertTheUser(error.message);
    //   },
    //   complete: () => {
    //   }
    // });
  }

  // For removing a scheduled event from configuration
  onDeleteCronEditor() {
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the scheduler configuration from the targeted communication configuration?',
      header: 'Confirmation',
      key: 'cron-editor',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
      //   this.adhocMessageSchedulerService.deleteScheduleConfig(this.brandCode, this.adhocMsgConfigId, this.baseUrl || '')
      //   .subscribe({
      //     next: (response) => {
      //       this.selectCronEditorEvent.emit();
      //     },
      //     error: (error) => {
      //       // this.alertTheUser(error.message);
      //     },
      //     complete: () => {
      //     }
      //   });
        this.setCronSchedulerEvent.emit('');
      },
      reject: () => {
      }
    });
  }

  private alertTheUser(message: any) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'cron-editor',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  private getSelectOptions(baseYear: number, lastSupportedYear: number, minuteStep: number) {
    return {
      years: Utils.getRange(baseYear, lastSupportedYear),
      months: Utils.getRange(1, 12),
      tagMonths: Utils.getTagMonths(),
      atDays: Utils.getPaddedRange(1, 31),
      weekDays: Utils.getWeekDays(),
      orderedDays: Utils.getOrderedDays(),
      days: Utils.getRange(1, 7),
      allDays: Utils.getRange(1, 31),
      minutes: Utils.getRange(0, 59),
      tagMinutes: Utils.getTagIntervalsWithStep(0, 59, minuteStep),
      tagSeconds: Utils.getTagIntervals(0, 59),
      seconds: Utils.getRange(0, 59),
      hours: Utils.getRange(1, 24),
      tagHours: Utils.getTagIntervals(0, 23),
      monthDays: Utils.getRange(1, 31)
    };
  }

}

