<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <p-message severity="info" text="{{titleMessage}}"></p-message>
    </div>
   <div class="col-1">
       <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded" >
        </button>
   </div>  
</div> 

<!-- Dropdowns for selecting Pass Tempalte and Offer -->

<div class="grid pt-5">

    <div class="col-5 div-criteria-two"> 

        <div class="field grid">
            <div class="col-1"></div>
            <label for="passTemplate" class="col-fixed label" style="width: 15em">Pass Template: </label>
            <div class="col">
                <p-dropdown id ="passTemplate" [options]="passTemplates" [(ngModel)]="selectedPassTemplate" (onChange)="onChangePassTemplate($event)"
                    [filter]="false" [editable]="false" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div> 

        <div class="field grid">
            <div class="col-1"></div>
            <label for="offer" class="col-fixed label" style="width: 15em">Field Set: </label>
            <div class="col">
                <p-dropdown id ="offer" [options]="offers" [(ngModel)]="selectedOffer" (onChange)="onChangeOffer($event)"
                    [filter]="false" [editable]="false" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div> 

        <div class="field grid">
            <div class="col-1"></div>
            <label for="emailSmsTemplate" class="col-fixed label" style="width: 15em">Email/SMS Template: </label>
            <div class="col">
                <p-dropdown id ="emailSmsTemplate" [options]="emailSmsTemplates" [(ngModel)]="selectedEmailSmsTemplate" (onChange)="onChangeEmailSmsTemplate($event)"
                    [filter]="false" [editable]="false" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div> 

    </div>

</div> 

<!-- Override (offer) fields -->

<div class="col-12">
    <div class="col-4 ui-card-light" *ngIf="isOfferFieldsShown" >

        <p-table #dtt [columns]="offerFieldsCols" [value]="offerFields" selectionMode="single" 
            tableStyleClass="prime-table" sortMode="multiple" [paginator]="true" [rows]="12">
            <ng-template pTemplate="caption">
                Override Fields
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}" >
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <!-- <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="rowData.offerFieldId">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.offerFieldId}}
                            </ng-template>
                        </p-cellEditor>
                    </td> -->
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="rowData.fieldKey">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.fieldKey}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <!-- <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="rowData.fieldType">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.fieldType}}
                            </ng-template>
                        </p-cellEditor>
                    </td> -->
                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="rowData.fieldValue">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{rowData.fieldValue}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>
    
        </p-table> 
    
    </div>

</div> 

<div class="grid" *ngIf="isEmailSmsAreaShown">

    <div class="col-5 div-criteria-two"> 

        <div class="field grid" *ngIf="!isSmsAreaShown">
            <div class="col-1"></div>
                <label for="controlEmailRecipient" class="col-fixed label" style="width: 15em">Control Email Recipient: </label>
            <div class="col-5">
                <input pInputText type="email" id="controlEmailRecipient" [(ngModel)]="controlEmailRecipient" 
                    validatorMessage="Value does not match pattern."
                        [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
            </div> 
        </div> 

        <div class="field grid" *ngIf="!isSmsAreaShown">
            <div class="col-1"></div>
                <label for="emailAddress" class="col-fixed label" style="width: 15em">Primary Email Recipient: </label>
            <div class="col-5">
                <input pInputText type="email" id="emailAddress" [(ngModel)]="emailAddress" 
                    validatorMessage="Value does not match pattern."
                        [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
            </div> 
            <div class="col-3">
<!-- 
                <button pButton type="button" label="Send Email" class="ui-button-success" (click)="onSendEmail($event)"></button>
 -->
                <p-button type="button" label="Send Email" (click)="onSendEmail($event)"
                    styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                </p-button>
                
            </div> 
        </div> 

        <div class="field grid" *ngIf="!isSmsAreaShown">
            <div class="col-1"></div>
                <label for="otherRecipients" class="col-fixed label" style="width: 15em">Other Recipients (comma separated): </label>
            <div class="col-5">
                <textarea [rows]="2" [cols]="60" pInputTextarea [autoResize]="autoResize" id="otherRecipients" 
                    [(ngModel)]="otherRecipients" style="width: 100%;"></textarea>
            </div> 
        </div> 

        <div class="field grid" *ngIf="isSmsAreaShown">
            <div class="col-1"></div>
                <label for="phoneNum" class="col-fixed label" style="width: 15em">Recipient's Phone No: </label>
            <div class="col-5">
                <p-inputMask id="phoneNum" [(ngModel)]="phoneNum" mask="+19999999999"></p-inputMask>
            </div> 
            <div class="col-3">
<!-- 
                <button pButton type="button" label="Send SMS" class="ui-button-success" (click)="onSendSms($event)"></button>
 -->
                <p-button type="button" label="Send SMS" (click)="onSendSms($event)"
                    styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                </p-button>

            </div> 
        </div> 

    </div>
</div> 


<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="enrollment-invitation"></p-confirmDialog>