<h2>Walletron Portal Dashboard</h2> 
<!-- 

<div class="grid" id="flexbox"> 

  <div class="col-2" (click)="onJobMonitoringClick()"
       *ngIf="hasAllPrivileges(['PRIV_VP_JOB_MONITOR_VIEW','PRIV_VP_EVENT_OCCURANCE_VIEW'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">track_changes</mat-icon>
      <h3>Job Monitoring</h3>
      <p>Monitor occurrences of scheduled events and execution of various jobs, such as receiving new data, refreshing customers passes, and sending out notifications.</p>
    </div>
  </div>

  <div class="col-2" (click)="onCsrClick()" *ngIf="hasPrivilege('PRIV_VP_ACCT_CSR')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">headset_mic</mat-icon>
      <h3>CSR</h3>
      <p>Customer Service Representative verifies account validity and sends out an enrollment invitation to the customer as an eMail or SMS.</p>
    </div>
  </div>

   <div class="col-2" (click)="onUserAdministrationClick()" *ngIf="hasAnyPrivilege(['PRIV_WA_USER_MODIFY','PRIV_WA_USER_VIEW'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">perm_identity</mat-icon>
      <h3>User Administration</h3>
      <p>Creating and modifying user accounts, including granting security roles and domain of brands, resetting passwords and attributes.</p>
    </div>
  </div>

  <div class="col-2" (click)="onClientDashboardClick()" *ngIf="hasPrivilege('PRIV_VP_RTPN_VIEWER')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">dashboard</mat-icon>
      <h3>Client Dashboard</h3>
      <p>Real time customer enrollment, CSR, and other client statistical data.</p>
    </div>
  </div>

  <div class="col-2" (click)="onRtpnViewerClick()" *ngIf="hasPrivilege('PRIV_VP_RTPN_VIEWER')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">money</mat-icon>
      <h3>RTPN Viewer</h3>
      <p>Retrieve RTPN requests (cash transactions) based on the specified selection criteria.</p>
    </div>
  </div>

  <div class="col-2" (click)="onAccountInfoClick()" *ngIf="hasPrivilege('PRIV_WAL_ACCOUNT_INFO')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">location_city</mat-icon>
      <h3>Account Info</h3>
      <p>All data for the given customer's account, such as pass registrations, notifications, clickthroughs, and variable values.</p>
    </div>
  </div>

  <div class="col-2" (click)="onEnrollmentReportClick()" *ngIf="hasPrivilege('PRIV_VP_STATS')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">group_add</mat-icon>
      <h3>Enrollment Reports</h3>
      <p>Real-time active accounts statistics for all granted brands (clients/billers), including for the last 30-day period. </p>  
    </div>
  </div>

  <div class="col-2" (click)="onBrandListingClick()" *ngIf="hasAllPrivileges(['PRIV_WP_BRAND_VIEW','PRIV_WR_ADHOC_RPT'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">card_membership</mat-icon>
      <h3>Brand Listing</h3>
      <p>The list of granted brands (clients/biller), including their environmental regions and base URLs.</p>
    </div>
  </div>

  <div class="col-2" (click)="onBrandBootstrappingClick()" *ngIf="hasPrivilege('PRIV_VP_BRAND_BOOTSTRAP')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">palette</mat-icon>
      <h3>Brand Bootstrapping</h3>
      <p>Bootstrap a new brand based on the selected vertical template to generate pass and message templates, field sets, variables, etc.</p>
    </div>
  </div> 

  <div class="col-2" (click)="onBrandFieldSetsClick()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_OFFER_MODIFY','PRIV_WAL_BRAND_CONFIG_OFFER_VIEW'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">format_list_numbered_rtl</mat-icon>
      <h3>Brand Field Sets</h3>
      <p>Maintenance of different brand level field sets.  The fields are used to configure digital passes, their type, look, location, functionality and value.</p>
    </div>
  </div> 

  <div class="col-2" (click)="onMyAccountClick()" *ngIf="hasPrivilege('PRIV_PORTAL_USER_PROFILE')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">person</mat-icon>
      <h3>My Account</h3>
      <p>Ability to reset user's own email address and login password.  A list of previously generated data extract files for downloading them.</p>
    </div>
  </div> 

</div>

 -->



<div class="grid" id="flexbox"> 

  <div class="col-2" (click)="onJobMonitoringClick()"
       *ngIf="hasAllPrivileges(['PRIV_VP_JOB_MONITOR_VIEW','PRIV_VP_EVENT_OCCURANCE_VIEW'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">track_changes</mat-icon>
      <h3>Job Monitoring</h3>
      <p>Monitor occurrences of scheduled events and execution of various jobs, such as receiving new data, refreshing customers passes, and sending out notifications.</p>
    </div>
  </div>

  <div class="col-2" (click)="onCsrClick()" *ngIf="hasPrivilege('PRIV_VP_ACCT_CSR')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">tablet_android</mat-icon>
      <h3>CSR</h3>
      <p>Customer Service Representative verifies account validity and sends out an enrollment invitation to the customer as an eMail or SMS.</p>
    </div>
  </div>

   <div class="col-2" (click)="onUserAdministrationClick()" *ngIf="hasAnyPrivilege(['PRIV_WA_USER_MODIFY','PRIV_WA_USER_VIEW'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">perm_identity</mat-icon>
      <h3>User Administration</h3>
      <p>Creating and modifying user accounts, including granting security roles and domain of brands, resetting passwords and attributes.</p>
    </div>
  </div>

  <div class="col-2" (click)="onClientDashboardClick()" *ngIf="hasPrivilege('PRIV_WR_STATS')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">dashboard</mat-icon>
      <h3>Client Dashboard</h3>
      <p>Real time customer enrollment, CSR, and other client statistical data.</p>
    </div>
  </div>

  <div class="col-2" (click)="onRtpnViewerClick()" *ngIf="hasPrivilege('PRIV_VP_RTPN_VIEWER')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">money</mat-icon>
      <h3>RTPN Viewer</h3>
      <p>Retrieve RTPN requests (cash transactions) based on the specified selection criteria.</p>
    </div>
  </div>

  <div class="col-2" (click)="onAccountInfoClick()" *ngIf="hasPrivilege('PRIV_WAL_ACCOUNT_INFO')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">location_city</mat-icon>
      <h3>Account Info</h3>
      <p>All data for the given customer's account, such as pass registrations, notifications, clickthroughs, and variable values.</p>
    </div>
  </div>

  <div class="col-2" (click)="onEnrollmentReportClick()" *ngIf="hasPrivilege('PRIV_VP_STATS')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">group_add</mat-icon>
      <h3>Enrollment Reports</h3>
      <p>Real-time active accounts statistics for all granted brands (clients/billers), including for the last 30-day period. </p>  
    </div>
  </div>

  <div class="col-2" (click)="onBrandListingClick()" *ngIf="hasAllPrivileges(['PRIV_WP_BRAND_VIEW','PRIV_WR_ADHOC_RPT'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">card_membership</mat-icon>
      <h3>Brand Listing</h3>
      <p>The list of granted brands (clients/biller), including their environmental regions and base URLs.</p>
    </div>
  </div>

  <div class="col-2" (click)="onBrandBootstrappingClick()" *ngIf="hasPrivilege('PRIV_VP_BRAND_BOOTSTRAP')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">palette</mat-icon>
      <h3>Brand Bootstrapping</h3>
      <p>Bootstrap a new brand based on the selected vertical template to generate pass and message templates, field sets, variables, etc.</p>
    </div>
  </div> 

  <div class="col-2" (click)="onBrandFieldSetsClick()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_OFFER_MODIFY','PRIV_WAL_BRAND_CONFIG_OFFER_VIEW'])">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">format_list_numbered_rtl</mat-icon>
      <h3>Brand Field Sets</h3>
      <p>Maintenance of different brand level field sets.  The fields are used to configure digital passes, their type, look, location, functionality and value.</p>
    </div>
  </div> 

  <div class="col-2" (click)="onMyAccountClick()" *ngIf="hasPrivilege('PRIV_PORTAL_USER_PROFILE')">
    <div class="important-note-box box-style clickable" id ="fixed-height">
      <mat-icon color="accent">person</mat-icon>
      <h3>My Account</h3>
      <p>Ability to reset user's own email address and login password.  A list of previously generated data extract files for downloading them.</p>
    </div>
  </div> 

</div>









