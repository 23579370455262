<app-choose-brand 
    [displayBrandDialog] = "openBrandSelectionDialog" 
    (chooseBrandEvent)="chooseBrand($event)">
</app-choose-brand>

<app-filter-builder 
    [displayFilterBuilderDialog] = "openFilterBuilderDialog" 
    [currentBrandSubject]="currentBrandSubject"
    (selectFilterConfigEvent)="selectFilterConfig($event)">
</app-filter-builder>

<app-adhoc-message-template
    [displayMessageTemplateDialog] = "openMessageTemplateDialog" 
    [currentBrandSubject]="currentBrandSubject"
    (selectMessageTemplateEvent)="selectMessageTemplate($event)">
</app-adhoc-message-template>


<div class="grid">
    <div class="col-7 flex-container">
        <div class="flex-child">
            <p-message severity="info" text="{{titleMessage}}"></p-message>
        </div>
        <div class="flex-child">
            <div *ngIf="isImagePreviewShown"><img class="center-block" [src]="brandImage" /></div>
        </div>
    </div>

    <div class="col-1">
       <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded" >
        </button>
    </div> 
</div> 


<div class="grid">

    <div class="col-6">

        <p-card header="Ad Hoc Notification" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="adhocNotifForm" (ngSubmit)="onSubmit(adhocNotifForm.value)">
    
                <div class="ui-card-border">

                    <div class="field grid">
                            <label for="filterConfigId" class="col-fixed label" style="width: 15em">Filter Configuration:</label>
                        <div class="col">
                            <input pInputText id="filterName" formControlName="filterName"  [style]="{'width': '80%'}"  readonly="true"
                                pTooltip="Select the filter configuration" tooltipPosition="top" />                    
                            <p-message severity="error" text="Filter configuration is required" *ngIf="!adhocNotifForm.controls['filterName'].valid && adhocNotifForm.controls['filterName'].dirty"></p-message>                 
                        </div>
                        <div class="col-1"></div>
                        <p-button type="button" label="Select Filter" styleClass="p-button-success p-button-raised p-button-rounded " (click)="onSelectFilterClick()"></p-button>
                    </div>                  

                    <div class="field grid">
                            <label for="filterLogic" class="col-fixed label" style="width: 15em">Filter Logic:</label>
                        <div class="col">
                            <textarea pInputTextarea id="filterLogic" style="width: 100%;" [rows]="2" [cols]="36" readonly="true"
                                pTooltip="The business logic of the selected filter configuration" tooltipPosition="top" 
                                formControlName="filterLogic" > 
                            </textarea>
                        </div>
                    </div>

                </div>

                <hr>

                <div class="ui-card-border">

                    <div class="field grid">
                            <label for="messageTemplateName" class="col-fixed label" style="width: 15em">Message Template Name:</label>
                        <div class="col">
                            <input pInputText id="messageTemplateName" formControlName="messageTemplateName"  [style]="{'width': '75%'}"  readonly="true"
                                pTooltip="Message template name" tooltipPosition="top" readonly="true"/>                    
                            <p-message severity="error" text="Message template name is required" *ngIf="!adhocNotifForm.controls['messageTemplateName'].valid && adhocNotifForm.controls['messageTemplateName'].dirty"></p-message>                 
                        </div>
                        <div class="col-1"></div>
                        <p-button type="button" label="Select Message Template" styleClass="p-button-success p-button-raised p-button-rounded " (click)="onSelectMessageTemplateClick()"></p-button>
                    </div>

                    <div class="field grid">
                            <label for="messageTemplateHeader" class="col-fixed label" style="width: 15em">Message Template Header:</label>
                        <div class="col">
                            <textarea pInputTextarea id="messageTemplateHeader" style="width: 100%;" [rows]="2" [cols]="36" 
                                pTooltip="The text of the message template header" tooltipPosition="top" readonly="true"
                                formControlName="messageTemplateHeader" > 
                            </textarea>
                            <p-message severity="error" text="Message template header is required" *ngIf="!adhocNotifForm.controls['messageTemplateHeader'].valid && adhocNotifForm.controls['messageTemplateHeader'].dirty"></p-message>                 
                        </div>
                    </div>

                    <div class="field grid">
                            <label for="messageTemplateText" class="col-fixed label" style="width: 15em">Message Template Text:</label>
                        <div class="col">
                            <textarea pInputTextarea id="messageTemplateText" style="width: 100%;" [rows]="2" [cols]="36" 
                                pTooltip="The text of the message template text" tooltipPosition="top" readonly="true"
                                formControlName="messageTemplateText" > 
                            </textarea>
                            <p-message severity="error" text="Message template text is required" *ngIf="!adhocNotifForm.controls['messageTemplateText'].valid && adhocNotifForm.controls['messageTemplateText'].dirty"></p-message>                 
                        </div>
                    </div>

                </div>

                <div class="col-12">
                    <div  class="col-2" class="row container-fluid"  id="divshow">
                        <p-button type="submit" label="Submit Notification" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!adhocNotifForm.valid" ></p-button>
                        <p-button type="button" label="Reset" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onResetAdhocNotification()"></p-button>
                    </div> 
                    <div class="col-10">
                        <mat-label>{{resultMsg}}</mat-label>
                    </div>
                </div>                

            </form>

            <p-footer>
                <mat-icon class="nav-caption wal-center">system_update</mat-icon>
            </p-footer>

        </p-card>

    </div>

</div>
