import { Component, OnInit, ViewChild } from '@angular/core';
import { SummaryReportsService } from "../../services/reports/summary-reports.service";
import { ConfirmationService } from "primeng/api";
import { SharedService } from "../../services/shared.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { PortalUserService } from "../../services/admin/portal-user.service";
import { BrandConfigPropertyService } from '../../services/config/brand-config-property.service';
import { IBrand } from '../../interfaces/brand';

@Component({
  selector: 'app-summary-reports',
  templateUrl: './summary-reports.component.html',
  styleUrls: ['./summary-reports.component.css']
})
export class SummaryReportsComponent implements OnInit {

  openBrandSelectionDialog!: boolean;
  defaultDate: any;
  brandName!: string;
  selectedReportType!: string;
  customDatesHidden!: boolean;

  reportTypes: any[] = [];
  baseUrl!: string;
  selectedBrandCode!: string;
  selectedBrandName!: string;
  selectedDateFrom!: string;
  selectedDateTo!: string;
  emailAddress!: string;
  brandTimeZone: string = "";

  autoResize: boolean = true;

  constructor( 
    private tokenStorageService: TokenStorageService,
    private summaryReportsService: SummaryReportsService, 
    private confirmationService: ConfirmationService,
    private portalUserService: PortalUserService,
    private brandConfigPropertyService: BrandConfigPropertyService, 
    private sharedService: SharedService) { }

  ngOnInit(): void {
      this.reportTypes = [
        {label: "Daily", value: "REPORT_DAILY"}, {label: "Weekly", value: "REPORT_WEEKLY"}, 
        {label: "Monthly", value: "REPORT_MONTHLY"}, {label: "Custom Dates", value: "REPORT_CUSTOM"}];
      this.selectedReportType = "REPORT_DAILY";
      this.customDatesHidden = true;
      this.selectedDateFrom = this.sharedService.getCurrentDate();
      this.selectedDateTo = this.sharedService.getCurrentDate();

      // Prepopulate the email address input box
      var currentUserValue = this.tokenStorageService.getCurrentUser();
      if (currentUserValue != null) {
        var userName = currentUserValue.userName;
        this.portalUserService.getUserEmailAddress(userName)
        .subscribe(result => {
            this.emailAddress = result; 
        });
      }

      this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.selectedBrandCode = item.brandCode || '';
      this.selectedBrandName = " ( " + item.brandName + " )";
      this.baseUrl = item.baseUrl || '';
      // Get brand config properties to see whether there is timezone there
      this.brandTimeZone = "America/New_York";
      this.brandConfigPropertyService.getBrandConfigProperties(this.baseUrl, this.selectedBrandCode)
      .subscribe (response => {
        var properties = response.brandConfigPropertyList;
        for (var i = 0; i < properties.length; i++) {
          if (properties[i].propertyName == "REPORT_TimezoneID") {
            this.brandTimeZone = properties[i].propertyValue;
          }
        }
      });
    }
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  onClearClick(event: any) {
    this.selectedReportType == 'REPORT_DAILY';
    this.baseUrl = '';
    this.selectedBrandCode = '';
    this.selectedBrandName = '';
    this.selectedDateFrom = '';
    this.selectedDateTo = '';
    this.brandTimeZone = '';
  }

  // Process the Search request
  onSearchClick(event: any) {
    if (!(this.baseUrl && this.selectedBrandCode && (
          this.selectedReportType != "REPORT_CUSTOM" || (this.selectedReportType == "REPORT_CUSTOM" && this.selectedDateFrom && this.selectedDateTo)))) {
      this.confirmationService.confirm({
        message: 'Make sure to select report type, brand, and (if needed) the date interval to properly define report requirements.',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });          
      return;
    }  

    this.summaryReportsService.runSummaryReport(
      this.selectedReportType, this.baseUrl, this.selectedBrandCode, this.selectedDateFrom, this.selectedDateTo, 
      this.emailAddress, this.brandTimeZone)
    .subscribe(results => {
      this.confirmationService.confirm({
        message: results.message,
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });          
    });
  }

  onChangeReportType(event: any) {
    if (this.selectedReportType == 'REPORT_CUSTOM') {
      this.customDatesHidden = false;
    } else {
      this.customDatesHidden = true;
    }
  }

}
