import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Router } from '@angular/router'; 
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { ConfirmationService } from "primeng/api";

import { TokenStorageService } from '../services/safeguard/token-storage.service';
import { AuthService } from '../services/safeguard/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);  

  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService, 
    private confirmationService: ConfirmationService,
    private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // For those requests that do not use token based authentication or when logging-in
    // console.log('authIntercept: ' + req.url);
    if (req.url.includes('/auth/api/token')
       || req.url.includes('/auth/api/refresh-token')
       || req.url.includes('assets/config')
       // || req.url.toLowerCase().includes('/walletron/api')
      // || req.url.includes('/portal/api/')
      // || req.url.includes('/communication/api') 
      // || req.url.includes('reports/api') 
      // || req.url.includes('scheduler/api')
      ) {
      return next.handle(req);
    }

    let authReq = req;
    let authToken = this.tokenStorageService.getAuthToken();
    // console.log('authIntercept authToken: ' + authToken);
    if (authToken != null) {
      authReq = this.addTokenHeader(req, authToken);
    }
    return next.handle(authReq)
    .pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401
         && (error.error.message.includes('Token expired') || error.error.message.includes('__AUTH_TOKEN_EXPIRED__'))) {
        return this.handle401Error(authReq, next);
      }
      // console.log('authIntercept: ', error.status + ' ' + error.error.message);
      // // return catchError(this.errorProcessorService.handleError);
      //   this.confirmationService.confirm({
      //     message: error.error.message,
      //     key: "portal-user",   
      //     header: 'Exception Condition',
      //     icon: 'pi pi-exclamation-triangle',
      //     acceptLabel: "Ok",
      //     rejectVisible: false,
      //     acceptButtonStyleClass: "p-button-info  p-button-rounded",
      //     accept: () => {
      //           return;
      //         }
      //   });
      return throwError(error);
    }));
  }
  
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      // console.log('authIntercept: ', 'handle401Error' + ' isRefreshing becomes true');
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const userName = this.tokenStorageService.getCurrentUser().userName;
      const refreshToken = this.tokenStorageService.getRefreshToken();

      // console.log('authIntercept: ', 'handle401Error' + ' isRefreshing is already true');
      if (refreshToken)
        return this.authService.getRefreshedAuthToken(userName, refreshToken)
        .pipe(
          switchMap((authTokenResponse: any) => {
            this.isRefreshing = false;
            let authToken = authTokenResponse.accessToken;
            let refreshToken = authTokenResponse.refreshToken;
            this.tokenStorageService.setAuthToken(authToken);
            this.tokenStorageService.setRefreshToken(refreshToken);
            this.refreshTokenSubject.next(refreshToken);
            this.refreshTokenSubject.next(authToken);
            return next.handle(this.addTokenHeader(request, authToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.tokenStorageService.signOut();
            this.confirmationService.confirm({
              message: 'Your Walletron Portal session has expired.  Please login back into the web site, if needed.',
              header: 'Idle Session',
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: "Yes",
              rejectVisible: false,
              acceptButtonStyleClass: "p-button-info  p-button-rounded",
              accept: () => {
              },
              reject: () => {
              }
            });

            // make the actual logout
            this.tokenStorageService.signOut();
            this.router.navigate(['/login']);
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }

}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];