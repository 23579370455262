<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-7">
        <p-message severity="info" text="{{titleMessage}}"></p-message>
    </div>
   <div class="col-1">
       <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
            class="p-button-raised p-button-rounded">
        </button>
   </div>  
</div> 

<div class="col-5">

    <p-card header="Customer Account" subheader="" styleClass="ui-card-light" >

        <div class="grid">   
            
            <div class="col-6">

                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="extAccountId" class="col-fixed label" style="width: 7em">Account Id:</label>
                    <div class="col-2">
                        <input pInputText id="extAccountId" type="text" [(ngModel)]="extAccountId" 
                        pTooltip="Unique customer's account identifier within the brand" tooltipPosition="top"/> 
                    </div>
                </div>
    
            </div>

            <div class="col-2"></div>
            <div class="col-4">

<!-- 
                <button pButton type="button" label="Find Account" class="margin-left" (click)="onFindAccount($event)"></button>
-->
                <p-button type="button" label="Find Account" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onFindAccount($event)" ></p-button>

            </div> 
    
        </div>

        <div  *ngIf="isAccountFound">     

            <p-tabView orientation="top" styleClass="ui-card-light">

                <p-tabPanel header="Notifications" leftIcon="pi pi-pencil">

                    <div class="ui-card-light">

                        <form [formGroup]="userform" (ngSubmit)="onSubmitNotification(userform.value)">

                            <div class="field grid pt-5">
                                <div class="col-1"></div>
                                    <label for="passId" class="col-fixed label" style="width: 10em">Pass Template:</label>
                                <div class="col">
                                    <p-dropdown id ="passId" [options]="passTemplates" formControlName="passId" [style]="{'width': '60%'}" 
                                        (onChange)="onChangePassTemplate($event)"
                                        [filter]="false" [editable]="false" pTooltip="Select the pass template" tooltipPosition="top"> 
                                    </p-dropdown>
                                    <p-message severity="error" text="Pass template is required" *ngIf="!userform.controls['passId'].valid&&userform.controls['passId'].dirty"></p-message>                 
                                </div>
                            </div>
            
                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="messageTemplateCode" class="col-fixed label" style="width: 10em">Message Template:</label>
                                <div class="col">
                                    <p-dropdown id ="messageTemplateCode" [options]="messageTemplates" formControlName="messageTemplateCode" [style]="{'width': '60%'}" 
                                        (onChange)="onChangeMessageTemplate($event)"
                                        [filter]="false" [editable]="false" pTooltip="Select the message template" tooltipPosition="top"> 
                                    </p-dropdown>
                                    <p-message severity="error" text="Message template is required" *ngIf="!userform.controls['messageTemplateCode'].valid&&userform.controls['messageTemplateCode'].dirty"></p-message>                 
                                </div>
                            </div>
            
                            <div class="field grid" *ngIf="isTemplateShown">
                                <div class="col-1"></div>
                                    <label for="templateHeader" class="col-fixed label" style="width: 10em">Template Header:</label>
                                <div class="col">
                                    <textarea pInputTextarea id="templateHeader" style="width: 95%;" [rows]="2" [cols]="36" 
                                        pTooltip="The header of the message template" tooltipPosition="top" 
                                        formControlName="templateHeader" > 
                                    </textarea>
                                    <p-message severity="error" text="Template header is required" *ngIf="!userform.controls['templateHeader'].valid&&userform.controls['templateHeader'].dirty"></p-message>                 
                                </div>
                            </div>
            
                            <div class="field grid" *ngIf="isTemplateShown">
                                <div class="col-1"></div>
                                    <label for="templateText" class="col-fixed label" style="width: 10em">Template Text:</label>
                                <div class="col">
                                    <textarea pInputTextarea id="templateText" style="width: 95%;" [rows]="2" [cols]="36" 
                                        pTooltip="The text of the message template" tooltipPosition="top" 
                                        formControlName="templateText" > 
                                    </textarea>
                                    <p-message severity="error" text="Template text is required" *ngIf="!userform.controls['templateText'].valid&&userform.controls['templateText'].dirty"></p-message>                 
                                </div>
                            </div>
            
                            <div class="col-12">
                                <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                                    <button pButton type="submit" icon="fa fa-check" label="Send Notification" class="ui-button-primary" [disabled]="!userform.valid"></button>
 -->
                                    <p-button type="submit" label="Send Notification" [disabled]="!userform.valid"
                                        styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                                    </p-button>
    
                                </div> 
                            </div>
    
                        </form>

                    </div>
                </p-tabPanel>
            
                <p-tabPanel header="Scenarios" leftIcon="pi pi-tags">
                    <div class="ui-g ui-card-light">


                        <div class="ui-g btn-group" role="group">
<!-- 
                            <button pButton type="submit" label="Paid" class="ui-button-primary" (click)="onPaid($event)" style="margin:5px;"></button>
                            <button pButton type="submit" label="Past Due" class="ui-button-primary" (click)="onPastDue($event)" style="margin:5px;"></button>
                            <button pButton type="submit" label="Cycle" class="ui-button-primary" (click)="onCycle($event)" style="margin:5px;"></button>
                            <button pButton type="submit" label="Reset to Current Month" class="ui-button-primary" (click)="onReset($event)" style="margin:5px;"></button> 
-->
                        
                            <p-button type="button" label="Paid" 
                                styleClass="p-button-info p-button-raised p-button-rounded margin-left" 
                                (click)="onPaid($event)">
                            </p-button>
                            <p-button type="button" label="Past Due" 
                                styleClass="p-button-info p-button-raised p-button-rounded margin-left" 
                                (click)="onPastDue($event)">
                            </p-button>
                            <p-button type="button" label="Cycle" 
                                styleClass="p-button-info p-button-raised p-button-rounded margin-left" 
                                (click)="onCycle($event)">
                            </p-button>
                            <p-button type="button" label="Reset to Current Month" 
                                styleClass="p-button-info p-button-raised p-button-rounded margin-left" 
                                (click)="onReset($event)">
                            </p-button>
                        
                        </div>

                    </div>
                </p-tabPanel>

                <p-tabPanel header="Current Values" leftIcon="pi pi-list">
                    <div class="ui-g ui-card-light">

                        <p-table #dtt [columns]="varValuesCols" [value]="varValues" selectionMode="single" 
                            tableStyleClass="prime-table" sortMode="multiple" [paginator]="true" [rows]="15">
                            <ng-template pTemplate="caption">
                            Variable Values
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}" >
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                    <td>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData.variableId">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData.variableId}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData.variableName">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData.variableName}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData.dataType">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData.dataType}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="rowData.variableValue">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData.variableValue}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </tr>
                            </ng-template>

                        </p-table> 

                        <div class="col-12">
                            <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                                <button pButton type="submit" icon="fa fa-check" label="Send Data Update" class="ui-button-primary" (click)="onSubmitDataUpdate($event)"></button>
 -->
                                <p-button type="button" label="Send Data Update" 
                                    styleClass="p-button-success p-button-raised p-button-rounded margin-left" style="margin:5px;"
                                    (click)="onSubmitDataUpdate($event)">
                                </p-button>                                
                            </div> 
                        </div>
                        
                    </div>
                </p-tabPanel>

                <p-tabPanel header="Tasks" leftIcon="pi pi-times">
                    <div class="ui-g ui-card-light">

                        <p-table #dt [columns]="taskCols" [value]="tasks" selectionMode="single" 
                                [(selection)]="selectedTask"  
                                sortMode="multiple" (onRowSelect)="onRowTaskSelect($event)" [paginator]="true" [rows]="15" >
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                    <td *ngFor="let col of columns">
                                        {{rowData[col.field]}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table> 
        
                        <div class="col-12">
                            <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                                <button pButton type="submit" icon="fa fa-plus" (click)="triggerEvent()" label="Execute Now" class="ui-button-primary" ></button>
 -->
                                <p-button type="button" label="Execute Now" 
                                    styleClass="p-button-success p-button-raised p-button-rounded margin-left" style="margin:5px;"
                                    (click)="triggerEvent()">
                                </p-button>                                
                                
                            </div> 
                        </div>
                    </div>
                </p-tabPanel>
                

            </p-tabView>    

        </div>

        <p-footer>
            <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
        </p-footer>        

    </p-card>

</div>



<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="account-tasks"></p-confirmDialog>