import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { IBrand } from '../../interfaces/brand';
import { IPassTemplate } from '../../interfaces/pass-template';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'app-choose-pass-template',
  templateUrl: './choose-pass-template.component.html',
  styleUrls: ['./choose-pass-template.component.css']
})
export class ChoosePassTemplateComponent implements OnInit {

  @Input('displayPassTemplateDialog') displayPassTemplateDialog?: boolean;
  @Input() currentBrand?: IBrand;
  @Output() choosePassTemplateEvent = new EventEmitter<IPassTemplate>();
  
  //currentBrand: any;
  passTemplateCols: any[] = [];
  passTemplates: IPassTemplate[] = [];
  selectedPassTemplate: any;

  constructor(
    private sharedService: SharedService,
    private brandProfileService: BrandProfileService
  ) { }

  ngOnInit(): void {
    // Define the columns for Brands grid
    this.passTemplateCols = [
      { field: 'passId', header: 'Id', display: 'table-cell', width:'15%'  },
      { field: 'passName', header: 'Pass Template Name', display: 'table-cell', width:'30%'  },
      { field: 'passtypeName', header: 'Pass Type', display: 'table-cell', width:'25%'  },
      { field: 'passDesc', header: 'Description', display: 'table-cell', width:'30%'  },
    ]; 
  }

  ngOnChanges() {
    if (this.currentBrand) 
      this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '')
  }

  // Retrieve distinct pass templates
  getPassTemplates(brandCode: string, baseUrl: string): void {
    if (brandCode && baseUrl) {
      this.brandProfileService.getPassTemplates(brandCode, baseUrl)
      .subscribe( 
        (response) => {
            // console.log("Success in getPassTemplates", response);
            this.passTemplates = response;
          },
        (error)  => {
          console.log("Error", error.message);
        }
      )
    }
  }  

  // When the user chooses a Pass Template 
  onRowPassTemplateSelect(event: any) {
    // this.selectedPassTemplate = new Map([[event.data.passId, event.data.passName]]);
    this.selectedPassTemplate = event.data;
  } 

  // When the user chooses a Pass Template
  onPassTemplateSelect() {
    this.choosePassTemplateEvent.emit(this.selectedPassTemplate);
  }  

  // When the user cancels the dialog to select a PassTemplate
  onCancelPassTemplateSelect() {
    this.choosePassTemplateEvent.emit();
  }

}
