import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
// import { BnNgIdleService } from 'bn-ng-idle'; 
import { UserIdleService } from 'angular-user-idle';
import { Subscription} from "rxjs";
import { ConfirmationService } from "primeng/api";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { SharedService } from "../shared.service";

@Injectable({
  providedIn: 'root'
})
export class IdleMonitorService {

  private timerStartSubscription!: Subscription;
  private timeoutSubscription!: Subscription;

  private isWatchingStopped: boolean = false;

  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService,
    // private bnIdle: BnNgIdleService,
    private userIdle: UserIdleService
  ) { }

  startIdleMonitoring() {
    console.log('startIdleMonitoring');

    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching after user idle is starting.
    this.timerStartSubscription = this.userIdle.onTimerStart().subscribe(count => {
      // console.log('onTimerStart', count);
      if (this.isWatchingStopped) {
        return;
      }
      // make sure that the user want to stay on
          this.confirmationService.confirm({
            message: 'Your Walletron Portal session has been idle for a long time.  Do you want to stay logged in?',
            header: 'Idle Session',
            icon: 'pi pi-exclamation-triangle',
            key: 'idle-session',
            acceptLabel: "Yes",
            rejectVisible: true,
            acceptButtonStyleClass: "p-button-success  p-button-rounded",
            rejectButtonStyleClass: "p-button-danger p-button-rounded",   
            accept: () => {
              this.restartIdleMonitoring();
            },
            reject: () => {
              this.stopIdleMonitoring();
              this.logout();
            }
          });
    });
    
    // React on the timeout
    this.timeoutSubscription = this.userIdle.onTimeout().subscribe((val) => {
      this.stopIdleMonitoring();
      this.logout();
      }
    );    
  }

  stopIdleMonitoring() {
    console.log('stopIdleMonitoring');
    this.userIdle.stopWatching();
  }

  stopWatching() {
    console.log('stopWatching');
    this.isWatchingStopped = true;
    this.userIdle.stopWatching();
  }

  // startWatching() {
  //   console.log('startWatching');
  //   this.userIdle.startWatching();
  // }

  restartIdleMonitoring() {
    // console.log('restartIdleMonitoring');
    this.userIdle.resetTimer();
  }

  // startIdleMonitoringOld() {
  //   // console.log('IdleMonitorService','entered startIdleMonitoring()');
  //   let timeOutInterval: number = 60 * 15;
  //   this.sessionSubscription = this.bnIdle.startWatching(timeOutInterval).subscribe((isTimedOut: boolean) => { // 60 * 15
  //     console.log('isTimedOut', isTimedOut);
  //     debugger;
  //     // console.log('IdleMonitorService: isTimedOut', isTimedOut);
  //     if (isTimedOut) {
  //       debugger;
  //       isTimedOut = false;
  //       console.log('IdleMonitorService', 'Ready for TimedOut - YYYYYYYYYYYYYYY');
  //       this.timerSubscription = timer(1000,1000).pipe(
  //         takeUntil(this.subject),
  //       ).subscribe(val =>  {
  //           if (val >= 30) {  // 30
  //             console.log('IdleMonitorService', "Force a log out since the user does not reply on the prompt");
  //             this.subject.next(val);
  //             this.stopIdleMonitoring();
  //             this.logout()
  //           } 
  //       });

  //       this.confirmationService.confirm({
  //         message: 'Your moBills Portal session has been idle for a long time.  Do you want to stay logged in?',
  //         header: 'Idle Session',
  //         icon: 'pi pi-exclamation-triangle',
  //         key: 'idle-session',
  //         acceptLabel: "Yes",
  //         rejectVisible: true,
  //         acceptButtonStyleClass: "p-button-success  p-button-rounded",
  //         rejectButtonStyleClass: "p-button-danger p-button-rounded",   
  //         accept: () => {
  //           // console.log('IdleMonitorService','The user wants to stay in');
  //           // this.bnIdle.resetTimer();
  //           if (this.timerSubscription) {
  //             // console.log('IdleMonitorService','Reset the timer for the prompt');
  //             this.timerSubscription.unsubscribe();
  //           }
  //         },
  //         reject: () => {
  //           // console.log('IdleMonitorService', "The user wants to log out");
  //           this.stopIdleMonitoring();
  //           this.logout();
  //         }
  //       });
  //     } 
  //   });
  // }

  // stopIdleMonitoringOld() {
  //   // console.log('IdleMonitorService','Entered stopIdleMonitoring()');
  //   if (this.timerSubscription) {
  //     this.timerSubscription.unsubscribe();
  //     // console.log('IdleMonitorService','called timer unsubscribe()');
  //   }
  //   if (this.sessionSubscription){
  //     this.bnIdle.stopTimer();
  //     this.sessionSubscription.unsubscribe();
  //     // console.log('IdleMonitorService','called bnIdle unsubscribe()');
  //   }
  // }

  logout() {
    // console.log('IdleMonitorService', "logout()");
    if (!this.timerStartSubscription.closed) {
      this.timerStartSubscription.unsubscribe();
    }
    if (!this.timeoutSubscription.closed) {
      this.timeoutSubscription.unsubscribe();
    }
    this.confirmationService.close();
    this.sharedService.clearUserSession();
    this.confirmationService.close
    this.tokenStorageService.signOut();
    this.router.navigate(['/login']);
  }
  
}
