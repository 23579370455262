export class CronEditorConfig {
    private isSecondsTabShown: boolean;
    private isMinutesTabShown: boolean;
    private isHoursTabShown: boolean;
    private isDayTabShown: boolean;
    private isMonthTabShown: boolean;
    private isYearTabShown: boolean;

    private isEveryMinuteOptionShown: boolean;
    private isEveryNMinutesOptionShown: boolean;
    private isEveryMinuteInRangeOptionShown: boolean;
    private minuteStep: number;

    private isEveryHourOptionShown: boolean;
    private isEveryHourInRangeOptionShown: boolean;   
    
    private isEveryDayOptionShown: boolean;
    private isEveryNDaysOfWeekOptionShown: boolean;
    private isEveryNDaysOfMonthOptionShown: boolean;    

    private isEveryMonthOptionShown: boolean; 
    private isEveryNMonthsOptionShown: boolean;    
    private isEveryMonthInRangeShown: boolean; 

    constructor(isSecondsTabShown: boolean = false, isMinutesTabShown: boolean = true, isHoursTabShown: boolean = true, isDayTabShown: boolean = true,
        isMonthTabShown: boolean = true, isYearTabShown: boolean = false, isEveryMinuteOptionShown: boolean = false, isEveryNMinutesOptionShown: boolean = false,
        isEveryMinuteInRangeOptionShown: boolean = false, isEveryHourOptionShown: boolean = false, isEveryHourInRangeOptionShown: boolean = false,
        isEveryDayOptionShown: boolean = false, isEveryNDaysOfWeekOptionShown: boolean = false, isEveryNDaysOfMonthOptionShown: boolean = false,
        isEveryMonthOptionShown: boolean = false, isEveryNMonthsOptionShown: boolean = false, isEveryMonthInRangeShown: boolean = false,
        minuteStep: number = 1
    ) {
        this.isSecondsTabShown = isSecondsTabShown;
        this.isMinutesTabShown = isMinutesTabShown;
        this.isHoursTabShown = isHoursTabShown;
        this.isDayTabShown = isDayTabShown;
        this.isMonthTabShown = isMonthTabShown;
        this.isYearTabShown = isYearTabShown;

        this.isEveryMinuteOptionShown = isEveryMinuteOptionShown;
        this.isEveryNMinutesOptionShown = isEveryNMinutesOptionShown;
        this.isEveryMinuteInRangeOptionShown = isEveryMinuteInRangeOptionShown;
        this.minuteStep = minuteStep;

        this.isEveryHourOptionShown = isEveryHourOptionShown;
        this.isEveryHourInRangeOptionShown = isEveryHourInRangeOptionShown;

        this.isEveryDayOptionShown = isEveryDayOptionShown;
        this.isEveryNDaysOfWeekOptionShown = isEveryNDaysOfWeekOptionShown;
        this.isEveryNDaysOfMonthOptionShown = isEveryNDaysOfMonthOptionShown;

        this.isEveryMonthOptionShown = isEveryMonthOptionShown;
        this.isEveryNMonthsOptionShown = isEveryNMonthsOptionShown;
        this.isEveryMonthInRangeShown = isEveryMonthInRangeShown;
    }

    public getSecondsTabShown(): boolean{
        return this.isSecondsTabShown;
    }
    public getMinutesTabShown(): boolean{
        return this.isMinutesTabShown;
    }
    public getHoursTabShown(): boolean{
        return this.isHoursTabShown;
    }
    public getDayTabShown(): boolean{
        return this.isDayTabShown;
    }
    public getMonthTabShown(): boolean{
        return this.isMonthTabShown;
    }
    public getYearTabShown(): boolean{
        return this.isYearTabShown;
    }

    public getEveryMinuteOptionShown(): boolean{
        return this.isEveryMinuteOptionShown;
    }
    public getEveryNMinutesOptionShown(): boolean{
        return this.isEveryNMinutesOptionShown;
    }
    public getEveryMinuteInRangeOptionShown(): boolean{
        return this.isEveryMinuteInRangeOptionShown;
    }
    public getMinuteStep(): number{
        return this.minuteStep;
    }
    public setMinuteStep(minuteStep: number) {
        this.minuteStep = minuteStep;
    }
    
    public getEveryHourOptionShown(): boolean{
        return this.isEveryHourOptionShown;
    }
    public getEveryHourInRangeOptionShown(): boolean{
        return this.isEveryHourInRangeOptionShown;
    }
    
    public getEveryDayOptionShown(): boolean{
        return this.isEveryDayOptionShown;
    }
    public getEveryNDaysOfWeekOptionShown(): boolean {
        return this.isEveryNDaysOfWeekOptionShown;
    }
    public getEveryNDaysOfMonthOptionShown(): boolean {
        return this.isEveryNDaysOfMonthOptionShown;
    }
    
    public getEveryMonthOptionShown(): boolean{
        return this.isEveryMonthOptionShown;
    }
    public getEveryNMonthsOptionShown(): boolean{
        return this.isEveryNMonthsOptionShown;
    }
    public getEveryMonthInRangeOptionShown(): boolean{
        return this.isEveryMonthInRangeShown;
    }


}
