<!--- Generic header, inclusing current brand and a button to select another brand -->

<div class="grid">
    <div class="col-8">
        <app-brand-admin-header></app-brand-admin-header>
    </div>  
    <div class="col-2">
        <button pButton type="button" label="Back to Field Set" icon="pi pi-backward" iconPos="left" (click)="brandOfferRedirect($event)"  
            class="p-button-raised p-button-rounded" [disabled]="isDetailsShown">
        </button>
    </div>

    <!-- <div class="col-2">
        <button pButton type="button" label="Back to Field Set" icon="pi pi-backward" iconPos="left" (click)="brandOfferRedirect($event)"  
             class="p-button-raised p-button-rounded" [disabled]="isDetailsShown">
         </button>
    </div>   -->
 </div> 

<!--   -->

<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-10 side-list-content-data" *ngIf="isListShown">

        <!-- The list of Field Sets -->

        <div class="row" *ngIf="isListOfOfferFieldsShown">

            <div class="col-12">

                <p-card header="List of Fields" subheader="" styleClass="ui-card-powder" >
    
                    <p-table #dt [columns]="cols" [value]="offerFields" selectionMode="single"
                            sortMode="multiple"  [paginator]="true" [rows]="50" [rowsPerPageOptions]="[15,25,50,75]">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th [ngStyle]="{'width': 'table-cell', 'display': '2%'}">
                                </th>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                            <tr  [pReorderableRow]="index">
                                <td>
                                    <i class="pi pi-bars" pReorderableRowHandle></i>
                                </td>                                
                                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{rowData[col.field]}}
                                </td>
                                <td style="text-align:center">
                                    <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}" 
                                        data-toggle="tooltip" data-placement="top" title="Modify field set" 
                                        class="ui-button-info" (click)="onRowEdit(rowData)">
                                    </button> 
                                    <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Delete field set" 
                                        class="ui-button-danger" (click)="onRowDelete(rowData)" *ngIf="isSubmitShown">
                                    </button>                                
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="summary" let-rowData>
                            <div style="text-align:left" *ngIf="isSubmitShown">
<!-- 
                                <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button>
                                <button type="button" pButton icon="fa fa-file-o" (click)="saveFieldOrder()" label="Save Field Order" class="float-right"></button>
 -->
                                <p-button type="submit" label="Add" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="showDialogToAdd()"></p-button>
                                <p-button type="button" label="Save Field Order" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="saveFieldOrder()"></p-button>
        
                            </div>
                        </ng-template>    

                    </p-table> 

                    <p-footer>
                        <mat-icon class="nav-caption wal-center">list_all</mat-icon>
                    </p-footer>
                </p-card>
                    
            </div>
    
        </div>
 
    </div>

    <!--- Second card with the details -->

    <div @flyInOut class="col-10 side-list-content-data" *ngIf="isDetailsShown">

        <div class="col-8">

            <p-card header="Field Details" subheader="" styleClass="ui-card-light" >
            
                <form (ngSubmit)="onSubmit()" [formGroup]="form">

                    <div *ngFor="let offerFieldAttribute of offerField.offerFieldAttributes" class="form-row">
                        <app-attribute [offerFieldAttribute]="offerFieldAttribute" [form]="form" 
                            *ngIf="!(offerFieldAttribute?.key=='offerFieldId' && isNewOfferField)"
                            (changeFieldTypeEvent)="changeFieldType($event)"
                            (changeOfferLinkEvent)="changeOfferLink($event)"
                            (captureColorEvent)="captureColor($event)"
                            (showImageEvent)="showImage($event)"
                            (hideImageEvent)="hideImage($event)" >
                        </app-attribute>
                    </div>

                    <div class="form-row" style="text-align:left">
<!-- 
                        <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!form.valid" *ngIf="isSubmitShown" ></button>
                        <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button>
                        <button pButton type="button" icon="fa fa-file-o" (click)="onFunctionsCheatSheet()" label="Functions Cheat Sheet" class="float-right"></button>
 -->
                        <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!form.valid" *ngIf="isSubmitShown" ></p-button>
                        <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>
                        <p-button type="button" label="Functions Cheat Sheet" styleClass="p-button-info p-button-raised p-button-rounded float-right" (click)="onFunctionsCheatSheet()"></p-button>


                    </div>                    

                </form>
        
                <p-footer>
                    <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
                </p-footer>
            </p-card>

        </div>

        <div class="col-2" styleClass="ui-card-light"  *ngIf="isImagePreviewShown">
            <img [src]="image" />
        </div>

    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="offer-fields"></p-confirmDialog>

