import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ISchedulerConfig } from '../../interfaces/scheduler-config';
import { IMessage } from '../../interfaces/message';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class AdhocMessageSchedulerService {

  protected baseAuth = environment.appSetting.baseAuth;

  private baseHeaders = new HttpHeaders()
    .set("request-origin", "csr-portal")
    .set('Authorization', this.baseAuth);

    private schedulerConfigUrl = '/adhoc-msg/brands/{brandCode}/schedule-config/{adhocMsgConfigId}';
    private defaultSchedulerConfigUrl = '/adhoc-msg/brands/{brandCode}/schedule-config/default';
    private schedulerUrl = '/adhoc-msg/brands/{brandCode}/schedule/{adhocMsgConfigId}';
    private schedulerCronUrl = '/adhoc-msg/brands/{brandCode}/schedule-cron';

  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService
  ) { }

  getDefaultScheduleConfig(brandCode: string, baseUrl: string): Observable<ISchedulerConfig> {
    var url = baseUrl + this.defaultSchedulerConfigUrl
      .replace('{brandCode}', brandCode);
    return this.http.get<ISchedulerConfig>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getScheduleConfig(brandCode: string, adhocMsgConfigId: number, baseUrl: string): Observable<ISchedulerConfig> {
    var url = baseUrl + this.schedulerConfigUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMsgConfigId}', adhocMsgConfigId.toString());
    return this.http.get<ISchedulerConfig>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addScheduleConfig(brandCode: string, adhocMsgConfigId: number, schedulerConfig: any, baseUrl: string) {
    var url = baseUrl + this.schedulerConfigUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMsgConfigId}', adhocMsgConfigId.toString());
      let rawJson: string = JSON.stringify(schedulerConfig, (key, val) => typeof val === 'bigint' ? Number(val) : val);
    return this.http.post(url, JSON.parse(rawJson), {headers: this.baseHeaders, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  deleteScheduleConfig(brandCode: string, adhocMsgConfigId: number, baseUrl: string) {
    var url = baseUrl + this.schedulerConfigUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMsgConfigId}', adhocMsgConfigId.toString());
    return this.http.delete(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  putOnScheduleConfig(brandCode: string, adhocMsgConfigId: number, baseUrl: string) {
    var url = baseUrl + this.schedulerUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMsgConfigId}', adhocMsgConfigId.toString());
    return this.http.post(url, null, {headers: this.baseHeaders, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  removeFromScheduleConfig(brandCode: string, adhocMsgConfigId: number, baseUrl: string) {
    var url = baseUrl + this.schedulerUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMsgConfigId}', adhocMsgConfigId.toString());
    return this.http.delete(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  makeCronExpression(brandCode: string, schedulerConfig: string, baseUrl: string): Observable<IMessage>{
    var url = baseUrl + this.schedulerCronUrl
      .replace('{brandCode}', brandCode);
      // let rawJson: string = JSON.stringify(schedulerConfig, (key, val) => typeof val === 'bigint' ? Number(val) : val);
      // console.log('makeCronExpression - url', url);
      // console.log('json',schedulerConfig);
    return this.http.post<IMessage>(url, JSON.parse(schedulerConfig), {headers: this.baseHeaders, responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }


}
