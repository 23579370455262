import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, query, stagger, group, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IEmailSmsTemplate } from '../../interfaces/email-sms-template';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-email-sms-templates',
  templateUrl: './email-sms-templates.component.html',
  styleUrls: ['./email-sms-templates.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class EmailSmsTemplatesComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "EmailSmsTemplates";
  titleMessage = "Email/SMS Templates";
  userform!: FormGroup;
  isListShown = true;
  isDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;
  isEmailTemplateType = false;

  emailSmsTemplates: any[] = [];
  templateTypes: any[] = [];
  cols: any[] = [];
  resultMsg = "";
  currentBrand: IBrand;

  openBrandSelectionDialog!: boolean;
  
  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private userContextService: UserContextService,    
    private confirmationService: ConfirmationService  ) { 
      this.currentBrand = this.sharedService.getDummyBrand();      
    }

  ngOnInit(): void {
    this.templateTypes = [
      {label: "Email", value: "E"}, 
      {label: "SMS", value: "S"}, 
      {label: "Mail To", value: "M"}
    ];

    this.cols = [
      { field: 'templateID', header: 'Template Id', display: 'table-cell', width:'12%'  },
      { field: 'templateName', header: 'Template Name', display: 'table-cell', width:'20%'  },
      { field: 'subject', header: 'Subject', display: 'table-cell', width:'25%'  },
      { field: 'templateType', header: 'Template Type', display: 'table-cell', width:'15%'  },
      // { field: 'templateInfo', header: 'Template Info', display: 'table-cell', width:'15%'  },
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'table-cell', width:'20%'  },
    ]; 

    this.userform = this.fb.group({
      'templateID': new FormControl(''),
      'templateName': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'subject': new FormControl('', [Validators.required]),
      'templateType': new FormControl('', [Validators.required]),
      'templateInfo':  new FormControl('', [Validators.required]),
      'passAttach': new FormControl(true, [Validators.required]),
      'senderAddress': new FormControl('', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      'senderName': new FormControl(null),
      'createDate': new FormControl(null),
      'lastUpdateDate': new FormControl(''),

    });

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.getEmailSmsTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_TEMPLATE_MODIFY')) {
      this.isSubmitShown = true;
    }
    
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.sharedService.setCurrentBrand(item);
      this.getEmailSmsTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    }
  }

  onSubmit(value: string) {
    let emailSmsTemplate: IEmailSmsTemplate = {
      brandCode: this.currentBrand.brandCode,
      templateID: this.userform.value.templateID,
      templateName: this.userform.value.templateName,
      subject: this.userform.value.subject, 
      templateType: this.userform.value.templateType,
      templateInfo: this.userform.value.templateInfo,
      passAttach: this.userform.value.passAttach,
      senderAddress: this.userform.value.senderAddress,
      senderName: this.userform.value.senderName,
      createDate: this.sharedService.formatToISO(this.userform.value.createDate),
      lastUpdateDate: this.sharedService.formatToISO(this.userform.value.lastUpdateDate)
    };
    // Call the service to invoke a Web API call
    this.brandProfileService.submitEmailSmsTemplate(this.currentBrand.brandCode, emailSmsTemplate, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        if (emailSmsTemplate.templateID) {
          this.alertTheUser('Successfully modified Email/SMS template ' + emailSmsTemplate.templateName + ' from brand ' + this.currentBrand.brandCode);
        } else {
          this.alertTheUser('Successfully added Email/SMS template ' + emailSmsTemplate.templateName + ' to brand ' + this.currentBrand.brandCode);
        }
        this.getEmailSmsTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
        this.isListShown = true;
        this.isDetailsShown = false;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  cancelDetails() {
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  // Select all existing Email/SMS Templates
  getEmailSmsTemplates(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getEmailSmsTemplates(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        this.emailSmsTemplates = response;
        this.emailSmsTemplates.forEach(template => {
          var templateType = this.templateTypes.find(element => element.value == template.templateType);
          // Replace templateType's value with the corresponding label, so it gets properly displayed on the grid
          template.templateType = templateType.label;                
          // Format the dates
          if (template.createDate) {
            template.createDate = this.sharedService.formatFromISO(template.createDate);
          }
          if (template.lastUpdateDate) {
            template.lastUpdateDate = this.sharedService.formatFromISO(template.lastUpdateDate);
          }
        })
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  showDialogToAdd() {
    this.userform.setValue({
      templateID: null, 
      templateName: '', 
      subject: '', 
      templateType: 'S', 
      senderAddress: null,
      senderName: null, 
      templateInfo: null, 
      passAttach: true, 
      createDate: null, 
      lastUpdateDate: null
    });
    this.isEmailTemplateType = false;
    this.isListShown = false;
    this.isDetailsShown = true;
  }

  // Modify an existing Email/SMS Template
  onRowEdit(event: any) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getEmailSmsTemplate(this.currentBrand.brandCode, event.templateID, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.userform.setValue({templateID: response.templateID, templateName: response.templateName, subject: response.subject, 
          templateType: response.templateType, templateInfo: response.templateInfo, passAttach: response.passAttach, 
          senderAddress: response.hasOwnProperty('senderAddress') ? response.senderAddress : null, 
          senderName: response.hasOwnProperty('senderName') ? response.senderName : null,
          createDate: this.sharedService.formatFromISO(response.createDate),
          lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate)})
        if (response.templateType == 'E') {
          this.isEmailTemplateType = true;
        } else {
          this.isEmailTemplateType = false;
        }
        this.isListShown = false;
        this.isDetailsShown = true;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the Email/SMS Template
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the Email/SMS template ' + event.templateName + ' ?',
      header: 'Confirmation',
      key: 'email-sms-template',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteEmailSmsTemplate(this.currentBrand.brandCode, event.templateID, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted Email/SMS templatee ' + event.templateName + ' from brand ' + this.currentBrand.brandCode);
            this.getEmailSmsTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  onChangeTemplateType(event: any) {
    if (event.value == 'E') {
      this.isEmailTemplateType = true;
    } else {
      this.isEmailTemplateType = false;
    }
  }

  onRowSelect(event: any) {
  }  

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'email-sms-template',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
