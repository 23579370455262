<!-- 
<h2>Login into Walletron Portal</h2>

<div class="col-3" (keyup.enter)="onSubmitClick()">

    <p-card header="" subheader="" styleClass="ui-card-shadow">

        <div class="d-flex align-items-end">
            <mat-icon class="nav-caption">lock</mat-icon>
        </div>

        <div>

            <div class="col-12">
                <div class="col-4">
                    <label for="userName">User Name:</label>
                </div>
                <div class="col-8">
                    <input pInputText id="userName" [(ngModel)]="login.userName" />
                </div>
            </div>
            
            <div class="col-12">
                <div class="col-4">
                    <label for="password">Password:</label>
                </div>
                <div class="col-8">
                    <input pPassword type="password" id="password" [feedback]="false" [(ngModel)]="login.password"/>
                </div>
            </div>
            
            <div class="col-12" *ngIf="isPasswordChangeRequired">
                <div class="col-4">
                    <label for="newPassword">New Password:</label>
                </div>
                <div class="col-8">
                    <input pPassword type="password" id="newPassword" [feedback]="false" [(ngModel)]="login.newPassword"/>
                </div>
            </div>
            
            <div class="col-12" *ngIf="isPasswordChangeRequired">
                <div class="col-4">
                    <label for="confirmPassword">Confirm Password:</label>
                </div>
                <div class="col-4">
                    <input pPassword type="password" id="confirmPassword" [feedback]="false" [(ngModel)]="login.confirmPassword"/>
                </div>

            </div>
            
        </div>
    
        <p-footer>

            <table class="table table-dark">
                <tbody>
                    <tr>
                        <td>
                            <button type="submit" pButton (click)="onSubmitClick()" icon="pi pi-check" label="Submit" class="ui-button-success"></button>
                        </td>
                    </tr>
                </tbody>
            </table>      
            <div *ngIf="error" class="alert alert-danger">
                <p-message severity="error" text="{{error}}"></p-message>
            </div>
                      

        </p-footer>
    </p-card>


</div>

 -->




<div class="grid">


<div class="col-3" (keyup.enter)="onSubmitClick()">

    <p-card header="Login into Walletron Portal" subheader="" styleClass="ui-card-light">

        <div class="d-flex align-items-end">
            <mat-icon class="nav-caption">lock</mat-icon>
        </div>

        <div>
<!-- 
            <div class="flex-row">
                <div class="col-4">
                    <label for="userName">User Name:</label>
                </div>
                <div class="col-8">
                    <input pInputText id="userName" [(ngModel)]="login.userName" />
                </div>
            </div> -->

<!-- 
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input type="text" id="userName" pInputText [(ngModel)]="login.userName" class="p-inputtext-sm"> 
                    <label for="userName">User Name:</label>
                </span>
            </div>
 -->
            <div class="field grid">
                <label for="userName" class="col-fixed label" style="width:150px">User Name:</label>
                <div class="col">
                    <input pInputText id="userName" type="text" [(ngModel)]="login.userName" class="text-base text-color surface-overlay p-1 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary">
                </div>
            </div>      

            <div class="field grid">
                <label for="password" class="col-fixed label" style="width:150px">Password:</label>
                <div class="col">
                    <input pPassword id="password" type="password" [feedback]="false"  [(ngModel)]="login.password" class="text-base text-color surface-overlay p-1 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary">
                </div>
            </div>      
            
            <div class="field grid" *ngIf="isPasswordChangeRequired">
                <label for="newPassword" class="col-fixed label" style="width:150px">New Password:</label>
                <div class="col">
                    <input pPassword id="newPassword" type="password" [feedback]="false"  [(ngModel)]="login.newPassword" class="text-base text-color surface-overlay p-1 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary">
                </div>
            </div>      
            
            <div class="field grid" *ngIf="isPasswordChangeRequired">
                <label for="confirmPassword" class="col-fixed label" style="width:150px">Confirm Password:</label>
                <div class="col">
                    <input pPassword id="confirmPassword" type="password" [feedback]="false"  [(ngModel)]="login.confirmPassword" class="text-base text-color surface-overlay p-1 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary">
                </div>
            </div>      
            
<!-- 
            <div class="col-12" *ngIf="isPasswordChangeRequired">
                <div class="col-4">
                    <label for="newPassword">New Password:</label>
                </div>
                <div class="col-8">
                    <input pPassword type="password" id="newPassword" [feedback]="false" [(ngModel)]="login.newPassword"/>
                </div>
            </div>
            
            <div class="col-12" *ngIf="isPasswordChangeRequired">
                <div class="col-4">
                    <label for="confirmPassword">Confirm Password:</label>
                </div>
                <div class="col-4">
                    <input pPassword type="password" id="confirmPassword" [feedback]="false" [(ngModel)]="login.confirmPassword"/>
                </div>

            </div>
             -->
        </div>
    
        <p-footer>

            <table class="table table-dark">
                <tbody>
                    <tr>
                        <td>
                            <p-button label="Submit" icon="pi pi-check" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSubmitClick()"></p-button>
<!-- 
                            <button type="submit" pButton (click)="onSubmitClick()" icon="pi pi-check" label="Submit" class="ui-button-success"></button>
 -->
                        </td>
                    </tr>
                </tbody>
            </table>      
            <div *ngIf="error" class="alert alert-danger">
                <p-message severity="error" text="{{error}}"></p-message>
            </div>
                      

        </p-footer>


    </p-card>


</div>

</div>



