import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ConfirmationService } from "primeng/api";
import { User } from '../../models/user';
import { UserRoles } from '../../interfaces/user-roles';
import { WalletronUserService } from '../../services/admin/walletron-user.service';
import { BrandService } from '../../services/config/brand.service';
import { EnvRegionService } from '../../services/config/env-region.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { IEnvRegion } from '../../interfaces/env-region';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

//  userDomain: string = 'Walletron';
  users: User[] = [];
  cols: any[] = [];
  selectedUser!: User;
  displayDialog!: boolean;
  newUser!: boolean; 
  // user: User = new User(); 
  user!: User; 
  userRoles: UserRoles[] = [];
  roleNames: any[] = [];
  availableRoles: any[] = [];
  grantedRoles: any[] = [];
  brandCodes: any[] = [];
  offset!: number;
  selectedUserName!: string;
  selectedBrandCode!: string;
  selectedRoleName!: string;
  displayEnvRegionDialog: boolean = false;
  envRegionCols: any[] = [];
  envRegion: IEnvRegion = <IEnvRegion> {};
  envRegions: IEnvRegion[] = [];
  envRegionBaseUrl!: string;

  isSubmitShown: boolean = false;  
  
  constructor(
    private userService: WalletronUserService, 
    private brandService: BrandService, 
    private envRegionService: EnvRegionService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService) { 
  }

  ngOnInit() {
    // Define the columns for Users grid
     this.cols = [
      { field: 'userId', header: 'User Id', display: 'table-cell' },
      { field: 'userName', header: 'User Name', display: 'table-cell' },
      { field: 'brandCode', header: 'Brand Code', display: 'table-cell'},
      { field: 'locked', header: 'Locked', display: 'table-cell' },
      { field: 'disabled', header: 'Disabled', display: 'table-cell'},
      { field: 'roles', header: 'roles', display: 'none'},
      { field: 'lastLoginDate', header: 'Last Login Date', display: 'none'}
    ]; 
    // Define the columns for Enviromental Regions grid
    this.envRegionCols = [
      { field: 'envCode', header: 'Env. Region', display: 'table-cell', width:'20%' },
      { field: 'brands', header: 'Brands', display: 'table-cell', width:'80%' },
      { field: 'baseUrl', header: 'Base URL', display: 'none', width:'80%' }
    ];
    // Retrieve all enviromental regions 
    this.getEnvRegions();
    this.establishEnvRegion();
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WA_USER_MODIFY')) {
      this.isSubmitShown = true;
    }
  }

  // Desplay a message and then a popup form to select an enviromental region
  establishEnvRegion(): void {
    this.displayEnvRegionDialog = false;
    this.confirmationService.confirm({
        message: 'Please select an enviromental region to work with its dedicated database and access its specific group of users.',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
          this.displayEnvRegionDialog = true;
        }
    });
 }

 // When the user cancels the dialog to select an enviromental region
 onCancelEnvRegionSelect() {
  this.displayEnvRegionDialog = false;
}

// When the user chooses an enviromental region
onRowEnvRegionSelect(event: any) {
  this.envRegion = <IEnvRegion> {
    envCode: event.data.envCode,
    brands: event.data.brands,
    baseUrl: event.data.baseUrl,
    createDate: '',
    lastUpdateDate: ''
  }
}  

// When the user chooses an enviromental region
onEnvRegionSelect() {
  this.users = [];
  this.getRoleNames(this.envRegion);
  this.getBrandCodes(this.envRegion.envCode);
  this.displayEnvRegionDialog = false;
}  

// When the user opens up the dialog to select an enviromental region 
onEnvRegionClick(event: any) {
  this.displayEnvRegionDialog = true;
}

  // Retrieve all environmental regions
  getEnvRegions(): void {
      this.envRegionService.getEnvRegions()
      .subscribe(envRegions => {
        this.envRegions = envRegions;
      });
  }

// Retrieve all role names for the given environmental region
  getRoleNames(envRegion: any): void {
    this.roleNames.splice(0);
    this.userService.getRoleNames(envRegion)
       .subscribe(names => {
         for(let i = 0; i < names.length; i++){
           this.roleNames.push({label: names[i], value: names[i]});
        }
       });
  }

    // Retrieve distinct brand codes for the given environmental region
    getBrandCodes(envRegion: string): void {
      this.brandCodes.splice(0);
      this.brandService.getEnvBrands(envRegion)
      .subscribe(brands => {
        for(let i = 0; i < brands.length; i++){
          this.brandCodes.push({label: brands[i].brandCode, value: brands[i].brandCode});
        }
        this.brandCodes.sort((a,b) => a.value - b.value);
      });
   }

  getUsers(envRegion: any, selectedUserName: string, selectedBrandCode: string, selectedRoleName: string, offset: number): void {
    this.userService.getUsers(envRegion, selectedUserName, selectedBrandCode, selectedRoleName, offset)
        .subscribe (userDescResponse => {
          console.log('getUsers', userDescResponse);
          this.users = userDescResponse.userDescriptorList;
          this.offset = userDescResponse.paging.nextPageToken;
          // Format the dates
/*           this.users.forEach(function (arrayItem) {
            arrayItem.lastLoginDate = arrayItem.lastLoginDate.replace('T', ' ').replace('Z[UTC]','');
          });
 */        });
  }

  onSearchClick(event: any) {
    if (!(this.envRegion)) {
      this.establishEnvRegion();
      return;
    }
    var offset = 0;
    this.getUsers(this.envRegion, this.selectedUserName, this.selectedBrandCode, this.selectedRoleName, offset);
  }

  onNextClick(event: any) {
    if (!(this.envRegion)) {
      this.establishEnvRegion();
      return;
    }
    var offset = this.offset;
    this.getUsers(this.envRegion, this.selectedUserName, this.selectedBrandCode, this.selectedRoleName, offset);
  }

  onClearClick(event: any) {
    this.selectedUserName = '';
    this.selectedBrandCode = '';
    this.selectedRoleName = '';
  }

  showDialogToAdd() {
    this.newUser = true;
    this.user = {userName: '', brandCode: '', disabled: false, locked: false, roles: [''], lastLoginDate: '', 
          userId: 0, password: ''};
    this.grantedRoles = [];
    this.availableRoles = [];
    var role;
    for (role of this.roleNames) {
        this.availableRoles.push(role);
    }
    this.availableRoles = [...this.availableRoles];
    this.grantedRoles = [...this.grantedRoles];
    this.displayDialog = true;
  }

  save() {
    // Basic input data validation
    if (!this.user.userName || !this.user.brandCode || !(this.grantedRoles && this.grantedRoles.length)) {
      this.confirmationService.confirm({
        message: 'The user record must have non-empty Name, Password, Brand Code, and at least one Role.',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });
    }
    else {
      var roles = [];
      var role;
      for (role of this.grantedRoles) {
          roles.push(role.value);
      }
      if (this.user.userId == null || this.user.userId == 0) {
          this.userService.addUser(this.envRegion, this.user, roles)
          .subscribe({
            next: (response) => {
              debugger;
              this.processResult('The user record has been successfully added.');
            },
            error: (error) => {
              debugger;
              console.log("Error", error);
            },
            complete: () => {
            }
          });
        }
      else {
        this.userService.modifyUser(this.envRegion, this.user, roles)
        .subscribe({
          next: (response) => {
            this.processResult('The user record has been successfully modified.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      }
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the user record with all its properties and roles?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        this.userService.deleteUser(this.envRegion, this.user.userName)
        .subscribe({
          next: (response) => {
            this.processResult('The user record has been successfully deleted.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  cancel() {
    // this.user = new User();
    this.displayDialog = false;
  }

  onRowSelect(event: any) {
    this.newUser = false;
    this.grantedRoles = [];
    this.availableRoles = [];
    let userName = event.data.userName;
    console.log('onRowSelect', userName);
    console.log('roleNames', this.roleNames);
    this.userService.getUsers(this.envRegion, userName, '', '', 0)
    .subscribe({
      next: (response) => {
        if (response) {
          if (response.userDescriptorList.length >= 1) {
            // this.user = response.userDescriptorList[0];
            let recs = response.userDescriptorList.filter(item => item.userName == userName);
            this.user = recs[0];
            this.roleNames.forEach(role => {
              if (this.user.roles.includes(role.value)) {
                this.grantedRoles.push(role);
              }
              else{
                this.availableRoles.push(role);
              }
            })
        }
      }},
      error: (error) => {
      },
      complete: () => {
        this.availableRoles = [...this.availableRoles];
        this.grantedRoles = [...this.grantedRoles];
        this.displayDialog = true;
      }
    });
  }

//   onRowSelectOld(event: any) {
//     this.newUser = false;
//     this.grantedRoles.length = 0;
//     this.availableRoles.length = 0;
//     this.user = this.cloneUser(event.data);
// //    this.userService.getUserRoles(this.user.userId);
//     var role;
//     for (role of this.roleNames) {
//       if (this.user.roles.includes(role.value)) {
//         this.grantedRoles.push(role);
//       }
//       else{
//         this.availableRoles.push(role);
//       }
//     }
//     this.displayDialog = true;
//   }

  cloneUser(c: User): User {
  //   let user = new User();
  //  for (let prop in c) {
  //     user[prop] = c[prop];
  //   }

    let user: User = {
      brandCode: c.brandCode,
      disabled: c.disabled,
      lastLoginDate: c.lastLoginDate,
      locked: c.locked,
      roles: c.roles,
      userId: c.userId,
      userName: c.userName,
      password: c.password
    };
    return user;
  }

  processResult(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Notification',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
    var offset = 0;
    this.getUsers(this.envRegion.createDate, this.selectedUserName, this.selectedBrandCode, this.selectedRoleName, offset);
    // this.user = new User();
    this.displayDialog = false;

  }

}

/* selectedUser: User;
  onSelect(user: User): void {
    this.selectedUser = user;
  } 
 */



