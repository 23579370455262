<h2>Account Enrollments Trend</h2>

<div class="grid">

    <div class="col-3">

        <div  class="row">

            <p-table #dt [value]="brands" selectionMode="multiple" dataKey="brandCode"
                    [(selection)]="selectedBrands" [columns]="cols" 
                    sortMode="multiple"  [paginator]="true" [rows]="12" >
                <ng-template pTemplate="caption">
                    Select up to 5 brands:
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th style="width: 20%">Brand Code</th>
                        <th style="width: 70%">Brand Name</th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            <input *ngSwitchCase="'brandCode'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '60%'}">
                            <input *ngSwitchCase="'brandName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                        </th>
                    </tr>                          
                </ng-template>
                <ng-template pTemplate="body" let-brand>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="brand"></p-tableCheckbox>
                        </td>
                        <td style="width: 20%">{{brand.brandCode}}</td>
                        <td style="width: 70%">{{brand.brandName}}</td>
                    </tr>
                </ng-template>
            </p-table> 

        </div>

        <div class="ui-g ui-card-ultra-light">
            <div class="col-5">
                <label style="font-size: 12px !important;">Number of Monthly Periods:</label>
            </div>
            <div class="col-4">
                <p-inputMask [(ngModel)]="numOfMonths" mask="99"></p-inputMask>
            </div>
            <div class="col-3">
                <!-- <button pButton type="button" label="Submit" icon="pi refresh" iconPos="left" (click)="onGetTrend()" style="float:right"></button> -->
                <p-button type="button" label="Submit" 
                    styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onGetTrend()">
                </p-button>

            </div>
        </div>  

        <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
            <div class="loader"></div>  
        </div>
    
    </div>

    <div class="col-8" *ngIf="!isNoDataFound">
        <p-chart type="bar" [data]="enrollmentCounts" [options]="enrollmentOptions" ></p-chart>
        <!-- <button type="button" pButton icon="fa-file-o" iconPos="left" label="CSV Export" (click)="onCsvExport()" style="float:right"> </button> -->
        <p-button type="button" label="CSV Export" 
            styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onCsvExport()">
        </p-button>   
    </div>

    <!-- <div class="ui-g-1 ui-helper-clearfix"  *ngIf="!isNoDataFound">
    </div> -->

</div>

  <p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="enrollment-trend"></p-confirmDialog>