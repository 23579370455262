<div class="grid">

  <div class="col-8">

      <p-card header="Communication History" subheader="" styleClass="ui-card-light">


<!--  C R I T E R I A  -->
<div class="grid">
    <div class="grid col-12">

      <div class="col-1">
        <label for="brandCode" class="margin-right">Brand Code:</label>
        <p-dropdown id ="brandCode" [options]="brandCodes" [(ngModel)]="selectedBrandCode" [filter]="true" 
            styleClass="prime-dropdown" placeholder="Brand Code:"  [editable]="false">
        </p-dropdown>
      </div>

      <div class="col-2 ml-3">
        <label for="messageCode" class="margin-right">Message Code:</label>
        <p-dropdown id ="messageCode" [options]="messageCodes" [(ngModel)]="selectedMessageCode" [filter]="true" 
        styleClass="prime-dropdown" placeholder="Message Code:" [editable]="false"  class="margin-left">
        </p-dropdown>
      </div>

      <div class="col-1">
        <label for="channelCode" class="margin-right">Channel Code:</label>
        <p-dropdown id ="channelCode" [options]="channelCodes" [(ngModel)]="selectedChannelCode" [filter]="true" 
            styleClass="prime-dropdown" placeholder="Channel Code:" [editable]="false"  class="margin-left">
        </p-dropdown>
      </div>

      <div class="col-2 ml-5">
          <label for="dateFrom" class="margin-right">Date From:</label>
          <p-calendar id="dateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" 
          [showIcon]="true" class="margin-left"></p-calendar>
        </div>
    
        <div class="col-2">
          <label for="dateFrom" class="margin-right">Date From:</label>
          <p-calendar id="dateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" 
          [showIcon]="true" class="margin-left"></p-calendar>
        </div>

        <!-- <div class="col-1"></div> -->

        <div class="col-3">
          <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
          <p-button label="Next" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onNextClick($event)"></p-button>
          <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)"></p-button>
        </div>


    </div>

    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
      <div class="loader"></div>  
    </div>

</div>  

</p-card> 
</div>

</div>  

<!--  G R I D  -->
  
<div class="grid">
    <div class="col-10">

      <p-table #dtt [columns]="communicationHistoryCols" [value]="communicationHistories" selectionMode="single" 
          [(selection)]="selectedCommunicationHistory" 
          tableStyleClass="prime-table" [rowsPerPageOptions]="[10,15,25,50]" [showCurrentPageReport]="true"
          sortMode="multiple" (onRowSelect)="onCommunicationHistoryRowSelect($event)" [paginator]="true" [rows]="15">
        <ng-template pTemplate="caption">
          Log of Communication History
        </ng-template>
        <ng-template pTemplate="caption">
          <div style="text-align: right">        
              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dtt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
          </div>
      </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
<!--
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                <input *ngSwitchCase="'brandCode'" pInputText type="text" (input)="dtt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'taskCode'" pInputText type="text" (input)="dtt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'status'" pInputText type="text" (input)="dtt.filter($event.target.value, col.field, 'contains')" [style]="{'width':'50%'}">
                <input *ngSwitchCase="'environment'" pInputText type="text" (input)="dtt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'host'" pInputText type="text" (input)="dtt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'exitCode'" pInputText type="text" (input)="dtt.filter($event.target.value, col.field, 'contains')">
            </th>
          </tr>
--> 
      </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
              <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{rowData[col.field]}}
              </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
          <div style="text-align:left">
          </div>
        </ng-template>    
      </p-table> 

    </div>
  </div>


