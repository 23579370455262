import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field'; 

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
// import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';

import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ToolbarModule } from 'primeng/toolbar';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview'
import { PickListModule } from 'primeng/picklist';
import { ListboxModule } from 'primeng/listbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageModule } from 'primeng/message';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { TreeModule } from 'primeng/tree';
import { PasswordModule }  from 'primeng/password';
import { EditorModule } from 'primeng/editor';
import { DataViewModule } from 'primeng/dataview'; // Here
import { TagModule } from 'primeng/tag';
import { InputSwitchModule } from 'primeng/inputswitch';


import { ColorPickerModule } from 'ngx-color-picker';

import { AuthInterceptor } from './interceptors/auth.interceptor';

// import { BnNgIdleService } from 'bn-ng-idle'; // User Idle / Session Timout detector service
import { provideUserIdleConfig } from 'angular-user-idle';

//import { NgxQueryBuilderModule } from 'ngx-query-builder';
import { NgxAngularQueryBuilderModule } from "ngx-angular-query-builder";
import { CronEditorModule } from 'ngx-cron-editor';

// import { fileSaver } from 'file-saver';
// import { QuillModule } from 'ngx-quill'

import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component'
import { HeaderComponent } from './navigation/header/header.component';

import { BrandAdminHeaderComponent } from './components/brand-admin-header/brand-admin-header.component';
import { ChooseBrandComponent } from './components/choose-brand/choose-brand.component';
import { ChoosePassTemplateComponent } from './components/choose-pass-template/choose-pass-template.component';
import { DynamicAccountFieldsComponent } from './components/dynamic-account-fields/dynamic-account-fields.component';
import { DynamicEnrollmentLinkComponent } from './components/dynamic-enrollment-link/dynamic-enrollment-link.component';
import { DynamicEvFieldComponent } from './components/dynamic-ev-field/dynamic-ev-field.component';
import { DynamicOfferFieldAttributeComponent } from './components/dynamic-offer-field-attribute/dynamic-offer-field-attribute.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { MessagesComponent } from './messages/messages.component';
import { AccountInfoComponent } from './portal/account-info/account-info.component';
import { AccountProfileComponent } from './portal/account-profile/account-profile.component';
import { AccountTasksComponent } from './portal/account-tasks/account-tasks.component';
import { BrandBootstrapComponent } from './portal/brand-bootstrap/brand-bootstrap.component';
import { BrandConfigPropertyComponent } from './portal/brand-config-property/brand-config-property.component';
import { BrandImagesComponent } from './portal/brand-images/brand-images.component';
import { BrandListingComponent } from './portal/brand-listing/brand-listing.component';
import { BrandMigrationComponent } from './portal/brand-migration/brand-migration.component';
import { BrandOffersComponent } from './portal/brand-offers/brand-offers.component';
import { BrandPassTemplatesComponent } from './portal/brand-pass-templates/brand-pass-templates.component';
import { BrandProfileComponent } from './portal/brand-profile/brand-profile.component';
import { BrandVariablesComponent } from './portal/brand-variables/brand-variables.component';
import { ClickthroughStatsComponent } from './portal/clickthrough-stats/clickthrough-stats.component';
import { ClientDashboardComponent } from './portal/client-dashboard/client-dashboard.component';
import { CommunicationHistoryComponent } from './portal/communication-history/communication-history.component';
import { CommunicationSubscribersComponent } from './portal/communication-subscribers/communication-subscribers.component';
import { ConfigMappingsComponent } from './portal/config-mappings/config-mappings.component';
import { ConfigPropertyComponent } from './portal/config-property/config-property.component';
import { CsrAccountComponent } from './portal/csr-account/csr-account.component';
import { EmailSmsTemplatesComponent } from './portal/email-sms-templates/email-sms-templates.component';
import { EncryptionUtilityComponent } from './portal/encryption-utility/encryption-utility.component';
import { EnrollmentInvitationComponent } from './portal/enrollment-invitation/enrollment-invitation.component';
import { EnrollmentLinksComponent } from './portal/enrollment-links/enrollment-links.component';
import { EnrollmentReportComponent } from './portal/enrollment-report/enrollment-report.component';
import { EnrollmentStatsComponent } from './portal/enrollment-stats/enrollment-stats.component';
import { EnrollmentTrendComponent } from './portal/enrollment-trend/enrollment-trend.component';
import { GroupOnboardComponent } from './portal/group-onboard/group-onboard.component';
import { HomeComponent } from './portal/home/home.component';
import { HomeBillerComponent } from './portal/home-biller/home-biller.component';
import { LoginComponent } from './portal/login/login.component';
import { MessageBroadcastingComponent } from './portal/message-broadcasting/message-broadcasting.component';
import { MessageProfileComponent } from './portal/message-profile/message-profile.component';
import { MessageTemplatesComponent } from './portal/message-templates/message-templates.component';
import { MonitoringComponent } from './portal/monitoring/monitoring.component';
import { NotificationStatsComponent } from './portal/notification-stats/notification-stats.component';
import { OfferFieldsComponent } from './portal/offer-fields/offer-fields.component';
import { PortalRolesComponent } from './portal/portal-roles/portal-roles.component';
import { PortalUsersComponent } from './portal/portal-users/portal-users.component';
import { QrcodeGenerationComponent } from './portal/qrcode-generation/qrcode-generation.component';
import { RtpnTransactionsComponent } from './portal/rtpn-transactions/rtpn-transactions.component';
import { ScheduledEventsComponent } from './portal/scheduled-events/scheduled-events.component';
import { SummaryReportsComponent } from './portal/summary-reports/summary-reports.component';
import { TestSuiteComponent } from './portal/test-suite/test-suite.component';
import { UserProfileComponent } from './portal/user-profile/user-profile.component';
import { UsersComponent } from './portal/users/users.component';
import { ScheduledEventsListingComponent } from './portal/scheduled-events-listing/scheduled-events-listing.component';
import { RtpnSimulatorComponent } from './portal/rtpn-simulator/rtpn-simulator.component';
import { FilterBuilderComponent } from './components/filter-builder/filter-builder.component';
import { AdhocNotificationComponent } from './portal/adhoc-notification/adhoc-notification.component';
import { AdhocMessageTemplateComponent } from './components/adhoc-message-template/adhoc-message-template.component';
import { AdhocMessageConfigComponent } from './portal/adhoc-message-config/adhoc-message-config.component';
import { CronEditorComponent } from './components/cron-editor/cron-editor.component';
import { MessageExecViewerComponent } from './portal/message-exec-viewer/message-exec-viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent,
    SidenavListComponent,
    HeaderComponent,
    BrandAdminHeaderComponent,
    ChooseBrandComponent,
    ChoosePassTemplateComponent,
    DynamicAccountFieldsComponent,
    DynamicEnrollmentLinkComponent,
    DynamicEvFieldComponent,
    DynamicOfferFieldAttributeComponent,
    SideMenuComponent,
    // MessagesComponent,
    AccountInfoComponent,
    AccountProfileComponent,
    AccountTasksComponent,
    BrandBootstrapComponent,
    BrandConfigPropertyComponent,
    BrandImagesComponent,
    BrandListingComponent,
    BrandMigrationComponent,
    BrandOffersComponent,
    BrandPassTemplatesComponent,
    BrandProfileComponent,
    BrandVariablesComponent,
    ClickthroughStatsComponent,
    ClientDashboardComponent,
    CommunicationHistoryComponent,
    CommunicationSubscribersComponent,
    ConfigMappingsComponent,
    ConfigPropertyComponent,
    CsrAccountComponent,
    EmailSmsTemplatesComponent,
    EncryptionUtilityComponent,
    EnrollmentInvitationComponent,
    EnrollmentLinksComponent,
    EnrollmentReportComponent,
    EnrollmentStatsComponent,
    EnrollmentTrendComponent,
    GroupOnboardComponent,
    HomeComponent,
    HomeBillerComponent,
    LoginComponent,
    MessageBroadcastingComponent,
    MessageProfileComponent,
    MessageTemplatesComponent,
    MonitoringComponent,
    NotificationStatsComponent,
    OfferFieldsComponent,
    PortalRolesComponent,
    PortalUsersComponent,
    QrcodeGenerationComponent,
    RtpnTransactionsComponent,
    ScheduledEventsComponent,
    SummaryReportsComponent,
    TestSuiteComponent,
    UserProfileComponent,
    UsersComponent,
    ScheduledEventsListingComponent,
    RtpnSimulatorComponent,
    FilterBuilderComponent,
    AdhocNotificationComponent,
    AdhocMessageTemplateComponent,
    AdhocMessageConfigComponent,
    CronEditorComponent,
    MessageExecViewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    ReactiveFormsModule,
    FormsModule,

    MatMenuModule, MatIconModule, MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatExpansionModule,
    MatFormFieldModule, MatDatepickerModule, MatInputModule,
    // MatStepperModule,

    // fileSaver,
    // QuillModule,

    TableModule, PaginatorModule, DialogModule, ButtonModule, DropdownModule, EditorModule, InputMaskModule,
    CalendarModule, ToolbarModule, PanelModule, CheckboxModule, TabViewModule, PickListModule, ListboxModule, 
    InputTextModule, InputTextareaModule, ConfirmDialogModule, PasswordModule, CardModule, ChartModule, 
    MessageModule, RadioButtonModule, TreeModule, FileUploadModule, TooltipModule, ColorPickerModule, TagModule,
    InputSwitchModule,

    NgxAngularQueryBuilderModule,
    CronEditorModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ConfirmationService, 
    // BnNgIdleService,
    provideUserIdleConfig({ idle: 900, timeout: 60, ping: 120 }), // idle - 15 minutes, timeout: 1 minute
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
