import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { TokenStorageService } from '../safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";
import { IRtpnBiller } from '../../interfaces/rtpn-biller';
import { IRtpnBarcodeResponse } from '../../interfaces/rtpn-barcode-response';
import { IRtpnRequest } from '../../interfaces/rtpn-request';
import { IRtpnTransaction } from '../../interfaces/rtpn-transaction';

@Injectable({
  providedIn: 'root'
})
export class RtpnSimulatorService {

  protected baseUrl = environment.apiServer.portalUrl.replace('portal/api','rtpn/api');
  protected portalBaseUrl = environment.apiServer.portalUrl;
  private rtpnUrl =  environment.apiServer.rtpnUrl;
  protected baseAuth = environment.appSetting.baseAuth; 

  private barcodeUrl = this.portalBaseUrl + '/rtpn-barcodes/brands/{brandCode}/accounts/{accountId}';
  private transactionUrl = this.rtpnUrl + '/simulator';
  private rtpnBillersUrl = this.rtpnUrl + '/simulator/billers';
  private rtpnTxnLogUrl = this.rtpnUrl + '/rtpn-txn/details';

  constructor(
    private http: HttpClient, 
    private sharedService: SharedService,
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService     
  ) { }

  getRtpnBillers(): Observable<IRtpnBiller[]> {
    return this.http.get<IRtpnBiller[]>(this.rtpnBillersUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getBarcode(brandCode: string, accountId: string): Observable<IRtpnBarcodeResponse> {
    var url = this.barcodeUrl.replace('{brandCode}', brandCode).replace('{accountId}', accountId);
    console.log('getBarcode URL: ', url);
    return this.http.get<IRtpnBarcodeResponse>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)    
    )
  }

  processRtpnTransaction(barcodeValue: string, amount: number): Observable<any> {
    let rtpnRequest: IRtpnRequest = {
      barcodeValue: barcodeValue,
      amount: amount
    }
    console.log('rtpnRequest', rtpnRequest);
    console.log('transactionUrl: ', this.transactionUrl);
    return this.http.post<any>(this.transactionUrl, rtpnRequest, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)    
    )
  }

  getTxnsLog(dateFrom: string, dateTo: string): Observable<IRtpnTransaction[]> {
    dateFrom = this.sharedService.getFormattedDate(dateFrom);
    dateTo = this.sharedService.getFormattedDate(this.sharedService.getNextDate(dateTo));
    let request = {
      brandCode: null, 
      requestType: 'P', 
      status: null, 
      accountNumber: null,
      txnDateFrom:  this.sharedService.getFormattedDate(dateFrom) ?? null, 
      txnDateTo:  this.sharedService.getFormattedDate(dateTo) ?? null, 
      rtpnRequestId: 0,
      limit: 25
    }
    return this.http.post<IRtpnTransaction[]>(this.rtpnTxnLogUrl, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)    
    )
  }

  getBarcodeImage(barcodeImageUrl: string): Observable<Blob> {
    let headers = new HttpHeaders()
      .append('Access-Control-Allow-Origin', '*');
    return this.http.get<Blob>(barcodeImageUrl, {headers: headers, responseType: 'blob' as 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)    
    )
  }

}
