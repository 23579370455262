import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MethodResponse } from '../../models/method-response.model';
import { IBrandVariable } from '../../interfaces/brand-variable';
import { IPassTemplate } from '../../interfaces/pass-template';
import { IBrandImage } from '../../interfaces/brand-image';
import { IPassType } from '../../interfaces/pass-type';
import { IMessageTemplate } from '../../interfaces/message-template';
import { IEmailSmsTemplate } from '../../interfaces/email-sms-template';
import { IOffer } from '../../interfaces/offer';
import { IOfferField } from '../../interfaces/offer-field';
import { IEnrollmentLink } from '../../interfaces/enrollment-link';
import { IScheduledEvent } from '../../interfaces/scheduled-event';
import { IScheduledEventsResponse } from '../../interfaces/scheduled-events-response';
import { ILinkCategory }  from '../../interfaces/link-category';
import { IOfferFieldType }  from '../../interfaces/offer-field-type';
import { IBrandProfile }  from '../../interfaces/brand-profile';
import { IConfigMapping }  from '../../interfaces/config-mapping';
import { IConfigMappingDescriptor }  from '../../interfaces/config-mapping-descriptor';
import { IMessageDestination }  from '../../interfaces/message-destination';
// import { AppConfigService} from '../../services/app-config.service';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { SharedService } from '../../services/shared.service';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class BrandProfileService {

  // protected portalUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected portalAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected portalUrl = environment.apiServer.portalUrl;
  protected portalAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;

  private brandBootstrapUrl = this.portalUrl + '/brand-admin/brand-bootstrap';
  private activateBrandUrl = this.portalUrl + '/brand-admin/activate/{brandCode}';
  private deactivateBrandUrl = this.portalUrl + '/brand-admin/deactivate/{brandCode}';

  private brandVariablesUrl = '/variable-admin/brands/{brandCode}/variables';
  private brandChangeIndVariablesUrl = '/variable-admin/brands/{brandCode}/change-ind-candidates';
  
  private passTemplatesUrl = '/pass-admin/brands/{brandCode}/passes';
  private passTemplateUrl = '/pass-admin/brands/{brandCode}/passes/{passTemplateId}';  
  private passTemplateCloneUrl = '/pass-admin/brands/{brandCode}/passes/{passTemplateId}/clone';  
  private passTemplateCloneToOtherBrandUrl = '/pass-admin/brands/{brandCode}/passes/{passTemplateId}/clone-to-other-brand';  

  private brandImagesUrl = '/image-admin/brands/{brandCode}/images';
  private brandLogoImageUrl = '/image-admin/brands/{brandCode}/logo';

  private passTypesUrl = '/passtype-admin/passtypes';

  private messageTemplatesUrl = '/msg-template-admin/brands/{brandCode}/passes/{passTemplateId}/msg-templates';
  private messageTemplateUrl = '/msg-template-admin/brands/{brandCode}/passes/{passTemplateId}/msg-templates/{messageTemplateId}';

  private brandOffersUrl = '/offer-admin/brands/{brandCode}/offers';
  private brandOfferUrl = '/offer-admin/brands/{brandCode}/offers/{offerId}';
  private brandOfferCloneUrl = '/offer-admin/brands/{brandCode}/offers/{offerId}/clone';
  private brandOfferCloneToOtherBrandUrl = '/offer-admin/brands/{brandCode}/offers/{offerId}/clone-to-other-brand';

  private brandOfferFieldOrderUrl = '/field-admin/brands/{brandCode}/offers/{offerId}/fields-order';
  private offerFieldsUrl = '/field-admin/brands/{brandCode}/offers/{offerId}/fields';
  private offerFieldUrl = '/field-admin/brands/{brandCode}/offers/{offerId}/fields/{fieldId}';

  private enrollmentLinksUrl = '/enrollment-link-admin/brands/{brandCode}/passes/{passTemplateId}/offers/{offerId}';
  private enrollmentLinksWithChannel = '/enrollment-link-admin/brands/{brandCode}/passes/{passTemplateId}/offers/{offerId}/channels/{srcChannel}';

  private emailSmsTemplatesUrl = '/template-admin/brands/{brandCode}/templates';
  private emailSmsTemplateUrl = '/template-admin/brands/{brandCode}/templates/{templateId}';

  private scheduledEventsUrl = '/admin/scheduled-events';
  private scheduledEventUrl = '/admin/scheduled-event/{eventName}';
  private messageDestinationsUrl = '/admin/message-types';
  private disableScheduledEventsUrl = '/admin/scheduled-events/{brandCode}/disable';

  private linkCategoriesUrl = '/metadata/link-categories';
  private offerFieldTypesUrl = '/metadata/field-types';

  private brandProfileUrl = '/brand-admin/brands/{brandCode}';

  private shortenUrl = "/ver1/shorten?longUrl={longUrl}";
  private barCodeUrl = "/admin/barcode/image?txt={txt}&size={size}";
  private sourceChannelsUrl = "/admin/brandconfig/{brandCode}/channels";

  private configMappingsTemplateNamesUrl = "/admin/mappings/template-names";
  private configMappingsUrl = "/admin/mappings/{brandCode}";
  private configMappingUrl = "/admin/mappings/{mappingId}";
  private configMappingSpecUrl = "/admin/mappings/spec/{mappingId}";
  private configMappingEditUrl = "/admin/mappings/{brandCode}/{mappingName}";
  private configMappingCloneUrl = "/admin/mappings/{mappingName}/clone/{brandCode}";

  // @Path("client-admin/clients")
  // @Path("brand-admin/brands")
  // brand-admin/bootstrap
  // @Path("brand-admin/export/{brandCode}")
  // @Path("brand-admin/import/{targetBrandCode}")
  
  // private portalHeaders = new HttpHeaders()
  //   .set('Authorization', this.portalAuth);
  // private baseHeaders = new HttpHeaders()
  //   .set('Authorization', this.baseAuth);

  // private methodResponse: MethodResponse = new MethodResponse(); 

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private sharedService: SharedService,
    private errorProcessorService: ErrorProcessorService 
    ) { 
      let currUser = this.tokenStorageService.getCurrentUser() ? this.tokenStorageService.getCurrentUser().userName : null;
      // this.portalHeaders = this.portalHeaders.append('mobills-user', currUser);
  }

  // Brand bootstraping services

  bootstrapBrandProfile(brandProfile: any, logoImageFile: any, appleIconImageFile: any, googleIconImageFile: any) {
      let formData = new FormData();
      formData.append("logoImage", logoImageFile);
      formData.append("appleIconImage", appleIconImageFile);
      formData.append("googleIconImage", googleIconImageFile);
      formData.append("brandMetaData", JSON.stringify(brandProfile));
      return this.http.post<any>(this.brandBootstrapUrl, formData, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  // Brand variables services

  getBrandVariables(brandCode: string, baseUrl: string): Observable<IBrandVariable[]> {
      var url = baseUrl + this.brandVariablesUrl.replace('{brandCode}', brandCode);
      return this.http.get<IBrandVariable[]>(url, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  getBrandVariable(brandCode: string, variableId: number, baseUrl: string): Observable<IBrandVariable> {
      var url = baseUrl + this.brandVariablesUrl.replace('{brandCode}', brandCode) + '/' + variableId;
      return this.http.get<IBrandVariable>(url, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  getBrandChangeIndicatorVariables(brandCode: string, baseUrl: string): Observable<string> {
      var url = baseUrl + this.brandChangeIndVariablesUrl.replace('{brandCode}', brandCode);
      return this.http.get<string>(url, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  submitBrandVariable(brandCode: string, brandVariable: any, baseUrl: string) {
    if (brandVariable.variableId) {
      return this.modifyBrandVariable(brandCode, brandVariable, baseUrl);
    } else {
      return this.addBrandVariable(brandCode, brandVariable, baseUrl);
    }
  }

  addBrandVariable(brandCode: string, brandVariable: any, baseUrl: string): Observable<IBrandVariable> {
    let newBrandVariable: IBrandVariable = 
    {
      variableId: 0, 
      variableName: brandVariable.variableName, 
      datatype: brandVariable.datatype, 
      tableName: '',
      variableMetadata: brandVariable.variableMetadata,
      evalScope: brandVariable.evalScope,
      defaultValue: brandVariable.defaultValue ? brandVariable.defaultValue : null, 
      evalExpression: brandVariable.evalExpression != '[none]' ? brandVariable.evalExpression : null,  
      createDate: '',
      lastUpdateDate: ''
    };
    var url = baseUrl + this.brandVariablesUrl.replace('{brandCode}', brandCode);
    return this.http.post<IBrandVariable>(url, newBrandVariable, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  modifyBrandVariable(brandCode: string, brandVariable: any, baseUrl: string): Observable<IBrandVariable> {
    let modifiedBrandVariable: IBrandVariable = 
    {
      variableId: brandVariable.variableId, 
      variableName: brandVariable.variableName, 
      datatype: brandVariable.datatype, 
      tableName: '',
      variableMetadata: brandVariable.variableMetadata,
      evalScope: brandVariable.evalScope,
      defaultValue: brandVariable.defaultValue ? brandVariable.defaultValue : null, 
      evalExpression: brandVariable.evalExpression != '[none]' ? brandVariable.evalExpression : null,  
      createDate: brandVariable.createDate ? this.sharedService.formatToISO(brandVariable.createDate) : '',
      lastUpdateDate: brandVariable.lastUpdateDate ? this.sharedService.formatToISO(brandVariable.lastUpdateDate) : ''
    };
    var url = baseUrl + this.brandVariablesUrl.replace('{brandCode}', brandCode) + '/' + brandVariable.variableId;
    // console.log('modifiedBrandVariable', modifiedBrandVariable);
    return this.http.put<IBrandVariable>(url, modifiedBrandVariable, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deleteBrandVariable(brandCode: string, variableId: number, baseUrl: string): Observable<string> {
      var url = baseUrl + this.brandVariablesUrl.replace('{brandCode}', brandCode) + '/' + variableId;
      return this.http.delete<string>(url, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  // Brand pass template services

  getPassTemplates(brandCode: string, baseUrl: string): Observable<IPassTemplate[]> {
    var url = baseUrl + this.passTemplatesUrl.replace('{brandCode}', brandCode);
    return this.http.get<IPassTemplate[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getPassTemplate(brandCode: string, passTemplateId: number, baseUrl: string): Observable<IPassTemplate> {
    var url = baseUrl + this.passTemplateUrl
      .replace('{brandCode}', brandCode)
      .replace('{passTemplateId}',passTemplateId.toString());
    return this.http.get<IPassTemplate>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  submitPassTemplate(brandCode: string, passTemplate: any, baseUrl: string) {
    if (passTemplate.passId) {
      return this.modifyPassTemplate(brandCode, passTemplate, baseUrl);
    } else {
      return this.addPassTemplate(brandCode, passTemplate, baseUrl);
    }
  }

  addPassTemplate(brandCode: string, passTemplate: any, baseUrl: string): Observable<IPassTemplate> {
    let newPassTemplate: IPassTemplate = 
    {
      passId: 0,
      getLatestOfferOnFetch: passTemplate.getLatestOfferOnFetch,
      newCPonDiffOvers: passTemplate.newCPonDiffOvers,
      passCache: passTemplate.passCache,
      fetchLimit: passTemplate.fetchLimit,
      passtypeName: passTemplate.passtypeName,
      imageIconUID: passTemplate.imageIconUID != '[none]' ? passTemplate.imageIconUID : null,
      imageLogoUID: passTemplate.imageLogoUID != '[none]' ? passTemplate.imageLogoUID : null, 
      gwIconImageUID: passTemplate.gwIconImageUID != '[none]' ? passTemplate.gwIconImageUID : null, 
      formatVersion: passTemplate.formatVersion,
      brandCode: passTemplate.brandCode,
      gwAllowMultipleUsers: passTemplate.gwAllowMultipleUsers,
      randomDigits: passTemplate.randomDigits,
      passName: passTemplate.passName,
      passDesc: passTemplate.passDesc,
      foregroundColor: passTemplate.foregroundColor.replace('#',''),
      backgroundColor: passTemplate.backgroundColor.replace('#',''),
      labelColor: passTemplate.labelColor.replace('#',''),
      gwAccountIdLabel: passTemplate.gwAccountIdLabel,
      supportedWalletPlatforms: passTemplate.supportedWalletPlatforms,
      ignoreDaq: passTemplate.ignoreDaq,
      associatedApp: passTemplate.associatedApp,    
      logoText: passTemplate.logoText,
      gwHeroImageUID: passTemplate.gwHeroImageUID != '[none]' ? passTemplate.gwHeroImageUID : null, 
      initMsgTemplateCode: passTemplate.initMsgTemplateCode != '[none]' ? passTemplate.initMsgTemplateCode : null,
      gwIssuerName: passTemplate.gwIssuerName,
      gwProgramName: passTemplate.gwProgramName,
      gwAccountNameLabel: passTemplate.gwAccountNameLabel,
      createDate: '',
      lastUpdateDate: '',
      gwClassId: passTemplate.gwClassId
    };
    var url = baseUrl + this.passTemplatesUrl.replace('{brandCode}', brandCode);
    return this.http.post<IPassTemplate>(url, newPassTemplate, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  modifyPassTemplate(brandCode: string, passTemplate: any, baseUrl: string): Observable<IPassTemplate> {
    let updatedPassTemplate: IPassTemplate = 
    {
      passId: passTemplate.passId,
      getLatestOfferOnFetch: passTemplate.getLatestOfferOnFetch,
      newCPonDiffOvers: passTemplate.newCPonDiffOvers,
      passCache: passTemplate.passCache,
      fetchLimit: passTemplate.fetchLimit,
      passtypeName: passTemplate.passtypeName,
      imageIconUID: passTemplate.imageIconUID != '[none]' ? passTemplate.imageIconUID : null,
      imageLogoUID: passTemplate.imageLogoUID != '[none]' ? passTemplate.imageLogoUID : null, 
      gwIconImageUID: passTemplate.gwIconImageUID != '[none]' ? passTemplate.gwIconImageUID : null, 
      formatVersion: passTemplate.formatVersion,
      brandCode: passTemplate.brandCode,
      gwAllowMultipleUsers: passTemplate.gwAllowMultipleUsers,
      randomDigits: passTemplate.randomDigits,
      passName: passTemplate.passName,
      passDesc: passTemplate.passDesc,
      foregroundColor: passTemplate.foregroundColor.replace('#',''),
      backgroundColor: passTemplate.backgroundColor.replace('#',''),
      labelColor: passTemplate.labelColor.replace('#',''),
      gwAccountIdLabel: passTemplate.gwAccountIdLabel,
      supportedWalletPlatforms: passTemplate.supportedWalletPlatforms,
      ignoreDaq: passTemplate.ignoreDaq,
      associatedApp: passTemplate.associatedApp,    
      logoText: passTemplate.logoText,
      gwHeroImageUID: passTemplate.gwHeroImageUID != '[none]' ? passTemplate.gwHeroImageUID : null, 
      initMsgTemplateCode: passTemplate.initMsgTemplateCode != '[none]' ? passTemplate.initMsgTemplateCode : null,
      gwIssuerName: passTemplate.gwIssuerName,
      gwProgramName: passTemplate.gwProgramName,
      gwAccountNameLabel: passTemplate.gwAccountNameLabel,
      createDate: passTemplate.createDate ? this.sharedService.formatToISO(passTemplate.createDate) : '',
      lastUpdateDate: passTemplate.createDate ? this.sharedService.formatToISO(passTemplate.createDate) : '',
      gwClassId: passTemplate.gwClassId
    };
    var url = baseUrl + this.passTemplatesUrl.replace('{brandCode}', brandCode) + '/' + passTemplate.passId;
    return this.http.put<IPassTemplate>(url, updatedPassTemplate, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deletePassTemplate(brandCode: string, passTemplateId: number, baseUrl: string): Observable<string> {
    var url = baseUrl + this.passTemplatesUrl.replace('{brandCode}', brandCode) + '/' + passTemplateId;
    return this.http.delete<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  clonePassTemplate(brandCode: string, passTemplateId: number, baseUrl: string): Observable<IPassTemplate> {
    var url = baseUrl + this.passTemplateCloneUrl
      .replace('{brandCode}', brandCode)
      .replace('{passTemplateId}', passTemplateId.toString());
    return this.http.post<IPassTemplate>(url, null, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  clonePassTemplateToOtherBrand(passTemplateRequest: any, baseUrl: string):Observable<IPassTemplate> {
    let sourceBrandCode = passTemplateRequest.sourceBrandCode;
    let sourcePassId = passTemplateRequest.sourcePassId.toString();
    let cloneRequest = {
      targetBrandCode: passTemplateRequest.targetBrandCode,
      targetName: passTemplateRequest.targetPassTemplateName,
      targetDesc: passTemplateRequest.targetPassTemplateDescription
    }
    let formData = new FormData();
    formData.append("appleLogoImage", passTemplateRequest.appleLogoImage);
    formData.append("appleIconImage", passTemplateRequest.appleIconImage);
    formData.append("googleLogoImage", passTemplateRequest.googleLogoImage);
    formData.append("googleIconImage", passTemplateRequest.googleIconImage);
    formData.append("passMetaData", JSON.stringify(cloneRequest));
    var url = baseUrl + this.passTemplateCloneToOtherBrandUrl
      .replace('{brandCode}', sourceBrandCode)
      .replace('{passTemplateId}', sourcePassId);
    return this.http.post<IPassTemplate>(url, formData, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Misc services
  getPassTypes(baseUrl: string): Observable<IPassType[]> {
    var url = baseUrl + this.passTypesUrl;
    return this.http.get<IPassType[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Message template services 

  getMessageTemplates(brandCode: string, passTemplateId: number, baseUrl: string): Observable<IMessageTemplate[]> {
    var url = baseUrl + this.messageTemplatesUrl
      .replace('{brandCode}', brandCode)
      .replace('{passTemplateId}',passTemplateId.toString());
    return this.http.get<IMessageTemplate[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getMessageTemplate(brandCode: string, passTemplateId: number, messageTemplateId: number, 
    baseUrl: string): Observable<IMessageTemplate> {
    var url = baseUrl + this.messageTemplateUrl
      .replace('{brandCode}', brandCode)
      .replace('{passTemplateId}',passTemplateId.toString())
      .replace('{messageTemplateId}',messageTemplateId.toPrecision());
    return this.http.get<IMessageTemplate>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  submitMessageTemplate(brandCode: string, passTemplateId: number, messageTemplate: any, baseUrl: string): Observable<IMessageTemplate> {
    if (messageTemplate.messageTemplateId) {
      var url = baseUrl + this.messageTemplateUrl
        .replace('{brandCode}', brandCode)
        .replace('{passTemplateId}', passTemplateId.toPrecision())
        .replace('{messageTemplateId}', messageTemplate.messageTemplateId);
      return this.http.put<IMessageTemplate>(url, messageTemplate, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    } else {
      var url = baseUrl + this.messageTemplatesUrl
        .replace('{brandCode}', brandCode)
        .replace('{passTemplateId}',passTemplateId.toString());
      return this.http.post<IMessageTemplate>(url, messageTemplate, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    }
  }

  deleteMessageTemplate(brandCode: string, passTemplateId: number, messageTemplateId: number, baseUrl: string): Observable<string> {
    var url = baseUrl + this.messageTemplateUrl
      .replace('{brandCode}', brandCode)
      .replace('{passTemplateId}', passTemplateId.toString())
      .replace('{messageTemplateId}', messageTemplateId.toString());
    return this.http.delete<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Image services

  getBrandImages(brandCode: string, baseUrl: string): Observable<IBrandImage[]> {
    var url = baseUrl + this.brandImagesUrl.replace('{brandCode}', brandCode);
    return this.http.get<IBrandImage[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addBrandImage(brandCode: string, imageDescr: any, baseUrl: string): Observable<string> {
    var url = baseUrl + this.brandImagesUrl.replace('{brandCode}', brandCode);
    return this.http.post<string>(url, imageDescr, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deleteBrandImage(brandCode: string, imageUID: any, baseUrl: string): Observable<string> {
    var url = baseUrl + this.brandImagesUrl.replace('{brandCode}', brandCode) + '/' + imageUID;
    return this.http.delete<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getBrandLogoImage(brandCode: string, baseUrl: string): Observable<IBrandImage> {
    var url = baseUrl + this.brandLogoImageUrl.replace('{brandCode}', brandCode);
    return this.http.get<IBrandImage>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Brand field sets (offers) services

  getBrandOffers(brandCode: string, baseUrl: string): Observable<IOffer[]> {
    var url = baseUrl + this.brandOffersUrl.replace('{brandCode}', brandCode);
    return this.http.get<IOffer[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  

  getBrandOffer(brandCode: string, offerId: number, baseUrl: string): Observable<IOffer> {
    var url = baseUrl + this.brandOfferUrl
      .replace('{brandCode}', brandCode)
      .replace('{offerId}', offerId.toString());
    return this.http.get<IOffer>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  

  submitBrandOffer(brandCode: string, brandOffer: any, baseUrl: string) {
    if (brandOffer.offerId) {
      var url = baseUrl + this.brandOfferUrl
        .replace('{brandCode}', brandCode)
        .replace('{offerId}', brandOffer.offerId);
      return this.http.put<IOffer>(url, brandOffer, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    } else {
      var url = baseUrl + this.brandOffersUrl.replace('{brandCode}', brandCode);
      return this.http.post<IOffer>(url, brandOffer, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    }
  }

  deleteBrandOffer(brandCode: string, offerId: number, baseUrl: string): Observable<string> {
    var url = baseUrl + this.brandOfferUrl
      .replace('{brandCode}', brandCode)
      .replace('{offerId}', offerId.toString());
    return this.http.delete<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  cloneBrandOffer(brandCode: string, offerId: number, baseUrl: string): Observable<IOffer> {
    var url = baseUrl + this.brandOfferCloneUrl
      .replace('{brandCode}', brandCode)
      .replace('{offerId}', offerId.toString());
    return this.http.post<IOffer>(url, '', {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  

  cloneBrandOfferToOtherBrand(sourceBrandCode: string, sourceOfferId: number, targetBrandCode: string, targetOfferName: string, 
    targetOfferDesc: string, baseUrl: string): Observable<IOffer> {
      let cloneToOtherBrandRequest = {
        targetBrandCode: targetBrandCode,
        targetName: targetOfferName,
        targetDesc: targetOfferDesc
      }
      var url = baseUrl + this.brandOfferCloneToOtherBrandUrl.replace('{brandCode}', sourceBrandCode).replace('{offerId}', sourceOfferId.toString());
    return this.http.post<IOffer>(url, cloneToOtherBrandRequest, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  

  // Offer fields services

  getOfferFieldTypesOld() {
    var offerFieldTypes = [
      {label: '[none]',  value: '[none]'},
      // {label: 'Action',  value: 'actionField'},
      {label: 'Apple Wallet Sharing',  value: 'awShare'},
      {label: 'Auxiliary Field',  value: 'auxiliaryFields'},  
      {label: 'Back Field',  value: 'backFields'},  
      {label: 'Background Text Color',  value: 'bgColorField'},  
      {label: 'Background Image',  value: 'BackgroundImage'},  
      {label: 'Bar Code',  value: 'barCode'},  
      {label: 'Bar Code Text',  value: 'barCodeAlt'},
      {label: 'Bill Payment',  value: 'billPay'},
      {label: 'Custom Rendered',  value: 'customJsonField'},
      {label: 'Delay My Payment',  value: 'dmpField'},
      {label: 'Document Viewer',  value: 'docViewer'},
      {label: 'Enrollment Authorization Parameter',  value: 'enrollAuthParam'},
      // {label: 'Expiration Date',  value: 'expirationDate'},
      {label: 'Foreground Text Color',  value: 'fgColorField'},  
      {label: 'Foreground Label Color',  value: 'fgLblColorField'},
      // {label: 'Geo Locations',  value: 'locationsField'},
      {label: 'Google Pay Account ID',  value: 'gwAccountIdLabel'},
      {label: 'Google Pay Account Name',  value: 'gwAccountName'},
      // {label: 'Google Pay Message Container',  value: 'gwMsgField'},
      {label: 'Google Pay Landing Page Logo',  value: 'gwLandPageLogoOverride'},
      {label: 'Header',  value: 'headerFields'},  
      // {label: 'Language Code',  value: 'langCode'},
      // {label: 'Mail To',  value: 'mailtoField'},
      {label: 'Message Container',  value: 'msgField'},
      {label: 'Non-mobile Page Logo',  value: 'pbNonmobilePageLogoOverride'},
      {label: 'Non-mobile Email Template Override',  value: 'pbNonmobileTemplateOverride'},
      {label: 'Non-mobile SMS Template Override',  value: 'pbNonmobileSMSTemplateOverride'},
      {label: 'Notification Preferences Editor',  value: 'notifPrefEditor'},
      {label: 'Notification Schedule Descriptor',  value: 'notifSchedDescr'},  
      {label: 'Notification Schedule Item',  value: 'notifSchedItem'},
      {label: 'Personalized Image',  value: 'PersonalizedImage'},  
      {label: 'Primary Field',  value: 'primaryFields'},  
      {label: 'RTPN Barcode Link',  value: 'rtpnBarcodeField'},  
      // {label: 'Relevant Date',  value: 'relevantDate'},
      {label: 'Secondary Field',  value: 'secondaryFields'},  
      {label: 'SMS Phone Editor',  value: 'smsPhoneEditor'},  
      {label: 'SMS Country Code',  value: 'smsPrepend'},
      {label: 'Single Sign On',  value: 'ssoField'},  
      {label: 'Strip Image',  value: 'StripImage'}, 
      {label: 'Template Selector',  value: 'templateSelector'},
      {label: 'Triggered Action', value: 'trigActionField'}, 
      {label: 'Thumbnail Image',  value: 'ThumbnailImage'},
      {label: 'Variable Field', value: 'filterData'},  
      {label: 'WOB Refresher',  value: 'refreshWobFld'},
    ]
    return offerFieldTypes;
  }

  getOfferFieldTypes(baseUrl: string): Observable<IOfferFieldType[]>{
    var url = baseUrl + this.offerFieldTypesUrl;
    return this.http.get<IOfferFieldType[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getOfferFields(brandCode: string, offerId: number, baseUrl: string): Observable<IOfferField[]> {
    var url = baseUrl + this.offerFieldsUrl
      .replace('{brandCode}', brandCode)
      .replace('{offerId}', offerId.toString());
    return this.http.get<IOfferField[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  

  getOfferField(brandCode: string, offerId: number, fieldId: number, baseUrl: string): Observable<IOfferField> {
    var url = baseUrl + this.offerFieldUrl
      .replace('{brandCode}', brandCode)
      .replace('{offerId}', offerId.toString())
      .replace('{fieldId}', fieldId.toString());
    return this.http.get<IOfferField>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  

  submitOfferField(brandCode: string, offerId: number, offerField: any, baseUrl: string) {
    if (offerField.offerFieldId) {
      var url = baseUrl + this.offerFieldUrl
      .replace('{brandCode}', brandCode)
      .replace('{offerId}', offerId.toString())
      .replace('{fieldId}', offerField.offerFieldId);
      return this.http.put<IOfferField>(url, offerField, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    } else {
        var url = baseUrl + this.offerFieldsUrl
          .replace('{brandCode}', brandCode)
          .replace('{offerId}', offerId.toString());
        return this.http.post<IOfferField>(url, offerField, {responseType: 'json'})
        .pipe(
          catchError(this.errorProcessorService.handleError)
        )
    }
  }

  deleteOfferField(brandCode: string, offerId: number, fieldId: number, baseUrl: string): Observable<string> {
    var url = baseUrl + this.offerFieldUrl
      .replace('{brandCode}', brandCode)
      .replace('{offerId}', offerId.toString())
      .replace('{fieldId}', fieldId.toString());
    return this.http.delete<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  setOfferFieldsOrder(brandCode: string, offerId: number, orderedList: any, baseUrl: string) : Observable<IOfferField[]> {
    var url = baseUrl + this.brandOfferFieldOrderUrl
    .replace('{brandCode}', brandCode)
    .replace('{offerId}', offerId.toString()); 
    return this.http.put<IOfferField[]>(url, orderedList, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Enrollment Links

  getEnrollmentLinks(brandCode: string, passTemplateId: number, offerId: any, baseUrl: string): Observable<IEnrollmentLink[]> {
    var url = baseUrl + this.enrollmentLinksUrl
      .replace('{brandCode}', brandCode)
      .replace('{passTemplateId}', passTemplateId.toString())
      .replace('{offerId}', offerId);
    return this.http.get<IEnrollmentLink[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getEnrollmentLinksWithChannel(brandCode: string, passTemplateId: number, offerId: number, 
      channel: any, baseUrl: string): Observable<IEnrollmentLink[]> {
    var url = baseUrl + this.enrollmentLinksWithChannel
      .replace('{brandCode}', brandCode)
      .replace('{passTemplateId}', passTemplateId.toString())
      .replace('{offerId}', offerId.toString())
      .replace('{srcChannel}', channel);
    return this.http.get<IEnrollmentLink[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Email/SMS Templates

  getEmailSmsTemplates(brandCode: string, baseUrl: string): Observable<IEmailSmsTemplate[]> {
    var url = baseUrl + this.emailSmsTemplatesUrl.replace('{brandCode}', brandCode);
    return this.http.get<IEmailSmsTemplate[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getEmailSmsTemplate(brandCode: string, templateId: number, baseUrl: string): Observable<IEmailSmsTemplate> {
    var url = baseUrl + this.emailSmsTemplateUrl
      .replace('{brandCode}', brandCode)
      .replace('{templateId}',templateId.toString());
    return this.http.get<IEmailSmsTemplate>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  submitEmailSmsTemplate(brandCode: string, emailSmsTemplate: any, baseUrl: string): Observable<IEmailSmsTemplate> {
    if (emailSmsTemplate.templateID) {
      var url = baseUrl + this.emailSmsTemplateUrl
        .replace('{brandCode}', brandCode)
        .replace('{templateId}', emailSmsTemplate.templateID);
        return this.http.put<IEmailSmsTemplate>(url, emailSmsTemplate, {responseType: 'json'})
        .pipe(
          catchError(this.errorProcessorService.handleError)
        )
    } else {
      var url = baseUrl + this.emailSmsTemplatesUrl
        .replace('{brandCode}', brandCode);
      return this.http.post<IEmailSmsTemplate>(url, emailSmsTemplate, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    }
  }

  deleteEmailSmsTemplate(brandCode: string, templateId: number, baseUrl: string): Observable<string> {
    var url = baseUrl + this.emailSmsTemplateUrl
      .replace('{brandCode}', brandCode)
      .replace('{templateId}', templateId.toString());
    return this.http.delete<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Scheduled Events

  getScheduledEventsByCriteria(baseUrl: string, eventCriteria: any): Observable<IScheduledEventsResponse> {
    var url = this.schedulerBaseUrl(baseUrl) + this.scheduledEventsUrl;
    var params = new HttpParams();
    if (eventCriteria != null) {
      if (eventCriteria.eventName != null) {
        params = params.set('eventName', eventCriteria.eventName);
      }
      if (eventCriteria.msgDestinationType != null) {
        params = params.set('msgDestinationType', eventCriteria.msgDestinationType);
      }
      if (eventCriteria.msgDestinationName != null) {
        params = params.set('msgDestinationName', eventCriteria.msgDestinationName);
      }
      if (eventCriteria.enabled != null) {
        params = params.set('enabled', eventCriteria.enabled);
      }
      if (eventCriteria.sendToAll != null) {
        params = params.set('sendToAll', eventCriteria.sendToAll);
      }
    }
    return this.http.get<IScheduledEventsResponse>(url, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getScheduledEvents(brandCode: string, baseUrl: string): Observable<IScheduledEvent[]> {
    var url = this.schedulerBaseUrl(baseUrl) + this.scheduledEventsUrl  + '/brands/' + brandCode;
    return this.http.get<IScheduledEvent[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getScheduledEvent(eventName: string, baseUrl: string): Observable<IScheduledEvent> {
      var url = this.schedulerBaseUrl(baseUrl) + this.scheduledEventUrl.replace('{eventName}', eventName);
      return this.http.get<IScheduledEvent>(url, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  submitScheduledEvent(scheduledEvent: any, baseUrl: string) {
    let scheduledEvents = [scheduledEvent];
    if (scheduledEvent.createDate) {
      var url = this.schedulerBaseUrl(baseUrl) + this.scheduledEventsUrl;
      return this.http.put<string>(url, scheduledEvents, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    } else {
      var url = this.schedulerBaseUrl(baseUrl) + this.scheduledEventsUrl;
      return this.http.post<string>(url, scheduledEvents, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    }
  }

  deleteScheduledEvent(eventName: string, baseUrl: string): Observable<string> {
      var url = this.schedulerBaseUrl(baseUrl) + this.scheduledEventUrl.replace('{eventName}', eventName);
      return this.http.delete<string>(url, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  getMessageDestinations(baseUrl: string): Observable<IMessageDestination[]> {
    var url = this.schedulerBaseUrl(baseUrl) + this.messageDestinationsUrl;
    return this.http.get<IMessageDestination[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  disableScheduledEvents(brandCode: string, baseUrl: string) {
      var url = this.schedulerBaseUrl(baseUrl) 
        + this.disableScheduledEventsUrl.replace('{brandCode}', brandCode);
      return this.http.put<string>(url, null, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  schedulerBaseUrl(baseUrl: string) {
    if (baseUrl.toLowerCase().indexOf('scheduler/api') >= 0) {
      return baseUrl;
    }
    return baseUrl.toLowerCase().replace('walletron/api','scheduler/api');
  }

  // Clickthrough Categories
  getLinkCategories(baseUrl: string): Observable<ILinkCategory[]>{
    var url = baseUrl + this.linkCategoriesUrl;
    return this.http.get<ILinkCategory[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getBrandProfile(brandCode: string, baseUrl: string): Observable<IBrandProfile> {
    var url = baseUrl + this.brandProfileUrl.replace('{brandCode}', brandCode);
    return this.http.get<IBrandProfile>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  modifyBrandProfile (brandCode: string, baseUrl: string, modifiedBiller: IBrandProfile): Observable<IBrandProfile> {
    var url = baseUrl + this.brandProfileUrl.replace('{brandCode}', brandCode);
    debugger;
    return this.http.put<IBrandProfile>(url, modifiedBiller, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getShortenUrl(longUrl: string, baseUrl: string): Observable<string> {
    var url = baseUrl + this.shortenUrl
      .replace('{longUrl}', longUrl)
      .replace('&src=','%26src=');
      debugger;
    return this.http.get(url, {responseType: 'text'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getQrCode(txt: string, size: number, baseUrl: string): Observable<IBrandImage> {
    var url = baseUrl + this.barCodeUrl
      .replace('{txt}', txt)
      .replace('{size}', size.toString());
    return this.http.get<IBrandImage>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getSourceChannels(brandCode: string, baseUrl: string): Observable<string[]> {
    var url = baseUrl + this.sourceChannelsUrl
      .replace('{brandCode}', brandCode);
    return this.http.get<string[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Config mappings

  getConfigMappings(brandCode: string, baseUrl: string): Observable<IConfigMapping[]> {
    var url = baseUrl + this.configMappingsUrl
      .replace('{brandCode}', brandCode);
    return this.http.get<IConfigMapping[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getConfigMapping(mappingId: number, baseUrl: string): Observable<IConfigMapping> {
    var url = baseUrl + this.configMappingSpecUrl
    .replace('{mappingId}',mappingId.toString());
    return this.http.get<IConfigMapping>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  submitConfigMapping(configMapping: IConfigMapping, baseUrl: string) {
    let configMappingDescr: IConfigMappingDescriptor = {
      mappingId: configMapping.mappingId,
      brandCode: configMapping.brandCode,
      mappingName: configMapping.mappingName,
      mappingDescription: configMapping.mappingDescription,
      mappingSet: {
        mappingType: configMapping.mappingType,
        mappingElements: configMapping.mappingElements
      }
    };
    var url = baseUrl + this.configMappingEditUrl
      .replace('{brandCode}', configMapping.brandCode)
      .replace('{mappingName}', configMapping.mappingName);
    return this.http.post<any>(url, configMappingDescr, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  createConfigMapping(brandCode: string, mappingName: string,  baseUrl: string): Observable<string> {
    var url = baseUrl + this.configMappingCloneUrl
    .replace('{brandCode}', brandCode)
    .replace('{mappingName}', mappingName);
    return this.http.post<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deleteConfigMapping(mappingId: number, baseUrl: string): Observable<string> {
    var url = baseUrl + this.configMappingUrl
      .replace('{mappingId}',mappingId.toString());
    return this.http.delete<string>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getConfigMappingsTemplateNames(baseUrl: string): Observable<string[]> {
    var url = baseUrl + this.configMappingsTemplateNamesUrl;
    return this.http.get<string[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  activateBrand(brandCode: string) {
    var url = this.activateBrandUrl.replace('{brandCode}', brandCode);
    let request = {};
    return this.http.put<any>(url, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deactivateBrand(brandCode: string) {
    var url = this.deactivateBrandUrl.replace('{brandCode}', brandCode);
    let request = {};
    return this.http.put<any>(url, request, {responseType: 'json'})
   .pipe(
     catchError(this.errorProcessorService.handleError)
   )
 }

}



