import { Component, OnInit } from '@angular/core';
import { Validators,FormControl,FormGroup,FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { BrandProfile } from '../../models/brand-profile.model';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { LookupService } from '../../services/config/lookup.service';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { UserContextService } from '../../services/safeguard/user-context.service';

@Component({
  selector: 'app-brand-bootstrap',
  templateUrl: './brand-bootstrap.component.html',
  styleUrls: ['./brand-bootstrap.component.css']
})
export class BrandBootstrapComponent implements OnInit {

  userform!: FormGroup;
  isDisabled = false;
  isSubmitShown = false;
  resultMsg = "";
  billerType!: string;
  billerTypes: any[] = [];
  imageLogoFile!: File
  imageAppleIconFile!: File
  imageGoogleIconFile!: File

  isSpinnerHidden: boolean = true;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private lookupService: LookupService, 
    private tokenStorageService: TokenStorageService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.getBrandTemplates();
    this.userform = this.fb.group({
      'brandCode': new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]),
      'brandName': new FormControl('', [Validators.required, Validators.maxLength(45)]),
      'billerType': new FormControl('Speedpay', [Validators.required]),
      'secondaryAccountId': new FormControl(''),
      'iosAppId': new FormControl(''),
      // 'emailFromAddress': new FormControl('', [Validators.required]),
    });
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_VP_BRAND_BOOTSTRAP')) {
      this.isSubmitShown = true;
    }
   
  }

  getBrandTemplates(): void {
    this.lookupService.getBrandTemplates()
    .subscribe(items => {
      for(let i = 0; i < items.length; i++){
        let templateName = items[i].replace('.json', '');
        this.billerTypes.push({label: templateName, value: templateName});
      }
    });
  }

  onLogoFileChange(event: any) {
    this.imageLogoFile = event.files[0]
  }

  onApleIconFileChange(event: any) {
    this.imageAppleIconFile = event.files[0]
  }

  onGoogleIconFileChange(event: any) {
    this.imageGoogleIconFile = event.files[0]
  }


  onSubmit(value: string) {
    // populate brandProfile variable
    var currentUser = this.tokenStorageService.getCurrentUser() ? this.tokenStorageService.getCurrentUser().userName : null;
    let brandProfile: BrandProfile = {
      brandCode: this.userform.value.brandCode,
      brandName: this.userform.value.brandName,
      billerType: this.userform.value.billerType, 
      secondaryAccountId: this.userform.value.secondaryAccountId,
      iosAppId: this.userform.value.iosAppId,
      senderEmail: this.userform.value.emailFromAddress,
      modifiedUser: currentUser || ''
    };

    // Call the service to invoke a Web API call
    this.isSpinnerHidden = false;
    this.brandProfileService.bootstrapBrandProfile(brandProfile, this.imageLogoFile, this.imageAppleIconFile, this.imageGoogleIconFile)
    .subscribe({
      next: (response) => {
        this.isSpinnerHidden = true;
        // console.log("Success", response);
        this.isDisabled = true;
        this.isSubmitShown = false;
        this.resultMsg = response.message;
        this.alertTheUser(response.message);
      },
      error: (error) => {
        console.log('error',error);
        this.isSpinnerHidden = true;
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });

  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'bootstrap-brand',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }



}
