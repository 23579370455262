import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from './services/safeguard/token-storage.service';
import { SharedService } from "./services/shared.service";

import { Subscription } from "rxjs";

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Walletron Portal';
  currentUser: any;
  subscription!: Subscription;

  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private sharedService: SharedService) {
  }

  // initiate it in your component OnInit
  ngOnInit(): void {}

  logout() {
    // clean up current brand and template
    this.sharedService.setCurrentPassTemplate(this.sharedService.getDummyPassTemplate());
    this.sharedService.setCurrentOffer(this.sharedService.getDummyOffer());
    this.sharedService.setCurrentBrandCode('');
    this.sharedService.setCurrentBrand(this.sharedService.getDummyBrand());
  // Reset the idle service
    if (this.subscription){
      this.subscription.unsubscribe();
    }
            // console.log('app.component.ts', "called stopTimer()");
    // make the actual logout
    // this.authenticationService.logout();
    this.tokenStorageService.signOut();
    this.router.navigate(['/login']);
  }

}
