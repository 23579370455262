<h2>My Account</h2>
<!-- <p-message severity="info" text="My Account"></p-message> -->


<p-tabView>

    <p-tabPanel header="My Profile">

        <div class="col-4" *ngIf="isProfileFrameShown">

            <p-card header="User Profile" subheader="" styleClass="ui-card-light">
        
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="userId" class="col-fixed label" style="width: 10em">User Id:</label>
                    <div class="col-8">
                        <input pInputText id="userId" [(ngModel)]="userId" autofocus readonly="true" [style]="{'width': '20%'}"/>
                    </div>
                </div>
        
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="userName" class="col-fixed label" style="width: 10em">User Name:</label>
                    <div class="col-8">
                        <input pInputText id="userName" [(ngModel)]="userName" readonly="true" [style]="{'width': '60%'}"/>
                    </div>
                </div>
        
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="emailAddress" class="col-fixed label" style="width: 10em">Email Address: </label>
                    <div class="col-8">
                        <input pInputText type="email" id="emailAddress" [(ngModel)]="emailAddress"  [style]="{'width': '100%'}" readonly="true"
                            validatorMessage="Value does not match pattern." pTooltip="Examle: jane.doe@gmail.com" tooltipPosition="top" 
                                [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
                        <!-- <p-message severity="error" text="Incorrect email address specified" *ngIf="!emailForm.controls['customerEmailAddress'].valid&&emailForm.controls['customerEmailAddress'].dirty"></p-message>                  -->
                    </div> 
        
                </div>        
        
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="grantedBrands" class="col-fixed label" style="width: 10em">Granted Brands:</label>
                    <div class="col-8">
                        <input pInputText id="grantedBrands" [(ngModel)]="grantedBrands" readonly="true" [style]="{'width': '100%'}"/>
                    </div>
                </div>
        
                <div class="col-12"  *ngIf="!isChangePasswordFrameShown && !isChangeEmailAddressFrameShown">

                    <div class="ui-g btn-group" role="group">

                        <p-button type="button" label="Change Password" (click)="changePassword()"
                            styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                        </p-button>                                    

                        <p-button type="button" label="Change Email Adddress" (click)="changeEmailAddress()"
                            styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                        </p-button>                                    

                    </div>
        
<!-- 
                                    <button type="button" pButton (click)="changePassword()" label="Change Password" class="ui-button-info"></button>
-->
<!-- 
                                    <button type="button" pButton (click)="changeEmailAddress()" label="Change Email Adddress" class="ui-button-info"></button>
-->
        
                </div>        
        
                <p-footer>
                            
                    <mat-icon class="nav-caption wal-center">person</mat-icon>
        
                </p-footer>
        
            </p-card>
        
        </div>
        
        <div class="col-3" *ngIf="isChangePasswordFrameShown">
        
            <p-card header="Password Change" subheader="" styleClass="ui-card-light">

                <form [formGroup]="passwordForm">

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="password" class="col-fixed label" style="width: 10em">Existing Password:</label>
                        <div class="col-6">
                            <input pPassword type="password" id="password" formControlName="existingPassword"/>
                            <div *ngIf="!passwordForm.controls['existingPassword'].valid && passwordForm.controls['existingPassword'].dirty" class="alert alert-danger">
                                Incorrect existing password
                            </div>                                    
                        </div>
                    </div>
                    
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="newPassword" class="col-fixed label" style="width: 10em">New Password:</label>
                        <div class="col-6">
                            <input pPassword type="password" id="newPassword" formControlName="newPassword"/>
                            <div *ngIf="!passwordForm.controls['newPassword'].valid && passwordForm.controls['newPassword'].dirty" class="alert alert-danger">
                                Incorrect new password
                            </div>                                    
                        </div>
                    </div>
                    
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="confirmPassword" class="col-fixed label" style="width: 10em">Confirm Password:</label>
                        <div class="col-6">
                            <input pPassword type="password" id="confirmPassword"  formControlName="confirmPassword"/>
                            <div *ngIf="!passwordForm.controls['confirmPassword'].valid && passwordForm.controls['confirmPassword'].dirty" class="alert alert-danger">
                                Incorrect confirm password
                            </div>                                    
                        </div>
                    </div>
            
                    <p-footer>
            
                        <table class="table table-dark">
                            <tbody>
                                <tr>
                                    <td>
                                        <!-- <button type="submit" pButton (click)="onSubmitPasssword()" icon="pi pi-check" label="Submit" class="ui-button-success"></button> -->
                                        <p-button type="button" label="Submit" (click)="onSubmitPasssword()" [disabled]="!passwordForm.valid"
                                            styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                                        </p-button>                                    
                                    </td>
                                    <td>
                                        <!-- <button type="button" pButton (click)="onCancelPassword()" icon="pi pi-times" label="Cancel" class="ui-button-secondary" style="margin-right: .5em"></button> -->
                                        <p-button type="button" label="Cancel" (click)="onCancelPassword()"
                                            styleClass="p-button-primary p-button-raised p-button-rounded margin-left">
                                        </p-button>                                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>      
                                
                        <mat-icon class="nav-caption wal-center">lock_open</mat-icon>
            
                    </p-footer>
    
                </form>

            </p-card>
        
        </div>
        
        <div class="col-4" *ngIf="isChangeEmailAddressFrameShown">
        
            <p-card header="Email Address Change" subheader="" styleClass="ui-card-light">

                <form [formGroup]="emailForm">

                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="password" class="col-fixed label" style="width: 10em">Existing Password:</label>
                        <div class="col-8">
                            <input pPassword type="password" id="password" [feedback]="false" formControlName="existingPassword"/>
                            <div *ngIf="!emailForm.controls['existingPassword'].valid && emailForm.controls['existingPassword'].dirty" class="alert alert-danger">
                                Incorrect password
                            </div>                                    
                        </div>
                    </div>
                    
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="newEmailAddress" class="col-fixed label" style="width: 10em">New Email Address: </label>
                        <div class="col-8">
                            <input pInputText type="email" id="newEmailAddress" formControlName="newEmailAddress"  [style]="{'width': '100%'}" 
                                validatorMessage="Value does not match pattern." pTooltip="Examle: jane.doe@gmail.com" tooltipPosition="top" 
                                    [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
                            <div *ngIf="!emailForm.controls['newEmailAddress'].valid && emailForm.controls['newEmailAddress'].dirty" class="alert alert-danger">
                                Incorrect email address
                            </div>                                    
                        </div> 
            
                    </div>        
            
                    <p-footer>
            
                        <table class="table table-dark">
                            <tbody>
                                <tr>
                                    <td>
                                        <!-- <button type="submit" pButton (click)="onSubmitEmail()" icon="pi pi-check" label="Submit" class="ui-button-success"></button> -->
                                        <p-button type="button" label="Submit" (click)="onSubmitEmail()" [disabled]="!emailForm.valid"
                                            styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                                        </p-button>     
                                    </td>
                                    <td>
                                        <!-- <button type="button" pButton (click)="onCancelEmail()" icon="pi pi-times" label="Cancel" class="ui-button-secondary" style="margin-right: .5em"></button> -->
                                        <p-button type="button" label="Cancel" (click)="onCancelEmail()"
                                            styleClass="p-button-primary p-button-raised p-button-rounded margin-left">
                                        </p-button>                                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>      
                                
                        <mat-icon class="nav-caption wal-center">lock_open</mat-icon>
            
                    </p-footer>
    
                </form>
        
            </p-card>
        
        </div>        

    </p-tabPanel>


    <p-tabPanel header="My File Requests">

        <div class="grid">
            <div class="col-8">
    
                <p-table #dt [columns]="fileRequestCols" [value]="fileRequests" selectionMode="single" 
                    [(selection)]="selectedFileRequest" sortMode="multiple"[paginator]="true" [rows]="15" >
                    <ng-template pTemplate="caption">
                        List of File Creation Requests
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                          {{col.header}}
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '3%'}">
                        </th>
                       </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pEditableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                        <td style="text-align:center">
                            <button pButton type="button" icon="pi pi-download" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Download previously generated file" 
                                class="ui-button-info" (click)="onFileDownload(rowData)">
                            </button>
                        </td>
                    </tr>
                  </ng-template> 
                </p-table> 
          
            </div>

            <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
                <div class="loader"></div>  
            </div>
    
        </div>

    </p-tabPanel>    

</p-tabView>    


<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="user-profile"></p-confirmDialog>






