import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { SharedService } from "../../services/shared.service";
import { BrandService } from '../../services/config/brand.service';
import { CommunicationManagerService } from '../../services/admin/communication-manager.service';
import { JobMonitorService } from '../../services/monitor/job-monitor.service';

@Component({
  selector: 'app-communication-history',
  templateUrl: './communication-history.component.html',
  styleUrls: ['./communication-history.component.css']
})
export class CommunicationHistoryComponent implements OnInit {

  @ViewChild(Table) dt!: Table;

  brandCodes: any[] = [];
  messageCodes: any[] = [];
  channelCodes: any[] = [];
  selectedBrandCode!: string;
  selectedMessageCode!: string;
  selectedChannelCode!: string;
  selectedDateFrom!: string;
  selectedDateTo!: string;
  offset!: number;

  communicationHistories: any[] = [];
  selectedCommunicationHistory!: any;
  communicationHistoryCols: any[] = [];

  isSpinnerHidden: boolean = true;

  constructor(
    private brandService: BrandService, 
    private communicationManagerService: CommunicationManagerService,
    private jobMonitorService: JobMonitorService, 
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.communicationHistoryCols = [
      { field: 'communicationHistoryId', header: 'Id', width:'5%', display: 'table-cell' },
      { field: 'messageCode', header: 'Message Code', width:'15%', display: 'table-cell' },
      { field: 'channelCode', header: 'Channel Code', width:'15%', display: 'table-cell' },
      { field: 'attributes', header: 'Attributes', width:'10%', display: 'table-cell' },
      { field: 'contactPoints', header: 'Contact Points', width:'45%', display: 'table-cell' },
      { field: 'communicationTimestamp', header: 'Timestamp', width:'10%', display: 'table-cell' },
    ];     
      // Prepopulate the dates
      this.selectedDateFrom = this.sharedService.getCurrentDate();
      this.selectedDateTo = this.sharedService.getCurrentDate();
      // Populate the dropdowns
      this.getBrandCodes();
      this.getChannelCodes();
      this.getMessageCodes();
  }

  // Retrieve distinct brand codes
  getBrandCodes(): void {
    this.brandService.getBrands()
    .subscribe({
      next: (brands) => {
        for(let i = 0; i < brands.length; i++){
          this.brandCodes.push({label: brands[i].brandCode, value: brands[i].brandCode});
        }
        this.brandCodes.sort((a, b) => (a.value >= b.value) ? 1 : -1)
        },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Retrieve distinct channel codes
  getChannelCodes(): void {
    this.communicationManagerService.getChannels()
    .subscribe({
      next: (channels) => {
        for(let i = 0; i < channels.length; i++){
          this.channelCodes.push({label: channels[i].channelCode, value: channels[i].channelCode});
        }
        },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Retrieve distinct channel codes
  getMessageCodes(): void {
    this.communicationManagerService.getMessageProfiles()
    .subscribe({
      next: (messageProfiles) => {
        for(let i = 0; i < messageProfiles.length; i++){
          this.messageCodes.push({label: messageProfiles[i].messageCode, value: messageProfiles[i].messageCode});
        }
        },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // Select Communication History based on the user defined selection criteria
  getCommunicationHistory(selectedBrandCode: string, selectedMessageCode: string, selectedChannelCode: string, 
    selectedDateFrom: string, selectedDateTo: string, offset: number): void {
      this.isSpinnerHidden = false;
    this.dt.reset();
    this.jobMonitorService.getCommunicationHistory(selectedBrandCode, selectedMessageCode, selectedChannelCode,
      selectedDateFrom, selectedDateTo, offset)
      .subscribe({
        next: (response) => {
          this.offset = response.paging.nextPageToken;
          this.communicationHistories = response.communicationHistoryList;
          this.communicationHistories.forEach(element => {
            element.contactPoints = element.contactPoints.replaceAll(',',', ');
          })
          this.isSpinnerHidden = true;        
        },
        error: (error) => {
        },
        complete: () => {
        }
      });
  }

  // Process events when clicking on Search button
  onSearchClick(event: any) {
  var offset = 0;
  this.getCommunicationHistory(this.selectedBrandCode, this.selectedMessageCode, this.selectedChannelCode, 
    this.selectedDateFrom, this.selectedDateTo, offset);
  }

  // Process events when clicking on Next button
  onNextClick(event: any) {
    var offset = this.offset;
    this.getCommunicationHistory(this.selectedBrandCode, this.selectedMessageCode, this.selectedChannelCode, 
      this.selectedDateFrom, this.selectedDateTo, offset);
  }

  // Process events when clicking on Clear button
  onClearClick(event: any) {
    this.selectedBrandCode = '';
    this.selectedMessageCode = '';
    this.selectedChannelCode = '';
    this.selectedDateFrom = '';
    this.selectedDateTo = '';
  }

  onCommunicationHistoryRowSelect(event: any) {

  }
}
