import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { IEnvRegion } from '../interfaces/env-region';
import { IBrand } from '../interfaces/brand';
import { IPassTemplate } from '../interfaces/pass-template';
import { IOffer } from '../interfaces/offer';
import { IBillerBrand } from '../interfaces/biller-brand';
// import { EnvRegionService } from './config/env-region.service'
// import { BrandService } from '../services/config/brand.service';
import { UserContextService } from '../services/safeguard/user-context.service';

const CURRENT_BRAND_CODE = 'current-brand-code';
const CURRENT_BRAND = 'current-brand';
const CURRENT_PASS_TEMPLATE = 'current-pass-template';
const CURRENT_OFFER = 'current-offer';
const ENV_REGIONS = 'env-regions';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // private currentBrandCode!: string | null;
  private envRegions!: IEnvRegion[];
  // private currentBrand!: IBrand | null;
  // private currentPassTemplate!: IPassTemplate | null;
  // private currentOffer!: IOffer | null;

  constructor (
    private userContextService: UserContextService,
    // private envRegionService: EnvRegionService,
    // private brandService: BrandService
    ) { }

  // Clear all application items from the local storage
  public clearUserSession() {
    localStorage.removeItem(CURRENT_BRAND_CODE);
    localStorage.removeItem(CURRENT_BRAND);
    localStorage.removeItem(CURRENT_PASS_TEMPLATE); 
    localStorage.removeItem(CURRENT_OFFER);       
    localStorage.removeItem(ENV_REGIONS);       
  }

  // Set and get current brand code

  public setCurrentBrandCode(brandCode: string){
    // this.currentBrandCode = brandCode;
    localStorage.removeItem(CURRENT_BRAND_CODE);
    localStorage.setItem(CURRENT_BRAND_CODE, brandCode);
  }
  
  public getCurrentBrandCode(): string | null {
    // return this.currentBrandCode;
    return localStorage.getItem(CURRENT_BRAND_CODE);   
  }

  public isCurrentBrandCodeKnown(): boolean {
    return localStorage.getItem(CURRENT_BRAND_CODE) ? true : false;
  }

  // Set and get current brand

  public setCurrentBrand(brand: IBrand){
    // this.currentBrand = brand;
    localStorage.removeItem(CURRENT_BRAND);
    localStorage.setItem(CURRENT_BRAND, JSON.stringify(brand));
  }
  
  public getCurrentBrand(): IBrand {
    // return this.currentBrand;
    let brand = localStorage.getItem(CURRENT_BRAND);
    return (brand != null) ? JSON.parse(brand) : null;
  }

  public isCurrentBrandKnown(): boolean {
    return localStorage.getItem(CURRENT_BRAND) ? true : false;
  }

  // Set and get current pass

  public setCurrentPassTemplate(passTemplate: IPassTemplate){
    // this.currentPassTemplate = passTemplate;
    localStorage.removeItem(CURRENT_PASS_TEMPLATE);
    localStorage.setItem(CURRENT_PASS_TEMPLATE, JSON.stringify(passTemplate));
  }
  
  public getCurrentPassTemplate(): IPassTemplate | null {
    // return this.currentPassTemplate;
    let brand = localStorage.getItem(CURRENT_PASS_TEMPLATE);
    return (brand != null) ? JSON.parse(brand) : null;
  }

  public isCurrentPassTemplateKnown(): boolean {
    return localStorage.getItem(CURRENT_PASS_TEMPLATE) ? true : false;
  }

  // Set and get current offer

  public setCurrentOffer(offer: IOffer){
    // this.currentOffer = offer;
    localStorage.removeItem(CURRENT_OFFER);
    localStorage.setItem(CURRENT_OFFER, JSON.stringify(offer));
  }
  
  public getCurrentOffer(): IOffer{
    // return this.currentOffer;
    let brand = localStorage.getItem(CURRENT_OFFER);
    return (brand != null) ? JSON.parse(brand) : null;
  }

  public isCurrenOfferKnown(): boolean {
    return localStorage.getItem(CURRENT_OFFER) ? true : false;
  }

  // Set and get enviromental regions

  public setEnvRegions(envRegions: IEnvRegion[]){
    // this.envRegions = envRegions;
    localStorage.removeItem(ENV_REGIONS);
    localStorage.setItem(ENV_REGIONS, JSON.stringify(envRegions));
  }
  
  public getEnvRegions(): IEnvRegion[]{
    // return this.envRegions;
    let envRegions = localStorage.getItem(ENV_REGIONS);
    return (envRegions != null) ? JSON.parse(envRegions) : null;
  }

  public isEnvRegionsKnown(): boolean {
    return localStorage.getItem(ENV_REGIONS) ? true : false;
  }

  // Retrieve a list of the brands that are granted to the current user
  public getCurrentUserGrantedBrands(billerBrands: IBillerBrand[]): IBillerBrand[] {
    let brands: IBillerBrand[] = [];
    let hasGrantedBrands: boolean = false;    
    // Get current user's brand level authentication
    var hasAllBrandsAttribute = this.userContextService.hasAllBrands();
    var grantedBrands = this.userContextService.getUserBrands();  
    // If the user has the privilege for all brands, then no filtering is needed
    if (hasAllBrandsAttribute) {
      brands = billerBrands;
      hasGrantedBrands = true;
    } else {
    // If the user does not have the privilege for all brands, then filtering is needed
    billerBrands.forEach(billerBrand => {
        var index = grantedBrands.findIndex(brandCode => brandCode == billerBrand.brandCode);
          if (index >= 0) {
            brands.push(billerBrand);
            hasGrantedBrands = true;
          }
        }
      )
    }
    if (hasGrantedBrands) {
      brands.sort((a, b) => (a.brandCode >= b.brandCode) ? 1 : -1)
    } 
    return brands;
  }
  

  // Return the current date in yyyy-mm-dd format
  public getCurrentDate() {
    var d = new Date();
    return this.getFormattedDate(d);
  }

  public getNextDate(d: any) {
    let thisDate = null;
    if (d instanceof Date) {
      thisDate = d;
    } else {
      var parts = d.split('-');
      thisDate = new Date(parts[0], parts[1] - 1, parts[2]); 
    }
      const nextDate = new Date(thisDate)
      nextDate.setDate(thisDate.getDate() + 1);
      return nextDate;
  }

  // Return the one week ago from the current date in yyyy-mm-dd format
  public getOneWeekAgoDate() {
    var d = new Date();
    d.setDate( d.getDate() - 6 );
    return this.getFormattedDate(d);
  }

  // Return the one month ago from the current date in yyyy-mm-dd format
  public getOneMonthAgoDate() {
    var d = new Date();
    d.setDate( d.getDate() - 30 );
    // d.setDate( d.getMonth() - 1 );
    return this.getFormattedDate(d);
  }

  // Format given date field as yyyy-mm-dd
  public getFormattedDate(d: any) {
    if (d instanceof Date) {
      var day = String(d.getDate()).padStart(2, '0');
      var month = String(d.getMonth() + 1).padStart(2, '0'); //Months are zero based
      var year = d.getFullYear();
      return year + "-" + month + "-" + day;
    } else {
      return d;
    }
  }

  // Format given date field as yyyy-mm-dd hh24:mi:ss
  public formatFromISO(dt: string): string {
    let date = new Date(this.dateFormatFromUtc(dt));
    const format = 'yyyy-MM-dd HH:mm:ss';
    const locale = 'en-US';
    const formattedDate = formatDate(date, format, locale);
    return formattedDate;
  }

  // Format given date field from yyyy-mm-dd hh24:mi:ss to ISO
  public formatToISO(dt: string): string {
    const event = new Date(dt);
    return event.toISOString();
  }

  public formatFromEpoch(dt: string): string {
    let date = new Date(dt);
    const format = 'yyyy-MM-dd HH:mm:ss';
    const locale = 'en-US';
    const formattedDate = formatDate(date, format, locale);
    return formattedDate;
  }

  public dateFormatFromUtc(d: any) {
    return d.replace('T', ' ').replace('Z[UTC]','').replace('Z[GMT]','').replace('Z','');
  }

  // Prepare a chart
  public options(titleText: any) {
    return {
     title: {
       display: true,
       text: titleText,
       fontSize: 16
     },
     legend: {
       position: 'top'
     },
     scales: {
      yAxes: [{
          ticks: {
            beginAtZero: true
          }
      }]
  }     
   };
  }
  
  public formatPhoneNumber(str: string): string {
    // Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    // Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    // Format as 999-999-9999
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3]
    };
    return '';
  }

  getDummyBrand(): IBrand {
    return {
      brandCode: '',
      brandName: '',
      baseUrl: ''
    }
  }

  getDummyPassTemplate(): IPassTemplate {
    return {
      passId: 0,
      passtypeName: '',
      brandCode: '',
      gwAllowMultipleUsers: false,
      passName: ''
    }
  }

  getDummyOffer(): IOffer {
    return {
      offerId: 0,
      brandCode: '',
      offerName: '', itemDesc: ''
    }
  }  

  convertBlobToBase64(blob: Blob) {
    return Observable.create((observer: any) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        console.log('File could not be read: ' + event.target.error.code);
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }  

}
