import { Component, OnInit, Input } from '@angular/core';
import { AccountField } from '../../classes/account-field';

@Component({
  selector: 'app-account-field',
  templateUrl: './dynamic-account-fields.component.html',
  styleUrls: ['./dynamic-account-fields.component.css']
})
export class DynamicAccountFieldsComponent implements OnInit {

  @Input() accountField: AccountField<string>;

  constructor() { 
    this.accountField = new AccountField();
  }

  ngOnInit(): void {
  }

}
