import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, query, stagger, group, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IPassTemplate } from '../../interfaces/pass-template';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { BrandService } from '../../services/config/brand.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-brand-pass-templates',
  templateUrl: './brand-pass-templates.component.html',
  styleUrls: ['./brand-pass-templates.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class BrandPassTemplatesComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  
  
  currentForm: string = "BrandPassTemplates";
  titleMessage = "Pass Templates";
  userform!: FormGroup;
  cloneform!: FormGroup;
  isListShown = true;
  isDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;
  isImagePreviewShown = false;
  googlePayDisabled = true;

  passTemplates: any[] = [];
  cols!: any[];
  passTypes: any[] = [];
  messageTemplates: any[] = [];
  brandImages: any[] = [];
  imageList: any[] = [];
  image: any;
  resultMsg = "";
  currentBrand: IBrand;

  noBrandWarning!: string;
  openBrandSelectionDialog!: boolean;
  openCloneToOtherBrandDialog: boolean = false;
  isSpinnerHidden: boolean = true;

  targetBrands: any[] = [];

  imageAppleLogoFile!: File | null;
  imageAppleIconFile!: File | null
  imageGoogleIconFile!: File | null
  imageGoogleLogoFile!: File | null

  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
  public presetValues : string[] = [];
  public labelColorInit = "#ABB2B9";
  public foregroundColorInit = "#ABB2B9";
  public backgroundColorInit = "#ABB2B9";

  constructor(
    private fb: FormBuilder, 
    private brandService: BrandService,
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private userContextService: UserContextService,
    private cpService: ColorPickerService,
    private router: Router
    ) {
      this.currentBrand = this.sharedService.getDummyBrand();      
    }

  ngOnInit(): void {
    // Define the columns on the grid
    this.cols = [
      { field: 'passId', header: 'Pass Template Id', display: 'table-cell', width:'14%'  },
      { field: 'passName', header: 'Pass Template Name', display: 'table-cell', width:'22%'  },
      { field: 'passtypeName', header: 'Pass Type', display: 'table-cell', width:'15%'  },
      { field: 'passDesc', header: 'Description', display: 'table-cell', width:'23%'  },
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'table-cell', width:'14%'  },
    ]; 

    this.userform = this.fb.group({
      'passId': new FormControl(''),
      'getLatestOfferOnFetch': new FormControl(''),
      'newCPonDiffOvers': new FormControl(''),
      'passCache': new FormControl(''),
      'fetchLimit': new FormControl(''),
      'passtypeName': new FormControl('', [Validators.required]),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
      'imageIconUID': new FormControl(''),
      'imageLogoUID': new FormControl(''),
      'gwIconImageUID': new FormControl(''),
      'formatVersion': new FormControl(''),
      'gwAllowMultipleUsers': new FormControl('', [Validators.required]),
      'randomDigits': new FormControl(''),
      'passName': new FormControl('', [Validators.required, Validators.maxLength(45)]),
      'passDesc': new FormControl('', [Validators.required, Validators.maxLength(200)]),
      'foregroundColor': new FormControl(''),
      'backgroundColor': new FormControl(''),
      'labelColor': new FormControl(''),
      'gwAccountIdLabel': new FormControl(''),
      'ignoreDaq': new FormControl(''),
      'associatedApp': new FormControl(''),
      'logoText': new FormControl(''),
      'gwHeroImageUID': new FormControl(''),
      'initMsgTemplateCode': new FormControl(''),
      'gwIssuerName': new FormControl(''),
      'gwProgramName': new FormControl(''),
      'gwAccountNameLabel': new FormControl(''),
      'gwSupported': new FormControl(''),
      'gwClassId': new FormControl('')
    });

    this.cloneform = this.fb.group({
      'sourcePassId': new FormControl(0, [Validators.required]),
      'targetBrandCode': new FormControl('', [Validators.required, Validators.maxLength(3)]),
      'passTemplateName': new FormControl('', [Validators.required, Validators.maxLength(45)]),
      'passTemplateDescription': new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl);
      this.getBrandImages(this.currentBrand.brandCode || '', this.currentBrand.baseUrl);
      this.getPassTypes(this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }
    // Hide the submit buttons for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_PASS_MODIFY')) {
      this.isSubmitShown = true;
    }
    
  }

  // Redirect to message templates page
  msgTemplatesRedirect() {
    this.router.navigate(['/messageTemplates']);
  }

  // Select all possible pass types
  getPassTypes(baseUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getPassTypes(baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          response.forEach(element => {
            this.passTypes.push({label: element.displayName, value: element.passTypeName});
          });
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  getMessageTemplates(brandCode: string, passTemplateId: number, baseUrl: string) {
    this.messageTemplates.length = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getMessageTemplates(brandCode, passTemplateId, baseUrl)
    .subscribe({
      next: (response) => {
        this.messageTemplates.push({label: '[none]', value: '[none]'});
        if (response) {
            response.forEach(element => {
              this.messageTemplates.push({label: element.messageTemplateName, value: element.messageTemplateCode});
            });
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Select all existing brand images
  getBrandImages(brandCode: string, baseUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandImages(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        this.brandImages = response;
        this.imageList.length = 0;
        this.imageList.push({label: '[none]', value: '[none]'});
        if (response) {
          response.forEach(element => {
            this.imageList.push({label: element.imageName, value: element.imageUID});
          });
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }  

  // Select all existing brand pass templates
  getPassTemplates(brandCode: string, baseUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getPassTemplates(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        this.passTemplates = response;
        // Format the dates
        for(let i = 0; i < response.length; i++){
          if (response[i].createDate) {
            response[i].createDate = this.sharedService.formatFromISO(response[i].createDate || '');
          }
          if (response[i].lastUpdateDate) {
            response[i].lastUpdateDate = this.sharedService.formatFromISO(response[i].lastUpdateDate || '');
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.sharedService.setCurrentBrand(item);
      this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl);
      this.getBrandImages(this.currentBrand.brandCode || '', this.currentBrand.baseUrl);
      this.getPassTypes(this.currentBrand.baseUrl);
    }
  }

  onSubmit(value: string) {
    // Make sure that Google Pay Program Name is populated when gwSupported is on
    if (this.userform.value.gwSupported && !(this.userform.value.gwProgramName)) {
      this.alertTheUser('Google Pay Program Name is required when Google Pay Support is turned on');
      return;
    }
    // Prepare an object of IPassTemplate class from the form controls
    let passTemplate: IPassTemplate = {
      brandCode: this.currentBrand.brandCode,
      passId: this.userform.value.passId,
      getLatestOfferOnFetch: this.userform.value.getLatestOfferOnFetch,
      newCPonDiffOvers: this.userform.value.newCPonDiffOvers,
      passCache: this.userform.value.passCache,
      fetchLimit: this.userform.value.fetchLimit,
      passtypeName: this.userform.value.passtypeName,
      imageIconUID: this.userform.value.imageIconUID,
      imageLogoUID: this.userform.value.imageLogoUID, 
      gwIconImageUID: this.userform.value.gwIconImageUID, 
      formatVersion: this.userform.value.formatVersion,
      gwAllowMultipleUsers: this.userform.value.gwAllowMultipleUsers,
      randomDigits: this.userform.value.randomDigits,
      passName: this.userform.value.passName,
      passDesc: this.userform.value.passDesc,
      foregroundColor: this.foregroundColorInit,
      backgroundColor: this.backgroundColorInit,
      labelColor: this.labelColorInit,
      gwAccountIdLabel: this.userform.value.gwAccountIdLabel,
      supportedWalletPlatforms: this.userform.value.gwSupported == true ? 3 : 1,
      ignoreDaq: this.userform.value.ignoreDaq,
      associatedApp: this.userform.value.associatedApp,    
      logoText: this.userform.value.logoText,
      gwHeroImageUID: this.userform.value.gwHeroImageUID, 
      initMsgTemplateCode: this.userform.value.initMsgTemplateCode,
      gwIssuerName: this.userform.value.gwIssuerName,
      gwProgramName: this.userform.value.gwProgramName,
      gwAccountNameLabel: this.userform.value.gwAccountNameLabel,
      gwClassId: this.userform.value.gwClassId,
      createDate: '',
      lastUpdateDate: ''
    };
    // Call the service to invoke a Web API call
    this.brandProfileService.submitPassTemplate(this.currentBrand.brandCode || '', passTemplate, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        console.log('!!response', response)
        if (passTemplate.passId) {
          this.alertTheUser('Successfully modified pass template ' + passTemplate.passName + ' from brand ' + this.currentBrand.brandCode);
        } else {
          this.alertTheUser('Successfully added pass template ' + passTemplate.passName + ' to brand ' + this.currentBrand.brandCode);
        }
        this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl);
        this.isListShown = true;
        this.isDetailsShown = false;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  cancelDetails() {
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  showDialogToAdd() {
    this.messageTemplates.length = 0;
    this.messageTemplates.push({label: '[none]', value: '[none]'});
    // this.getMessageTemplates(this.currentBrand.brandCode, 0, this.currentBrand.baseUrl);
    this.userform.setValue({
        passId: null, passtypeName: this.passTypes[0].value, imageIconUID: '', imageLogoUID: '', gwIconImageUID: '', gwAllowMultipleUsers: false, 
        passName: '', passDesc: '', foregroundColor: '', backgroundColor: '',labelColor: '',gwAccountIdLabel: '', 
        gwSupported: false, associatedApp: '',  fetchLimit: 0, logoText: '',  gwHeroImageUID: '',  initMsgTemplateCode: '[none]', 
        gwIssuerName: '',  gwProgramName: '', gwAccountNameLabel: '',  getLatestOfferOnFetch: '', randomDigits: '',
        ignoreDaq: false, newCPonDiffOvers: false, passCache: false, formatVersion: 0, createDate: '', lastUpdateDate: '', gwClassId: ''
      });
    this.labelColorInit = "#ABB2B9";
    this.foregroundColorInit = "#ABB2B9";
    this.backgroundColorInit = "#ABB2B9";
    this.googlePayDisabled = true;
    this.googlePayDisabled = true;
    this.userform.get('gwIssuerName')?.disable();
    this.userform.get('gwProgramName')?.disable();
    this.userform.get('gwAccountIdLabel')?.disable();
    this.userform.get('gwAccountNameLabel')?.disable();
    this.isListShown = false;
    this.isDetailsShown = true;
  }

  // Modify existing pass template
  onRowEdit(event: any) {
    this.sharedService.setCurrentPassTemplate(event);
    // this.getMessageTemplates(this.currentBrand.brandCode, event.passId, this.currentBrand.baseUrl);
    // First step: get the message templates for the current pass template
    this.messageTemplates.length = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getMessageTemplates(this.currentBrand.brandCode || '', event.passId, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.messageTemplates.push({label: '[none]', value: '[none]'});
        if (response) {
            response.forEach(element => {
              this.messageTemplates.push({label: element.messageTemplateName, value: element.messageTemplateCode});
            });
        }
        // Second step: get the details of the pass template
        this.brandProfileService.getPassTemplate(this.currentBrand.brandCode || '', event.passId, this.currentBrand.baseUrl)
        .subscribe( 
          (response) => {
              this.userform.setValue({
                passId: response.passId, 
                passtypeName: response.passtypeName, 
                imageIconUID: response.hasOwnProperty('imageIconUID') ? response.imageIconUID : '', 
                imageLogoUID: response.hasOwnProperty('imageLogoUID') ? response.imageLogoUID : '', 
                gwIconImageUID: response.hasOwnProperty('gwIconImageUID') ? response.gwIconImageUID : '', 
                gwAllowMultipleUsers: response.gwAllowMultipleUsers, 
                passName: response.passName, 
                passDesc: response.hasOwnProperty('passDesc') ? response.passDesc : '', 
                foregroundColor: '', // response.hasOwnProperty('foregroundColor') ? response.foregroundColor : '', 
                backgroundColor: '', // response.hasOwnProperty('backgroundColor') ? response.backgroundColor : '',
                labelColor: '', // response.hasOwnProperty('labelColor') ? response.labelColor : '', 
                gwAccountIdLabel: response.hasOwnProperty('gwAccountIdLabel') ? response.gwAccountIdLabel : '', 
                gwSupported: response.hasOwnProperty('supportedWalletPlatforms') ? (response.supportedWalletPlatforms == 3 ? true : false)  : false,
                associatedApp: response.hasOwnProperty('associatedApp') ? response.associatedApp : '', 
                fetchLimit: response.hasOwnProperty('fetchLimit') ? response.fetchLimit : 0, 
                logoText: response.hasOwnProperty('logoText') ? response.logoText : '', 
                gwHeroImageUID: response.hasOwnProperty('gwHeroImageUID') ? response.gwHeroImageUID : '', 
                initMsgTemplateCode: response.hasOwnProperty('initMsgTemplateCode') ? response.initMsgTemplateCode : '[none]', 
                gwIssuerName: response.hasOwnProperty('gwIssuerName') ? response.gwIssuerName : '', 
                gwProgramName: response.hasOwnProperty('gwProgramName') ? response.gwProgramName : '',
                gwAccountNameLabel: response.hasOwnProperty('gwAccountNameLabel') ? response.gwAccountNameLabel : '', 
                getLatestOfferOnFetch: response.hasOwnProperty('getLatestOfferOnFetch') ? response.getLatestOfferOnFetch : '',
                randomDigits: response.hasOwnProperty('randomDigits') ? response.randomDigits : '', 
                ignoreDaq: response.hasOwnProperty('ignoreDaq') ? response.ignoreDaq : false,
                newCPonDiffOvers: response.hasOwnProperty('newCPonDiffOvers') ? response.newCPonDiffOvers : false,
                passCache: response.hasOwnProperty('passCache') ? response.passCache : false,
                formatVersion: response.hasOwnProperty('fetchLimit') ? response.fetchLimit : 0,
                createDate: this.sharedService.formatFromISO(response.createDate || ''),
                lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate || ''),
                gwClassId: response.hasOwnProperty('gwClassId') ? response.gwClassId : ''
              });
              this.labelColorInit = response.hasOwnProperty('labelColor') ? '#' + response.labelColor : "#ABB2B9";
              this.foregroundColorInit = response.hasOwnProperty('foregroundColor') ? '#' + response.foregroundColor : "#ABB2B9";
              this.backgroundColorInit = response.hasOwnProperty('backgroundColor') ? '#' + response.backgroundColor : "#ABB2B9";
              this.googlePayDisabled = response.hasOwnProperty('supportedWalletPlatforms') ? (response.supportedWalletPlatforms == 1 ? true : false)  : true;
              if (response.hasOwnProperty('supportedWalletPlatforms') && response.supportedWalletPlatforms == 3) {
                this.googlePayDisabled = false;
                this.userform.get('gwIssuerName')?.enable();
                this.userform.get('gwProgramName')?.enable();
                this.userform.get('gwAccountIdLabel')?.enable();
                this.userform.get('gwAccountNameLabel')?.enable();
              } else {
                this.googlePayDisabled = true;
                this.userform.get('gwIssuerName')?.disable();
                this.userform.get('gwProgramName')?.disable();
                this.userform.get('gwAccountIdLabel')?.disable();
                this.userform.get('gwAccountNameLabel')?.disable();
              }
              this.isListShown = false;
              this.isDetailsShown = true;
            })
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the variable
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the pass template ' + event.passName + ' ?',
      header: 'Confirmation',
      key: 'brand-pass-templates',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deletePassTemplate(this.currentBrand.brandCode || '', event.passId, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted pass template ' + event.passName + ' from brand ' + this.currentBrand.brandCode);
            this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  onRowClone(event: any) {
    // Make sure that the user indeed wants to clone the pass template
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to clone the pass template ' + event.passName + ' ?',
      header: 'Confirmation',
      key: 'brand-pass-templates',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.clonePassTemplate(this.currentBrand.brandCode || '', event.passId, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully cloned pass template ' + event.passName + ' for brand ' + this.currentBrand.brandCode);
            this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  } 


  onRowSelect(event: any) {
  }  

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-pass-templates',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  public onChangeColorCmyk(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);
      return this.cpService.rgbaToCmyk(rgba);
    }
    return new Cmyk(0, 0, 0, 0);
  }

  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);
    if (hsva) {
      return this.cpService.outputFormat(hsva, 'rgba', null);
    }
    return '';
  }

  onChangeLogoImage(event: any) {
    var imageUid = event.value;
    this.previewImage (imageUid);
  }

  onBlurLogoImage(event: any) {
    this.isImagePreviewShown = false;
  }

  onGwSupported(event: any) {
    if (event) {
      this.googlePayDisabled = false;
      this.userform.get('gwIssuerName')?.enable();
      this.userform.get('gwProgramName')?.enable();
      this.userform.get('gwAccountIdLabel')?.enable();
      this.userform.get('gwAccountNameLabel')?.enable();
    } else {
      this.googlePayDisabled = true;
      this.userform.get('gwIssuerName')?.disable();
      this.userform.get('gwProgramName')?.disable();
      this.userform.get('gwAccountIdLabel')?.disable();
      this.userform.get('gwAccountNameLabel')?.disable();
    }
  }

  onChangeIconImage(event: any) {
    var imageUid = event.value;
    this.previewImage (imageUid);
  }

  onBlurIconImage(event: any) {
    this.isImagePreviewShown = false;
  }

  onChangeGwIconImage(event: any) {
    var imageUid = event.value;
    this.previewImage (imageUid);
  }

  onBlurGwIconImage(event: any) {
    this.isImagePreviewShown = false;
  }

  onChangeGwHeroImage(event: any) {
    var imageUid = event.value;
    this.previewImage (imageUid);
  }

  onBlurGwHeroImage(event: any) {
    this.isImagePreviewShown = false;
  }

  previewImage (imageUid: string) {
    if (imageUid == '[none]') {
      this.isImagePreviewShown = false;
      return;
    }
    if (this.brandImages) {
      this.brandImages.forEach(element => {
        if (element.imageUID == imageUid) {
          let imageType = element.imageType;
          this.image = 'data:' + imageType + ';base64,' + element.imageContent;
          this.isImagePreviewShown = true;
        }
      });
    }
  }

  onRowCloneToOtherBrand (event: any) {
    // Get current user's brand level authentication
    var hasAllBrandsAttribute = this.userContextService.hasAllBrands();
    var grantedBrands = this.userContextService.getUserBrands();
    // Retrieve all granted target brands
    this.getTargetBrandCodes(this.currentBrand.brandCode || '', hasAllBrandsAttribute, grantedBrands || ''); 
    this.cloneform.setValue({
      sourcePassId: event.passId, 
      targetBrandCode: '', 
      passTemplateName: event.passName, 
      passTemplateDescription: event.passDesc
    });
    this.imageAppleLogoFile = null;
    this.imageAppleIconFile = null;
    this.imageGoogleIconFile = null;
    this.imageGoogleLogoFile = null;
    this.openCloneToOtherBrandDialog = true;   
  }

    // Retrieve all granted target brands
  getTargetBrandCodes(brandCode: string, hasAllBrandsAttribute: boolean, grantedBrands: any[]) {
    this.brandService.getBrands()
    .subscribe(billerBrands => {
        billerBrands.forEach(billerBrand => {
          var index = grantedBrands.findIndex(brandCode => brandCode == billerBrand.brandCode);
            if ((hasAllBrandsAttribute || index >= 0) && billerBrand.brandCode != brandCode) {
              this.targetBrands.push({label: billerBrand.brandCode + '  ' + "\u2B24" + '  ' + billerBrand.brandName, value: billerBrand.brandCode});
            }
          })
      this.targetBrands.sort((a, b) => (a >= b) ? 1 : -1)
    })
  }

  onCancelCloneToOtherBrand () {
    this.openCloneToOtherBrandDialog = false;    
    this.cloneform.setValue({sourcePassId: null, targetBrandCode: null, passTemplateName: null, passTemplateDescription: null});
    this.imageAppleLogoFile = null;
    this.imageAppleIconFile = null;
    this.imageGoogleIconFile = null;
    this.imageGoogleLogoFile = null;
  }

  onAppleLogoFileChange(event: any) {
    this.imageAppleLogoFile = event.files[0]
  }

  onApleIconFileChange(event: any) {
    this.imageAppleIconFile = event.files[0]
  }

  onGoogleLogoFileChange(event: any) {
    this.imageGoogleLogoFile = event.files[0]
  }

  onGoogleIconFileChange(event: any) {
    this.imageGoogleIconFile = event.files[0]
  }

  onCloneToOtherBrand (event: any) {
    this.openCloneToOtherBrandDialog = false; 
    let passTemplateRequest = {
      sourceBrandCode: this.currentBrand.brandCode,
      sourcePassId: this.cloneform.value.sourcePassId,
      targetBrandCode: this.cloneform.value.targetBrandCode,
      targetPassTemplateName: this.cloneform.value.passTemplateName,
      targetPassTemplateDescription: this.cloneform.value.passTemplateDescription,
      appleLogoImage: this.imageAppleLogoFile,
      appleIconImage: this.imageAppleIconFile,
      googleLogoImage: this.imageGoogleLogoFile,
      googleIconImage: this.imageGoogleIconFile
    }
    // Call the service to invoke a Web API call
    this.brandProfileService.clonePassTemplateToOtherBrand(passTemplateRequest, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.alertTheUser('Successfully cloned pass template ' + passTemplateRequest.targetPassTemplateName
        + ' into brand ' + passTemplateRequest.targetBrandCode);
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowBrandSelect(event: any) {
  }

}
