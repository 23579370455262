import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, query, stagger, group, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IOffer } from '../../interfaces/offer';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { BrandService } from '../../services/config/brand.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-brand-offers',
  templateUrl: './brand-offers.component.html',
  styleUrls: ['./brand-offers.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class BrandOffersComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  
  
  currentForm: string = "BrandFieldSets";
  titleMessage = "Field Sets";
  userform!: FormGroup;
  cloneform!: FormGroup;
  isListShown = true;
  isListOfOffersShown = true;
  isDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;

  itemDescs: any[] = [];
  brandOffers: any[] = [];
  cols!: any[];
  resultMsg = "";
  currentBrand: IBrand;

  currentOffer!: IOffer;

  openBrandSelectionDialog!: boolean;
  openCloneToOtherBrandDialog: boolean = false;
  targetBrands: any[] = [];

  constructor(
    private fb: FormBuilder, 
    private brandService: BrandService,
    private brandProfileService: BrandProfileService,
    private userContextService: UserContextService,
    private sharedService: SharedService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) { 
    this.currentBrand = this.sharedService.getDummyBrand();    
  }

  ngOnInit(): void {
    
    this.itemDescs = [
      {label: "[none]", value: "[none]"}, 
      {label: "Auto", value: "Auto"}, 
      {label: "CreditCard", value: "CreditCard"}, 
      {label: "Insurance", value: "Insurance"}, 
      {label: "Other", value: "Other"}, 
      {label: "Utility", value: "Utility"}, 
      {label: "ImageName", value: "ImageName"}
    ];

    // Define the columns on the grid
    this.cols = [
      { field: 'offerId', header: 'Id', display: 'table-cell', width:'8%'  },
      { field: 'offerName', header: 'Field Set Name', display: 'table-cell', width:'24%'  },
      { field: 'itemDesc', header: 'Field Set Type', display: 'table-cell', width:'14%'  },
      { field: 'offerDesc', header: 'Field Set Description', display: 'table-cell', width:'24%'  },
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'table-cell', width:'14%'  },
      // { field: 'brandCode', header: 'Brand Code', display: 'none'}
    ]; 

    this.userform = this.fb.group({
      'offerId': new FormControl(''),
      'offerName': new FormControl('', [Validators.required, Validators.maxLength(45)]),
      'offerDesc': new FormControl('', [Validators.required, Validators.maxLength(400)]),
      'discount': new FormControl(null),
      'itemDesc': new FormControl('', [Validators.maxLength(200)]),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
    });

    this.cloneform = this.fb.group({
      'sourceOfferId': new FormControl(0, [Validators.required]),
      'targetBrandCode': new FormControl('', [Validators.required, Validators.maxLength(3)]),
      'offerName': new FormControl('', [Validators.required, Validators.maxLength(45)]),
      'offerDescription': new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.openBrandSelectionDialog = false;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }
    // Hide the submit buttons for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_OFFER_MODIFY')) {
      this.isSubmitShown = true;
    }
    
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.titleMessage = this.currentBrand.brandName + " ( " + this.currentBrand.brandCode + " ) > " + "Field Sets";
      this.isCurrentBrandShown = false;
      this.sharedService.setCurrentBrand(item);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    }
  }

  // Select all existing brand offers
  getBrandOffers(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandOffers(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        this.brandOffers = response;
        // Format the dates
        for(let i = 0; i < response.length; i++){
          if (response[i].createDate) {
            response[i].createDate = this.sharedService.formatFromISO(response[i].createDate || '');
          }
          if (response[i].lastUpdateDate) {
            response[i].lastUpdateDate = this.sharedService.formatFromISO(response[i].lastUpdateDate || '');
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }  

  showDialogToAdd() {
    this.currentOffer = {
      offerId: 0,
      brandCode: '',
      offerName: '',
      itemDesc: ''
    };
    this.userform.setValue({
      offerId: null, 
      offerName: '', 
      offerDesc: '', 
      discount: 0,
      itemDesc: 'Other', 
      createDate: null, 
      lastUpdateDate: null
    })
    this.isListShown = false;
    this.isDetailsShown = true;
  }

  // Modify existing brand offer
  onRowEdit(event: any) {
    // this.populateBrandChangeIndicatorVariables();
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandOffer(this.currentBrand.brandCode, event.offerId, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.currentOffer = response;
        this.userform.setValue({offerId: response.offerId, offerName: response.offerName, offerDesc: response.offerDesc, 
          discount: response.hasOwnProperty('discount') ? response.discount : null, 
          itemDesc: response.hasOwnProperty('itemDesc') ? response.itemDesc : '[none]', 
          createDate: this.sharedService.formatFromISO(response.createDate || ''),
          lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate || '')})
        this.isListShown = false;
        this.isDetailsShown = true;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the brand offer
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the field set ' + event.offerName + ' ?',
      header: 'Confirmation',
      key: 'brand-offers',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",      
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteBrandOffer(this.currentBrand.brandCode, event.offerId, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted field set ' + event.offerName + ' from brand ' + this.currentBrand.brandCode);
            this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  // Redirect to offer fields page
  offerFieldsRedirect(event: any) {
    let brandOffer: IOffer = {
      brandCode: this.currentBrand.brandCode,
      offerId: event.offerId,
      offerName: event.offerName,
      offerDesc: event.offerDesc, 
      itemDesc: event.itemDesc
    };
    this.sharedService.setCurrentOffer(brandOffer);
    this.router.navigate(['/offerFields']);
  }

  onSubmit(value: string) {
    let brandOffer: IOffer = {
      brandCode: this.currentBrand.brandCode,
      offerId: this.userform.value.offerId,
      offerName: this.userform.value.offerName,
      offerDesc: this.userform.value.offerDesc, 
      // discount: (typeof this.userform.value.discount === 'number') ? this.userform.value.discount : null,
      discount: this.userform.value.discount,
      itemDesc: this.userform.value.itemDesc == '[none]' ? null : this.userform.value.itemDesc,
      createDate: this.userform.value.createDate,
      lastUpdateDate: this.userform.value.lastUpdateDate,
      randomDigits: this.currentOffer ? this.currentOffer.randomDigits : 0,
      updateable: this.currentOffer ? this.currentOffer.updateable : false,
      authToken: this.currentOffer ? this.currentOffer.authToken : '',
      locations: this.currentOffer ? this.currentOffer.locations : 0
    };
    // Call the service to invoke a Web API call
    this.brandProfileService.submitBrandOffer(this.currentBrand.brandCode, brandOffer, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        if (brandOffer.offerId) {
          this.alertTheUser('Successfully modified field set ' + brandOffer.offerName + ' from brand ' + this.currentBrand.brandCode);
        } else {
          this.alertTheUser('Successfully added field set ' + brandOffer.offerName + ' to brand ' + this.currentBrand.brandCode);
        }
        this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
        this.isListShown = true;
        this.isDetailsShown = false;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowClone(event: any) {
    // Make sure that the user indeed wants to clone the brand offer
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to clone the field set ' + event.offerName + ' ?',
      header: 'Confirmation',
      key: 'brand-offers',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.cloneBrandOffer(this.currentBrand.brandCode, event.offerId, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully cloned field set ' + response.offerName + ' for brand ' + this.currentBrand.brandCode);
            this.getBrandOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  } 

  cancelDetails() {
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-offers',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  onRowCloneToOtherBrand (event: any) {
    // Get current user's brand level authentication
    var hasAllBrandsAttribute = this.userContextService.hasAllBrands();
    var grantedBrands: any [] = this.userContextService.getUserBrands();
    // Retrieve all granted target brands
    this.getTargetBrandCodes(this.currentBrand.brandCode, hasAllBrandsAttribute, grantedBrands); 
    this.cloneform.setValue({
      sourceOfferId: event.offerId, 
      targetBrandCode: '', 
      offerName: event.offerName, 
      offerDescription: event.offerDesc
    });
    this.openCloneToOtherBrandDialog = true;   
  }

    // Retrieve all granted target brands
  getTargetBrandCodes(brandCode: string, hasAllBrandsAttribute: boolean, grantedBrands: any[]) {
    this.brandService.getBrands()
    .subscribe({
      next: (response) => {
        response.forEach(billerBrand => {
          var index = grantedBrands.findIndex(brandCode => brandCode == billerBrand.brandCode);
            if ((hasAllBrandsAttribute || index >= 0) && billerBrand.brandCode != brandCode) {
              this.targetBrands.push({label: billerBrand.brandCode + '  ' + "\u2B24" + '  ' + billerBrand.brandName, value: billerBrand.brandCode});
            }
          })
        this.targetBrands.sort((a, b) => (a >= b) ? 1 : -1)
      },
      error: (error) => {
        },
      complete: () => {
      }
    });
  }

  onCancelCloneToOtherBrand () {
    this.openCloneToOtherBrandDialog = false;    
    this.cloneform.setValue({sourceOfferId: null, targetBrandCode: null, offerName: null, offerDescription: null});
  }

  onCloneToOtherBrand (event: any) {
    this.openCloneToOtherBrandDialog = false; 
    let sourceOfferId = this.cloneform.value.sourceOfferId;
    let targetBrandCode = this.cloneform.value.targetBrandCode;
    let offerName = this.cloneform.value.offerName;
    let offerDescription = this.cloneform.value.offerDescription;
    // Call the service to invoke a Web API call
    this.brandProfileService.cloneBrandOfferToOtherBrand(this.currentBrand.brandCode, sourceOfferId, 
      targetBrandCode, offerName, offerDescription, this.currentBrand.baseUrl)
      .subscribe({
        next: (response) => {
          this.alertTheUser('Successfully cloned pass offer ' + offerName + ' into brand ' + targetBrandCode);
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  }



}
