import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { UserRoles } from '../../interfaces/user-roles';
import { PortalRoleService } from '../../services/admin/portal-role.service';
import { PortalUserService } from '../../services/admin/portal-user.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";
import { IPortalUser } from '../../interfaces/portal-user';
import { IPortalRole } from '../../interfaces/portal-role';
import { IUserTypeRole } from '../../interfaces/user-type-role';

interface Brand {
  name: string;
  code: string;
}

const PRIV_USER_VIEW = 'PRIV_WA_USER_VIEW';
const PRIV_USER_MODIFY = 'PRIV_WA_USER_MODIFY';
const USER_BILLER_REP = 'biller-user';
const BILLER_ROLES: string[] = [ 'rl_user_admin', 'rl_csr_user', 'rl_biller_rep', 'rl_rtpn_viewer'];

export const StrongPasswordRegx: RegExp =/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
export const EmailAddressRegx: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;  

@Component({
  selector: 'app-users',
  templateUrl: './portal-users.component.html',
  styleUrls: ['./portal-users.component.css']
})

export class PortalUsersComponent implements OnInit {

  // isPortalAdmin: boolean = false;
  isBillerRep: boolean = false;
  isAciStaff: boolean = false;
  // hasUserAdminRole: boolean = false;
  hasAllBrandsAttribute: boolean = false;
  grantedBrandCodes: string[] = [];

  isSubmitShown: boolean = false;
  isUnlockVisible: boolean = false;
  isRoleTabpageShown: boolean = false;

  users: IPortalUser[] = [];
  portalRoles: IPortalRole[] = [];
  cols: any[] = [];
  selectedUser!: IPortalUser;
  displayDialog!: boolean;
  newUser!: boolean; 
  user!: IPortalUser; 
  userRoles: UserRoles[] = [];
  roleNames: any[] =  [];
  brands: any[] =  [];
  availableRoles: any[] =  [];
  grantedRoles: any[] =  [];
  brandCodes: Brand[] = [];
  cities: Brand[] = [];
  selectedBrandCodes: Brand[] = [];
  selectedUserName!: string;
  selectedRoleName!: string;
  selectedUserType: string = '';
  selectedBrandCode: string = '';
  allBrandsGrant: boolean = false;
  userType!: string;
  userTypes: any[] = [];
  userTypeRoles: IUserTypeRole[] = []; 
  roleDescrHint!: string;

  tabIndex: number = 0;
  isSubmitHidden: boolean = false;  

  isSpinnerHidden: boolean = true;  

  public passwordForm!: FormGroup;
  passwordRuleText : string = 'Password must contain at least one digit, lowercase, uppercase and special characters with the length of 8 to 20 characters.';

  constructor(
    private fb: FormBuilder,
    private portalUserService: PortalUserService, 
    private portalRoleService: PortalRoleService, 
    private userContextService: UserContextService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService) { 
  }

  ngOnInit() {
     this.cols = [
      { field: 'userId', header: 'User Id', display: 'table-cell', width:'15%' },
      { field: 'userName', header: 'User Name', display: 'table-cell', width:'25%' },
      { field: 'locked', header: 'Locked', display: 'none', width:'15%' },
      { field: 'enabled', header: 'Enabled', display: 'none', width:'15%'},
      { field: 'emailAddress', header: 'Email Address', display: 'table-cell', width:'30%'},
      { field: 'roles', header: 'roles', display: 'none'},
      { field: 'lastLoginDate', header: 'Last Login Date', display: 'none'},
      { field: 'passwordChangeRequired', header: 'passwordChangeRequired', display: 'none'}
    ];
    this.userTypes = [
      {label: '[none]', value: '[none]'},
      {label: 'Admin', value: 'admin'},
      {label: 'ACI User', value: 'aci-staff'},
      {label: 'ACI PMO', value: 'aci-pmo'},
      {label: 'ACI Read-Only', value: 'aci-read-only'},
      {label: 'Biller User', value: 'biller-user'},
      {label: 'Client Application', value: 'client-app'},
      {label: 'Customer', value: 'customer'}
    ];
    // For possible password reset
    this.passwordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern(StrongPasswordRegx)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(StrongPasswordRegx)]]
    })
    // Find out whether the current user is a portal or CSR admin
    // this.isPortalAdmin = this.userContextService.isPortalAdmin();
    this.isBillerRep = this.userContextService.isBillerRep();
    this.isAciStaff = this.userContextService.isAciStaff();
    // this.hasUserAdminRole = this.userContextService.isUserAdmin();
    this.grantedBrandCodes = this.userContextService.getUserBrands();
    this.hasAllBrandsAttribute = this.userContextService.hasAllBrands();
    // Prepopulate the dropdowns
    this.getRoleNames();
    this.getBrandCodes();
    this.getPortalRoles();
    this.getUserTypeRoles();
    // Hide the submit button for users with read-only access type
    // if (this.userContextService.hasReadOnlyAccess()) {
    //   this.isSubmitShown = false;        
    // }
    if (this.userContextService.userHasPrivilege(PRIV_USER_MODIFY)) {
      this.isSubmitShown = true;        
    }
  }

  getLocked(locked: boolean) {
    if (!locked)
      return 'success';
    else
      return 'warning';
  }

  getEnabled(enabled: boolean) {
    if (enabled)
      return 'success';
    else
      return 'warning';
  }  

  getRoleNames(): void {
    this.portalUserService.getRoleNames()
       .subscribe(names => {
         for(let i = 0; i < names.length; i++){
           this.roleNames.push({label: names[i], value: names[i]});
        }
       });
  }

  getPortalRoles(): void {
    this.portalRoleService.getRoles()
       .subscribe(roles => {
         this.portalRoles = roles;
       });
  }

  getUserTypeRoles(): void {
    this.portalUserService.getUserTypeRoles()
       .subscribe(userTypeRoles => {
        // Define the scope of role combinations that the current user's type can deal with
         this.userTypeRoles = userTypeRoles;
         // Define the scope of user types that the current user can deal with
          for (var i = this.userTypes.length - 1; i >= 0; i--) {
            let item = this.userTypes[i];
            let found = false;
            if (this.userTypeRoles.find(elem => elem.userType === item.value) || item.value === '[none]') {
              found = true;
            }
            if (!found) {
              this.userTypes.splice(i,1);
            }
          }
       });
  }

  getBrandCodes(): void {
    this.portalUserService.getBillerBrands()
       .subscribe(response => {
        this.brands = response;
        this.brands.sort(this.compare);
        this.brandCodes = [];
        // The list of brands for CSR Admins is limited to their own domain; for Portal admins - all brands
        for (let i = 0; i < this.brands.length; i++){
          // if ((this.hasUserAdminRole && this.grantedBrandCodes.includes(this.brands[i].brandCode))
          //     || (this.hasUserAdminRole && this.hasAllBrandsAttribute) 
          //     || this.isPortalAdmin) {
          if (this.grantedBrandCodes.includes(this.brands[i].brandCode) || this.hasAllBrandsAttribute) {                
            this.brandCodes.push({name: this.brands[i].brandCode + ' - ' + this.brands[i].brandName, code: this.brands[i].brandCode});
          }
        }
      });
  }

  compare(a: any, b: any) {
    if ((a.brandCode || '') > (b.brandCode || '')) return 1;
    if ((b.brandCode || '') > (a.brandCode || '')) return -1;
    return 0;
  }

  getUsers(userName: string, roleName: string, brandCode: string, userType: string): void {
    this.isSpinnerHidden = false;
    // For a biller rep admin limit the list of users to only those that have brandCode attribute within the biller rep's domain
    if (this.isBillerRep) {
      brandCode = this.grantedBrandCodes.join(",");
      userType = USER_BILLER_REP;
    } else {
      brandCode = '';
      userType = userType == '[none]' ?  '' : userType;
    }
    this.portalUserService.getUsersListing(userName, roleName, brandCode, userType)
        .subscribe (users => {
          this.users = users;
          this.isSpinnerHidden = true;
        });
  }

  onSearchClick(event: any) {
    // Retrieve the list of users
    this.getUsers(this.selectedUserName, this.selectedRoleName, this.selectedBrandCode, this.selectedUserType);
  }

  onClearClick(event: any) {
    this.selectedUserName = '';
    this.selectedRoleName = '';
    this.selectedUserType = '';
  }

  showDialogToAdd() {
    this.newUser = true;
    this.isUnlockVisible = false;
    this.userType = '[none]';
    this.allBrandsGrant = false;
    this.isRoleTabpageShown = false;
    this.user = {
      userId: 0, userName: '', enabled: true, locked: false, userRoles: [], lastLogin: '',  emailAddress: '',
      password: '', userBrands: [], userAttributes: [{ attributeCode: '', attributeValue: '' }], 
      passwordChangeRequired: true, loginFailCount: 0
    };
    // if (this.isBillerRep) {
    //   this.userType = USER_BILLER_REP;
    // }
    this.grantedRoles = [];
    this.availableRoles = [];
    var role;
    // The list of roles for biller admins is limited to only biller roles; for Portal admins - all roles
    // for (role of this.roleNames) {
    //   if ((this.isBillerRep && BILLER_ROLES.includes(role.value))
    //       || this.isAciStaff) {
    //     this.availableRoles.push(role);
    //   }
    // }
    this.selectedBrandCodes = [];
    this.tabIndex = 0;
    this.isSubmitHidden = false;
    this.displayDialog = true;
  }

  onChangeUserType(event: any) {
//    if (!this.newUser) return;
    this.grantedRoles.length = 0;
    this.availableRoles.length = 0;
    this.userTypeRoles.forEach(item => {
      if (item.userType == event.value) {
        if (item.requiredInd || item.suggestedInd) this.grantedRoles.push({label: item.roleName, value: item.roleName});
        if (item.optionalInd) this.availableRoles.push({label: item.roleName, value: item.roleName});
      }
    })
    this.availableRoles = [...this.availableRoles];
    this.grantedRoles = [...this.grantedRoles];
    this.isRoleTabpageShown = true;
  }

  onSaveUserClick() {
    if (!this.newUser && this.user.password) {
      this.confirmationService.confirm({
        message: "The user's password is being reset.  Do you want to proceed with the change?",
        header: 'Warning',
        key: "portal-user",
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Contunue",
        rejectLabel: "Cancel",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          this.save();
        },
        reject: () => {
          return;
        }            
      });
    } else {
      this.save();
    }
  }


  save() {
    // Email address has correct pattern
    let validEmail = EmailAddressRegx.test(this.user.emailAddress || '');
    let validPassword = StrongPasswordRegx.test(this.user.password || '');
    if (this.newUser && !validPassword) {
      this.displayMessage('Warning', this.passwordRuleText);
      // return;
    }
    else if (!validEmail) {
      this.displayMessage('Warning', 'The entered email address is incorrect.');
      // return;
    }
    // Email address is required for all new users
    else if (this.newUser && this.userType != 'client-app' && this.userType != 'customer' && !this.user.emailAddress) {
      this.confirmationService.confirm({
        message: 'An email address is required for all new users.',
        header: 'Warning',
        key: "portal-user",
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });
    }
    // Basic input data validation
    else if (!this.user.userName || !this.userType || this.userType =='[none]') {
      this.confirmationService.confirm({
        message: 'The user record must have a non-empty User Name and Type.',
        header: 'Warning',
        key: "portal-user",
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });
    }
    else {
      // Get a list of granted roles to assign then to the user
      if (this.user.userRoles) {
        this.user.userRoles.length = 0;
      } else {
        this.user.userRoles = [];
      }
      this.grantedRoles.forEach(role => {
        this.user.userRoles?.push(role.value);
      })
      // Get a list of granted brands to assign then to the user
      if (this.user.userBrands) {
        this.user.userBrands.length = 0;
      } else {
        this.user.userBrands = [];
      }
      if (!this.allBrandsGrant) {
        this.selectedBrandCodes.forEach(item => {
          this.user.userBrands?.push(item.code);
        })
      }
      // Assign the attributes
      this.user.userAttributes = [{attributeCode: "userType", attributeValue: this.userType}];
      if (this.allBrandsGrant) {
        this.user.userAttributes.push({attributeCode: "allBrands", attributeValue: "true"});
      }
      for( var i = 0; i < this.user.userAttributes.length; i++){ 
        if ( this.user.userAttributes[i].attributeValue === '[none]') { 
          this.user.userAttributes.splice(i, 1); 
            i--; 
        }
      }
      // If the user is defined as 'admin' or 'aci-staff', then make sure they have 'rl_aci_staff'
      if ((this.userType == 'aci-staff' || this.userType == 'admin') && !this.user.userRoles.includes('rl_aci_staff')) {
        this.user.userRoles.push('rl_aci_staff');
      }
      // If the user is defined as 'biller-user', then make sure they have 'rl_biller_rep'
      if (this.userType == 'biller-user' && !this.user.userRoles.includes('rl_biller_rep')) {
        this.user.userRoles.push('rl_biller_rep');
      }
      // If the user is defined as 'customer', then make sure they have 'rl_customer'
      if (this.userType == 'customer' && !this.user.userRoles.includes('rl_customer')) {
        this.user.userRoles.push('rl_customer');
      }
      // Submit the request      
      if (this.user.userId == null || this.user.userId == 0) {
          this.portalUserService.addUser(this.user)
          .subscribe({
            next: (response) => {
              this.processResult('The user record has been successfully added.');
            },
            error: (error) => {
              console.log("Error", error);
            },
            complete: () => {
            }
          });
        }
      else {
        this.portalUserService.modifyUser(this.user)
        .subscribe({
          next: (response) => {
            this.processResult('The user record has been successfully modified.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      }
    }
  }

  delete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the user record with all its properties, roles, brands and attributes?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",         
      accept: () => {
        this.portalUserService.deleteUser(this.user.userName)
        .subscribe({
          next: (response) => {
            this.processResult('The user record has been successfully deleted.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  unlock() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to unlock the user record?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",         
      accept: () => {
        this.portalUserService.unlockUser(this.user.userName)
        .subscribe({
          next: (response) => {
            this.processResult('The user record has been successfully unlocked.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  cancel() {
    this.user = {
      userId: 0, userName: '', enabled: false, locked: false, userRoles: [], lastLogin: '',  
      password: '', userBrands: [], userAttributes: [{ attributeCode: '', attributeValue: '' }], 
      passwordChangeRequired: true, loginFailCount: 0
    };
    this.displayDialog = false;
  }

  onRowSelect(event: any) {
    this.tabIndex = 0;
    this.isSubmitHidden = false;
    this.passwordForm.patchValue({
      newPassword: null, confirmPassword: null
    });  
    var role: any;
    this.newUser = false;
    this.grantedRoles = [];
    this.availableRoles = [];
    this.selectedBrandCodes = [];
    let userName = event.data.userName;
    this.isRoleTabpageShown = true;
    this.portalUserService.getUser(userName)
    .subscribe (user => {
        if (user) {
          this.user = user;
          if (this.user.hasOwnProperty('lastLogin')) {
            this.user.lastLogin = this.sharedService.formatFromISO(this.user.lastLogin || '');
          }
          if (user.hasOwnProperty('lastUpdateDate')) {
           this.user.lastUpdateDate = this.sharedService.formatFromISO(this.user.lastUpdateDate || '');
          }
          if (user.hasOwnProperty('createDate')) {
            this.user.createDate = this.sharedService.formatFromISO(this.user.createDate || '');
          }
          this.isUnlockVisible = this.user.locked;
          // do not populate 'password' field for an existing user, so we do not change it by accident
          this.user.password = '';
          // Attributes
          this.allBrandsGrant = false;
          this.userType = '[none]';
          if (this.user.hasOwnProperty('userAttributes')) {
            let attributes = this.user.userAttributes;
            if (attributes) {
              attributes.filter(attribute => attribute.attributeCode == 'allBrands').forEach(attribute => {
                this.allBrandsGrant = (attribute.attributeValue.toLowerCase() == 'true');
              })
              attributes.filter(attribute => attribute.attributeCode == 'userType').forEach(attribute => {
                this.userType = attribute.attributeValue;
              })
            }
          }
          // User roles
          if (this.user.hasOwnProperty('userRoles')) {
            for (role of this.roleNames) {
              if (this.user.userRoles?.includes(role.value)) {
                this.grantedRoles.push(role);
              }
              else {
                // The list of roles for biller admins is limited to only biller roles; for Portal admins - all roles
                // if ((this.isBillerRep && BILLER_ROLES.includes(role.value))
                //     || this.isAciStaff) {
                // if (this.userTypeRoles.find(elem => elem.roleName === role)) {
                //   this.availableRoles.push(role);
                // }
                this.userTypeRoles.forEach(item => {
                  if (item.userType === this.userType && item.roleName == role.value) {
                    this.availableRoles.push(role);                    
                  }
                })
              }
            }
          } else {
            //for (role of this.roleNames) {
              // The list of roles for biller admins is limited to only biller roles; for Portal admins - all roles
              // if ((this.isBillerRep && BILLER_ROLES.includes(role.value))
              //     || this.isAciStaff) {
              //     this.availableRoles.push(role);
              // }
            //}
            this.userTypeRoles.forEach(item => {
              if (item.userType == this.userType) {
                if (item.requiredInd) this.grantedRoles.push({label: item.roleName, value: item.roleName});
                if (item.optionalInd || item.suggestedInd) this.availableRoles.push({label: item.roleName, value: item.roleName});
              }
            })
          }
          this.availableRoles = [...this.availableRoles];
          this.grantedRoles = [...this.grantedRoles];
          // Prepopulate already granted brand codes
          if (this.user.hasOwnProperty('userBrands')) {
            // if (this.user.userBrands.length > 0) {
            if (this.user.userBrands) {
              this.user.userBrands?.forEach(brandCode => {
                var index = this.brands.findIndex(element => element.brandCode == brandCode);
                if (index >= 0) {
                  this.selectedBrandCodes.push({name: this.brands[index].brandCode + ' - ' + this.brands[index].brandName, code: brandCode});
                }
              })
            }
          }
          // console.log('this.selectedBrandCodes', this.selectedBrandCodes);
          this.selectedBrandCodes = [...this.selectedBrandCodes];
          this.displayDialog = true;
        }
      // }
    });
  }

  processResult(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Notification',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
    this.getUsers(this.selectedUserName, this.selectedRoleName, '', '');
    this.user = {
      userId: 0, userName: '', enabled: false, locked: false, userRoles: [], lastLogin: '',  emailAddress: '',
      password: '', userBrands: [], userAttributes: [{ attributeCode: '', attributeValue: '' }], 
      passwordChangeRequired: true, loginFailCount: 0
    };
    this.displayDialog = false;
  }

  onSourceSelect(event: any){
    if (event && event.items.length == 1) {
      this.getRoleDescrHint(event.items[0]);
    }
  }

  onTargetSelect(event: any){
    if (event && event.items.length == 1) {
      this.getRoleDescrHint(event.items[0]);
    }
  }

  getRoleDescrHint(item: any){
    this.portalRoles.forEach(role => {
      if (role.roleName == item.value) {
        if (role.roleDescription) {
          this.roleDescrHint = item.value + ' : ' +  role.roleDescription;
        } else {
          this.roleDescrHint = '';
        }
      }
    }) 
  }

  onSubmitPassswordReset() {
    let confirmPassword: string = this.passwordForm.get('confirmPassword')?.value;
    let newPassword: string = this.passwordForm.get('newPassword')?.value;
    if (newPassword != confirmPassword) {
      this.displayMessage('Warning',"New and confirm passwords do not match.");
      return;
    }
    else {
      this.userContextService.revampUserPassword(this.user.userName, newPassword)
      .subscribe({
        next: (response) => {
          this.displayMessage('Notification', "The user's password has been successfully changed.");
          this.tabIndex = 0;
          this.passwordForm.patchValue({
            newPassword: null, confirmPassword: null
          });  
          return;                      
        },
        error: (error) => {
          this.displayMessage('Warning', this.passwordRuleText);
        },
        complete: () => {
        }
      });
    }
  }  

  onActiveTabChange(event: any) {
    if (event.index == 3) {
      this.isSubmitHidden = true;
      this.passwordForm.patchValue({
        newPassword: null, confirmPassword: null
      });  
    } else {
      this.isSubmitHidden = false;
    }
  }

  displayMessage(header: string, message: string) {
    this.confirmationService.confirm({
      message: message,
      key: "portal-user",   
      header: header,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
  }

}
