import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldVariableField extends OfferFieldBase {

    brandVariables: any[];
    linkCategories: any[];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        brandVariables: any[],
        linkCategories: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.brandVariables = brandVariables;
            this.linkCategories = linkCategories;
            this.setOfferFieldAttributes();
        } 

    // public getOfferFieldAttributes(): OfferFieldAttribute<string>[] {
    public setOfferFieldAttributes() {

        // Field Value, Alt Field Value, API Setting, Snapshot Variable, Hide from Distrib URL?, Merge Field, Var Transformation Expression

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'altFieldValue',
            label: 'Alt Field Value',
            value: this.data.altFieldValue,
            styleWidth: '20em',
            required: false,
            order: 5
        }));

        // this.offerFieldAttributes.push( new OfferFieldAttribute({
        //     controlType: 'dropdown',
        //     key: 'overrideStatus',
        //     label: 'API Setting',
        //     value: this.data.overrideStatus,
        //     required: false,
        //     tooltip: 'Select the proper API setting on the pass',
        //     options: [
        //         {label: '[none]', value: 'A'},
        //         {label: 'Client Provided Value',  value: 'B'},
        //         {label: 'Client Provided URL',  value: 'C'},
        //         {label: 'External Service',   value: 'D'},
        //         {label: 'Client Provided Temp Value',   value: 'T'}
        //     ],
        //     order: 6
        // }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'variableName',
            label: 'Snapshot Variable',
            styleWidth: '10em',
            value: this.data.variableName,
            required: false,
            tooltip: 'Select the proper snapshot variable on the pass',
            options: this.brandVariables,
            order: 7
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'checkbox',
            key: 'hiddenFromDistribUrl',
            label: 'Hide from Distrib URL?',
            checked: this.data.hiddenFromDistribUrl,
            required: false,
            order: 8
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'mergeField',
            label: 'Merge Field',
            styleWidth: '10em',
            value: this.data.mergeField,
            required: false,
            order: 9
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'offerlink',
            label: 'Link',
            value: this.data.offerlink,
            required: false,
            styleWidth: '40em',
            validator: 'url',
            tooltip: 'Invalid URL link',
            order: 10
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'linkCategoryCode',
            label: 'Link Category',
            value: this.data.linkCategoryCode,
            styleWidth: '20em',
            required: false,
            tooltip: 'Select the proper link category',
            options: this.linkCategories,
            order: 11
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 12
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'varTransformationExpression',
            label: 'Var Transformation Expression',
            value: this.data.varTransformationExpression,
            required: false,
            order: 13,
            rows: 4,
            cols: 36
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }
}
