import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { Validators,FormControl,FormGroup,FormBuilder } from '@angular/forms';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { SharedService } from "../../services/shared.service";
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-qrcode-generation',
  templateUrl: './qrcode-generation.component.html',
  styleUrls: ['./qrcode-generation.component.css']
})
export class QrcodeGenerationComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "QRCodes";
  titleMessage: string = "QR Code";
  isCurrentBrandShown: boolean = true;
  isEnrollmentLinkShown: boolean = false;
  isShortenLinkShown: boolean = false;
  openBrandSelectionDialog!: boolean;  
  currentBrand: IBrand;

  userForm!: FormGroup;

  passTemplates: any[] = [];
  offers: any[] = [];
  imageSizes: any[] = [];
  sourceChannels: any[] = [];

  enrollmentLinkKey: string = '';
  enrollmentLinkValue: string = '';
  shortenLinkValue: string = '';
  image: any;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService) {
      this.currentBrand = this.sharedService.getDummyBrand();
    }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      'passTemplate': new FormControl('', [Validators.required]),
      'offer': new FormControl('', [Validators.required]),
      'imageSize': new FormControl('150', [Validators.required]),
      'sourceChannel': new FormControl('', [Validators.required]),
    });
    this.imageSizes = [
      {label: '75', value: '75'},
      {label: '100', value: '100'},
      {label: '125', value: '125'},
      {label: '150', value: '150'},
      {label: '175', value: '175'},
      {label: '200', value: '200'},
      {label: '225', value: '225'},
      {label: '250', value: '250'},
      {label: '275', value: '275'},
      {label: '300', value: '300'}
    ];
    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.getPassTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getOffers(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getChannels(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }  
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.enrollmentLinkKey = '';
      this.enrollmentLinkValue = '';
      this.shortenLinkValue = '';
      this.isCurrentBrandShown = false;
      this.isEnrollmentLinkShown = false;
      this.isShortenLinkShown = false;
      this.currentBrand = item;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.sharedService.setCurrentBrand(item);
      this.getPassTemplates(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
      this.getOffers(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
      this.getChannels(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
    }
  }  

  // Select enrollment links for the given combination of brand, pass template, and offer
  onSubmit(value: string) {
    // this.isSubmitShown = false;
    let brandCode = this.currentBrand.brandCode;
    let passTemplate = this.userForm.value.passTemplate;
    let offer = this.userForm.value.offer;
    let channel = this.userForm.value.sourceChannel;
    if (!channel) {
      channel = brandCode?.toUpperCase() + "QRCode";
    }
    // Call the service to invoke a Web API call
    this.brandProfileService.getEnrollmentLinks(brandCode || '', passTemplate, offer, this.currentBrand.baseUrl || '')
    .subscribe({
      next: (response) => {
        response.forEach(item => {
          if (item.key.includes("Smart")) {
            this.enrollmentLinkKey = item.key;
            let pos = item.value.indexOf("&");
            this.enrollmentLinkValue = item.value.substring(0, pos) + "&src=" + channel;
            this.isEnrollmentLinkShown = true;
            this.isShortenLinkShown = false;
          }
        })
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  } 

  onQrCodeClick (event: any) {
    let imageSize = this.userForm.value.imageSize;
    // Find out the shorten url
    this.brandProfileService.getShortenUrl(this.enrollmentLinkValue, this.currentBrand.baseUrl || '')
    .subscribe({
      next: (response) => {
        this.shortenLinkValue = response;
        this.isShortenLinkShown = true;

        // Generate the barcode
        this.brandProfileService.getQrCode(this.shortenLinkValue, imageSize, this.currentBrand.baseUrl || '')
        .subscribe({
          next: (response) => {
            // Display the image on the page
            let imageType = response.imageType;
            let imageContent = 'data:' + imageType + ';base64,' + response.imageContent;
            this.image = imageContent

            // Save the image to the local disk
            const dlink: HTMLAnchorElement = document.createElement('a');
            dlink.download = this.currentBrand.brandCode + '-barcode-' + imageSize + '.png'; // the file name
            dlink.href = imageContent;
            dlink.click(); // this will trigger the dialog window
            dlink.remove();
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
}

  // Populate pass templates and brand offers dropdowns
  getPassTemplates(brandCode: string, baseUrl: string) {
    this.passTemplates.length = 0;
    this.userForm.patchValue({passTemplate: null});
    // Select all existing brand pass templates
    this.brandProfileService.getPassTemplates(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        response.forEach(item => {
          this.passTemplates.push({label: item.passName, value: item.passId});
        })
        // If there is just one pass template, then default to it
        if (response.length == 1) {
          this.userForm.patchValue({passTemplate: response[0].passId});
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Select all existing brand offers
  getOffers(brandCode: string, baseUrl: string) {
    this.offers.length = 0;
    this.userForm.patchValue({offer: null});
    this.brandProfileService.getBrandOffers(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        response.forEach(item => {
          this.offers.push({label: item.offerName, value: item.offerId});
          // If there is just one offer, then default to it
          if (response.length == 1) {
            this.userForm.patchValue({offer: response[0].offerId});
          }
        })
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  getChannels(brandCode: string, baseUrl: string) {
    this.userForm.patchValue({sourceChannel: null});
    this.sourceChannels.length = 0;
    this.sourceChannels.push({label: '', value: ''});
    this.brandProfileService.getSourceChannels(brandCode, baseUrl)
    .subscribe({
      next: (response) => {
        this.sourceChannels.push({label: '', value: ''});
        if (response) {
          response.forEach (channel => {this.sourceChannels.push({label: channel, value: channel})})
        }
        this.userForm.patchValue({sourceChannel: ''});
      },
      error: (error) => {
        if (error.status != 404) {
          this.alertTheUser(error.message);
        }
        this.userForm.patchValue({sourceChannel: ''});
      },
      complete: () => {
      }
    });
  }

  // onChangePassTemplate(event) {
  //   if (this.selectedPassTemplate && this.selectedOffer) {
  //     this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, this.currentBrand.baseUrl);
  //   }
  // } 

  // onChangeOffer(event) {
  //   if (this.selectedPassTemplate && this.selectedOffer) {
  //     this.getEnrollmentLinks(this.currentBrand.brandCode, this.selectedPassTemplate, this.selectedOffer, this.currentBrand.baseUrl);
  //   }
  // }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'qrcode-generation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
