import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IBrandImage } from '../../interfaces/brand-image';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { SharedService } from "../../services/shared.service";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-brand-images',
  templateUrl: './brand-images.component.html',
  styleUrls: ['./brand-images.component.css']
})
export class BrandImagesComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "BrandImages";
  titleMessage = "Brand Images";
  isCurrentBrandShown = true;
  isImagePreviewShown = false;
  isImageSelected = false;

  brandImages: any[] = [];
  cols!: any[];
  image: any;
  currentBrand: IBrand;
  selectedImage!: IBrandImage;
  fileContent: any;

  openBrandSelectionDialog!: boolean;  
  isSubmitShown = false;

  imageFile!: File
  
  constructor(
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService
  ) {
    this.currentBrand = this.sharedService.getDummyBrand();    
  }

  ngOnInit(): void {
    // Hide the add image section for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY')) {
      this.isSubmitShown = true;
    }
       
    this.cols = [
      { field: 'imageID', header: 'Image Id', display: 'table-cell', width:'10%'  },
      { field: 'imageName', header: 'Image Name', display: 'table-cell', width:'20%'  },
      { field: 'imageType', header: 'Image Type', display: 'table-cell', width:'12%'  },
      { field: 'imageUID', header: 'Image UID', display: 'table-cell', width:'30%'  },
      { field: 'imageSize', header: 'Image Size', display: 'table-cell', width:'10%'  },
      { field: 'createDate', header: 'Create Date', display: 'table-cell', width:'14%'  },
    ]; 

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.getBrandImages(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }

  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.sharedService.setCurrentBrand(item);
      this.getBrandImages(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    }
  }

  // Select all existing brand images
  getBrandImages(brandCode: string, brandUrl: string) {
    this.isImagePreviewShown = false;
    this.isImageSelected = false;
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandImages(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        this.brandImages = response;
        // Format the dates
        for(let i = 0; i < response.length; i++){
          if (response[i].createDate) {
            response[i].createDate = this.sharedService.formatFromEpoch(response[i].createDate || '');
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowSelect(event: any) {
    let imageType = event.data.imageType;
    this.image = 'data:' + imageType + ';base64,' + event.data.imageContent;
    this.isImagePreviewShown = true;
  }

  onLogoFileChange (event: any) {
    this.isImageSelected = true;
    this.imageFile = event.files[0];
    const reader = new FileReader();
    let buffer: string = '';

    reader.addEventListener("load", function () {
      // convert image file to base64 string
      let textarea = document.querySelector('textarea');
      if (textarea) {
        const image = reader.result;
        if (image) {
          if (typeof image === 'string') {
            buffer = image;
          } else {
            buffer = image.toString();
          }
        }
        textarea.value = buffer;
        }
      }, 
    false);

    if (this.imageFile) {
      reader.readAsDataURL(this.imageFile);
    }
  } 

  onSubmitImage () {
    if (this.imageFile) {
      // Get the file content
      let textarea = document.querySelector('textarea');
      if (textarea) {
        let fileContent: string = textarea.value.split(',')[1];
        let imageDescr : IBrandImage = {
          brandCode: this.currentBrand.brandCode,
          imageName: this.imageFile.name,
          imageType: this.imageFile.type,
          imageSize: this.imageFile.size,
          imageContent: fileContent,
          imageUID: '',
          imageID: 0
        }
        // Call the service to invoke a Web API call
        this.brandProfileService.addBrandImage(this.currentBrand.brandCode, imageDescr, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.image = null;
            this.isImagePreviewShown = false;
            this.alertTheUser('Successfully added image ' + imageDescr.imageName + ' to brand ' + this.currentBrand.brandCode);
            this.getBrandImages(this.currentBrand.brandCode, this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
            },
          complete: () => {
          }
        });
      }
    }
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the image
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the image ' + event.imageName + ' ?',
      header: 'Confirmation',
      key: 'brand-images',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteBrandImage(this.currentBrand.brandCode, event.imageUID, this.currentBrand.baseUrl)
        .subscribe({
          next: (response) => {
            this.image = null;
            this.isImagePreviewShown = false;
            this.alertTheUser('Successfully deleted image ' + event.imageName + ' from brand ' + this.currentBrand.brandCode);
            this.getBrandImages(this.currentBrand.brandCode, this.currentBrand.baseUrl);
          },
          error: (error) => {
            this.alertTheUser(error.message);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-images',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
