import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountField } from '../../classes/account-field';

@Component({
  selector: 'app-ev-field',
  templateUrl: './dynamic-ev-field.component.html',
  styleUrls: ['./dynamic-ev-field.component.css']
})
export class DynamicEvFieldComponent implements OnInit {

  @Input() evField: AccountField<string>;
  @Input() form: FormGroup;

  constructor(
    private fb: FormBuilder    
  ) { 
    this.evField = new AccountField();
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
  }

}
