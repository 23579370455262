import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldOnlyTemplate extends OfferFieldBase {

    templates: any[] = [];
    templateType: string;

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        emailSmsTemplates: any[],
        templateType: string
    ) { 
        super(offerFieldData, offerFieldTypes);
        this.data = offerFieldData;
        this.templateType = templateType;
        this.templates.push({label: '[none]', value: ''});
        emailSmsTemplates.forEach(item => {
            if (item.templateType == templateType) {
                this.templates.push({label: item.templateName, value: item.templateID.toString()});
            }
          });
        this.setOfferFieldAttributes();
    } 

    public setOfferFieldAttributes() {

        // Field Value, Email/SMS Template, Show/hide Field Expression(N)

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            rows: 1,
            cols: 36,
            required: true,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'mailtoTemplateID',
            label: this.templateType == 'E' ? 'Email Template' : 'SMS Template',
            value: this.data.hasOwnProperty('mailtoTemplateID') ? this.data.mailtoTemplateID?.toString() : '',
            required: true,
            tooltip: this.templateType == 'E' ? 'Select the proper email template' : 'Select the proper SMS template',
            options: this.templates,
            styleWidth: '30em',
            order: 6
        }));
       
        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 7
        }));
        
        this.offerFieldAttributes.sort((a, b) => a.order - b.order);

    }

}
