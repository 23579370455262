import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from "primeng/api";
import { EncryptionUtilityService } from '../../services/config/encryption-utility.service';

@Component({
  selector: 'app-encryption-utility',
  templateUrl: './encryption-utility.component.html',
  styleUrls: ['./encryption-utility.component.css']
})
export class EncryptionUtilityComponent implements OnInit {

  selectedEnvironment!: string;
  textToEncrypt!: string;
  encryptedText!: string;
  environments: any[] = [];

  autoResize: boolean = true;

  constructor(
    private encryptionUtilityService: EncryptionUtilityService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.environments.push({label: 'QA', value: 'QA'});
    this.environments.push({label: 'UAT', value: 'UAT'});
    this.environments.push({label: 'PROD', value: 'PROD'});
    this.selectedEnvironment = "QA";
  }

  onEncryptTextClick() {
    // Basic input data validation
    if (!this.selectedEnvironment || !this.textToEncrypt) {
      this.confirmationService.confirm({
        message: 'Please select Environment and enter in Text to Encrypt',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });
    }
    else {
      this.encryptionUtilityService.encryptText(this.selectedEnvironment, this.textToEncrypt)
      .subscribe({
        next: (response) => {
          this.encryptedText = response.toString();
        },
        error: (error) => {
          console.log("Error", error);
        },
        complete: () => {
        }
      });
    }
  }


}
