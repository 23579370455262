<h2>Brand Listing</h2>

<div class="grid">

    <div class="col-8">

        <p-table #dt [columns]="cols" [value]="brands" selectionMode="single" 
            [(selection)]="selectedBrand"  (onRowSelect)="onRowBrandSelect($event)" 
            sortMode="multiple" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rows]="15" >
          <ng-template pTemplate="caption">
              List of Brands
          </ng-template>
          <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th pSortableColumn="isActive" style="width: 5%">Active? <p-sortIcon field="isActive"></p-sortIcon></th>
            </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    <input *ngSwitchCase="'brandCode'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '50%'}">
                    <input *ngSwitchCase="'brandName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                    <input *ngSwitchCase="'envCode'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '50%'}">
                </th>
            </tr>  
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
                  <td>
                    <p-tag [value]="rowData.isActive ? 'Y' : 'N'" [severity]="getStatus(rowData.isActive)"></p-tag>
                </td>                  
            </tr>
          </ng-template>

        </p-table> 
      
    </div>

    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
    </div>

</div>    

    <div class="grid">
        <div class="col-8" style="background-color: #7b95a3; text-align: left;"> 

            <div class="grid">
                <div class="col-1"></div>
                <div class="field grid">
                    <label for="emailAddress" class="col-fixed label" style="width: 15em">Receiver's Email: </label>
                </div>
                <div class="col-3 group">
                    <textarea [rows]="1" [cols]="60" pInputTextarea [autoResize]="autoResize" id="emailAddress" 
                        [(ngModel)]="emailAddress" style="width: 100%;"></textarea>
                </div>

                <div class="col-2 group">
                    <p-button type="button" label="Get Enrolled Accounts" (click)="onEnrolledAccountsClick($event)"
                        pTooltip="Generate an email with a list of enrolled account numbers for the selected brand"
                        styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                    </p-button>
                </div> 
                <div class="col-2 group">
                    <p-button type="button" label="Account Data Extract" (click)="onAccountDataExtract($event)"
                        *ngIf="isAccountDataShown"
                        pTooltip="Generate an email with an extract of enrolled accounts, including user specified data elements, for the selected brand"
                        styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                    </p-button>
                </div> 
                <div class="col-2 group">
                    <p-button type="button" label="Remove Brand" (click)="onRemoveBrandClick($event)"
                        *ngIf="isRemoveBrandShown"
                        pTooltip="Completely delete the selected brand, including all its configuration and customer data"
                        styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                    </p-button>
                </div> 
    
            </div>                
    
        </div>
    </div> 


<!-- </div> -->

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-listing"></p-confirmDialog>

<p-dialog appendTo="body" header="Account Data Extract" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '30%'}">

        <p-card header="Brand Level Variables" subheader="" styleClass="ui-card-light" >

            <p-pickList [source]="availableVariables" [target]="selectedVariables" sourceHeader="Available" targetHeader="Selected" 
                    [sourceStyle]="{'height':'25rem'}" [targetStyle]="{'height':'25rem'}"
                    styleClass="ui-card-light" filterBy="tags"
                    [responsive]="true" [dragdrop]="true" [showSourceControls]="false" [showTargetControls]="true">
                <ng-template let-variable pTemplate="item">
                <div class="ui-helper-clearfix">
                    <div>{{variable.label}}</div>
                </div>
                </ng-template>
            </p-pickList>

        </p-card>

        
        <p-footer>
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
<!-- 
                <button type="button" pButton icon="fa fa-check p-button-raised" (click)="onSubmitAccountExtract()" label="Submit"></button>
                <button type="button" pButton icon="fa fa-cancel p-button-raised" (click)="cancel()" label="Cancel"></button>
 -->
                <p-button type="button" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSubmitAccountExtract()"></p-button>
                <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancel()"></p-button>


            </div>
        </p-footer>


</p-dialog>

