import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldSmsPhoneEditor extends OfferFieldBase {

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.setOfferFieldAttributes();
    } 

    // Field Key, Field Label (N), Field Value, Field Alignment, Field Data Type and Style, API Setting (N), Show/hide Field Expression (N)

    public setOfferFieldAttributes() {

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldLabel',
            label: 'Field Label',
            value: this.data.fieldLabel,
            styleWidth: '25em',
            required: false,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'overrideStatus',
            label: 'API Setting',
            value: this.data.overrideStatus,
            required: false,
            styleWidth: '15em',
            tooltip: 'Select the proper API setting on the pass',
            options: [
                {label: '[none]', value: 'A'},
                {label: 'Client Provided Value',  value: 'B'},
                {label: 'Client Provided URL',  value: 'C'},
                {label: 'External Service',   value: 'D'},
                {label: 'Client Provided Temp Value',   value: 'T'}
            ],
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: true,
            styleWidth: '30em',
            order: 7
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }

}
