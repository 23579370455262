<div [formGroup]="form">

    <div class="field grid">
        <div class="col-1"></div>
        <label for="evField.key" class="col-fixed label" style="width: 15em">{{evField.label}}:</label>
        <div class="col">
            <input pInputText [id]="evField.key" type="text"  [formControlName]="evField.key" /> 
        </div>
    </div>
    
</div>

