<div class="grid">

  <div class="col-11">

      <p-card header="Test Suite Log" subheader="" styleClass="ui-card-light">

    <!--  T E S T   S U I T E   C R I T E R I A  -->

    <div class="formgrid grid">
      <div class="field col-1">
          <label for="suiteName">Suite Name:</label>
          <p-dropdown id ="suiteName" [options]="suiteNames" [(ngModel)]="selectedSuiteName" [filter]="true" 
            styleClass="prime-dropdown" placeholder="Suite Name:" [editable]="false" (onChange)="onSuiteNameChange($event)"></p-dropdown>
      </div>
      <div class="field col-1">
          <label for="testName">Test Name:</label>
          <p-dropdown id ="testName" [options]="testNames" [(ngModel)]="selectedTestName" [filter]="true" 
            styleClass="prime-dropdown" placeholder="Test Name:" [editable]="false"  class="margin-left"></p-dropdown>
      </div>
      <div class="field col-1">
        <label for="jobDateFrom">From:</label>
        <p-calendar id="jobDateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" placeholder="Date From:" 
          [showIcon]="true" class="margin-left"></p-calendar>
      </div>
      <div class="field col-1">
        <label for="selectedDateTo">To:</label>
        <p-calendar id="jobDateTo" [(ngModel)]="selectedDateTo" dateFormat="yy-mm-dd" placeholder="Date To:" 
          [showIcon]="true" class="margin-left"></p-calendar>
      </div>

      <div class="col-1"></div>

      <div class="col-2">
        <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onTestSuiteSearchClick($event)"></p-button>
        <p-button label="Next" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onTestSuiteNextClick($event)"></p-button>
        <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onTestSuiteClearClick($event)"></p-button>
      </div>

      <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
      </div>

    </div>


    <!--  T E S T   S U I T E   G R I D  -->

    <div class="grid">
      <div class="col-9">

        <p-table #dtt [columns]="testSuiteCols" [value]="testSuites" selectionMode="single" [(selection)]="selectedTestSuite" [responsive]="true"
            tableStyleClass="prime-table" [rowsPerPageOptions]="[10,15,25,50]" [showCurrentPageReport]="true"
            sortMode="multiple" (onRowSelect)="onTestSuiteRowSelect($event)" [paginator]="true" [rows]="15">
          <ng-template pTemplate="caption">
            Test Suite Log
          </ng-template>
          <ng-template pTemplate="caption">
            <div style="text-align: right">        
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dtt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
            </div>
        </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
        </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary" let-rowData>
            <div style="text-align:left">
            </div>
          </ng-template>    
        </p-table> 

      </div>
    </div>

  </p-card> 
</div>

</div>    

 <!--  T E S T   S U I T E   D E T A I L S  -->

 <p-dialog appendTo="body" header="Test Suite Details" [(visible)]="displayDialog" [focusOnShow]="false" 
    [responsive]="true" showEffect="fade" [modal]="true"  [style]="{width: '50%'}">
  <div class="ui-g ui-fluid" *ngIf="testSuite">

    <p-tabView  orientation="top">

      <p-tabPanel header="Details" leftIcon="pi pi-volume-up">

        <div class="field grid">
          <label for="sessionGuid" class="col-fixed label" style="width:150px">Session Uuid:</label>
          <div class="col">
              <input pInputText id="sessionGuid" [(ngModel)]="testSuite.sessionGuid" autofocus readonly style="width: 30em;">
          </div>
        </div>           

        <div class="field grid">
          <label for="suiteName" class="col-fixed label" style="width:150px">Suite Name:</label>
          <div class="col">
              <input pInputText id="suiteName" [(ngModel)]="testSuite.suiteName" autofocus readonly style="width: 30em;">
          </div>
        </div>           

        <div class="field grid">
          <label for="testName" class="col-fixed label" style="width:150px">Test Name:</label>
          <div class="col">
              <input pInputText id="testName" [(ngModel)]="testSuite.testName" autofocus readonly style="width: 40em;">
          </div>
        </div>           

        <div class="field grid">
          <label for="testDesc" class="col-fixed label" style="width:150px">Test Desc:</label>
          <div class="col">
              <input pInputText id="testDesc" [(ngModel)]="testSuite.testDesc" autofocus readonly style="width: 40em">
          </div>
        </div>           

        <div class="grid">
          <div class="field grid">
            <label for="seqNo" class="col-fixed label" style="width:150px">Seq No:</label>
            <div class="col">
                <input pInputText id="seqNo" [(ngModel)]="testSuite.seqNo" autofocus readonly style="width: 10em">
            </div>
          </div>
          <div class="col-1"></div>
          <div class="field grid">
            <label for="status" class="col-fixed label" style="width:150px">Test Status:</label>
            <div class="col">
                <input pInputText id="status" [(ngModel)]="testSuite.status" autofocus readonly style="width: 10em">
            </div>
          </div>
        </div>           

        <div class="grid">
          <div class="field grid">
            <label for="envRegion" class="col-fixed label" style="width:150px">Env. Region:</label>
            <div class="col">
                <input pInputText id="envRegion" [(ngModel)]="testSuite.envRegion" autofocus readonly style="width: 10em">
            </div>
          </div>
          <div class="col-1"></div>
          <div class="field grid">
            <label for="envHost" class="col-fixed label" style="width:150px">Env. Host:</label>
            <div class="col">
                <input pInputText id="envHost" [(ngModel)]="testSuite.envHost" autofocus readonly style="width: 10em">
            </div>
          </div>
        </div>           

        <div class="grid">
          <div class="field grid">
            <label for="startTimestamp" class="col-fixed label" style="width:150px">Started:</label>
            <div class="col">
                <input pInputText id="startTimestamp" [(ngModel)]="testSuite.startTimestamp" autofocus readonly style="width: 20em;">
            </div>
          </div>
          <div class="col-1"></div>
          <div class="field grid">
            <label for="endTimestamp" class="col-fixed label" style="width:150px">Finished:</label>
            <div class="col">
                <input pInputText id="endTimestamp" [(ngModel)]="testSuite.endTimestamp" autofocus readonly style="width: 20em;">
            </div>
          </div>
        </div>           

      </p-tabPanel>

      <p-tabPanel header="Parameters" leftIcon="pi pi-list">

        <div class="col-7">
          <p-table #dt [columns]="paramCols" [value]="params" selectionMode="single" 
              tableStyleClass="prime-table" sortMode="multiple" [paginator]="true" [rows]="10">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
            </ng-template>
          </p-table> 
        </div>
      </p-tabPanel>

      <p-tabPanel header="Test Results" leftIcon="pi pi-info">

        <div class="col-10">
          <p-table #dt [columns]="testResultsCols" [value]="testResults" selectionMode="single" 
              tableStyleClass="prime-table" sortMode="multiple" [paginator]="true" [rows]="10">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
            </ng-template>
          </p-table> 
      </div>

      </p-tabPanel>

    </p-tabView>
  
  </div>


  <p-footer>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <!-- <button type="button" pButton icon="fa fa-check" (click)="testSuiteCancel()" label="Cancel"></button> -->
          <p-button label="Cancel" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="testSuiteCancel()"></p-button>
        </div>
  </p-footer>
</p-dialog>

<p-confirmDialog  class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>


