import { Component, Input, OnInit } from '@angular/core';
import { IBrand } from '../../interfaces/brand';
import { IOffer } from '../../interfaces/offer';
import { IPassTemplate } from '../../interfaces/pass-template';

@Component({
  selector: 'app-brand-admin-header',
  templateUrl: './brand-admin-header.component.html',
  styleUrls: ['./brand-admin-header.component.css']
})
export class BrandAdminHeaderComponent implements OnInit {

  // @Input() title: string;
  // @Input() currentBrand: IBrand;  

  titleHeader: string = '';
  brandLogoImage: any = null;
  isbrandLogoImageShown: boolean = false;
  isHeaderShown: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  // makeTitleMessage (title: string, currentBrand: IBrand) {
  makeTitleMessage (brand: IBrand, title: string, offer?: IOffer, passTemplate?: IPassTemplate) {
      // Display the brand name and code
    this.titleHeader = '';
    if (brand) {
      this.titleHeader += 'Brand: ' + brand.brandName + ' ( ' + brand.brandCode + ' ) >> ';
    }
    // Display the brand logo image, if possible    
    this.brandLogoImage = null;
    this.isbrandLogoImageShown = false;
    if (brand.logoImageContent) {
      this.brandLogoImage = 'data:' + brand.logoImageType + ';base64,' + brand.logoImageContent;
      this.isbrandLogoImageShown = true;
    }
    if (offer) {
      this.titleHeader += 'Field Set: ' + offer.offerName + ' ';
    }
    if (passTemplate) {
      this.titleHeader += 'Pass Template: ' + passTemplate.passName + ' >> '
    }
    this.titleHeader += title;
    this.isHeaderShown = true;
  }

}
