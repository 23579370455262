import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IOffer } from '../../interfaces/offer';
import { IOfferField } from '../../interfaces/offer-field';
import { IOfferFieldType }  from '../../interfaces/offer-field-type';
import { OfferFieldBase } from '../../classes/offer-field-base';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { OfferFieldFactoryService }  from '../../services/construct/offer-field-factory.service';
import { SharedService } from "../../services/shared.service";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-offer-fields',
  templateUrl: './offer-fields.component.html',
  styleUrls: ['./offer-fields.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class OfferFieldsComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  titleMessage = "Field";
  isListShown = true;
  isListOfOfferFieldsShown = true;
  isDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;
  isImagePreviewShown = false;
 
  currentBrand: IBrand;
  currentOffer: IOffer;

  offerFields: any[] = [];
  cols: any[] = [];
  brandVariables: any[] = [];
  brandImages: any[] = [];
  offerFieldTypes: any[] = [];
  offerFieldTypesList: IOfferFieldType[] = [];
  notifSchedDescrTypes: any[] = [];
  emailSmsTemplates: any[] = [];
  linkCategories: any[] = [];
  origFieldListOrder: number[] = [];
  resultMsg = "";
  image: any;

  isNewOfferField!: boolean;

  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';
  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);

  form!: FormGroup;
  offerField!: OfferFieldBase;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private offerFieldFactoryService: OfferFieldFactoryService,
    private router: Router,
    private sharedService: SharedService,
    private userContextService: UserContextService,    
    private cpService: ColorPickerService,
    private confirmationService: ConfirmationService) {
      this.currentBrand = this.sharedService.getDummyBrand();
      this.currentOffer = this.sharedService.getDummyOffer();            
    }

  ngOnInit(): void {
    // Define the columns on the grid
    this.cols = [
      { field: 'offerFieldId', header: 'Id', display: 'table-cell', width:'6%'  },
      { field: 'fieldType', header: 'Field Type', display: 'table-cell', width:'13%'  },
      { field: 'fieldKey', header: 'Field Key', display: 'table-cell', width:'11%'  },
      { field: 'fieldLabel', header: 'Field Label', display: 'table-cell', width:'13%'  },
      { field: 'fieldValue', header: 'Field Value', display: 'table-cell', width:'24%'  },
      { field: 'fieldOrder', header: 'Field Order', display: 'table-cell', width:'8%'  },
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'table-cell', width:'12%'  },
    ]; 

    if (this.sharedService.isCurrentBrandKnown() && this.sharedService.isCurrenOfferKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.currentOffer = this.sharedService.getCurrentOffer();
      this.getOfferFieldTypes(this.currentBrand.baseUrl);
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage, this.currentOffer);
      this.getOfferFields(this.currentBrand.brandCode, this.currentOffer.offerId, this.currentBrand.baseUrl);
      this.getBrandVariables(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getBrandImages(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getEmailSmsTemplates(this.currentBrand.brandCode, this.currentBrand.baseUrl);
      this.getLinkCategories(this.currentBrand.baseUrl);
    }
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_OFFER_FIELD_MODIFY')) {
      this.isSubmitShown = true;
    }
       
  }

  // Select all existing offer field types
  getOfferFieldTypes(brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getOfferFieldTypes(brandUrl)
    .subscribe({
      next: (response) => {
        this.offerFieldTypesList = response;
        this.offerFieldTypesList.forEach (item => {
          this.offerFieldTypes.push({label: item.literal,  value: item.fieldTypeCode})
        })
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }  

  // Select all existing offer fields
  getOfferFields(brandCode: string, offerId: number, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getOfferFields(brandCode, offerId, brandUrl)
    .subscribe({
      next: (response) => {
        this.styleOfferField(response);
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }  

  // Create a new offer field
  showDialogToAdd() {
    var offerField: IOfferField = {
      offerFieldId: 0, 
      offerId: this.currentOffer.offerId,
      fieldType: 'default',
      fieldKey: '',
      fieldValue: '',
      variableName: '',
      fieldOrder: 0
    };
    this.offerField = this.offerFieldFactoryService.getOfferFieldAttributesObject(offerField, this.offerFieldTypes); 
    this.offerField.setFormGroup();
    this.form =  this.offerField.getFormGroup();
    this.isNewOfferField = true;
    this.isListShown = false;
    this.isDetailsShown = true;
  }

  // Modify existing brand offer
  onRowEdit(event: any) {
    this.brandProfileService.getOfferField(this.currentBrand.brandCode, this.currentOffer.offerId,
           event.offerFieldId, this.currentBrand.baseUrl)
           .subscribe({
            next: (response) => {
              if (response) {
                // Populate an array of offer field attributes depending on the field type
                this.offerField = this.offerFieldFactoryService.getOfferFieldAttributesObject(response, 
                  this.offerFieldTypes, this.brandVariables, this.notifSchedDescrTypes, this.brandImages, this.emailSmsTemplates, this.linkCategories); 
                  console.log('onRowEdit - offerField', this.offerField);
                  this.offerField.setFormGroup();
                this.form =  this.offerField.getFormGroup();
                // Special logic to sync up offerLink and linkCategory
                if (this.form.value.hasOwnProperty('offerlink') && this.form.value.hasOwnProperty('linkCategoryCode')) {
                  if (!this.form.get('offerlink')?.value) {
                    this.form.controls['linkCategoryCode'].clearValidators()
                  } else {
                    this.form.controls['linkCategoryCode'].setValidators([Validators.required]);
                  }
                  this.form.controls['linkCategoryCode'].updateValueAndValidity();
                }
                // this.form.controls['linkCategoryCode'].updateValueAndValidity();
                this.isNewOfferField = false;
                this.isListShown = false;
                this.isDetailsShown = true;
              }
            },
            error: (error) => {
              this.alertTheUser(error.message);
            },
            complete: () => {
            }
          });
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the brand offer
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the field ' + event.fieldKey + ' ?',
      header: 'Confirmation',
      key: 'offer-fields',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteOfferField(this.currentBrand.brandCode, event.offerId, event.offerFieldId, 
              this.currentBrand.baseUrl)
              .subscribe({
                next: (response) => {
                  this.alertTheUser('Successfully deleted field ' + event.fieldKey + ' from brand ' + this.currentBrand.brandCode);
                  this.getOfferFields(this.currentBrand.brandCode, this.currentOffer.offerId, this.currentBrand.baseUrl);
                },
                error: (error) => {
                  this.alertTheUser(error.message);
                },
                complete: () => {
                }
              });
      },
      reject: () => {
      }
    });
  }

 onSubmit() {
    var isFieldKeyUnique = true;
    var offerFieldData = this.offerField.getFormData();
    if (!offerFieldData.offerFieldId) {
      this.offerFields.forEach(offerField => {
        if (offerField.fieldKey == offerFieldData.fieldKey) {
          isFieldKeyUnique = false;
        }
      })
    }
    // Make sure it is a proper offer field type
    if (offerFieldData.fieldType == '[none]' || offerFieldData.fieldType == 'default') {
      this.alertTheUser('Please select correct field type from the dropdown.');
    } 
    // Make sure the field key is unique for new offer fields
    else if (!isFieldKeyUnique) {
      this.alertTheUser('The field key "' + offerFieldData.fieldKey + '" is already in used by another field.  Please create a new and unique field key.');
    }
    else {
    // Call the service to invoke a Web API call
    this.brandProfileService.submitOfferField(this.currentBrand.brandCode, this.currentOffer.offerId, 
      offerFieldData, this.currentBrand.baseUrl)
      .subscribe({
        next: (response) => {
          if (offerFieldData.offerFieldId) {
            this.alertTheUser('Successfully modified field ' + offerFieldData.fieldKey + ' from brand ' + this.currentBrand.brandCode);
          } else {
            this.alertTheUser('Successfully added field ' + offerFieldData.fieldKey + ' to brand ' + this.currentBrand.brandCode);
          }
          this.getOfferFields(this.currentBrand.brandCode, this.currentOffer.offerId, this.currentBrand.baseUrl);
          this.isListShown = true;
          this.isDetailsShown = false;
        },
        error: (error) => {
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
    }
 }

  cancelDetails() {
    this.image = null;
    this.isImagePreviewShown = false;
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  // When the user select a new field type from the dropdown - re-draw the form
  changeFieldType(event: any) {
    var offerFieldData = this.offerField.getFormData();
    var offerFieldType = event; //event.target.value;
    this.offerField = this.offerFieldFactoryService.getOfferFieldAttributesObject(offerFieldData, 
      this.offerFieldTypes, this.brandVariables, this.notifSchedDescrTypes, this.brandImages, this.emailSmsTemplates, this.linkCategories); 
    this.offerField.setFormGroup();
    this.form = this.offerField.getFormGroup();
  }

  // When the user changes offerlink - change the required attribute and, if needed, null out linkCategoryCode
  changeOfferLink(event: any) {
    if (!event) {
      this.form.patchValue({linkCategoryCode: ''});
      this.form.controls['linkCategoryCode'].clearValidators()
    } else {
      this.form.controls['linkCategoryCode'].setValidators([Validators.required]);
    }
    this.form.controls['linkCategoryCode'].updateValueAndValidity();
  }

  // When the user selects a new color from a color-picker control - set the value on the form with what the control has
  captureColor(event: any) {
    // console.log('captureColor: ',  event);
    var someArr = event.split('|');
    var formField = someArr[0];
    var newColor = someArr[1];
    switch(formField) {
      case 'fieldValue':
        this.form.patchValue({fieldValue: newColor});
        break;
      case 'altFieldValue':
        this.form.patchValue({altFieldValue: newColor});
        break;
        case 'fieldValueBeta':
          this.form.patchValue({fieldValueBeta: newColor});
          break;
        case 'altFieldValueBeta':
          this.form.patchValue({altFieldValueBeta: newColor});
          break;
      }
  }

  // When the user selects an image id from the dropdown - display its image
  showImage(event: any) {
    var imageUid = event.value;
    if (this.brandImages) {
      this.brandImages.forEach(element => {
        if (element.imageUID == imageUid) {
          let imageType = element.imageType;
          this.image = 'data:' + imageType + ';base64,' + element.imageContent;
          this.isImagePreviewShown = true;
        }
      });
    }
  }

  // When the image dropdown loses focus - hide the image
  hideImage(event: any) {
    this.isImagePreviewShown = false;
  }

  // Redirect to back to brand offers page
  brandOfferRedirect(event: any) {
    this.router.navigate(['/brandOffers']);
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'offer-fields',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

  // makeTitleMessage (currentBrand, currentOffer) {
  //   var title = '';
  //   if (currentBrand.brandCode) {
  //     title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) &emsp; >> &emsp;';
  //   }
  //   if (currentOffer.offerName) {
  //     title += 'Field Set: ' + currentOffer.offerName + '&emsp;';
  //   }
  //   // Display the brand logo image, if possible    
  //   this.brandLogoImage = null;
  //   this.isbrandLogoImageShown = false;
  //   if (currentBrand.logoImageContent) {
  //     this.brandLogoImage = 'data:' + currentBrand.logoImageType + ';base64,' + currentBrand.logoImageContent;
  //     this.isbrandLogoImageShown = true;
  //   }
  //   return title;
  // }  

  // Select all existing brand variables
  getBrandVariables(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandVariables(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.brandVariables.length = 0;
          response.forEach(element => {
            this.brandVariables.push({label: element.variableName, value: element.variableName});
          });
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Select all existing Email/Sms Templates
  getEmailSmsTemplates(brandCode: string, brandUrl: string) {
    this.emailSmsTemplates.length = 0;
    // Call the service to invoke a Web API call
    this.brandProfileService.getEmailSmsTemplates(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.emailSmsTemplates = response;
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Select all existing brand images
  getBrandImages(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandImages(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        this.brandImages = response;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // Select all link  categories
  getLinkCategories(brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getLinkCategories(brandUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.linkCategories.length = 0;
          this.linkCategories.push({label: '[none]', value: ''});
          response.forEach(element => {
            this.linkCategories.push({label: element.linkCategoryName, value: element.linkCategoryCode});
          });
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  } 

  onRowReorder() {
  }

  saveFieldOrder() {
    var orderedList: any[] = [];
    this.offerFields.forEach(offerField => {
      orderedList.push(offerField.offerFieldId);
    })
    if (orderedList.length > 0) {
      this.confirmationService.confirm({
        message: "Do you really want to proceed with reordering the order fields as they are currently positioned on the web form?",
        header: 'Warning',
        key: 'offer-fields',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Yes",
        rejectVisible: true,
        acceptButtonStyleClass: "p-button-success  p-button-rounded",
        rejectButtonStyleClass: "p-button-danger p-button-rounded",   
        accept: () => {
          // Call the service to invoke a Web API call
          this.brandProfileService.setOfferFieldsOrder(this.currentBrand.brandCode, this.currentOffer.offerId, 
            orderedList, this.currentBrand.baseUrl)
            .subscribe({
              next: (response) => {
                this.styleOfferField(response);
                this.alertTheUser('Successfully processed fields reordering for brand ' + this.currentBrand.brandCode);
              },
              error: (error) => {
                this.alertTheUser(error.message);
              },
              complete: () => {
              }
            });
          }
        })
    }
  }

  public onChangeColorCmyk(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);
      // console.log('onChangeColorCmyk', rgba);
      return this.cpService.rgbaToCmyk(rgba);
    }
    return new Cmyk(0, 0, 0, 0);
  }

  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);
    if (hsva) {
      // console.log('onChangeColorHex8', hsva);
      return this.cpService.outputFormat(hsva, 'rgba', null);
    }
    return '';
  }

  public onEventLog(event: string, data: any): void {
    // console.log(event, data);
  }  

  private styleOfferField(response: any) {
    this.origFieldListOrder.length = 0;
    this.offerFields = response;
    this.offerFields.forEach(offerField => {
      var offerFieldType = this.offerFieldTypes.find(element => element.value == offerField.fieldType);
      this.origFieldListOrder.push(offerField.offerFieldId);
      // Determine all kinds of fieldKey's for the offerFieldType = 'notifSchedDescr', so it could be used for a dropdown
      if (offerField.fieldType == 'notifSchedDescr') {
        if (!(this.notifSchedDescrTypes.some(elem => elem.value === null))) {
          this.notifSchedDescrTypes.push({label: '',  value: null});
        }
        if (!(this.notifSchedDescrTypes.some(elem => elem.value === offerField.fieldKey))) {
          this.notifSchedDescrTypes.push({label: offerField.fieldKey,  value: offerField.fieldKey});
        }
      }
      // Replace offerField's value with the corresponding label, so it gets properly displayed on the grid
      offerField.fieldType = offerFieldType.label;                
      // Format the dates
      if (offerField.createDate) {
        offerField.createDate = this.sharedService.formatFromISO(offerField.createDate);
      }
      if (offerField.lastUpdateDate) {
        offerField.lastUpdateDate = this.sharedService.formatFromISO(offerField.lastUpdateDate);
      }
      // Shorten up the fieldvalue
      if (offerField.fieldValue.length > 40) {
        offerField.fieldValue = offerField.fieldValue.substr(0, 40) + ' ...'
      }
    });
  }

  // canDeactivate(): Observable<boolean> | boolean {
  //   // Get the current order of offer fields
  //   var currOrderedList = [];
  //   this.offerFields.forEach(offerField => {
  //     currOrderedList.push(offerField.offerFieldId);
  //   })
  //   // Compare the current order against original order
  //   var isFieldListUnchanged = 
  //     this.origFieldListOrder.length === currOrderedList.length && this.origFieldListOrder.every((val, index) => val === currOrderedList[index]);
  //   if (!isFieldListUnchanged) {
  //     return window.confirm('There are pending changes in the field list, such as their order.  Do you still want to proceed navigating to another page and discard the changes?');
  //   }
  //   return true;
  // }   


  onFunctionsCheatSheet() {
    window.open( 
      "https://s3.amazonaws.com/WalletronShare/DEV-FunctionsDocumentation-190919-2334.pdf", "_blank");
  }  
}







