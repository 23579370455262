import { Component, OnInit, ViewChild } from '@angular/core';
import { TestSuiteRegister } from '../../models/test.suite.register';
import { TestSuiteService } from '../../services/monitor/test-suite.service';
import { Table } from 'primeng/table';
import { SharedService } from "../../services/shared.service";

@Component({
  selector: 'app-test-suite',
  templateUrl: './test-suite.component.html',
  styleUrls: ['./test-suite.component.css']
})
export class TestSuiteComponent implements OnInit {
  @ViewChild(Table) dt!: Table;

    testSuites: TestSuiteRegister[] = [];
    selectedTestSuite!: TestSuiteRegister;
    // testSuite: TestSuiteRegister = new TestSuiteRegister();
    testSuite!: TestSuiteRegister;

    displayDialog!: boolean;
    testSuiteCols: any[] = [];

    paramCols: any[] = [];
    params: any[] = [];
    testResultsCols: any[] = [];
    testResults: any[] = [];

    // Selection criteria
    suiteNames: any[] = [];
    statuses: any[] = [];
    testNames: any[] = [];
    selectedSuiteName!: string;
    selectedTestName!: string;
    selectedStatus!: string;
    selectedDateFrom!: string;
    selectedDateTo!: string;
    testSuiteOffset!: number;

    isSpinnerHidden: boolean = true;

  constructor(
      private testSuiteService: TestSuiteService, 
      private sharedService: SharedService
  ) { }

  ngOnInit(): void {
      // Define the columns for Job Executions grid
      this.testSuiteCols = [
        { field: 'sessionGuid', header: 'Session Uuid', width:'8%', display: 'none' },
        { field: 'suiteName', header: 'Suite Name', width:'17%', display: 'table-cell' },
        { field: 'testName', header: 'Test Name', width:'17%', display: 'table-cell' },
        { field: 'testDesc', header: 'Test Desc', width:'18%', display: 'table-cell' },
        { field: 'status', header: 'Status', width:'8%', display: 'table-cell' },
        { field: 'envRegion', header: 'Env. Region', width:'8%', display: 'table-cell' },
        { field: 'envHost', header: 'Host', width:'8%', display: 'table-cell' },
        { field: 'startTimestamp', header: 'Start Timestamp', width:'12%', display: 'table-cell' },
        { field: 'endTimestamp', header: 'End Timestamp', width:'12%', display: 'table-cell' }
      ]; 
      // Define the columns for Parameters and Test Results grids
      this.paramCols = [
        { field: 0, header: 'Key', width:'25%', display: 'table-cell' },
        { field: 1, header: 'Value', width:'75%', display: 'table-cell' }
      ]; 
      this.testResultsCols = [
        { field: 'tag', header: 'Tag', width:'20%', display: 'table-cell' },
        { field: 'type', header: 'Type', width:'10%', display: 'table-cell' },
        { field: 'outcome', header: 'Outcome', width:'10%', display: 'table-cell' },
        { field: 'message', header: 'Comments', width:'60%', display: 'table-cell' }
      ]; 
      // Prepopulate the dates
      this.selectedDateFrom = this.sharedService.getCurrentDate();
      this.selectedDateTo = this.sharedService.getCurrentDate();
      // Populate the dropdowns
      this.getSuiteNames();
      this.getTestNames();
  }

    // Retrieve distinct test suite names
    getSuiteNames(): void {
      this.testSuiteService.getSuiteNames()
      .subscribe(testSuites=> {
        for(let i = 0; i < testSuites.length; i++){
          this.suiteNames.push({label:testSuites[i], value: testSuites[i]});
       }
      });
   }  

    // Retrieve distinct test names
    getTestNames(): void {
      this.testSuiteService.getTestNames()
      .subscribe(testSuites=> {
        for(let i = 0; i < testSuites.length; i++){
          this.testNames.push({label:testSuites[i], value: testSuites[i]});
       }
      });
   }  

   // Select test suite log based on the user defined selection criteria
   getTestSuiteLog(selectedSuiteName: string, selectedTestName: string, selectedStatus: string, 
    selectedDateFrom: string, selectedDateTo: string, offset: number): void {
     this.isSpinnerHidden = false;
    this.dt.reset();
    this.testSuiteService.getTestSuiteLog(selectedSuiteName, selectedTestName, selectedStatus, selectedDateFrom, selectedDateTo, offset)
        .subscribe(testSuiteResponse => {
          this.testSuiteOffset = testSuiteResponse.paging.nextPageToken;
          this.testSuites = this.formatDate(testSuiteResponse.testSuiteLogRegisterList);
          this.isSpinnerHidden = true;
      });
  }

  // Process test suite log when clicking on the Search button
  onTestSuiteSearchClick(event: any) {
    var offset = 0;
    this.getTestSuiteLog(this.selectedSuiteName, this.selectedTestName, this.selectedStatus,
      this.selectedDateFrom, this.selectedDateTo, offset);
  }

  // Process test suite log when clicking on the Next button
  onTestSuiteNextClick(event: any) {
    var offset = this.testSuiteOffset;
    this.getTestSuiteLog(this.selectedSuiteName, this.selectedTestName, this.selectedStatus,
      this.selectedDateFrom, this.selectedDateTo, offset);
  }

  // Process test suite log when clicking on the Clear button
  onTestSuiteClearClick(event: any) {
    this.selectedSuiteName = '';
    this.selectedTestName = '';
    this.selectedStatus = '';
    this.selectedDateFrom = '';
    this.selectedDateTo = '';
  }

  // Process events when clicking on a row within grid
  onTestSuiteRowSelect(testSuite: any) {
    this.testSuite = this.cloneTestSuite(testSuite.data);
    this.params = Object.entries(testSuite.data.testParameters);
    this.testResults = testSuite.data.testResults;
    this.displayDialog = true;
  }

  cloneTestSuite(c: TestSuiteRegister): TestSuiteRegister {
    // let testSuite = new TestSuiteRegister();
    // for (let prop in c) {
    //   testSuite[prop] = c[prop];
    // }
    let testSuite: TestSuiteRegister = {
      endTimestamp: c.endTimestamp,
      envHost: c.envHost,
      envRegion: c.envRegion,
      seqNo: c.seqNo,
      sessionGuid: c.sessionGuid,
      startTimestamp: c.startTimestamp,
      status: c.status,
      suiteName: c.suiteName,
      testName: c.testName,
      testDesc: c.testDesc,
      testParameters: c.testParameters,
      testResults: c.testResults
    }
    return testSuite;
  }

  testSuiteCancel() {
    // this.testSuite = new TestSuiteRegister();
    this.displayDialog = false;
  }

  onSuiteNameChange (event: any) {
    this.testNames.length = 0;
    var suiteName = event.value;
    this.testSuiteService.getTestNamesForSuite(suiteName)
    .subscribe(testSuites=> {
      for(let i = 0; i < testSuites.length; i++){
        this.testNames.push({label:testSuites[i], value: testSuites[i]});
     }
    });
  }

  formatDate(array: any) {
    array.forEach(function (arrayItem: any) {
      if(arrayItem.startTimestamp) {
        arrayItem.startTimestamp = arrayItem.startTimestamp.replace('T', ' ').replace('Z[UTC]','');
      }
      if(arrayItem.endTimestamp) {
        arrayItem.endTimestamp = arrayItem.endTimestamp.replace('T', ' ').replace('Z[UTC]','');
      }
    });
    return array;
  }  
}
