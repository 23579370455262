import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldTextColor extends OfferFieldBase {

    brandVariables: any[];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        brandVariables: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.brandVariables = brandVariables;
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Key, Field Value, Alt Field Value, API Setting, Snapshot Variable, Hide from Distrib URL?

        if (this.data.fieldValue && this.data.fieldValue.search('#{') >= 0) {

            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'textarea',
                key: 'fieldValue',
                label: 'Field Value (Color Expression)',
                value: this.data.fieldValue,
                // styleWidth: '40em',
                required: false,
                rows: 1,
                cols: 80,
                order: 4
            }));
    
            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'colorpicker',
                key: 'fieldValueBeta',
                label: '(or Color Picker)',
                value: '',
                styleWidth: '15em',
                colorInit: '',
                required: false,
                order: 5
            }));
    
        } else {

            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'textarea',
                key: 'fieldValue',
                label: 'Field Value (Color Expression)',
                value: '',
                // styleWidth: '40em',
                required: false,
                rows: 1,
                cols: 80,
                order: 4
            }));
            
            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'colorpicker',
                key: 'fieldValueBeta',
                label: '(or Color Picker)',
                value: this.data.fieldValue,
                styleWidth: '15em',
                colorInit: this.data.fieldValue ? this.data.fieldValue : '',
                required: false,
                order: 5
            }));

        }

        if (this.data.altFieldValue && this.data.altFieldValue.search('#{') >= 0) {

            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'textarea',
                key: 'altFieldValue',
                label: 'Alt Field Value (Color Expression)',
                value: this.data.altFieldValue,
                // styleWidth: '40em',
                required: false,
                rows: 1,
                cols: 80,
                order: 6
            }));
    
            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'colorpicker',
                key: 'altFieldValueBeta',
                label: '(or Color Picker)',
                value: '',
                styleWidth: '15em',
                colorInit: '',
                required: false,
                order: 7
            }));
    
        } else {

            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'textarea',
                key: 'altFieldValue',
                label: 'Alt Field Value (Color Expression)',
                value: '',
                // styleWidth: '40em',
                required: false,
                rows: 1,
                cols: 80,
                order: 6
            }));
            
            this.offerFieldAttributes.push( new OfferFieldAttribute({
                controlType: 'colorpicker',
                key: 'altFieldValueBeta',
                label: '(or Color Picker)',
                value: this.data.altFieldValue,
                styleWidth: '15em',
                colorInit: this.data.altFieldValue ? this.data.altFieldValue  : '',
                required: false,
                order: 7
            }));

        }

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'varTransformationExpression',
            label: 'Var Transformation Expression',
            value: this.data.varTransformationExpression,
            required: false,
            order: 8,
            rows: 2,
            cols: 80
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'variableName',
            label: 'Snapshot Variable',
            styleWidth: '10em',
            value: this.data.variableName,
            required: false,
            tooltip: 'Select the proper snapshot variable on the pass',
            options: this.brandVariables,
            order: 9
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'checkbox',
            key: 'hiddenFromDistribUrl',
            label: 'Hide from Distrib URL?',
            checked: this.data.hiddenFromDistribUrl,
            required: false,
            order: 10
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'mergeField',
            label: 'Merge Field',
            styleWidth: '10em',
            value: this.data.mergeField,
            required: false,
            order: 11
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 12
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);

    }

}
