<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-8">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded"  [disabled]="isDetailsShown">
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 


<!--   -->


<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-8 side-list-content-data" *ngIf="isListShown">
        <p-card header="List of Scheduled Events" subheader="" styleClass="ui-card-light" >

            <p-table #dt [columns]="eventCols" [value]="scheduledEvents" selectionMode="single" 
                sortMode="multiple" [paginator]="false" [rows]="15" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pEditableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                        <td style="text-align:center">
                            <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Modify Scheduled Event" 
                                class="ui-button-info" (click)="onRowEdit(rowData)">
                            </button>
                            <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Delete Scheduled Event" 
                                class="ui-button-danger" (click)="onRowDelete(rowData)" *ngIf="isSubmitShown">
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left" *ngIf="isSubmitShown">
<!-- 
                        <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button>
 -->
                        <button pButton type="button" label="Add" icon="pi fa-plus" iconPos="left" (click)="showDialogToAdd()"  
                            class="p-button-info p-button-rounded" >
                        </button>

                    </div>
                </ng-template>    
          </p-table> 
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">list_all</mat-icon>
            </p-footer>
        </p-card>
    </div>

    <!--- Second card with the details -->

    <div @flyInOut class="col-10 side-list-content-data" *ngIf="isDetailsShown">
        <p-card header="Scheduled Event Details" subheader="" styleClass="ui-card-light" >

            <div class="grid">

                <div class="col-7">

                    <form [formGroup]="eventForm" (ngSubmit)="onSubmit(eventForm.value)">

                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="eventName" class="col-fixed label" style="width: 15em">Event Name:</label>
                            <div class="col-7">
                                <input pInputText id="eventName" type="text" formControlName="eventName"  [style]="{'width': '75%'}" 
                                    [size]="255" placeholder="Required" pTooltip="Unique scheduled event name" tooltipPosition="top"/> 
                                <p-message severity="error" text="Scheduled event name is required" *ngIf="!eventForm.controls['eventName'].valid&&eventForm.controls['eventName'].dirty"></p-message>                 
                            </div>
                        </div>
                            
                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="eventDescription" class="col-fixed label" style="width: 15em">Event Description:</label>
                            <div class="col-7">
                                <input pInputText id="eventDescription" type="text" formControlName="eventDescription"  [style]="{'width': '100%'}" 
                                    [size]="1000" placeholder="Required" pTooltip="Scheduled event description" tooltipPosition="top"/> 
                                <p-message severity="error" text="Scheduled event description is required" *ngIf="!eventForm.controls['eventDescription'].valid&&eventForm.controls['eventDescription'].dirty"></p-message>                 
                            </div>
                        </div>

                        
                        <div class="grid">
                
                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-2"></div>
                                        <label for="enabled" class="col-fixed label" style="width: 15em">Enabled:</label>
                                    <div class="col-3">
                                        <p-checkbox formControlName="enabled" id="enabled" [binary]="true"></p-checkbox>
                                        <p-message severity="error" text="Enabled is required" *ngIf="!eventForm.controls['enabled'].valid&&eventForm.controls['enabled'].dirty"></p-message>                 
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-1"></div>
                                        <label for="sendToAll" class="col-fixed label" style="width: 15em">Send to All:</label>
                                    <div class="col-3">
                                        <p-checkbox formControlName="sendToAll" id="sendToAll" [binary]="true"></p-checkbox>
                                        <p-message severity="error" text="Send to All is required" *ngIf="!eventForm.controls['sendToAll'].valid&&eventForm.controls['sendToAll'].dirty"></p-message>                 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid">

                            <div class="col-6">

                                <div class="field grid">
                                    <div class="col-2"></div>
                                        <label for="msgDestinationType" class="col-fixed label" style="width: 15em">Destination Type:</label>
                                    <div class="col-3">
                                        <p-dropdown id ="msgDestinationType" [options]="msgDestinationTypes" formControlName="msgDestinationType"
                                            (onChange)="onChangMsgDestinationType($event)"
                                            [filter]="false" placeholder="Select the destination type" [editable]="false" [style]="{'width':'100%'}">
                                        </p-dropdown>
                                        <p-message severity="error" text="Destination Type is required" *ngIf="!eventForm.controls['msgDestinationType'].valid&&eventForm.controls['msgDestinationType'].dirty"></p-message>                 
                                    </div>
                                </div>
        
                            </div>

                            <div class="col-6">

                                <div class="field grid">
                                    <div class="col-1"></div>
                                        <label for="msgDestinationName" class="col-fixed label" style="width: 15em">Destination Name:</label>
                                    <div class="col-4">
                                        <p-dropdown id ="msgDestinationName" [options]="msgDestinationNames" formControlName="msgDestinationName"
                                            [filter]="false" placeholder="Select the destination name" [editable]="false" [style]="{'width':'100%'}">
                                        </p-dropdown>
                                        <p-message severity="error" text="Destination Name is required" *ngIf="!eventForm.controls['msgDestinationName'].valid&&eventForm.controls['msgDestinationName'].dirty"></p-message>                 
                                    </div>
                                </div>
                                        
                            </div>

                        </div>

                        <div class="grid">
                
                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-2"></div>
                                        <label for="hourExpr" class="col-fixed label" style="width: 15em">Hour Expression:</label>
                                    <div class="col-3">
                                        <input pInputText id="hourExpr" type="text" formControlName="hourExpr"  
                                            pTooltip="A cron style hour expression, such as '8,12,16', or '*/4', or '10', or '*'" tooltipPosition="top"/> 
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-1"></div>
                                        <label for="minuteExpr" class="col-fixed label" style="width: 15em">Minute Expression:</label>
                                    <div class="col-3">
                                        <input pInputText id="minuteExpr" type="text" formControlName="minuteExpr"  
                                            pTooltip="A cron style minute expression, such as '5,15,25,35,45,55', or '*/5', or '15'" tooltipPosition="top"/> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field grid">
                            <div class="col-1"></div>
                                <label for="secondExpr" class="col-fixed label" style="width: 15em">Second Expression:</label>
                            <div class="col-3">
                                <input pInputText id="secondExpr" type="text" formControlName="secondExpr"  
                                    pTooltip="A cron style second expression, such as '0,30', or '30'" tooltipPosition="top"/> 
                            </div>
                        </div>

                        <div class="grid">
                
                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-2"></div>
                                        <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                                    <div class="col-3">
                                        <input pInputText type="createDate" id="createDate" type="text" formControlName="createDate" readonly="true">
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-1"></div>
                                        <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                                    <div class="col-3">
                                        <input pInputText type="lastUpdateDate" id="lastUpdateDate" type="text" formControlName="lastUpdateDate" readonly="true">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid">
                
                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-2"></div>
                                        <label for="lastFiredDate" class="col-fixed label" style="width: 15em">Last Fired Date:</label>
                                    <div class="col-3">
                                        <input pInputText type="lastFiredDate" id="lastFiredDate" type="text" formControlName="lastFiredDate" readonly="true">
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="field grid">
                                    <div class="col-1"></div>
                                        <label for="nextTimeoutDate" class="col-fixed label" style="width: 15em">Next Timeout Date:</label>
                                    <div class="col-3">
                                        <input pInputText type="nextTimeoutDate" id="nextTimeoutDate" type="text" formControlName="nextTimeoutDate" readonly="true">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid">
        
                            <div class="col-12 float-center">
                                <div  class="col-3" id="divshow">

                                    <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!eventForm.valid" *ngIf="isSubmitShown" ></p-button>
                                    <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>
    
                                </div> 
                                <div class="col-6" *ngIf="!isSubmitShown">
                                    <mat-label>{{resultMsg}}</mat-label>
                                </div>
                                <div  class="col-3" *ngIf="isSubmitShown"  id="divshow">
<!-- 
                                    <button pButton type="button" icon="fa fa-plus" (click)="triggerEvent()" label="Execute Now" class="ui-button-info"></button>
 -->
                                    <p-button type="button" label="Execute Now" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="triggerEvent()"></p-button>
                                    
                                </div> 
                            </div>
                
                        </div>
                    </form>
        
                </div>

                <!-- <mat-divider [vertical]="true"></mat-divider> -->
        
                <!-- Attributes -->

                <div class="col-5">
                    
                    <p-table #dtt [columns]="attributeCols" [value]="attributes" selectionMode="single" 
                            tableStyleClass="prime-table" [paginator]="false" [rows]="15" *ngIf="!isAttributeDetailsShown">
                        <ng-template pTemplate="caption">
                            Scheduled Event Attributes
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}" >
                                    {{col.header}}
                                </th>
                                <th [ngStyle]="{'width': 'table-cell', 'display': '15%'}">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pEditableRow]="rowData">
                                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{rowData[col.field]}}
                                </td>
                                <td style="text-align:center">
                                    <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Modify Attribute" 
                                        class="ui-button-info" (click)="onAttributeEdit(rowData)">
                                    </button>
                                    <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Delete Attribute" 
                                        class="ui-button-danger" (click)="onAttributeDelete(rowData)" *ngIf="isSubmitShown">
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary" let-rowData>
                            <div style="text-align:left" *ngIf="isSubmitShown">
<!-- 
                                <button type="button" pButton icon="fa fa-plus" (click)="onAddAttribute()" label="Add"></button>
 -->
                                <button pButton type="button" label="Add" icon="pi fa-plus" iconPos="left" (click)="onAddAttribute()"  
                                    class="p-button-info p-button-rounded" >
                                </button>
                                    
                            </div>
                        </ng-template>    
                    </p-table> 

                    <div class="ui-g ui-card-shadow" *ngIf="isAttributeDetailsShown">

                        <h3 style="text-align:center">Event Attribute Details</h3>

                        <form [formGroup]="attributeForm" (ngSubmit)="onAdvanceAttribute(attributeForm.value)">

                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="attributeName" class="col-fixed label" style="width: 15em">Attribute Name:</label>
                                <div class="col-7">
                                    <input pInputText id="attributeName" type="text" formControlName="attributeName"  [style]="{'width': '100%'}" 
                                        [size]="255" placeholder="Required" pTooltip="Unique attribute name" tooltipPosition="top"/> 
                                </div>
                            </div>                            

                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="attributeValue" class="col-fixed label" style="width: 15em">Attribute Value:</label>
                                <div class="col-7">
                                    <input pInputText id="attributeValue" type="text" formControlName="attributeValue"  [style]="{'width': '100%'}" 
                                        [size]="255" placeholder="Required" pTooltip="Attribute value" tooltipPosition="top"/> 
                                </div>
                            </div>                            

                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="attributeEnabled" class="col-fixed label" style="width: 15em">Enabled:</label>
                                <div class="col-7">
                                    <p-checkbox formControlName="attributeEnabled" id="attributeEnabled" [binary]="true"></p-checkbox>
                                </div>
                            </div>                            
    
    
                            <div class="col-12">
                                <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                                    <button pButton type="submit" icon="fa fa-check" label="Advance" class="ui-button-primary" [disabled]="!attributeForm.valid"  *ngIf="isSubmitShown"></button>
                                    <button pButton type="button" icon="fa fa-close" (click)="onCancelAttributeDetails()" label="Cancel"></button>
 -->
                                    <p-button type="submit" label="Advance" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!attributeForm.valid" *ngIf="isSubmitShown" ></p-button>
                                    <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelAttributeDetails()"></p-button>
                                    
                                </div> 
                            </div>
    
                        </form>

                    </div>

                </div>

            </div>


            <p-footer>
                <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
            </p-footer>
        </p-card>
    
    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="scheduled-events"></p-confirmDialog>
