<!-- The following is a popup dialog to select a pass template -->

<p-dialog appendTo="body" header="Pass Template Selection" [(visible)]="displayPassTemplateDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '40%'}" position="left,top" [showHeader]="true">

    <div class="ui-g">
        <div class="col-12">

            <p-table #dt [columns]="passTemplateCols" [value]="passTemplates" selectionMode="single" 
                [(selection)]="selectedPassTemplate"  
                sortMode="multiple" (onRowSelect)="onRowPassTemplateSelect($event)" [paginator]="true" [rows]="15" >
              <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                      {{col.header}}
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field"  [ngStyle]="{'width': col.width}">
                        <input *ngSwitchCase="'passName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                    </th>
                </tr>  
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                      <td *ngFor="let col of columns">
                          {{rowData[col.field]}}
                      </td>
                  </tr>
              </ng-template>
            </p-table> 
      
        </div>
    </div>


    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton icon="fa fa-check" (click)="onPassTemplateSelect()" label="Select"></button>
            <button type="button" pButton icon="fa fa-check" (click)="onCancelPassTemplateSelect()" label="Cancel"></button>
        </div>
    </p-footer>


</p-dialog>


