import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { Subscription, timer } from 'rxjs';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SocketAdapterService } from "../../services/monitor/socket-adapter.service";
import { JobMonitorService } from "../../services/monitor/job-monitor.service";
import { EnvRegionService } from "../../services/config/env-region.service";
import { EventOccurrenceService } from "../../services/monitor/event-occurrence.service";
import { BrandStatsService } from "../../services/stats/brand-stats.service";
import { SharedService } from "../../services/shared.service";
import { ConfirmationService } from "primeng/api";
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit{
  
  @ViewChild(Table) dt!: Table;

  hasJobMonitoringPrivilege: boolean = false;
  hasCsrPrivilege: boolean = false;
  hasUserAdministrationPrivilege: boolean = false;
  hasRtpnViewerPrivilege: boolean = false;
  hasAccountInfoPrivilege: boolean = false;
  hasEnrollmentStatsPrivilege: boolean = false;
  hasBrandListingPrivilege: boolean = false;
  hasBrandBootstrappingPrivilege: boolean = false;
  hasBrandFieldSetsPrivilege: boolean = false;
  hasMyAccountPrivilege: boolean = false;

  constructor(
    private router: Router,
    private userContextService: UserContextService,
    private socketAdapterService: SocketAdapterService, 
    private jobMonitorService: JobMonitorService, 
    private envRegionService: EnvRegionService, 
    private eventOccurrenceService: EventOccurrenceService,
    private brandStatsService: BrandStatsService, 
    private confirmationService: ConfirmationService,
    private sharedService: SharedService) {
  }

  ngOnInit() {
  }

  // Find out whether the current user has ALL the privileges from the list
  public hasAllPrivileges (priveleges: string[]): boolean {
      return this.userContextService.userHasAllPrivileges(priveleges);
  }

  // Find out whether the current user has at least one privilege from the list
  public hasAnyPrivilege (priveleges: string[]): boolean {
    return this.userContextService.userHasAllPrivileges(priveleges);
  }

  // Find out whether the current user has a required privilege
  public hasPrivilege (privelege: string): boolean {
    return this.userContextService.userHasPrivilege(privelege);
  }

  onJobMonitoringClick() {
    this.router.navigate(['/monitoring']);
  }

  onCsrClick() {
    this.router.navigate(['/csrAccount']);
  }

  onUserAdministrationClick() {
    this.router.navigate(['/portalUsers']);
  }

  onRtpnViewerClick() {
    this.router.navigate(['/rtpnTransactions']);
  }

  onAccountInfoClick() {
    this.router.navigate(['/accountInfo']);
  }

  onEnrollmentReportClick() {
    this.router.navigate(['/enrollmentReport']);
  }

  onBrandListingClick() {
    this.router.navigate(['/brandListing']);
  }

  onBrandBootstrappingClick() {
    this.router.navigate(['/brandBootstrap']);
  }

  onBrandFieldSetsClick() {
    this.router.navigate(['/brandOffers']);
  }

  onMyAccountClick() {
    this.router.navigate(['/userProfile']);
  }

  onClientDashboardClick() {
    this.router.navigate(['/clientDashboard']);
  }

}



