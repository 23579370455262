<h2>Config Property</h2>

<div class="col-7">
    <div class="grid">
        <div class="col-2">
            <p-dropdown id ="regionName" [options]="envRegions" [(ngModel)]="envRegionBaseUrl" [filter]="true" 
                placeholder="Enviromental Region:" [editable]="false"></p-dropdown>
        </div>  
        <div class="col-2">
            <input pInputText id="propertyName" [(ngModel)]="selectedPropertyName" placeholder="Property Name:"/> 
        </div>  
        <div class="col-2">
            <p-dropdown id ="propertyScope" [options]="propertyScopes" [(ngModel)]="selectedPropertyScope" [filter]="true" 
                placeholder="Property Scope:" [editable]="false"></p-dropdown>
        </div>  
        <div class="col-2">
            <p-dropdown id ="propertyType" [options]="propertyTypes" [(ngModel)]="selectedPropertyType" [filter]="false" 
                placeholder="Property Type:" [editable]="false"></p-dropdown>
        </div>  
        <div class="col-1">
            <p-button type="submit" label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
            <!-- <button pButton type="button" label="Search" class="ui-button-success" (click)="onSearchClick($event)"></button> -->
        </div>  
        <div class="col-1">
            <p-button type="button" label="Clear" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)"></p-button>
            <!-- <button pButton type="button" label="Clear" class="ui-button-danger" (click)="onClearClick($event)"></button> -->
        </div>  
    </div>
</div> 

<div class="grid">
    <div class="col-8">

        <p-table #dt [columns]="cols" [value]="configProperties" selectionMode="single" 
            [(selection)]="selectedConfigProperty" 
            sortMode="multiple" (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="15" >
          <ng-template pTemplate="caption">
              List of Config Properties
          </ng-template>
          <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    <input *ngSwitchCase="'propertyName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                    <input *ngSwitchCase="'propertyType'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"  [style]="{'width': '75%'}">
                </th>
            </tr>  
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary" let-rowData>
              <div style="text-align:left" *ngIf="isSubmitShown">
                <button pButton type="button" label="Add" icon="pi fa-plus" iconPos="left" (click)="showDialogToAdd()"  
                    class="p-button-info p-button-rounded" >
                </button>  
                  <!-- <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button> -->
              </div>
          </ng-template>    
          </p-table> 
      
    </div>
</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>

<p-dialog appendTo="body" header="Config Property Details" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '50%'}">
    <div *ngIf="configProperty">

        <p-card header="Master Config Property Details" subheader="" styleClass="ui-card-light" >

            <div class="grid">

                <div class="col-6">
                    
                    <div class="field grid">
                        <div class="col-2"></div>
                        <label for="configPropertyId" class="col-fixed label" style="width: 15em">Config Property Id:</label>
                        <div class="col-2">
                            <input pInputText id="configPropertyId" [(ngModel)]="configProperty.configPropertyId" autofocus readonly  style="width: 10em"/>
                        </div>
                    </div>

                </div>

                <div class="col-6">

                    <div class="field grid">
                        <div class="col-1"></div>
                        <label for="Enabled" class="col-fixed label" style="width: 15em">Enabled:</label>
                        <div class="col-2">
                            <p-checkbox [(ngModel)]="configProperty.enabled" id="enabled" [binary]="true"></p-checkbox>
                        </div>
                    </div>
        
                </div>

            </div>

            <div class="field grid">
                <div class="col-1"></div>
                    <label for="propertyName" class="col-fixed label" style="width: 15em">Property Name:</label>
                <div class="col-8"> 
                    <textarea [rows]="1" [cols]="30" pInputTextarea [autoResize]="autoResize" id="propertyName" 
                        [(ngModel)]="configProperty.propertyName" style="width: 100%"></textarea>
                </div>
            </div>

            <div class="field grid">
                <div class="col-1"></div>
                    <label for="propertyDescription" class="col-fixed label" style="width: 15em">Property Description:</label>
                <div class="col-8">
                    <textarea [rows]="1" [cols]="30" pInputTextarea [autoResize]="autoResize" id="propertyDescription" 
                        [(ngModel)]="configProperty.propertyDescription" style="width: 100%"></textarea>
                </div>
            </div>

            <div class="grid">

                <div class="col-6">
                    
                    <div class="field grid">
                        <div class="col-2"></div>
                        <label for="propertyScope" class="col-fixed label" style="width: 15em">Property Scope:</label>
                        <div class="col-4">
                            <p-dropdown id ="propertyScope" [options]="propertyScopes" [(ngModel)]="configProperty.propertyScope" [filter]="false" 
                                placeholder="Select scope" [editable]="false"></p-dropdown>
                        </div>
                    </div>
                </div>

                <div class="col-6">

                    <div class="field grid">
                        <div class="col-1"></div>
                        <label for="propertyType" class="col-fixed label" style="width: 15em">Property Type:</label>
                        <div class="col-4">
                            <p-dropdown id ="propertyType" [options]="propertyTypes" [(ngModel)]="configProperty.propertyType" [filter]="false" 
                                placeholder="Select type" [editable]="false"></p-dropdown>
                        </div>
                    </div>
                </div>

            </div>
        

            <div class="field grid">
                <div class="col-1"></div>
                    <label for="defaultValue" class="col-fixed label" style="width: 15em">Default Value:</label>
                <div class="col-8">
                    <textarea [rows]="1" [cols]="30" pInputTextarea [autoResize]="autoResize" id="propertyValue" 
                        [(ngModel)]="configProperty.defaultValue" style="width: 100%"></textarea>
                </div>
            </div>

            <div class="grid">

                <div class="col-6">

                    <div class="field grid">
                        <div class="col-2"></div>
                        <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                        <div class="col-4">
                            <input pInputText id="createDate" [(ngModel)]="configProperty.createDate" readonly style="width: 12em"/>
                        </div>
                    </div>
                            
                </div>

                <div class="col-6">

                    <div class="field grid">
                        <div class="col-1"></div>
                        <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                        <div class="col-4">
                            <input pInputText id="lastUpdateDate" [(ngModel)]="configProperty.lastUpdateDate" readonly style="width: 12em"/>
                        </div>
                    </div>
        
                </div>

            </div>


    </p-card>

    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
<!-- 
            <button type="button" pButton icon="fa fa-close" (click)="delete()" label="Delete" *ngIf="isSubmitShown"></button>
            <button type="button" pButton icon="fa fa-check" (click)="save()" label="Save" *ngIf="isSubmitShown"></button>
            <button type="button" pButton icon="fa fa-check" (click)="cancel()" label="Cancel"></button>
 -->
            <p-button type="button" label="Delete" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="delete()" *ngIf="isSubmitShown" ></p-button>
            <p-button type="button" label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="save()" *ngIf="isSubmitShown" ></p-button>
            <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancel()"></p-button>

        </div>
    </p-footer>

</p-dialog>
