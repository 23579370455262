import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ConfirmationService } from "primeng/api";
import { IConfigProperty } from '../../interfaces/config-property';
import { ConfigPropertyService } from '../../services/config/config-property.service';
import { EnvRegionService } from '../../services/config/env-region.service';
import { UserContextService } from '../../services/safeguard/user-context.service';

@Component({
  selector: 'app-config-property',
  templateUrl: './config-property.component.html',
  styleUrls: ['./config-property.component.css']
})
export class ConfigPropertyComponent implements OnInit {

  configProperties: IConfigProperty[] = [];
  cols!: any[];
  selectedConfigProperty!: IConfigProperty;
  displayDialog!: boolean;
  newConfigProperty!: boolean; 
  configProperty!: IConfigProperty; 
  propertyScopes: any[] = [];
  propertyTypes: any[] = [];
  offset!: number;
  selectedPropertyName!: string;
  selectedPropertyScope!: string;
  selectedPropertyType!: string;
  dateFormat!: string;
  envRegions: any[] = [];
  envRegionBaseUrl!: string;

  isSubmitShown: boolean = false;  

  autoResize: boolean = true;

  constructor(
    private envRegionService: EnvRegionService, 
    private configPropertyService: ConfigPropertyService, 
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'configPropertyId', header: 'Config Property Id', display: 'table-cell', width:'10%'  },
      { field: 'propertyName', header: 'Property Name', display: 'table-cell', width:'30%' },
      { field: 'propertyScope', header: 'Property Scope', display: 'table-cell', width:'10%'},
      { field: 'propertyType', header: 'Property Type', display: 'table-cell' , width:'15%'},
      { field: 'propertyDescription', header: 'Property Description', display: 'table-cell' , width:'35%'},
      { field: 'enabled', header: 'Enabled', display: 'none'},
      { field: 'defaultValue', header: 'Default Value', display: 'none'},
      { field: 'createDate', header: 'Create Date', display: 'none'},
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'none'}
    ]; 
    this.propertyScopes = [{label: 'brand', value: 'brand'}];
    this.propertyTypes = [
      {label: 'Boolean', value: 'Boolean'},
      {label: 'Integer', value: 'Integer'},
      {label: 'String', value: 'String'}];

      this.dateFormat= "yyyy-mm-dd'T'hh:mm:ss'Z'";
      this.getEnviromentalRegions();
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_MASTER_CONFIG_PROP_MODIFY')) {
      this.isSubmitShown = true;
    }
    
  }

  getEnviromentalRegions(): void {
    // find out all enviromental regions
    this.envRegionService.getEnvRegions()
    .subscribe({
      next: (response) => {
        for(let i = 0; i < response.length; i++){
          var prop = response[i];
          this.envRegions.push({label: prop.envCode, value: prop.baseUrl});
        }
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  } 

  getConfigProperties(envRegionBaseUrl: string, selectedPropertyName: string, selectedPropertyScope: string, 
        selectedPropertyType: string, offset: number): void {
    this.configPropertyService.getConfigProperties(
      envRegionBaseUrl, selectedPropertyName, selectedPropertyScope, selectedPropertyType, offset)
      .subscribe({
        next: (response) => {
          this.configProperties = response.masterConfigPropertyList;
          this.offset = response.paging.nextPageToken;
          // Format the dates
          this.configProperties.forEach(function (arrayItem) {
            arrayItem.createDate = arrayItem.createDate.replace('T', ' ').replace('Z[UTC]','');
            arrayItem.lastUpdateDate = arrayItem.lastUpdateDate.replace('T', ' ').replace('Z[UTC]','');
          });
        },
        error: (error) => {
        },
        complete: () => {
        }
      });
  }

  isEnvRegionBaseUrl () {
     if (!this.envRegionBaseUrl) {
      this.confirmationService.confirm({
        message: 'Please select an enviromental region from the drop-down',
        header: 'Warninh',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
        },
        reject: () => {
        }
      })
      return false;  
    } 
    return true;
}

  onSearchClick(event: any) {
    if (!(this.isEnvRegionBaseUrl ())) {
      return;
    }
    var offset = 0;
    this.getConfigProperties(this.envRegionBaseUrl, this.selectedPropertyName, this.selectedPropertyScope, 
      this.selectedPropertyType, offset);
  }

  onClearClick(event: any) {
    this.selectedPropertyName = '';
    this.selectedPropertyScope = '';
    this.selectedPropertyType = '';
  }

  showDialogToAdd() {
    this.newConfigProperty = true;
    this.configProperty = {
      configPropertyId: 0, 
      propertyName: '', 
      propertyScope: 'brand',
      propertyType: 'String', 
      propertyDescription: '',
      enabled: true, 
      defaultValue: '',
      createDate: '', 
      lastUpdateDate: ''
    };
    this.displayDialog = true;
  }

  save() {
    if (!(this.isEnvRegionBaseUrl ())) {
      return;
    }
    // Basic input data validation
    if (!this.configProperty.propertyDescription || !this.configProperty.propertyName
       || !this.configProperty.propertyScope || !this.configProperty.propertyType) {
      this.confirmationService.confirm({
        message: 'The config property reccord record must have non-empty Name, Description, Scope, Type, and Enabled fields.',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });
    }
    else {
      if (this.configProperty.configPropertyId == null || this.configProperty.configPropertyId == 0) {
          this.configPropertyService.addConfigProperty(this.envRegionBaseUrl, this.configProperty)
          .subscribe({
            next: (response) => {
              this.processResult('The config property record has been successfully added.');
            },
            error: (error) => {
              console.log("Error", error);
            },
            complete: () => {
            }
          });
        }
      else {
        this.configPropertyService.modifyConfigProperty(this.envRegionBaseUrl, this.configProperty)
        .subscribe({
          next: (response) => {
            this.processResult('The config property record has been successfully modified.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      }
    }
  }

  delete() {
    if (!(this.isEnvRegionBaseUrl ())) {
      return;
    }
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete the config property record with all its properties?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded", 
      accept: () => {
        this.configPropertyService.deleteConfigProperty(this.envRegionBaseUrl, this.configProperty.configPropertyId)
        .subscribe({
          next: (response) => {
            this.processResult('The config property record has been successfully deleted.');
          },
          error: (error) => {
            console.log("Error", error);
          },
          complete: () => {
          }
        });
      },
      reject: () => {
      }
    });
  }

  cancel() {
    this.configProperty = {
      configPropertyId: 0,
      propertyName: '',
      propertyScope: '',
      propertyType: '',
      propertyDescription: '',
      enabled: false,
      defaultValue: '',
      createDate: '',
      lastUpdateDate: ''      
    };
    this.displayDialog = false;
  }

  onRowSelect(event: any) {
    this.newConfigProperty = false;
    this.configProperty = this.cloneConfigProperty(event.data);
    this.displayDialog = true;
  }

  cloneConfigProperty(c: IConfigProperty): IConfigProperty {
  //  let configProperty = new ConfigProperty();
  //  for (let prop in c) {
  //   configProperty[prop] = c[prop];
  //   }
    let configProperty: IConfigProperty = {
      configPropertyId: c.configPropertyId,
      propertyName: c.propertyName,
      propertyScope: c.propertyScope,
      propertyType: c.propertyType,
      propertyDescription: c.propertyDescription,
      enabled: c.enabled,
      defaultValue: c.defaultValue,
      createDate: c.createDate,
      lastUpdateDate: c.lastUpdateDate
    }
  return configProperty;
  }

  processResult(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Notification',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
            return;
          }
    });
    var offset = 0;
    this.getConfigProperties(this.envRegionBaseUrl, this.selectedPropertyName, this.selectedPropertyScope, this.selectedPropertyType, offset);
    // this.configProperty = new ConfigProperty();
    this.configProperty = {
      configPropertyId: 0,
      propertyName: '',
      propertyScope: '',
      propertyType: '',
      propertyDescription: '',
      enabled: false,
      defaultValue: '',
      createDate: '',
      lastUpdateDate: ''      
    };
    this.displayDialog = false;

  }

}


