<p-dialog  header="Message Template" [(visible)]="displayMessageTemplateDialog" [focusOnShow]="false" [responsive]="true"
    showEffect="fade" [modal]="true" [style]="{width: '55%'}" position="top"  [showHeader]="true" [closable]="false">


    @if(!isMessageTemplateDialogVisible) {

      <p-card header="Message Templates" subheader="" styleClass="ui-card-light">

          <div class="grid">
              <div class="col-12">
      
                  <p-table #dt [columns]="messageTemplatesCols" [value]="messageTemplates" selectionMode="single" 
                      [(selection)]="selectedMessageTemplate"  
                      sortMode="multiple" (onRowSelect)="onRowMessageTemplateSelect($event)" [paginator]="true" [rows]="15" >
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '15%'}"></th>
                      </tr>
                        <tr>
                          <th *ngFor="let col of columns" [ngSwitch]="col.field"  [ngStyle]="{'width': col.width}">
                            <input *ngSwitchCase="'messageTemplateName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                            <input *ngSwitchCase="'messageTemplateDescription'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '100%'}">
                          </th>
                      </tr>  
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns">
                                {{rowData[col.field]}}
                            </td>

                            <td style="text-align:center">
                                <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}" 
                                    data-toggle="tooltip" data-placement="top" title="Modify Message Template" 
                                    class="btn ui-button-info p-mr-2" (click)="onModifyMessageTemplate(rowData)">
                                </button> 
                                <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                    data-toggle="tooltip" data-placement="top" title="Delete Message Template" 
                                    class="btn ui-button-danger" (click)="onDeleteMessageTemplate(rowData)" style="margin-right:10px">
                                </button>                                
                            </td>

                        </tr>
                    </ng-template>
                  </p-table> 
            
              </div>
          </div>
  
  
          <p-footer>

              <div class="ui-g btn-group" role="group">
  
                  <p-button type="button" label="New Message Template" (click)="onNewMessageTemplate()"
                      styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                  </p-button>                                    
  
                  @if (isSelectMessageTemplateButtonShown) {
                    <p-button type="button" label="Select Message Template" (click)="onSelectMessageTemplate()"
                        styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                    </p-button>                                    
                    }
  
                  <p-button type="button" label="Close" (click)="onCancelMessageTemplate()"
                      styleClass="p-button-primary p-button-raised p-button-rounded margin-left">
                  </p-button>                                    
  
              </div>
  
          </p-footer>
  
      </p-card>
  
  }


  @if(isMessageTemplateDialogVisible) {

    <p-card header="Message Template" subheader="" styleClass="ui-card-light">

      <form [formGroup]="messageTemplateForm" (ngSubmit)="onSubmit(messageTemplateForm.value)">

        <div>

            @if(isMessageTemplateCodeShown) {
                <div class="field grid">
                    <label for="messageTemplateCode" class="col-fixed label" style="width:15em">Message Template Code:</label>
                    <div class="col">
                        <input pInputText id="messageTemplateCode" type="text" formControlName="messageTemplateCode" [style]="{'width': '50%'}" 
                            [size]="255" placeholder="Required" pTooltip="Discriptive message template code" tooltipPosition="top"/> 
                    </div>
                </div>
            }
    
            <div class="field grid">
                <label for="messageTemplateName" class="col-fixed label" style="width:15em">Message Template Name:</label>
                <div class="col">
                    <input pInputText id="messageTemplateName" type="text" formControlName="messageTemplateName" [style]="{'width': '50%'}" 
                        [size]="255" placeholder="Required" pTooltip="Discriptive message template name" tooltipPosition="top"/> 
                    <p-message severity="error" text="Message template name is required" *ngIf="!messageTemplateForm.controls['messageTemplateName'].valid && messageTemplateForm.controls['messageTemplateName'].dirty"></p-message>                 
                </div>
            </div>

            <div class="field grid">
                <label for="messageTemplateDescription" class="col-fixed label" style="width:15em">Template Description:</label>
                <div class="col">
                    <input pInputText id="messageTemplateDescription" type="text" formControlName="messageTemplateDescription" [style]="{'width': '100%'}" 
                        [size]="2000" placeholder="Required" pTooltip="Discriptive Message template description" tooltipPosition="top"/> 
                    <p-message severity="error" text="Message template description is required" *ngIf="!messageTemplateForm.controls['messageTemplateDescription'].valid && messageTemplateForm.controls['messageTemplateDescription'].dirty"></p-message>                 
                </div>
            </div>

            <div class="field grid">
                  <label for="messageTemplateHeader" class="col-fixed label" style="width: 15em">Template Header:</label>
                  <div class="col">
                    <input pInputText id="messageTemplateHeader" type="text" formControlName="messageTemplateHeader" 
                    [style]="{'width': '80%'}"
                    [size]="100" pTooltip="The header of the Message Template" tooltipPosition="top"/> 
                  </div>
            </div>    
            
            <div class="field grid">
                  <label for="messageTemplateText" class="col-fixed label" style="width: 15em">Template Text:</label>
                  <div class="col">
                    <textarea pInputTextarea id="messageTemplateText" type="text" formControlName="messageTemplateText" 
                        [style]="{'width': '100%'}"
                        [rows]="2" pTooltip="The content of the Message Template" tooltipPosition="top"> 
                    </textarea>
                  </div>
            </div>                             

            <div class="field grid">
                <label for="lastUpdateDate" class="col-fixed label" style="width:15em">Modified Date:</label>
                <div class="col">
                    <input pInputText id="lastUpdateDate" type="text" formControlName="lastUpdateDate" [style]="{'width': '25%'}" readonly="true"/> 
                </div>
            </div>

        </div>

        <div class="col-12">
          <div  class="col-2" class="row container-fluid"  id="divshow">
              <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!messageTemplateForm.valid" ></p-button>
              <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelNewMessageTemplate()"></p-button>
          </div> 
          @if (this.isMessageTemplateNew) {
            <div class="col-12">
                <mat-label>{{hintMsg}}</mat-label>
              </div>
            }
      </div>

      </form>

        <p-footer>
        </p-footer>
    
    </p-card>

}

</p-dialog>



<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="message-template"></p-confirmDialog>
