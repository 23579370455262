import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";

import { IUserAttribute } from '../../interfaces/user-attribute';
import { IPortalUser } from '../../interfaces/portal-user';

const USER_TYPE_CODE = 'userType';
const ALL_BRANDS_CODE = 'allBrands';

const USER_CLIENT_APP = 'client-app';
const USER_BILLER_REP = 'biller-user';
const USER_ACI_STAFF = 'aci-staff';
const USER_ACI_PMO = 'aci-pmo';
const USER_ACI_READ_ONLY = 'aci-read-only';
const USER_ADMIN = 'admin';

const ROLE_BILLER_REP = 'rl_biller_rep';
const ROLE_ACI_STAFF = 'rl_aci_staff';
const ROLE_ACI_PMO = 'rl_aci_pmo';
const ROLE_ACI_READ_ONLY = 'rl_aci_read_only';
const ROLE_ADMIN = 'rl_admin';
const ROLE_USER_ADMIN = 'rl_user_admin';
const ROLE_BRAND_CONFIG = 'rl_brand_config';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {

  protected authServerUrl = environment.apiServer.authServerUrl;
  
  private userProfileUrl = this.authServerUrl + '/auth/users/{userName}';
  private userRolesUrl = this.authServerUrl + '/auth/users/{userName}/roles';
  private userAttributesUrl = this.authServerUrl + '/auth/users/{userName}/attributes';
  private userChangePasswordUrl = this.authServerUrl + '/auth/users/{userName}/change-password';  
  private userResetPasswordUrl = this.authServerUrl + '/auth/users/{userName}/reset-password'; 
  private userRevampPasswordUrl = this.authServerUrl + '/auth/users/{userName}/revamp-password'; 
  private userChangeEmailAddressUrl = this.authServerUrl + '/auth/users/{userName}/email-addresses';  

  constructor(
    private tokenStorageService: TokenStorageService, 
    private errorProcessorService: ErrorProcessorService,
    private http: HttpClient
  ) { }

  // // Get a list of user attributes of a specific attribute code
  // public getUserAttributes(attributeCode: string): string[] {
  //   let currentUser = this.tokenStorageService.getCurrentUser();
  //   let userAttributes: string[] = [];
  //   if (currentUser.userAttributeList) {
  //     currentUser.userAttributeList.filter(attribute => attribute.attributeCode == attributeCode).forEach(attribute => {
  //       userAttributes.push(attribute.attributeValue);
  //     })
  //   }
  //   return userAttributes;
  // }

  // Find out whether the user us authenticated
  public isUserAuthenticated(): boolean {
    let currentUser = this.tokenStorageService.getCurrentUser();
    if (!currentUser) return false;
    return true;
  }

  // Get a list of the brand codes that are granted to the current user
  public getUserBrands(): string[] {
    let currentUser = this.tokenStorageService.getCurrentUser();
    let userBrands: string[] = [];
    if (currentUser.userBrandList) {
      currentUser.userBrandList.forEach(brandCode => {
        userBrands.push(brandCode);
      })
    }
    return userBrands;
  }

  // Get a list of the role names that are granted to the current user
  public getUserRoles(): string[] {
    let currentUser = this.tokenStorageService.getCurrentUser();
    let userRoles: string[] = [];
    if (currentUser.userRoleList) {
      currentUser.userRoleList.forEach(roleName => {
        userRoles.push(roleName);
      })
    }
    return userRoles;
  }

  // Get a list of the privilege names that are granted to the current user
  public getUserPrivileges(): string[] {
    let currentUser = this.tokenStorageService.getCurrentUser();
    let userPrivileges: string[] = [];
    if (currentUser.userPrivilegeList) {
      currentUser.userPrivilegeList.forEach(privilegesName => {
        userPrivileges.push(privilegesName);
      })
    }
    return userPrivileges;
  }  

  // Get a list of the attributes that are granted to the current user
  public getUserAttributes(): IUserAttribute[] {
    let currentUser = this.tokenStorageService.getCurrentUser();
    let userAttributes: IUserAttribute[] = [];
    if (currentUser.userAttributeList) {
      currentUser.userAttributeList.forEach(attribute => {
        userAttributes.push(attribute);
      })
    }
    return userAttributes;
  }

  // Get a list of specific attributes that are granted to the current user based on the attribute code
  public getUserAttributesByAttributeCode(attributeCode: string): string[] {
    let currentUser = this.tokenStorageService.getCurrentUser();
    let userAttributes: string[] = [];
    if (currentUser.userAttributeList) {
        currentUser.userAttributeList.filter(attribute => attribute.attributeCode == attributeCode).forEach(attribute => {
          userAttributes.push(attribute.attributeValue);
        })
    }
    return userAttributes;
  }

  // Get the value of a specific boolean attribute based on the attribute code
  public getBooleanAttribute(attributeCode: string): boolean {
    let bool = false;
    let currentUser = this.tokenStorageService.getCurrentUser();
    if (currentUser.userAttributeList) {
      currentUser.userAttributeList.filter(attribute => attribute.attributeCode == attributeCode).forEach(attribute => {
          if (attribute.attributeValue.toLowerCase() == 'true') {
            bool = true;
          }
        })
    }
    return bool;
  }

  // Find out whether the current user has ALL the privileges from the list
  public userHasAllPrivileges (privileges: string[]): boolean {
    let hasRequiredPrivileges = true;
    if (privileges) {
      privileges.forEach(privilege => {
        if (privilege) {
          if (!this.userHasPrivilege(privilege)) {
            hasRequiredPrivileges = false;
          }
        } 
       })
    }
    return hasRequiredPrivileges;
  }

  // Find out whether the current user has at leat one privilege from the list
  public userHasAnyPrivilege (privileges: string[]): boolean {
    let hasPrivilege = false;
    if (privileges) {
      privileges.forEach(privilege => {
        if (privilege) {
          if (this.userHasPrivilege(privilege)) {
            hasPrivilege = true;
          }
        } 
       })
    }
    return hasPrivilege;
  }

  // Determine whether the current user has a specific privilege
  public userHasPrivilege(privelegeName: string): boolean {
    let currentUser = this.tokenStorageService.getCurrentUser();
    let userPrivileges = this.getUserPrivileges();
    if (currentUser.userName && userPrivileges.includes(privelegeName)) {
      return true;
    }
    return false;
  }

  // Determine whether the current user has a specific role
  public userHasRole(roleName: string): boolean {
    let currentUser = this.tokenStorageService.getCurrentUser();
    let userRoles = this.getUserRoles();
    if (currentUser.userName && userRoles.includes(roleName)) {
      return true;
    }
    return false;
  }

  // Determine whether the user is a client application
  public isClientApp(): boolean {
    if (this.getUserAttributesByAttributeCode(USER_TYPE_CODE).includes(USER_CLIENT_APP)) {
        return true;
    }
    return false;
  }

  // Determine whether the user is an external biller representative
  public isBillerRep(): boolean {
    if (this.getUserAttributesByAttributeCode(USER_TYPE_CODE).includes(USER_BILLER_REP)
       || this.getUserRoles().includes(ROLE_BILLER_REP)) {
      return true;
    }
    return false;
  }

  // Determine whether the user has read-only access type
  public hasReadOnlyAccess(): boolean {
    if (this.getUserAttributesByAttributeCode(USER_TYPE_CODE).includes(USER_ACI_READ_ONLY)
        || this.getUserRoles().includes(ROLE_ACI_READ_ONLY)) {
      return true;
    }
    return false;
  }

  // Determine whether the user is an ACI staff (internal user)
  public isAciStaff(): boolean {
    if (this.getUserAttributesByAttributeCode(USER_TYPE_CODE).includes(USER_ACI_STAFF)
        || this.getUserAttributesByAttributeCode(USER_TYPE_CODE).includes(USER_ADMIN)
        || this.getUserRoles().includes(ROLE_ACI_STAFF)
        || this.getUserRoles().includes(ROLE_ADMIN)) {
      return true;
    }
    return false;
  }

  // Determine whether the user is an ACI PMO (internal user)
  public isAciPmo(): boolean {
    if (this.getUserAttributesByAttributeCode(USER_TYPE_CODE).includes(USER_ACI_PMO)
        || this.getUserRoles().includes(ROLE_ACI_PMO)) {
      return true;
    }
    return false;
  }

  // Determine whether the user is an admin of the moBills Portal
  public isPortalAdmin(): boolean {
    if ((this.getUserAttributesByAttributeCode(USER_TYPE_CODE).includes(USER_ADMIN) || this.getUserRoles().includes(ROLE_ADMIN))
       && this.getUserRoles().includes(ROLE_ACI_STAFF)) {
      return true;
    }
    return false;
  }

  // Determine whether the user is brand configuration admin
  public isBrandConfigAdmin(): boolean {
    if (this.getUserRoles().includes(ROLE_ADMIN) && this.getUserRoles().includes(ROLE_BRAND_CONFIG)
       && this.getUserRoles().includes(ROLE_ACI_STAFF)) {
      return true;
    }
    return false;
  }


  // Determine whether the user is a user admin
  public isUserAdmin(): boolean {
    if (this.getUserRoles().includes(ROLE_USER_ADMIN)) {
      return true;
    }
    return false;
  }

  // Determine whether the user has all brands granted
  public hasAllBrands(): boolean {
    if ((this.getBooleanAttribute(ALL_BRANDS_CODE) || this.getUserRoles().includes(ROLE_ADMIN))
        && (this.isAciStaff() || this.isAciPmo() || this.hasReadOnlyAccess())) {
      return true;
    }
    return false;
  }

  // Find out the user's roles during the login event to proper authorize the user
  findUserRoles (userName: string): Observable<string[]> {
    let url = this.userRolesUrl.replace('{userName}', userName);
    return this.http.get<string[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Find out the user's attributes during the login event, especially to know their "allBrands" and "userType" settings
  findUserAttributes (userName: string): Observable<IUserAttribute[]> {
    let url = this.userAttributesUrl.replace('{userName}', userName);
    return this.http.get<IUserAttribute[]>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Find out the user's profile, including roles, brands, and attributes
  findUserProfileByUserName (userName: string): Observable<IPortalUser> {
    let url = this.userProfileUrl.replace('{userName}', userName);
    // console.log('findUserProfileByUserName', url);
    return this.http.get<IPortalUser>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Change the password of the user on the login page
  changeUserPassword(userName: string, password: string, newPassword: string): Observable<string> {
    let url = this.userChangePasswordUrl.replace('{userName}', userName);
    let passwordResetRequest = {oldPassword: password, newPassword: newPassword};
    return this.http.put<string>(url, passwordResetRequest, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Reset the password of a user by an admin
  resetUserPassword(userName: string, newPassword: string): Observable<string> {
    let url = this.userResetPasswordUrl.replace('{userName}', userName);
    let authToken = this.tokenStorageService.getAuthToken();
    let passwordResetRequest = {authToken: authToken, newPassword: newPassword};
    return this.http.put<string>(url, passwordResetRequest, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Revanp the password of a user by an admin
  revampUserPassword(userName: string, newPassword: string): Observable<string> {
    let url = this.userRevampPasswordUrl.replace('{userName}', userName);
    let authToken = this.tokenStorageService.getAuthToken();
    let passwordResetRequest = {newPassword: newPassword};
    return this.http.put<string>(url, passwordResetRequest, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Change the user's email address
  changeEmailAddress(userName: string, password: string, emailAddress: string): Observable<string> {
    let url = this.userChangeEmailAddressUrl.replace('{userName}', userName);
    let passwordResetRequest = {oldPassword: password, newPassword: emailAddress};
    return this.http.put<string>(url, passwordResetRequest, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Find out whether the user is granted a specific brand
  public hasBrandGranted(brandCode : string): boolean {
    let hasBrand = false;
    let currentUser = this.tokenStorageService.getCurrentUser();
    if (currentUser.userBrandList) {
      currentUser.userBrandList.forEach(item => {
        if (item == brandCode) {
          hasBrand = true;
        }
      })
    }
    return hasBrand;
  }


}
