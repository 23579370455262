import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IMessage } from '../../interfaces/message';
// import { AppConfigService} from '../../services/app-config.service';
import { environment } from '../../../environments/environment';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class AccountAdminService {

  // protected portalUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected portalAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected portalUrl = environment.apiServer.portalUrl;
  protected portalAuth = environment.appSetting.portalAuth;
  protected baseAuth = environment.appSetting.baseAuth;

  private portalHeaders = new HttpHeaders()
    .set('Authorization', this.portalAuth);
  private baseHeaders = new HttpHeaders()
    .set('Authorization', this.baseAuth);

  private messageBroadcastingUrl = "/ver1/brands/{brandCode}/accounts/messages";
  private messageToAllAccountsUrl = "/demo-scenario/brands/{brandCode}/message-to-all";
  private updateAccountUrl = "/ver1/brands/{brandCode}/accounts/{accountId}";
  private runScenarioUrl = "/demo-scenario";
  private emailBroadcastingUrl = "/demo-scenario/email-distr";
 
  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService 
  ) { }

  submitMessageBroadcasting(brandCode: string, messageBroadcasting: any, baseUrl: string) : Observable<IMessage>{
    let request: any = [];
    messageBroadcasting.accountIds.forEach((accountId: any) => {
      request.push(
      {
        "accountId": accountId,
        "msgCode": messageBroadcasting.messageTemplateCode,
        "passId": messageBroadcasting.passId,
        "msgHeader": messageBroadcasting.templateHeader,
        "msgBody": messageBroadcasting.templateText,
        "linkUrl": null,
      });
    })
    var url = baseUrl + this.messageBroadcastingUrl.replace('{brandCode}', brandCode);
    return this.http.post<IMessage>(url, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 

  submitMessageToAllAccounts(brandCode: string, messageBroadcasting: any, baseUrl: string) : Observable<IMessage>{
    let request = 
      {
        "accountId": null,
        "msgCode": messageBroadcasting.messageTemplateCode,
        "passId": messageBroadcasting.passId,
        "msgHeader": messageBroadcasting.templateHeader,
        "msgBody": messageBroadcasting.templateText,
        "linkUrl": null,
      };
    var url = baseUrl + this. messageToAllAccountsUrl.replace('{brandCode}', brandCode);
    return this.http.post<IMessage>(url, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 

  updateAccount(brandCode: string, accountId: string, map: any, baseUrl: string) : Observable<IMessage>{
    let jsonObject: any = {};  
    map.forEach((value: string, key: string) => {  
        jsonObject[key] = value  
    }); 
    let request = {
      "accountId": accountId,
      "values": jsonObject 
    };
    var url = baseUrl + this.updateAccountUrl.replace('{brandCode}', brandCode).replace('{accountId}', accountId);
    return this.http.put<IMessage>(url, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 

  runScenario(brandCode: string, accountId: string, scenarioName: string, amountDueChange: any,
     standardAmountsMap: any, baseUrl: string) : Observable<IMessage>{
    let jsonObject:any = {};  
    standardAmountsMap.forEach((value: string, key: string) => {  
        jsonObject[key] = value  
    }); 
    let request = {
      "brandCode": brandCode,
      "accountId": accountId,
      "scenarioName": scenarioName,
      "dueChangeAmount": amountDueChange,
      "industryAmountMap": jsonObject 
    };
    var url = baseUrl + this.runScenarioUrl;
    return this.http.post<IMessage>(url, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 

  assembleEmailBroadcasting(brandCode: string, passId: number, offerId: number, templateId: number,
     overrideFields: any, controlRecipient: any, 
      recipientEmailAddress: any, otherEmailAddresses: any, baseUrl: string) : Observable<IMessage>{
    let fieldValues:any = {};  
    overrideFields.forEach((value: string, key: string) => {  
      fieldValues[key] = value  
    }); 
    let request = {
      "brandCode": brandCode,
      "passId": passId,
      "offerId": offerId,
      "templateId": templateId,
      "fieldValues": fieldValues,
      "sendViaEmail": true,
      "controlRecipient": controlRecipient,
      "recipientSMS": null,
      "recipientEmailAddress": recipientEmailAddress,
      "otherEmailAddresses": otherEmailAddresses
    };
    var url = baseUrl + this.emailBroadcastingUrl;
    return this.http.post<IMessage>(url, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 

  assembleSmsBroadcasting(brandCode: string, passId: number, offerId: number, templateId: number, 
    controlRecipient: any, recipientSMS: any, baseUrl: string) : Observable<IMessage>{
    let request = {
      "brandCode": brandCode,
      "passId": passId,
      "offerId": offerId,
      "templateId": templateId,
      "fieldValues": null,
      "sendViaEmail": false,
      "controlRecipient": controlRecipient,
      "recipientSMS": recipientSMS,
      "recipientEmailAddress": null,
      "otherEmailAddresses": null
    };
    var url = baseUrl + this.emailBroadcastingUrl;
    return this.http.post<IMessage>(url, request, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 

}
