<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<h3 [hidden]="isDashboardHidden">Portal Dashboard - {{brandName}}</h3> 

<div class="col-6" [hidden]="isDashboardHidden">

    <div class="col-12">

        <div class="ui-g-4 group div-criteria">
            <label for="dateFrom">From: </label>
            <p-calendar id="dateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" placeholder="Date From:" 
            [defaultDate]="defaultDate" [showIcon]="true" class="margin-left"></p-calendar>
        </div> 
        <div class="ui-g-4 group div-criteria">
            <label for="dateTo">To: </label>
            <p-calendar id="dateTo" [(ngModel)]="selectedDateTo" dateFormat="yy-mm-dd" placeholder="Date To:" 
            [showIcon]="true" class="margin-left"></p-calendar>
        </div> 

        <div class="col-3">
            <button pButton type="button" label="Search" icon="pi pi-check" iconPos="left" (click)="onSearchClick($event)"
                class="p-button-raised p-button-rounded">
            </button>
            <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
                class="p-button-raised p-button-rounded float-right" *ngIf="isBrandSelectionButtonShown">
            </button>
        </div>

        <div class="col-1" id="idSpinner" [hidden]="isSpinnerHidden">
            <div class="loader"></div>  
        </div>

    </div>

    <div class="col-12">

        <p-card header="Clickthroughs Statistics" subheader="" styleClass="ui-card-light_sized">

            <p-table #dtt [columns]="clickthroughStatsCols" [value]="clickthroughStats" tableStyleClass="prime-table" >
                <ng-template pTemplate="caption">
                    Clickthrough Counts by Link Type and Platform
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{col.header}}
                    </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> 

        </p-card>   

    </div>

    <div class="col-12">

        <p-card header="Notifications Statistics" subheader="" styleClass="ui-card-light_sized">

            <p-table #dtt [columns]="notificationStatsCols" [value]="notificationStats" tableStyleClass="prime-table">
                <ng-template pTemplate="caption">
                    Notification Counts by Template
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{col.header}}
                    </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> 

        </p-card> 

    </div>

    <div class="col-12">

        <p-card header="CSR Statistics" subheader="" styleClass="ui-card-light_sized">

            <p-table #dtt [columns]="csrStatsCols" [value]="csrStats" tableStyleClass="prime-table">
                <ng-template pTemplate="caption">
                    CSR Sends
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{col.header}}
                    </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> 

        </p-card> 

    </div>

</div>

<div class="col-6" [hidden]="isDashboardHidden">

    <div class="col-12">

        <p-card header="Active Accounts" subheader="" styleClass="ui-card-light_sized">

            <p-table #dt [columns]="activeAccountsCols" [value]="enrollments" tableStyleClass="prime-table">
                <ng-template pTemplate="caption">
                    Enrollments by Unique Accounts
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{col.header}}
                        <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> 

        </p-card>            

    </div>

    <div class="col-12">

        <p-card header="Enrollment Trend" subheader="" styleClass="ui-card-light_sized">

            <p-chart type="bar" [data]="enrollmentCounts" [options]="enrollmentOptions" ></p-chart>

        </p-card>            

    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="home-biller"></p-confirmDialog>