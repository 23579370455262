import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAdhocMessageConfig } from '../../interfaces/adhoc-message-config';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class AdhocMessageConfigService {

  protected baseAuth = environment.appSetting.baseAuth;

  private baseHeaders = new HttpHeaders()
    .set("request-origin", "csr-portal")
    .set('Authorization', this.baseAuth);

    private adhocMessageConfigUrl = '/adhoc-msg/brands/{brandCode}/configs';
    private adhocMessageConfigByIdUrl = '/adhoc-msg/brands/{brandCode}/configs/{adhocMessageConfigId}';
    private sendAdhocMessageUrl = '/adhoc-msg/brands/{brandCode}/configs/{adhocMessageConfigId}/send';

  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService
  ) { }

  getAdhocMessageConfigsForBrand(brandCode: string, baseUrl: string): Observable<IAdhocMessageConfig[]> {
    var url = baseUrl + this.adhocMessageConfigUrl
      .replace('{brandCode}', brandCode);
    return this.http.get<IAdhocMessageConfig[]>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  } 
  
  getAdhocMessageConfigById(brandCode: string, adhocMessageConfigId: number, baseUrl: string): Observable<IAdhocMessageConfig> {
    var url = baseUrl + this.adhocMessageConfigByIdUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMessageConfigId}', adhocMessageConfigId.toString());
      console.log('getAdhocMessageConfigById', url);
    return this.http.get<IAdhocMessageConfig>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }  

  addAdhocMessageConfig(brandCode: string, adhocMsgConfig: any, baseUrl: string): Observable<IAdhocMessageConfig> {
    var url = baseUrl + this.adhocMessageConfigUrl
      .replace('{brandCode}', brandCode);
      // console.log('url', url);
      // console.log('adhocMsgConfig', adhocMsgConfig);
    return this.http.post<IAdhocMessageConfig>(url, adhocMsgConfig, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  modifyAdhocMessageConfig(brandCode: string, adhocMsgConfig: any, baseUrl: string): 
      Observable<IAdhocMessageConfig> {
    var url = baseUrl + this.adhocMessageConfigByIdUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMessageConfigId}', adhocMsgConfig.adhocMessageConfigId.toString());
      // console.log('url', url);
      // console.log('adhocMsgConfig', adhocMsgConfig);
    return this.http.put<IAdhocMessageConfig>(url, adhocMsgConfig, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  deleteAdhocMessageConfig(brandCode: string, adhocMessageConfigId: number, baseUrl: string): Observable<number> {
    var url = baseUrl + this.adhocMessageConfigByIdUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMessageConfigId}', adhocMessageConfigId.toString());
    return this.http.delete<number>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  sendAdhocMessage(brandCode: string, adhocMessageConfigId: number, baseUrl: string): Observable<string> {
    var url = baseUrl + this.sendAdhocMessageUrl
      .replace('{brandCode}', brandCode)
      .replace('{adhocMessageConfigId}', adhocMessageConfigId.toString());;
      console.log('url', url);
    return this.http.post<string>(url, null, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }



}
