<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded" >
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 


<!--   -->


<div class="grid">

    <!--- First card with the master list -->

    <div class="col-8 side-list-content-data" >
        <p-card header="List of Brand Images" subheader="" styleClass="ui-card-light" >

            <p-table #dt [columns]="cols" [value]="brandImages" selectionMode="single" [(selection)]="selectedImage"
                    (onRowSelect)="onRowSelect($event)" sortMode="multiple" [paginator]="true" [rows]="15" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                        <td style="text-align:center">
                            <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Delete brand image" 
                                class="ui-button-danger" (click)="onRowDelete(rowData)" *ngIf="isSubmitShown" >
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <!-- <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left">
                        <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button>
                    </div>
                </ng-template>     -->
          </p-table> 
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">list_all</mat-icon>
            </p-footer>
        </p-card>
    </div>

    <div class="col-2" styleClass="ui-card-light"  *ngIf="isImagePreviewShown">
        <img [src]="image" />
    </div>
    
</div>

<div class="col-3" *ngIf="isSubmitShown">

    <p-card header="Add Image" subheader="" styleClass="ui-card-light" >

        <div class="col-12">

            <div class="col-10">
                <p-fileUpload name="logoImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                    [customUpload]="true" (onSelect)="onLogoFileChange($event)" [fileLimit]="1"
                    [showCancelButton]="true" [disabled]="false" > 
                </p-fileUpload>
            </div>
    
            <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                <button pButton type="button" label="Submit" class="ui-button-primary" [disabled]="!isImageSelected" (click)="onSubmitImage()"></button>
 -->
                <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!isImageSelected"  (click)="onSubmitImage()"></p-button>

            </div>          

        </div>
    

        <p-footer>
            <mat-icon class="nav-caption wal-center">add_a_photo</mat-icon>
        </p-footer>
    </p-card>

</div>

<div>
    <textarea cols="30" rows="1" style="display:none">
    </textarea>
</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="brand-images"></p-confirmDialog>


