<h2>Portal Users</h2>

<div class="grid col-8">
        <div class="col-2">
            <label for="selectedUserName" class="margin-left">User Name: </label>
            <input pInputText id="selectedUserName" [(ngModel)]="selectedUserName" placeholder="User Name:"/> 
        </div>  
        <div class="col-2" *ngIf="isAciStaff">
            <!-- "isPortalAdmin || isAciStaff" -->
            <label for="selectedRoleName" class="margin-left">Role Name: </label>
            <p-dropdown id ="selectedRoleName" [options]="roleNames" [(ngModel)]="selectedRoleName" [filter]="false" 
                placeholder="Role Name:" [editable]="false"  [style]="{'width': '90%'}" ></p-dropdown>
        </div>  
        <div class="col-2" *ngIf="isAciStaff">
            <!-- ngIf="isPortalAdmin || isAciStaff" -->
            <label for="selectedUserType" class="margin-left">User Type: </label>
            <p-dropdown id ="selectedUserType" [options]="userTypes" [(ngModel)]="selectedUserType" [filter]="false" 
                placeholder="User Type:" [editable]="false" [style]="{'width': '90%'}" ></p-dropdown>
        </div>  
        <div class="col-1">
            <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
        </div>  
        <div class="col-1">
            <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)"></p-button>
        </div>  

    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader">
    </div>  
</div>

<div class="grid col-9">

        <p-table #dt [columns]="cols" [value]="users" selectionMode="single" [(selection)]="selectedUser" 
            sortMode="multiple" (onRowSelect)="onRowSelect($event)" [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,25,50,75]">
          <ng-template pTemplate="caption">
              List of Users
          </ng-template>
          <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th pSortableColumn="enabled" style="width: 5%">Enabled? <p-sortIcon field="enabled"></p-sortIcon></th>
              <th pSortableColumn="locked" style="width: 5%">Locked? <p-sortIcon field="locked"></p-sortIcon></th>
            </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    <input pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, col.filterMatchMode)" />
                </th>
            </tr>  
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
                  <td>
                    <p-tag [value]="rowData.enabled ? 'Y' : 'N'" [severity]="getEnabled(rowData.enabled)"></p-tag>
                  </td>                  
                  <td>
                    <p-tag [value]="rowData.locked ? 'Y' : 'N'" [severity]="getLocked(rowData.locked)"></p-tag>
                  </td>                  
    
                </tr>
          </ng-template>
          <ng-template pTemplate="summary" let-rowData>
              <div style="text-align:left" *ngIf="isSubmitShown">
                <p-button label="Add" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="showDialogToAdd()"></p-button>
              </div>
          </ng-template>    
          </p-table> 
      
</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>

<p-dialog appendTo="body" header="User Details" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"   [style]="{ width: '70em' }">
    <div *ngIf="user">

        <p-tabView  [(activeIndex)]="tabIndex" (onChange)="onActiveTabChange($event)" >
            <p-tabPanel header="Properties" leftIcon="pi pi-user-edit">

              <p-card header="User Attributes" subheader="" styleClass="ui-card-light">

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="userId" class="col-fixed label" style="width: 12em">User Id:</label>
                      <div class="col">
                        <input pInputText id="userId" [(ngModel)]="user.userId" autofocus [disabled]="true"/>
                      </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="userName" class="col-fixed label" style="width:12em">User Name:</label>
                      <div class="col">
                          <input pInputText id="userName" [(ngModel)]="user.userName" style="width: 17em">
                      </div>
                    </div>
                </div>    

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="password" class="col-fixed label" style="width: 12em">Password:</label>
                      <div class="col">
                        <input pPassword type="password" id="password" [(ngModel)]="user.password" [disabled]="!newUser"/>
                      </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="userName" class="col-fixed label" style="width:12em">User Type:</label>
                      <div class="col">
                        <p-dropdown id ="userType" [options]="userTypes" [(ngModel)]="userType"  
                            (onChange)="onChangeUserType($event)"
                            [filter]="false" [editable]="false"> 
                        </p-dropdown>
                      </div>
                    </div>
                </div>    

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="emailAddress" class="col-fixed label" style="width: 12em">Email Address:</label>
                      <div class="col">
                        <input pInputText type="email" id="emailAddress" [(ngModel)]="user.emailAddress" style="width: 20em"
                        validatorMessage="Value does not match pattern." pTooltip="Examle: jane.doe@gmail.com" tooltipPosition="top" 
                              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>                    
                      </div>
                    </div>
                </div>                 

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="passwordChangeRequired" class="col-fixed label" style="width: 12em">Password Reset:</label>
                      <div class="col">
                        <p-checkbox [(ngModel)]="user.passwordChangeRequired" id="passwordChangeRequired" [binary]="true"></p-checkbox>
                      </div>
                    </div>
                    <div class="col-3"></div>
                    <div class="field grid" *ngIf="hasAllBrandsAttribute">
                      <label for="allBrandsGrant" class="col-fixed label" style="width:12em">All Brands Grant:</label>
                      <div class="col">
                        <p-checkbox [(ngModel)]="allBrandsGrant" id="allBrandsGrant" [binary]="true"></p-checkbox>
                      </div>
                    </div>
                </div>    

                <div class="grid">
                  <div class="col-1"></div>
                  <div class="field grid">
                    <label for="enabled" class="col-fixed label" style="width: 12em">Enabled:</label>
                    <div class="col">
                      <p-checkbox [(ngModel)]="user.enabled" id="enabled" [binary]="true"></p-checkbox>
                    </div>
                  </div>
                  <div class="col-3"></div>
                  <div class="field grid">
                    <label for="locked" class="col-fixed label" style="width:12em">Locked:</label>
                    <div class="col">
                      <p-checkbox [(ngModel)]="user.locked" id="locked" [binary]="true"></p-checkbox>
                    </div>
                  </div>
              </div>    

              <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="loginFailCount" class="col-fixed label" style="width: 12em">Login Fail Cnt:</label>
                      <div class="col">
                        <input pInputText id="loginFailCount" [(ngModel)]="user.loginFailCount" [disabled]="true"/>
                      </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="field grid" *ngIf="hasAllBrandsAttribute">
                      <label for="lastLogin" class="col-fixed label" style="width:12em">Last Login:</label>
                      <div class="col">
                        <input pInputText id="lastLogin" [(ngModel)]="user.lastLogin" [disabled]="true"/>
                      </div>
                    </div>
                </div>    

                <div class="grid">
                    <div class="col-1"></div>
                    <div class="field grid">
                      <label for="createDate" class="col-fixed label" style="width: 12em">Create Date:</label>
                      <div class="col">
                        <input pInputText id="createDate" [(ngModel)]="user.createDate" [disabled]="true"/>
                      </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="field grid" *ngIf="hasAllBrandsAttribute">
                      <label for="lastUpdateDate" class="col-fixed label" style="width:12em">Update Date:</label>
                      <div class="col">
                        <input pInputText id="lastUpdateDate" [(ngModel)]="user.lastUpdateDate" [disabled]="true"/>
                      </div>
                    </div>
                </div>    

                <p-footer>
                  <mat-icon class="nav-caption wal-center">add_a_photo</mat-icon>
                </p-footer>
  
              </p-card>                

            </p-tabPanel>

            <p-tabPanel header="Roles" leftIcon="pi pi-check-circle" *ngIf="isRoleTabpageShown">

                <p-pickList [source]="availableRoles" [target]="grantedRoles" sourceHeader="Available" targetHeader="Granted" 
                        [sourceStyle]="{height:'25rem'}" [targetStyle]="{height:'25rem'}"  filterBy="label" filter = "true"
                        (onSourceSelect)=onSourceSelect($event) (onTargetSelect)="onTargetSelect($event)"
                        [responsive]="true" [dragdrop]="true" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-role pTemplate="item">
                    <div class="ui-helper-clearfix">
                        <div>{{role.label}}</div>
                    </div>
                    </ng-template>
                </p-pickList>

                <div class="col-12">
                    <label>{{roleDescrHint}}</label>
                </div>

            </p-tabPanel>

            <p-tabPanel header="Brands" leftIcon="pi pi-sitemap" *ngIf="!allBrandsGrant">

                <p-listbox [options]="brandCodes" [(ngModel)]="selectedBrandCodes" [checkbox]="true"  [filter]="true" 
                  [multiple]="true" optionLabel="name" [style]="{'width':'30rem'}" [listStyle]="{'max-height':'25rem'}">
                </p-listbox>

            </p-tabPanel>

            <p-tabPanel header="Password Reset" leftIcon="pi pi-sync" *ngIf="!newUser">

              <form [formGroup]="passwordForm">

                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="newPassword" class="col-fixed label" style="width: 10em">New Password:</label>
                    <div class="col-6">
                        <input pPassword type="password" id="newPassword" formControlName="newPassword"/>
                        <div *ngIf="!passwordForm.controls['newPassword'].valid && passwordForm.controls['newPassword'].dirty" class="alert alert-danger">
                            Incorrect new password
                        </div>                                    
                    </div>
                </div>
                
                <div class="field grid">
                    <div class="col-1"></div>
                        <label for="confirmPassword" class="col-fixed label" style="width: 10em">Confirm Password:</label>
                    <div class="col-6">
                        <input pPassword type="password" id="confirmPassword"  formControlName="confirmPassword"/>
                        <div *ngIf="!passwordForm.controls['confirmPassword'].valid && passwordForm.controls['confirmPassword'].dirty" class="alert alert-danger">
                            Incorrect confirm password
                        </div>                                    
                    </div>
                </div>

                <div class="field grid">
                  <p-button type="button" label="Submit" (click)="onSubmitPassswordReset()" [disabled]="!passwordForm.valid"
                    styleClass="p-button-success p-button-raised p-button-rounded margin-left">
                 </p-button>                                    
                </div>

              </form>

            </p-tabPanel>

        </p-tabView>

    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix" *ngIf="!isSubmitHidden">

          <p-button label="Unlock" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="unlock()" *ngIf="isUnlockVisible && isSubmitShown"></p-button>
          <p-button label="Delete" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="delete()" *ngIf="isSubmitShown"></p-button>
          <p-button label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSaveUserClick()" *ngIf="isSubmitShown"></p-button>
          <p-button label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancel()"></p-button>


            <!-- <button type="button" pButton icon="fa fa-close p-button-raised" (click)="unlock()" label="Unlock" *ngIf="isUnlockVisible && isSubmitShown"></button>
            <button type="button" pButton icon="fa fa-close p-button-raised" (click)="delete()" label="Delete" *ngIf="isSubmitShown"></button>
            <button type="button" pButton icon="fa fa-check p-button-raised" (click)="onSaveUserClick()" label="Save" *ngIf="isSubmitShown"></button>
            <button type="button" pButton icon="fa fa-check p-button-raised" (click)="cancel()" label="Cancel"></button> -->
        </div>
    </p-footer>

</p-dialog>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="portal-user"></p-confirmDialog>