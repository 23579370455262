import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { SharedService } from "../shared.service";
import { TokenStorageService } from '../safeguard/token-storage.service';
import { IRtpnBiller } from '../../interfaces/rtpn-biller';
import { IRtpnRequestsViewerResponse } from '../../interfaces/rtpn-requests-viewer-response';
import { IFileCreationSession }  from '../../interfaces/file-creation-session';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class RtpnTransactionsService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl.replace('portal/api','rtpn/api');
  // protected portalBaseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected baseAuth = AppConfigService.settings.appSetting.baseAuth;

  protected baseUrl = environment.apiServer.portalUrl.replace('portal/api','rtpn/api');
  protected portalBaseUrl = environment.apiServer.portalUrl;
  protected baseAuth = environment.appSetting.baseAuth;

  private baseHeaders = new HttpHeaders()
    .set("request-origin", "csr-portal")
    .set('Authorization', this.baseAuth);

  private rtpnBillersUrl = this.portalBaseUrl + '/rtpn-admin/billers';  // PRIV_RTPN_DATA_EXTRACT
  private rtpnRequestsUrl = this.portalBaseUrl + '/rtpn-requests-viewer'; // PRIV_VP_RTPN_VIEWER

  private fileCreationRequestUrl = this.portalBaseUrl + '/file-creation-request'; // PRIV_VP_DATA_EXTRACT
  private fileCreationRequestsUrl = this.portalBaseUrl + '/file-creation-request/downloads'; // PRIV_VP_FILE_DOWNLOAD
  private fileDownloadUrl = this.portalBaseUrl + '/file-creation-request/downloads/{fileGuid}'; // PRIV_VP_FILE_DOWNLOAD

  constructor(
    private http: HttpClient, 
    private sharedService: SharedService,
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService       
  ) { }

  getRtpnBillers(): Observable<IRtpnBiller[]> {
    return this.http.get<IRtpnBiller[]>(this.rtpnBillersUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getRtpnRequests(brandCode: string, requestType: string, statusCode: string, accountNumber: string,
    dateFrom: string, dateTo: string, nextPageToken: string, direction: string, pageSize: number): Observable<IRtpnRequestsViewerResponse> {
      if (dateFrom != null) {
        dateFrom = this.sharedService.getFormattedDate(dateFrom);
      }
      if (dateTo != null) {
        dateTo = this.sharedService.getFormattedDate(this.sharedService.getNextDate(dateTo));
      }
      let request = {
        nextPageToken: nextPageToken ?? null,
        direction: direction ?? null,
        brandCodes: brandCode == null ? null : [brandCode], 
        requestType: requestType ?? null, 
        status: statusCode ?? null, 
        accountNumber: accountNumber ?? null,
        txnDateFrom:  this.sharedService.getFormattedDate(dateFrom) ?? null, 
        txnDateTo:  this.sharedService.getFormattedDate(dateTo) ?? null, 
        limit: pageSize
      }
      console.log('getRtpnRequests', request);
      console.log('rtpnRequestsUrl', this.rtpnRequestsUrl);

      return this.http.post<IRtpnRequestsViewerResponse>(this.rtpnRequestsUrl, request, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
    }

    getLatestPayments(dateFrom: string, dateTo: string, pageSize: number): Observable<IRtpnRequestsViewerResponse> {
        if (dateFrom != null) {
          dateFrom = this.sharedService.getFormattedDate(dateFrom);
        }
        if (dateTo != null) {
          dateTo = this.sharedService.getFormattedDate(this.sharedService.getNextDate(dateTo));
        }
        let request = {
          nextPageToken: null,
          direction: null,
          brandCodes: null, 
          requestType: 'P', 
          status: null, 
          accountNumber: null,
          txnDateFrom:  this.sharedService.getFormattedDate(dateFrom) ?? null, 
          txnDateTo:  this.sharedService.getFormattedDate(dateTo) ?? null, 
          limit: pageSize
        }
        console.log('getLatestPayments', request);
        console.log('rtpnRequestsUrl', this.rtpnRequestsUrl);
  
        return this.http.post<IRtpnRequestsViewerResponse>(this.rtpnRequestsUrl, request, {responseType: 'json'})
        .pipe(
          catchError(this.errorProcessorService.handleError)
        )
      }
  
    // Send in a request to generate a file based on the current selection criteria, including aggregating by brands or regions
  sendFileCreationRequest(brandCode: string, requestType: string, statusCode: string, accountNumber: string,
    dateFrom: string, dateTo: string): Observable<any> {
      let brandCodes = null;
      if (brandCode) {
        brandCodes = [brandCode];
      }
      if (dateFrom != null) {
        dateFrom = this.sharedService.getFormattedDate(dateFrom);
      }
      if (dateTo != null) {
        dateTo = this.sharedService.getFormattedDate(this.sharedService.getNextDate(dateTo));
      }
      let request = {
          brandCodes: brandCodes,
          userName: this.tokenStorageService.getCurrentUser().userName,
          requestParams: {
            status: statusCode ?? null,
            requestType: requestType ?? null,
            accountNumber: accountNumber ?? null,
            txnDateFrom: dateFrom ?? null,
            txnDateTo: dateTo ?? null
          },
          requestType: "RTPN_TRANSACTIONS_DETAILS",
          fileType: "csv"
        };  
      return this.http.post<any>(this.fileCreationRequestUrl, request, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }


  // Get a list of the files generated by the current user
  getFileCreationRequests(): Observable<IFileCreationSession[]> {
    return this.http.get<IFileCreationSession[]>(this.fileCreationRequestsUrl, {responseType: 'json'}) 
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  // Download a specific file that was previously generated by the user
  downloadFile(fileGuid: string): Observable<any> {
    var url = this.fileDownloadUrl
      .replace('{fileGuid}', fileGuid);
    return this.http.get(url, {responseType: 'arraybuffer', reportProgress: true})   // text
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

}

