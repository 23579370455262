<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded"  [disabled]="isDetailsShown">
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 


<!--   -->


<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-7 side-list-content-data" *ngIf="isListShown">
        <p-card header="List of Email/SMS Templates" subheader="" styleClass="ui-card-light" >

            <p-table #dt [columns]="cols" [value]="emailSmsTemplates" selectionMode="single" 
                 sortMode="multiple" [paginator]="true" [rows]="15" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                        </th>
                    </tr>
                    <!-- <tr>
                        <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            <input *ngSwitchCase="'variableName'" pInputText type="text" (input)="dt.filter($event.target?.value, col.field, 'contains')">
                            <input *ngSwitchCase="'datatype'" pInputText type="text" (input)="dt.filter($event.target?.value, col.field, 'contains')" [style]="{'width': '75%'}">
                        </th>
                        <th></th>
                    </tr>   -->
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pEditableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                        <td style="text-align:center">
                            <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Modify template" 
                                class="ui-button-info" (click)="onRowEdit(rowData)">
                            </button>
                            <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                data-toggle="tooltip" data-placement="top" title="Delete template" 
                                class="ui-button-danger" (click)="onRowDelete(rowData)" *ngIf="isSubmitShown">
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary" let-rowData>
                    <div style="text-align:left" *ngIf="isSubmitShown">
<!-- 
                        <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add"></button>
 -->
                        <p-button type="submit" label="Add" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="showDialogToAdd()"></p-button>
                        
                    </div>
                </ng-template>    
          </p-table> 
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">list_all</mat-icon>
            </p-footer>
        </p-card>
    </div>

    <!--- Second card with the details -->

    <div @flyInOut class="col-7 side-list-content-data" *ngIf="isDetailsShown">
        <p-card header="Email/SMS Template Details" subheader="" styleClass="ui-card-light" >
        
            <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">
    
                <div>
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="templateID" class="col-fixed label" style="width: 15em">Template Id:</label>
                        <div class="col">
                            <input pInputText id="templateID" type="text" formControlName="templateID" readonly="true" 
                                pTooltip="Unique template identifier" tooltipPosition="top"/> 
                        </div>
                    </div>
                    
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="templateName" class="col-fixed label" style="width: 15em">Template Name:</label>
                        <div class="col">
                            <input pInputText id="templateName" type="text" formControlName="templateName" [style]="{'width': '60%'}" 
                                [size]="35" placeholder="Required" pTooltip="Discriptive template name" tooltipPosition="top"/> 
                            <p-message severity="error" text="Template name is required" *ngIf="!userform.controls['templateName'].valid&&userform.controls['templateName'].dirty"></p-message>                 
                        </div>
                    </div>

                    <div class="field grid" *ngIf="isEmailTemplateType">
                        <div class="col-1"></div>
                            <label for="subject" class="col-fixed label" style="width: 15em">Subject:</label>
                        <div class="col">
                            <input pInputText id="subject" type="text" formControlName="subject" [style]="{'width': '60%'}" 
                                [size]="35" placeholder="Required" pTooltip="The subject of an email template" tooltipPosition="top"/> 
                            <p-message severity="error" text="Subject is required" *ngIf="!userform.controls['subject'].valid&&userform.controls['subject'].dirty"></p-message>                 
                        </div>
                    </div>


                    <div class="grid">

                        <div class="col-6">

                            <div class="field grid">
                                <div class="col-2"></div>
                                    <label for="templateType" class="col-fixed label" style="width: 15em">Template Type:</label>
                                <div class="col">
                                    <p-dropdown id ="templateType" [options]="templateTypes" formControlName="templateType" 
                                        (onChange) = "onChangeTemplateType($event)"
                                        [filter]="false" [editable]="false" pTooltip="Select the template type" tooltipPosition="top"> 
                                        <p-message severity="error" text="Template Type is required" *ngIf="!userform.controls['templateType'].valid&&userform.controls['templateType'].dirty"></p-message>                 
                                    </p-dropdown>
                                </div>
                            </div>
        
                        </div>
            
                        <div class="col-6">
    
                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="passAttach" class="col-fixed label" style="width: 10em">Attach Pass?:</label>
                                <div class="col">
                                    <p-checkbox formControlName="passAttach" id="passAttach" [binary]="true" pTooltip="Should the pass be attached to this template?" tooltipPosition="top"></p-checkbox>
                                </div>
                            </div>
                                        
                        </div>
                    </div>
                    
                    <div class="field grid">
                        <div class="col-1"></div>
                            <label for="Template Info" class="col-fixed label" style="width: 15em">Message Text:</label>
                        <div class="col">
                            <input pInputText id="templateInfo" type="text" formControlName="templateInfo" style="width: 25em" 
                                *ngIf="!isEmailTemplateType" 
                                placeholder="Required" pTooltip="The content of the Template" tooltipPosition="top"/> 

                            <p-editor formControlName="templateInfo" [style]="{'height':'200px'}" *ngIf="isEmailTemplateType"></p-editor>                        

                            <p-message severity="error" text="Template info is required" *ngIf="!userform.controls['templateInfo'].valid&&userform.controls['templateInfo'].dirty"></p-message>                 

                        </div>
                    </div>

                    <div class="grid">

                        <div class="col-6">

                            <div class="field grid" *ngIf="isEmailTemplateType">
                                <div class="col-2"></div>
                                    <label for="senderName" class="col-fixed label" style="width: 15em">Sender Name:</label>
                                <div class="col">
                                    <input pInputText id="senderName" type="text" formControlName="senderName" style="width: 16em" /> 
                                </div>
                            </div>
                                    
                        </div>

                        <div class="col-6">

                            <div class="field grid" *ngIf="isEmailTemplateType">
                                <div class="col-1"></div>
                                    <label for="senderAddress" class="col-fixed label" style="width: 15em">Sender Address:</label>
                                <div class="col">
                                    <input pInputText id="senderAddress" type="email" formControlName="senderAddress" style="width: 20em"/> 
                                </div>
                            </div>
        
                        </div>

                    </div>

                    <div class="grid">

                        <div class="col-6">

                            <div class="field grid">
                                <div class="col-2"></div>
                                    <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                                <div class="col">
                                    <input pInputText type="createDate" id="createDate" type="text" formControlName="createDate" readonly="true">
                                </div>
                            </div>
        
                        </div>

                        <div class="col-6">

                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                                <div class="col">
                                    <input pInputText type="lastUpdateDate" id="lastUpdateDate" type="text" formControlName="lastUpdateDate" readonly="true">
                                </div>
                            </div>
                                    
                        </div>

                    </div>




        
                    <div class="col-12 margin-bottom-narrower">
                        <div  class="col-2" class="row container-fluid"  id="divshow">
<!-- 
                            <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" *ngIf="isSubmitShown"></button>
                            <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button>
 -->
                            <p-button type="submit" label="Submit" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!userform.valid" *ngIf="isSubmitShown" ></p-button>
                            <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelDetails()"></p-button>

                        </div> 
                        <div class="col-10" *ngIf="!isSubmitShown">
                            <mat-label>{{resultMsg}}</mat-label>
                        </div>
                    </div>
        
                </div>
            </form>
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
            </p-footer>
        </p-card>
    
    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="email-sms-template"></p-confirmDialog>


