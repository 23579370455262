<h2>Portal Roles</h2>

<div class="grid">
    <div class="col-5">
        <div class="col-1">
            <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($any($event))"></p-button>
            <!-- <button pButton type="button" label="Search" class="ui-button-success" (click)="onSearchClick($any($event))"></button> -->
        </div>  
    </div>
    <!-- <h3 [hidden]="hideRole">
        Role Details: {{selectedRole}}
    </h3> -->
</div>

<!-- L I S T   O F   R O L E S -->    

<div class="grid">
    <div class="col-6">

        <p-table #dt [columns]="roleCols" [value]="roles" selectionMode="single" [(selection)]="selectedRole" 
            sortMode="multiple" (onRowSelect)="onRoleRowSelect($event)" [paginator]="true" [rows]="15" >
          <ng-template pTemplate="caption">
              List of Roles
          </ng-template>
          <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                    <input *ngSwitchCase="'roleName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '75%'}">
                    <input *ngSwitchCase="'enabled'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')" [style]="{'width': '75%'}">
                </th>
            </tr>  
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                      {{rowData[col.field]}}
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary" let-rowData>
              <div style="text-align:left" *ngIf="isSubmitShown">
                  <!-- <button type="button" pButton icon="fa fa-plus p-button-raised p-button-rounded" (click)="addRole()" label="Add"></button> -->
                  <p-button label="Add" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="addRole()"></p-button>
              </div>
          </ng-template>    
        </p-table> 
      
    </div>

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body"></p-confirmDialog>

    <!-- F U L L   V I E W   OF   O N E   R O L E -->    

    <p-dialog appendTo="body" header="Details for Role: {{role.roleName}}" [(visible)]="displayDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true"  [style]="{width: '50%'}">

    <div *ngIf="role">

        <p-tabView>

            <p-tabPanel header="Role Attributes" leftIcon="pi pi-user-edit">    

                <p-card header="Role Attributes" subheader="" styleClass="ui-card-light">
        
                    <div class="grid">
                        <div class="col-10">

                            <div class="field grid">
                                <label for="roleId" class="col-fixed label" style="width:15em">Role Id:</label>
                                <div class="col">
                                    <input pInputText id="roleId" type="text" [(ngModel)]="role.roleId" style="width: 20%;"  
                                    placeholder="Required" [readonly]="true"/> 
                                </div>
                              </div>                               

                              <div class="field grid">
                                <label for="roleName" class="col-fixed label" style="width:15em">Role Name:</label>
                                <div class="col">
                                    <input pInputText id="roleName" type="text" [(ngModel)]="role.roleName" style="width: 50%;"  
                                    placeholder="roleName"/> 
                                </div>
                              </div>                               

                              <div class="field grid">
                                <label for="roleDescription" class="col-fixed label" style="width:15em">Role Description:</label>
                                <div class="col">
                                    <input pInputText id="roleDescription" type="text" [(ngModel)]="role.roleDescription" style="width: 100%;"  
                                    placeholder="roleDescription"/> 
                                </div>
                              </div>                               

                              <div class="field grid">
                                <label for="enabled" class="col-fixed label" style="width:15em">Enabled?:</label>
                                <div class="col">
                                    <p-checkbox [(ngModel)]="role.enabled" id="enabled" [binary]="true" [readonly]="false"></p-checkbox>
                                </div>
                              </div>                               

                              <div class="field grid">
                                <label for="createDate" class="col-fixed label" style="width:15em">Create Date:</label>
                                <div class="col">
                                    <input pInputText id="createDate" type="text" [(ngModel)]="role.createDate" style="width: 50%;"  
                                    placeholder="createDate" [readonly]="true"/> 
                                </div>
                              </div>                               

                              <div class="field grid">
                                <label for="lastUpdateDate" class="col-fixed label" style="width:15em">Last Update Date:</label>
                                <div class="col">
                                    <input pInputText id="lastUpdateDate" type="text" [(ngModel)]="role.lastUpdateDate" style="width: 50%;"  
                                    placeholder="lastUpdateDate" [readonly]="true"/> 
                                </div>
                              </div>                               

                        </div>                        
        
                    </div>
            
                    <p-footer>
                        <mat-icon class="nav-caption wal-center">add_a_photo</mat-icon>
                    </p-footer>
                </p-card>
        

            </p-tabPanel>

            <p-tabPanel header="Role Privileges" leftIcon="pi pi-user-edit">    

                <p-pickList [source]="availablePrivileges" [target]="grantedPrivileges" sourceHeader="Available" targetHeader="Granted"
                        [sourceStyle]="{height:'25rem'}" [targetStyle]="{height:'25rem'}" 
                        styleClass="ui-card-light" filterBy="tags"
                        [responsive]="true" [dragdrop]="true" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-privilege pTemplate="item">
                        <div class="ui-helper-clearfix">
                            <div>{{ privilege.privilegeName }}</div>
                        </div>
                    </ng-template>
                </p-pickList>

            </p-tabPanel>

            <p-tabPanel header="Users" leftIcon="pi pi-user-edit">    

                <p-table #dt [columns]="userCols" [value]="roleUsers" selectionMode="single"  
                    sortMode="multiple"  [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15,25,50,75]">
                    <ng-template pTemplate="caption">
                        List of Users with the Role Granted
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                <input *ngSwitchCase="'emailAddress'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                                <input *ngSwitchCase="'userName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                            </th>
                        </tr>  
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary" let-rowData>
                    </ng-template>    
                </p-table> 
        
            </p-tabPanel>

        </p-tabView>

    </div>

    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <p-button label="Delete" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="deleteRole()" *ngIf="isSubmitShown"></p-button>
            <p-button label="Save" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSaveRoleClick()" *ngIf="isSubmitShown"></p-button>
            <p-button label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancel()"></p-button>
        </div>
    </p-footer>

</p-dialog>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="portal-role"></p-confirmDialog>


