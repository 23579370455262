import { Component, OnInit, Input } from '@angular/core';
import { EnrollmentLinkAttribute } from '../../classes/enrollment-link-attribute';

@Component({
  selector: 'app-enrollment',
  templateUrl: './dynamic-enrollment-link.component.html',
  styleUrls: ['./dynamic-enrollment-link.component.css']
})
export class DynamicEnrollmentLinkComponent implements OnInit {

  @Input() enrollmentLinkAttribute: EnrollmentLinkAttribute<string>;

  constructor() {
    this.enrollmentLinkAttribute = new EnrollmentLinkAttribute();
   }

  ngOnInit(): void {
  }

}
