import { Component, OnInit, ViewChild } from '@angular/core';
import { BrandStatsService } from "../../services/stats/brand-stats.service";
import { ConfirmationService } from "primeng/api";
import { SharedService } from "../../services/shared.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { BrandService } from '../../services/config/brand.service';
import { IBrand } from '../../interfaces/brand';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-clickthrough-stats',
  templateUrl: './clickthrough-stats.component.html',
  styleUrls: ['./clickthrough-stats.component.css']
})
export class ClickthroughStatsComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  titleMessage = "Clickthrough Statistics";
  isBrandSelectionButtonShown: boolean = true;
  openBrandSelectionDialog: boolean = false;
  isDetailsShown = false;
  currentBrand!: IBrand;
  defaultDate: any;
  brandName!: string;
  brandList = [];

  envRegions = [];
  brands = [];
  baseUrl: string = '';
  selectedBrand: string ='';
  selectedBrandName!: string;
  selectedDateFrom!: string;
  selectedDateTo!: string;

  clickthroughStatsCols!: any[];
  clickthroughStats: any[] = [];
  clickthroughStatsChart: any;
  optionsClickthroughs: any;

  aggregationTypes: any[] = [];
  aggregationType: string = '';
  tabViewActiveIndex: number = 0;

  totalAppleWalletCnt: number = 0;
  totalGooglePayCnt: number = 0;

  isSpinnerHidden: boolean = true;
 
  constructor(    
    private tokenStorageService: TokenStorageService,    
    private brandService: BrandService,
    private brandStatsService: BrandStatsService, 
    private confirmationService: ConfirmationService,
    private sharedService: SharedService) { 
  }

  ngOnInit() {
    this.aggregationTypes = [
      {label: 'Link Category', value: 'category'},
      {label: 'Category Group', value: 'category_group'},
      {label: 'Link Text', value: 'link_text'}
    ];
    this.aggregationType = 'category';
      // Define the columns for ClickthroughStats grid
      this.clickthroughStatsCols = [
        { field: 'linkName', header: 'Link Type', width:'50%', display: 'table-cell' },
        { field: 'appleWalletCnt', header: 'Apple Wallet Cnt', width:'25%', display: 'table-cell' },
        { field: 'googlePayCnt', header: 'Google Pay Cnt', width:'25%', display: 'table-cell' }
      ]; 

      this.optionsClickthroughs = this.sharedService.options('Clickthrough Counts by Device Platform');
      this.selectedDateFrom = this.sharedService.getOneWeekAgoDate();
      this.selectedDateTo = this.sharedService.getCurrentDate();

      // Figure out whether the user has just one granted brand - in this case we can eliminate brand selection altogether
      let assignedBrand = this.tokenStorageService.getCurrentUserAssignedBrand();
      if (assignedBrand) {
        this.setSingleBrand(assignedBrand);
      } else {
        this.isBrandSelectionButtonShown = true;
        this.currentBrand = this.sharedService.getCurrentBrand();
        if (this.currentBrand != null) {
          this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
          this.selectedBrand = this.currentBrand.brandCode || '';
          this.baseUrl = this.currentBrand.baseUrl || '';
          this.openBrandSelectionDialog = false;
        } else {
          this.openBrandSelectionDialog = true;
        }  
      }
  }

  // Set single brand as the default when the user has just one brand granted
  setSingleBrand(brandCode: string) {
    this.isBrandSelectionButtonShown = false;
    this.isSpinnerHidden = false;
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe({
      next: (response) => {
        this.currentBrand = response;
        this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
        this.baseUrl = response.baseUrl || '';
        this.selectedBrand = response.brandCode || '';
        this.isSpinnerHidden = true;
      },
      error: (error) => {
      },
      complete: () => {
      }
    });
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  onClearClick(event: any) {
    // this.baseUrl = null;
    // this.selectedBrand = null;
    this.selectedDateFrom = '';
    this.selectedDateTo = '';
  }

  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.sharedService.setCurrentBrand(item);
      this.selectedBrand = item.brandCode || '';
      this.selectedBrandName = " ( " + item.brandName + " )";
      this.baseUrl = item.baseUrl || '';
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
    }
  }

  // Process the Search request
  onSearchClick(event: any) {
    this.tabViewActiveIndex = 0;
    this.totalAppleWalletCnt = 0;
    this.totalGooglePayCnt = 0;
    if (!(this.aggregationType && this.selectedBrand && this.selectedDateFrom && this.selectedDateTo)) {
      this.confirmationService.confirm({
        message: 'Make sure to select the aggregation type, a brand and the date interval in the selection criteria to retireve database records.',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "Ok",
        rejectVisible: false,
        acceptButtonStyleClass: "p-button-info  p-button-rounded",
        accept: () => {
              return;
            }
      });          
      return;
    }
    this.isSpinnerHidden = false;
    this.brandStatsService.getClickthroughStats(
          this.baseUrl, this.selectedBrand, this.selectedDateFrom, this.selectedDateTo, this.aggregationType)
          .subscribe({
            next: (result) => {
              this.isSpinnerHidden = true;
              if (result.length == 0) {
                this.clickthroughStats = [];
                this.clickthroughStatsChart = {
                  labels: [],
                  datasets: []
                }
                this.confirmationService.confirm({
                  message: 'No records found for the specified selection criteria.',
                  header: 'Warning',
                  icon: 'pi pi-exclamation-triangle',
                  acceptLabel: "Ok",
                  rejectVisible: false,
                  acceptButtonStyleClass: "p-button-info  p-button-rounded",
                  accept: () => {
                        return;
                      }
                });          
              }
              else {
                // Populate the grid
                result.forEach(element => {
                  this.totalAppleWalletCnt += element.appleWalletCnt;
                  this.totalGooglePayCnt += element.googlePayCnt;
                })
                  this.clickthroughStats = result;
                // Populate the chart
                var labels = [];
                var appleWallet = [];
                var googlePay = [];
                for(let i = 0; i < result.length; i++){
                  var label = result[i].linkName;
                  var index = labels.findIndex(element => element == label);
                  if (index < 0) {
                    labels.push(label);
                  }
                }
                for(let i = 0; i < result.length; i++){
                  var label = result[i].linkName;
                  var index = labels.findIndex(element => element == label);
                  if (index >= 0) {
                      appleWallet[index] = result[i].appleWalletCnt;
                      googlePay[index] = result[i].googlePayCnt;
                  }
                }
      
                this.clickthroughStatsChart = {
                  labels: labels,
                  datasets: [
                      {
                          label: 'Apple Wallet',
                          backgroundColor: 'blue',
                          borderColor: 'darkblue',
                          data: appleWallet
                      },
                      {
                        label: 'Google Pay',
                        backgroundColor: 'green',
                        borderColor: 'darkgreen',
                        data: googlePay
                      }
                  ]
                } 
      
              }
      
            },
            error: (error) => {
            },
            complete: () => {
            }
          });
  }

}
