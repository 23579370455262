import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot} from '@angular/router';
import { map } from 'rxjs';
import {AuthService }  from "../services/safeguard/auth.service";

export const requiredPrivilege = (next: ActivatedRouteSnapshot) => {
  let role: string = next.data?.['role'];
  let roles: string[] = next.data?.['roles'];
  let privilege: string = next.data?.['privilege'];
  let privileges: string[] = next.data?.['privileges'];
  const service = inject(AuthService);
  return service
  .isUserAuthorized(role, roles, privilege, privileges)
    .pipe(
      map((isAuthorized) =>
      isAuthorized ? true : createUrlTreeFromSnapshot(next, ['/', 'login'])
      )
    );
};
