import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../interfaces/filter-config';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class FilterBuilderService {

  protected baseAuth = environment.appSetting.baseAuth;

  private baseHeaders = new HttpHeaders()
    .set("request-origin", "csr-portal")
    .set('Authorization', this.baseAuth);

    private filterConfigUrl = '/filter-config';
    private filterConfigsByBrandUrl = '/filter-config/brands/{brandCode}';
    private filterConfigById = '/filter-config/{filterConfigId}';
    private cloneFilterConfigUrl = '/filter-config/clone';

  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService
  ) { }

  getFilterConfigsForBrand(brandCode: string, baseUrl: string): Observable<IFilterConfig[]> {
    var url = baseUrl + this.filterConfigsByBrandUrl.replace('{brandCode}', brandCode);
    return this.http.get<IFilterConfig[]>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getFilterConfigById(filterConfigId: number, baseUrl: string): Observable<IFilterConfig> {
    var url = baseUrl + this.filterConfigById.replace('{filterConfigId}', filterConfigId.toString());
    return this.http.get<IFilterConfig>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addFilterConfig(filterConfig: any, baseUrl: string): Observable<IFilterConfig> {
    var url = baseUrl + this.filterConfigUrl;
    return this.http.post<IFilterConfig>(url, filterConfig, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  modifyFilterConfig(filterConfig: any, baseUrl: string): Observable<IFilterConfig> {
    var url = baseUrl + this.filterConfigById
      .replace('{filterConfigId}', filterConfig.filterConfigId.toString());
      // console.log('modifyFilterConfig', filterConfig);
    return this.http.put<IFilterConfig>(url, filterConfig, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  cloneFilterConfig(filterConfig: any, baseUrl: string): Observable<IFilterConfig> {
    var url = baseUrl + this.cloneFilterConfigUrl;
    return this.http.post<IFilterConfig>(url, filterConfig, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  deleteFilterConfig(filterConfigId: number, baseUrl: string): Observable<number> {
    var url = baseUrl + this.filterConfigById.replace('{filterConfigId}', filterConfigId.toString());
    return this.http.delete<number>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }


}
