<!-- The following is a popup dialog to select a brand -->

<p-dialog appendTo="body" header="Brand Selection" [(visible)]="displayBrandDialog" [focusOnShow]="false" [responsive]="true" 
        showEffect="fade" [modal]="true" [style]="{width: '45%'}" position="top"  [showHeader]="true" [closable]="false">

    <div class="grid">
        <div class="col-12">

            <p-table #dt [columns]="brandCols" [value]="brands" selectionMode="single" 
                [(selection)]="selectedBrand"  
                sortMode="multiple" (onRowSelect)="onRowBrandSelect($event)" [paginator]="true" [rows]="15" >
              <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                      {{col.header}}
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
                  <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field"  [ngStyle]="{'width': col.width}">
                        <input *ngSwitchCase="'brandCode'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"  [style]="{'width': '75%'}">
                        <input *ngSwitchCase="'brandName'" pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')">
                    </th>
                </tr>  
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                      <td *ngFor="let col of columns">
                          {{rowData[col.field]}}
                      </td>
                  </tr>
              </ng-template>
            </p-table> 
      
        </div>
    </div>

    <div class="grid">
        <div *ngIf="!isBrandGranted">
            <label>{{noBrandWarning}}</label>
        </div>
    </div>

    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
<!--             
            <button type="button" pButton icon="fa fa-check" (click)="onBrandSelect()" label="Select" *ngIf="isBrandGranted"></button>
            <button type="button" pButton icon="fa fa-check" (click)="onCancelBrandSelect()" label="Cancel"></button>
 -->
            <p-button label="Select" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onBrandSelect()" *ngIf="isBrandGranted"></p-button>
            <p-button label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelBrandSelect()"></p-button>

            <div class="ui-g-2" id="idSpinner" [hidden]="isSpinnerHidden">
                <div class="loader"></div>  
            </div>
        </div>
    </p-footer>


</p-dialog>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="choose-brand"></p-confirmDialog>

