<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand andd a button to select another brand -->

<div class="grid">
    <div class="col-6">
        <app-brand-admin-header></app-brand-admin-header>
    </div>
    <div class="col-1">
        <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
             class="p-button-raised p-button-rounded" >
         </button>
    </div>  
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 


<!-- Dropdowns for selecting Pass Tempalte and Offer -->

<div class="grid col-6">

    <p-card header="Settings Criteria" subheader="" styleClass="ui-card-light" >

        <div class="grid">

        <div class="col-3">
            <label for="passTemplate" class="margin-left">Pass Template: </label>
            <p-dropdown id ="passTemplate" [options]="passTemplates" [(ngModel)]="selectedPassTemplate" (onChange)="onChangePassTemplate($event)"
                [filter]="false" [editable]="false" [style]="{'width': '100%'}">
            </p-dropdown>
        </div> 

        <div class="col-3">
            <label for="offer" class="margin-left">Field Set: </label>
            <p-dropdown id ="offer" [options]="offers" [(ngModel)]="selectedOffer" (onChange)="onChangeOffer($event)"
                [filter]="false" [editable]="false" [style]="{'width': '100%'}">
            </p-dropdown>
        </div> 

        <div class="col-3">
            <label for="sourceChannel" class="margin-left">Source Channel: </label>
            <p-dropdown id ="sourceChannel" [options]="sourceChannels" [(ngModel)]="selectedChannel" (onChange)="onChangeChannel($event)"
                [filter]="false" [editable]="false" [style]="{'width': '100%'}">
            </p-dropdown>
        </div> 

        <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
            <div class="loader"></div>  
        </div>

    </div>

        <p-footer>
            <mat-icon class="nav-caption wal-center">settings</mat-icon>
        </p-footer>



    </p-card>

</div> 


<!-- Dynamic list of enrollment links for the brand -->

<div *ngFor="let enrollmentLink of enrollmentLinks" class="form-row">
    <app-enrollment [enrollmentLinkAttribute]="enrollmentLink">
    </app-enrollment>
</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="enrollment-links"></p-confirmDialog>
