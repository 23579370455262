<!-- A popup dialog to select a different brand -->
<app-choose-brand [displayBrandDialog] = "openBrandSelectionDialog" (chooseBrandEvent)="chooseBrand($event)"></app-choose-brand>

<!--- Generic header, inclusing current brand and a button to select another brand -->

<div class="grid">
    <div class="col-8">
        <app-brand-admin-header></app-brand-admin-header>
        <div class="col-1">
            <button pButton type="button" label="Brand" icon="pi pi-sitemap" iconPos="left" (click)="onChooseBrandClick($event)"  
                 class="p-button-raised p-button-rounded" [disabled]="isDetailsShown">
             </button>
        </div>  
    </div>
    <app-side-menu  [parentForm] = "currentForm"></app-side-menu>
</div> 

<!--   -->

<div class="grid">

    <!--- First card with the master list -->

    <div @flyInOut class="col-8 side-list-content-data" *ngIf="isListShown">

        <!-- The list of Config Mappings -->

        <div class="row" *ngIf="isListOfConfigMappingsShown">

            <div class="col-12">

                <p-card header="List of Config Mappings" subheader="" styleClass="ui-card-powder" >
    
                    <p-table #dt [columns]="cols" [value]="configMappings" selectionMode="single" 
                         sortMode="multiple" [paginator]="true" [rows]="15" >
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pEditableRow]="rowData">
                                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{rowData[col.field]}}
                                </td>
                                <td style="text-align:center">
                                    <!-- <button pButton type="button" icon="pi pi-table" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Go to individual fields" 
                                        class="btn ui-button-primary" (click)="offerFieldsRedirect(rowData)" style="margin-right:10px">
                                    </button>                                 -->
                                    <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}" 
                                        data-toggle="tooltip" data-placement="top" title="Modify config mapping" 
                                        class="btn ui-button-info p-mr-2" (click)="onRowEdit(rowData)">
                                    </button> 
                                    <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Delete config mapping" *ngIf="isSubmitShown" 
                                        class="btn ui-button-danger" (click)="onRowDelete(rowData)" style="margin-right:10px">
                                    </button>                                
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary" let-rowData>
                            <div style="text-align:left">
<!-- 
                                <button type="button" pButton icon="fa fa-plus" (click)="showDialogToAdd()" label="Add" *ngIf="isSubmitShown" ></button>
 -->
                                <p-button type="submit" label="Add" 
                                    styleClass="p-button-info p-button-raised p-button-rounded margin-left" 
                                    (click)="showDialogToAdd()"  *ngIf="isSubmitShown">
                                </p-button>

                            </div>
                        </ng-template>    
                  </p-table> 
            
                    <p-footer>
                        <mat-icon class="nav-caption wal-center">list_all</mat-icon>
                    </p-footer>
                </p-card>
                    
            </div>
    
        </div>
 
    </div>

    <!--- Second card with the details -->

    <div @flyInOut class="col-10 side-list-content-data" *ngIf="isDetailsShown">

        <div class="col-10">

            <p-card header="Config Mapping Details" subheader="" styleClass="ui-card-light" >
            
                <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">

                    <div class="grid">

                        <div class="col-6">

                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="mappingId" class="col-fixed label" style="width: 15em">Mapping Id:</label>
                                <div class="col-7">
                                    <input pInputText id="mappingId" type="text" formControlName="mappingId" readonly="true" [style]="{'width': '25%'}"
                                        pTooltip="Unique mapping identifier" tooltipPosition="top"/> 
                                </div>
                            </div>
                            
                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="mappingName" class="col-fixed label" style="width: 15em">Mapping Name:</label>
                                <div class="col-7">
                                    <input pInputText id="mappingName" type="text" formControlName="mappingName" [style]="{'width': '75%'}" readonly="true" 
                                        [size]="200" placeholder="Required" pTooltip="Discriptive mapping name" tooltipPosition="top"/> 
                                    <p-message severity="error" text="Mapping name is required" *ngIf="!userform.controls['mappingName'].valid&&userform.controls['mappingName'].dirty"></p-message>                 
                                </div>
                            </div>
                
                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="mappingDescription" class="col-fixed label" style="width: 15em">Mapping Description:</label>
                                <div class="col-7">
                                    <input pInputText id="mappingDescription" type="text" formControlName="mappingDescription" [style]="{'width': '100%'}" 
                                        [size]="1000" pTooltip="Mapping description" tooltipPosition="top"/> 
                                </div>
                            </div>
                
                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="mappingType" class="col-fixed label" style="width: 15em">Type:</label>
                                <div class="col-7">
                                    <p-dropdown id ="mappingType" [options]="mappingTypes" formControlName="mappingType" [style]="{'width': '40%'}" [readonly]="true" 
                                        [filter]="false" [editable]="false" pTooltip="Select the mapping type" tooltipPosition="top"> 
                                    </p-dropdown>
                                    <p-message severity="error" text="Mapping type is required" *ngIf="!userform.controls['mappingType'].valid&&userform.controls['mappingType'].dirty"></p-message>                 
                                </div>
                            </div>
                
                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="createDate" class="col-fixed label" style="width: 15em">Create Date:</label>
                                <div class="col-7">
                                    <input pInputText type="createDate" id="createDate" type="text" formControlName="createDate" readonly="true">
                                </div>
                            </div>
                
                            <div class="field grid">
                                <div class="col-1"></div>
                                    <label for="lastUpdateDate" class="col-fixed label" style="width: 15em">Last Update Date:</label>
                                <div class="col-7">
                                    <input pInputText type="lastUpdateDate" id="lastUpdateDate" type="text" formControlName="lastUpdateDate" readonly="true">
                                </div>
                            </div>
                
                                <div class="col-12">
                                <div  class="col-6" id="divshow">
<!-- 
                                    <button pButton type="submit" icon="fa fa-check" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" *ngIf="isSubmitShown"></button>
                                    <button pButton type="button" icon="fa fa-close" (click)="cancelDetails()" label="Cancel"></button>
 -->
                                    <p-button type="submit" label="Submit" 
                                        styleClass="p-button-success p-button-raised p-button-rounded margin-left" 
                                        [disabled]="!userform.valid" *ngIf="isSubmitShown" >
                                    </p-button>
                                    <p-button type="button" label="Cancel" 
                                        styleClass="p-button-primary p-button-raised p-button-rounded margin-left" 
                                        (click)="cancelDetails()">
                                    </p-button>
                                            
                                </div> 
                            </div>
    
                        </div>
    
                        <div class="col-6">
    
                            <div class="col-12">
                                <label for="mappingElements">Mapping Elements:</label>
                            </div>
                            <div class="col-12">
                                <textarea [rows]="35" [cols]="30" pInputTextarea id="mappingElements" 
                                    formControlName="mappingElements" style="width: 100%;" ></textarea>
                            </div>
        
                        </div>
    
                    </div>

                </form>
    

                <!-- <div class="col-6">

                    <p-table #dt [columns]="elemCols" [value]="mappingElements" selectionMode="single" 
                        [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="15" >
                        <ng-template pTemplate="caption">
                            Mapping Elements
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <th [ngStyle]="{'width': 'table-cell', 'display': '8%'}">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pEditableRow]="rowData">
                                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                                    {{rowData[col.field]}}
                                </td>
                                <td style="text-align:center">
                                    <button pButton type="button" icon="pi pi-pencil" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}" 
                                        data-toggle="tooltip" data-placement="top" title="Modify mapping element" 
                                        class="btn ui-button-info p-mr-2" (click)="onElementEdit(rowData)">
                                    </button> 
                                    <button pButton type="button" icon="pi pi-times" [style]="{'width':'20px', 'height':'20px', 'margin-right':'2px'}"  
                                        data-toggle="tooltip" data-placement="top" title="Delete mapping element" *ngIf="isSubmitShown" 
                                        class="btn ui-button-danger" (click)="onElementDelete(rowData)" style="margin-right:10px">
                                    </button>                                
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary" let-rowData>
                            <div style="text-align:left">
                                <button type="button" pButton icon="fa fa-plus" (click)="showAddElement()" label="Add" *ngIf="isAdvanceElementShown" ></button>
                            </div>
                        </ng-template>    
                    </p-table> 
                    
                </div> -->

                <p-footer>
                    <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
                </p-footer>
            </p-card>

        </div>

    </div>



    <!--- Third card to clone a new config mapping from a template -->

    <div @flyInOut class="col-10 side-list-content-data" *ngIf="isCreateShown">

        <div class="col-5">

            <p-card header="Create Config Mapping" subheader="" styleClass="ui-card-light" >
            
                <form [formGroup]="createform" (ngSubmit)="onCreate(createform.value)">

                    <div class="grid">

                        <div class="col-12">
                
                            <div class="col-12">
                                <div class="col-4">
                                    <label for="templateName">Template Name:</label>
                                </div>
                                <div class="col-8">
                                    <p-dropdown id ="templateName" [options]="availableTemplateNames" formControlName="templateName" [style]="{'width': '75%'}"
                                        [filter]="false" [editable]="false" pTooltip="Select the mapping template" tooltipPosition="top"> 
                                    </p-dropdown>
                                    <p-message severity="error" text="Mapping template is required" *ngIf="!createform.controls['templateName'].valid&&createform.controls['templateName'].dirty"></p-message>                 
                                </div>
                            </div>
                
                            <div class="col-12">
                                <div  class="col-6" id="divshow">
<!-- 
                                    <button pButton type="submit" icon="fa fa-check" label="Create" class="ui-button-primary" [disabled]="!createform.valid" *ngIf="isCreateShown"></button>
                                    <button pButton type="button" icon="fa fa-close" (click)="cancelCreate()" label="Cancel"></button>
 -->
                                    <p-button type="submit" label="Create" styleClass="p-button-success p-button-raised p-button-rounded margin-left" [disabled]="!createform.valid" *ngIf="isCreateShown" ></p-button>
                                    <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="cancelCreate()"></p-button>
        
                                </div> 
                            </div>
    
                        </div>
    
                    </div>

                </form>

                <p-footer>
                    <mat-icon class="nav-caption wal-center">view_compact</mat-icon>
                </p-footer>
            </p-card>

        </div>

    </div>
    

</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="config-mappings"></p-confirmDialog>

