import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'; 
import { WebSocketService } from "./web-socket.service";
// import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketAdapterService {

  // protected webSocketlUrl = AppConfigService.settings.apiServer.webSocketlUrl + '/websocket/monitor';
  protected webSocketlUrl = environment.apiServer.webSocketlUrl + '/websocket/monitor';
  public messages: Subject<any>;

  constructor(wsService: WebSocketService) {
    this.messages = <Subject<any>>wsService
    .connect(this.webSocketlUrl)
    .pipe((response: any): any => {
      return response;
    })
  }
}
