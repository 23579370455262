import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, query, stagger, group, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IBrandVariable } from '../../interfaces/brand-variable';
import { BrandProfileService } from '../../services/construct/brand-profile.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
import { SharedService } from "../../services/shared.service";
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-brand-variables',
  templateUrl: './brand-variables.component.html',
  styleUrls: ['./brand-variables.component.css'],
  animations: [
    trigger('listDeailsShowTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s linear', style({ opacity: 0, width: '0px' })),
      ]),
      transition(':leave', [
        style({ opacity: 0 }),
        animate('0.5s linear', style({ opacity: 0, width: '0px' }))
      ])
    ]),
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class BrandVariablesComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "BrandVariables";
  titleMessage = "Brand Variables";
  userform!: FormGroup;
  isListShown = true;
  isDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;

  brandVariables: any[]  = [];
  cols!: any[];
  dataTypes: any[] = [];
  evalScopes: any[]  = [];
  evalExpressions: any[]  = [];
  resultMsg = "";
  currentBrand!: IBrand;
  
  openBrandSelectionDialog!: boolean;

  constructor(
    private fb: FormBuilder, 
    private brandProfileService: BrandProfileService,
    private sharedService: SharedService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService
    ) {
      this.currentBrand = this.sharedService.getDummyBrand();      
    }

  ngOnInit(): void {
    this.dataTypes = [
      {label: "String", value: "String"}, 
      {label: "Boolean", value: "BOOLEAN"}, 
      {label: "Date", value: "Date"}, 
      {label: "DateTime", value: "DateTime"}, 
      {label: "Numeric", value: "Numeric"}, 
      {label: "Json", value: "Json"},
      {label: "ImageName", value: "ImageName"}
    ];
    this.evalScopes = [{label: "Instance", value: "INSTANCE"}];

    this.cols = [
      { field: 'variableId', header: 'Variable Id', display: 'table-cell', width:'12%'  },
      { field: 'variableName', header: 'Variable Name', display: 'table-cell', width:'20%'  },
      { field: 'datatype', header: 'Datatype', display: 'table-cell', width:'11%'  },
      { field: 'defaultValue', header: 'Default Value', display: 'table-cell', width:'15%'  },
      { field: 'evalExpression', header: 'Change Indicator Variable', display: 'table-cell', width:'15%'  },
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'table-cell', width:'19%'  },
    ]; 

    this.userform = this.fb.group({
      'variableId': new FormControl(''),
      'variableName': new FormControl('', [Validators.required, Validators.maxLength(35)]),
      'datatype': new FormControl('String', [Validators.required]),
      'defaultValue': new FormControl(''),
      'evalScope':  new FormControl('Instance', [Validators.required]),
      'evalExpression': new FormControl('[none]'),
      'variableMetadata': new FormControl(null),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
    });

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.populateBrandChangeIndicatorVariables();
      this.openBrandSelectionDialog = false;
      this.getBrandVariables(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_VARIABLE_MODIFY')) {
      this.isSubmitShown = true;
    }
       
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.sharedService.setCurrentBrand(item);
      this.populateBrandChangeIndicatorVariables();
      this.getBrandVariables(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    }
  }

  onSubmit(value: string) {
    let brandVariable: IBrandVariable = {
      variableId: this.userform.value.variableId,
      variableName: this.userform.value.variableName,
      datatype: this.userform.value.datatype, 
      evalScope: this.userform.value.evalScope,
      defaultValue: this.userform.value.defaultValue ? this.userform.value.defaultValue : null,
      evalExpression: this.userform.value.evalExpression ? this.userform.value.evalExpression : null,
      createDate: this.userform.value.createDate,
      lastUpdateDate: this.userform.value.lastUpdateDate,
      variableMetadata: this.userform.value.variableMetadata ? this.userform.value.variableMetadata : null
    };
    // Call the service to invoke a Web API call
    this.brandProfileService.submitBrandVariable(this.currentBrand.brandCode, brandVariable, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        if (brandVariable.variableId) {
          this.alertTheUser('Successfully modified variable ' + brandVariable.variableName + ' from brand ' + this.currentBrand.brandCode);
        } else {
          this.alertTheUser('Successfully added variable ' + brandVariable.variableName + ' to brand ' + this.currentBrand.brandCode);
        }
        this.getBrandVariables(this.currentBrand.brandCode, this.currentBrand.baseUrl);
        this.isListShown = true;
        this.isDetailsShown = false;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  cancelDetails() {
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  // Select all existing brand variables
  getBrandVariables(brandCode: string, brandUrl: string) {
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandVariables(brandCode, brandUrl)
    .subscribe({
      next: (response) => {
        this.brandVariables = response;
        // Format the dates
        for(let i = 0; i < response.length; i++){
          if (response[i].createDate) {
            response[i].createDate = this.sharedService.formatFromISO(response[i].createDate);
          }
          if (response[i].lastUpdateDate) {
            response[i].lastUpdateDate = this.sharedService.formatFromISO(response[i].lastUpdateDate);
          }
        }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  showDialogToAdd() {
    // this.populateBrandChangeIndicatorVariables();
    this.userform.setValue({
      variableId: null, 
      variableName: '', 
      datatype: 'String', 
      evalScope: 'INSTANCE',
      defaultValue: null, 
      evalExpression: null, 
      createDate: null, 
      lastUpdateDate: null,
      variableMetadata: null
    })
    this.isListShown = false;
    this.isDetailsShown = true;
  }

  // Modify existing brand variable
  onRowEdit(event: any) {
    // this.populateBrandChangeIndicatorVariables();
    // Call the service to invoke a Web API call
    this.brandProfileService.getBrandVariable(this.currentBrand.brandCode, event.variableId, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        let varMet = response.hasOwnProperty('variableMetadata') ? response.variableMetadata : null;
        if (varMet) {
          varMet = JSON.stringify(JSON.parse(varMet), null, 2);
        }
        let defVal = response.hasOwnProperty('defaultValue') ? response.defaultValue : '';
        let evalExpr = response.hasOwnProperty('evalExpression') ? response.evalExpression : '';
        let variableMetadata = varMet;
        this.userform.setValue({variableId: response.variableId, variableName: response.variableName, datatype: response.datatype, 
          evalScope: response.evalScope, defaultValue: defVal, evalExpression: evalExpr, 
          createDate: this.sharedService.formatFromISO(response.createDate),
          lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate),
          variableMetadata: variableMetadata})
        this.isListShown = false;
        this.isDetailsShown = true;
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the variable
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the variable ' + event.variableName + ' ?',
      header: 'Confirmation',
      key: 'brand-variables',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success  p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandProfileService.deleteBrandVariable(this.currentBrand.brandCode, event.variableId, 
            this.currentBrand.baseUrl)
            .subscribe({
              next: (response) => {
                this.alertTheUser('Successfully deleted variable ' + event.variableName + ' from brand ' + this.currentBrand.brandCode);
                this.getBrandVariables(this.currentBrand.brandCode, this.currentBrand.baseUrl);
              },
              error: (error) => {
                this.alertTheUser(error.message);
              },
              complete: () => {
              }
            });
      },
      reject: () => {
      }
    });
  }

  onRowSelect(event: any) {
  }  

  populateBrandChangeIndicatorVariables() {
    this.brandProfileService.getBrandChangeIndicatorVariables(this.currentBrand.brandCode, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        this.evalExpressions = [];
        this.evalExpressions.push({label: '[none]', value: '[none]'});
          for(let i = 0; i < response.length; i++){
            this.evalExpressions.push({label: response[i], value: response[i]});
          }
      },
      error: (error) => {
        this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  alertTheUser(message: string) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-variables',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info  p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
