<div class="grid">

    <div class="col-4">
        <p-card header="Brand Profile" subheader="" styleClass="ui-card-light" >

            <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)">
    
                <div>

                    <div class="field grid">
                        <label for="brandCode" class="col-fixed label" style="width:15em">Brand Code:</label>
                        <div class="col">
                            <input pInputText id="brandCode" type="text" formControlName="brandCode" [disabled]="isDisabled" 
                                [size]="3" [minLength]="3" placeholder="Required" pTooltip="Three character unique brand code" tooltipPosition="top"/> 
                            <p-message severity="error" text="Brand code is required" *ngIf="!userform.controls['brandCode'].valid&&userform.controls['brandCode'].dirty"></p-message>                
                        </div>
                    </div>    

                    <div class="field grid">
                            <label for="brandName" class="col-fixed label" style="width:15em">Brand Name:</label>
                        <div class="col">
                            <input pInputText id="brandName" type="text" formControlName="brandName" [style]="{'width': '100%'}" [disabled]="isDisabled" 
                                [size]="45" placeholder="Required" pTooltip="Discriptive brand name" tooltipPosition="top"/> 
                            <p-message severity="error" text="Brand name is required" *ngIf="!userform.controls['brandName'].valid&&userform.controls['brandName'].dirty"></p-message>                 
                        </div>
                    </div>
        
                    <div class="field grid">
                        <label for="billerType" class="col-fixed label" style="width:15em">Biller Type:</label>
                        <div class="col">
                            <p-dropdown id ="billerType" [options]="billerTypes" formControlName="billerType"  styleClass="wal-dropdown"
                                [filter]="false" [editable]="false" [disabled]="isDisabled" pTooltip="Select the biller type" tooltipPosition="top"> 
                                <p-message severity="error" text="Biller type is required" *ngIf="!userform.controls['billerType'].valid&&userform.controls['billerType'].dirty"></p-message>                 
                            </p-dropdown>
                        </div>
                    </div>
        
                    <div class="field grid">
                        <label for="secondaryAccountId" class="col-fixed label" style="width:15em">Secondary Account Id:</label>
                        <div class="col">
                            <input pInputText id="secondaryAccountId" type="text" formControlName="secondaryAccountId" [disabled]="isDisabled">
                        </div>
                    </div>
        
                    <div class="field grid">
                        <label for="iosAppId" class="col-fixed label" style="width:15em">iOS App Id:</label>
                        <div class="col">
                            <input pInputText id="iosAppId" type="text" formControlName="iosAppId" [disabled]="isDisabled">
                        </div>
                    </div>
        
                    <!-- <div class="col-12">
                        <div class="col-4">
                            <label for="emailFromAddress">Email From Address:</label>
                        </div>
                        <div class="col-8">
                            <input pInputText type="email" id="emailFromAddress" formControlName="emailFromAddress" [disabled]="isDisabled"
                            validatorMessage="Value does not match pattern."
                                [style]="{'width': '100%'}"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"/>
                        </div>
                    </div> -->
        
                    <div class="grid">
                        <div  class="col-2" *ngIf="isSubmitShown" class="row container-fluid"  id="divshow">
                            <button pButton type="submit" label="Submit" class="ui-button-primary" [disabled]="!userform.valid" ></button>
                        </div>          
                        <div class="col-10" *ngIf="!isSubmitShown">
                            <mat-label>{{resultMsg}}</mat-label>
                        </div>
                    </div>
        
                </div>

            </form>

            <p-footer>
                <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
            </p-footer>
        </p-card>
    </div>
    
    <div class="col-4">

        <p-card header="Images" subheader="" styleClass="ui-card-light" >
        
            <div>
    
                <div class="grid">
                    <div class="col-4">
                        <label for="logoImage">Logo Image:</label>
                    </div>
                    <div class="col-8">
                        <p-fileUpload name="logoImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                            [customUpload]="true" (onSelect)="onLogoFileChange($event)"
                            [showCancelButton]="true" [disabled]="isDisabled" > 
                        </p-fileUpload>
                    </div>
                </div>
    
                <div class="grid">
                    <div class="col-4">
                        <label for="appleIconImage">Apple Icon Image:</label>
                    </div>
                    <div class="col-8">
                        <p-fileUpload name="appleIconImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                            [customUpload]="true" (onSelect)="onApleIconFileChange($event)"
                            [showCancelButton]="true" [disabled]="isDisabled" > 
                        </p-fileUpload>
                    </div>
                </div>
    
                <div class="grid">
                    <div class="col-4">
                        <label for="googleIconImage">Google Pay Icon Image:</label>
                    </div>
                    <div class="col-8">
                        <p-fileUpload name="googleIconImage" [multiple]="false" accept="image/*" [maxFileSize]="1000000" [showUploadButton]="false"
                            [customUpload]="true" (onSelect)="onGoogleIconFileChange($event)"
                            [showCancelButton]="true" [disabled]="isDisabled" > 
                        </p-fileUpload>
                    </div>
                </div>
    
            </div>
    
            <p-footer>
                <mat-icon class="nav-caption wal-center">add_a_photo</mat-icon>
            </p-footer>
        </p-card>
    
        <hr/>

        <p-panel header="Image Size Guidelines" [toggleable]="true" styleClass="ui-card" [collapsed]="true">

            <dl class="row ui-card-powder">
                <dt class="col-sm-3 fas fa-lg"><mat-icon>place</mat-icon>Background</dt>
                <dd class="col-sm-9">The background image (background.png) is displayed behind the entire front of the pass. The expected dimensions are 180x220 points. The image is cropped slightly on all sides and blurred. Depending on the image, you can often provide an image at a smaller size and let it be scaled up, because the blur effect hides details. This lets you to reduce the file size without a noticeable difference in the pass.</dd>
                <dt class="col-sm-3 fas fa-lg"><mat-icon>place</mat-icon>Footer</dt>
                <dd class="col-sm-9">The footer image (footer.png) is displayed near the barcode. The allotted space is 286x15 points.</dd>
                <dt class="col-sm-3 fas fa-lg"><mat-icon>place</mat-icon>Icon</dt>
                <dd class="col-sm-9">The icon (icon.png) is displayed when a pass is shown on the lock screen and by apps such as Mail when showing a pass attached to an email. The expected dimensions are the same as a small icon. A shine effect is automatically applied to the icon for you.</dd>
                <dt class="col-sm-3 fas fa-lg"><mat-icon>place</mat-icon>Logo</dt>
                <dd class="col-sm-9">The logo image (logo.png) is displayed in the top left corner of the pass, next to the logo text. The allotted space is 160x50 points; in most cases it should be narrower. To engrave the logo so that it matches the logo text, add a black drop shadow with a 1 pixel y offset, a 1 pixel blur, and a 35% opacity.</dd>
                <dt class="col-sm-3 fas fa-lg"><mat-icon>place</mat-icon>Strip</dt>
                <dd class="col-sm-9">The strip image (strip.png) is displayed behind the primary fields. The allotted space is 312x84 points for event tickets, 312x110 points for other pass styles with a square barcode, and 312x123 in all other cases. A shine effect is automatically applied to the strip image; to suppress it use the suppressStripShine key.</dd>
                <dt class="col-sm-3 fas fa-lg"><mat-icon>place</mat-icon>Thumbnail</dt>
                <dd class="col-sm-9">The thumbnail image (thumbnail.png) displayed next to the fields on the front of the pass. The allotted space is 90x90 points. The aspect ratio should be in the range of 2:3 to 3:2, otherwise the image is cropped.</dd>
            </dl>

        </p-panel>

    </div>

    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
        <div class="loader"></div>  
    </div>
    


</div>

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="bootstrap-brand"></p-confirmDialog>



