import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISubscriber } from '../../interfaces/subscriber';
import { IChannel } from '../../interfaces/channel';
import { IMessageProfile } from '../../interfaces/message-profile';
import { ITemplateRepository } from '../../interfaces/template-repository';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { ErrorProcessorService }  from "../error-processor.service";


@Injectable({
  providedIn: 'root'
})
export class CommunicationManagerService {

  // protected baseUrl = AppConfigService.settings.apiServer.portalUrl;
  // protected basicAuth = AppConfigService.settings.appSetting.portalAuth;
  // protected communicationAuth = AppConfigService.settings.appSetting.communicationAuth;
  // protected authServerUrl = AppConfigService.settings.apiServer.authServerUrl;

  protected baseUrl = environment.apiServer.portalUrl;
  protected basicAuth = environment.appSetting.portalAuth;
  protected communicationAuth = environment.appSetting.communicationAuth;
  protected authServerUrl = environment.apiServer.authServerUrl;

  private subscribersUrl = this.baseUrl.replace("portal/api","communication/api") + '/admin/subscribers';
  private subscriberUrl = this.baseUrl.replace("portal/api","communication/api") + '/admin/subscribers/{subscriberId}';
  private channelsUrl = this.baseUrl.replace("portal/api","communication/api") + '/admin/channels';
  private messageProfilesUrl = this.baseUrl.replace("portal/api","communication/api") + '/admin/message-profiles';
  private templateRepositoriesUrl = this.baseUrl.replace("portal/api","communication/api") + '/admin/template-repositories';

  // private communicationHeaders = new HttpHeaders()
  // .set('Authorization', this.communicationAuth);


  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private errorProcessorService: ErrorProcessorService
  ) { }

// Communication Manager Related Services

  getSubscribers(): Observable<ISubscriber[]> {
    return this.http.get<ISubscriber[]>(this.subscribersUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getSubscriber(subscriberId: number): Observable<ISubscriber> {
    var url = this.subscriberUrl.replace('{subscriberId}', subscriberId.toString());
    return this.http.get<ISubscriber>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addSubscriber(subscriberName: string, contactPointType: string, contactPoint: string, attributeCode: string, attribute: string) {
    var subscriberRec = 
    [{
      "subscriberName": subscriberName,
      "contactPoints": [{
        "addressOrNumber": contactPoint,
        "contactPointType": contactPointType  
      }],
      "attributes": [{
          "attributeCode": attributeCode,
          "attributeValue": attribute
        }
      ]
    }]
    return this.http.post(this.subscribersUrl, subscriberRec, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  modifySubscriber(subscriberId: number, subscriberName: string, enabled: boolean) {
    var subscriberRec = 
    {
      subscriberId: subscriberId,
      subscriberName: subscriberName,
      enabled: enabled
    }
    var url = this.subscribersUrl + "/" + subscriberId;
    return this.http.put(url, subscriberRec, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deleteSubscriber(subscriberName: string) {
    return this.http.delete(this.subscribersUrl + '/' + subscriberName, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getMessageProfiles(): Observable<IMessageProfile[]> {
    return this.http.get<IMessageProfile[]>(this.messageProfilesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addMessageProfile(messageProfile: any) {
    return this.http.post(this.messageProfilesUrl, messageProfile, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getChannels(): Observable<IChannel[]> {
    return this.http.get<IChannel[]>(this.channelsUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addContactPointToSubscriber(subscriberId: number, contactPointType: string, addressOrNumber: string) {
    var contactPoint = {
      "addressOrNumber": addressOrNumber,
      "contactPointType": contactPointType
    }
    return this.http.post(this.subscribersUrl + '/' + subscriberId + '/contactPoint', contactPoint, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  dropContactPointFromSubscriber(subscriberId: number, contactPointId: number) {
    return this.http.delete(this.subscribersUrl + '/' + subscriberId + '/contactPoint/' + contactPointId, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addAttributeToSubscriber(subscriberId: number, attributeCode: string, attributeValue: string) {
    var attribute = {
      "attributeCode": attributeCode,
      "attributeValue": attributeValue
    }
    return this.http.post(this.subscribersUrl + '/' + subscriberId + '/attribute', attribute, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  dropAttributeFromSubscriber(subscriberId: number, attributeId: any) {
    return this.http.delete(this.subscribersUrl + '/' + subscriberId + '/attribute/' + attributeId, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  subscribeContactPointToChannel(channelId: number, contactPointId: number) {
    return this.http.post(this.channelsUrl + '/' + channelId + '/subscribe/' + contactPointId, null, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  unsubscribeContactPointFromChannel(channelId: number, contactPointId: number) {
    return this.http.delete(this.channelsUrl + '/' + channelId + '/unsubscribe/' + contactPointId, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  changeSubscriptionStatus(channelId: number, contactPointId: number, subscriptionStatus: boolean) {
    return this.http.put(this.channelsUrl + '/' + channelId + '/subscribe/' + contactPointId, subscriptionStatus, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getTemplateRepositories(): Observable<ITemplateRepository[]> {
    return this.http.get<ITemplateRepository[]>(this.templateRepositoriesUrl, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }



}
