import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorProcessorService {

  constructor() { }

  public handleError(error: HttpErrorResponse) {

    console.log('handleError', error);

    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    if (error.status == 404 || error.status == 409 || error.status == 417 || error.status == 500) {
     // special "popular" case for a 404 - NOT FOUND condition, 500 - internal error
      return throwError(() => new Error(error.error.message));  
    } else {
      // cases like 400 - bad request, 401 -unauthorrized
      return throwError(() => new Error('Something bad happened; please try again later.')); 
    }
  } 

  public handleError2<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      debugger;
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }  


}
