import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldCustomJson  extends OfferFieldBase {

    brandVariables: any[];
    fieldAlignmentOptions: any[];
    fieldFormatOptions: any[];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        brandVariables: any[],
        fieldAlignmentOptions: any[],
        fieldFormatOptions: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.brandVariables = brandVariables;
            this.fieldAlignmentOptions = fieldAlignmentOptions;
            this.fieldFormatOptions = fieldFormatOptions;
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Value, Field Label(N), Snapshot Variable, Field Alignment (N), Field Data Type and Style (N), Hide from Distrib URL?,

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            required: true,
            rows: 1,
            cols: 36,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldLabel',
            label: 'Field Label',
            value: this.data.fieldLabel,
            styleWidth: '25em',
            required: false,
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldAlignment',
            label: 'Field Alignment',
            value: this.data.fieldAlignment,
            styleWidth: '15em',
            required: false,
            tooltip: 'Select the proper field allignment on the pass',
            options: this.fieldAlignmentOptions,
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldFormat',
            label: 'Field Data Type and Style',
            value: this.data.fieldFormat,
            styleWidth: '15em',
            required: false,
            tooltip: 'Select the proper field data type and style on the pass',
            options: this.fieldFormatOptions,
            order: 7
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'variableName',
            label: 'Snapshot Variable',
            styleWidth: '10em',
            value: this.data.variableName,
            required: true,
            tooltip: 'Select the proper snapshot variable on the pass',
            options: this.brandVariables,
            order: 8
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'checkbox',
            key: 'hiddenFromDistribUrl',
            label: 'Hide from Distrib URL?',
            checked: this.data.hiddenFromDistribUrl,
            required: false,
            order: 9
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 10
        }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);
    }
}