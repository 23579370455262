import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IMessageTemplate } from '../../interfaces/message-template';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class AdhocMessageTemplateService {

  protected baseAuth = environment.appSetting.baseAuth;

  private baseHeaders = new HttpHeaders()
    .set("request-origin", "csr-portal")
    .set('Authorization', this.baseAuth);

    private msgTemplateUrl = '/adhoc-msg-template/brands/{brandCode}/msg-templates';
    private msgTemplateByIdUrl = '/adhoc-msg-template/brands/{brandCode}/msg-templates/{msgTemplateCode}';

  constructor(
    private http: HttpClient,
    private errorProcessorService: ErrorProcessorService
  ) { }

  getMessageTemplatesByBrand(brandCode: string, baseUrl: string): Observable<IMessageTemplate[]> {
    var url = baseUrl + this.msgTemplateUrl
    .replace('{brandCode}', brandCode);
    return this.http.get<IMessageTemplate[]>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getMessageTemplateById(brandCode: string, msgTemplateCode: string, baseUrl: string): Observable<IMessageTemplate> {
    var url = baseUrl + this.msgTemplateByIdUrl
      .replace('{brandCode}', brandCode)
      .replace('{msgTemplateCode}', msgTemplateCode);
    return this.http.get<IMessageTemplate>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addMessageTemplate(brandCode: string, msgTemplate: any, baseUrl: string): Observable<IMessageTemplate> {
    var url = baseUrl + this.msgTemplateUrl
      .replace('{brandCode}', brandCode);
    return this.http.post<IMessageTemplate>(url, msgTemplate, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  modifyMessageTemplate(brandCode: string, msgTemplateCode: string, msgTemplate: any, baseUrl: string): Observable<IMessageTemplate> {
    var url = baseUrl + this.msgTemplateByIdUrl
      .replace('{brandCode}', brandCode)
      .replace('{msgTemplateCode}', msgTemplateCode);
    return this.http.put<IMessageTemplate>(url, msgTemplate, {responseType: 'json'})
      .pipe(
        catchError(this.errorProcessorService.handleError)
      )
  }

  deleteMessageTemplate(brandCode: string, msgTemplateCode: string, baseUrl: string): Observable<number> {
    var url = baseUrl + this.msgTemplateByIdUrl
      .replace('{brandCode}', brandCode)
      .replace('{msgTemplateId}', msgTemplateCode);
    return this.http.delete<number>(url, {headers: this.baseHeaders, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

}
